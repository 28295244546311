import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './components/sidebar';
import Topbar from './components/topbar';

import PaymentForm from './components/paymentForm';
import PaymentMethod from './components/paymentMethod';

import toggleLeft from '../../assets/img/ui_icons/toggle-left.svg';
import toggleRight from '../../assets/img/ui_icons/toggle-Right.svg';
import checkMark from '../../assets/img/ui_icons/cld_checkmark.svg';

import { FaBuilding } from "react-icons/fa";
import { Button } from 'react-bootstrap';
import SessionService from '../../services/SessionsService';

function Dashboard() {
    const [isCollapsed, setIsCollapsed] = useState(SessionService.ISCOLLAPSED);
    const [isMobileOpen, setIsMobileOpen] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('darkMode') === 'true' || localStorage.getItem('darkMode') === null);
    const [annuallyPlan, setAnnuallyPlan] = useState<boolean>(false);
    const [isUpgradeSelected, setIsUpgradeSelected] = useState(false); // Estado para el botón de "Upgrade"
    const [isPaymentActive, setIsPaymentActive] = useState(false); // Estado para activar el contenedor de Payment
    const [showPaymentForm, setShowPaymentForm] = useState(false); // Estado para alternar entre PaymentMethod y PaymentForm

    const togglePlan = () => {
        setAnnuallyPlan(!annuallyPlan); // Alterna el valor de `annuallyPlan`
    };

    useEffect(() => {
        const dashboardWrapper = document.getElementById('dashboardWrapper');
        if (dashboardWrapper) {
            if (isDarkMode) {
                dashboardWrapper.classList.add('dark-mode');
            } else {
                dashboardWrapper.classList.remove('dark-mode');
            }
        }
    }, [isDarkMode]);

    const toggleSidebar = () => {
        SessionService.ISCOLLAPSED = !isCollapsed;
        setIsCollapsed(!isCollapsed);
    };

    const toggleMobileSidebar = () => {
        setIsMobileOpen(!isMobileOpen);
    };

    const toggleTheme = () => {
        setIsDarkMode(prevMode => {
            const newMode = !prevMode;
            localStorage.setItem('darkMode', String(newMode)); // Guardar el estado en localStorage
            return newMode;
        });
    };

    // Función para alternar el estado de upgrade y activar Payment
    const handleUpgradeClick = () => {
        setIsUpgradeSelected(true);
        setIsPaymentActive(true);
    };

    // Alternar entre PaymentMethod y PaymentForm
    const handleAddPaymentMethod = () => {
        setShowPaymentForm(true);
    };

    const handleCancelPaymentForm = () => {
        setShowPaymentForm(false);
    };

    return (
        <div className="d-flex dark-mode" id="dashboardWrapper">
            {/* Sidebar */}
            <Sidebar isCollapsed={isCollapsed} isMobileOpen={isMobileOpen} />

            {/* Page Content */}
            <div id="page-content-wrapper" className="w-100">
                <Topbar
                    toggleSidebar={toggleSidebar}
                    toggleMobileSidebar={toggleMobileSidebar}
                    toggleTheme={toggleTheme}
                    isCollapsed={isCollapsed}
                    isDarkMode={isDarkMode}
                />
                <div id='content' className="container-fluid mt-0">
                    <div className="row">
                        <div className="col-12">
                            <h1 className='title margin-y-24px'><span>Organizations</span><span className='subroute'>/</span><span className='subroute'>My Organization</span></h1>
                            <div className='customBorder' />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <p className="subtitle mt-3">Create a Organization</p>
                            <div className="organizationWrapper">
                                <p className="subtitle m-0">Add Organization to your Account</p>
                                <p className="legend">Choose your package and continue to checkout.</p>

                                <div className="customBorder"></div>

                                <p className="subtitle mb-0 mt-4">Add One Slot</p>
                                <p className="legend">One Organization Slot</p>

                                <p className="title">$25.00 USD/mo*</p>
                                <p className="legend">*Taxes and fees may apply</p>

                                {!isUpgradeSelected ? (
                                    <Button variant="primary" onClick={handleUpgradeClick}>
                                        Upgrade Now
                                    </Button>
                                ) : (
                                    <p className="legend c-blue"><img src={checkMark} className='mr-2' alt="checkmark" />Selected</p>
                                )}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <p className="subtitle mt-3">Payment</p>
                            <div className="organizationWrapper" style={{ opacity: isPaymentActive ? 1 : 0.5 }}>
                                <p className="subtitle m-0">One Organization Slot</p>
                                <p className="legend">Review your payment and proceed.</p>

                                <div className="customBorder"></div>

                                <p className="subtitle mb-0 mt-4">Add One Slot</p>
                                <p className="legend">One Organization Slot</p>


                                <div className="d-flex align-items-center mb-3">
                                    <p className="subtitle mb-0">Annual Payment</p>
                                    <button onClick={togglePlan} className="toggle-button ml-3" style={{ border: 'none', background: 'none' }}>
                                        <img src={annuallyPlan ? toggleRight : toggleLeft} alt="Toggle Icon" />
                                    </button>
                                </div>

                                {/* Condicional para alternar entre PaymentMethod y PaymentForm */}
                                {showPaymentForm ? (
                                    <PaymentForm
                                        onCancel={handleCancelPaymentForm}
                                        onSubmit={handleCancelPaymentForm}
                                    />
                                ) : (
                                    <PaymentMethod onAdd={handleAddPaymentMethod} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
