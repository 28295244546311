import React from 'react';
import './chapter.css'
const Chapter9_20 = () => (
    <div>
        <h2>Slices</h2>
        <p>
            A slice selects a range of elements in a sequence object (e.g., a string, tuple, or list). Slices can be used as expressions or as targets in assignments or statements. The syntax for a slice is as follows:
        </p>
        <pre>
            <code>
{`slicing      ::=  primary "[" slice_list "]"
slice_list   ::=  slice_item ("," slice_item)* [","]
slice_item   ::=  expression | proper_slice
proper_slice ::=  [lower_bound] ":" [upper_bound] [ ":" [stride] ]
lower_bound  ::=  expression
upper_bound  ::=  expression
stride       ::=  expression
`}
            </code>
        </pre>
        <p>
            There is ambiguity in the formal syntax here: anything that looks like a list expression also looks like a list slice, so any subscription might be interpreted as a slice. Instead of complicating the syntax further, this is disambiguated by defining that in this case, the interpretation as a subscription takes precedence over the interpretation as a slice (this is the case if the list slice does not contain a proper slice).
        </p>
        <p>
            The semantics for a slice are as follows. The primary is indexed (using the same <code>__getitem__()</code> method as in a normal subscription) with a key constructed from the slice list, as follows. If the slice list contains at least one comma, the key is a tuple that contains the conversion of the slice elements; otherwise, the conversion of the single slice element is the key. The conversion of a slice element that is an expression is that expression. The conversion of a proper slice is a <code>slice</code> object whose start, stop, and step attributes are the values of the expressions given as the lower bound, upper bound, and step, respectively, substituting <code>None</code> for missing expressions.
        </p>
    </div>
);

export default Chapter9_20;
