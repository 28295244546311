import React, { useEffect, useRef } from "react";
import EasyMDE from "easymde";
import "easymde/dist/easymde.min.css";

const MarkdownEditor = ({ value, onChange }) => {
  const editorRef = useRef(null);
  const easyMDERef = useRef(null);

  useEffect(() => {
    if (editorRef.current && !easyMDERef.current) {
      easyMDERef.current = new EasyMDE({
        element: editorRef.current,
        initialValue: value || "",
        autofocus: true,
        placeholder: "Write your documentation here...",
        toolbar: [
          "bold",
          "italic",
          "heading",
          "|",
          "quote",
          "code",
          "table",
          "|",
          "preview",
        //   "side-by-side",
          "fullscreen",
          "|",
          "guide",
        ],
      });

      // Escucha los cambios en el editor y llama onChange
      easyMDERef.current.codemirror.on("change", () => {
        if (onChange) {
          onChange(easyMDERef.current.value());
        }
      });
    }

    // Limpieza en caso de que el componente se desmonte
    return () => {
      if (easyMDERef.current) {
        easyMDERef.current.toTextArea();
        easyMDERef.current = null;
      }
    };
  }, [onChange]);

  // Actualizar el valor del editor cuando la prop value cambie
  useEffect(() => {
    if (easyMDERef.current && value !== easyMDERef.current.value()) {
      easyMDERef.current.value(value || "");
    }
  }, [value]);

  return (
    <textarea
      ref={editorRef}
      style={{ display: "none" }}
    />
  );
};

export default MarkdownEditor;
