import React, { Fragment, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const DisplayCode2 = (props) => {
    const [codeToshow2, setCodeToshow2] = useState(props.codeToshow);
    const [copied, setCopied] = useState(false);

    const handleChangeCode1 = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = codeToshow2.options.find(option => option.id === selectedValue);
        setCodeToshow2({ ...codeToshow2, selected: selectedOption });
        setCopied(false);

    };
    // let codeToshow = props.codeToshow;

    return (
        <Fragment>
            <div className="backgroundHeader rounded-top p-2 px-3 d-flex justify-content-between">
                <div>Request</div>

                <div className='d-flex'>
                    <div className='pr-3'>
                        <select name="select" className='customSelectCode rounded' onChange={handleChangeCode1}>
                            {codeToshow2.options.map(option => (
                                <option key={option.id} value={option.id} className="text-capitalize">{option.id}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-top"> {copied ? "copied" : "copy"}</Tooltip>}
                        >
                            <CopyToClipboard text={codeToshow2.selected.code} onCopy={() => setCopied(true)}>
                                <div className='sizeClipBoard'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#ffffff"><path d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM96 424c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm96-192c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm128 368c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z" /></svg>
                                </div>
                            </CopyToClipboard>
                        </OverlayTrigger>
                    </div>

                </div>
            </div>
            <SyntaxHighlighter style={darcula} key={codeToshow2.selected.type} language={codeToshow2.selected.type} className="rounded-bottom m-0 backgroundCode" showLineNumbers wrapLines={true}>
                {codeToshow2.selected.code}
            </SyntaxHighlighter>
        </Fragment>
    )
};
export default DisplayCode2;
