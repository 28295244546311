import React, { Fragment } from 'react';
import './chapter.css'
const Infrastructure_AVS = () => (
  <Fragment>
        <div>
      <h3>What Will You Find in This Book?</h3>
      <p>
        In this book, we will guide you through the basic and advanced concepts of AVAP™, providing practical examples, useful tips, and challenging exercises to help you master the language and become an expert AVAP™ developer. From installing and configuring the development environment to creating complete applications, this book will accompany you every step of the way towards mastering AVAP™.
      </p>
    </div>
  </Fragment>
);
export default Infrastructure_AVS;
