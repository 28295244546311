import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';

const Cuadro_de_mando_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{ "status": <boolean>, 
        "structure": { 
          "section": [ 
            { "label": "altas", 
            "values": [ 
              [ "registration_date", <integer> ], 
              [ "registration_today", <integer> ], 
              [ "registration_variation", <string> ], 
              [ "users", <integer> ], 
              [ "average_registrations", <string> ] ] }, 
              { "label": "transacciones", "values": [ 
                [ "transaction_date", <integer> ], 
                [ "transactions_today", <integer> ], 
                [ "transaction_variation", <string> ], 
                [ "total_transactions", <integer> ], 
                [ "average_transactions", <string> ] ] }, 
                { "label": "volumen", "values": [ 
                  [ "total_transactions_date", <string> ], 
                  [ "income", <string> ], 
                  [ "discharge", <string> ], 
                  [ "margen", <string> ] ] }, ]
                  , 
                "date": <string>, 
                "start": <string>, 
                "today": <string> }, 
                "elapsed": <float> }`,
        copied: false
      },
      answe_ko2: {
        title:"Answer ok",
        type: "json",
        code: `
        { "status": <boolean>, 
        "level": <string>, 
        "message": <string>, 
        "error": <string> }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: ` import requests url = "URL_BASE/ws/admin.py/cuadrodemando?country_code=MX&user_id=4532&session_id=406-dwr5sTs_m29rnbzw9_miJQ==&date=20-12-2019" 
      payload = {} 
      files = {} 
      headers= {} 
      response = requests.request("GET", url, headers=headers, data = payload, files = files) 
      print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: ` import requests url = "URL_BASE/ws/admin.py/cuadrodemando?country_code=MX&user_id=4532&session_id=406-dwr5sTs_m29rnbzw9_miJQ==&date=20-12-2019" 
      payload = {} 
      files = {} 
      headers= {} 
      response = requests.request("GET", url, headers=headers, data = payload, files = files) 
      print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: ` var request = require('request');
    var options = {
    'method': 'GET',
    'url': 'URL_BASE/ws/admin.py/cuadrodemando?country_code=MX&user_id=4532&session_id=406-dwr5sTs_m29rnbzw9_miJQ==&date=20-12-2019',
    'headers': {},
    formData: {}
    };
    request(options, function (error, response) { 
    if (error) throw new Error(error);
    console.log(response.body);
    });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: ` var formdata = new FormData();
    var requestOptions = {
    method: 'GET',
    body: formdata,
    redirect: 'follow'
    };
    fetch("URL_BASE/ws/admin.py/cuadrodemando?country_code=MX&user_id=4532&session_id=406-dwr5sTs_m29rnbzw9_miJQ==&date=20-12-2019", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request GET 'URL_BASE/ws/admin.py/cuadrodemando?country_code=MX&user_id=4532&session_id=406-dwr5sTs_m29rnbzw9_miJQ%3D%3D&date=20-12-2019'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="control-panel">Control Panel</h1>
        <p>Obtain everything needed to display the dashboard.</p>
        <p>
          <span className="label get">GET:</span>
          <span> </span> <code>URL_BASE + /ws/admin.py/cuadrodemando</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All parameters are sent in the querystring of the call, so a percentage
          encoding for URI must be applied &#40;aka URL encoding&#41;.
        </p>
        <div className='overflow-auto'>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  ISO 3166 alpha-2
                </a>
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">Identifier of the user making the request</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">date</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                Date with which the information will be compared
              </td>
              <td align="center">N/A</td>
            </tr>
          </tbody>
        </table>
        </div>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        
        <h2 id="where">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>structure:</code> Structure that contains everything necessary to
            be able to show all the information of the control panel.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>section:</code> Object that contains each of the control panel
            sections.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>label:</code> informs about the information level of the following
            data.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>values:</code> List of values in the section, each value is a list
            that follows the format [0] key and [1] value.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>registration_date:</code> User registrations produced on the
            indicated date.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>registration_today:</code> User registrations produced today.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>registration_variation:</code> Percentage of variation between the
            indicated date and today, of the number of user registrations.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>users:</code> Total number of platform users.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>average_registrations:</code> Relationship between the total
            number of users and the total days of operation of the platform.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>values:</code> List of values in the section, each value is a list
            that follows the format [0] key and [1] value.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>transaction_date:</code> Number of transactions on the indicated
            date.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>transactions_today:</code> Number of transactions today.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>transaction_variation:</code> Percentage of variation between the
            indicated date and today, of the number of transactions.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>total_transactions:</code> Total number of platform transactions.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>average_transactions:</code> Ratio between the total number of
            transactions and the total days of operation of the platform.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>values:</code> List of values in the section, each value is a list
            that follows the format [0] key and [1] value.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>total_transactions_date:</code> Total amount transacted from the
            date indicated until today.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>income:</code> Commissions charged from the date indicated until
            today.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>discharge:</code> Commissions charged from the date indicated
            until today.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>margen:</code> Difference between income and expenses.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>date:</code> Date in the format dd-mm-yyyy, which has been used to
            compare the data with today&#39;s date.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>start:</code> Date in dd-mm-yyyy format, to be used to obtain the
            data from the beginning of the platform.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>today:</code> Today&#39;s date in dd-mm-yyyy format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko2}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>level:</code> Error importance level.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message</code>: Error message.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          In order to run this service it is necessary to do it from a user logged
          into the system who has an administrator profile. With this endpoint,
          general information is obtained, relative to the volume of transactions,
          user registrations and money movements, comparing the data of the entered
          date, with the current date and the general one from a date prior to the
          start-up of the product.
        </p>
        </div>

      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
        </div>
      </Fragment>
    );
  }
export default Cuadro_de_mando_EN;
