import React, { Fragment } from 'react';
import { Typography } from 'antd';
import { CodeOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const RequestSample = () => (
  <Fragment>
 
 <Typography>
    <Title level={1}>HTTP GET Request Example</Title>
    <Paragraph>
      This example performs an HTTP GET request to a specific endpoint (<code>url</code>) using a set of query parameters (<code>querystring</code>) and headers (<code>headers</code>). The parameters and headers are stored in JSON objects before being sent with the request. Finally, the result of the request is returned as a response.
    </Paragraph>

    <Title level={2}>Inputs:</Title>
    <ul>
      <li><strong>url</strong>: The web service URL where the request is made.</li>
      <li><strong>param</strong>: The query key parameter (in this case, <code>'all'</code>).</li>
      <li><strong>param_value</strong>: The value of the query parameter (in this case, <code>'False'</code>).</li>
      <li><strong>header_1</strong>: The HTTP header key (in this case, <code>'101OBeXAPIKey'</code>).</li>
      <li><strong>header_value_1</strong>: The value of the HTTP header (in this case, <code>'N94c7d1H66oBC7y3rRUpik0EVOum9fCl'</code>).</li>
    </ul>

    <Title level={2}>Steps:</Title>
    <ol>
      <li>
        <strong>addVar(url, 'docking.101obex.mooo.com/ws/util.py/paises')</strong>
        <Paragraph>
          The web service URL is assigned to the <code>url</code> variable.
        </Paragraph>
      </li>
      <li>
        <strong>addVar(param, 'all')</strong>
        <Paragraph>
          The query key parameter <code>'all'</code> is assigned to the <code>param</code> variable.
        </Paragraph>
      </li>
      <li>
        <strong>addVar(param_value, 'False')</strong>
        <Paragraph>
          The query parameter value <code>'False'</code> is assigned to the <code>param_value</code> variable.
        </Paragraph>
      </li>
      <li>
        <strong>AddvariableToJSON(param, param_value, querystring)</strong>
        <Paragraph>
          The parameter <code>param</code> and its value <code>param_value</code> are added to the <code>querystring</code> JSON object, which will be used in the GET request.
        </Paragraph>
      </li>
      <li>
        <strong>addVar(header_1, '101OBeXAPIKey')</strong>
        <Paragraph>
          The HTTP header key <code>'101OBeXAPIKey'</code> is assigned to the <code>header_1</code> variable.
        </Paragraph>
      </li>
      <li>
        <strong>addVar(header_value_1, 'N94c7d1H66oBC7y3rRUpik0EVOum9fCl')</strong>
        <Paragraph>
          The value for the HTTP header <code>'101OBeXAPIKey'</code> is assigned to the <code>header_value_1</code> variable.
        </Paragraph>
      </li>
      <li>
        <strong>AddvariableToJSON(header_1, header_value_1, headers)</strong>
        <Paragraph>
          The header key <code>header_1</code> and its value <code>header_value_1</code> are added to the <code>headers</code> JSON object, which will be included in the GET request.
        </Paragraph>
      </li>
      <li>
        <strong>RequestGet(url, querystring, headers, result)</strong>
        <Paragraph>
          An HTTP GET request is made to the URL stored in <code>url</code>, using the query parameters (<code>querystring</code>) and headers (<code>headers</code>). The result of the request is stored in the <code>result</code> variable.
        </Paragraph>
      </li>
      <li>
        <strong>addResult(result)</strong>
        <Paragraph>
          The result of the GET request is returned.
        </Paragraph>
      </li>
    </ol>

    <Title level={2}>Result:</Title>
    <Paragraph>
      The result is a JSON object containing the web service's response. Depending on the service's implementation, the result may include information about countries or other related data.
    </Paragraph>

    <div style={{ backgroundColor: '#f5f5f5', padding: '16px', borderRadius: '8px' }}>
      <pre>
        <code>
{`{
  "status": true,
  "data": [
    {
      "country": "Mexico",
      "code": "MX"
    },
    {
      "country": "United States",
      "code": "US"
    }
  ]
}`}
        </code>
      </pre>
    </div>

    <Title level={2}>Usage Example:</Title>
    <div style={{ backgroundColor: '#f5f5f5', padding: '16px', borderRadius: '8px' }}>
      <pre>
        <code>
{`addVar(url, 'docking.101obex.mooo.com/ws/util.py/paises')
addVar(param, 'all')
addVar(param_value, 'False')
AddvariableToJSON(param, param_value, querystring)
addVar(header_1, '101OBeXAPIKey')
addVar(header_value_1, 'N94c7d1H66oBC7y3rRUpik0EVOum9fCl')
AddvariableToJSON(header_1, header_value_1, headers)
RequestGet(url, querystring, headers, result)
addResult(result)`}
        </code>
      </pre>
    </div>

    <Title level={2}>Notes:</Title>
    <Paragraph>
      The <code>url</code> endpoint must be accessible and correctly configured to accept the provided query parameters and headers.
      The <code>'101OBeXAPIKey'</code> header seems to be an API key used for authentication, indicating that this service requires authentication to make requests.
    </Paragraph>
  </Typography>
  </Fragment>
);
export default RequestSample;
