import React from 'react';
import './chapter.css'
const Chapter9_47 = () => (
    <div>
        <h2>Value Patterns</h2>
        <p>
            In AVAP, value patterns are used to match specific values. The syntax for a value pattern is:
        </p>
        <pre><code>value_pattern ::=  attr</code></pre>
        <p>
            Value patterns only succeed if the subject's value matches the specified value. They are useful when you want to perform actions based on an exact value.
        </p>
        <p>
            Here’s how you might use value patterns in AVAP:
        </p>
        <pre><code>match value:
    case 42:
        print("Matched the value 42")
    case "hello":
        print("Matched the string 'hello'")
    case _:
        print("Matched something else")</code></pre>
        <p>
            In this example:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>case 42:</code> matches the value 42 specifically.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>case "hello":</code> matches the string "hello" specifically.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>case _:</code> matches any other value not covered by the previous cases.</li>
        </ul>
        <p>
            Value patterns are ideal for scenarios where you need to check for specific values and respond accordingly. They provide precise control over the matching process.
        </p>
    </div>
);

export default Chapter9_47;
