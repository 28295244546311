import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';

const Actividad_del_usuario_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{
           "status": true, 
           "activity": {
             "items": [
               { 
                "date": <string>,
                 "hour": <string>,
                  "service_id": <integer>,
                   "service": <string>, 
                   "point_of_entry": <string>, 
                   "affiliate": <string>,
                   "photo_id": <integer>, 
                   "repeatable": <boolean>,
                   "repeat_transaction": <boolean>,
                   "reference": <string>, 
                   "invoice": <integer>, 
                   "commission": <decimal>,
                    "commission_fmt": <string>,
                     "local_commission": <decimal>, 
                     "local_commission_fmt": <string>,
                      "valid_commission": <boolean>, 
                      "receptor": <string>,
                       "amount": <decimal>,
                        "amount_fmt": <string>,
                         "amount_fmt_compact": <string>,
                          "amount_local": <decimal>, 
                          "amount_local_fmt": <string>, 
                          "currency": <string>, 
                          "local_currency": <string>, 
                          "description": <string>,
                           "other_data": {
                             "phone_origin": <string>,
                              "phone_destination": <string>
                             }, 
                             "change": <decimal>, "channnel_id": <integer>,
                              "channel": <string>, "status": <integer>,
                               "concept": <string>, "codtran": <string>, 
                               "origin_of_funds": <string> 
                              } ], 
                              "there_is_more": <boolean> 
                            }, 
                            "elapsed": <float> 
                          }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{
           "status": false, 
        "level": <string>, 
        "message": <string>, 
        "error": <string> 
      }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requestsurl = URL_BASE/ws/movimientos.py/activity 
        payload = {}
         headers= {}
         response = requests.request =("GET", url, headers=headers, data = payload)
        print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requestsurl = URL_BASE/ws/movimientos.py/activity 
          payload = {}
           headers= {}
           response = requests.request =("GET", url, headers=headers, data = payload)
          print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'GET',
              'url': 'URL_BASE/ws/movimientos.py/activity?country_code=MX&user_id=78&session_id=388-481WXSHIEDX7ba5MWcFXCA==&startdate=1-04-2020&enddate=29-05-2020',
              'headers': {
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var requestOptions = {
                method: 'GET',
                redirect: 'follow'
                };
                fetch("URL_BASE/ws/movimientos.py/activity?country_code=MX&user_id=78&session_id=388-481WXSHIEDX7ba5MWcFXCA==&startdate=1-04-2020&enddate=29-05-2020", requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl  --location  --request GET 'URL_BASE/ws/movimientos.py/activity?country_code=MX&user_id=78&session_id=388-481WXSHIEDX7ba5MWcFXCA%3D%3D&startdate=1-04-2020&enddate=29-05-2020'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="user-activity-log">User Activity Log</h1>
        <p>
          This service is used to obtain a summary of the different services that
          the user has used.
        </p>
        <p>
          <span className="label get">GET:</span>
          <span> </span> <code>URL_BASE + /ws/movimientos.py/activity</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All parameters are sent in the querystring of the call, so a percentage
          encoding for URI must be applied &#40;aka URL encoding&#41;.
        </p>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  ISO 3166 alpha-2
                </a>
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Identifier of the user making the request&#40;Admin or
                Affiliate&#41;
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Acess
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Acess
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">number_of_files</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Number of movements that we want to visualize per page.
              </td>
              <td align="center">10</td>
            </tr>
            <tr>
              <td align="left">page</td>
              <td align="center">Integer</td>
              <td align="center">No</td>
              <td align="center">Page we want to get.</td>
              <td align="center">1</td>
            </tr>
            <tr>
              <td align="left">startdate</td>
              <td align="center">
                String &#40;dd/mm/yyyy, dd-mm-yyyy, yyyy/mm/dd, yyyy-mm-dd&#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">
                Date from which the movements begin to be posted.
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">enddate</td>
              <td align="center">
                String &#40;dd/mm/yyyy, dd-mm-yyyy, yyyy/mm/dd, yyyy-mm-dd&#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Date until which the movements are posted.</td>
              <td align="center">N/A</td>
            </tr>
          </tbody>
        </table>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h3 id="where">Where:</h3>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>activity:</code> The container for each of the user&#39;s
            activities.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>items:</code> A list of objects that represent each of the
            user&#39;s activities.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>date:</code> Timestamp, of the moment in which the operation was
            carried out.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>hour:</code> Timestamp, of the moment in which the operation was
            carried out.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>service_id:</code> Identifier of the service used.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>service:</code> Name of the service used.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>point_of_entry:</code> Name of the action associated with the
            service used.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>affiliate:</code> Name of the affiliate of the operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>photo_id:</code> Image ID of the user, who performed the
            operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>repeatable:</code> Boolean, which indicates whether this operation
            can be repeated by the endpoint &#40;<code>repeattransaction</code>
            &#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>repeat_transaction:</code> Boolean, which indicates whether this
            operation can be repeated by the endpoint &#40;
            <code>repeattransaction</code>&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>reference:</code> Indicates the reference of the operation, if
            any.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>invoice:</code> Invoice number associated with the operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>commission:</code> Indicates the commission charged to the user.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>commission_fmt:</code> Indicates the commission charged to the
            user applying the currency format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>local_commission:</code> Indicates the commission charged to the
            user.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>local_commission_fmt:</code> Indicates the commission charged to
            the user.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>valid_commission:</code> Indicate if the commission is valid to
            show it on the front or not.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>receptor:</code> Receiving user name.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>amount:</code> Amount associated with the operation not including
            the commission.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>amount_fmt:</code> Amount associated with the operation without
            including the commission, applying the currency format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>amount_fmt_compact:</code> Amount associated with the operation
            without including the commission, applying the currency format without
            including the suffix.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>amount_local:</code> Amount associated with the operation not
            including the commission.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>amount_local_fmt:</code> Amount associated with the operation
            without including the commission, applying the currency format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency:</code> Abbreviation of the currency used.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>local_currency:</code> Abbreviation of the currency used.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>description:</code> Name of the operation performed in the system.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>other_data:</code> Object containing other data associated with
            the operation performed.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>phone_origin:</code> Telephone number from which the operation
            starts.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>phone_destination:</code> Telephone number to which the operation
            is directed.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>change:</code> Value with which the currency exchange is
            calculated &#40;if any&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>channnel_id:</code> Channel identifier from which the operation is
            made.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>channel:</code> Name of the channel from which the operation was
            performed.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Operation status, it can take the following values:
            <ul>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>-3, Wrong</li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>-2, Rejected</li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>-1, Expired</li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>0, Requested</li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>1, Approved</li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>2, Revoked</li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>3, Totally returned</li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>4, Partially returned</li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>10, In process</li>
            </ul>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>concept:</code> Text entered by the user in the operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>codtran:</code> Transaction code that identifies the executed
            operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>origin_of_funds:</code> Name of the source of funds in which the
            charge / credit has been made.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>there_is_more:</code> Boolean that indicates if the user has more
            activities.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code>Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h3 id="where-1">Where:</h3>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>level:</code> Error importance level.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code> Error message.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          It searches for the most relevant movements made by the user, and returns
          them between two given dates.
        </p>
        </div>
        <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
       
        </div>
        </div>
      </Fragment>
    );
  }
export default Actividad_del_usuario_EN;
