import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_3 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>stampToDatetime()</h2>
      <p>
        The <code>stampToDatetime()</code> command converts a timestamp value to a date and time according to a specified format, applying a possible time difference, and stores the result in a target variable. It is useful for manipulating and formatting time values into different representations.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>timestamp</strong> <br />
          Type: <code>var</code> <br />
          Description: A value representing a timestamp, which can be provided directly or through a variable. This value is the starting point for conversion to a date and time format.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Format</strong> <br />
          Type: <code>var</code> <br />
          Description: A format string that defines how the resulting date and time should be presented. This string follows the same conventions used in Python for formatting dates and times. Common symbols include:
          <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>%Y</code>: Year with four digits (e.g., 2024)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>%m</code>: Month with two digits (01 to 12)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>%d</code>: Day of the month with two digits (01 to 31)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>%H</code>: Hour in 24-hour format (00 to 23)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>%M</code>: Minutes (00 to 59)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>%S</code>: Seconds (00 to 59)</li>
          </ul>
          For example, the format <code>%Y-%m-%d %H:%M:%S</code> converts a timestamp into a string like <code>2024-08-25 14:30:00</code>. It can be a direct value or a variable containing the desired format.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>TimeDelta</strong> <br />
          Type: <code>var</code> <br />
          Description: An optional value representing a time adjustment (positive or negative) applied to the timestamp before conversion. This value can be provided directly or through a variable and is expressed in seconds.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>TargetVariable</strong> <br />
          Type: <code>var</code> <br />
          Description: The variable where the resulting date and time from the conversion will be stored. Unlike the other parameters, this must be a variable and not a direct value.
        </li>
      </ul>
      <h3>Usage Example</h3>
      <pre>
        <code>
          {`// Direct call with values:
            stampToDatetime(1692966600, '%Y-%m-%d %H:%M:%S', 3600, convertedDatetime)

            // Call using variables:
            timestamp = 1692966600
            format = '%Y-%m-%d %H:%M:%S'
            adjustment = 3600
            stampToDatetime(timestamp, format, adjustment, convertedDatetime)`}
        </code>
      </pre>
      <p>
        In the first example, a timestamp is converted to a date and time in the format <code>"%Y-%m-%d %H:%M:%S"</code>, applying a 3600-second (1-hour) adjustment, and the result is stored in the variable <code>convertedDatetime</code>. In the second example, variables are used to define the timestamp, format, and adjustment.
      </p>
    </div>
  </Fragment>
);

export default Appendices_3;
