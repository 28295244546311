import React, { Fragment } from 'react';
import './chapter.css'
const Infrastructure_AVS = () => (
  <Fragment>
         <div>
      <h3>1.2 Benefits of the Virtuality Attribute</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Flexibility:</strong> The ability to construct code in real-time provides significant flexibility in API development and management.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Agility:</strong> The capacity to adapt and evolve without the need for precompilation or distributed updates allows for greater agility in software development.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Simplified Maintenance:</strong> The development of dynamic virtual APIs simplifies the maintenance process, as changes do not need to be made to clients consuming those APIs.</li>
      </ul>
    </div>
  </Fragment>
);
export default Infrastructure_AVS;
