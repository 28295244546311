import React from 'react';
import './chapter.css'
const Chapter9_34 = () => (
    <div>
        <h2>Break Statement</h2>
        <p>
            In AVAP, the <strong>break</strong> statement is used to terminate the closest enclosing loop. The syntax for the <strong>break</strong> statement is as follows:
        </p>
        <pre>
            <code>
                break
            </code>
        </pre>
        <p>
            When a <strong>break</strong> statement is encountered, it causes the loop to exit immediately, regardless of the loop's condition or any remaining iterations. This effectively transfers control to the statement following the loop.
        </p>
        <p>
            The <strong>break</strong> statement is typically used within <code>for</code> or <code>while</code> loops to provide a way to exit the loop prematurely based on a certain condition.
        </p>
        <pre>
            <code>
                for i in range(10):
                    if i == 5:
                        break
                    print(i)
                print("Loop ended")
            </code>
        </pre>
        <p>
            In this example, the loop will terminate when <code>i</code> equals 5, and "Loop ended" will be printed. The numbers 0 through 4 will be printed before the loop is exited.
        </p>
    </div>
);

export default Chapter9_34;
