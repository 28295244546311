import React, { Fragment } from 'react';

const Autorizar_Solucion_EN = () => {


  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 id="authorize-user-of-solution">Authorize User of Solution</h1>
          <p>This service is used to add users to a system solution.</p>
          <p>
            <span className="label post">POST:</span>
            <span> </span>{' '}
            <code>URL_BASE + /ws/ws_soluciones.py/autorizar_solucion</code>
          </p>
          <h2 id="receives">Receives:</h2>
          <p>
            All the parameters that the service receives must be indicated in the body
            of the request.
          </p>
          <table>
            <thead>
              <tr>
                <th align="left">Parameter</th>
                <th align="center">Format</th>
                <th align="center">Obligatory</th>
                <th align="center">Description</th>
                <th align="center">Default value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="left">country_code</td>
                <td align="center">
                  <a href="https://www.iso.org/glossary-for-iso-3166.html">
                    ISO 3166 alpha-2
                  </a>
                </td>
                <td align="center">Yes</td>
                <td align="center">Country code in which the user resides</td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">user_id</td>
                <td align="center">Integer</td>
                <td align="center">Yes</td>
                <td align="center">
                  Identifier of the user making the request &#40;Admin or
                  Affiliate&#41;
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">session_id</td>
                <td align="center">String</td>
                <td align="center">
                  Yes &#40;if you do not send signature to &#39;Access
                  Service&#39;&#41;
                </td>
                <td align="center">
                  Obtained by identifying the user with ID user_id on the platform
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">signature</td>
                <td align="center">String</td>
                <td align="center">
                  Yes &#40;if you do not send session_id to &#39;Access
                  Service&#39;&#41;
                </td>
                <td align="center">
                  Enables you to sign the request without having to log in
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">phone</td>
                <td align="center">String</td>
                <td align="center">
                  No, if <code>destination_id</code> is not indicated
                </td>
                <td align="center">
                  Phone of the user who wants to join the solution
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">destination_id</td>
                <td align="center">Integer</td>
                <td align="center">
                  No, if <code>phone</code> is not indicated
                </td>
                <td align="center">
                  Identifier of the user who wants to join the solution
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">solution_id</td>
                <td align="center">Integer</td>
                <td align="center">Yes</td>
                <td align="center">
                  Identifier of the solution that the user is to join
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">pin</td>
                <td align="center">String</td>
                <td align="center">No</td>
                <td align="center">
                  Password of the user who wants to join the solution
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">revoke</td>
                <td align="center">Boolean</td>
                <td align="center">No</td>
                <td align="center">Reject the solution to the user</td>
                <td align="center">False</td>
              </tr>
              <tr>
                <td align="left">direct_signature</td>
                <td align="center">Boolean</td>
                <td align="center">No</td>
                <td align="center">
                  Indicates if the service requires a signature or not by the user
                </td>
                <td align="center">False</td>
              </tr>
            </tbody>
          </table>

        </div>
        <div className='col-md-5'>

        </div>

      </div>


    </Fragment>
  );
}
export default Autorizar_Solucion_EN;