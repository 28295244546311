import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';

const Dashboard_Afiliado_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{
          "status": <boolean>,
          "codtran": <string>,
          "result": {
          "point_summary": {
          "active": <integer>
          },
          "user_summary": {
          "total_number_of_users": <integer>,
          "frequent_users": [
          {
          "amount": <decimal>,
          "alias": <string>,
          "amount_fmt": <string>,
          "currency": <string>
          }
          ]
          },
          "resumen_ventas": [
          {
          "currency": <string>,
          "currency_id": <integer>,
          "sales": [
          {
          "amount": <decimal>,
          "year": <integer>,
          "day": <integer>,
          "month": <integer>,
          "amount_fmt": <string>
          }
          ]
          }
          ]
          },
          "elapsed": <float>
          }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{
          "status": false,
          "level": <string>,
          "message": <string>,
          "error": <string>
          }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests
        url = "URL_BASE/ws/afiliados.py/dashboardafiliado?country_code=MX&user_id=4532&session_id=442-4tXk7omYJZt7PGRAaToHVQ==&period=month&year=2020&month=7&day=7"
        payload = {}
        files = {}
        headers= {}
        response = requests.request("GET", url, headers=headers, data = payload, files = files)
        print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests
          url = "URL_BASE/ws/afiliados.py/dashboardafiliado?country_code=MX&user_id=4532&session_id=442-4tXk7omYJZt7PGRAaToHVQ==&period=month&year=2020&month=7&day=7"
          payload = {}
          files = {}
          headers= {}
          response = requests.request("GET", url, headers=headers, data = payload, files = files)
          print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'GET',
              'url': 'URL_BASE/ws/afiliados.py/dashboardafiliado?country_code=MX&user_id=4532&session_id=442-4tXk7omYJZt7PGRAaToHVQ==&period=month&year=2020&month=7&day=7',
              'headers': {},
              formData: {}
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var formdata = new FormData();
              var requestOptions = {
              method: 'GET',
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/afiliados.py/dashboardafiliado?country_code=MX&user_id=4532&session_id=442-4tXk7omYJZt7PGRAaToHVQ==&period=month&year=2020&month=7&day=7", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request GET 'URL_BASE/ws/afiliados.py/dashboardafiliado?country_code=MX&user_id=4532&session_id=442-4tXk7omYJZt7PGRAaToHVQ%3D%3D&period=month&year=2020&month=7&day=7'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="affiliate-dashboard">Affiliate Dashboard</h1>
        <p>This service is used to obtain the affiliate&#39;s dashboard.</p>
        <p>
          <span className="label get">GET:</span>
          <span> </span> <code>URL_BASE + /ws/afiliados.py/dashboardafiliado</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All parameters are sent in the querystring of the call, so a percentage
          encoding for URI must be applied &#40;aka URL encoding&#41;.
        </p>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                ISO 3166 alpha-2{' '}
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  ISO 3166 alpha-2
                </a>
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center"></td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Identifier of the user making the request &#40;Admin or
                Affiliate&#41;
              </td>
              <td align="center">N/A</td>
              <td align="center"></td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
              <td align="center"></td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
              <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
            </tr>
            <tr>
              <td align="left">period</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                Period of time in which we are going to make the consultation. Year
                &#40;year&#41;, month &#40;month&#41;, day &#40;day&#41;
              </td>
              <td align="center">N/A</td>
              <td align="center">day</td>
            </tr>
            <tr>
              <td align="left">year</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">Year in which we want to do the search</td>
              <td align="center">N/A</td>
              <td align="center">2019</td>
            </tr>
            <tr>
              <td align="left">month</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Month in which we want to do the search. value between 1 and 12
              </td>
              <td align="center">N/A</td>
              <td align="center">7</td>
            </tr>
            <tr>
              <td align="left">day</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">Day we want to do the search.</td>
              <td align="center">N/A</td>
              <td align="center">13</td>
            </tr>
          </tbody>
        </table>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>codtran:</code> Transaction code that identifies the executed
            operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>result:</code> Service answer.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>point_summary:</code> Container object for point programs.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>active:</code> Active point program count of the affiliate making
            the dashboard request.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>user_summary:</code> Container object for user data belonging to
            the affiliate community.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>total_number_of_users:</code> Number of users that belong to the
            affiliate&#39;s community.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>frequent_users:</code> List of users who have made the most
            transactions.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>amount:</code> Sum of the payments that the user has made.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>amount_fmt:</code> Amount formatted with the format of the
            currency used for payments.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency:</code> Currency with which payments have been made.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>resumen_ventas:</code> List of sales filtered by currency.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency:</code> Currency with which the sales were made.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency_id:</code> Identifier of the currency used.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>sales:</code> Sales list filtered by year, month or day.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>amount:</code> Accumulated amount of the month, day or hour,
            according to the filter made.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>year:</code> Year in which the transactions occurs.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>month:</code> Month in which the transactions occurs.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>day:</code> Day in which the transactions occurs.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>amount_fmt:</code> Amount formatted in the format of the currency
            used.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>level:</code> Error importance level.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code> Error message.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>Returns relevant information of the affiliate making the request.</p>
        </div>

      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
       
        </div>
        </div>
      </Fragment>
    );
  }
export default Dashboard_Afiliado_EN;
