import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h3>1.1.1 Integers (int)</h3>
  <p>
    Integers represent whole numbers without decimals. They can be positive, negative, or zero. In AVAP™, integers are defined using the <code>int</code> data type.
  </p>
  <pre>
    <code>
{`integer_number = 10`}
    </code>
  </pre>
</div>
  </Fragment>
);
export default Intro_1;
