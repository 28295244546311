import React, { useState } from 'react';

const SelectablePricingTable = () => {
    const [selectedPlan, setSelectedPlan] = useState("Professional");

    const handlePlanChange = (plan) => {
        setSelectedPlan(plan);
    };

    return (
        <div className="selectable-pricing-table">
            <div className="pricing-header">
                <div className={`plan ${selectedPlan === "Professional" ? "selected" : ""}`}>
                    <input
                        type="radio"
                        id="professional"
                        name="pricing-plan"
                        value="Professional"
                        checked={selectedPlan === "Professional"}
                        onChange={() => handlePlanChange("Professional")}
                    />
                    <label htmlFor="professional">
                        <h3>Professional</h3>
                        <p className="price">$500 /month</p>
                        <p>For small API projects</p>
                    </label>
                </div>
                <div className={`plan ${selectedPlan === "Business" ? "selected" : ""}`}>
                    <input
                        type="radio"
                        id="business"
                        name="pricing-plan"
                        value="Business"
                        checked={selectedPlan === "Business"}
                        onChange={() => handlePlanChange("Business")}
                    />
                    <label htmlFor="business">
                        <h3>Business</h3>
                        <p className="price">$2000 /month</p>
                        <p>For teams with moderate API traffic</p>
                    </label>
                </div>
                <div className={`plan ${selectedPlan === "Enterprise" ? "selected" : ""}`}>
                    <input
                        type="radio"
                        id="enterprise"
                        name="pricing-plan"
                        value="Enterprise"
                        checked={selectedPlan === "Enterprise"}
                        onChange={() => handlePlanChange("Enterprise")}
                    />
                    <label htmlFor="enterprise">
                        <h3>Enterprise</h3>
                        <p className="price">Contact us</p>
                        <p>Custom solutions for high-demand APIs</p>
                    </label>
                </div>
            </div>
            <div className="pricing-body">
                {selectedPlan === "Professional" && (
                    <div className="feature-details">
                        <p>API Calls: 50 million</p>
                        <p>Data Storage: 500 GB</p>
                        <p>Support: Standard</p>
                        <p>API Rate Limit: 1000 requests/min</p>
                        <p>SLA: 99.5%</p>
                    </div>
                )}
                {selectedPlan === "Business" && (
                    <div className="feature-details">
                        <p>API Calls: 500 million</p>
                        <p>Data Storage: 5 TB</p>
                        <p>Support: Premium</p>
                        <p>API Rate Limit: 5000 requests/min</p>
                        <p>SLA: 99.9%</p>
                    </div>
                )}
                {selectedPlan === "Enterprise" && (
                    <div className="feature-details">
                        <p>API Calls: Custom</p>
                        <p>Data Storage: Custom</p>
                        <p>Support: Dedicated</p>
                        <p>API Rate Limit: Custom</p>
                        <p>SLA: 99.99%</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SelectablePricingTable;
