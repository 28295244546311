import React, { Fragment } from 'react';
import './chapter.css'
const Chapter7_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h1>How to Work with Comments</h1>
      <p>
        Comments are a fundamental tool in any programming language, as they allow you to document code, make it easier to understand, and help keep it organized. In the AVAP™ programming language, comments are an integral part of the syntax and are used to add additional information to the source code without affecting its execution.
      </p>
      <p>
        Comments serve several purposes:
      </p>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Documentation:</strong> Comments can be used to explain what specific parts of the code do, which can be helpful for anyone reading or maintaining the code in the future.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Clarification:</strong> They can clarify complex sections of code, making it easier for others (or yourself) to understand the logic and flow of the program.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Organization:</strong> Comments can help organize code by separating different sections or explaining the purpose of various code blocks.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Debugging:</strong> Comments can temporarily disable parts of code during debugging without deleting it, allowing you to test different scenarios.</li>
      </ul>
      <p>
        In AVAP™, you can use different types of comments to suit your needs. They can be single-line comments or multi-line comments, depending on the level of detail and context required.
      </p>
      <p>
        By incorporating comments into your code, you make it more maintainable and easier for others to follow, which is essential for collaborative projects and long-term code management.
      </p>
    </div>
  </Fragment>
);

export default Chapter7_1;
