import React from 'react';
import './chapter.css'
const Chapter9_30 = () => (
    <div>
        <h2>Expression Statements</h2>
        <p>
            Expression statements are used (mostly interactively) to compute and write a value, or (usually) to call a method (a function that does not return a meaningful result; in Python, methods return the value <code>None</code>). Other uses of expression statements are allowed and occasionally useful. The syntax for an expression statement is:
        </p>
        <pre>
            <code>
                expression_stmt ::=  starred_expression
            </code>
        </pre>
        <p>
            An expression statement evaluates the list of expressions (which can be a single expression).
        </p>
        <p>
            In interactive mode, if the value is not <code>None</code>, it is converted to a string using the built-in function <code>repr()</code>, and the resulting string is written to the standard output on a line by itself (except if the result is <code>None</code>, in which case the called procedure produces no output).
        </p>
    </div>
);

export default Chapter9_30;
