import React from 'react';
import './chapter.css'
const Chapter13_6 = () => (
  <div>
    <h2>Common Uses</h2>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Including Standard Libraries:</strong> Standard libraries that provide common functions and utilities can be included to simplify application development.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Including Definition Files:</strong> Files containing definitions of variables, constants, or data structures used in multiple parts of the project can be included.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Including Specific Functionality Modules:</strong> Modules providing additional features for the project, such as file handling, text processing, or data manipulation, can be included.</li>
    </ul>
  </div>
);

export default Chapter13_6;
