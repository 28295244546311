import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../components/DisplayCodeKo/DisplayCodeKo';

const Listar_Programa_de_Puntos_EN = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status": <boolean>,
        "result": [{
            "point_value": <decimal>,
            "number_of_users": <integer>,
            "amount": <integer>,
            "maximum_gifts": <integer>,
            "sms_user_gift": <string>,
            "points_redeemed": <integer>,
            "services": [
                <string>
            ],
            "from": <string>,
            "until": <string>,
            "name": <string>,
            "solution_id": <integer>,
            "users": <integer>,
            "active": <boolean>,
            "conditions": <string>,
            "points_for_gift": <integer>
        }],
        "elapsed": <float>,
        "codtran": <string>
    }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
    url = "URL_BASE/ws/programadepuntos.py/listado?country_code=MX&user_id=4532&session_id=400-nnpRvOwTzJejvCUfGxXQOA=="
    payload = {}
    files = {}
    headers= {}
    response = requests.request("GET", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
    url = "URL_BASE/ws/programadepuntos.py/listado?country_code=MX&user_id=4532&session_id=400-nnpRvOwTzJejvCUfGxXQOA=="
    payload = {}
    files = {}
    headers= {}
    response = requests.request("GET", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'GET',
  'url': 'URL_BASE/ws/programadepuntos.py/listado?country_code=MX&user_id=4532&session_id=400-nnpRvOwTzJejvCUfGxXQOA==',
  'headers': {
  },
  formData: {}
  };
  request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
  });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var formdata = new FormData();
  var requestOptions = {
  method: 'GET',
  body: formdata,
  redirect: 'follow'
  };
  fetch("URL_BASE/ws/programadepuntos.py/listado?country_code=MX&user_id=4532&session_id=400-nnpRvOwTzJejvCUfGxXQOA==", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request GET 'URL_BASE/ws/programadepuntos.py/listado?country_code=MX&user_id=4532&session_id=400-nnpRvOwTzJejvCUfGxXQOA%3D%3D'`
      }
    ]
  }

  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >List of Point Programs</h1>
          <p>
            This service is used to list the point programs that belong to the user of
            the session.
          </p>
          <p>
            <span className="label get">GET:</span>
            <span> </span> <code>URL_BASE + /ws/programadepuntos.py/listado</code>
          </p>
          <h2 >Receives:</h2>
          <p>
            All the parameters that the service receives must be indicated in the body
            of the request.
          </p>
          <div className='overflow-auto'>

            <table>
              <thead>
                <tr>
                  <th align="left">Parameter</th>
                  <th align="center">Format</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="left">country_code</td>
                  <td align="center">
                    <a href="https://www.iso.org/glossary-for-iso-3166.html">
                      ISO 3166 alpha-2
                    </a>
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">Country code in which the user resides</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">user_id</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">Identifier of the user making the request</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">session_id</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send signature to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Obtained by identifying the user with ID user_id on the platform
                  </td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">signature</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send session_id to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Enables you to sign the request without having to log in
                  </td>
                  <td align="center">N/A</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSONs:
          </p>
          <h3 >Answer JSON OK:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2 >Where:</h2>
          <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>result:</code> List with each of the point programs of the user
              who has made the request.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>point_value:</code> Value in pesos of 1 point.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>number_of_users:</code> Number of users that belong to the points
              program.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>amount:</code> Amount associated with the operation not including
              the commission.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>maximum_gifts:</code> Maximum number of gifts to be distributed
              with the points program.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>sms_user_gift:</code> Text of the sms that will be sent to the
              users of the points program &#40;Maximum 140 characters&#41;
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>points_redeemed:</code> Number of points that have been redeemed
              by users.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>services:</code> List of the names of services on which points are
              generated.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>from:</code> End date of validity of the points program.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>until:</code> Initial validity date of the points program.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>name:</code> Points program name.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>solution_id:</code> Points program identifier.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>users:</code> Total number of platform users included in this
              program.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>active:</code> Shows if the points program is active or not.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>conditions:</code> Text of legal conditions to show to users who
              join the points program &#40;Maximum 140 characters&#41;
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>points_for_gift:</code> Number of points needed to get a gift.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>elapsed:</code> Operation execution time.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>codtran:</code> Transaction code that identifies the executed
              operation.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Business logic:</h2>
          <p>
            This service returns a list with all the points programs of the user who
            makes the request.
          </p>

        </div>
        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>

      </div>

    </Fragment>
  );
}
export default Listar_Programa_de_Puntos_EN;
