import React, { Fragment } from 'react';
import './chapter.css'
const Chapter11_4 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>7.3 Example of Use</h2>
      <p>
        Below is an example of using the loop statement in AVAP™, along with a detailed explanation of each part of the code:
      </p>
      <pre>
        <code>
          {`// Loop Sample Use
            // Initialize the variable 'variable' with the value 5.
            addVar(variable,5)
            // Start the loop with the control variable 'control', ranging from 1 to 5.
            startLoop(control,1,5)
            // In each iteration of the loop, assign the current value of 'control' to the variable 'counter'.
            addVar(counter,$control)
            endLoop()
            // Add the final value of 'counter' to the API result.
            addResult(counter)`}
        </code>
      </pre>
    </div>
  </Fragment>
);

export default Chapter11_4;
