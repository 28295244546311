import React, { useEffect, useState } from 'react';  
import { BrowserView, MobileView } from 'react-device-detect';  
import TopMenuBar from '../landing_framework/TopMenuBar';  
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';  
import Disclaimer from '../../components/mobileComponents/DisclaimerCloud';  
// import SwaggerUI from 'swagger-ui';  
// import 'swagger-ui/dist/swagger-ui.css';
import { useHistory, useRouteMatch } from 'react-router-dom';  
  
//import './swaggerView.css'; // Import the styles  
  
const SwaggerView = () => {  
   
  
   return (  
      <>  
        <BrowserView>  

       {/*} <SwaggerUI url="https://petstore.swagger.io/v2/swagger.json" />  */}
        </BrowserView>  
        <MobileView>  
           <Disclaimer />  
        </MobileView>  
      </>  
   );  
};  
  
export default SwaggerView;
