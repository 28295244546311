import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';

const Alta_baja_modificacion_EN = () => 
  {
    const codes ={
      answe_return1: {
        title:"Answer ok",
        type: "json",
        code: `{  
          "status": true, 
          "codtran": <str>, "result":{  
            "user_id_registration": <int>, 
            "longitud_otp": <int> }, 
            "elapsed": <float> }`,
        copied: false
      },
      answe_where1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": false, 
          "level": <str>, 
          "message": <str>, 
          "error": <str> 
        }`,
        copied: false
      },
      answe_return2: {
        title:"Answer ok",
        type: "json",
        code: `{
          "status": true,
          "codtran": <str>,
          "unsibscription_date": <date>,
          "unsubscribed_user": <dict>,
          "elapsed": <float>
          }`,
        copied: false
      },
      answe_where2: {
        title:"Answer ok",
        type: "json",
        code: `{
          "status": false,
          "level": <str>,
          "message": <str>,
          "error": <str>
          }`,
        copied: false
      },
      answe_return3: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": true, 
          "codtran": <str>, 
          "datos_cambiados": <dict>, 
          "elapsed": <float> }`,
        copied: false
      },
      answe_where3: {
        title:"Answer ok",
        type: "json",
        code: `{  
          "status": false, 
          "level": <str>, 
          "message": <str>, 
          "error": <str> }`,
        copied: false
      },
      answe_example: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": true,  
          "codtran": "7a8b534f90637a4e0ceea3690b90427e",  
          "result": { "user_id_registration": 22, "longitud_otp": 4 }, 
          "elapsed": 2.592542886734009}`,
        copied: false
      }
    };
    return(
      <Fragment>
        
        <h1 id="registration-and-modification-services">
          <strong>Registration and Modification Services</strong>
        </h1>
        <h1 id="1-versions">1. VERSIONS</h1>
        <table>
          <thead>
            <tr>
              <th align="left">Version</th>
              <th align="center">
                <strong>In Charge</strong>
              </th>
              <th align="center">
                <strong>Date</strong>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">0.1: Initial Version</td>
              <td align="center">IT Waynnovate</td>
              <td align="center">12/03/2020</td>
            </tr>
          </tbody>
        </table>
        <h1 id="2-services">2. SERVICES</h1>
        <ol>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>USER REGISTRATION</p>
            <h2 id="service-identifer">Service identifer</h2>
            <p>
              {' '}
              <em>SER.EXT.ALT.PDM.001 User registration.</em>
            </p>
            <h2 id="description">Description</h2>
            <p>
              {' '}
              This service allows creating new accounts with enormous flexibility,
              being able to specify almost all the data belonging to the new user.
            </p>
            <h2 id="endpoints">
              <em>Endpoints</em>
            </h2>
            <ul>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <p>Preproduction</p>
                <p>
                  <code>
                    https://impulso-prews.pademobile.com/ws/cuentas.py/alta
                  </code>
                </p>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <p>Production</p>
                <p>
                  <code>https://www.pademobile.com:50/ws/cuentas.py/alta</code>
                </p>
                <h2 id="parameters">Parameters</h2>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <h3 id="receives">Receives</h3>
                <p>
                  To exploit this service, only two parameters are required:{' '}
                  <em>codigo_país</em> and <em>phone</em>. The rest of the
                  parameters allow you to configure the account in greater depth
                  according to its purpose.
                </p>
                <p>
                  All parameters are sent in the call&#39;s <em>querystring</em>, so
                  ** percentage encoding for URIs ** &#40;also known as{' '}
                  <em>URL encoding</em>&#41; must be applied.
                </p>
                <table>
                  <thead>
                    <tr>
                      <th align="center">Parameter</th>
                      <th align="center">Obligatory</th>
                      <th align="center">Format</th>
                      <th align="center">Description</th>
                      <th align="center">Default value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td align="center">phone</td>
                      <td align="center">Yes</td>
                      <td align="center">Numeric</td>
                      <td align="center">New user&#39;s phone number</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">country_code</td>
                      <td align="center">Yes</td>
                      <td align="center">
                        <a href="https://www.iso.org/glossary-for-iso-3166.html">
                          ISO 3166 alpha-2
                        </a>
                      </td>
                      <td align="center">
                        Country code in which to register the user
                      </td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">channel_id</td>
                      <td align="center">Yes</td>
                      <td align="center">Numeric</td>
                      <td align="center">
                        Channel from which the request is made. See table Annex 1 at
                        the end of the document
                      </td>
                      <td align="center">1</td>
                    </tr>
                    <tr>
                      <td align="center">affiliate_id</td>
                      <td align="center">Yes</td>
                      <td align="center">Numeric</td>
                      <td align="center">
                        ID of the affiliate whose community the new user is
                        associated with
                      </td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">active</td>
                      <td align="center">Yes</td>
                      <td align="center">Boolean</td>
                      <td align="center">
                        It is reported in the call to activate the account
                        automatically
                      </td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">profile</td>
                      <td align="center">Yes</td>
                      <td align="center">Numeric</td>
                      <td align="center">Profile of the new user</td>
                      <td align="center">By country</td>
                    </tr>
                    <tr>
                      <td align="center">email</td>
                      <td align="center">No</td>
                      <td align="center">Alphanumeric characters</td>
                      <td align="center">New user&#39;s email</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">prefix</td>
                      <td align="center">No</td>
                      <td align="center">Numeric</td>
                      <td align="center">Phone prefix of the new user</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">name</td>
                      <td align="center">No</td>
                      <td align="center">Alphanumeric</td>
                      <td align="center">Name of the new user</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">surname1</td>
                      <td align="center">No</td>
                      <td align="center">Alphanumeric</td>
                      <td align="center">First surname of the new user</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">surname2</td>
                      <td align="center">No</td>
                      <td align="center">Alphanumeric</td>
                      <td align="center">Second surname of the new user</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">rfc</td>
                      <td align="center">No</td>
                      <td align="center">Alphanumeric</td>
                      <td align="center">RFC code of the new user</td>
                      <td align="center">Calculated. If it is not possible N/A</td>
                    </tr>
                    <tr>
                      <td align="center">nationality</td>
                      <td align="center">No</td>
                      <td align="center">Alphabetic</td>
                      <td align="center">Country of origin of the new user</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">birth_status</td>
                      <td align="center">No</td>
                      <td align="center">Alphabetic</td>
                      <td align="center">New user&#39;s birth status</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">state</td>
                      <td align="center">No</td>
                      <td align="center">Alphabetic</td>
                      <td align="center">State of residence of the new user</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">nick</td>
                      <td align="center">No</td>
                      <td align="center">Alphabetic</td>
                      <td align="center">Nick or alias of the new user</td>
                      <td align="center">
                        Parameter value <em>phone</em>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">date_of_birth</td>
                      <td align="center">No</td>
                      <td align="center">
                        <a href="https://www.iso.org/iso-8601-date-and-time-format.html">
                          IS0 8601
                        </a>
                      </td>
                      <td align="center">New user&#39;s birth date</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">occupation</td>
                      <td align="center">No</td>
                      <td align="center">Numeric</td>
                      <td align="center">New user occupation</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">CURP</td>
                      <td align="center">No</td>
                      <td align="center">Numeric</td>
                      <td align="center">CURP code of the new user</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">gender</td>
                      <td align="center">No</td>
                      <td align="center">Numeric</td>
                      <td align="center">Gender of the new user</td>
                      <td align="center">0 &#40;not specified&#41;</td>
                    </tr>
                  </tbody>
                </table>
                <h6 id="chart-2a1-list-of-parameters-for-the-service-alta-usuario">
                  <strong>Chart 2.a.1</strong>: List of parameters for the service{' '}
                  <em>Alta Usuario</em>.
                </h6>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <h3 id="returns">Returns</h3>
                <p>
                  {' '}
                  Depending on the discharge result, this service can return two{' '}
                  <a href="https://www.json.org/">JSON</a> diferentes:
                </p>
                <ol>
                  <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                    <p>In case of success</p>
    
                    <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_return1}/>
          </div>
                  </li>
                </ol>
              </li>
            </ul>
          </li>
        </ol>
        <h3 id="where">Where:</h3>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>status</em>: Shows if the call has been successful &#40;true&#41; or
            not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>codtran</em>: Transaction code that identifies the executed
            operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>result</em>: Contains information about the result of the service.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>user_id_registration</em>: New user ID.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>longitud_otp</em>: Length of the OTP associated with the operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>elapsed</em>: Operation execution time.
          </li>
        </ul>
        <ol>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>If an error occurs</li>
        </ol>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_where1}/>
          </div>
        <h3 id="where-1">Where:</h3>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>status</em>: Shows if the call has been successful &#40;true&#41;
              or not &#40;false&#41;.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>level: Error importance level.</p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>message</em>: Error message.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>error</em>: Sole error code.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h3 id="error-catalogue">Error catalogue</h3>
            <table>
              <thead>
                <tr>
                  <th align="center">Message</th>
                  <th align="center">Cause</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">Email is required</td>
                  <td align="center">
                    The parameter <em>enviar_email_confirmar</em> has been sent, but
                    the parameter <em>email</em> has not been informed nor attached
                    an email address
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    The phone +<em>prefix phone</em> already exists
                  </td>
                  <td align="center">
                    The account identified by <em>phone </em>already exits and is
                    activated
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    An attempt to create an account without a phone was made
                  </td>
                  <td align="center">
                    Required parameter not provided <em>phone</em>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    An attempt was made to create a phone number with the wrong
                    length
                  </td>
                  <td align="center">
                    The value of the parameter <em>phone</em> has the wrong length,
                    depending on the country indicated in <em>country_code</em>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    An attempt was made to create an account with a prefix other
                    than the country <em>prefix</em> != <em>country_code</em>
                  </td>
                  <td align="center">
                    The value of the parameter <em>prefix</em> does not match with
                    the country code indicated in <em>country_code</em>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    The account &lt;cuenta&gt; is pending to sign the discharge
                  </td>
                  <td align="center">
                    The account already exists in the system, but is inactive
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    The nick <em>nick</em> is already used
                  </td>
                  <td align="center">
                    The account identified by <em>nick</em> exists and it&#39;s
                    active
                  </td>
                </tr>
                <tr>
                  <td align="center">Country not found</td>
                  <td align="center">
                    Controlled error in case the country code entered is wrong.
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    We have found a problem and are working to fix it ... sorry for
                    the inconvenience
                  </td>
                  <td align="center">Uncontrolled error</td>
                </tr>
                <tr>
                  <td align="center">500: Internal Server Error</td>
                  <td align="center">
                    In order not to provide service information, a 500 error is
                    thrown if a required parameter is not reported.
                  </td>
                </tr>
                <tr>
                  <td align="center">500: Internal Server Error</td>
                  <td align="center">
                    You can also get such an error if an uncontrolled error occurs
                    on the server
                  </td>
                </tr>
              </tbody>
            </table>
            <h6 id="chart-2a2-list-of-exceptions-thrown-by-the-service-alta-usuario">
              <strong>Chart 2.a.2</strong>: List of exceptions thrown by the service{' '}
              <em>Alta Usuario</em>.
            </h6>
            <ul>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <h3 id="business-logic">Business logic</h3>
                <p>
                  This section details some particularities related to this service
                  that it is advisable to take into account.{' '}
                </p>
              </li>
            </ul>
            <ol>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <p>
                  If an invalid channel_id value is provided or none is provided,
                  the default channel &#40;Web&#41; is set.
                </p>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <p>
                  If an email is associated with the new user, an activation email
                  will be sent, even if their account is automatically activated.
                </p>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <p>
                  If, on the contrary, email is not provided, there is the
                  possibility of activating the user directly by sending the{' '}
                  <em>activa</em> parameter. If it is not activated directly to the
                  user, an SMS is sent with activation instructions. The account
                  will remain in an inactive state until it is activated, it will
                  not be deleted from the system at any time.
                </p>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <p>
                  If the account is already active, trying to activate it again will
                  get a 404 error. This error is forced from the system when no
                  registration is found to sign.
                </p>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <p>
                  The PIN is generated and sent in the first activation SMS. If the
                  user does not activate the account or does not enter the OTP
                  correctly, the password generated initially is reused and it is
                  not sent in subsequent activation messages.
                </p>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <p>
                  The user&#39;s nickname can be used for the identification process
                  &#40;login&#41;. If nick is not indicated during the registration
                  process, it will take the value of <em>phone</em> parametro.
                </p>
                <p>
                  If the parameter <em>affiliate_id</em> is specified, the name of
                  the same will be used in the welcome SMS, instead of using the
                  name of the affiliate by default &#40;Pademobile&#41;.
                </p>
              </li>
            </ol>
          </li>
        </ul>
        <hr />
        <ol >
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>USER DISCHARGE</p>
            <h2 id="user-identifier">User identifier</h2>
            <p>
              {' '}
              <em>SER.EXT.BAJ.PDM.002 User Discharge.</em>
            </p>
            <h2 id="description-1">Description</h2>
            <p>
              {' '}
              This service allows you to cancel an existing account on the platform.
              Canceling an account consists of making a “logical” cancellation,
              which does not imply deleting it from the system, but rather
              deactivating the account so that it cannot access the services.
            </p>
            <h2 id="endpoints-1">
              <em>Endpoints</em>
            </h2>
            <ul>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <p>Preproduction</p>
    
                <pre className="">
                  <code className="">
                    https://unisap-prews.pademobile.com/ws/cuentas.py/baja
                  </code>
                </pre>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <p>Production</p>
    
                <pre className="">
                  <code className="">
                    https://www.pademobile.com:50/ws/cuentas.py/baja
                  </code>
                </pre>
                <h2 id="parameters-1">Parameters</h2>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <h3 id="receives-1">Receives</h3>
                <table>
                  <thead>
                    <tr>
                      <th align="center">Parameter</th>
                      <th align="center">Obligatory</th>
                      <th align="center">Format</th>
                      <th align="center">Description</th>
                      <th align="center">Default value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td align="center">session_id</td>
                      <td align="center">
                        Yes, if the signature parameter is not reported
                      </td>
                      <td align="center">Alphanumeric</td>
                      <td align="center">
                        Obtained by identifying the user with ID id_usuario on the
                        platform
                      </td>
                      <td align="center">N / A</td>
                    </tr>
                    <tr>
                      <td align="center">signature</td>
                      <td align="center">
                        Yes, if the session_id parameter is not reported
                      </td>
                      <td align="center">Alphanumeric</td>
                      <td align="center">
                        Allows you to sign the petition, making it unnecessary to
                        log in
                      </td>
                      <td align="center">N / A</td>
                    </tr>
                    <tr>
                      <td align="center">channel_id</td>
                      <td align="center">Yes</td>
                      <td align="center">Numeric</td>
                      <td align="center">
                        Channel from which the request is made. See table Annex 1 at
                        the end of the document
                      </td>
                      <td align="center">1</td>
                    </tr>
                    <tr>
                      <td align="center">unsubscribe_user_id</td>
                      <td align="center">Yes</td>
                      <td align="center">Numeric</td>
                      <td align="center">
                        User identifier to unsubscribe from 1 to 2147483647
                      </td>
                      <td align="center">N / A</td>
                    </tr>
                    <tr>
                      <td align="center">country_code</td>
                      <td align="center">Yes</td>
                      <td align="center">
                        [ISO 3166 alpha-2] &#40;
                        <a href="https://www.iso.org/glossary-for-iso-3166.html">
                          https://www.iso.org/glossary-for-iso-3166.html
                        </a>
                        &#41;
                      </td>
                      <td align="center">Country code where the user resides</td>
                      <td align="center">N / A</td>
                    </tr>
                  </tbody>
                </table>
                <h6 id="chart-2b1-list-of-parameters-for-the-service-baja-usuario">
                  <strong>Chart 2.b.1</strong>: List of parameters for the service{' '}
                  <em>Baja Usuario</em>.
                </h6>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <h3 id="returns-1">Returns</h3>
                <p>
                  {' '}
                  Depending on the result of the withdrawal, this service can return
                  two <a href="https://www.json.org/">JSON</a> diferentes:
                </p>
                <ol>
                  <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                    <p>In case of success</p>
    
                    <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_return2}/>
          </div>
                    <p> Where:</p>
                    <ul>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <em>status</em>: Shows if the call has been successful
                        &#40;true&#41; or not &#40;false&#41;.
                      </li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <em>codtran</em>: Transaction code that identifies the
                        executed operation.
                      </li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        unsibscription_date: Indicate the exact date and time of the
                        withdrawal.
                      </li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        unsubscribed_user: Dictionary that indicates the information
                        of the user unsubscribed.
                      </li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <em>elapsed</em>: Operation execution time.
                      </li>
                    </ul>
                  </li>
                  <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                    <p>If an error occurs</p>
    
                    <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_where2}/>
          </div>
                    <p> Where:</p>
                    <ul>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <em>status</em>: Shows if the call has been successful
                        &#40;true&#41; or not &#40;false&#41;.
                      </li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>level: Error importance level.</li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <em>message</em>: Error message.
                      </li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <em>error</em>: Sole error code.
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <h3 id="error-catalogue-1">Error catalogue</h3>
                <table>
                  <thead>
                    <tr>
                      <th align="center">Message</th>
                      <th align="center">Cause</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td align="center">Account does not exist</td>
                      <td align="center">
                        The account you want to cancel is not registered in the
                        system.
                      </td>
                    </tr>
                    <tr>
                      <td align="center">The account is already terminated</td>
                      <td align="center">
                        The account that you want to cancel already has the
                        cancellation status on the platform.
                      </td>
                    </tr>
                    <tr>
                      <td align="center">Country not found</td>
                      <td align="center">
                        Controlled error in case the country code entered is wrong.
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        We have found a problem and are working to fix it ... sorry
                        for the inconvenience
                      </td>
                      <td align="center">Uncontrolled error</td>
                    </tr>
                    <tr>
                      <td align="center">500: Internal Server Error</td>
                      <td align="center">
                        In order not to provide service information, a 500 error is
                        thrown if a required parameter is not reported.
                      </td>
                    </tr>
                    <tr>
                      <td align="center">500: Internal Server Error</td>
                      <td align="center">
                        You can also get such an error if an uncontrolled error
                        occurs on the server
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h6 id="chart-2b2-list-of-exceptions-thrown-by-the-service-baja-usuario">
                  <strong>Chart 2.b.2</strong>: List of exceptions thrown by the
                  service <em>Baja Usuario</em>.
                </h6>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <h3 id="business-logic-1">Business logic</h3>
                <p>
                  {' '}
                  This service has a very simple logic, which basically consists in
                  that once the user id is received, it is logically deregistered
                  and the information of said user is kept in the database.
                </p>
                <p>
                  {' '}
                  Once the cancellation is launched, the user is notified by sending
                  an SMS that their account is no longer accessible.
                </p>
                <p>
                  {' '}
                  From this moment this account is not recoverable and in case the
                  user wants to have an account again, a new one will have to be
                  generated.
                </p>
              </li>
            </ul>
          </li>
        </ol>
        <hr />
        <ol >
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>USER DATA UPDATE</p>
            <h2 id="service-identifier">Service Identifier</h2>
            <p>
              {' '}
              <em>SER.EXT.ANC.PDM.003 Actualización datos usuario</em>
            </p>
            <h2 id="description-2">Description</h2>
            <p>
              {' '}
              This service allows modifying the data associated with a user that is
              stored in 101OBeX except for the password, which for security reasons
              can only be managed by the user himself.
            </p>
            <h2 id="endpoints-2">Endpoints</h2>
            <ul>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <p>Preproduction</p>
    
                <pre className="">
                  <code className="">
                    https://impulso-prews.pademobile.com/ws/users.py/cambiodedatos
                  </code>
                </pre>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <p>Production</p>
    
                <pre className="">
                  <code className="">
                    https://www.pademobile.com:50/ws/users.py/cambiodedatos
                  </code>
                </pre>
                <h2 id="parameters-2">Parameters</h2>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <h3 id="receives-2">Receives</h3>
                <p>
                  {' '}
                  All parameters are sent in the call&#39;s <em>query string</em>,
                  so ** percentage encoding for URIs ** &#40;also known as{' '}
                  <em>URL encoding</em>&#41; must be applied.
                </p>
                <table>
                  <thead>
                    <tr>
                      <th align="center">Parameter</th>
                      <th align="center">Obligatory</th>
                      <th align="center">Format</th>
                      <th align="center">Description</th>
                      <th align="center">Default value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td align="center">country_code</td>
                      <td align="center">Yes</td>
                      <td align="center">
                        <a href="https://www.iso.org/glossary-for-iso-3166.html">
                          ISO 3166 alpha-2
                        </a>
                      </td>
                      <td align="center">Country code in which the user resides</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">id_usuario</td>
                      <td align="center">Yes</td>
                      <td align="center">Numeric</td>
                      <td align="center">
                        Identifer of the user making the request.
                      </td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">id_usuario_cambio</td>
                      <td align="center">Yes</td>
                      <td align="center">Numeric</td>
                      <td align="center">User identifier to modify</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">session_id</td>
                      <td align="center">
                        Yes, if the signature parameter is not reported
                      </td>
                      <td align="center">Alphanumeric</td>
                      <td align="center">
                        Obtained by identifying the user with ID user_id on the
                        platform
                      </td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">firma</td>
                      <td align="center">
                        Yes, if the session_id parameter is not reported
                      </td>
                      <td align="center">Alphanumeric</td>
                      <td align="center">
                        Allows you to sign the petition, making it unnecessary to
                        log in
                      </td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">channel_id</td>
                      <td align="center">Yes</td>
                      <td align="center">Numeric</td>
                      <td align="center">Request Channel. &#40;Annex&#41;</td>
                      <td align="center">1</td>
                    </tr>
                    <tr>
                      <td align="center">phone</td>
                      <td align="center">No</td>
                      <td align="center">Numeric</td>
                      <td align="center">New phone number</td>
                      <td align="center">N/ZA</td>
                    </tr>
                    <tr>
                      <td align="center">name</td>
                      <td align="center">No</td>
                      <td align="center">Alphanumeric</td>
                      <td align="center">New Username</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">surname1</td>
                      <td align="center">No</td>
                      <td align="center">Alphanumeric</td>
                      <td align="center">New Paternal Surname</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">surname2</td>
                      <td align="center">No</td>
                      <td align="center">Alphanumeric</td>
                      <td align="center">New Maternal Surname</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">CURP</td>
                      <td align="center">No</td>
                      <td align="center">Alphanumeric</td>
                      <td align="center">New CURP User</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">nationality</td>
                      <td align="center">No</td>
                      <td align="center">Alphabetic</td>
                      <td align="center">New country of origin of the user</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">birth_status</td>
                      <td align="center">No</td>
                      <td align="center">Alphabetic</td>
                      <td align="center">New user&#39;s birth state</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">date_of_birth</td>
                      <td align="center">No</td>
                      <td align="center">
                        <a href="https://www.iso.org/iso-8601-date-and-time-format.html">
                          IS0 8601
                        </a>
                      </td>
                      <td align="center">New user&#39;s date of birth</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">email</td>
                      <td align="center">No</td>
                      <td align="center">Alphanumeric characters</td>
                      <td align="center">New user email</td>
                      <td align="center">N / A</td>
                    </tr>
                    <tr>
                      <td align="center">occupation</td>
                      <td align="center">No</td>
                      <td align="center">Numeric</td>
                      <td align="center">New user occupation</td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">gender</td>
                      <td align="center">No</td>
                      <td align="center">Numeric</td>
                      <td align="center">
                        New gender option that defines the user
                      </td>
                      <td align="center">0 &#40;not specified&#41;</td>
                    </tr>
                    <tr>
                      <td align="center">nick</td>
                      <td align="center">No</td>
                      <td align="center">
                        Alphanumeric &#40;Maximum 10 characters&#41;
                      </td>
                      <td align="center">New nick of the user</td>
                      <td align="center">
                        Parameter value <em>phone</em>
                      </td>
                    </tr>
                    <tr>
                      <td align="center">state</td>
                      <td align="center">No</td>
                      <td align="center">Alphabetic</td>
                      <td align="center">New user status</td>
                      <td align="center">N/A</td>
                    </tr>
                  </tbody>
                </table>
                <h6 id="chart-2c1-list-of-parameters-for-the-data-update-service_">
                  <strong>Chart 2.c.1</strong>: List of parameters for the data
                  update service_.
                </h6>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <h3 id="returns-2">Returns</h3>
                <p>
                  {' '}
                  Depending on the result of the update, this service may return two{' '}
                  <a href="https://www.json.org/">JSON</a> diferentes:
                </p>
                <ol>
                  <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                    <p>In case of success</p>
    
                    <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_return3}/>
          </div>
                    <p> Where:</p>
                    <ul>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <em>status</em>: Shows if the call has been successful
                        &#40;true&#41; or not &#40;false&#41;.
                      </li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <em>codtran</em>: Transaction code that identifies the
                        executed operation.
                      </li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        datos_cambiados: A list with all the data that was modified.
                      </li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <em>elapsed</em>: Operation execution time.
                      </li>
                    </ul>
                  </li>
                  <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                    <p>If an error occurs</p>
    
                    <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_where3}/>
          </div>
                    <p> Where:</p>
                    <ul>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <em>status</em>: Shows if the call has been successful
                        &#40;true&#41; or not &#40;false&#41;.
                      </li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>level: Error importance level.</li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <em>message</em>: Error message.
                      </li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <em>error</em>: Sole error code.{' '}
                      </li>
                    </ul>
                  </li>
                </ol>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <h3 id="error-catalogue-2">Error catalogue</h3>
                <table>
                  <thead>
                    <tr>
                      <th align="center">Message</th>
                      <th align="center">Cause</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td align="center">Account does not exist</td>
                      <td align="center">
                        The account for which you want to update data does not
                        exist.
                      </td>
                    </tr>
                    <tr>
                      <td align="center">Blocked Account</td>
                      <td align="center">
                        The account for which you want to update data is locked in
                        the system
                      </td>
                    </tr>
                    <tr>
                      <td align="center">Country not found</td>
                      <td align="center">
                        Controlled error in case the country code entered is wrong.
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        It is not possible to process operations on deactivated
                        accounts
                      </td>
                      <td align="center">
                        Controlled error in case the account to which you want to
                        change the phone is deactivated.
                      </td>
                    </tr>
                    <tr>
                      <td align="center">New phone already exists</td>
                      <td align="center">
                        Controlled error in case the new phone number to be saved is
                        already assigned to another account in the system.
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        The new phone must be different from the current one
                      </td>
                      <td align="center">
                        Controlled error in case the new telephone number is the
                        same as the one assigned to it.
                      </td>
                    </tr>
                    <tr>
                      <td align="center">
                        We have found a problem and are working to fix it ... sorry
                        for the inconvenience
                      </td>
                      <td align="center">Uncontrolled error</td>
                    </tr>
                    <tr>
                      <td align="center">500: Internal Server Error</td>
                      <td align="center">
                        In order not to provide service information, a 500 error is
                        thrown if a required parameter is not reported.
                      </td>
                    </tr>
                    <tr>
                      <td align="center">500: Internal Server Error</td>
                      <td align="center">
                        You can also get such an error if an uncontrolled error
                        occurs on the server
                      </td>
                    </tr>
                  </tbody>
                </table>
                <h6 id="chart-2c2-list-of-exceptions-thrown-by-the-service-actualizar-teléfono">
                  <strong>Chart 2.c.2</strong>: List of exceptions thrown by the
                  service <em>Actualizar Teléfono</em>.
                </h6>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <h3 id="business-logic-2">Business logic</h3>
                <p>
                  {' '}
                  This service allows modifying each of the elements indicated as
                  non-mandatory in the input parameters, in such a way that it is
                  only necessary to inform the field to update.
                </p>
              </li>
            </ul>
          </li>
        </ol>
        <h1 id="3-calls-data">3. CALLS DATA</h1>
        <p>
          This section details, for each box, all the information necessary to
          exploit the previously documented services.
        </p>
        <table>
          <thead>
            <tr>
              <th align="center">Parameter</th>
              <th align="center">Value</th>
              <th align="center">Services to which it applies</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">country_code</td>
              <td align="center">--</td>
              <td align="center">All</td>
            </tr>
            <tr>
              <td align="center">profile</td>
              <td align="center">--</td>
              <td align="center">User registration</td>
            </tr>
            <tr>
              <td align="center">affiliate_id</td>
              <td align="center">--</td>
              <td align="center">User registration</td>
            </tr>
            <tr>
              <td align="center">channel_id</td>
              <td align="center">--</td>
              <td align="center">All</td>
            </tr>
            <tr>
              <td align="center">private_key</td>
              <td align="center">--</td>
              <td align="center">All &#40;Signature generation&#41;</td>
            </tr>
          </tbody>
        </table>
        <h6 id="chart-2d1-parameters-provided-by-pademobile-for-the-pre-production-environment">
          <strong>Chart 2.d.1</strong>: Parameters provided by Pademobile for the
          pre-production environment.
        </h6>
        <table>
          <thead>
            <tr>
              <th align="center">Parameter</th>
              <th align="center">Value</th>
              <th align="center">Services to which it applies</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">country_code</td>
              <td align="center">--</td>
              <td align="center">All</td>
            </tr>
            <tr>
              <td align="center">profile</td>
              <td align="center">--</td>
              <td align="center">User registration</td>
            </tr>
            <tr>
              <td align="center">affiliate_id</td>
              <td align="center">--</td>
              <td align="center">User registration</td>
            </tr>
            <tr>
              <td align="center">channel_id</td>
              <td align="center">--</td>
              <td align="center">All</td>
            </tr>
            <tr>
              <td align="center">private_key</td>
              <td align="center">--</td>
              <td align="center">All &#40;signature generation&#41;</td>
            </tr>
          </tbody>
        </table>
        <h6 id="chart-2d2-parameters-provided-by-pademobile-for-the-production-environment">
          <strong>Chart 2.d.2</strong>: Parameters provided by Pademobile for the
          production environment.
        </h6>
        <p>
          There is a user who has an &quot;AFFILIATE&quot; profile and who will
          allow managing the community:
        </p>
        <table>
          <thead>
            <tr>
              <th align="center">User Nick</th>
              <th align="center">Password</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">--</td>
              <td align="center">--</td>
            </tr>
          </tbody>
        </table>
        <h6 id="chart-2d3-community-owning-user-credentials-in-pre-production-environment">
          <strong>Chart 2.d.3</strong>: Community owning user credentials in
          pre-production environment.
        </h6>
        <table>
          <thead>
            <tr>
              <th align="center">User Nick</th>
              <th align="center">Password</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">--</td>
              <td align="center">--</td>
            </tr>
          </tbody>
        </table>
        <h6 id="chart-2d4-community-owning-user-credentials-in-production-environment">
          <strong>Chart 2.d.4</strong>: Community owning user credentials in
          production environment.
        </h6>
        <h3 id="examples">Examples</h3>
        <p>
          Below are some examples of calls to the services described in this
          document:
        </p>
        <h4 id="user-registration">User registration</h4>
        <ol>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>User registration:</p>
            <p>
              {' '}
              with telephone number 9876543200, profile &quot;BANKING USER&quot;,
              belonging to the &quot;impulse&quot; community and email &quot;
              <a href="mailto:test@dominio.com">test@dominio.com</a>&quot;:
            </p>
            <ol>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <p>Call:</p>
                <p>
                  {' '}
                  <code>
                    https://impulso-prews.pademobile.com/ws/cuentas.py/alta?country_code=MX&amp;profile=133&amp;email=prueba@dominio.com&amp;active=True&amp;name=Prueba&amp;channel_id=25&amp;phone=9876543200&amp;ip_request=127.0.0.1&amp;surname1=Prueba&amp;surname2=Prueba&amp;affiliate_id=7
                  </code>
                </p>
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <p>Answer:</p>
    
                <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_example}/>
          </div>
              </li>
            </ol>
          </li>
        </ol>
      </Fragment>
    );
  }
export default Alta_baja_modificacion_EN;
