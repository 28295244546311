import React from 'react';
import './chapter.css'
const Chapter9_19 = () => (
    <div>
        <h2>6.2.7. Dictionary Displays</h2>
        <p>
            In AVAP, objects are created and managed using specific commands. An object is created with <code>AddvariableToJSON(key, value, object_variable)</code>, and a key from the object is retrieved with <code>variableFromJSON(object_variable, key, var_to_store_key_value)</code>.
        </p>
        <p>
            The syntax for dictionary displays is:
        </p>
        <pre>
            <code>
{`dict_display       ::=  "{" [dict_item_list | dict_comprehension] "}"
dict_item_list     ::=  dict_item ("," dict_item)* [","]
dict_item          ::=  expression ":" expression | "**" or_expr
dict_comprehension ::=  expression ":" expression comp_for
`}
            </code>
        </pre>
        <p>
            A dictionary display produces a new dictionary object. If a comma-separated sequence of dictionary items is provided, they are evaluated from left to right to define the dictionary entries.
        </p>
    </div>
);

export default Chapter9_19;
