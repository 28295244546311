import React, { Fragment } from 'react';
import './chapter.css'
const Chapter7_6 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>3.5 Summary</h2>
      <p>
        Comments in AVAP™ are an essential tool for improving the readability and maintainability of source code. 
        With line comments, block comments, and documentation comments, developers can add explanations, clarifications, 
        and useful documentation to the code, making it easier to understand and collaborate within development teams.
      </p>
    </div>
  </Fragment>
);

export default Chapter7_6;