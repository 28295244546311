import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.css'; // or 'antd/dist/antd.less'
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import common_en from './assets/translations/en/common.json';
import common_es from './assets/translations/es/common.json';
import common_fr from './assets/translations/fr/common.json';

//Redux
import { Provider } from 'react-redux'
import store from './redux/store';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
    resources: {
      en: {
        common: common_en
      },
      es: {
        common: common_es
      },
      fr: {
        common: common_fr
      },
    },
});


ReactDOM.render(
  <React.StrictMode>
    <Provider  store={store}>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
