import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h3>General Conventions</h3>
      <p>
        In AVAP™, several general notation conventions are followed, similar to those used in other programming languages like Python. 
        Some of these conventions include:
      </p>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Indentation</strong>: Code is structured through indentation, using white spaces or tabs to indicate the hierarchy and structure of the code. It is recommended to use four spaces for each level of indentation.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Case Sensitivity</strong>: AVAP™ is case-sensitive, meaning that identifiers, variable names, and keywords must be consistently written using the same capitalization format throughout the code.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Comments</strong>: Comments are used to document the code and explain its functionality. Single-line comments begin with the <code>//</code> symbol, while multi-line comments start with <code>/*</code> and end with <code>*/</code>.
        </li>
      </ul>
    </div>
  </Fragment>
);
export default Intro_1;
