import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { Row, Container, Tooltip, Col, Spinner, Button } from 'react-bootstrap';
import ConsumptionCard from './ConsumptionCard';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import AddProjectModalSel from '../../Modals/addProjectModalSelect';
import AddProjectModal from '../../Modals/addProjectModal';
import AddTxSelModal from '../../Modals/addTxModalSelect';
import AddStorageModal from '../../Modals/addStorageModal';
import AddBalanceModalSelection from '../../Modals/addBalanceModalSelection';
import AddDevsModalSel from '../../Modals/addDevelopersModalSelect';
import AddIncomeModalSelection from '../../Modals/addIncomeModalSelection';
import { amountToEur, parseToNumberFormat } from '../../../services/AmountService';
import UpgradePlanModal from '../../Modals/UpgradePlanModal';
import AddDeveloperModalSel from '../../Modals/addDevelopersModalSelect';
import AddDeveloperModal from '../../Modals/addDeveloperModal';
import SessionStorageHandler from '../../../handlers/SessionStorageHandler';
import SessionService from '../../../services/SessionsService';
import AddAPISModal from '../../Modals/addAPISModal';

import dasIcon from '../../../assets/img/ui_icons/cld_dashboard-s.svg'






//create organization
import AddOrganizationModal from '../../Modals/addOrganizationModal';
import AddOrganizationSlots from '../../Modals/addOrganizationSlots';
import Drawer from '../Drawer';

interface Region {
  id: number;
  region: string;
  server: string;
  code: string;
}

interface Developers {
  dev_id: number;
  email: string;

}

const Consumptions: FunctionComponent<{}> = () => {

  const [isAddOrgDrawerOpen, setIsAddOrgDrawerOpen] = useState(false);
  const [addingOrg, setAddingOrg] = useState(false);
  const [currentOrganizations, setCurrentOrganizations] = useState(0);
  const [maxOrganizations, setMaxOrganizations] = useState(0);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const [slotsInfo, setSlots] = useState({
    allowProjectsBySubscription: 0,
    maxAllowProjecs: 0,
    maxExtraProjects: 0,
    extraPurchasedProjects: 0,
    activeProjects: 0,
    totalSlots: 0,
    availableSlots: 0,
    activeUsers: 0,
    maxAllowUsers: 0
  });
  const [txInfo, setTx] = useState({
    limit: 0,
    current: 0,
    rest: 0,
    projects: {}
  });




  document.addEventListener('updateDashboard', (e) => {

    e.preventDefault();
    e.stopPropagation();

    getData();
    getVerified();

    if (slotsInfo.activeProjects < slotsInfo.maxAllowProjecs) {
      setAvailableAdd(true);
    } else {
      setAvailableAdd(false);
    }

  }, false);

  const [possibleProjectsToBuy, setPossibleProjectsToBuy] = useState(0);
  const [possibleDevelopersToBuy, setPossibleDevelopersToBuy] = useState(0);
  const [possibleSlotsToBuy, setSlotsToBuy] = useState(0);
  const [addingProjects, setAddingProjects] = useState(false);


  const [addingDevelopers, setAddingDevelopers] = useState(false);

  const [addingTx, setAddingTx] = useState(false);
  const [addingBalance, setAddingBalance] = useState(false);
  const [removingIncome, setRemovingIncome] = useState(false);
  const [balance, setBalance] = useState('€0,00');
  const [income, setIncome] = useState('€0,00');
  const [loadingProject, setLoadingProject] = useState(true);
  const [loadingTx, setLoadingTx] = useState(true);
  const [loadingBalance, setLoadingBalance] = useState(true);
  const [loadingIncome, setLoadingIncome] = useState(true);
  const [availableAdd, setAvailableAdd] = useState(false);

  const [availableAddDev, setAvailableAddDev] = useState(false);

  const [loadingStorage, setLoadingStorage] = useState(true);
  const [addingStorage, setAddingStorage] = useState(false);
  const [addingAPIS, setAddingAPIS] = useState(false);
  const [storageUsed, setStorageUsed] = useState('-');
  const [storageLimit, setStorageLimit] = useState('-');
  const [storageHelper, setStorageHelper] = useState(false);
  const [currentSuscription, SetCurrentsuscripcion] = useState(0);
  const [maxTX, setMaxTX] = useState(3100);
  const [actualTX, setActualTX] = useState(0)
  const [MaxDBSize, setMaxDBSize] = useState(0);
  const [ActualDBSize, setActualDBSize] = useState(0);
  const [UsedMembers, setUsedMembers] = useState(0);
  const [ActualMembers, setActualMembers] = useState(0);
  const [MaxMembers, setMaxMembers] = useState(0);
  const [DeveloperMultiplier, setDeveloperMultiplier] = useState(0);

  const [BlockedAdd, setBlockedAdd] = useState(false);
  const [susinfo, setSusInfo] = useState()
  const [vpnInfo, setVPNInfo] = useState({ max_vpn: 0, slot_vpn: 0 })
  const [productInfo, setProductInfo] = useState({ max_api_products: 0, slot_api_products: 0, used_apis: 0 })
  const [apiBuyed, setAPIBuyed] = useState(0);
  const handleAddProjects = () => {
    const restOfPossibleProjectToBuy = slotsInfo.maxExtraProjects - slotsInfo.extraPurchasedProjects;
    console.log(restOfPossibleProjectToBuy)
    setPossibleProjectsToBuy(restOfPossibleProjectToBuy);
    setAddingProjects(!addingProjects);

  }

  const handleAddDevelopers = () => {
    const restOfPossibleDevelopersToBuy = MaxMembers - UsedMembers;
    setPossibleDevelopersToBuy(restOfPossibleDevelopersToBuy);
    setAddingDevelopers(!addingDevelopers);
  }


  const handleAddTx = () => setAddingTx(!addingTx);
  const handleAddStorage = () => {
    setAddingStorage(!addingStorage);
    console.log(MaxDBSize);
    console.log(ActualDBSize);
  }

  const handleAddAPIS = () => {
    setAddingAPIS(!addingAPIS);

  }

  const handleAddBalance = () => setAddingBalance(!addingBalance);
  const handleRemoveIcome = () => setRemovingIncome(!removingIncome);
  const [regions, setRegions] = useState<Region[]>([]);
  const [developers, setDevelopers] = useState<Developers[]>([]);

  //
  //${slotsInfo.maxAllowProjecs}, /${MaxMembers} UsedMembers, ${productInfo.max_api_products}, ${susinfo == 1 ? '/5Gb' : '/1000Gb'}

  const projectsCard = <ConsumptionCard upgradeLabel={'Upgrade your plan'} vlabel={'Available API projects'} svlabel={'API projects'} NoPayment={BlockedAdd} title='API Projects' plus={true} value={`${slotsInfo.totalSlots}`} valueSecondary={`${slotsInfo.activeProjects}`} action={handleAddProjects} loading={loadingProject} available={availableAdd} info={false} currentSuscription={currentSuscription} />
  
  
  //const usersCard = <ConsumptionCard upgradeLabel={'Add Developers Slots'} vlabel={'Available slots'} svlabel={'Slots in use'} NoPayment={BlockedAdd} title='Developers' plus={true} value={`${MaxMembers}`} valueSecondary={`${ActualMembers}`} action={handleAddDevelopers} loading={loadingProject} available={availableAddDev} info={false} currentSuscription={currentSuscription} />
  
  
  const txCard = <ConsumptionCard upgradeLabel={'Upgrade your plan'} vlabel={'Request'} svlabel={'Available'} NoPayment={BlockedAdd} title='Requests' plus={true} valueSecondary={`${txInfo.current}`} value  ={`${(parseToNumberFormat(parseInt(txInfo.limit.toString().replace(',', ''))))}K${susinfo == 1 ? '/10,000K' : ''}`} action={handleAddTx} loading={loadingTx} available={true} info={false} currentSuscription={currentSuscription} />
  const balanceCard = <ConsumptionCard NoPayment={BlockedAdd} title='Balance' plus={true} value={balance} action={handleAddBalance} loading={loadingBalance} available={true} info={false} currentSuscription={currentSuscription} />
  const projectsVPN = <ConsumptionCard NoPayment={BlockedAdd} title='VPNs' plus={true} value={`0/${vpnInfo.slot_vpn}/${vpnInfo.max_vpn}`} action={handleAddProjects} loading={loadingProject} available={availableAdd} info={false} currentSuscription={currentSuscription} />
  const storageCard = <ConsumptionCard upgradeLabel={'Upgrade your plan'} vlabel={'Contracted Storage'} svlabel={'Real Time Usage'} rightLine={false} NoPayment={BlockedAdd} title='Storage' plus={true} value={`${parseInt(storageLimit)} Gb`} valueSecondary={`${parseInt(storageUsed) / 1000}Gb`} action={handleAddStorage} loading={loadingStorage} available={true} info={storageHelper} currentSuscription={currentSuscription}>
    i
  </ConsumptionCard>


  const managedApisCard = <ConsumptionCard upgradeLabel={'Add APIs'} vlabel={'Available slots'} svlabel={'Used Slots'} rightLine={false} NoPayment={BlockedAdd} title='Managed APIs' plus={true} value={`${productInfo.slot_api_products + parseInt(apiBuyed.toString())}`} valueSecondary={`${productInfo.used_apis === undefined ? 0 : productInfo.used_apis}`} action={handleAddAPIS} loading={loadingStorage} available={true} info={storageHelper} currentSuscription={currentSuscription}>
    i
  </ConsumptionCard>

  const incomeCard = <ConsumptionCard NoPayment={BlockedAdd} title='Income' plus={false} value={income} action={handleRemoveIcome} loading={loadingIncome} available={true} info={false} currentSuscription={currentSuscription} />

  const getConsumption = async (disableCache = false) => {
    const result = await ObexRequestHandler.get('/consumption', {}, !disableCache);
    const { success, data } = result;
    if (success) {
      setMaxTX(data.subscriptionInfo.max_month_rate_limit)
      SetCurrentsuscripcion(data.subscriptionInfo.subscription_id);
      setLoadingTx(false);
      setLoadingProject(false);
      let { slots, tx, subscriptionInfo, vpn, apiproducts, extraApis } = data;
      setActualTX(tx.limit);
      tx.limit = new Intl.NumberFormat('en-Us').format(tx.limit);
      tx.current = new Intl.NumberFormat('en-US').format(tx.current);
      setSlots(slots);
      setSlotsToBuy(slots.maxExtraProjects - slots.extraPurchasedProjects);
      setSusInfo(subscriptionInfo.subscription_id);
      if (vpn === undefined) vpn = { id: 0, max_vpn: 1000, slot_vpn: 500 }
      if (apiproducts === undefined) apiproducts = { id: 0, max_api_products: 10000, slot_api_products: 5000, used_apis: 0 }
      setVPNInfo(vpn);
      setProductInfo(apiproducts);
      setAPIBuyed(extraApis);

      console.log('Consumption Data:', data);
      //SetCurrentsuscripcion(data.subscriptionInfo.subscription_id);
      //console.log(`RESULTADO DE CONSUMO ${slots.maxExtraProjects - slots.extraPurchasedProjects}`)

      setTx(tx);
    }
  }

  const getBalance = async (disableCache = false) => {
    setLoadingBalance(true);
    try {
      //const result = await ObexRequestHandler.get('/balance', {}, disableCache);
      //let { success, data, message } = result;
      //if (!success)  throw { message };
      let balance = '0';
      const data = 0;
      balance = amountToEur(data);
      setBalance(balance);
    } catch (error) {
      console.error('ERROR FETCHING BALANCE ', error)
    }
    setLoadingBalance(false);
  }

  const getIncome = async (disableCache = false) => {
    setLoadingIncome(true);
    try {
      //const result = await ObexRequestHandler.get('/income', {}, disableCache);
      //let { success, data, message } = result;
      //if (!success) throw { message };
      const data = '0'
      const income = amountToEur(data);
      setIncome(income);
    } catch (error) {
      console.error('ERROR FETCHING INCOME ', error)
    }
    setLoadingIncome(false);
  }

  const getStorage = async (disableCache = false) => {
    setLoadingStorage(true);
    setStorageHelper(false);
    try {
      const result = await ObexRequestHandler.get('/database_usage', {}, !disableCache);
      const { success, data, message } = result;

      setActualDBSize(data.allowed.total.db_size_project);
      setMaxDBSize(data.allowed.subscription.db_size_total / 1000);
      if (!success) throw { message };
      //const storageUsed = (data.used.total / 1e+6).toFixed(2).toString();
      //const storageLimit = (data.allowed.total.db_size_total  / 1e+6).toFixed(2).toString();

      const storageUsed = new Intl.NumberFormat('en-US').format(Math.trunc(data.used.total / 1e+6));
      const storageLimit = new Intl.NumberFormat('en-US').format(Math.trunc((parseInt(data.allowed.subscription.db_size_project) + parseInt(data.allowed.bought.extra_size_by_project)) / 1e+6));

      setStorageUsed(storageUsed);
      setStorageHelper(false);
      setStorageLimit(storageLimit);
    } catch (error) {
      console.error('ERROR FETCHING STORAGE ', error)
      setStorageHelper(true);
    }
    setLoadingStorage(false);
  }

  const getDevsData = async (disableCache = false) => {
    const result = await ObexRequestHandler.get('/devs_account', {}, !disableCache);
    //console.log(result);
    setMaxMembers(parseInt(result.data.Maxslots));
    setUsedMembers(result.data.activeSlots);
    setActualMembers(result.data.activeDevelopers)

    SessionService.MAXDEVSLOTS = parseInt(result.data.Maxslots);
    SessionService.CURRENTDEVS = result.data.activeSlots;
    SessionService.ACTUALDEVS = result.data.activeDevelopers;

    setAvailableAddDev(result.data.activeSlots < parseInt(result.data.Maxslots));

  };

  const getSusbscription = async (disableCache = false) => {
    // setLoadingStorage(true);

    // try {
    //   const result = await ObexRequestHandler.get('/current_subscription', {}, !disableCache);
    //   //console.log(result);
    //   setMaxDBSize(result.data.db_size_total);
    //   const { success, data, message } = result;
    //   if (!success) throw { message };
    //   const currentsuscripcion = (data.subscription_id);
    //   let desarrolladores_activos = 0;
    //   if (data.active_slots_membersdata.active_slots_members == null){
    //     desarrolladores_activos = 0;
    //   } else {
    //     desarrolladores_activos = data.active_slots_members;
    //   }
    //   const max_members_allow = (data.max_members_slots);
    //   const active_slots_members = (desarrolladores_activos);

    //   const annualPayment = (data.annual_payment);
    //   const annualprice = (data.annual_price)
    //   const monthlyprice = (data.monthly_price)

    //   SetCurrentsuscripcion(currentsuscripcion);
    //   setMaxMembers(max_members_allow);
    //   setUsedMembers(active_slots_members);

    //   setDeveloperMultiplier(10);

    //   //console.log(data);

    // } catch (error) {
    //   console.error('ERROR FETCHING STORAGE ', error)

    // }
    // setLoadingStorage(false);
  }

  const getData = (disableCache = false) => {
    getConsumption(disableCache);
    getIncome(disableCache);
    getBalance(disableCache);
    getStorage(disableCache);
    getSusbscription(disableCache);
    getDevsData(disableCache);
  }


  const getVerified = async () => {
    SessionService.RENOVATEDSUSCRIPTION = true;

    // const result2 = await ObexRequestHandler.get('/api_requests');


    const result = await ObexRequestHandler.get('/check_renovated_suscription');

    const { success, data } = result;
    if (success) {
      const { renovated } = data;

      const wast = document.getElementById('nopayment') as HTMLDivElement;
      if (!renovated) {
        wast.style.display = 'block';
        SessionService.RENOVATEDSUSCRIPTION = renovated;
        setBlockedAdd(true);

      }
    }
  }

  const fetchRegions = async () => {
    try {
      const response = await ObexRequestHandler.get('/servers_regions');

      if (response.data) {
        const regions_ = response.data;
        setRegions(regions_);
      } else {
        console.error('Error in the request:', response.data.error);
      }
    } catch (error) {
      console.error('Error at fetching regions', error);

    }
  };

  const fetchDevelopers = async () => {
    try {
      const response = await ObexRequestHandler.get('/owner_organizations_devs');
      console.log(response)
      if (response.data) {
        const regions_ = response.data;
        setDevelopers(regions_);
      } else {
        console.error('Error in the request:', response.data.error);
      }
    } catch (error) {
      console.error('Error at fetching regions', error);

    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
    getVerified();
    console.log("ASASAS")
    fetchRegions();
    console.log("XCXCXCXCXC")
    fetchDevelopers();
    console.log("DFDFDFDFDFD")
    // const consumptionInterval = setInterval(getData, 1000000); // Cada 30seg
    // return () => { 
    //   clearInterval(consumptionInterval) // El return en el use effect ejectuta la función dada cuando el componente se desmonta
    // }
  }, []);

  useEffect(() => {
    if (slotsInfo.activeProjects < slotsInfo.maxAllowProjecs) {
      setAvailableAdd(true);
    } else {
      setAvailableAdd(false);
    }
  }, [slotsInfo])

  // Método para cuando se cierra un modal(no obtenemos los datos si no se opera)
  const whenClosing = () => {
    setAddingTx(false);
    setAddingBalance(false);
    setAddingProjects(false);
    setAddingAPIS(false);
    setAddingDevelopers(false);

    setRemovingIncome(false);
    setAddingStorage(false);
    getData();
  }

  // Método para cuando una operación de un modal ha ido bien y obtiene los datos de nuevo sin cachear
  const onSuccess = () => {
    setAddingTx(false);
    setAddingBalance(false);
    setAddingProjects(false);

    setAddingDevelopers(false);

    setRemovingIncome(false);
    setAddingStorage(false);
    getData(true);
  }

  // Función para alternar el estado del drawer de "Add Organization"
  const toggleAddOrgDrawer = () => {
    setIsAddOrgDrawerOpen(!isAddOrgDrawerOpen);
  };

  // Función para manejar la apertura de AddOrganizationSlots cuando se alcanza el límite
  const handleOrg = () => {
    setAddingOrg(!addingOrg);
    getProjects(''); // Actualiza la lista de organizaciones, si es necesario
  };

  // Función para obtener la lista de organizaciones
  const getProjects = async (filtro = '') => {
    try {
      setLoading(true);
      const response = await ObexRequestHandler.get('/organizations', {}, true);
      const data = response.data || [];
      const projectsList = data.result || [];

      setCurrentOrganizations(projectsList.length);
      setMaxOrganizations(data.max || 10);

      // Filtra los proyectos por nombre, si hay un filtro aplicado
      const filteredProjects = projectsList.filter(project =>
        project.name.toLowerCase().includes(filtro.toLowerCase())
      );
      setProjects(filtro === '' ? projectsList : filteredProjects);

    } catch (error) {
      console.error('Error al obtener organizaciones:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getProjects(); // Llama a getProjects al cargar el componente
  }, []);

  return (
    <Fragment>

      <Row id='topHeader' className='mb-3'>
        <Col lg="10"  md="6">
          <div className='d-flex flex-column'>
            <div className="cld_wrapperTitle">
              <img src={dasIcon} alt="icon dashboard" />
              <span className="cld_title w-100 margin-y-24px">
                Dashboard
              </span>
            </div>

            {/* <span className="cld_legend w-100">
              Complete overview of your projects, organizations, teams and more.
            </span> */}
          </div>
        </Col>
        <Col lg="2"  md="6"  className='align-content-center'>
          {currentOrganizations < maxOrganizations ? (
            <Button
              style={{ width: '225px'}}
              className="customButton-black btn px-4 float-right   resBtn"
              onClick={() => setIsAddOrgDrawerOpen(true)} // Abre el modal para añadir una nueva organización
            >
              Create Organization
            </Button>
          ) : (
            <button
            style={{ width: '225px'}}
              className="customButton-black btn  px-4 float-right  resBtn"
              onClick={() => setAddingOrg(true)} // Abre `AddOrganizationSlots` cuando se alcanza el límite
            >
              Create Organization
            </button>
          )}
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      
      </Row>
          {/*<div style={{ fontSize:'14px', fontWeight:600 }} className='mb-3'>Organization: {SessionService.ORGANIZATION_NAME}</div>*/}
      <Row className="consumption mb-4  justify-content-between ">
       <Col>
       <div className="customGridFlex">
      {/*    {usersCard} */}
          {projectsCard}
          {managedApisCard}
          {/*} {projectsVPN} */}
          {txCard}
          {storageCard}

          {/*      {balanceCard}
          {incomeCard} */}
        </div>
       
       </Col>


      </Row>

      {/* Modal para las opciones proyectos */}


      <UpgradePlanModal showModal={possibleSlotsToBuy == 0 && addingProjects} text={'In order to add a more slots you need to upgrade your plan.'} onCloseModal={whenClosing} onCancelModal={whenClosing} currentSuscription={currentSuscription} onSuccess={onSuccess} />


      <AddProjectModalSel opened={addingProjects} possibleProjectsToBuy={possibleSlotsToBuy} shown={addingProjects && possibleSlotsToBuy > 0} onCloseAction={whenClosing}  cloud={true}/>




      {(addingDevelopers && (SessionService.MAXDEVSLOTS - SessionService.CURRENTDEVS) > 0) ??

        <AddDevsModalSel DeveloperMultiplier={DeveloperMultiplier} possibleProjectsToBuy={possibleDevelopersToBuy} shown={addingDevelopers && possibleDevelopersToBuy > 0} onCloseAction={whenClosing} />

      }

      {/*

      {(addingDevelopers && MaxMembers-UsedMembers > 0 )  
        ? <AddDeveloperModal 
          shown={addingDevelopers && MaxMembers-UsedMembers > 0} 
          onSuccessCreation = {whenClosing} 
          onCloseAction={whenClosing}
          setAvailable={setAvailableAdd}/>
        : <AddDeveloperModalSel possibleProjectsToBuy={possibleSlotsToBuy} shown={addingDevelopers && possibleDevelopersToBuy>0} onCloseAction={whenClosing}/> 
        
      }

    */}
      <AddDeveloperModalSel DeveloperMultiplier={DeveloperMultiplier} possibleProjectsToBuy={possibleDevelopersToBuy} shown={addingDevelopers && possibleDevelopersToBuy > 0} onCloseAction={whenClosing} cloud={true} />

      {/* Modal para las opciones de Storage */}
      {(addingStorage && !BlockedAdd) && <AddStorageModal shown={addingStorage && !BlockedAdd} onCloseAction={whenClosing} onSuccess={onSuccess} MaxDBSize={MaxDBSize} ActualDBSize={ActualDBSize} currentSuscription={currentSuscription} cloud={true}/>}

      {(addingAPIS && !BlockedAdd) && <AddAPISModal shown={addingAPIS && !BlockedAdd} onCloseAction={whenClosing} onSuccess={onSuccess} MaxAPIS={productInfo.max_api_products} ActualAPIS={productInfo.slot_api_products + parseInt(apiBuyed.toString())} currentSuscription={currentSuscription} cloud={true} grid={true} />}

      {/* Modal para las opciones de Tx */}
      {(addingTx && !BlockedAdd) && <AddTxSelModal shown={addingTx && !BlockedAdd} onCloseAction={whenClosing} onSuccess={onSuccess} maxMonthLimit={maxTX} actualMonthLimit={actualTX} currentSuscription={currentSuscription}  cloud={true}/>}
      {/* Modal para las opciones de Balance */}
      {addingBalance && <AddBalanceModalSelection shown={addingBalance} onCloseAction={whenClosing} onSuccess={onSuccess} />}
      {/* Modal para las opciones de Income */}
      {removingIncome && <AddIncomeModalSelection shown={removingIncome} onCloseAction={whenClosing} onSuccess={onSuccess} />}



      {addingOrg && (
        <AddOrganizationSlots
          shown={addingOrg}
          onCloseAction={handleOrg}
          onSuccess={handleOrg}
          MaxDBSize={maxOrganizations}
          ActualDBSize={currentOrganizations}
        />
      )}

      <Drawer
        isOpen={isAddOrgDrawerOpen}
        toggleSidebar={toggleAddOrgDrawer}
        name="Add Organization"
      >
        <AddOrganizationModal
          opened={isAddOrgDrawerOpen}
          handleClose={toggleAddOrgDrawer}
          updateTable={() => getProjects('')} // Llama a la función de actualización de proyectos si es necesario
          show
          regions={regions} organization_devs={developers} />
      </Drawer>

    </Fragment>
  );
};

export default Consumptions;