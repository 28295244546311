import React, { Fragment } from 'react';
import './chapter.css'
const Infrastructure_AVS = () => (
  <Fragment>
     <div>
    <h2>AVS (AVAP Virtual Server) Network Architecture Types (Infrastructure)</h2>
    
    <p>
      This document outlines the most common deployment alternatives for AVAP infrastructure, ranging from basic models available in the Individual edition to complex interconnected networks of AVS (AVAP Virtual Server) for international infrastructures.
    </p>
    <p>
      This guide does not encompass all possible deployment and configuration options. Instead, it provides a comprehensive basic guide to assist professionals and corporate system administrators in designing the appropriate model to meet their needs.
    </p>
    <p>
      First, it is essential to understand the different distribution types and their editions.
    </p>
    <p>
      The information contained herein applies internationally and to all territories and markets where AVS (AVAP Virtual Server) is available, whether directly from the manufacturer 101OBEX, CORP or through its network of partners.
    </p>
    <p>
      <strong>AVS (AVAP Virtual Server) is distributed in two models:</strong>
    </p>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>AVAP Cloud</strong></li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>AVAP On Premise</strong></li>
    </ul>
    <p>
      <strong>AVAP Cloud</strong> is further divided into three membership types:
    </p>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>AVAP Cloud Professional</strong></li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>AVAP Cloud Business</strong></li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>AVAP Cloud Enterprise</strong></li>
    </ul>
    <p>
      <strong>AVAP On Premise</strong> is split into two types of licenses:
    </p>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>AVAP Standalone</strong> (free license)</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>AVAP Enterprise</strong> (commercial license)</li>
    </ul>
    <p>
      This document does not cover the peer-to-peer AVAP Collaborative Network. For more information related to AVAP Collaborative Network, please visit AVAP Alexandria at <a href="http://alexandria.avapframework.com/">alexandria.avapframework.com</a> and join the user community at <a href="http://communities.avapframework.com/">AVAP Communities</a>.
    </p>

    <h3>AVAP Cloud</h3>
    <p>
      Aimed at professionals, small to medium-sized businesses, and corporations whose technological strategy is cloud-based.
    </p>
    <p>
      AVAP Cloud offers a comprehensive suite for API development, publication, management, and consumption based on cloud technology.
    </p>
    <p>
      AVAP Cloud is a service provided by 101OBEX, CORP, supported by cloud infrastructure from leading industry providers: VULTR, AWS, Google Cloud, and Microsoft Azure in a hybrid model worldwide.
    </p>
    <p>
      As mentioned, AVAP Cloud can be accessed through three types of memberships:
    </p>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>AVAP Cloud Professional and Business:</strong> These memberships, beyond providing access to allocated resources, allow connection to a deployment managed by 101OBEX, CORP. This setup is entirely transparent to the user, focusing on working with API Projects without requiring time and resources for infrastructure management. Ideal for professionals and small to medium-sized businesses.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>AVAP Cloud Enterprise:</strong> This membership enables the management of a full AVAP infrastructure deployment on cloud services, giving complete control over all elements: AVS (AVAP Virtual Server), AVAP Gateway Advanced, and AVAP Interpreter & Language Server. It allows for the integration of cloud networks and on-premise networks, creating hybrid deployments. This is aimed at corporations.</li>
    </ul>

    <h3>AVAP On Premise</h3>
    <p>
      Targeted at professionals, small to medium-sized businesses, and corporations that prefer having all or part of their infrastructure hosted on their premises or within their own cloud.
    </p>
    <p>
      AVAP Enterprise provides a full suite for API development, publication, management, and consumption in corporate environments.
    </p>
    <p>
      The on-premise editions are offered in three types of distributions and licenses:
    </p>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>AVS (AVAP Virtual Server) in standalone mode:</strong> A free license distribution aimed at non-professional markets, intended for educational and personal non-commercial use.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>AVS (AVAP Virtual Server) in p2p mode:</strong> Allows an AVS (AVAP Virtual Server) to join the AVAP Collaborative Network.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>AVS (AVAP Virtual Server) in Enterprise mode:</strong> Provides a complete experience with all services for full network deployment and management with tools designed for large infrastructure management.</li>
    </ul>

    <p>
      For more information related to AVS (AVAP Virtual Server), AVAP Interpreter & Language, or AVAP Cloud, please visit AVAP Alexandria at <a href="http://alexandria.avapframework.com/">alexandria.avapframework.com</a> and connect with the user community at <a href="http://communities.avapframework.com/">AVAP Communities</a>.
    </p>
  </div>
  </Fragment>
);
export default Infrastructure_AVS;
