import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import {  Modal, Form, Alert, Spinner, Container, Row, Col } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import ObexAlert from '../basicComponents/ObexAlerts';



type CommissionsSectionsModalProps = {
  shown: boolean,
  receivedSections: Array<any>,
  project: string,
  property: string,
  profile: string,
  actionId: number,
  onCloseAction: () => void,
  onSuccess: () => void,
  saveAction: (customSections) => any
}

const CommissionsSectionsModal:FunctionComponent<CommissionsSectionsModalProps> = (props) => {
  
  const { shown, receivedSections, project, actionId, property, profile, onCloseAction, onSuccess, saveAction } = props;

  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const startSaving = () => {
    setSuccess('');
    setError('');
    setLoading(true);
  }

  const saveSections = async () => {
    try {
      startSaving();
      const cleanedSections = cleanSections();
      const result = await saveAction(cleanedSections);
      const { success, data, message } = result;
      if (!success) throw { message };
      setSuccess('Sections edited');
      await onSuccess();
      setSuccess('');
    } catch (error) {
      setError(error.message || error);
      console.error('Error saving sections ', error)
    }
    setLoading(false);
  }

  const close = () => {
    // Si salimos sin guardar se restablecen los tramos para resetear si se vuelve a abrir el mismo
    setError('');
    setSections(receivedSections);
    onCloseAction();
  }

  const cleanSections = () => {
    const cleaned = [];
    for (let i = 0; i < sections.length; i++) {
      const cs = Object.assign(sections[i]);
      Object.keys(cs).forEach(k => {
        if (cs[k] !== 0 && !cs[k]) { 
          delete cs[k] 
        };
      })
      if (Object.keys(cs).length) cleaned.push(cs);      
    }
    return cleaned;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    await saveSections();
  }

  useEffect(() => {
    setSections(receivedSections);
  }, [receivedSections])


  const editSectionValue = (index, key, value) => {
    const intValue = parseInt(value);
    const ns = [...sections];
    ns[index][key] = intValue;
    setSections(ns);
  }

  const removeSection = (index) => {
    const ns = [...sections];
    ns.splice(index, 1);
    setSections(ns);
  }

  const addSection = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const ns = [...sections];
    ns.push({ desde: '', hasta: '', fijo: ''});
    setSections(ns);
  }  

  const renderSections = () => {
    const sectionsInputs = [];
    for (let i = 0; i < sections.length; i++) {
      sectionsInputs.push(
        <Form.Row className="mb-12">
          <Col md="3">
            <Form.Label column sm="2" className="obex-form-label">From</Form.Label>
            <Form.Control 
              type="number" 
              placeholder="From" 
              value={sections[i].desde} 
              onChange={(e) => {
                const { value } = e.target;
                editSectionValue(i, 'desde', value);
              }}/>
          </Col>
          <Col md="3">
            <Form.Label column sm="2" className="obex-form-label">To</Form.Label>
            <Form.Control 
              type="number" 
              placeholder="To" 
              value={sections[i].hasta} 
              onChange={(e) => {
                const { value } = e.target;
                editSectionValue(i, 'hasta', value);
              }}/>
          </Col>
          <Col md="3">
            <Form.Label column sm="2" className="obex-form-label">Fix</Form.Label>
            <Form.Control 
              type="number" 
              placeholder="Fix" 
              value={sections[i].fijo} 
              onChange={(e) => {
                const { value } = e.target;
                editSectionValue(i, 'fijo', value);
              }}/>
          </Col>
          <Col md="2" className="text-center" style={{ paddingTop: '47px' }}>
            <FontAwesomeIcon cursor="pointer" 
              icon={faMinusCircle} 
              size="2x" 
              onClick={(e) => removeSection(i)}
            />
          </Col>
        </Form.Row>
      )
    }
    return sectionsInputs;
  }

  return (
    <Modal dialogClassName="addons-dialog w-75 obex-dialog" show={shown} onHide={close}>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center w-100">
          <Row>
            <Col className="addons-title">
              <span>Editing sections</span>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Form className="obex-form">
            {renderSections()}
            <Form.Row>
              <Col className="text-right" style={{margin: '40px 105px 10px 10px'}}>
              <span><FontAwesomeIcon icon={faPlusCircle} size="1x" className="mr-1"/> Add new section</span>
              <FontAwesomeIcon cursor="pointer" 
                icon={faPlusCircle} 
                size="2x" 
                onClick={addSection}
              />
              </Col>
            </Form.Row>
          </Form>
          <ObexAlert type="error" msg={error}/>
          <ButtonSubmit 
            submitDisabled={!sections.length}
            success={success} 
            loading={loading} 
            error={error} 
            handleSubmit={handleSubmit} 
            textButton="Save"
          />
        </Container>
      </Modal.Body>
    </Modal> 
  )
}

export default CommissionsSectionsModal;


