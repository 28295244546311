import React, { Fragment } from 'react';
import './chapter.css'
const Chapter11_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h1>StartLoop() Statement</h1>
      <p>
        The loop statement in AVAP™ allows you to execute a block of code repeatedly until a specific condition is met. Below is a detailed explanation of its syntax and functionality.
      </p>
    </div>
  </Fragment>
);

export default Chapter11_1;