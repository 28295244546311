import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h4>1.1.3 Strings (str)</h4>
  <p>
    Strings represent text. In AVAP™, they are defined using the <code>str</code> data type.
  </p>
  <pre>
    <code>
{`text_string = "Hello, world!"`}
    </code>
  </pre>
</div>
  </Fragment>
);
export default Intro_1;
