import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const CreateProductVersionForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        file: null,
        controlPlane: '',
        gatewayService: '',
    });

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: files ? files[0] : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Product Version Data Submitted:", formData);
        // Lógica para enviar al backend o procesar el archivo cargado
    };

    const handleCancel = () => {
        setFormData({
            name: '',
            file: null,
            controlPlane: '',
            gatewayService: '',
        });
    };

    return (
        <Form className="cloudForm" onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
            <h2>Create Product Version</h2>
            <p>
                Provide a unique name for your API version and upload your OpenAPI Specification (OAS) file in YAML or JSON format.
                This file will be used by developers in the Developer Portal to understand and interact with your API’s endpoints and data models.
            </p>

            {/* Name */}
            <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter a unique name for the API version"
                    required
                />
            </Form.Group>

            {/* File Upload */}
            <Form.Group controlId="file">
                <Form.Label>Upload File</Form.Label>
                <Form.File
                    name="file"
                    label={formData.file ? formData.file.name : "No file selected"}
                    onChange={handleChange}
                    accept=".yaml, .yml, .json"
                    custom
                />
                <Form.Text className="text-muted">
                    YAML or JSON files supported
                </Form.Text>
            </Form.Group>

            {/* Control Plane */}
            <Form.Group controlId="controlPlane">
                <Form.Label>Select Control Plane</Form.Label>
                <Form.Control
                    as="select"
                    name="controlPlane"
                    value={formData.controlPlane}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select...</option>
                    <option value="controlPlane1">Control Plane 1</option>
                    <option value="controlPlane2">Control Plane 2</option>
                    {/* Agregar más opciones si es necesario */}
                </Form.Control>
            </Form.Group>

            {/* Gateway Service */}
            <Form.Group controlId="gatewayService">
                <Form.Label>Gateway Service</Form.Label>
                <Form.Control
                    as="select"
                    name="gatewayService"
                    value={formData.gatewayService}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select any Gateway service not yet linked to an API product version</option>
                    <option value="gatewayService1">Gateway Service 1</option>
                    <option value="gatewayService2">Gateway Service 2</option>
                    {/* Agregar más opciones si es necesario */}
                </Form.Control>
                <Form.Text className="text-muted">
                    Linking does not have an impact on your Gateway service.
                </Form.Text>
            </Form.Group>

            {/* Buttons */}
            <div className="d-flex justify-content-between mt-4">
                <Button variant="primary" type="submit">Save Product Version</Button>
                <Button variant="outline-secondary" onClick={handleCancel}>Cancel</Button>
            </div>
        </Form>
    );
};

export default CreateProductVersionForm;
