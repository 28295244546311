import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h3>2.3 Declaration of Variables in AVAP™</h3>
  <p>In AVAP™, variables can be declared in several ways:</p>
</div>
  </Fragment>
);
export default Intro_1;
