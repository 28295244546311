import React from 'react';
import './chapter.css'
const Chapter9_16 = () => (
    <div>
        <h2>6.2.4. Comprehensions for Lists, Sets and Dictionaries</h2>
        <p>
            To construct a list, set, or dictionary, AVAP provides special syntax called "comprehension," each in two flavors:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>The contents of the container are listed explicitly.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>They are computed using a set of loop and filtering instructions, called a "comprehension."</li>
        </ul>
        <p>
            Common syntax elements for comprehensions are:
        </p>
        <pre>
            <code>
{`comprehension ::=  assignment_expression comp_for
comp_for      ::=  "for" target_list "in" or_test [comp_iter]
comp_iter     ::=  comp_for | comp_if
comp_if       ::=  "if" or_test [comp_iter]
`}
            </code>
        </pre>
        <p>
            A comprehension consists of a single expression followed by at least one <code>for</code> clause and zero or more <code>for</code> or <code>if</code> clauses. In this case, the elements of the new container are those produced by considering each <code>for</code> or <code>if</code> clause as a block, nested from left to right, and evaluating the expression to produce an element each time the innermost block is reached.
        </p>
    </div>
);

export default Chapter9_16;
