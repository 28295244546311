import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import "./Accesos_EN_Styles.css"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import "./../../../../assets/css/effectStickySections.css"


const Accesos_EN = () => {

    const [copiado, setCopiado] = useState(false);
    const [copiado2, setCopiado2] = useState(false);
    const [copiado3, setCopiado3] = useState(false);
    const [codesAuth, setCodesAuth] = useState({
        selected: {
            id: "curl",
            type: "bash",
            code: ` curl --location --request POST 'URL_BASE/ws/users.py/checksession' 
 --form 'country_code=MX' 
 --form 'user_id=4532' 
 --form 'session_id=395-61pklGG-uYj3XUQMFjOLgg=='`
        },
        options: [
            {
                id: "curl",
                type: "bash",
                code: ` curl --location --request POST 'URL_BASE/ws/users.py/checksession' 
 --form 'country_code=MX' 
 --form 'user_id=4532' 
 --form 'session_id=395-61pklGG-uYj3XUQMFjOLgg=='`
            },
            {
                id: "python",
                type: "python",
                code: `import requests
url = "URL_BASE/ws/users.py/checksession"
payload = {'country_code': 'MX',
'user_id': '4532',
'session_id': '395-61pklGG-uYj3XUQMFjOLgg=='}
files = []
headers= {}
response = requests.request("POST", url, headers=headers, data = payload, files = files)
print(response.text.encode('utf8'))`
            },
            {
                id: "nodejs",
                type: "javascript",
                code: `var request = require('request');
var options = {
    'method': 'POST',
    'url': 'URL_BASE/ws/users.py/checksession',
    'headers': {
    },
    formData: {
        'country_code': 'MX',
        'user_id': '4532',
        'session_id': '395-61pklGG-uYj3XUQMFjOLgg=='
    }
};
request(options, function (error, response) { 
    if (error) throw new Error(error);
    console.log(response.body);
});`
            },
            {
                id: "javascript",
                type: "javascript",
                code: `var formdata = new FormData();
formdata.append("country_code", "MX");
formdata.append("user_id", "4532");
formdata.append("session_id", "395-61pklGG-uYj3XUQMFjOLgg==");
var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
};
fetch("URL_BASE/ws/users.py/checksession", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));`
            }
        ]
    });

    const [codesConnec, setCodesConnec] = useState({
        selected: {
            id: "curl",
            type: "bash",
            code: ` curl --location --request POST 'URL_BASE/ws/cuentas.py/activar' 
 --form 'country_code=MX' 
 --form 'user_id=4532' 
 --form 'session_id=410-GvUO9y9b3ll_gQXTlYxH9Q==' 
 --form 'phone=7229063245'`
        },
        options: [
            {
                id: "curl",
                type: "bash",
                code: ` curl --location --request POST 'URL_BASE/ws/cuentas.py/activar' 
 --form 'country_code=MX' 
 --form 'user_id=4532' 
 --form 'session_id=410-GvUO9y9b3ll_gQXTlYxH9Q==' 
 --form 'phone=7229063245'`
            },
            {
                id: "python",
                type: "python",
                code: `import requests
url = "URL_BASE/ws/cuentas.py/activar"
payload = {
    'country_code': 'MX',
    'user_id': '4532',
    'session_id': '410-GvUO9y9b3ll_gQXTlYxH9Q==',
    'phone': '7229063245'
}
files = []
headers= {}
response = requests.request("POST", url, headers=headers, data = payload, files = files)
print(response.text.encode('utf8'))`
            },
            {
                id: "nodejs",
                type: "javascript",
                code: `var request = require('request');
var options = {
    'method': 'POST',
    'url': 'URL_BASE/ws/cuentas.py/activar',
    'headers': {
    },
    formData: {
        'country_code': 'MX',
        'user_id': '4532',
        'session_id': '410-GvUO9y9b3ll_gQXTlYxH9Q==',
        'phone': '7229063245'
    }
};
request(options, function (error, response) { 
    if (error) throw new Error(error);
    console.log(response.body);
});`
            },
            {
                id: "javascript",
                type: "javascript",
                code: `var formdata = new FormData();
formdata.append("country_code", "MX");
formdata.append("user_id", "4532");
formdata.append("session_id", "410-GvUO9y9b3ll_gQXTlYxH9Q==");
formdata.append("phone", "7229063245");
var requestOptions = {
    method: 'POST',
    body: formdata,
    redirect: 'follow'
};
fetch("URL_BASE/ws/cuentas.py/activar", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));`
            }
        ]
    });

    const handleChangeCode1 = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = codesAuth.options.find(option => option.id === selectedValue);
        setCodesAuth({ ...codesAuth, selected: selectedOption });
        setCopiado2(false);

    };

    const handleChangeCode2 = (event) => {
        const selectedValue = event.target.value;
        const selectedOption = codesConnec.options.find(option => option.id === selectedValue);
        setCodesConnec({ ...codesConnec, selected: selectedOption });
        setCopiado3(false);

    };

    const codigo = `https://api.stripe.com`;


    return (
        <div className="container-fluid">

            <div className="row ">
                <div className="col-md-6 pl-md-5">

                    <h2 className="text-bold">API Reference </h2>

                    <div className="mb-2">The Stripe API is organized around REST. Our API has predictable resource-oriented URLs, accepts formencoded request bodies, returns JSON-encoded responses, and uses standard HTTP response codes, authentication, and verbs.</div>
                    <div className="mb-2">You can use the Stripe API in test mode, which doesn’t affect your live data or interactwith the banking networks. The API key you use to authenticate the request determines whether the request is live mode or test mode.
                    </div>
                    <div className="mb-2">The Stripe API doesn’t support bulk updates. You can work on only one object per request.</div>
                    <div>The Stripe API differs for every account as we release new versions and tailor functionality. Log in to
                        see
                        docs customized to your version of the API, with your test key and data.</div>
                </div>
                <div className="col-md-6">
                    <h4 className="mt-5 text-bold">Just getting started?
                    </h4>
                    <div className="mb-4">Check out our development quickstart guide.</div>
                    <h4 className="text-bold">Not a developer?
                    </h4>
                    <div>Use Stripe’s no-code options or apps from our partners to get started with Stripe and to do more with
                        your Stripe account—no code required.</div>

                    <div className='mt-3'>
                        <div className="backgroundHeader rounded-top p-2 px-3 d-flex justify-content-between">
                            <div>BASE URL</div>
                            <div>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={<Tooltip id="tooltip-top"> {copiado ? "copied" : "copy"}</Tooltip>}
                                >
                                    <CopyToClipboard text={codigo} onCopy={() => setCopiado(true)}>
                                        <div className='sizeClipBoard'>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#ffffff"><path d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM96 424c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm96-192c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm128 368c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z" /></svg>
                                        </div>
                                    </CopyToClipboard>
                                </OverlayTrigger>

                            </div>
                        </div>
                        <SyntaxHighlighter style={darcula} className="rounded-bottom m-0 backgroundCode">
                            {/* {numerarLineas(codigo).join('\n')} */}
                            {codigo}
                        </SyntaxHighlighter>
                    </div>

                    <div className='border rounded mt-4 mb-5'>
                        <div className='d-flex flex-column'>
                            <div className='pt-2 pl-1'>CLIENT LIBRARIES</div>
                            <div className='d-flex justify-content-center px-2 py-3'>
                                <div className='containerLanguages justify-content-between  w-75 flex-wrap'>
                                    <div className='d-flex flex-column align-items-center'>
                                        <div>img</div>
                                        <div className='text-center nameLanguages'>Ruby</div>
                                    </div>
                                    <div className='d-flex flex-column align-items-center'>
                                        <div>img</div>
                                        <div className='text-center nameLanguages'>Python</div>
                                    </div>
                                    <div className='d-flex flex-column align-items-center'>
                                        <div>img</div>
                                        <div className='text-center nameLanguages'>PHP</div>
                                    </div>
                                    <div className='d-flex flex-column align-items-center'>
                                        <div>img</div>
                                        <div className='text-center nameLanguages'>Java</div>
                                    </div>
                                    <div className='d-flex flex-column align-items-center'>
                                        <div>img</div>
                                        <div className='text-center nameLanguages'>Node.js</div>
                                    </div>
                                    <div className='d-flex flex-column align-items-center'>
                                        <div>img</div>
                                        <div className='text-center nameLanguages'>Go</div>
                                    </div>
                                    <div className='d-flex flex-column align-items-center'>
                                        <div>img</div>
                                        <div className='text-center nameLanguages'>.NET</div>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <div className='border-top px-2 py-3 backgroundDefault'>
                            By default, the Stripe API DOCs demostrate using curl to interact with the API over HTTP, Select one of our official <span>client libraries</span> to see examples in code

                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="row ">
                <div className="col-md-6 pl-md-5">
                    <h2 className="text-bold">Authentication </h2>
                    <div className="mb-2">The Stripe API uses API keys to authenticate requests. You can view and manage your API
                        keys in the Stripe
                        Dashboard.</div>
                    <div className="mb-2">Test mode secret keys have the prefix sk_test_ and live mode secret keys have the prefix
                        sk_live_.
                        Alternatively, you can use restricted API keys for granular permissions.
                    </div>
                    <div className="mb-2">Your API keys carry many privileges, so be sure to keep them secure! Do not share your
                        secret API keys
                        in
                        publicly accessible areas such as GitHub, client-side code, and so forth.
                    </div>
                    <div className="mb-2">All API requests must be made over HTTPS. Calls made over plain HTTP will fail. API
                        requests without authentication will also fail.
                    </div>
                    <div>Related video: Authentication </div>
                </div>
                <div className="col-md-6">
                    <div className='effectStickySections'>
                    <div className='mt-5 '>
                        <div className="backgroundHeader rounded-top p-2 px-3 d-flex justify-content-between">
                            <div>AUTHENTICATED REQUEST</div>

                            <div className='d-flex'>
                                <div className='pr-3'>
                                    <select name="select" className='customSelectCode rounded' onChange={handleChangeCode1}>
                                        <option value="curl">cURL</option>
                                        <option value="python" >Python</option>
                                        <option value="nodejs" >Nodejs</option>
                                        <option value="javascript" >Javascript</option>
                                    </select>
                                </div>
                                <div>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-top"> {copiado2 ? "copied" : "copy"}</Tooltip>}
                                    >
                                        <CopyToClipboard text={codesAuth.selected.code} onCopy={() => setCopiado2(true)}>
                                            <div className='sizeClipBoard'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#ffffff"><path d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM96 424c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm96-192c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm128 368c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z" /></svg>
                                            </div>
                                        </CopyToClipboard>
                                    </OverlayTrigger>
                                </div>

                            </div>
                        </div>
                        <SyntaxHighlighter style={darcula} key={codesAuth.selected.type} language={codesAuth.selected.type} className="rounded-bottom m-0 backgroundCode" showLineNumbers={true} showInlineLineNumbers={true}>
                            {codesAuth.selected.code}
                        </SyntaxHighlighter>
                    </div>
                    <div className='border rounded mt-4 mb-5'>
                        <div className='pt-2 pl-2 backgroundDefault'>YOUR API KEY</div>
                        <div className='border-top px-2 py-3 '>
                            <div className='mb-2'>A sample test API KEY is included in all the examples here, so you can test any example right away. Do not submit any personally identifiable information in requests made with this key.</div>

                            <div>To test requests using your account, replace the sample API KEY with your actual API key or <span>sign in.</span></div>


                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className="row ">
                <div className="col-md-6 pl-md-5 my-5 ">
                    <h2 className="text-bold">Connected Accounts
                    </h2>
                    <div className="mb-2">To act as connected accounts, clients can issue requests using the Stripe-Account special
                        header. Make sure
                        that this header contains a Stripe account ID, which usually starts with the acct_ prefix.
                    </div>
                    <div className="mb-2">The value is set per-request as shown in the adjacent code sample. Methods on the returned
                        object reuse
                        the same account ID.
                    </div>
                    <div>Related guide: Making API calls for connected accounts
                    </div>
                </div>
                <div className="col-md-6">
                    <div className='mt-5 effectStickySections'>
                        <div className="backgroundHeader rounded-top p-2 px-3 d-flex justify-content-between">
                            <div>PRE-REQUEST ACCOUNT</div>
                            <div className='d-flex'>
                                <div className='pr-3'>
                                    <select name="select" className='customSelectCode rounded' onChange={handleChangeCode2}>
                                        <option value="curl">cURL</option>
                                        <option value="python" >Python</option>
                                        <option value="nodejs" >Nodejs</option>
                                        <option value="javascript" >Javascript</option>
                                    </select>
                                </div>
                                <div>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-top"> {copiado3 ? "copied" : "copy"}</Tooltip>}
                                    >
                                        <CopyToClipboard text={codesConnec.selected.code} onCopy={() => setCopiado3(true)}>
                                            <div className='sizeClipBoard'>
                                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#ffffff"><path d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM96 424c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm96-192c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm128 368c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z" /></svg>
                                            </div>
                                        </CopyToClipboard>
                                    </OverlayTrigger>
                                </div>

                            </div>
                        </div>
                        <SyntaxHighlighter style={darcula} key={codesConnec.selected.type} language={codesConnec.selected.type} className="rounded-bottom m-0 backgroundCode" showLineNumbers={true} showInlineLineNumbers={true}>
                            {codesConnec.selected.code}
                        </SyntaxHighlighter>
                    </div>
                </div>
            </div>
            <hr />
            <div className="row ">
                <div className="col-md-6 pl-md-5">

                    <h2 className="text-bold">Errors
                    </h2>
                    <div className="mb-2">Stripe uses conventional HTTP response codes to indicate the success or failure of an API
                        request. In
                        general: Codes in the 2xx range indicate success. Codes in the 4xx range indicate an error that failed
                        given
                        the information provided (e.g., a required parameter was omitted, a charge failed, etc.). Codes in the
                        5xx
                        range indicate an error with Stripe’s servers (these are rare).
                    </div>
                    <div className="mb-4">Some 4xx errors that could be handled programmatically (e.g., a card is declined) include
                        an error code that briefly explains the error reported.
                    </div>
                    <h4 className="text-bold">Attributes
                    </h4>
                </div>
                <div className="col-md-6">
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-6 pl-md-5">
                    <div className="mb-2">typeenum
                    </div>
                    <div className="mb-4">The type of error returned. One of api_error, card_error, idempotency_error, or invalid_request_error
                    </div>
                </div>
                <div className="col-md-6">
                </div>
            </div>
        </div>


    )
}

export default Accesos_EN;