import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Inter_networks_pool_stake = () => 
 {
  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{
        "status": true,
        "result": "<603808>",
        "elapsed": 0.326801061630249
       }`,
      copied: false
    },
    answe_ko1: {
      title:"Answer ko",
      type: "json",
      code: `{
        "status": false,
        "level": <string>,
        "message": <string>,
        "error": <string>
        }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: `import requests
      url = "http://34.121.95.179:80/ws/users.py/inter_networks_pool_stake?country_code=ES&id_usuario=95&id_sesion=671-UTl1w5hL5fi7JmMPt5caTA==&amount=101&id_origen=212"
      payload={}
      headers = {
       '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'
      }
      response = requests.request("GET", url, headers=headers, data=payload)
      print(response.text)`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: `import requests
        url = "http://34.121.95.179:80/ws/users.py/inter_networks_pool_stake?country_code=ES&id_usuario=95&id_sesion=671-UTl1w5hL5fi7JmMPt5caTA==&amount=101&id_origen=212"
        payload={}
        headers = {
         '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'
        }
        response = requests.request("GET", url, headers=headers, data=payload)
        print(response.text)`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: `var request = require('request');
            var options = {
             'method': 'GET',
             'url': 'http://34.121.95.179:80/ws/users.py/inter_networks_pool_stake?country_code=ES&id_usuario=95&id_sesion=671-UTl1w5hL5fi7JmMPt5caTA==&amount=101&id_origen=212',
             'headers': {
             '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'
             }
            };
            request(options, function (error, response) {
             if (error) throw new Error(error);
             console.log(response.body);
            });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: `var myHeaders = new Headers();
            myHeaders.append("101ObexApiKey", "KcM6Vr7EfNnKScEi00jdritBzNjWAOkz");
            
            var requestOptions = {
             method: 'GET',
             headers: myHeaders,
             redirect: 'follow'
            };
            
            fetch("http://34.121.95.179:80/ws/users.py/inter_networks_pool_stake?country_code=ES&id_usuario=95&id_sesion=671-UTl1w5hL5fi7JmMPt5caTA==&amount=101&id_origen=212", requestOptions)
             .then(response => response.text())
             .then(result => console.log(result))
             .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: `curl --location --request GET 'http://34.121.95.179:80/ws/users.py/inter_networks_pool_stake?country_code=ES&id_usuario=95&id_sesion=671-UTl1w5hL5fi7JmMPt5caTA==&amount=101&id_origen=212' \
          --header '101ObexApiKey: KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'`
      }
    ]
  }
  return(
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
      <h1 id="inter-networls-pool-stake">Inter Networls Pool Stake</h1>
      <h2 id="wsuserspyinter_networks_pool_stake">
        /ws/users.py/inter_networks_pool_stake
      </h2>
      <h3 id="receives">Receives</h3>
      <p>
        All the parameters that the service receives must be indicated in the body
        of the request.
      </p>
      <div className='overflow-auto'>
      <table>
        <thead>
          <tr>
            <th align="center">Parameter</th>
            <th align="center">Format</th>
            <th align="center">Obligatory</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
            <th align="center">Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="center">country_code</td>
            <td align="center">
              ISO 3166 alpha-2 &#40;
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                https://www.iso.org/glossary-for-iso-3166.html
              </a>
              &#41;
            </td>
            <td align="center">Yes</td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
            <td align="center">ES</td>
          </tr>
          <tr>
            <td align="center">user_id</td>
            <td align="center">Int</td>
            <td align="center">Yes</td>
            <td align="center">Identifier of the user making the request</td>
            <td align="center">N/A</td>
            <td align="center">12</td>
          </tr>
          <tr>
            <td align="center">session_id</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send signature to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Obtained by identifying the user with ID id_usuario on the platform
            </td>
            <td align="center">N/A</td>
            <td align="center">34242-7M94x6WvLh7-b19HC6PM_g==</td>
          </tr>
          <tr>
            <td align="center">signature</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send session to &#39;Access Service&#39;&#41;
            </td>
            <td align="center">
              Enables you to sign the request without having to log in
            </td>
            <td align="center">N/A</td>
            <td align="center">328621ee3acda7a582338c5bba444c3094cd6f29</td>
          </tr>
          <tr>
            <td align="center">amount</td>
            <td align="center">int</td>
            <td align="center">Yes</td>
            <td align="center">Stake Amount</td>
            <td align="center">N/A</td>
            <td align="center">23328000</td>
          </tr>
          <tr>
            <td align="center">origin_id</td>
            <td align="center">int</td>
            <td align="center">Yes</td>
            <td align="center">
              Identifier of the origin where the funds are obtained to carry out
              the stake
            </td>
            <td align="center">N/A</td>
            <td align="center">23</td>
          </tr>
        </tbody>
      </table>
      </div>
      <h2 id="returns">Returns:</h2>
      <p>
        Depending on the result of the operation, this service can return two
        different JSON:
      </p>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
      <h2 id="where">Where:</h2>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>result:</code> transaction code for the stake operation.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>elapsed:</code> Operation execution time.
        </li>
      </ul>
      <h3 id="answer-json-ko">Answer JSON KO:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
      <h2 id="where-1">Where:</h2>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>level:</code> Error importance level.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>message:</code> Error message.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>error:</code> Sole error code.
        </li>
        <h2 id="business-logic">Business logic:</h2>
      <p>
        This service aims to stake the network selected by the user drawn to the
        selected wallet.
      </p>
      </ul>
      </div>
      <div className='col-md-5'>
      <h2 id="example-requests">Example requests:</h2>
      <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
     
      </div>
      </div>
    </Fragment>
  );
 }
export default Inter_networks_pool_stake;