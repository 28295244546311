import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Vault = () => 
  {
    const codes ={
      Retur: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": true, 
          "message": "Transaction registered successfully", 
          "elapsed": 1.70774507522583
        }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": false, 
          "level": <string>, 
          "message": <string>, 
          "error": <string> 
        }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requestsurl = "http://grayghosttoken.mooo.com/ws/util.py/vault?account=0x902f956650c5cDfDE40CCeC5E7cC2f565Fe3fAf0&amount=74006285000000000000000000&time=23328000&vault_address=0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735&abi=\"abi\"&transaction_hash=0x67f28bb1c81128f88b09e0fe0d71a9cf97670dd5ced9c1a2a0dfcc83e34f406d&date=2021-10-07&unblock=2022-07-07&currency=GRAY&network=4&email=rafa.ruiz@waynnovate.com&refcode=NOREF"
        payload={}
        headers = { '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'}
        response = requests.request("GET", url, headers=headers, data=payload)
        print(response.text)`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requestsurl = "http://grayghosttoken.mooo.com/ws/util.py/vault?account=0x902f956650c5cDfDE40CCeC5E7cC2f565Fe3fAf0&amount=74006285000000000000000000&time=23328000&vault_address=0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735&abi=\"abi\"&transaction_hash=0x67f28bb1c81128f88b09e0fe0d71a9cf97670dd5ced9c1a2a0dfcc83e34f406d&date=2021-10-07&unblock=2022-07-07&currency=GRAY&network=4&email=rafa.ruiz@waynnovate.com&refcode=NOREF"
          payload={}
          headers = { '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'}
          response = requests.request("GET", url, headers=headers, data=payload)
          print(response.text)`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
               'method': 'GET',
               'url': 'http://grayghosttoken.mooo.com/ws/util.py/vault?account=0x902f956650c5cDfDE40CCeC5E7cC2f565Fe3fAf0&amount=74006285000000000000000000&time=23328000&vault_address=0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735&abi="abi"&transaction_hash=0x67f28bb1c81128f88b09e0fe0d71a9cf97670dd5ced9c1a2a0dfcc83e34f406d&date=2021-10-07&unblock=2022-07-07&currency=GRAY&network=4&email=rafa.ruiz@waynnovate.com&refcode=NOREF',
               'headers': {
               '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'
               }
              };
              request(options, function (error, response) {
               if (error) throw new Error(error);
               console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var myHeaders = new Headers();
              myHeaders.append("101ObexApiKey", "KcM6Vr7EfNnKScEi00jdritBzNjWAOkz");
              
              var requestOptions = {
               method: 'GET',
               headers: myHeaders,
               redirect: 'follow'
              };
              
              fetch("http://grayghosttoken.mooo.com/ws/util.py/vault?account=0x902f956650c5cDfDE40CCeC5E7cC2f565Fe3fAf0&amount=74006285000000000000000000&time=23328000&vault_address=0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735&abi=\"abi\"&transaction_hash=0x67f28bb1c81128f88b09e0fe0d71a9cf97670dd5ced9c1a2a0dfcc83e34f406d&date=2021-10-07&unblock=2022-07-07&currency=GRAY&network=4&email=rafa.ruiz@waynnovate.com&refcode=NOREF", requestOptions)
               .then(response => response.text())
               .then(result => console.log(result))
               .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request GET 'http://grayghosttoken.mooo.com/ws/util.py/vault?account=0x902f956650c5cDfDE40CCeC5E7cC2f565Fe3fAf0&amount=74006285000000000000000000&time=23328000&vault_address=0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735&abi="abi"&transaction_hash=0x67f28bb1c81128f88b09e0fe0d71a9cf97670dd5ced9c1a2a0dfcc83e34f406d&date=2021-10-07&unblock=2022-07-07&currency=GRAY&network=4&email=rafa.ruiz@waynnovate.com&refcode=NOREF' \--header '101ObexApiKey: KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="vault-tokens">Vault Tokens</h1>
        <h2 id="wsutilpyvault">ws/util.py/vault</h2>
        <h3 id="receives">Receives</h3>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <div className='overflow-auto'>
        <table>
          <thead>
            <tr>
              <th align="center">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">country_code</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center">ES</td>
            </tr>
            <tr>
              <td align="center">account</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">Ethereum Account</td>
              <td align="center">N/A</td>
              <td align="center">0x902f956650c5cDfDE40CCeC5E7cC2f565Fe3fAf0</td>
            </tr>
            <tr>
              <td align="center">amount</td>
              <td align="center">int</td>
              <td align="center">Yes</td>
              <td align="center">cantidad de token a bloquear</td>
              <td align="center">N/A</td>
              <td align="center">74006285000000000000000000</td>
            </tr>
            <tr>
              <td align="center">time</td>
              <td align="center">int</td>
              <td align="center">Yes</td>
              <td align="center">Operatin Time</td>
              <td align="center">N/A</td>
              <td align="center">23328000</td>
            </tr>
            <tr>
              <td align="center">vault_address</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">vaulting address</td>
              <td align="center">N/A</td>
              <td align="center">0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735</td>
            </tr>
            <tr>
              <td align="center">abi</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">abi for the vault</td>
              <td align="center">N/A</td>
              <td align="center">[]</td>
            </tr>
            <tr>
              <td align="center">date</td>
              <td align="center">
                String dd/mm/yyyy, yyyy/mm/dd, dd-mm-yyyy, yyyy-mm-dd
              </td>
              <td align="center">Yes</td>
              <td align="center">Date of the operation</td>
              <td align="center">N/A</td>
              <td align="center">12/12/2022</td>
            </tr>
            <tr>
              <td align="center">unblock</td>
              <td align="center">
                String dd/mm/yyyy, yyyy/mm/dd, dd-mm-yyyy, yyyy-mm-dd
              </td>
              <td align="center">Yes</td>
              <td align="center">Date of the unblocking</td>
              <td align="center">N/A</td>
              <td align="center">12/12/2022</td>
            </tr>
            <tr>
              <td align="center">currency</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">Symbol of the token to block</td>
              <td align="center">N/A</td>
              <td align="center">GRAY</td>
            </tr>
            <tr>
              <td align="center">network</td>
              <td align="center">Int</td>
              <td align="center">Yes</td>
              <td align="center">Ethereal network identifier</td>
              <td align="center">N/A</td>
              <td align="center">4</td>
            </tr>
            <tr>
              <td align="center">email</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">Mail of the user who blocks</td>
              <td align="center">N/A</td>
              <td align="center">
                <a href="mailto:usuario@servidor.ext">usuario@servidor.ext</a>
              </td>
            </tr>
            <tr>
              <td align="center">refcode</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">Reference</td>
              <td align="center">N/A</td>
              <td align="center">REF001</td>
            </tr>
          </tbody>
        </table>
        </div>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.Retur}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code> Message indicating the result of the operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>level:</code> Error importance level.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code> Error message.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>The objective of this service is to block tokens.</p>
        </div>
        <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        </div>
        </div>
      </Fragment>
    );
  }
export default Vault;
