import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const AddWebhookForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        method: 'GET',
        url: '',
        headerKey: 'x-auth',
        headerValue: 'value',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Webhook added:", formData);
        // Aquí puedes añadir la lógica de envío al backend
    };

    const handleCancel = () => {
        // Reinicia los datos del formulario
        setFormData({
            name: '',
            method: 'GET',
            url: '',
            headerKey: 'x-auth',
            headerValue: 'value',
        });
        console.log("Action cancelled");
    };

    return (
        <Form className="cloudForm" onSubmit={handleSubmit} style={{ maxWidth: '500px', margin: '0 auto' }}>
            <h2>Add Webhook</h2>

            {/* Name Field */}
            <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter webhook name"
                    required
                />
            </Form.Group>

            {/* Method Selector */}
            <Form.Group controlId="method">
                <Form.Label>Method</Form.Label>
                <Form.Control
                    as="select"
                    name="method"
                    value={formData.method}
                    onChange={handleChange}
                    required
                >
                    <option value="GET">GET</option>
                    <option value="POST">POST</option>
                    <option value="PUT">PUT</option>
                    <option value="DELETE">DELETE</option>
                </Form.Control>
            </Form.Group>

            {/* URL Field */}
            <Form.Group controlId="url">
                <Form.Label>URL</Form.Label>
                <Form.Control
                    type="url"
                    name="url"
                    value={formData.url}
                    onChange={handleChange}
                    placeholder="Enter webhook URL"
                    required
                />
            </Form.Group>

            {/* Header Key Field */}
            <Form.Group controlId="headerKey">
                <Form.Label>Header Key</Form.Label>
                <Form.Control
                    type="text"
                    name="headerKey"
                    value={formData.headerKey}
                    onChange={handleChange}
                    placeholder="Enter header key"
                    required
                />
            </Form.Group>

            {/* Header Value Field */}
            <Form.Group controlId="headerValue">
                <Form.Label>Header Value</Form.Label>
                <Form.Control
                    type="text"
                    name="headerValue"
                    value={formData.headerValue}
                    onChange={handleChange}
                    placeholder="Enter header value"
                    required
                />
            </Form.Group>

            {/* Fixed Buttons */}
            <div className="d-flex justify-content-between">
                <Button variant="primary" type="submit">
                    Add Webhook
                </Button>
                <Button variant="outline-secondary" onClick={handleCancel}>
                    Cancel
                </Button>
            </div>
        </Form>
    );
};

export default AddWebhookForm;
