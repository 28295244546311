import React from 'react';
import './chapter.css'
const Chapter9_31 = () => (
    <div>
        <h2>Assignment Statements</h2>
        <p>
            Assignment statements in AVAP are used to (re)assign names to values and to modify attributes or elements of mutable objects. Here is the syntax:
        </p>
        <pre><code>assignment_stmt ::=  (target_list "=")+ (starred_expression | yield_expression)
target_list     ::=  target ("," target)* [","]
target          ::=  identifier
                     | "(" [target_list] ")"
                     | "[" [target_list] "]"
                     | attributeref
                     | subscription
                     | slicing
                     | "*" target</code></pre>
        <p>
            Here's a breakdown of how assignment statements work:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Assignment Operation:</strong> An assignment statement evaluates the list of expressions and assigns the single resulting object to each of the target lists, from left to right.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Recursive Definition:</strong> The assignment operation is defined recursively depending on the form of the target list.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Target List:</strong> If the target list is a single object without ending in a comma, the object is assigned to that target. If the list contains a target prefixed with an asterisk, the object must be iterable with at least as many elements as targets, minus one. Elements before the starred target are assigned to the respective targets, and the remaining elements are assigned to the starred target.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Single Target:</strong> If the target is an identifier (name), it is bound to the object in the current local namespace. For other targets, names are bound in the global or enclosing namespace, depending on `nonlocal`.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Attribute Reference:</strong> If the target is an attribute reference, the primary expression is evaluated. It must produce an object with assignable attributes.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Subscription:</strong> If the target is a subscription, the primary expression is evaluated to produce a mutable sequence or mapping object, which is then used to assign the value.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Slice:</strong> If the target is a slice, the primary expression is evaluated, and the sequence object is requested to replace the slice with the assigned sequence elements.</li>
        </ul>
        <p>
            In summary, assignment statements in AVAP are crucial for assigning values to variables and modifying data structures effectively.
        </p>
    </div>
);

export default Chapter9_31;
