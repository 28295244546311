import React from 'react';
import './chapter.css'
const Chapter9_27 = () => (
    <div>
        <h2>Comparisons</h2>
        <p>
            Unlike C, all comparison operations in Python have the same priority, which is lower than any arithmetic, shift, or bitwise operation. Also, unlike C, expressions like <code>a &lt; b &lt; c</code> have the conventional mathematical interpretation:
        </p>
        <pre>
            <code>
{`comparison    ::=  or_expr (comp_operator or_expr)*
comp_operator ::=  "<" | ">" | "==" | ">=" | "<=" | "!="
                   | "is" ["not"] | ["not"] "in"
`}
            </code>
        </pre>
        <p>
            Comparisons produce boolean values: True or False.
        </p>
        <p>
            Comparisons can be arbitrarily chained, e.g., <code>x &lt; y &lt;= z</code> is equivalent to <code>x &lt; y and y &lt;= z</code>, except that <code>y</code> is evaluated only once.
        </p>
        <p>
            Formally, if <code>a</code>, <code>b</code>, <code>c</code>, ..., <code>y</code>, <code>z</code> are expressions and <code>op1</code>, <code>op2</code>, ..., <code>opN</code> are comparison operators, then <code>a op1 b op2 c ... y opN z</code> is equivalent to <code>a op1 b and b op2 c and ... y opN z</code>, except that each expression is evaluated at most once.
        </p>
        <p>
            Note that <code>a op1 b op2 c</code> does not imply any comparison between <code>a</code> and <code>c</code>, so, for example, <code>x &lt; y &gt; z</code> is perfectly legal.
        </p>
    </div>
);

export default Chapter9_27;
