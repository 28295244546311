import React, { Fragment } from 'react';
import './chapter.css'
const Chapter1_1 = () => (
  <Fragment>
       <div>
      <h2>Chapter 1: Dynamic Programming Language</h2>
      <p>
        In this chapter, we will introduce AVAP™ as a dynamic programming language. A dynamic language is one whose behavior can be 
        modified during the runtime of the program. AVAP™ shares many characteristics with other dynamic languages, making it a powerful 
        and versatile tool for application development.
      </p>
    </div>
  </Fragment>
);
export default Chapter1_1;
