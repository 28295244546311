import React, { FunctionComponent, Component, useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Modal, Form, Button  } from 'react-bootstrap';
import ButtonSubmit from '../basicComponents/buttonSubmit';



type settingPluginProps = {
  showModal: boolean,
  onCloseModal: () => void,
  pluginName: string,
  pluginPrice: number
  pluginDescription: string
}

const SettingPluginModal:FunctionComponent<settingPluginProps> = (props) => {
  
  const { showModal, onCloseModal, pluginName, pluginPrice, pluginDescription } = props
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const getSettingFormat = async() => {};

  const postSetting = (event) => {};

  useEffect(() => {
    getSettingFormat();
  })

  const ContentModal = (
    <Form>
      <Form.Row>
        <Col>

        </Col>
      </Form.Row>
    </Form>
  )

  return(
    <Modal dialogClassName="addons-dialog obex-dialog"
      show={showModal} 
      onHide={onCloseModal}
      animation={false}
      scrollable>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center mx-auto">
          <Row>
            <Col md="12" className="addons-title">
              <span>Plugin description</span>
            </Col>
            
            <Col md-6-6>
            <div className="border-top border-black my-2 mx-3"></div>
              <span>{pluginName}</span>
            </Col>
            <Col md="12" className="addons-subtitle">
            <div className="border-top border-black my-2 mx-3"></div>
              <span>
                {pluginDescription}

              </span>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <div className="border-top border-black my-2 mx-3"></div>
      <Modal.Body className="pt-0">
       {ContentModal}
      </Modal.Body>
    </Modal>
  )
}

export default SettingPluginModal;