import React, { Fragment } from 'react';
import './chapter.css'
const Chapter7_3 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>3.2 Block Comments</h2>
      <p>
        Block comments in AVAP™ are used to add comments that span multiple lines of code. These comments begin with <code>/*</code> and end with <code>*/</code>. Everything between <code>/*</code> and <code>*/</code> is considered a comment and is ignored by the compiler.
      </p>
      <pre>
        <code>
/* This is a block comment in AVAP™ that spans multiple lines of code. It is used to explain extensive sections of code or to temporarily disable entire blocks of code. */
        </code>
      </pre>
      <p>
        Block comments are ideal for providing detailed explanations about complex sections of code or for temporarily disabling entire blocks of code during debugging.
      </p>
    </div>
  </Fragment>
);

export default Chapter7_3;