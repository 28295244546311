import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const Crear_Programa_de_Puntos_EN = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: ` {
        "status": <boolean>,
        "result": <integer>
        "elapsed": <float>,
        "codtran": <string>
    }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
    url = "URL_BASE/ws/programadepuntos.py/crear"
    payload = {
    'country_code': 'MX',
    'user_id': '4532',
    'session_id': '400-nnpRvOwTzJejvCUfGxXQOA==',
    'services': '[8,9,13,14]',
    'name': 'Lorem ipsum dolor sit amet.',
    'from': '10-06-2020',
    'until': '20-06-2020',
    'sms_user_gift': 'Lorem ipsum dolor sit amet.',
    'maximum_gifts': '1500',
    'points_for_gift': '127',
    'point_value': '1',
    'conditions': 'Lorem ipsum dolor sit amet.'
    }
    files = []
    headers= {}
    response = requests.request("POST", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
    url = "URL_BASE/ws/programadepuntos.py/crear"
    payload = {
    'country_code': 'MX',
    'user_id': '4532',
    'session_id': '400-nnpRvOwTzJejvCUfGxXQOA==',
    'services': '[8,9,13,14]',
    'name': 'Lorem ipsum dolor sit amet.',
    'from': '10-06-2020',
    'until': '20-06-2020',
    'sms_user_gift': 'Lorem ipsum dolor sit amet.',
    'maximum_gifts': '1500',
    'points_for_gift': '127',
    'point_value': '1',
    'conditions': 'Lorem ipsum dolor sit amet.'
    }
    files = []
    headers= {}
    response = requests.request("POST", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'POST',
  'url': 'URL_BASE/ws/programadepuntos.py/crear',
  'headers': {
  },
  formData: {
  'country_code': 'MX',
  'user_id': '4532',
  'session_id': '400-nnpRvOwTzJejvCUfGxXQOA==',
  'services': '[8,9,13,14]',
  'name': 'Lorem ipsum dolor sit amet.',
  'from': '10-06-2020',
  'until': '20-06-2020',
  'sms_user_gift': 'Lorem ipsum dolor sit amet.',
  'maximum_gifts': '1500',
  'points_for_gift': '127',
  'point_value': '1',
  'conditions': 'Lorem ipsum dolor sit amet.'
  }
  };
  request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
  });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var formdata = new FormData();
  formdata.append("country_code", "MX");
  formdata.append("user_id", "4532");
  formdata.append("session_id", "400-nnpRvOwTzJejvCUfGxXQOA==");
  formdata.append("services", "[8,9,13,14]");
  formdata.append("name", "Lorem ipsum dolor sit amet.");
  formdata.append("from", "10-06-2020");
  formdata.append("until", "20-06-2020");
  formdata.append("sms_user_gift", "Lorem ipsum dolor sit amet.");
  formdata.append("maximum_gifts", "1500");
  formdata.append("points_for_gift", "127");
  formdata.append("point_value", "1");
  formdata.append("conditions", "Lorem ipsum dolor sit amet.");
  var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
  };
  fetch("URL_BASE/ws/programadepuntos.py/crear", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request POST 'URL_BASE/ws/programadepuntos.py/crear' 
          --form 'country_code=MX' 
          --form 'user_id=4532' 
          --form 'session_id=400-nnpRvOwTzJejvCUfGxXQOA==' 
          --form 'services=[8,9,13,14]' 
          --form 'name=Lorem ipsum dolor sit amet.' 
          --form 'from=10-06-2020' 
          --form 'until=20-06-2020' 
          --form 'sms_user_gift=Lorem ipsum dolor sit amet.' 
          --form 'maximum_gifts=1500' 
          --form 'points_for_gift=127' 
          --form 'point_value=1' 
          --form 'conditions=Lorem ipsum dolor sit amet.'`
      }
    ]
  }

  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >Create Points Program</h1>
          <p>This service is used to create a points program.</p>
          <p>
            <span className="label post">POST:</span>
            <span> </span> <code>URL_BASE + /ws/programadepuntos.py/crear</code>
            <br></br>
          </p>
          <h2 >Receives:</h2>
          <p>
            All the parameters that the service receives must be indicated in the body
            of the request.
          </p>
          <div className='overflow-auto'>

            <table>
              <thead>
                <tr>
                  <th align="left">Parameter</th>
                  <th align="center">Format</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="left">country_code</td>
                  <td align="center">
                    <a href="https://www.iso.org/glossary-for-iso-3166.html">
                      ISO 3166 alpha-2
                    </a>
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">Country code in which the user resides</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">user_id</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">Identifier of the user making the request</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">session_id</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send signature to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Obtained by identifying the user with ID user_id on the platform
                  </td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">signature</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send session_id to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Enables you to sign the request without having to log in
                  </td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">services</td>
                  <td align="center">String</td>
                  <td align="center">Yes</td>
                  <td align="center">List of services on which points are generated</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">name</td>
                  <td align="center">String</td>
                  <td align="center">Yes</td>
                  <td align="center">Points program name</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">from</td>
                  <td align="center">String</td>
                  <td align="center">Yes</td>
                  <td align="center">Initial validity date of the points program</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">until</td>
                  <td align="center">String</td>
                  <td align="center">Yes</td>
                  <td align="center">End date of validity of the points program</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">sms_user_gift</td>
                  <td align="center">String</td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Text of the sms that will be sent to the users of the points program
                    &#40;Maximum 140 characters&#41;
                  </td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">maximum_gifts</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Maximum number of gifts to be distributed with the points program
                  </td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">points_for_gift</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">Number of points needed to get a gift</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">point_value</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">Value in pesos of 1 point</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="left">conditions</td>
                  <td align="center">String</td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Text of legal conditions to show to users who join the points
                    program &#40;Maximum 140 characters&#41;
                  </td>
                  <td align="center">N/A</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p>
            <br></br>
          </p>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
            <br></br>
          </p>
          <h3 >Answer JSON OK:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2 >Where:</h2>
          <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>result:</code> Identifier of the points program created.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>elapsed:</code> Operation execution time.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>codtran:</code> Transaction code that identifies the executed
              operation.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Business logic:</h2>
          <p>
            This service generates a points program controlled by the affiliate, and
            that is applied to the users that belong to the affiliate that has
            generated it.
          </p>

        </div>
        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>

      </div>

    </Fragment>
  );
}
export default Crear_Programa_de_Puntos_EN;
