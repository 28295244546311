// redux/store.ts

import { configureStore } from '@reduxjs/toolkit';
import nameDivReducer from './slices/KnowDivInScreen'; // Importa el reducer del slice del usuario
import counterReducer from './slices/counterSlice'; // Importa el reducer del slice del contador
import updateSidebarGreenReducer from './slices/updateSidebarGreen'; // Importa el reducer del slice del contador
// import { RootState } from './types'; // Importa el tipo de estado raíz

const store = configureStore({
  reducer: {
    nameDiv: nameDivReducer,
    counter: counterReducer,
    updateSidebarGreen: updateSidebarGreenReducer// Agrega el reducer del slice del contador al objeto reducer
  },
});

export default store;
