import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, } from 'react-bootstrap';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import FooterMenu from '../../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import HeaderAVAPCLOUD from '../headerAVAPCLOUD/HeaderAVAPCLOUD';
import SidebarAVAPCLOUD from '../sidebarAVAPCLOUD/sidebarAVAPCLOUD';
// import FooterMenu from '../../../components/functionalComponents/FooterLayout/FooterMenu';
import "./DashboardAVAPCLOUD.css"
import SupportBanner from '../../../components/DashboardComponents/supportBanner';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../../components/mobileComponents/DisclaimerCloud';

type DashProps = {
    sider: any
};



const DashboardAVAPCLOUD = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    useEffect(() => {
        //getVerified();
    }, []);

    const algo = () => {
        return (
            <div>
                test view 1
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem esse adipisci enim quo dolor necessitatibus qui vero veritatis ducimus. Voluptate ipsum, ullam maxime sapiente nisi tempore! Laudantium nesciunt perspiciatis vero!
            </div>
        );
    }

    const algo2 = () => {
        return (
            <div>
               test view 2
            </div>
        );
    }




    return (
        <>
            <BrowserView>
        <div>

            <div style={{ minHeight: '100vh' }} className="d-flex">

                <div className='newSidebar boxShadowRight' >
                    {/* <NewSidebarAVAP /> */}
                    <SidebarAVAPCLOUD/>
                </div>

                <div className='d-flex flex-column newContent'>
                    <div>
                        {/* <NewHeader /> */}
                        <HeaderAVAPCLOUD/>
                    </div>
                    <div className='marginToShowContent'>
                        <main >
                            <Switch >
                                 <Route exact path={path}>
                                    <Route path={`${path}`} component={algo} />
                                </Route> 
                                <Route path={`${path}/projects`} component={algo2}/>
                                {/* <Route path={`${path}/sigin-and-security`} component={SiginSecurity} />
                <Route path={`${path}/licenses-and-downloads`} component={LicensesAnddownloads} />
                <Route path={`${path}/project-granted`} component={ProjectGranted} />
                <Route path={`${path}/payments-and-billing`} component={PaymentsAndbilling} />
                <Route path={`${path}/privacy`} component={Privacy} /> */}

                            </Switch>
                        </main>
                    </div>
                </div>
            </div>

            <div>
                
                <FooterMenu />
                
            </div>

        </div>
        </BrowserView>
            <MobileView>
                <Disclaimer />
            </MobileView>
        </>
    )
}

export default DashboardAVAPCLOUD;