import React from 'react';
import './chapter.css'
const Chapter9_51 = () => (
    <div>
        <h2>Class Patterns</h2>
        <p>
            In AVAP, <strong>class patterns</strong> are used to match instances of specific classes. Here is a detailed overview:
        </p>
        <pre>
            <code>
{`class_pattern ::=  name "(" [pattern_arguments ","?] ")"`}
            </code>
        </pre>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Pattern Syntax:</strong> A <strong>class pattern</strong> specifies the class name followed by a parenthesized list of <strong>pattern_arguments</strong>. The pattern matches instances of the specified class.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Matching Instances:</strong> The pattern will match if the subject is an instance of the specified class and the <strong>pattern_arguments</strong> (if any) match according to the rules defined for the pattern.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Usage:</strong> Class patterns are useful for deconstructing objects based on their class and extracting values from them, enabling more precise pattern matching.</li>
        </ul>
        <p>
            These patterns provide a way to work with objects based on their class type and structure, facilitating more sophisticated pattern matching and value extraction.
        </p>
    </div>
);

export default Chapter9_51;

