import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_19 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>ormCheckTable()</h2>
      <p>
        The <code>ormCheckTable()</code> command checks for the existence of a table in a specific database and stores the result in a destination variable. This command is useful for verifying if a table already exists before attempting further operations on it.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>dbaseName</strong> <br />
          Type: <code>value</code> <br />
          Description: The name of the database in which the table's existence should be checked. It should be a string indicating the database to check.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>varTarget</strong> <br />
          Type: <code>variable</code> <br />
          Description: The variable in which the result of the check will be stored. It should be a variable that will receive a value indicating whether the table exists or not.
        </li>
      </ul>
      <h3>Command Flow</h3>
      <ol>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Check Existence:</strong> Accesses the database specified by <code>dbaseName</code> to verify if the requested table exists.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Store the Result:</strong> Saves the result of the check in the variable specified by <code>varTarget</code>. The stored value will indicate whether the table exists (True or False).</li>
      </ol>
      <h3>Example Usage</h3>
      <p>Suppose you want to check if a table called <code>users</code> exists in a database called <code>myDatabase</code>.</p>
      <pre>
        <code>
          {`// Define variables
dbaseName = "myDatabase"
tableExists = ''

// Call the command to check the existence of the table
ormCheckTable(dbaseName, tableExists)

// Return the result of the check via addResult
addResult(tableExists)`}
        </code>
      </pre>
      <p>In this example, the <code>ormCheckTable()</code> command will check for the existence of the <code>users</code> table in the <code>myDatabase</code> database. The result of the check (whether the table exists or not) will be stored in the <code>tableExists</code> variable, and this variable will be returned via <code>addResult(tableExists)</code>. The output will reflect whether the table exists (True) or not (False).</p>
    </div>
  </Fragment>
);

export default Appendices_19;
