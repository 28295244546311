import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const Last_Order_Summary = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status": true,
        "codtran": "f856c5db007fcaa5a00b9a4d56a9d40a",
        "resultado": [
                      'status': 'Being sent',
                      'status_code': 4001,
                      'transaction_code': 'a556a5de007fcaa5a00b4a4d56a9d40a',
                      'order_code': 10023,
                      'date': 2021-03-01,
                      'client_address': 'Street without number',
                      'establishment_coordinates': {
                        'lat': 40,5431311,
                        'lng': -3,6302845
                      },
                      'location_gps': {
                        'lat': 40,5431311,
                        'lng': -3,6302845
                      }
                      'delivery_time': '12:03:01',
                      'delivery_type': 'delivery',
                      'order_products_codes': [
                                                 {
                                                    "article_id":35003,
                                                    "custom":[
                                                       [
                                                          "da9922e8-57a6-4440-97db-0de2a486b323",
                                                          "00e9a2cc-60c8-4e98-ac9b-accf15b7373a",
                                                          "a6aacb94-8fad-4394-a2f1-e328fcca9c79"
                                                       ],
                                                       [
                                                          "757ccbe3-d877-485d-ae10-89f0b78c133c"
                                                       ]
                                                      ]
                                                    }
                                                 ],
                      'purchase_detail': 'Palo Alto ( Lechuga , Tomate , Cebolla , Aguacate , )',
                      'order_category_id': 'FL013',
                      'contact': {
                             "firstName":"Rafa",
                             "lastName":" ",
                             "mail":"rafa.ruiz@waynnovate.com",
                             "phone":"643340526"
                          },
                      'status_history': [
                        {
                          'new_status':'Being sent',
                          'new_status_code': 4001,
                          'previous_status': 'Being picked up',
                          'previous_status_code':4000,
                          'status_change_date': '2021-02-01 13:00.01'
                        }
                      ]
                      'delivery_price': 1.9,
                      'delivery_distance': 34000.34),
        ]
        "elapsed": 0.06796097755432129
    }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
  url = "http://34.121.95.179:80/ws/orders.py/last_order_summary?country_code=ES&user_id=133&session_id=1689-oocyMaFovWi1jljrF-eaSw==";
  payload={} 
  headers = { '101ObexApiKey': 'MS1phGJRa3WyLilN9dlZ7vurJDIpe0nM' }
  response = requests.request("GET", url, headers=headers, data=payload)
  print(response.text)`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
  url = "http://34.121.95.179:80/ws/orders.py/last_order_summary?country_code=ES&user_id=133&session_id=1689-oocyMaFovWi1jljrF-eaSw==";
  payload={} 
  headers = { '101ObexApiKey': 'MS1phGJRa3WyLilN9dlZ7vurJDIpe0nM' }
  response = requests.request("GET", url, headers=headers, data=payload)
  print(response.text)`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
var options = {
'method': 'GET',
'url': 'http://34.121.95.179:80/ws/orders.py/last_order_summary?country_code=ES&user_id=133&session_id=1689-oocyMaFovWi1jljrF-eaSw==',
'headers': {
'101ObexApiKey': 'MS1phGJRa3WyLilN9dlZ7vurJDIpe0nM'
}
};
request(options, function (error, response) {
if (error) throw new Error(error);
console.log(response.body);
});`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var myHeaders = new Headers();
myHeaders.append("101ObexApiKey", "MS1phGJRa3WyLilN9dlZ7vurJDIpe0nM");

var requestOptions = {
method: 'GET',
headers: myHeaders,
redirect: 'follow'
};

fetch("http://34.121.95.179:80/ws/orders.py/last_order_summary?country_code=ES&user_id=133&session_id=1689-oocyMaFovWi1jljrF-eaSw==", requestOptions)
.then(response => response.text())
.then(result => console.log(result))
.catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request GET 'http://34.121.95.179:80/ws/orders.py/last_order_summary?country_code=ES&user_id=133&session_id=1689-oocyMaFovWi1jljrF-eaSw==' \--header '101ObexApiKey: MS1phGJRa3WyLilN9dlZ7vurJDIpe0nM'`
      }
    ]
  }

  return (
    <Fragment>

      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >Last Order Summary</h1>
          <h2 >ws/orders.py/last_order_summary</h2>
          <h3 >Receives</h3>
          <p>
            All the parameters that the service receives must be indicated in the body
            of the request.
          </p>

          <div className='overflow-auto'>

            <table>
              <thead>
                <tr>
                  <th align="center">Parameter</th>
                  <th align="center">Format</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                  <th align="center">Example</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">country_code</td>
                  <td align="center">
                    ISO 3166 alpha-2 &#40;
                    <a href="https://www.iso.org/glossary-for-iso-3166.html">
                      https://www.iso.org/glossary-for-iso-3166.html
                    </a>
                    &#41;
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">Country code in which the user resides</td>
                  <td align="center">N/A</td>
                  <td align="center">ES</td>
                </tr>
                <tr>
                  <td align="center">user_id</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Identifier of the user making the request &#40;Administrator&#41;
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">18</td>
                </tr>
                <tr>
                  <td align="center">session_id</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send signature to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Obtained by identifying the user with ID user_id on the platform
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">34242-7M94x6WvLh7-b19HC6PM_g==</td>
                </tr>
                <tr>
                  <td align="center">signature</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send session_id to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Enables you to sign the request without having to log in
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">328621ee3acda7a582338c5bba444c3094cd6f29</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2 >Where:</h2>
          <ul>
            <li>
              <p>
                <code>status:</code> Shows if the call has been successful
                &#40;true&#41; or not &#40;false&#41;.
              </p>
            </li>
            <li>
              <p>
                <code>codtran:</code> Operation result.
              </p>
            </li>
            <li>
              <p>
                <code>result:</code> Data of the last order in course.
              </p>
              <ul>
                <li>
                  <p>
                    <code>status:</code> Status of the order.
                  </p>
                </li>
                <li>
                  <p>
                    <code>status_code</code>: Code of the actual status of the order.
                  </p>
                </li>
                <li>
                  <p>
                    <code>transaction_code</code>: Code of the transaction
                  </p>
                </li>
                <li>
                  <p>
                    <code>order_code:</code> Code of the order.
                  </p>
                </li>
                <li>
                  <p>
                    <code>date:</code> Date of the order.
                  </p>
                </li>
                <li>
                  <p>
                    <code>client_address:</code> Address of the client.
                  </p>
                </li>
                <li>
                  <p>
                    <code>establishment_coordinates</code>: Coordinates of the
                    establishment that gives the order.
                  </p>
                </li>
                <li>
                  <p>
                    <code>location_gps:</code> Coordinates of the direction that
                    receives the delivery.
                  </p>
                </li>
                <li>
                  <p>
                    <code>delivery_time:</code> Hour of the delivery.
                  </p>
                </li>
                <li>
                  <p>
                    <code>delivery_type:</code> Type of delivery &#40;to pickup/ to
                    have sent&#41;.
                  </p>
                </li>
                <li>
                  <p>
                    <code>order_products_codes:</code> List of product codes and their
                    options.
                  </p>
                </li>
                <li>
                  <p>
                    <code>purchase_detail:</code> List of the names of the products
                    with their options.
                  </p>
                </li>
                <li>
                  <p>
                    <code>order_category_id:</code> Identifier of the establishment
                    that provides the product.
                  </p>
                </li>
                <li>
                  <p>
                    <code>contact:</code> Contact of the client.
                  </p>
                </li>
                <li>
                  <p>
                    <code>status_history:</code> History of the status changes in the
                    order.
                  </p>
                  <ul>
                    <li>
                      <code>new_status:</code> New status.
                    </li>
                    <li>
                      <code>new_status_code:</code> Code of the new status.
                    </li>
                    <li>
                      <code>previous_status:</code> Previous status.
                    </li>
                    <li>
                      <code>previous_status_code:</code> Previous status code.
                    </li>
                    <li>
                      <code>status_change_date:</code> Time and date of the change of
                      status.
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <code>delivery_price:</code> Price of the delivery.
                  </p>
                </li>
                <li>
                  <p>
                    <code>delivery_distance:</code> Distance between the client and
                    the establishment that provides the order.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                <code>elapsed:</code> Operation execution time.
              </p>
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Business logic:</h2>
          <p>
            The objective of this service is to retrieve the orders that are in course
            and its details.
          </p>

        </div>
        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>

      </div>


    </Fragment>
  );
}
export default Last_Order_Summary;
