import React, { Fragment } from 'react';

const AVAP_API_Products = () => (
  <Fragment>
     <div className="body-md">
      <h1>Getting Started with API Products</h1>
      
      <h2>API Products</h2>
      <p>
        In AVAP Framework, API Products play a crucial role by consolidating all developed APIs into a single product. This document will guide you through understanding and working with API Products, explaining the processes for creation, management, and administration within the platform.
      </p>

      <h3>What is an API Product?</h3>
      <p>
        An API Product is a collection of one or more APIs grouped into a single product. In AVAP Framework, API Products facilitate the exposure and utilization of APIs by grouping them based on a functional criterion. This allows for easier management and provides a centralized point for consumption.
      </p>

      <h4>Key Features</h4>
      <ul>
        <li className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
          <strong>Functional Grouping:</strong> Consolidates multiple APIs into a single product based on a functional criterion, simplifying management.
        </li>
        <li className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
          <strong>Consolidated Management:</strong> Facilitates administration and version control of the API set.
        </li>
      </ul>

      <h3>Steps to Get Started with API Products</h3>
      <ol>
        <li><strong>Open Your IDE:</strong> Start your Integrated Development Environment (IDE), which can be either Microsoft Visual Studio Code with AVAP extensions or AVAP Dev Studio. Ensure that your developer token is active and accessible.</li>
        <li><strong>Create a New API Product:</strong>
          <ul className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
            <li>In the API Publisher section, click the + icon to create a new API Product.</li>
          </ul>
        </li>
      </ol>

      <br />
      <div style={{ textAlign: 'center' }}>
        <img
          src="https://developer.101obex.com/static_images/GettingStartedAPIProducts_Figure1.1.png"
          alt="Figure 1.1 API Publisher Extension in Microsoft Visual Studio Code"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
        <small>*Figure 1.1 API Publisher Extension in Microsoft Visual Studio Code*</small>
      </div>
      <br />

      <li>
        <strong>Fill in the Required Information:</strong>
        <ul className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
          <li>Enter the name of the API Product in the top bar of the IDE and press Enter.</li>
          <li>The API Product will automatically become available, allowing you to group your created APIs within it.</li>
        </ul>
      </li>

      <p>
        For additional information related to AVAP API Publisher, visit AVAP Alexandria at (
        <a href="http://alexandria.avapframework.com/" target="_blank" rel="noopener noreferrer">
          alexandria.avapframework.com
        </a>
        ) and connect with the user community at (
        <a href="http://communities.avapframework.com/" target="_blank" rel="noopener noreferrer">
          communities.avapframework.com
        </a>
        ).
      </p>
    </div>
  </Fragment>
);
export default AVAP_API_Products;
