import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Retirar_EN = () =>
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{
          "status":"<boolean>",
          "codtran":"<string>""affiliates":[
             {
                "phone":"<string>",
                "affiliate_id":"<integer>",
                "country":"<string>",
                "name":"<string>",
                "other_data":"<string>",
                "allow_prefunding":"<boolean>"
             }
          ],
          "elapsed":"<float>"
       }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{
          "status":"<boolean>",
          "codtran":"<string>""affiliates":[
             {
                "phone":"<string>",
                "affiliate_id":"<integer>",
                "country":"<string>",
                "name":"<string>",
                "other_data":"<string>",
                "allow_prefunding":"<boolean>"
             }
          ],
          "elapsed":"<float>"
       }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/movimientos.py/retirar" 
        payload = { 'user_id': '4532', 'session_id': '385-XadgYoWixbzN_wqWc3Yyog==', 'amount': '12.50', 'pin': '1234', 'phone': '7229063245', 'country_code': 'MX'} 
        files = [] 
        headers= {} 
        response = requests.request("POST", url, headers=headers, data = payload, files = files)
         print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests url = "URL_BASE/ws/movimientos.py/retirar" 
          payload = { 'user_id': '4532', 'session_id': '385-XadgYoWixbzN_wqWc3Yyog==', 'amount': '12.50', 'pin': '1234', 'phone': '7229063245', 'country_code': 'MX'} 
          files = [] 
          headers= {} 
          response = requests.request("POST", url, headers=headers, data = payload, files = files)
           print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'POST',
              'url': 'URL_BASE/ws/movimientos.py/retirar',
              'headers': {
              },
              formData: {
              'user_id': '4532',
              'session_id': '385-XadgYoWixbzN_wqWc3Yyog==',
              'amount': '12.50',
              'pin': '1234',
              'phone': '7229063245',
              'country_code': 'MX'
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var formdata = new FormData();
              formdata.append("user_id", "4532");
              formdata.append("session_id", "385-XadgYoWixbzN_wqWc3Yyog==");
              formdata.append("amount", "12.50");
              formdata.append("pin", "1234");
              formdata.append("phone", "7229063245");
              formdata.append("country_code", "MX");
              var requestOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/movimientos.py/retirar", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request POST 'URL_BASE/ws/movimientos.py/retirar' \ --form 'user_id=4532' \ --form 'session_id=385-XadgYoWixbzN_wqWc3Yyog==' \ --form 'amount=12.50' \ --form 'pin=1234' \ --form 'phone=7229063245' \ --form 'country_code=MX'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="withdrawal">Withdrawal</h1>
        <p>
          This service is used to withdraw money from a clerk in a shop
          &#40;affiliate&#41; which is integrated in the project.
        </p>
        <p>
          <span className="label post">POST:</span>
          <span> </span> <code>URL_BASE + /ws/movimientos.py/retirar</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  ISO 3166 alpha-2
                </a>
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Identifier of the user &#40;affiliated&#41; making the request
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">amount</td>
              <td align="center">Decimal</td>
              <td align="center">Yes</td>
              <td align="center">
                Money amount to send to the indicated user with &#39;phone&#39;
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">pin</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">User pin &#40;affiliated&#41;</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">phone</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Telephone number of the user to whom the money is to be withdrawn.
              </td>
              <td align="center">N/A</td>
            </tr>
          </tbody>
        </table>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>signed:</code> Shows if the signature has been made &#40;true&#41;
            or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency_data:</code> Contains the different currency details used
            for the operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>abbreviation:</code> The abbreviated name of the currency
            &#40;EUR, MXN&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>suffix:</code> Indicates the suffix that is applied in the
            currency format &#40;pesos, euros&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>format:</code> The full format that is applied to the currency, it
            includes the suffix and the prefix.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>symbol:</code> The symbol associated to the currency &#40;€, ¢,
            $&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>prefix:</code> The prefix that is applied in the format of the
            currency.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>decimals:</code> The maximum number of decimal places that will be
            included in the currency format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>id:</code> Currency identifier in DB.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>raw_amount:</code> Negative transaction amount without applying
            the format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency:</code> Short name of the currency used in the operation,
            it matches the abbreviation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>commission_user_card:</code> Commission that would be applied if
            the payment is made by card.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>codtran:</code> Transaction code that identifies the executed
            operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>user_commission:</code> Commission that will be applied to the
            user.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>amount:</code> Negative transaction amount with the applied
            format.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>level:</code> Error importance level.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code> Error message.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          This service must be executed with an affiliated user session, and by
          means of the &#39;telephone&#39;, it looks for the user to whom the
          withdrawal of the indicated &#39;amount&#39; will be made. To complete the
          operation it is necessary to sign the operation through the &#39;signature
          service&#39; or an SMS. The requested money must be delivered to the user
          &#40;physical&#41;.
        </p>
        </div>

      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
      </div>
      </Fragment>
    );
  }
export default Retirar_EN;
