import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { metaConfig } from '../configs/metaConfig';

const useMetaTags = () => {
	const location = useLocation();

	useEffect(() => {
		const updateMetaTags = () => {
			const currentPath = location?.pathname || window.location.pathname;

			const meta = metaConfig.find((item) => item.path === currentPath);

			if (meta) {
				document.title = meta.title;

				let metaDescription = document.querySelector("meta[name='description']") as HTMLMetaElement;
				if (!metaDescription) {
					metaDescription = document.createElement('meta') as HTMLMetaElement;
					metaDescription.name = 'description';
					document.head.appendChild(metaDescription);
				}
				metaDescription.content = meta.description;

				let linkCanonical = document.querySelector("link[rel='canonical']") as HTMLLinkElement;
				if (!linkCanonical) {
					linkCanonical = document.createElement('link') as HTMLLinkElement;
					linkCanonical.rel = 'canonical';
					document.head.appendChild(linkCanonical);
				}
				linkCanonical.href = meta.url;

				let linkFavicon = document.querySelector("link[rel='icon']") as HTMLLinkElement;
				if (!linkFavicon) {
					linkFavicon = document.createElement('link');
					linkFavicon.rel = 'icon';
					linkFavicon.type = 'image/png';
					document.head.appendChild(linkFavicon);
				}
				linkFavicon.href = meta.favicon;
			} else {
				document.title = 'AVAP Framework';
				let linkFavicon = document.querySelector("link[rel='icon']") as HTMLLinkElement;
				if (!linkFavicon) {
					linkFavicon = document.createElement('link');
					linkFavicon.rel = 'icon';
					linkFavicon.type = 'image/png';
					document.head.appendChild(linkFavicon);
				}
				linkFavicon.href = '/assets/img/Favicon_AVAP_Framework.png';
			}
		};

		updateMetaTags();
	}, [location]);
};

export default useMetaTags;
