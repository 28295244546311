import React, { Fragment } from 'react';
import './chapter.css'
const Chapter12_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h1>Chapter 12: <code>addParam()</code> Function</h1>
      <h2>Introduction</h2>
      <p>
        The <code>addParam()</code> function in AVAP™ is a powerful tool used to add parameters to an API call in the query string.
        This parameter is assigned to a variable and acts as a bridge between the API call and the API itself, 
        allowing smooth and efficient communication between both.
      </p>
    </div>
  </Fragment>
);

export default Chapter12_1;
