import React, { FunctionComponent, Component, useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Modal, Form, Button, Image, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import ilustra_upgrade from '../../assets/img/upgrade.png';
import logo_framework_titulo_cloud from '../../assets/img/AVAP_Cloud_P.png'
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import logo_framework_1 from '../../assets/img/AVAP Cloud_Professional_Logo.png'
import logo_framework_2 from '../../assets/img/AVAP Cloud_Business_Logo.png'

import logo_framework_titulo_cloud_w from '../../assets/img/AVAP_Cloud_P_W.png'
import logo_framework_1_w from '../../assets/img/AVAP Cloud_Professional_Logo_blanco.png'
import logo_framework_2_w from '../../assets/img/AVAP Cloud_Business_Logo_white.png'

import AddCreditCard from '../../components/Modals/addCreditCard';
import PaymentConfirmation from '../../components/Modals/paymentConfirmation';

type upgradePlanProps = {
  showModal: boolean,
  text: string,
  currentSuscription: number,
  onCloseModal: () => void,
  onCancelModal: () => void,
  onSuccess: () => void
  grid?: boolean, // controlan los estilos
  cloud?: boolean,
}

const UpgradePlanModal: FunctionComponent<upgradePlanProps> = (props: upgradePlanProps) => {

  const [isDarkMode, setIsDarkMode] = useState(false);


  const { showModal, onSuccess, onCloseModal, onCancelModal, text, currentSuscription } = props
  const [loading, setLoading] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const history = useHistory();
  const [SusSel, setSusSel] = useState(0);
  const getSettingFormat = async () => { };
  const [isUpgrading, setisUpgrading] = useState(false);
  const [cards_, setCards] = useState([]);
  const [newadded_card, setAddedCard] = useState('');
  const [show, setShow] = useState(false);
  const [showModal1, setShowModal] = useState(false);
  const [card_id_stripe, setCardID] = useState('');
  const [hasCards, setHasCards] = useState(false);

  const onUpgrade = () => {
    history.push('/plan_subcription/upgrade_plan');
  }


  
  const modalClasses = `addons-dialog themeDialog w-75 obex-dialog` +
    (props.cloud && props.grid ? ' cloudModal isGrid' :
      props.cloud ? ' cloudModal' :
        props.grid ? ' isGrid' : '');

  const handleSubmit = (e) => { console.log(e); };


  useEffect(() => {
    if (hasCards === false) {
      getCards();
      setHasCards(true);
    }

    //getSettingFormat();

    const checkDarkMode = () => {
      setIsDarkMode(document.body.classList.contains('dark-mode'));
    };

    // Verifica el estado inicial
    checkDarkMode();

    // Añade un listener al almacenamiento para cambios de tema
    window.addEventListener('storage', checkDarkMode);

    // Limpia el listener al desmontar
    return () => {
      window.removeEventListener('storage', checkDarkMode);
    };
  })


  const getCards = async () => {


    try {
      const result = (await ObexRequestHandler.get('/cards', {}, true)).data || {};
      const { defaultCard, cards } = result;
      const pmCards = [];
      if (cards) {
        for (const c of cards) {
          const isDefault = c.id === defaultCard;
          c.defaultCard = isDefault;
          isDefault ? pmCards.unshift(c) : pmCards.push(c);
          if (cards_.some(e => e.id == c.id)) {
            //console.log('exists');
          } else {
            setAddedCard(c.id);
          }
        }
      }
      setCards(pmCards);

    } catch (error) {
      console.error('ERROR FETCHING CARDS ', error);
      throw error;
    }
  }


  const handleModal = () => {
    getCards();
    setShowModal(!showModal1);
    console.log('HANDLE MODAL')
    getCards();
  };


  const handleCard = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    if (value == 'addCard') {
      handleModal();
    } else {
      setCardID(value);

    }
    console.log(value);
    e.preventDefault();
    e.stopPropagation();

  }

  const onCloseAction = () => {

    setShow(false);
  }

  const checkOut = (newSusId) => {
    setSusSel(newSusId);
    setShow(true);
  }
  const upgradeLicense = async (newSusId) => {


    setShow(false);
    setisUpgrading(true);
    const payload = {
      //   card_stripe_id: card_id_stripe, 
      suscriptionId: currentSuscription,
      annualPayment: false,

      newSubscriptionId: newSusId
    };
    console.log(payload);

    const result = await ObexRequestHandler.post('/upgrade_subscription', payload);

    console.log(result);
    setisUpgrading(false);
    setError('');
    setSuccess('Exito');
    onSuccess();


  }


  const ContentModal = (
    <Form>
      <Form.Row>

        <Col md="5" className="my-3 mx-auto">
          <Image src={ilustra_upgrade} fluid />
        </Col>

      </Form.Row>



      <Form.Row>
        <Col>
          <ButtonSubmit
            color={true}
            loading={loading}
            success={success}
            error={error}
            handleSubmit={onCloseModal}
            submitDisabled={submitDisabled}
            textButton={'Close'}
          />
        </Col>

        <Col>
          <ButtonSubmit
            loading={loading}
            success={success}
            error={error}
            handleSubmit={onUpgrade}
            submitDisabled={submitDisabled}
            textButton={'Upgrade'}
            blue={true}
          />
        </Col>
      </Form.Row>
    </Form>
  )

  return (
    <Modal dialogClassName="themeDialog addons-dialog obex-dialog"
      show={showModal}
      onHide={onCancelModal}
      animation={false}
      scrollable
      container={document.getElementById('dashboardWrapper')}>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center mx-auto">
          <Row>
            <Col md="12" className="addons-title">
              <span>You need to Upgrade your Plan</span>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>



      <Modal.Body className="pt-0">
        <Row>
          <Col md="12" className="text-center">
            <span>{text}</span>
          </Col>
        </Row>
        {/*{ContentModal}*/}



        <div
          className='landingFont0'
          style={{
            marginTop: '0px',
            paddingTop: '10px',
            position: 'relative',
            color: 'black',
            lineHeight: '50px',
            textAlign: 'center'
          }}
        >AVAP Cloud <span className='textBold'>Memberships</span></div>


        <div className="border-top border-black my-2 mx-3"></div>


        <div style={{ marginTop: '40px', width: '100%', marginLeft: 'auto', marginRight: 'auto', display: 'flex' }}>



          <div style={{ width: '33%' }}>
            <div className='cld_border'
              style={{
                borderRadius: '10px',
                width: 'auto',
                marginLeft: '10px',
                marginRight: '10px',
                justifyContent: 'center',
                textAlign: 'center'
              }}>

              <Image
                src={isDarkMode ? logo_framework_1_w : logo_framework_1}
                style={{
                  marginTop: '20px',
                  width: '70%'
                }} width="" alt="Logo" />
              <div className='bg-main' style={{ width: '100%', marginTop: '20px', paddingTop: '10px', paddingBottom: '10px' }}>
                <span style={{ color: '#0473bf' }} className='landingFont3b blue textBold'>Professional</span>
              </div>
              <div style={{ minHeight: '125px', height: '125px', marginTop: '20px', marginBottom: '20px', paddingLeft: '20px', paddingRight: '20px' }}>Membership that allows you to explore and start taking advantage AVAP technology</div>
              <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <button disabled={currentSuscription > 0} onClick={() => { history.push('/avap_cloud/professional') }} style={{ paddingLeft: '10px', paddingRight: '10px', width: '90%' }} className='customButton-blue textBold'>{currentSuscription > 0 ? 'Active license' : 'Upgrade'}</button>
              </div>
            </div>
          </div>

          <div style={{ width: '33%' }}>
            <div className='cld_border' style={{
              borderRadius: '10px',
              width: 'auto',
              marginLeft: '10px',
              marginRight: '10px',
              justifyContent: 'center',
              textAlign: 'center'
            }}>
              <Image
                src={isDarkMode ? logo_framework_2_w : logo_framework_2}
                style={{
                  marginTop: '20px',
                  width: '70%'
                }}
                alt="Logo"
              />

              <div className='bg-main' style={{ width: '100%', marginTop: '20px', paddingTop: '10px', paddingBottom: '10px' }}>
                <span style={{ color: '#0473bf' }} className='landingFont3b blue textBold'>Business</span>
              </div>
              <div style={{ minHeight: '125px', height: '125px', marginTop: '20px', marginBottom: '20px', paddingLeft: '20px', paddingRight: '20px' }}>Pre-configured AVAP infrastructure deployment, enabling you concentrate on developing and publishing APIs.</div>
              <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <button disabled={currentSuscription > 1} onClick={() => { checkOut(2)/*history.push('/avap_cloud/business')*/ }} style={{ paddingLeft: '10px', paddingRight: '10px', width: '90%' }} className='customButton-blue textBold'>
                  {
                    isUpgrading ? <>
                      <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />
                    </> :
                      (currentSuscription > 1 ? 'Active license' : 'Upgrade')
                  }
                </button>
              </div>
            </div>
          </div>
          <div style={{ width: '33%' }}>
            <div className='cld_border' style={{
              borderRadius: '10px',
              width: 'auto',
              marginLeft: '10px',
              marginRight: '10px',
              justifyContent: 'center',
              textAlign: 'center'
            }}>

              <Image src={isDarkMode ? logo_framework_titulo_cloud_w : logo_framework_titulo_cloud} style={{
                marginTop: '20px',
                width: '70%'
              }} width="" alt="Logo" />
              <div className='bg-main' style={{ width: '100%', marginTop: '20px', paddingTop: '10px', paddingBottom: '10px' }}>
                <span style={{ color: '#0473bf' }} className='landingFont3b blue textBold'>Enterprise</span>
              </div>
              <div style={{ minHeight: '125px', height: '125px', marginTop: '20px', marginBottom: '20px', paddingLeft: '20px', paddingRight: '20px' }}>Maintain full control over the deployment of AVAP infrastructure, tailored for corporations.</div>
              <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <button onClick={() => { window.open('mailto:sales@101obex.com', '_self') }} style={{ paddingLeft: '10px', paddingRight: '10px', width: '90%' }} className='customButton-blue textBold'>Contact Us</button>
              </div>
            </div>
          </div>


        </div>
      </Modal.Body>


      {show &&

        <Modal dialogClassName={modalClasses}
          show={show}
          onHide={onCloseAction}
          animation={false}
          container={document.getElementById('dashboardWrapper')}>
          <Modal.Header closeButton className="border-0 pb-0">
            <Modal.Title className="text-center w-100">
              <Row>
                <Col className="addons-title">
                  <span>Upgrade to {SusSel === 1 ? 'AVAP Cloud Professional' : 'AVAP Cloud Business'}</span>
                </Col>
              </Row>
              <Row>
                <Col md="10" className="mx-auto text-center modal-subtitle">
                  <span className="text-center d-block">please verify your purchase and confirm your upgrade</span>
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PaymentConfirmation
              actionSubmit={() => upgradeLicense(SusSel)}
              handleGoBack={() => {
                // console.log("BACK");
                setShow(false);
              }}
              handleAnnualPayment={null}
              nameServiceSelected={SusSel === 1 ? 'AVAP Cloud Professional' : 'AVAP Cloud Business'}
              amountMonth={SusSel === 1 ? 75 : 250}
              amountYear={SusSel === 1 ? (75 * 10) / 12 : (250 * 10) / 12}
              processingPayment={loading}
              success={success}
              error={error}
              handleCard={handleCard}
              isBlue={true}
              cards={cards_}
              prorated={true}
              addedCard={newadded_card}
              spanish={false} />
          </Modal.Body>
        </Modal>}


      {showModal1 && <AddCreditCard show={showModal1} handleClose={handleModal} spanish={false} />}

    </Modal>


  )
}

export default UpgradePlanModal;