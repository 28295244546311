import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const Obtain_Active_Countries = () => {

  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{
        "status": true,
        "countries": [
            {
                "currency_symbol": "$    ",
                "currency_data": {
                    "abbreviation": "MXN",
                    "suffix": "pesos",
                    "format": "$%.2f pesos",
                    "symbol": "$",
                    "prefix": "$",
                    "decimals": 2.0,
                    "id": 70
                },
                "currency_format": "$%.2f pesos",
                "precision_currency": 2.0,
                "phone_longitude": 10,
                "currency": "Mexico Peso",
                "name": "Mexico",
                "prefix": "52",
                "phone": "98780",
                "placeholder_currency": "$0.00 pesos",
                "country_code": "MX"
            }
        ],
        "elapsed": 0.0168149471282959
    }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
    url = "URL_BASE/ws/util.py/countries"
    payload = {}
    headers= {}
    response = requests.request("GET", url, headers=headers, data = payload)
    print(response.text.encode('utf8'))`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
    url = "URL_BASE/ws/util.py/countries"
    payload = {}
    headers= {}
    response = requests.request("GET", url, headers=headers, data = payload)
    print(response.text.encode('utf8'))`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: ` var request = require('request');
    var options = {
        'method': 'GET',
        'url': 'URL_BASE/ws/util.py/countries',
        'headers': {}
    };
    request(options, function (error, response) { 
        if (error) throw new Error(error);
        console.log(response.body);
    });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: ` var requestOptions = {
        method: 'GET',
        redirect: 'follow'
    };
    fetch("URL_BASE/ws/util.py/countries", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: ` curl --location --request GET 'URL_BASE/ws/util.py/countries'`
      }
    ]
  }

  return (
    <Fragment>


      <div className='container-fluid row'>
        <div className='col-md-7'>

          <h1 >Obtain Active Countries</h1>
          <p>This service is used to obtain the active countries in the system.</p>
          <p>
            <span className="label get">GET:</span>
            <span> </span> <code>URL_BASE + /ws/util.py/countries</code>
          </p>
        
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>
          <h3 >Answer JSON OK:</h3>

          <div className='mb-3'>
        <DisplayCode1 codeToshow={codes.answe_ok1}/>
      </div>
          <h2 >Where:</h2>
          <ul>
            <li>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li>
              <code>countries:</code> List with the active countries of the system.
            </li>
            <li>
              <code>currency_symbol:</code> Symbol of the country&#39;s currency.
            </li>
            <li>
              <code>currency_data:</code>Contains the different details of the
              currency used in the operation.
            </li>
            <li>
              <code>abbreviation:</code> The abbreviated name of the currency
              &#40;EUR, MXN&#41;.
            </li>
            <li>
              <code>suffix:</code> Indicates the suffix that is applied in the
              currency format &#40;pesos, euros&#41;.
            </li>
            <li>
              <code>format:</code> The full format that applies to the currency, it
              includes the suffix and the prefix.
            </li>
            <li>
              <code>symbol:</code>The symbol associated to the currency &#40;€, ¢,
              $&#41;.
            </li>
            <li>
              <code>prefix:</code> The prefix that is applied in the currency format.
            </li>
            <li>
              <code>decimals:</code> The maximum number of decimal places that will be
              included in the currency format.
            </li>
            <li>
              <code>id:</code> Identifier of the currency in BBDD.
            </li>
            <li>
              <code>currency_format:</code> Format to be applied to the amounts that
              use this currency.
            </li>
            <li>
              <code>precision_currency:</code> Decimals of the amounts that use this
              currency.
            </li>
            <li>
              <code>phone_longitude:</code> Size of telephone numbers used in the
              country.
            </li>
            <li>
              <code>currency:</code> Full name of the country&#39;s currency.
            </li>
            <li>
              <code>name:</code> Name of the country.
            </li>
            <li>
              <code>prefix:</code> Telephone prefix of the country&#39;s telephone
              numbers.
            </li>
            <li>
              <code>phone:</code> Telephone from which the system&#39;s SMS for the
              country are sent.
            </li>
            <li>
              <code>placeholder_currency:</code> Text to display in the fields that
              are of the currency and have no value.
            </li>
            <li>
              <code>country_code:</code> Code of the country.
            </li>
            <li>
              <code>elapsed:</code> Operation execution time.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
        <DisplayCodeKo/>
      </div>
      <h2 >Business logic:</h2>
          <p>
            By means of this service all the active countries in the system are
            obtained.
          </p>

      </div>
        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='mb-3 effectStickySections'>
      <DisplayCode2 codeToshow={codesMutiple}/>
      </div>
          

        </div>

      </div>
    </Fragment>
  );
}
export default Obtain_Active_Countries;
