import React from 'react';
import './chapter.css'
const Chapter9_43 = () => (
    <div>
        <h2>AS Patterns</h2>
        <p>
            An AS pattern in AVAP is used to bind an OR pattern to a name. This allows you to match a value with an OR pattern and simultaneously capture it under a specified name for further use. The syntax for an AS pattern is:
        </p>
        <pre><code>as_pattern ::= or_pattern "as" capture_pattern</code></pre>
        <p>
            When an AS pattern is used, if the OR pattern succeeds, the subject is bound to the name specified by the capture pattern, and the AS pattern itself succeeds.
        </p>
        <p>
            Here's an example of how to use AS patterns:
        </p>
        <pre><code>match value:
    case 1 | 2 | 3 as x:
        print(f"Matched a number: x")
    case "hello" | "world" as greeting:
        print(f"Matched a greeting: greeting")
    case _:
        print("No match")</code></pre>
        <p>
            In this example:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>The first case matches if <code>value</code> is 1, 2, or 3. The matched value is bound to the name <code>x</code>, which is then used in the print statement.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>The second case matches if <code>value</code> is "hello" or "world". The matched value is bound to the name <code>greeting</code>, which is then used in the print statement.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>The last case is a catch-all pattern that executes if none of the previous patterns match.</li>
        </ul>
        <p>
            AS patterns are useful for capturing matched values under a name while using OR patterns, allowing for more flexible and readable pattern matching in your code.
        </p>
    </div>
);

export default Chapter9_43;
