import React from 'react';
import './chapter.css'
const Chapter9_11 = () => (
    <div>
        <h2>6.1. Arithmetic Conversions</h2>
        <p>
            When describing an arithmetic operator in AVAP and using the phrase "numeric arguments are converted to a common type," it means that the operator's implementation for built-in types works as follows:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>If either of the arguments is a complex number, the other is converted to complex.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Otherwise, if either of the arguments is a floating-point number, the other is converted to floating-point.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Otherwise, both must be integers, and no conversion is needed.</li>
        </ul>
        <p>
            Additional rules may apply for certain operators.
        </p>
    </div>
);

export default Chapter9_11;
