import React, { FunctionComponent, useEffect } from 'react'; 
import { Row, Col, Card } from 'react-bootstrap';
import { parseToCoin } from '../../services/AmountService';


type ProductDetailProps = {
    title: string,
    priceMonthly: number,
    priceYear: number,
    descrip: string,
    annualPayment: boolean,
    letMonths: number
}

const CustomProductDetailCard:FunctionComponent<ProductDetailProps> = (props: ProductDetailProps) => {

  const { title, priceMonthly, priceYear, descrip, annualPayment } = props;


  useEffect(() => {
    /**/
  }, [annualPayment])

  return(
    <Card className="product-detail-card mb-3">
      <Card.Body className="p-3">
        <Card.Title>
          <Row>
            <Col><span className="title-product">{title}</span></Col>
          </Row>
        </Card.Title>
        <div className="border-top my-2"></div>
        <Card.Text>
          <Row>
            <Col md="6">
              <span>
                {descrip}
              </span>
            </Col>
            <Col md="6" className="text-right my-auto">
            {annualPayment ?
              <span style={{fontSize:'16px'}}>
                <span className="product-price">
                  {parseToCoin(priceYear*10)}
                </span>/year
                </span>
              : <span style={{fontSize:'16px'}}>
                <span  className="product-price">{parseToCoin(priceMonthly)}
                </span>
                {annualPayment ? '/year' : '/month'}
                </span>
              }
            </Col>
          </Row>
        </Card.Text>
      </Card.Body>
    </Card>
    );
}

export default CustomProductDetailCard;