import React, { FunctionComponent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container, Image, Form, Button, Spinner, Alert } from 'react-bootstrap';
import PasswordRecovery from '../Modals/passwordRecovery';
import Logo from '../../assets/img/logo_negro.png';
import DevLogin from '../../assets/img/logindevicon.png';
import UserLogin from '../../assets/img/icon_login.png';
import EmailIcon from '../../assets/img/emailiconlogin.png';
import Fondologin from '../../assets/img/legos_fondologin.svg';
import VerificationNotice from './VerificationNotice';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { useParams } from 'react-router-dom';
//import Footer from '../functionalComponents/DashboardLayout/FooterMenuAccess';
import './style.css';
import Cookies from 'universal-cookie';
import VerificationNoticeResend from './VerificationNoticeResend';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type LoginFormProps = {
  userLabel?: string,
  passLabel?: string,
  rememberCheckboxLabel?: string,
  submitButtonLabel?: string,
  missingUserMsg?: string,
  missingPassMsg?: string,
  loginFailedMsg?: string
}

const LoginActivationForm:FunctionComponent<{}> = () => {

  const { token } = useParams();

  const [mail, setMail] = useState('');
  const [developer, setDeveloper] = useState(false);
  const [password, setPassword] = useState('');
  const [signining, setSignining] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showRecovery, setShowRecovery] = useState(false);
  const [stillLoaded, SetStillLoaded] = useState(false);
  const handleCloseRecovery = () => setShowRecovery(false);
  const handleShowRecovery = (e) => {
    e.preventDefault(); 
    setShowRecovery(true);
  };

  const history = useHistory();

  function routeDashboard() {
    history.push({
      pathname: '/cloud_dashboard'
    })
  }

  function routeDevDashboard() {
    history.push({
      pathname: '/devdashboard'
    })
  }

  function routeClientDashboard() {
    history.push({
      pathname: '/clientdashboard'
    })
  }

  function routeHome() {
    const path = '/login';
    window.location.href = path;
  }

  function routePlans() {
    const path = 'https://www.101obex.com/pricing';
    window.location.href = path;
  }

  const redirectPage = async (signin) =>{


    //SessionService.ORGANIZATION = 8;
if (signin.developer && !signin.user)
  {
    routeDevDashboard();
  } else 
  if (signin.user)
  {
      const projects = await ObexRequestHandler.get('/projects');
      if(!projects.success && projects.status == 403){
        SetStillLoaded(false);
        handleShow();
      
      }else{
        routeDashboard();
      }
    }
    else {
      routeClientDashboard();
    }
  }

  const handleSubmitSignip = async (event) => {
    event.preventDefault();
    try {
      setError('');
      setSignining(true);
      const signin = await SessionService.login(mail, password, developer);
      // console.log("SIGNIN");
      // const cook = new Cookies();
      // cook.set('101Obex', JSON.stringify(signin));
      // console.log(signin);
      setSuccess(true);
      //const sessionExpiration = signin.secondsTokenExpiration
      redirectPage(signin);
    } catch (error) {
      
      setError(error.message || 'An error was ocurred while sigining.');
      SetStillLoaded(false);
      setTimeout(function() { 
        SetStillLoaded(false);
        }.bind(this), 2000)
      setTimeout(function() { 
        setError('');
        }.bind(this), 4000)

    }
    setSubmitDisabled(false);
    setSignining(false);
    SetStillLoaded(true);
  };


  const handleSubmitSignup = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    routePlans();    
  };


  const changeMail = (e) => {
    const newMail = e.target.value;
    setMail(newMail);

  }

  const changeAcces = (e) => {

    setDeveloper(!developer);

  }

  const changePass = (e) => {
    const newPass = e.target.value;
    setPassword(newPass);
  }

  return(

    <>
      <BrowserView>

    <Container fluid className="text-center d-flex flex-column min-vh-100">
      { stillLoaded ?
      <Container className="vh-100 d-flex flex-column vw-100"> 
      <Row className="h-100" >    
            <Col md="1" className="mx-auto d-flex align-items-center">
              <Spinner animation="border"/>
            </Col>
          </Row>
      </Container>
      :
      <><Row className="justify-content-center pt-5 mt-2" 
        style={{ backgroundImage:`url(${Fondologin})`, backgroundSize:'100%' }}>
          <div className="login-form">
            <Row className="justify-content-center my-2">
              <Col xs="7">
                <Image onClick={routeHome} src={Logo} fluid />
              </Col>
            </Row>
            <Row style={{ marginTop:'25%' }}>
              <Col >
              <Row style={{
                
               marginTop:'10px',
               marginBottom:'20px',
               borderStyle:'hidden',
               borderWidth:'1px',
               marginLeft:'0px',
               marginRight:'0px',
               borderRadius:'5px'
               
               
               }}>
                <Col>


              <Form.Group controlId="customerLogin" 
                style={{ 
                          borderStyle:'solid', 
                          borderRadius:'7px', 
                          borderWidth:'1px', 
                          marginLeft:'-15px', 
                          marginRight:'-15px', 
                          borderColor:'lightgrey' 
                        }}>
              <Row style={{ marginTop:'10px', marginBottom:'-0px' }}>
              <Image  src={UserLogin} style={{
                maxWidth:'100px',
                marginLeft:'30px',
                marginTop:'10px',
                marginBottom:'10px',
                borderRightStyle:'solid',
                paddingRight:'15px',
                borderRightWidth:'1px',
                borderRightColor:'lightgray'


              }} />
                    
                    <Col style={{
                      maxWidth:'50px',
                      marginTop:'13px',
                      marginLeft:'10px'
                    }}
                    >

                      {/*
                    <Form.Control 
                    style={{
                      maxHeight:'20px'

                    }}
                    required type="radio" title="Customer Login" checked = {!developer} onChange={changeAcces} />

                  */}

                    </Col>
                    <Col style={{ textAlign:'start', marginLeft:'-50px' }} >
                    <Form.Label style={{ fontSize:'22px', marginTop:'7px', color: '#7abcb0' }}><b>Unique Login</b> </Form.Label>
                    <Row style={{ 
                      color:'gray', 
                      fontSize:'12px', 
                      paddingRight:'15px',
                       marginLeft:'0px' 
                       }}>Access to all your profiles, Customer, Developer or Administrator.</Row>
                    </Col>
              </Row>
              </Form.Group>

{/*

              <Form.Group controlId="developerLogin" 
                  style={{ 
                    borderStyle:'solid', 
                    borderRadius:'7px', 
                    borderWidth:'1px', 
                    marginLeft:'-15px', 
                    marginRight:'-15px', 
                    borderColor:'lightgrey' 
                    }}>
              <Row style={{ marginTop:'10px' }}>
                    
              <Image src={DevLogin}  style={{
                maxWidth:'100px',
                marginLeft:'30px',
                marginTop:'10px',
                marginBottom:'10px',
                borderRightStyle:'solid',
                paddingRight:'15px',
                borderRightWidth:'1px',
                borderRightColor:'lightgray'


              }}/>
                    
                    <Col style={{
                      maxWidth:'50px',
                      marginTop:'13px',
                      marginLeft:'10px'
                    }}
                    >
                      
                    <Form.Control 
                    style={{
                      maxHeight:'20px'

                    }}
                    required type="radio" title="Developer Login" checked = {developer} onChange={changeAcces}  />
                    </Col>
                    <Col style={{ textAlign:'start', marginLeft:'-10px' }} >
                    <Form.Label style={{ 
                      fontSize:'22px',
                      marginTop:'7px', 
                      color: '#7abcb0' 
                      }}><strong>Team Member </strong></Form.Label>
                    <Row style={{ 
                      color:'gray', 
                      fontSize:'12px', 
                      paddingRight:'15px', 
                      marginLeft:'3px' 
                      }}>User within an account that performs daily tasks.</Row>
                    </Col>
              </Row>
              </Form.Group>

                    */}

              </Col>
              </Row>
              <Row style={{ marginBottom:'-15px' }}>
                <Col style={{ maxWidth:'30px' }}>
                <Image  src={EmailIcon} style={{
                maxWidth:'25px',
}} />
                </Col>
                <Col style={{ textAlign:'start', marginLeft:'5px' }}>


                <h1 style={{ fontSize:'24px' }}>User Identification</h1>
                </Col>
                </Row>
                <Form className="obex-form">
                  <Form.Group controlId="formBasicEmail">
                  
                    <Form.Control required type="email" placeholder="Email address" onChange={changeMail} />
                  </Form.Group>
                  <Form.Group controlId="formBasicPassword">
                    <Form.Control required type="password" placeholder="Password" onChange={changePass} />
                  </Form.Group>
                  <Button 
                    type="submit" 
                    className="my-2 login btn-block" 
                    style={{ 
                      borderRadius:'10px'
                    }} 
                    onClick={handleSubmitSignip} disabled={signining}>
                    {signining ? 'Loading' : 'Next'}
                    {signining && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                  </Button>
                  
                  {error.length > 0 && (
                    <Alert variant='danger'>
                      {error}
                    </Alert>)}
                </Form>
              </Col>
            </Row>
            <Row>
            <Col style={{ color:'gray', fontSize:'12px', textAlign:'start', marginLeft:'15px', marginRight:'15px' }}>
            <span>
              <Row style={{ textAlign:'center', justifyContent:'center', marginBottom:'10px' }}>
              <a className="link_forgetpass" onClick={handleShowRecovery}>Are you having problems with signing in?</a>
              </Row>
            </span>
              <Row>
            By continuing, you agree to the 101OBeX Agreement or other agreement for 101OBeX services, and the Privace Notice. This site uses essential cookies. 
            </Row>
            <Row style={{ marginTop:'10px' }}></Row>
            <Row style={{ marginBottom:'30px' }}>
              <Col style={{ textAlign:'center' }}>
            <span>Need to create a new organization?</span>
            <a onClick={handleSubmitSignup} style={{ color:'#7abcb0' }}> Sign up </a>
            <span>here.</span>
            </Col>
            </Row>
            </Col>
            
            </Row>

          </div>
          <PasswordRecovery show={showRecovery} handleClose={handleCloseRecovery} isBlue={true} />
        </Row><VerificationNoticeResend
            mail={mail}
            show={show}
            token = {token}
            handleClose={handleClose} />
          <Row className="mt-auto">
            <Col className="px-0">
           {/*   <Footer /> */}
            </Col>
          </Row></> 
}
    </Container>
    </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  );
}

export default LoginActivationForm;