import React from 'react';
import './chapter.css'
const Chapter9_25 = () => (
    <div>
        <h2>Shift Operations</h2>
        <p>
            Shift operations have lower precedence than arithmetic operations:
        </p>
        <pre>
            <code>
{`shift_expr ::=  a_expr | shift_expr ("<<" | ">>") a_expr
`}
            </code>
        </pre>
        <p>
            These operators accept integers as arguments. They shift the first argument left or right by the number of bits specified by the second argument.
        </p>
        <p>
            A right shift by <code>n</code> bits is defined as an integer floor division by <code>pow(2, n)</code>. A left shift by <code>n</code> bits is defined as a multiplication by <code>pow(2, n)</code>.
        </p>
    </div>
);

export default Chapter9_25;
