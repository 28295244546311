import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_15 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>addParam()</h2>
      <p>
        The <code>addParam()</code> command retrieves the value associated with a specific key from the query string of the current request and assigns this value to a target variable. This command is useful for extracting values from query parameters in an HTTP request and storing them in variables for processing.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>param</strong> <br />
          Type: <code>value</code> <br />
          Description: The key of the query string whose value you want to retrieve. It should be a value that represents the key in the query string.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>variable</strong> <br />
          Type: <code>var</code> <br />
          Description: The variable in which the retrieved value from the query string will be stored. It must be a variable that will receive the value associated with the specified key.
        </li>
      </ul>
      <h3>Command Flow</h3>
      <ol>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Retrieve the Value:</strong> Access the value associated with the <code>param</code> key from the query string of the current request.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Assign the Value:</strong> Assign the retrieved value to the variable specified by <code>variable</code>.</li>
      </ol>
      <h3>Usage Example</h3>
      <p>Suppose the query string of the current request is <code>?user=alice&age=30</code>, and you want to retrieve the value associated with the key <code>"user"</code>.</p>
      <pre>
        <code>
          {`// Variable definitions
userName = ''

// Call the command to retrieve the value for the "user" key and assign it to the variable
addParam("user", userName)

// Return the retrieved value through addResult
addResult(userName)`}
        </code>
      </pre>
      <p>Given the query string <code>?user=alice&age=30</code>, the <code>addParam()</code> command will retrieve the value <code>"alice"</code> associated with the key <code>"user"</code> and store it in the <code>userName</code> variable. The <code>userName</code> variable will be returned through <code>addResult(userName)</code>, resulting in the following output:</p>
      <pre>
        <code>
          {`"alice"`}
        </code>
      </pre>
    </div>
  </Fragment>
);

export default Appendices_15;
