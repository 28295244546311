import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
      <div>
      <h3>Practical Example</h3>
      <pre>
        <code>
          {`
// Definition of a variable
example_variable = 10

// Definition of a function
example_function(parameter):
    // Function body
    result = parameter * 2
    return result

// Function call
result = example_function(example_variable)
`}
        </code>
      </pre>
      <p>
        In this example, notation conventions are used to define a variable, a function, and to call the function with a parameter.
      </p>
    </div>
  </Fragment>
);
export default Intro_1;
