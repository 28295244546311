import React from 'react';

const PricingTable = () => {
    return (
        <div className="pricing-table">
            <div className="pricing-header">
                <div className="plan">
                    <h3>Professional</h3>
                    <p className="price">$500 /month</p>
                    <p>Recommended for small API projects</p>
                    <button className=" cld-primary-button">Get Plan</button>
                </div>
                <div className="plan">
                    <h3>Business</h3>
                    <p className="price">$2000 /month</p>
                    <p>Recommended for teams with moderate API traffic</p>
                    <button className=" cld-primary-button">Get Plan</button>
                </div>
                <div className="plan">
                    <h3>Enterprise</h3>
                    <p className="price">Contact us</p>
                    <p>Custom solutions for high-demand APIs</p>
                    <button className=" cld-primary-button">Contact Us</button>
                </div>
            </div>
            <div className="pricing-body">
                <div className="feature-row">
                    <div className="feature">API Calls per Month</div>
                    <div>50 million</div>
                    <div>500 million</div>
                    <div>Custom</div>
                </div>
                <div className="feature-row">
                    <div className="feature">Data Storage</div>
                    <div>500 GB</div>
                    <div>5 TB</div>
                    <div>Custom</div>
                </div>
                <div className="feature-row">
                    <div className="feature">Support</div>
                    <div>Standard</div>
                    <div>Premium</div>
                    <div>Dedicated Support</div>
                </div>
                <div className="feature-row">
                    <div className="feature">Analytics & Monitoring</div>
                    <div>Basic</div>
                    <div>Advanced</div>
                    <div>Custom Dashboard</div>
                </div>
                <div className="feature-row">
                    <div className="feature">API Rate Limit</div>
                    <div>1000 requests/min</div>
                    <div>5000 requests/min</div>
                    <div>Custom</div>
                </div>
                <div className="feature-row">
                    <div className="feature">SLA</div>
                    <div>99.5%</div>
                    <div>99.9%</div>
                    <div>99.99%</div>
                </div>
            </div>
        </div>
    );
};

export default PricingTable;
