import React from 'react';
import './chapter.css'
const Chapter9_18 = () => (
    <div>
        <h2>6.2.6. Set Displays</h2>
        <p>
            A set display is denoted by curly braces and is distinguished from dictionary displays by the absence of colon characters separating keys and values:
        </p>
        <pre>
            <code>
{`set_display ::=  "{" (starred_list | comprehension) "}"
`}
            </code>
        </pre>
        <p>
            A set display produces a new mutable set object, whose content is specified by a sequence of expressions or a comprehension.
        </p>
    </div>
);

export default Chapter9_18;
