import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Obtener_preguntas_de_seguridad_del_usuario_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": <boolean>, 
          "questions": [
             { "id": <integer>, "text": <string> } 
            ], 
            "elapsed": <float> 
          }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": false, 
          "level": <string>, 
          "message": <string>, 
          "error": <string> 
        }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/seguridad_itf.py/preguntas_de_seguridad?country_code=MX&user_id=4532&session_id=412-ksO-VqllH8QyvYa7acIVkQ==&command=recuperacion&codtran=a5d73f98ba3520695892555548a44e71" 
        payload = {} 
        headers= {} 
        response = requests.request("GET", url, headers=headers, data = payload) 
        print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests url = "URL_BASE/ws/seguridad_itf.py/preguntas_de_seguridad?country_code=MX&user_id=4532&session_id=412-ksO-VqllH8QyvYa7acIVkQ==&command=recuperacion&codtran=a5d73f98ba3520695892555548a44e71" 
          payload = {} 
          headers= {} 
          response = requests.request("GET", url, headers=headers, data = payload) 
          print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'GET',
              'url': 'URL_BASE/ws/seguridad_itf.py/preguntas_de_seguridad?country_code=MX&user_id=4532&session_id=412-ksO-VqllH8QyvYa7acIVkQ==&command=recuperacion&codtran=a5d73f98ba3520695892555548a44e71',
              'headers': {}
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var requestOptions = {
                method: 'GET',
                redirect: 'follow'
                };
                fetch("URL_BASE/ws/seguridad_itf.py/preguntas_de_seguridad?country_code=MX&user_id=4532&session_id=412-ksO-VqllH8QyvYa7acIVkQ==&command=recuperacion&codtran=a5d73f98ba3520695892555548a44e71", requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request GET 'URL_BASE/ws/seguridad_itf.py/preguntas_de_seguridad?country_code=MX&user_id=4532&session_id=412-ksO-VqllH8QyvYa7acIVkQ==&command=recuperacion&codtran=a5d73f98ba3520695892555548a44e71'`
        }
      ]
    }
  
    return(
      <Fragment>
         <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="obtain-security-questions">Obtain Security Questions</h1>
        <p>This services is used to obtain the security questions.</p>
        <p>
          <span className="label get">GET:</span>{' '}
          <code>URL_BASE + /ws/seguridad_itf.py/preguntas_de_seguridad</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All parameters are sent in the querystring of the call, so a percentage
          encoding for URI must be applied &#40;aka URL encoding&#41;.
        </p>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  ISO 3166 alpha-2
                </a>
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">Identifier of the user making the request</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">codtran</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                Transaction code generated when trying to recover the user&#39;s
                password
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">command</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">It must have the recovery value</td>
              <td align="center">N/A</td>
            </tr>
          </tbody>
        </table>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>questions:</code> List of security questions selected by the user
            to be able to answer them.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>id:</code> Identifier of the security questions. &#40;Necessary to
            be able to send each question with its answer&#41;
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>text:</code> Security question.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>level:</code> Error importance level.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code>Error message.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business Logic:</h2>
        <p>
          The purpose of this service is to retrieve the repertoire of defined
          security questions from the project so that not all users have the same
          three selected questions. These questions and their answers will be stored
          in the user&#39;s settings to verify their identity in the event of
          wanting to modify or recover their access password.
        </p>
        </div>

      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
        </div>
      </Fragment>
    );
  }
export default Obtener_preguntas_de_seguridad_del_usuario_EN;
