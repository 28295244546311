import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_18 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>ormCreateTable()</h2>
      <p>
        The <code>ormCreateTable()</code> command creates a new table in a database using the specified ORM (Object-Relational Mapping). This command defines the columns of the table and their data types, and stores a reference to the created table in a destination variable.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>fields</strong> <br />
          Type: <code>value</code> <br />
          Description: A string containing the names of the table columns, separated by commas. Each column name should correspond to a field in the table.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>fieldsType</strong> <br />
          Type: <code>value</code> <br />
          Description: A string containing the data types for each column, separated by commas. The data types should be in the same order as the column names in <code>fields</code>.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>dbaseName</strong> <br />
          Type: <code>value</code> <br />
          Description: The name of the database where the table will be created. It should be a string indicating the target database.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>varTarget</strong> <br />
          Type: <code>variable</code> <br />
          Description: The variable in which the reference to the created table will be stored. It should be a variable that will receive the reference to the new table.
        </li>
      </ul>
      <h3>Command Flow</h3>
      <ol>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Define the Table:</strong> Uses the column names (<code>fields</code>) and their data types (<code>fieldsType</code>) to define the structure of the new table.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Create the Table:</strong> Creates the table in the database specified by <code>dbaseName</code> using the provided definition.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Store the Result:</strong> Saves the reference to the created table in the variable specified by <code>varTarget</code>.</li>
      </ol>
      <h3>Example Usage</h3>
      <p>Suppose you want to create a table called <code>users</code> in a database called <code>myDatabase</code>, with two columns: <code>username</code> of type <code>VARCHAR</code> and <code>age</code> of type <code>INTEGER</code>.</p>
      <pre>
        <code>
          {`// Define variables
fields = "username,age"
fieldsType = "VARCHAR,INTEGER"
dbaseName = "myDatabase"
tableReference = ''

// Call the command to create the table
ormCreateTable(fields, fieldsType, dbaseName, tableReference)

// Return the reference to the created table via addResult
addResult(tableReference)`}
        </code>
      </pre>
      <p>In this example, the <code>ormCreateTable()</code> command will create a table in the <code>myDatabase</code> database with the specified columns and data types. The reference to the new table will be stored in the <code>tableReference</code> variable, and this variable will be returned via <code>addResult(tableReference)</code>. The output will include the reference to the created table.</p>
    </div>
  </Fragment>
);

export default Appendices_18;
