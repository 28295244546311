import React, { useState } from 'react';
import { Form, Button, Col, Row } from 'react-bootstrap';

const AddPolicyForm = () => {
    const [formData, setFormData] = useState({
        apiName: '',
        authType: '',
        disableRateLimiting: false,
        rateLimit: 1000,
        rateLimitInterval: 60,
        disableThrottling: false,
        throttleRetryLimit: 0,
        throttleInterval: 0,
        unlimitedRequests: true,
        maxRequestsPerPeriod: 0,
        quotaResetInterval: 'never',
        rateLimitSmoothing: true,
        enforceAccessRights: false,
        enforceUsageQuota: false,
        enforceRateLimit: false,
        policyName: '',
        policyState: 'active',
        keyExpiry: 'never',
        tags: [{ name: '' }],
        metadata: [{ key: '', value: '' }],
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleAddField = (field) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: [...prevData[field], field === 'tags' ? { name: '' } : { key: '', value: '' }],
        }));
    };

    const handleFieldChange = (e, index, field) => {
        const { name, value } = e.target;
        const updatedField = [...formData[field]];
        updatedField[index][name] = value;
        setFormData((prevData) => ({ ...prevData, [field]: updatedField }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Policy Data Submitted:", formData);
    };

    const handleCancel = () => {
        setFormData({
            apiName: '',
            authType: '',
            disableRateLimiting: true,
            rateLimit: 1000,
            rateLimitInterval: 60,
            disableThrottling: false,
            throttleRetryLimit: 0,
            throttleInterval: 0,
            unlimitedRequests: true,
            maxRequestsPerPeriod: 0,
            quotaResetInterval: 'never',
            rateLimitSmoothing: false,
            enforceAccessRights: false,
            enforceUsageQuota: false,
            enforceRateLimit: false,
            policyName: '',
            policyState: 'active',
            keyExpiry: 'never',
            tags: [{ name: '' }],
            metadata: [{ key: '', value: '' }],
        });
    };

    return (
        <Form className="cloudForm" onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
            <h2>Add Policy</h2>

            {/* API Access Rights Section */}
            <h3>API Access Rights</h3>
            <Form.Group controlId="apiName">
                <Form.Label>API Name</Form.Label>
                <Form.Control
                    type="text"
                    name="apiName"
                    value={formData.apiName}
                    onChange={handleChange}
                    placeholder="Search by API Name"
                />
            </Form.Group>

            <Form.Group controlId="authType">
                <Form.Label>Authentication Type</Form.Label>
                <Form.Control
                    type="text"
                    name="authType"
                    value={formData.authType}
                    onChange={handleChange}
                    placeholder="Search by Authentication Type"
                />
            </Form.Group>

            {/* Global Limits and Quota */}
            <h3>Global Limits and Quota</h3>
            <Form.Group className="d-flex align-items-center">
                <Form.Label className="mr-2">Disable Rate Limiting</Form.Label>
                <label className="custom-toggle">
                    <input
                        type="checkbox"
                        name="disableRateLimiting"
                        checked={formData.disableRateLimiting}
                        onChange={handleChange}
                    />
                    <span className="slider"></span>
                </label>
            </Form.Group>
            {!formData.disableRateLimiting && (
                <>
                    <Form.Group controlId="rateLimit">
                        <Form.Label>Rate</Form.Label>
                        <Form.Control
                            type="number"
                            name="rateLimit"
                            value={formData.rateLimit}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="rateLimitInterval">
                        <Form.Label>Per (seconds)</Form.Label>
                        <Form.Control
                            type="number"
                            name="rateLimitInterval"
                            value={formData.rateLimitInterval}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </>
            )}

            {/* Policy Partitioning */}
            <h3>Policy Partitioning</h3>
            {["enforceAccessRights", "enforceUsageQuota", "enforceRateLimit"].map((toggleName) => (
                <Form.Group key={toggleName} className="d-flex align-items-center">
                    <Form.Label className="mr-2">{toggleName.replace(/([A-Z])/g, " $1")}</Form.Label>
                    <label className="custom-toggle">
                        <input
                            type="checkbox"
                            name={toggleName}
                            checked={formData[toggleName]}
                            onChange={handleChange}
                        />
                        <span className="slider"></span>
                    </label>
                </Form.Group>
            ))}

            {/* Configuration Section */}
            <h3>Configurations</h3>
            <Form.Group controlId="policyName">
                <Form.Label>Policy Name</Form.Label>
                <Form.Control
                    type="text"
                    name="policyName"
                    value={formData.policyName}
                    onChange={handleChange}
                    placeholder="e.g., Default Policy"
                />
            </Form.Group>

            <Form.Group controlId="policyState">
                <Form.Label>Policy State</Form.Label>
                <Form.Control
                    as="select"
                    name="policyState"
                    value={formData.policyState}
                    onChange={handleChange}
                >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </Form.Control>
                <Form.Text className="text-muted">
                    Keys connected to this policy are enabled and new keys can be created.
                </Form.Text>
            </Form.Group>

            <Form.Group controlId="keyExpiry">
                <Form.Label>Keys expire after</Form.Label>
                <Form.Control
                    as="select"
                    name="keyExpiry"
                    value={formData.keyExpiry}
                    onChange={handleChange}
                >
                    <option value="never">Key never expires</option>
                    <option value="1hour">1 hour</option>
                    <option value="6hours">6 hours</option>
                    <option value="12hours">12 hours</option>
                    <option value="24hours">24 hours</option>
                    <option value="1week">1 week</option>
                    <option value="2weeks">2 weeks</option>
                    <option value="1month">1 month</option>
                </Form.Control>
            </Form.Group>

            {/* Tags Section */}
            <h3>Tags</h3>
            {formData.tags.map((tag, index) => (
                <Form.Group key={index} controlId={`tag-${index}`}>
                    <Form.Label>Tag Name</Form.Label>
                    <Form.Control
                        type="text"
                        name="name"
                        value={tag.name}
                        onChange={(e) => handleFieldChange(e, index, 'tags')}
                        placeholder="e.g., Company Name, Security"
                    />
                </Form.Group>
            ))}
            <Button variant="outline-primary" onClick={() => handleAddField('tags')}>
                Add Tag
            </Button>

            {/* Metadata Section */}
            <h3>Metadata</h3>
            {formData.metadata.map((meta, index) => (
                <Row key={index}>
                    <Col>
                        <Form.Group controlId={`metadata-key-${index}`}>
                            <Form.Label>Metadata Key</Form.Label>
                            <Form.Control
                                type="text"
                                name="key"
                                value={meta.key}
                                onChange={(e) => handleFieldChange(e, index, 'metadata')}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId={`metadata-value-${index}`}>
                            <Form.Label>Value</Form.Label>
                            <Form.Control
                                type="text"
                                name="value"
                                value={meta.value}
                                onChange={(e) => handleFieldChange(e, index, 'metadata')}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            ))}
            <Button variant="outline-primary" onClick={() => handleAddField('metadata')}>
                Add Metadata
            </Button>

            {/* Fixed Buttons */}
            <div className="d-flex justify-content-between mt-4">
                <Button variant="primary" type="submit">
                    Save Policy
                </Button>
                <Button variant="outline-secondary" onClick={handleCancel}>
                    Cancel
                </Button>
            </div>
        </Form>
    );
};

export default AddPolicyForm;
