import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import MarkdownEditor from "./components/EasyMDE ";

const Documentation = ({ documenta }) => {
    const [documentationContent, setDocumentationContent] = useState("");

    const handleSave = async () => {
        console.log("Saved content:", documentationContent);
        //backend logica
    };

       useEffect(() => {
        if (documenta) setDocumentationContent(documenta);
       }, [documenta]);

    return (
        <Form>
            <Form.Group controlId="documentationEditor" className="mb-4">
                <Form.Label>API Documentation</Form.Label>
                <MarkdownEditor
                    value={documentationContent}
                    onChange={setDocumentationContent}
                />
            </Form.Group>
            <Button variant="primary" onClick={handleSave}>
                Save
            </Button>
        </Form>
    );
};

export default Documentation;
