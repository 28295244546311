import React from 'react';
import './chapter.css'
const Chapter9_33 = () => (
    <div>
        <h2>Return Statement</h2>
        <p>
            The <strong>return</strong> statement in AVAP is used to return the value of a desired variable from a function. Here is the syntax:
        </p>
        <pre><code>return(variable_to_return):</code></pre>
        <p>
            Here is an overview of how the <strong>return</strong> statement works:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Function Context:</strong> The <strong>return</strong> statement can only occur within a function definition, not inside a nested class definition.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Variable Evaluation:</strong> If a variable is provided, it is evaluated. If no variable is specified, <strong>None</strong> is used by default.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Function Exit:</strong> The <strong>return</strong> statement exits the current function call and returns the specified value.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Interaction with try-finally:</strong> When the <strong>return</strong> statement is executed within a try statement that has a finally clause, the finally clause is executed before the function exits.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Generator Functions:</strong> In generator functions, the <strong>return</strong> statement indicates the end of the generator. It causes a <strong>StopIteration</strong> exception to be raised, with the returned value (if any) used to construct the <strong>StopIteration</strong> exception and set as the <strong>StopIteration.value</strong> attribute.</li>
        </ul>
        <p>
            The <strong>return</strong> statement is a fundamental part of functions and generators, allowing for the output of values and proper function termination.
        </p>
    </div>
);

export default Chapter9_33;
