import React from 'react';
import './chapter.css'
const Chapter9_34 = () => (
    <div>
        <h2>Break Statement</h2>
        <p>
            The <strong>break</strong> statement in AVAP is used to terminate the closest enclosing loop. Here is an overview of its behavior:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Usage Context:</strong> The <strong>break</strong> statement can only occur within a <strong>for</strong> or <strong>while</strong> loop. It cannot be nested within a function or class definition inside that loop.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Loop Termination:</strong> It terminates the closest enclosing loop and skips the optional <strong>else</strong> clause if the loop has one.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Loop Control Target:</strong> If a <strong>for</strong> loop is terminated by <strong>break</strong>, the loop control target retains its current value.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Interaction with try-finally:</strong> When <strong>break</strong> is executed within a try statement with a <strong>finally</strong> clause, the <strong>finally</strong> clause is executed before actually exiting the loop.</li>
        </ul>
        <p>
            The <strong>break</strong> statement is essential for controlling loop execution, allowing for early exit from loops and proper handling of loop cleanup.
        </p>
    </div>
);

export default Chapter9_34;
