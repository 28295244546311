import React, { FunctionComponent } from 'react';
import { Button, Row, Col, Image } from 'react-bootstrap';
import ImgSerpentina from '../../assets/img/ilustracion_PagoExito@2x.png';
import ImgSerpentinagreen from '../../assets/img/ilustracion_PagoExito@2xgreen.png';



type PaymentSuccessprops = {
  onCloseAction: () => void,
  paymentType: string,
  paymentDescription: string
  blue?: boolean
}


const PaymentSuccessModal:FunctionComponent<PaymentSuccessprops> = (props: PaymentSuccessprops) => {

  const { onCloseAction, paymentType, paymentDescription } = props;

  return (
    <div>
      <div style={{ textAlign:'center' }} className="mt-4 mb-2"><h1>{paymentType}</h1></div>
      <Col md="10" className="mx-auto">
        <div>
          <Col md="6" className="mt-4 mb-5 mx-auto">
            <Image src={props.blue ? ImgSerpentina: ImgSerpentinagreen} fluid/>
          </Col>
        </div>
        <Row>
          <Col style={{ textAlign:'center' }} className="mx-auto mb-3">
            <button className={props.blue ? 'custom-button-blue textBold': 'custom-button-green textBold'} onClick={onCloseAction}>Accept</button>
          </Col>
        </Row>
      </Col>
    </div>
  )
}

export default PaymentSuccessModal;