import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Regenerar_contrasena_del_usuario_EN = () => 
 {
  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{ 
        "status": <boolean>, 
        "codtran": <string>, 
        "result": <string>, 
        "elapsed": <float> 
      }`,
      copied: false
    },
    answe_ko1: {
      title:"Answer ok",
      type: "json",
      code: `{ 
        "status": false, 
      "level": <string>, 
      "message": <string>, 
      "error": <string> 
    }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: `import requests url = "URL_BASE/ws/users.py/restartpin" 
      payload = { 'country_code': 'MX', 'phone': '7229063245' } 
      files = [] 
      headers= {} 
      response = requests.request("POST", url, headers=headers, data = payload, files = files) 
      print(response.text.encode('utf8'))`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/users.py/restartpin" 
        payload = { 'country_code': 'MX', 'phone': '7229063245' } 
        files = [] 
        headers= {} 
        response = requests.request("POST", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: `var request = require('request');
            var options = {
            'method': 'POST',
            'url': 'URL_BASE/ws/users.py/restartpin',
            'headers': {},
            formData: {
            'country_code': 'MX',
            'phone': '7229063245'
            }
            };
            request(options, function (error, response) { 
            if (error) throw new Error(error);
            console.log(response.body);
            });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: `var formdata = new FormData();
            formdata.append("country_code", "MX");
            formdata.append("phone", "7229063245");
            var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
            };
            fetch("URL_BASE/ws/users.py/restartpin", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: `curl --location --request POST 'URL_BASE/ws/users.py/restartpin' \ --form 'country_code=MX' \ --form 'phone=7229063245'`
      }
    ]
  }
  return(
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
      <h1 id="generate-new-password">Generate New Password</h1>
      <p>This service is used to regenerate the user&#39;s password.</p>
      <p>
        <span className="label post">POST:</span>
        <span> </span> <code>URL_BASE + /ws/users.py/restartpin</code>
      </p>
      <h2 id="receives">Receives:</h2>
      <p>
        All the parameters that the service receives must be indicated in the body
        of the request.
      </p>
      <table>
        <thead>
          <tr>
            <th align="left">Parameter</th>
            <th align="center">Format</th>
            <th align="center">Obligatory</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
            <th align="center">Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="left">country_code</td>
            <td align="center">
              ISO 3166 alpha-2 &#40;
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                https://www.iso.org/glossary-for-iso-3166.html
              </a>
              &#41;
            </td>
            <td align="center">Yes</td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
            <td align="center">ES &#40;Spain&#41;</td>
          </tr>
          <tr>
            <td align="left">phone</td>
            <td align="center">String</td>
            <td align="center">Yes</td>
            <td align="center">
              Phone number of the user whose password is going to be regenerated
            </td>
            <td align="center">N/A</td>
            <td align="center">229063245</td>
          </tr>
        </tbody>
      </table>
      <h2 id="returns">Returns:</h2>
      <p>
        Depending on the result of the operation, this service can return two
        different JSON:
      </p>
      <h3 id="answer-json-ok">Answer JSON OK:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
      <h2 id="where">Where:</h2>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>codtran:</code> Transaction code that identifies the executed
          operation.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>result:</code> Answer from the service which has executed the
          operation.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>elapsed:</code> Operation executed time.
        </li>
      </ul>
      <h3 id="answer-json-ko">Answer JSON KO:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
      <h2 id="where-1">Where:</h2>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>level:</code> Error importance level.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>message:</code> Error message.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>error:</code> Sole error code.
        </li>
      </ul>
      <h2 id="business-logic">Business logic:</h2>
      <p>
        By means of this service you can request the generation of a new password
        for the user, that will be notified by SMS to the user&#39;s phone number
        and an email to the email indicated in the user profile.
      </p>
      </div>
<div className='col-md-5'>
      <h2 id="example-requests">Example requests:</h2>
      <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
     
      </div>
      </div>
    </Fragment>
);
 }
export default Regenerar_contrasena_del_usuario_EN;
