import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';


import fondo from '../../assets/img/landingPage/fondoApps.png';
import dashboard from '../../assets/img/landingPage/cloudDashboard.png';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'

import features_1 from '../../assets/img/landingPage/shell/Shell-01.png'
import features_2 from '../../assets/img/landingPage/shell/Shell-02.png'
import features_3 from '../../assets/img/landingPage/shell/Shell-03.png'


import icn_1 from '../../assets/img/landingPage/icn_vision.png'
import icn_2 from '../../assets/img/landingPage/icn_mission.png'
import icn_3 from '../../assets/img/landingPage/icono1.png'

import rocket from '../../assets/img/landingPage/rocket.png'
import tablet from '../../assets/img/landingPage/tabletApi.png'

import logo from '../../assets/img/logos/AVAP-Shell-w.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'


import user from '../../assets/img/ui_icons/Icon fa-regular-user.svg'
import download from '../../assets/img/ui_icons/Icon akar-download.svg'
import key from '../../assets/img/ui_icons/Icon akar-key.svg'
import doc from '../../assets/img/ui_icons/fa-doc.svg'

import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'

import Favicon from '../../assets/img/Favicon_AVAP_Framework.png'

const PublisherProduct = () => {

    useEffect(() => {
        // favicon 
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = Favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);
    
        // clean favicon 
        return () => {
          document.head.removeChild(link);
        };
      }, []);

    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);



    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center pt-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">Optimized, scalable, and efficient API operations</h1>
                    <p className="fl-subtitle">
                        AI-powered command-line interface for seamless API development <br /> <br /> &nbsp;
                    </p>
                    <a className="glowBtn-dark fakeHide" href="#"><span className="btnInner">Start with AVAP Cloud</span></a>
                </section>


                {/* Powerful API Platform */}
                <section className="container text-center pb-5">
                    <div className="row">
                        <div className="col-md-12">
                            <p className="fl-subtitle-xs mb-3">
                                Effortless Command Execution Across Batches and Environments
                            </p>
                            <p className="fl-text">
                                AVAP Shell transforms your API development experience by integrating Brunix AI into the CLI, specifically designed for seamless operation with the AVAP Framework. It supports both batch and real-time execution, providing greater functionality and speed compared to traditional shells that process one command at a time. With AI-driven capabilities, AVAP Shell offers enhanced efficiency and intuitiveness, learning from past interactions to improve performance and suggest optimizations.
                            </p>
                        </div>
                    </div>

                </section>

                <div className="w-100 fl-divider" style={{ backgroundImage: `url(${dividerbg})` }}>

                </div>



                <section className="container">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center mb-5">Key Features</div>
                        </div>
                    </div>
                </section>

                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                           <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            /> 
                        </div>
                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">AVAP Framework Integration</h3>
                            <p className="fl-text">
                                Designed specifically for seamless operation with AVAP Framework, AVAP Shell optimizes your interaction with APIs, microservices, and AVAP-based environments. It understands and adapts to the unique structures of AVAP, offering users a tailored experience that accelerates development.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Batch Execution & Advanced Automation</h3>
                            <p className="fl-text">
                                Work more efficiently by running multiple commands or processes in parallel, eliminating the need for one-on-one interaction typical of standard terminals. AVAP Shell's batch execution optimizes repetitive tasks and streamlines workflows, reducing the time spent on manual operations.
                            </p>
                        </div>

                        <div className="col-md-6 text-center">
                            <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            
                            />
                        </div>
                    </div>
                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center">
                            <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>


                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Brunix AI-Powered Assistance & Automation</h3>
                            <p className="fl-text">
                                AVAP Shell uses Brunix AI to assist with command execution, making suggestions for improvements, automating repetitive tasks, and even detecting potential errors before they occur. The Brunix AI integration enhances the overall user experience by simplifying complex processes and making smarter decisions.
                            </p>
                        </div>

                    </div>
                </section>

                <section className="container fl-z-index-2 position-relative mb-8">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center mb-3">
                                Key Benefits
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                <p className="fl-subtitle-xs">
                                    Streamlined API Development & Deployment
                                </p>
                                <p className="fl-text">
                                    With batch processing, AVAP Shell reduces the friction of managing multiple tasks, making the entire development and deployment process smoother, faster, and more efficient.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                <p className="fl-subtitle-xs">
                                    Improved Productivity with Smarter Automation
                                </p>
                                <p className="fl-text">
                                    The Brunix AI integration automates tasks and improves them by analyzing past data and learning the best practices, ensuring faster and more accurate execution of commands.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                <p className="fl-subtitle-xs">
                                    Optimized Performance for Any Scale
                                </p>
                                <p className="fl-text">
                                    AVAP Shell scales with your needs. Its batch processing and AI enhancements ensure that you can handle larger, more complex deployments without slowing down.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default PublisherProduct;
