import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h3>1.3 Summary</h3>
      <p>
        AVAP™ is a dynamic programming language that offers a wide range of features promoting flexibility, 
        adaptability, and speed in application development. With its dynamic typing, automatic memory management, 
        runtime interpreter, and programming flexibility, AVAP™ becomes a powerful and versatile tool for developers.
      </p>
    </div>
  </Fragment>
);
export default Intro_1;
