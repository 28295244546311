import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, Spinner, Modal } from 'react-bootstrap';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import './LicensesAndSuscriptions.css'
import './../NewDashboard.css'
import config from '../../../configs/appConfig.json';

import icono_download from '../../../assets/img/newDashboard/icono_download.png';

import logo_AVAP from '../../../assets/img/newDashboard/logo_AVAP.png';
import logo_AVAP_DS from '../../../assets/img/newDashboard/logo_AVAP_DS.png';
import logo_AVAP_VS from '../../../assets/img/newDashboard/logo_AVAP_VS.png';
import logo_Gateway from '../../../assets/img/newDashboard/logo_Gateway.png';
import logo_Microsoft from '../../../assets/img/microsoft.png';
import link_icon from '../../../assets/img/link-icon.png';

import logotipo_collaborativeN from '../../../assets/img/newDashboard/logotipo-collaborativeN.png';
import logo_AVAP_Cloud from '../../../assets/img/newDashboard/logo_AVAP_Cloud.png';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import PaymentConfirmation from '../../../components/Modals/paymentConfirmation';
import PaymentSuccess from '../../../components/Modals/paymentSuccess';
import SessionService from '../../../services/SessionsService';
import AddCreditCard from '../../../components/Modals/addCreditCard';
import PaymentSuccessModal from '../../../components/Modals/paymentSuccess';


const LicensesAndSuscriptions = () => {
  let { path, url } = useRouteMatch();
  const [licenseslist, setLicenseslist] = useState([]);
  const [divsLicenses, setDivsLicenses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentEnable, setPaymentMode] = useState(true);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);
  const [card_id_stripe, setCardID] = useState('');
  const [altaSeted, setAltaSeted] = useState(0);

  const [SuccesPayment, setSuccesPayment] = useState(false);

  const [annualPayment, setAnnualPayment] = useState(false);

  const [buyTitle, setBuyTitle] = useState('');
  const [buySubTitle, setBuySubTitle] = useState('');
  const [buyDescription, setBuyDescription] = useState('');
  const [buyAmmount, setBuyAmmount] = useState(0);
  const [buyProrratedAmmount, setProrratedBuyAmmount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [cards_, setCards] = useState([]);
  const [newadded_card, setAddedCard] = useState('');
  const [itemSelected, setItemSelected] = useState({});
  const [suscription_id, setsuscription_id] = useState(2)
  const getCards = async () => {
    try {
      const result = (await ObexRequestHandler.get('/cards', {}, true)).data || {};
      const { defaultCard, cards } = result;
      const pmCards = [];
      if (cards) {
        for (const c of cards) {
          const isDefault = c.id === defaultCard;
          c.defaultCard = isDefault;
          isDefault ? pmCards.unshift(c) : pmCards.push(c);
          if (cards_.some(e => e.id == c.id)) {
            //console.log('exists');
          } else {
            setAddedCard(c.id);
          }
        }
      }
      setCards(pmCards);
    } catch (error) {
      console.error('ERROR FETCHING CARDS ', error);
      throw error;
    }
  }
  const startPayment = () => {
    setLoading(true);
    setSuccess('');
    setError('');
  }

  const onCloseAction = () => {

    setShow(false)
  }

  const OnSuccesAction = () => {

    setShow(false)
    if (!error) {
      setSuccesPayment(true);
    }
    setShow(false);
  }


  const makePayment2 = async () => {
    try {
      startPayment();
      sendBuy(itemSelected)
      /*
      const payload = {};
      const result = await ObexRequestHandler.post('/signup_avapcloud', payload);
      const { success, message, data } = result;
      if (!success) throw { message };
      setSuccess(data);
      setPaymentSuccess(true);
      setPaymentMode(false);
      OnSuccesAction();
      /*setTimeout(() => {
        onSuccess();
      }, 5000);*/ // TODO MIRAR SI HABILITAR ESTO

    } catch (error) {
      setError(error.message || error);
      console.error('ERROR BUYING EXTRA PROJECTS SLOTS ', error);
    }
    setLoading(false);

  }
  function sortByKey(array, key) {
    return array.sort(function (a, b) {
      let x = a[key]; let y = b[key];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
  }

  const makePayment = async (suscription_id) => {
    try {
      startPayment();
      const payload = {
        card_stripe_id: card_id_stripe,
        suscriptionId: suscription_id,
        annual: annualPayment
      };
      console.log(payload);
      const result = await ObexRequestHandler.post('/signup_avapcloud', payload);
      // const payload = { card_stripe_id: 5 };
      // const result = await ObexRequestHandler.post('/extradevelopers/buy', payload);
      const { success, message, data } = result;
      if (!success) throw { message };
      setSuccess(data);
      setPaymentSuccess(true);
      setPaymentMode(false);
      OnSuccesAction();
      /*setTimeout(() => {
        onSuccess();
      }, 5000);*/ // TODO MIRAR SI HABILITAR ESTO
    } catch (error) {
      setError(error.message || error);
      console.error('ERROR BUYING EXTRA PROJECTS SLOTS ', error);
    }
    setLoading(false);
  }


  const makePayment3 = async (suscription_id) => {
    try {
      startPayment();
      const payload = {
        card_stripe_id: card_id_stripe,
        suscriptionId: suscription_id,
        annualPayment: annualPayment,

        newSubscriptionId: suscription_id
      };
      console.log(payload);

      const result = await ObexRequestHandler.post('/upgrade_subscription', payload);
      // const payload = { card_stripe_id: 5 };
      // const result = await ObexRequestHandler.post('/extradevelopers/buy', payload);
      const { success, message, data } = result;
      if (!success) throw { message };
      setSuccess(data);
      setPaymentSuccess(true);
      setPaymentMode(false);
      OnSuccesAction();
      /*setTimeout(() => {
        onSuccess();
      }, 5000);*/ // TODO MIRAR SI HABILITAR ESTO


      setIsBuying(true);
      try {
        const result = (await ObexRequestHandler.post('/buy_license', { license_id: 7 }));

        //if(result.success){
        //  
        //  window.location.reload();
        //}


        //return result;
      } catch (error) {
        console.error('buy_license', error);
      } finally {
        setIsBuying(false);
      }




    } catch (error) {
      setError(error.message || error);
      console.error('ERROR BUYING EXTRA PROJECTS SLOTS ', error);
    }
    setLoading(false);
  }



  const history = useHistory();
  const logos =
    [
      {
        id: 1,
        name: 'AVAP Interpreter & Language Server',
        img: logo_AVAP
      },
      {
        id: 2,
        name: 'AVAP Dev Extensions for Microsoft Visual Code',
        img: logo_AVAP_DS,
      },
      {
        id: 3,
        name: 'AVAP Dev Studio',
        img: logo_AVAP_DS,
      },
      {
        id: 4,
        name: 'AVAP Virtual Server Enterprise',
        img: logo_AVAP_VS,
      },
      {
        id: 5,
        name: 'AVAP Gateway Advanced',
        img: logo_Gateway,
      },
      {
        id: 6,
        name: 'AVAP Collaborative Network',
        img: logotipo_collaborativeN,
      },
      {
        id: 7,
        name: 'AVAP Cloud Business',
        img: logo_AVAP_Cloud,
      },
      {
        id: 8,
        name: 'AVAP Cloud Enterprise',
        img: logo_AVAP_Cloud,
      },
      {
        id: 9,
        name: 'AVS',
        img: logo_AVAP_VS,
      },
      {
        id: 10,
        name: 'AVAP Gateway Advanced',
        img: logo_Gateway,
      },
      {
        id: 12,
        name: 'AVAP Cloud Professional',
        img: logo_AVAP_Cloud,
      },
      {
        id: 13,
        name: 'AVAP Virtual Server (Standalone & P2P)',
        img: logo_AVAP_VS,
      },
      {
        id: 14,
        name: 'AVAP (Interpreter & Language Server)',
        img: logo_AVAP,
      },
    ];
  const [isBuying, setIsBuying] = useState(false);


  useEffect(() => {
    getLicenses();
    getCards();
    window.scrollTo(0, 0);
  }, []);

  const getLicenses = async () => {
    try {
      const result = (await ObexRequestHandler.get('/get_all_licenses_availables')).data;
      generateItemLicenses(result);
    } catch (error) {
      console.error('ERROR GET PRIVACY INFO', error);
    }
  };

  const get_user_licenses = async () => {
    try {
      const result = (await ObexRequestHandler.get('/get_user_licenses')).data;
      return result;
    } catch (error) {
      console.error('get_user_licenses', error);
    }
  };
  const get_prorrated_price = async (suscription_id) => {
    try {
      const result = (await ObexRequestHandler.get(`/pricing_plan?id=${suscription_id}`)).data;
      setProrratedBuyAmmount(result.amount)
      return result;
    } catch (error) {
      console.error('pricing_plan', error);
    }
  };

  const OpenBuy = async (item) => {
    setAltaSeted(0);
    setsuscription_id(item.obex_subscription_id)
    console.log(item)
    setBuyAmmount(item.price)
    await get_prorrated_price(item.obex_subscription_id);
    setBuyTitle(`Suscribe to ${item.name}`)
    setBuySubTitle('Confirm your plan and proceed to pay for your new subscription')
    setBuyDescription(item.name);
    setShow(true);

  }

  const OpenUpgrade = async (item) => {
    setAltaSeted(2);
    setsuscription_id(item.obex_subscription_id)
    console.log(item)
    setBuyAmmount(item.price)
    await get_prorrated_price(item.obex_subscription_id);
    setBuyTitle(`Upgrade to ${item.name}`)
    setBuySubTitle('Confirm your plan and proceed to pay your upgrade')
    setBuyDescription(item.name);
    setShow(true);

  }


  const OpenBuy2 = (item) => {

    if (item.id === 3) return;
    setAltaSeted(1);
    console.log(item)
    setItemSelected(item);
    setBuyAmmount(item.price)
    setBuyTitle(`Buy ${item.name}`)
    setBuySubTitle('Please confirm your buy and proceed to pay.')
    setBuyDescription(item.name);
    setShow(true);

  }

  const sendBuy = async (item) => {
    setIsBuying(true);
    try {
      const result = (await ObexRequestHandler.post('/buy_license', { license_id: item.id }));

      if (result.success) {

        window.location.reload();
      }

      return result;
    } catch (error) {
      console.error('buy_license', error);
    } finally {
      setIsBuying(false);
    }

  }


  const generateItemLicenses = async (licenses) => {
    let arrayDivsProyects = [];

    let itemsBought = await get_user_licenses();

    let licOrdered = sortByKey(licenses, 'orden');

    for (const key in licOrdered) {
      let item = licenses[key];
      let imgFounded = logos.find(proj => proj.id === item.id);
      let itHasBeenBought = itemsBought.find(itemBought => itemBought.id === item.id);
      let bought = itHasBeenBought ? true : false;

      //console.log(itemsBought);
      let boughtOther = false;
      if (item.id === 12) {
        boughtOther = (itemsBought.find(itemBought => itemBought.id === 7));
      }

      if (item.id === 7) {
        boughtOther = (itemsBought.find(itemBought => itemBought.id === 12));
      }

      if ((licenses[key].obex_license_type_id === 2) || (licenses[key].obex_license_type_id === 1 && licenses[key].id === 3))
        arrayDivsProyects.push(

          <div className="boxShadowCard borderRadius " key={key}>
            <div className='py-3 d-flex flex-column'>
              {/* Header card */}
              <div ></div>
              <div className='d-flex boxShadowCardBottomTitle pl-3 pb-3'>
                <div className='pr-4'>
                  <div className='sizeImgTitleCard'>
                    <img src={imgFounded.img} alt="" width={'100%'} height={'100%'} />
                  </div>
                </div>
                <div className='fontSizeTitleCard textBold'>
                  {licenses[key].name}

                </div>
              </div>

              {/* Header card END*/}

              {/* Content */}
              <div className='d-flex flex-column align-self-center widthContentCard pt-3'>
                <div className='d-flex flex-column' style={{ height: '271px' }}>
                  <div className='colorBlack mb-3' style={{ height: '100%', overflow: 'auto' }}>
                    {licenses[key].description}
                  </div>

                  {licenses[key].obex_license_type_id != 2 && false ?

                    (licenses[key].obex_license_type_id != 3 ?
                      <div className="select-container d-flex mb-5">
                        <div className=' border-right backgroundSelect borderRadiusLeft d-flex align-items-center px-2'>Platform</div>

                        {licenses[key].obex_license_type_id < 3 ?
                          <select className="form-select p-2 borderRadiusRight backgroundSelect colorBlack" aria-label="Default select example">
                            <option value="1">Windows</option>
                            <option value="2">macOS</option>
                            <option value="3">Linux</option>

                          </select> :
                          <select className="form-select p-2 borderRadiusRight backgroundSelect colorBlack" aria-label="Default select example">
                            <option value="1">Kubernete deployment</option>
                          </select>
                        }
                      </div>
                      :
                      <div className="textBold link-container d-flex mb-5"><a style={{ width: '100%', height: '40px', borderRadius: '12px', color: 'black' }} className="border-right backgroundSelect borderRadiusLeft borderRadiusRight d-flex align-items-center px-2" href="https://marketplace.visualstudio.com/items?itemName=101OBeXCorp.avap-linter"><img style={{ marginRight: '10px', color: 'black' }} src={logo_Microsoft} alt="" width={"20px"} height={"20px"} />Microsoft Visual Code Marketplace</a></div>

                    ) :

                    <></>



                  }


                  <div className='d-flex flex-row W-100 justify-content-between colorGreen textBold mb-3 fontText3'>
                    {licenses[key].price >= 0 ? (
                      <>
                        <div>
                          Price: {licenses[key].price > 0 ? '(monthly)' : ''}
                        </div>
                        <div>
                          {licenses[key].price === 0
                            ? 'FREE'
                            : bought
                              ? 'Active license'
                              : boughtOther && licenses[key].id === 12
                                ? 'Other active'
                                : `$${licenses[key].price.toFixed(2)} USD`}
                        </div>
                      </>
                    ) : (
                      <div></div>
                    )}
                  </div>


                  <div className='d-flex flex-row W-100 justify-content-between'>
                    <div>
                      <div className='sizeImgTitleCard'>
                        {licenses[key].price > 0 && licenses[key].obex_license_type_id != 2 && false ? <img src={icono_download} alt="" width={"100%"} height={"100%"} /> : <></>}
                      </div>
                    </div>

                    {licenses[key].id === 6 ?
                      <div className='w-65'>
                        <button style={{ marginTop: 'auto' }} className="btn customButton-green w-100 textBold" disabled={isBuying || licenses[key].id === 3} onClick={() => {
                          if (licenses[key].id != 6) {
                            if (licenses[key].price >= 0) {

                              OpenBuy(item);


                            } else history.push('mail://www.101obex.com/contactus') /*sendBuy(item);*/
                          } else {

                            //history.push('http://p2p.101obex.com')
                            if (config.url === 'https://avs-primary-pre.101obex.mooo.com') window.open('https://p2p-pre.101obex.mooo.com', '_blank');
                            else window.open('https://avapcollaborativenetwork.com/', '_blank');

                          }
                        }}>{
                            licenses[key].price >= 0 ?
                              (licenses[key].price === 0 ?
                                (
                                  licenses[key].id === 6 ?
                                    ' Go to AVAP Collaborative Network' :
                                    'Download'
                                )
                                : 'Buy Now') :
                              'Contact Us'

                          }</button>
                      </div>
                      :
                      <div className='w-60'>
                        <button
                          style={{ marginTop: 'auto' }}
                          className="btn customButton-green w-100 textBold"
                          disabled={isBuying || licenses[key].id === 3 || (boughtOther && licenses[key].id === 12)}
                          onClick={() => {
                            // Evita cualquier acción si el producto es AVAP Dev Studio (id=3)
                            if (licenses[key].id === 3) return;

                            if (!bought) {
                              if (licenses[key].price >= 0) {
                                if (licenses[key].id === 3) OpenBuy2(item)
                                else if ((boughtOther && licenses[key].id === 7)) {
                                  OpenUpgrade(item);
                                }
                                else OpenBuy(item);
                              } else {
                                window.open('mailto:sales@101obex.com', '_self')
                              }
                            } else {
                              if (licenses[key].id === 7)
                                history.push('/cloud_dashboard')
                              else history.push('/dashboard/licenses-and-downloads')
                            }
                          }}
                        >{
                            licenses[key].id === 3 ? 'Coming Soon' : // **Cambio realizado**
                              (licenses[key].price >= 0 ?
                                (licenses[key].price === 0 ?
                                  (
                                    licenses[key].id === 6 ?
                                      ' Go to AVAP Collaborative Network' :
                                      'Download'


                                  )


                                  : (bought ? (licenses[key].id === 3 ? 'Download' : 'Go to AVAP Cloud login') : (boughtOther && licenses[key].id === 7 ? 'Upgrade' : 'Buy Now'))) :
                                'Contact Us')

                          }</button>
                      </div>
                    }
                  </div>

                </div>
              </div>
              {/* Content END*/}

            </div>
          </div>

        )



    }

    setDivsLicenses(arrayDivsProyects);
    setIsLoading(false);
    // return arrayDivsProyects;

  }

  const handleModal = () => {
    setShowModal(!showModal);
    //onCloseAction();
    getCards();

  };


  const handleCard = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    if (value == 'addCard') {
      handleModal();
    } else {
      setCardID(value);

    }
    //console.log(value);
    e.preventDefault();
    e.stopPropagation();

  }

  return (
    <><div>
      <h2 className='textBold marginBottom2rem'>Licenses and Suscriptions</h2>
      <p className='fontText3 colorBlack marginBottom2rem'>Subscribe and manage your AVAP products and services.</p>
      {isLoading &&
        <div className='d-flex justify-content-center align-items-center '>
          <Spinner animation="border" />
        </div>}
      <div className="grid-container" style={{ width: '65vw' }}>

        {divsLicenses}
      </div>
    </div>
      {show &&

        <Modal style={{ marginTop: '0rem' }} dialogClassName="addons-dialog w-75 obex-dialog"
          show={show}
          onHide={onCloseAction}
          animation={false}
          container={document.getElementById('avapIdWrapper')}>
          <Modal.Header closeButton className="border-0 pb-0">
            <Modal.Title className="text-center w-100">
              <Row>
                <Col className="addons-title">
                  <span >{buyTitle}</span>
                </Col>
              </Row>
              <Row>
                <Col md="10" className="mx-auto text-center modal-subtitle">
                  <span className="text-center d-block">{buySubTitle}</span>
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <PaymentConfirmation
              actionSubmit={altaSeted === 0 ? () => makePayment(suscription_id) : (altaSeted === 1 ? makePayment2 : () => makePayment3(suscription_id))}
              handleGoBack={() => {
                setPaymentMode(false),
                  setShow(false);
              }}
              handleAnnualPayment={() => { setAnnualPayment(!annualPayment); console.log(annualPayment) }}
              nameServiceSelected={buyDescription}
              amountMonth={buyAmmount}
              amountYear={buyAmmount}
              processingPayment={loading}
              success={success}
              error={error}
              handleCard={handleCard}
              isBlue={false}
              cards={cards_}
              prorated={true}
              addedCard={newadded_card}
              spanish={false}
            />
          </Modal.Body>
        </Modal>
      }


      <Modal style={{ marginTop: '0rem', paddingTop: '7rem' }} dialogClassName="addons-dialog w-75 obex-dialog"
        show={SuccesPayment}
        container={document.getElementById('avapIdWrapper')}
      >
        <PaymentSuccessModal
          blue={false}
          paymentDescription={'Your payment was successful'}
          paymentType={'Your payment was successful'}
          onCloseAction={() => {

            history.push('/dashboard');
            history.push('/dashboard/licenses-and-suscriptions');

            getLicenses();
            getCards();
            window.scrollTo(0, 0);
            setSuccesPayment(false);

            setSuccess('');
            setError('');
            setSuccesPayment(false);

          }}
        >
        </PaymentSuccessModal>
      </Modal>



      {showModal && <AddCreditCard show={showModal} handleClose={handleModal} isGreen={true} spanish={false} />}

    </>

  );
}


export default LicensesAndSuscriptions;
