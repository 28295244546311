import React, { Fragment } from 'react';
import './chapter.css'
const Chapter10_5 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>6.4 Conclusions</h2>
      <p>
        The IF-THEN-ELSE statement in AVAP™ provides an efficient way to make decisions based on specific conditions. Similar to other programming languages, it allows for executing different blocks of code based on the outcome of evaluating a condition.
      </p>
    </div>
  </Fragment>
);

export default Chapter10_5;
