import React, { FunctionComponent, useEffect, useState, Fragment } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import FetchingSpinner from '../../../components/basicComponents/loaderModal';
import ObexAlert from '../../../components/basicComponents/ObexAlerts';


const borderColors = ['#FF5733', '#8BC222', '#2299C2', 
                      '#C02AC7', '#C72A44', '#C7C52A', 
                      '#6D3B0F', '#073241', '#B2B684',
                      '#591303', '#032E59', '#585903', 
                      '#2F0359', '#E16FE8', '#B1E86F', 
                      '#E89D6F', '#84766E', '#0A6B88'];

const baseDataset = {
  fill: true,
  lineTension: 0.3,
  backgroundColor: 'rgba(225, 204,230, .3)',
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderColor: 'rgb(205, 130,1 58)',
  pointBackgroundColor: 'rgb(255, 255, 255)',
  pointBorderWidth: 10,
  pointHoverRadius: 5,
  pointHoverBackgroundColor: 'rgb(0, 0, 0)',
  pointHoverBorderColor: 'rgba(220, 220, 220,1)',
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10,
};

const options = { 
  responsive: true,
  scales: {
    yAxes: [{
      stacked: true,
      ticks: {
        min: 0,
        stepSize: 1
      }
    }]
  }
}

type TransactionsProps = {
    from: string,
    to: string,
    project: string
};

const TransactionsMediaStats:FunctionComponent<TransactionsProps> = ({ from, to, project }) => {

  const [chartData, setChartData] = useState({ datasets: [] });
  const [chartData2, setChartData2] = useState({ datasets: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [warning, setWarning] = useState('');



  const options2 = {
    scales: {
      xAxes: [{
        gridLines: {
          display: false, // Ocultar las líneas de cuadrícula en el eje x
          drawOnChartArea: false // No dibujar las líneas de cuadrícula dentro del área del gráfico
        }
      }],
      yAxes: [{
        ticks: {
          display: false, // Ocultar los ticks en el eje y
        },
        gridLines: {
          display: false, // Ocultar las líneas de cuadrícula en el eje y
          drawOnChartArea: false // No dibujar las líneas de cuadrícula dentro del área del gráfico
        }
      }]
    },
    legend: {
      display: false, // Ocultar la leyenda completa
    }
  };
  
  const getStats = async () => {
    try {
      setLoading(true);
      const mediaParams = { from, to };
      const mediaStats = (await ObexRequestHandler.get(`/media_stats/${project}`, mediaParams)).data || {};
      console.log(mediaStats);
      
      const { days, stats } = mediaStats;
      processData(days);
      const dayLabels = Object.keys(days);
      const chartInfo = { 
        labels: dayLabels,
        datasets: [],
      }; 
      const catLabels = Object.keys(stats);

      for (let i = 0; i < catLabels.length; i++) {
        const data = [];
        const label = catLabels[i];
        const borderColor = borderColors[i];
        for (let y = 0; y < dayLabels.length; y++) {
          const dayLabel = dayLabels[y];
          const day = days[dayLabel];
          const count = (day && day[label]) ? day[label] : 0;
          data.push(count);
        }
        chartInfo.datasets.push(Object.assign({ label, data, borderColor }, baseDataset));
      }
      setChartData(chartInfo);

    } catch (error) {
      console.error('ERROR FETCHING TRANSACTIONS BY PROJECT ', error);
      setError(error.message || error);
    }
    setLoading(false);
  }

  const processData = (data) => {

    // Values test
    //  data = {
    //   '2023-01-01': { "Wallets": 20, "Tools": 20 },
    //   '2023-01-03': { "Wallets": 14, "Tools": 10 },
    //   '2023-02-03': { "Wallets": 14, "Tools": 10 },
    //   '2023-02-04': { "Wallets": 2, "Tools": 3 },
    //   '2023-03-04': { "Wallets": 10, "Tools": 5 },
    //   '2023-03-06': { "Wallets": 2, "Tools": 3 }
    // };

    const groupedByMonth = {};


    for (const date in data) {
      const month = date.slice(0, 7); // Obtener el año y el mes en formato 'YYYY-MM'
      if (!groupedByMonth[month]) {
        groupedByMonth[month] = { Wallets: 0, Tools: 0 };
      }
      if (data[date].Wallets) {
        groupedByMonth[month].Wallets += data[date].Wallets;
      }
      if (data[date].Tools) {
        groupedByMonth[month].Tools += data[date].Tools;
      }
    }


    let newLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    let newDataDataset = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    for (const key in groupedByMonth) {
      const element = groupedByMonth[key];
      let total = element.Wallets + element.Tools;
      const month = key.split('-')[1];
      let PositionMonth = Number(month);
      //replace value
      newDataDataset.splice(PositionMonth - 1, 1, total);

    }


    // Preparar los datos en el formato adecuado para el gráfico
    const chartData = {
      labels: newLabels,
      datasets: [
        {
          label: '',
          backgroundColor: '#1F78B4',
          borderColor: '#1F78B4)',
          borderWidth: 1,
          barThickness: 15, // Ancho de las barras
          maxBarThickness: 15, // Ancho máximo de las barras
          data: newDataDataset,
        },
      ],
    };

    setChartData2(chartData); // Actualizar el estado de chartData
  };


  useEffect(() => {
    if (!from || !to || !project) {
      setLoading(true);
      return;
    };
    getStats();
  }, [from, to, project]);

  return (
    <div>
      
      {loading && chartData.datasets.length > 0 ? 
        <FetchingSpinner/>
      :
        <Fragment>
        
          {chartData.datasets.length > 0 ? <>
            <Bar data={chartData2} options={options2} height={170} />

            {/* <Line data={chartData} height={90} options={options}/> */}
          </>
            : <span></span>
          }
        </Fragment>
      }
    <ObexAlert type='error' msg={error}/>
    </div>
  );
};


export default TransactionsMediaStats;