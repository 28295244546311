import React, { useState } from 'react';
import { Form, Button, Tabs, Tab, Row, Col } from 'react-bootstrap';

const AddApiToCatalogueForm = () => {
    const [formData, setFormData] = useState({
        showApi: false,
        publicApiName: '',
        policy: '',
        description: '',
        detail: '',
        email: '',
        customFields: [{ fieldName: '', fieldValue: '' }],
        overrideGlobalSettings: false,
        oauthLimit: -1,
        requireKeyApproval: false,
        redirectKeyRequests: false,
        dynamicClientRegistration: false,
        developerFields: [{ fieldName: '' }]
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleAddField = (field) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: [...prevData[field], { fieldName: '', fieldValue: '' }],
        }));
    };

    const handleFieldChange = (e, index, field) => {
        const { name, value } = e.target;
        const updatedField = [...formData[field]];
        updatedField[index][name] = value;
        setFormData((prevData) => ({ ...prevData, [field]: updatedField }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("API Catalogue Data Submitted:", formData);
    };

    const handleCancel = () => {
        // Reset form data to initial state
    };

    return (
        <Form className="cloudForm" onSubmit={handleSubmit} style={{ maxWidth: '700px', margin: '0 auto' }}>
            <Tabs defaultActiveKey="details" id="api-catalogue-tabs" className="mb-3">
                {/* API Details Tab */}
                <Tab eventKey="details" title="API Details">
                    <h3>Show/Hide API in Developer Portal</h3>
                    <p>Checking the box below will show the API in the catalogue in the Developer portal</p>
                    
                    {/* Custom Toggle for Show API */}
                    <Form.Group className="d-flex align-items-center">
                        <Form.Label className="mr-2">Show API</Form.Label>
                        <label className="custom-toggle">
                            <input
                                type="checkbox"
                                name="showApi"
                                checked={formData.showApi}
                                onChange={handleChange}
                            />
                            <span className="slider"></span>
                        </label>
                    </Form.Group>
                    
                    <h3>Modify the parameters for this API entry</h3>
                    <Form.Group controlId="publicApiName">
                        <Form.Label>Public API Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="publicApiName"
                            value={formData.publicApiName}
                            onChange={handleChange}
                            placeholder="Name*"
                        />
                    </Form.Group>
                    <Form.Group controlId="policy">
                        <Form.Label>Available Policies</Form.Label>
                        <Form.Control
                            as="select"
                            name="policy"
                            value={formData.policy}
                            onChange={handleChange}
                        >
                            <option value="">Please select a policy</option>
                            <option value="policy1">Policy 1</option>
                            <option value="policy2">Policy 2</option>
                        </Form.Control>
                        <Form.Text className="text-muted">
                            No policies left to publish, please create a new policy or unpublish an existing one.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="description">
                        <Form.Label>Describe this API in a few words</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            rows={3}
                            placeholder="A really cool API that provides..."
                        />
                    </Form.Group>
                    <Form.Group controlId="detail">
                        <Form.Label>Give us a little detail (can contain markdown)</Form.Label>
                        <Form.Control
                            as="textarea"
                            name="detail"
                            value={formData.detail}
                            onChange={handleChange}
                            rows={5}
                            placeholder="Detailed description with **markdown** support"
                        />
                    </Form.Group>
                    <Form.Group controlId="email">
                        <Form.Label>Catalogue owner email address</Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="catalogue_owner@tyk.io"
                        />
                    </Form.Group>
                    <h3>Custom Fields</h3>
                    {formData.customFields.map((field, index) => (
                        <Row key={index}>
                            <Col>
                                <Form.Group controlId={`customField-name-${index}`}>
                                    <Form.Label>Field Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="fieldName"
                                        value={field.fieldName}
                                        onChange={(e) => handleFieldChange(e, index, 'customFields')}
                                        placeholder="Type a name"
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId={`customField-value-${index}`}>
                                    <Form.Label>Field Value</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="fieldValue"
                                        value={field.fieldValue}
                                        onChange={(e) => handleFieldChange(e, index, 'customFields')}
                                        placeholder="Type a value"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    ))}
                    <Button variant="outline-primary" onClick={() => handleAddField('customFields')}>
                        Add Custom Field
                    </Button>
                    {/* <Button variant="outline-primary" className="mt-3">Attach Documentation</Button> */}
                </Tab>

                {/* Settings Tab */}
                <Tab eventKey="settings" title="Settings">
                    <h3>Catalogue Settings</h3>
                    <Form.Group className="d-flex align-items-center">
                        <Form.Label className="mr-2">Override Global Settings</Form.Label>
                        <label className="custom-toggle">
                            <input
                                type="checkbox"
                                name="overrideGlobalSettings"
                                checked={formData.overrideGlobalSettings}
                                onChange={handleChange}
                            />
                            <span className="slider"></span>
                        </label>
                    </Form.Group>

                    <Form.Group controlId="oauthLimit">
                        <Form.Label>Define number of allowed OAuth requests</Form.Label>
                        <Form.Control
                            type="number"
                            name="oauthLimit"
                            value={formData.oauthLimit}
                            onChange={handleChange}
                        />
                        <Form.Text className="text-muted">Use -1 or 0 for unlimited number of OAuth</Form.Text>
                    </Form.Group>

                    <Form.Group className="d-flex align-items-center">
                        <Form.Label className="mr-2">Require Key Approval</Form.Label>
                        <label className="custom-toggle">
                            <input
                                type="checkbox"
                                name="requireKeyApproval"
                                checked={formData.requireKeyApproval}
                                onChange={handleChange}
                            />
                            <span className="slider"></span>
                        </label>
                    </Form.Group>

                    <Form.Group className="d-flex align-items-center">
                        <Form.Label className="mr-2">Redirect Key Requests</Form.Label>
                        <label className="custom-toggle">
                            <input
                                type="checkbox"
                                name="redirectKeyRequests"
                                checked={formData.redirectKeyRequests}
                                onChange={handleChange}
                            />
                            <span className="slider"></span>
                        </label>
                    </Form.Group>

                    <h3>Developer Request Form Customisation</h3>
                    {formData.developerFields.map((field, index) => (
                        <Form.Group key={index} controlId={`developerField-${index}`}>
                            <Form.Label>Field Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="fieldName"
                                value={field.fieldName}
                                onChange={(e) => handleFieldChange(e, index, 'developerFields')}
                                placeholder="Type the name of the field"
                            />
                        </Form.Group>
                    ))}
                    <Button variant="outline-primary" onClick={() => handleAddField('developerFields')}>
                        Add Field
                    </Button>

                    <Form.Group className="d-flex align-items-center mt-4">
                        <Form.Label className="mr-2">Dynamic Client Registration for portal APIs</Form.Label>
                        <label className="custom-toggle">
                            <input
                                type="checkbox"
                                name="dynamicClientRegistration"
                                checked={formData.dynamicClientRegistration}
                                onChange={handleChange}
                            />
                            <span className="slider"></span>
                        </label>
                    </Form.Group>
                </Tab>
            </Tabs>

            <div className="d-flex justify-content-between mt-4">
                <Button variant="primary" type="submit">Save API to Catalogue</Button>
                <Button variant="outline-secondary" onClick={handleCancel}>Cancel</Button>
            </div>
        </Form>
    );
};

export default AddApiToCatalogueForm;
