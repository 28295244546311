import React from 'react';
import './chapter.css'
const Chapter9_28 = () => (
    <div>
        <h2>Value Comparisons</h2>
        <p>
            The operators <code>&lt;</code>, <code>&gt;</code>, <code>==</code>, <code>&gt;=</code>, <code>&lt;=</code>, and <code>!=</code> compare the values of two objects. The objects do not need to be of the same type.
        </p>
        <p>
            The chapter <strong>Objects, Values, and Types</strong> states that objects have a value (in addition to type and identity). The value of an object is a rather abstract notion in Python: For example, there is no canonical method to access the value of an object. Furthermore, there is no requirement that the value of an object must be constructed in a particular way, e.g., composed of all its data attributes. Comparison operators implement a particular notion of what an object's value is.
        </p>
        <p>
            The default behavior for equality comparison (<code>==</code> and <code>!=</code>) is based on object identity. Therefore, comparison of instances with the same identity results in equality, and comparison of equality of instances with different identities results in inequality.
        </p>
        <p>
            No default comparison order (<code>&lt;</code>, <code>&gt;</code>, <code>&lt;=</code>, <code>&gt;=</code>) is provided; an attempt generates a <code>TypeError</code>.
        </p>
        <p>
            The following list describes the comparison behavior of the most important built-in types:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Numbers</strong>: Built-in numeric types (<code>int</code>, <code>float</code>, <code>complex</code>) and types from the standard library (<code>fractions.Fraction</code> and <code>decimal.Decimal</code>) can be compared with themselves and among their types, with the restriction that complex numbers do not support order comparisons. Within the limits of the involved types, they are compared mathematically correctly without loss of precision.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>None and NotImplemented</strong>: They are singletons. PEP 8 advises that comparisons for singletons should be done with <code>is</code> or <code>is not</code>, never with equality operators.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Binary Sequences</strong>: Instances of <code>bytes</code> or <code>bytearray</code> compare lexicographically using the numeric values of their elements.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Character Strings</strong>: Instances of <code>str</code> compare lexicographically using Unicode code points (the result of the built-in <code>ord()</code> function) or their characters.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Sequences</strong>: Instances of <code>tuple</code>, <code>list</code>, or <code>range</code> can only be compared within their types, with the restriction that ranges do not support order comparisons. Equality comparisons between these types result in inequality, and order comparisons between these types generate <code>TypeError</code>. They compare lexicographically using comparison of their corresponding elements.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Mappings</strong>: Instances of <code>dict</code> compare equal if and only if they have the same <code>(key, value)</code> pairs.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Sets</strong>: Instances of <code>set</code> or <code>frozenset</code> can be compared with each other and among their types. They define order comparison operators with the intention of checking subsets and supersets.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Other Built-in Types</strong>: Most other built-in types do not have comparison methods implemented, so they inherit the default comparison behavior.</li>
        </ul>
        <p>
            User-defined classes that customize their comparison behavior should follow some consistency rules, if possible:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Equality comparison should be reflexive.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Comparison should be symmetric.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Comparison should be transitive.</li>
        </ul>
        <p>
            If any of these conditions are not met, the resulting behavior is undefined.
        </p>
    </div>
);

export default Chapter9_28;
