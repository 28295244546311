import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h4>2.3.3 Direct Initialization</h4>
  <p>
    It is also possible to declare and initialize a global variable at the same time using the following syntax:
  </p>
  <pre><code>global variable_name=value</code></pre>
  <p>Where:</p>
  <ul>
    <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>variable_name</strong> is the name of the variable to be declared.</li>
    <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>value</strong> is the initial value to be assigned to the variable, which automatically defines the variable's type.</li>
  </ul>
</div>
  </Fragment>
);
export default Intro_1;
