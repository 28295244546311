import React, { Fragment } from 'react';
import './chapter.css'
const Infrastructure_AVS = () => (
  <Fragment>
         <div>
      <h3>1.1.2 Dynamic Code Construction in Real-Time</h3>
      <p>
        Thanks to the virtuality attribute, AVAP™ allows for dynamic code construction in real-time. 
        This means that the final code to be interpreted by the language server can vary and mutate according to current needs, 
        without the need for recompilation or redistribution.
      </p>
    </div>
  </Fragment>
);
export default Infrastructure_AVS;
