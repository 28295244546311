import React, { Fragment } from 'react';
import { Typography } from 'antd';
import { CodeOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const HelloSample = () => (
  <Fragment>
            <Typography>
    <Title level={1}>Hello World Sample</Title>
    <Paragraph>
      This example demonstrates a simple "Hello World" process where the string "Hello World!" is assigned to a variable and then returned as the result.
    </Paragraph>

    <Title level={2}>Steps:</Title>
    <ol>
      <li>
        <strong>addVar(result, 'Hello World!')</strong>
        <Paragraph>
          The string <code>'Hello World!'</code> is assigned to the variable <code>result</code>.
        </Paragraph>
      </li>
      <li>
        <strong>addResult(result)</strong>
        <Paragraph>
          The value of the variable <code>result</code> is returned, which in this case is <code>'Hello World!'</code>.
        </Paragraph>
      </li>
    </ol>

    <Title level={2}>Result:</Title>
    <Paragraph>
      The result is the string:
      <div style={{ backgroundColor: '#f5f5f5', padding: '16px', borderRadius: '8px' }}>
        <pre>
          <code>
            {`"Hello World!"`}
          </code>
        </pre>
      </div>
    </Paragraph>

    <Title level={2}>Usage Example:</Title>
    <div style={{ backgroundColor: '#f5f5f5', padding: '16px', borderRadius: '8px' }}>
      <pre>
        <code>
{`// Assign 'Hello World!' value to a variable
addVar(result, 'Hello World!')

// Return this variable
addResult(result)`}
        </code>
      </pre>
    </div>

    <Title level={2}>Notes:</Title>
    <Paragraph>
      This is a basic example to demonstrate how to assign a value to a variable and return it.
    </Paragraph>
  </Typography>
  </Fragment>
);
export default HelloSample;
