import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h4>1.2.2 Conversion to Floating-Point</h4>
  <p>
    To convert a value to a floating-point number, the <code>float()</code> function is used.
  </p>
  <pre><code>
text = "3.14" <br />
number = float(text)
  </code></pre>
</div>
  </Fragment>
);
export default Intro_1;
