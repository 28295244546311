import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Dropdown, DropdownButton, Table } from "react-bootstrap";
import GenericSelectableList from '../DashboardComponents/CustomSelectSearch'
import MultiColumnSelectableList from '../DashboardComponents/MultiColumnSelectableList'
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import SessionService from "../../services/SessionsService";

type GatewayFormProps = {
  id?: string;
  certs?: string[];
  basePath: string;
  selectedGA: string[];
  useCerts: boolean;
  balance: boolean;
  algorithm: string;
  routes: string[];
  onSubmit: (proxy: {
    id?: string;
    basePath: string;
    selectedGA: string[];
    useCerts: boolean;
    balance: boolean;
    algorithm: string;
    routes: string[];
    certs?: string[];
  }) => void;
};


const ProxyForm: React.FC<GatewayFormProps> = ({
  id,
  basePath = "",
  selectedGA = [],
  useCerts = false,
  balance = false,
  algorithm = "",
  routes = [],
  onSubmit,
}) => {
  const [localBasePath, setBasePath] = useState(basePath);
  const [localSelectedGA, setSelectedGA] = useState<string[]>(selectedGA);
  const [localUseCerts, setUseCerts] = useState(useCerts);
  const [localBalance, setBalance] = useState(balance);
  const [localAlgorithm, setAlgorithm] = useState(algorithm);
  const [localRoutes, setRoutes] = useState<string[]>(routes);
  const [certs, setCerts] = useState<string[]>(() => []);
  const [certsT, setCertsT] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [localSelectedCA, setSelectedCA] = useState([]);
  const [localSelectedA, setSelectedA] = useState([]);
  const [GAS, setGas] = useState([]);
  
  const [AMBIENTS, setAMBIENTS] = useState([]);

  const apis = [
    { id: 1, name: 'API 1' },
    { id: 2, name: 'API 2' },
    { id: 3, name: 'API 3' },
  ];

  const apisProduction = [
    { id: 101, name: 'Prod API 1' },
    { id: 102, name: 'Prod API 2' },
  ];


  const algorithms = [
    'Least Response Time',
    'Hash of IP',
    'Weighted Round Robin',
    'Consistent Hashing',
    'Random',
    'Geo IP',
  ];

  const getGAs = async () =>{

    const gas = await ObexRequestHandler.get(`/gateway/get_gas?organization=${SessionService.ORGANIZATION}`);

    setGas(gas.data);

  }

  const getAMBIENTS = async () =>{
    const gas = await ObexRequestHandler.get(`/gateway/get_gateway_ambients?organization=${SessionService.ORGANIZATION}`);
    setAMBIENTS(gas.data);
  }

  const getCerts = async () =>{
    const certs = await ObexRequestHandler.get(`/cert/get_certs?organization=${SessionService.ORGANIZATION}`)


    console.log(certs.data);

console.log(certs.data.map((ga) => ({ id: ga.id, name: ga.name, region_id: ga.region_id })))
    setCertsT(certs.data);

  };


  useEffect(() => {
    setBasePath(basePath);
    setSelectedGA(selectedGA);
    setUseCerts(useCerts || (certs && certs.length > 0)); // Activar automáticamente si hay certs
    setBalance(balance);
    setAlgorithm(algorithm);
    setRoutes(routes);
    getCerts();
    getGAs();
    getAMBIENTS();

    setCerts(certs || []); // Sincronizar certs
    
  }, [basePath, selectedGA, useCerts, balance, algorithm, routes, certs]);


  const handleAddGA = (ga: string) => {
    if (!localSelectedGA.includes(ga)) {
      setSelectedGA([...localSelectedGA, ga]);
    }
  };

  const handleRemoveGA = (ga: string) => {
    setSelectedGA(localSelectedGA.filter((item) => item !== ga));
  };

  const handleAddRoute = () => {
    setRoutes([...localRoutes, '']);
  };

  const handleUpdateRoute = (index: number, value: string) => {
    const updatedRoutes = [...localRoutes];
    updatedRoutes[index] = value;
    setRoutes(updatedRoutes);
  };

  const handleRemoveRoute = (index: number) => {
    setRoutes(localRoutes.filter((_, i) => i !== index));
  };

  const handleGenerateCert = () => {
    const newCert = `GeneratedCert-${Math.random().toString(36).substr(2, 9)}`;
    setCerts([...certs, newCert]);
  };

  const handleCopyCert = (cert: string) => {
    navigator.clipboard.writeText(cert).then(() => {
      alert('Certificate copied to clipboard!');
    });
  };


  const handleAddCA = (permit) => {
    // Añade un permiso seleccionado
    if (!localSelectedCA.some((p) => p.id === permit.id)) {
      setSelectedCA((prev) => [...prev, permit]);
    }
  };

  const handleRemoveCA = (permit) => {
    // Elimina un permiso seleccionado
    setSelectedCA((prev) => prev.filter((p) => p.id !== permit.id));
  };


  const handleAddA = (permit) => {
    // Añade un permiso seleccionado
    if (!localSelectedA.some((p) => p.id === permit.id)) {
      setSelectedA((prev) => [...prev, permit]);
    }
  };

  const handleRemoveA = (permit) => {
    // Elimina un permiso seleccionado
    setSelectedA((prev) => prev.filter((p) => p.id !== permit.id));
  };

  


  const handleRemoveCert = (index: number) => {
    setCerts(certs.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    onSubmit({
      id,
      basePath: localBasePath,
      selectedGA: localSelectedGA,
      useCerts: localUseCerts,
      balance: localBalance,
      algorithm: localAlgorithm,
      routes: localRoutes,
      certs, // Incluye los certificados
    });/*
    console.log({
      id,
      basePath: localBasePath,
      selectedGA: localSelectedGA,
      useCerts: localUseCerts,
      balance: localBalance,
      algorithm: localAlgorithm,
      routes: localRoutes,
      certs, // Incluye los certificados
      localSelectedCA
    })
*/
    const payload = {
      base_path: localBasePath,
      selected_ga: localSelectedGA,
      use_certs: localUseCerts,
      balance: localBalance,
      algorithm: localAlgorithm,
      selected_ca: localSelectedCA,
      organization: SessionService.ORGANIZATION
    }
    console.log(payload)

    await ObexRequestHandler.post('/gateway/set_proxies', payload)

  };


  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="basePath" className="mb-3">
        <Form.Label>Basepath</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter basepath"
          value={localBasePath}
          onChange={(e) => setBasePath(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="selectGA" className="mb-3">
        <Form.Label>G.A. List</Form.Label>
        <GenericSelectableList
          items={GAS.map((ga) => ({ id: ga.id, name: ga.alias, region_name: ga.region_name, region_id: ga.region_id }))} // Convierte las cadenas en objetos con `id` y `name`
          searchKeys={['name', 'region_name']} // Busca por el nombre
          onAdd={handleAddGA}
          onRemove={handleRemoveGA}
          renderLabel={(item) => item.name ? `${item.name} ${item.region_name}` : `${item}`} // Renderiza directamente el nombre
          placeholder="Select G.A."
          multiOption={true} // Permitir selección múltiple
          selectedItems={localSelectedGA}
          setSelectedItems={setSelectedGA}
        />
      </Form.Group>

      <Form.Group controlId="useCerts" className="mb-3">
        <Form.Check
          type="checkbox"
          label="Use Certs"
          checked={localUseCerts}
          onChange={(e) => setUseCerts(e.target.checked)}
        />
        {localUseCerts && (
<>

              <Form.Label>Select Cert</Form.Label>
              <GenericSelectableList
                items={certsT.map((ga) => ({ id: ga.id.toString(), name: ga.name }))}
                searchKeys={['id', 'name']} // Búsqueda por código o descripción
                onAdd={handleAddCA}
                onRemove={handleRemoveCA}
                renderLabel={(permit) => permit.name}
                placeholder="Select certs..."
                multiOption={false} // Permitir selección múltiple
                /*
                selectedItems={permissions}
                setSelectedItems={setPermissions}
*/

              />
</>
        )}
      </Form.Group>

      <Form.Group controlId="balance" className="mb-3">
        <Form.Check
          type="checkbox"
          label="Load Balancer"
          checked={localBalance}
          onChange={(e) => setBalance(e.target.checked)}
        />
        {localBalance && (
          <DropdownButton
            className="dropdownForm"
            title={localAlgorithm || 'Algorithm List'}
            onSelect={(algo) => setAlgorithm(algo || '')}
          >
            {algorithms.map((algo, index) => (
              <Dropdown.Item key={index} eventKey={algo}>
                {algo}
              </Dropdown.Item>
            ))}
          </DropdownButton>
        )}
      </Form.Group>

      <Form.Group controlId="selectGA" className="mb-3">
        <Form.Label>Ambients List</Form.Label>
        <GenericSelectableList
          items={AMBIENTS.map((ga) => ({ id: ga.ambient_id, name: ga.alias, region_name: ga.ambient_name, region_id: ga.region_id }))} // Convierte las cadenas en objetos con `id` y `name`
          searchKeys={['name', 'region_name']} // Busca por el nombre
          onAdd={handleAddA}
          onRemove={handleRemoveA}
          renderLabel={(item) => item.name ? `${item.name} ${item.region_name}` : `${item}`} // Renderiza directamente el nombre
          placeholder="Select Ambient."
          multiOption={true} // Permitir selección múltiple
          
          selectedItems={localSelectedA}
          //setSelectedItems={setSelectedA}
          
        />
      </Form.Group>

{/*}
      <Form.Group controlId="routes" className="mb-3">
        <Form.Label>Routes</Form.Label>

        <MultiColumnSelectableList
          items={{ left: apis, right: apisProduction }}
          searchKeys={{ left: ['name'], right: ['name'] }}
          renderLabel={(item) => item.name}
          onAdd={(item, column) => console.log(`Added from ${column}:`, item)}
          onRemove={(item) => console.log('Removed:', item)}
        />
      </Form.Group>
*/}
      <Row>
        <Col md='6'>
          <Button className="w-100" variant="primary" type="submit">
            Save Proxy
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ProxyForm;
