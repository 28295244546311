import React from 'react';
import './chapter.css'
const Chapter12_3 = () => (
  <div>
    <h2>Example Usage</h2>
    <p>Below is a practical example illustrating how to use the <code>addParam()</code> function in an API call:</p>
    <pre>
      <code>
        # API call with addParam() <br />
        addParam(user, "john_doe") <br />
        addParam(password, "password123")
      </code>
    </pre>
    <p>
      In this example, two parameters, <code>user</code> and <code>password</code>, are being added to an API call. 
      The value of <code>user</code> is set to "john_doe" and the value of <code>password</code> is set to "password123".
    </p>
  </div>
);

export default Chapter12_3;
