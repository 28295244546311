import React, { Fragment } from 'react';
import './chapter.css'
const Chapter14_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h1>Function Declaration</h1>
      <h2>Introduction</h2>
      <p>
        Functions in AVAP™ are reusable blocks of code that perform a specific task. Just like in Python, functions in AVAP™ allow for code modularization, improved readability, easier maintenance, and code reuse.
      </p>
    </div>
  </Fragment>
);

export default Chapter14_1;