import React from 'react';

interface DrawerProps {
    isOpen: boolean;
    toggleSidebar: () => void;
    name?: string; // Nueva prop opcional `name`
    children: React.ReactNode;
}

const Drawer: React.FC<DrawerProps> = ({ isOpen, toggleSidebar, name, children }) => {
  return (
    <>
      {/* Overlay de fondo oscuro */}
      {isOpen && <div className="overlay" onClick={toggleSidebar}></div>}

      <div className={`drawer overflowX ${isOpen ? 'open' : ''}`}>
        <div className="drawer-header">
          <h2>{name || 'Drawer'}</h2>
          <button className="close-btn" onClick={toggleSidebar}>✕</button>
        </div>
        
        {/* Contenido dinámico */}
        <div className="drawer-content">
          {children}
        </div>
      </div>
    </>
  );
};

export default Drawer;
