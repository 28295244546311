import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>Data Types</h2>
      <p>
        In AVAP™, just like in Python, data types are categories that represent different kinds of values that can be stored and manipulated in a program. Some of the most common data types in AVAP™ include:
      </p>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Integers (int):</strong> Represent whole numbers, positive or negative, without a fractional part.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Floating-point numbers (float):</strong> Represent numbers with both integer and fractional parts.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Strings (str):</strong> Represent sequences of Unicode characters.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Booleans (bool):</strong> Represent truth values, either True or False.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Lists (list):</strong> Ordered and mutable collections of elements.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Tuples (tuple):</strong> Ordered and immutable collections of elements.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Dictionaries (dict):</strong> Unordered collections of key-value pairs.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Sets (set):</strong> Unordered collections of unique elements.</li>
      </ul>
    </div>
  </Fragment>
);
export default Intro_1;
