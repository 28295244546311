import React from 'react';
import './chapter.css'
const Chapter9_6 = () => (
    <div>
        <h2>5. The Import System in AVAP</h2>
        <p>
            AVAP code in one file gains access to code in another file through the import process. The <code>import</code> statement is the only way to invoke the import machinery in AVAP.
        </p>
        <p>
            The <code>import</code> statement inserts the contents of the specified file at the exact point where the import statement appears in the original file. There are no other ways to invoke the import system in AVAP.
        </p>
        <p>
            When an <code>import</code> statement is executed, the contents of the imported file are processed as if they were part of the original file, ensuring that all functions and variables from the imported file are available in the context of the original file. If the specified file is not found, a <code>FileNotFoundError</code> is raised.
        </p>
        <p>Example of using the <code>import</code> statement in AVAP:</p>
        <pre>
            <code>
{`Content of file main.avap 
addVar(10, x) 
import functions.avap 
myFunction(x)

Content of file functions.avap 
def myFunction(y): 
    addVar(y + 5, result) 
    print(result)
`}
            </code>
        </pre>
        <p>
            In this example, the content of <code>functions.avap</code> is inserted into <code>main.avap</code> at the point of the import statement, ensuring that <code>myFunction</code> is defined before being called.
        </p>
    </div>
);

export default Chapter9_6;
