import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h4>2.3.2 Direct Declaration</h4>
  <p>
    Local and global variables can also be declared directly without using the <code>global</code> statement, simply by assigning a value:
  </p>
  <pre><code>variable_name = value</code></pre>
  <p>Where:</p>
  <ul>
    <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>variable_name</strong> is the name of the variable to be declared.</li>
    <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>value</strong> is the initial value to be assigned to the variable.</li>
  </ul>
</div>
  </Fragment>
);
export default Intro_1;
