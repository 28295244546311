import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_10 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>getListLen()</h2>
      <p>
        The <code>getListLen()</code> command calculates the length of a list and stores the result in a target variable. This command is useful for determining the number of elements in a list.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>SourceVariable</strong> <br />
          Type: <code>var</code> <br />
          Description: The variable containing the list whose length you want to calculate. It can be a variable that stores the list or a direct value representing the list.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>TargetVariable</strong> <br />
          Type: <code>var</code> <br />
          Description: The variable where the result of the list length will be stored. This should be a variable that will receive the integer value representing the number of elements in the list.
        </li>
      </ul>
      <h3>Command Flow</h3>
      <ol>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Retrieve the List:</strong> Access the list stored in the <code>SourceVariable</code>.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Calculate the Length:</strong> Calculate the number of elements in the list.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Store the Result:</strong> Save the calculated length in the variable specified by <code>TargetVariable</code>.</li>
      </ol>
      <h3>Usage Example</h3>
      <p>Suppose the list in <code>myList</code> is <code>['apple', 'banana', 'cherry']</code>.</p>
      <pre>
        <code>
          {`// Variable definitions
myList = ['apple', 'banana', 'cherry']
listLength = 0

// Call the command to calculate the length of the list
getListLen(myList, listLength)

// Return the list length through addResult
addResult(listLength)`}
        </code>
      </pre>
      <p>Since the list <code>myList</code> has 3 elements, the <code>getListLen()</code> command will calculate that the length is 3. This value will be stored in the <code>listLength</code> variable and returned through <code>addResult(listLength)</code>, resulting in the following output:</p>
      <pre>
        <code>
          {`3`}
        </code>
      </pre>
    </div>
  </Fragment>
);

export default Appendices_10;
