import React, { Fragment } from 'react';
import './chapter.css'
const Chapter14_2 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>Function Construction</h2>
      <p>
        In AVAP™, similar to Python, functions are defined using the keyword <code>def</code>, followed by the function name and its parameters in parentheses. The function definition ends with a colon (<code>:</code>), followed by the block of code that forms the function body, indented with four spaces.
      </p>
      <h3>Defining a function in AVAP™</h3>
      <pre>
        <code>
          {`def greet(name):
            return "Hello, " + name + "!"`}
        </code>
      </pre>
      <h3>Calling the function</h3>
      <pre>
        <code>
          {`message = greet("World")
            print(message)`}
        </code>
      </pre>
      <h3>Output</h3>
      <pre>
        <code>
          {`Hello, World!`}
        </code>
      </pre>
    </div>
  </Fragment>
);

export default Chapter14_2;
