import React, { useState } from 'react';
import { Form, Button, Table } from 'react-bootstrap';

const AddEditUserForm = () => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        isActive: false,
        isAdmin: false,
        userGroup: '',
        permissions: {
            Analytics: 'deny',
            APIs: 'deny',
            APITemplates: 'deny',
            Certificates: 'deny',
            IdentityManagement: 'deny',
            KeysTokens: 'deny',
            Logs: 'deny',
            OAuth: 'deny',
            Policies: 'deny',
            Portal: 'deny',
            RealTimeNotifications: 'deny',
            SystemLicenses: 'deny',
            Users: 'deny',
            UserGroups: 'deny',
            Webhooks: 'deny',
        },
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handlePermissionChange = (e, permission) => {
        setFormData((prevData) => ({
            ...prevData,
            permissions: { ...prevData.permissions, [permission]: e.target.value },
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("User Data Submitted: ", formData);
        // Aquí puedes añadir la lógica de envío al backend
    };

    const handleCancel = () => {
        // Reinicia los datos del formulario
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            isActive: false,
            isAdmin: false,
            userGroup: '',
            permissions: {
                Analytics: 'deny',
                APIs: 'deny',
                APITemplates: 'deny',
                Certificates: 'deny',
                IdentityManagement: 'deny',
                KeysTokens: 'deny',
                Logs: 'deny',
                OAuth: 'deny',
                Policies: 'deny',
                Portal: 'deny',
                RealTimeNotifications: 'deny',
                SystemLicenses: 'deny',
                Users: 'deny',
                UserGroups: 'deny',
                Webhooks: 'deny',
            },
        });
        console.log("Action cancelled");
    };

    return (
        <Form className="cloudForm" onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
            <h2>Add / Edit User</h2>
            <p className="text-muted">Edit level of access and account details for this user.</p>

            {/* User Details */}
            <Form.Group controlId="firstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                />
            </Form.Group>

            <Form.Group controlId="lastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                />
            </Form.Group>

            <Form.Group controlId="email">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                />
            </Form.Group>

            <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    required
                />
            </Form.Group>

            <Form.Check
                type="checkbox"
                label="Account is active"
                name="isActive"
                checked={formData.isActive}
                onChange={handleChange}
            />

            <Form.Check
                type="checkbox"
                label="Account is admin"
                name="isAdmin"
                checked={formData.isAdmin}
                onChange={handleChange}
            />

            <Form.Group controlId="userGroup">
                <Form.Label>User Group</Form.Label>
                <Form.Control
                    as="select"
                    name="userGroup"
                    value={formData.userGroup}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select a Group</option>
                    <option value="group1">Group 1</option>
                    <option value="group2">Group 2</option>
                    <option value="group3">Group 3</option>
                    {/* Agrega más grupos de usuarios aquí según sea necesario */}
                </Form.Control>
            </Form.Group>

            {/* Permissions Table */}
            <h3>Permissions</h3>
            <Table bordered responsive>
                <thead>
                    <tr>
                        <th>Permission</th>
                        <th>Deny</th>
                        <th>Read</th>
                        <th>Write</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(formData.permissions).map((permission) => (
                        <tr key={permission}>
                            <td>{permission.replace(/([A-Z])/g, ' $1')}</td>
                            <td>
                                <Form.Check
                                    type="radio"
                                    name={permission}
                                    value="deny"
                                    checked={formData.permissions[permission] === 'deny'}
                                    onChange={(e) => handlePermissionChange(e, permission)}
                                />
                            </td>
                            <td>
                                <Form.Check
                                    type="radio"
                                    name={permission}
                                    value="read"
                                    checked={formData.permissions[permission] === 'read'}
                                    onChange={(e) => handlePermissionChange(e, permission)}
                                />
                            </td>
                            <td>
                                <Form.Check
                                    type="radio"
                                    name={permission}
                                    value="write"
                                    checked={formData.permissions[permission] === 'write'}
                                    onChange={(e) => handlePermissionChange(e, permission)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            {/* Fixed Buttons */}
            <div className="d-flex justify-content-between">
                <Button variant="primary" type="submit">
                    Save
                </Button>
                <Button variant="outline-secondary" onClick={handleCancel}>
                    Cancel
                </Button>
            </div>
        </Form>
    );
};

export default AddEditUserForm;
