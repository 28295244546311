import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_20 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>ormAccessUpdate()</h2>
      <p>
        The <code>ormAccessUpdate()</code> command updates records in a database table based on the provided selection criteria. This command modifies the values of specified fields in a database using the corresponding values from variables.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>fields</strong> <br />
          Type: <code>variable</code> <br />
          Description: A string containing the names of the fields to be updated. The field names should be separated by commas.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>fieldsValuesVariables</strong> <br />
          Type: <code>variable</code> <br />
          Description: A string containing the names of the variables holding the new values for the specified fields. The variable names should be separated by commas, in the same order as the fields in <code>fields</code>.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>dbase</strong> <br />
          Type: <code>variable</code> <br />
          Description: The name of the database where the table to be updated is located. It should be a variable containing the name of the database.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>selector</strong> <br />
          Type: <code>variable</code> <br />
          Description: A condition to select the records to be updated. It should be a string specifying the selection criteria in SQL format, such as <code>id = 1</code>.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>varTarget</strong> <br />
          Type: <code>variable</code> <br />
          Description: The variable in which the result of the update operation will be stored. It should be a variable that will receive a value indicating whether the update was successful or not.
        </li>
      </ul>
      <h3>Command Flow</h3>
      <ol>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Define Fields and Values:</strong> Uses the field names (<code>fields</code>) and the variables with the values to be updated (<code>fieldsValuesVariables</code>) to define which records should be modified and with what data.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Select Records:</strong> Uses the condition provided in <code>selector</code> to identify the records to be updated.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Update the Database:</strong> Performs the update in the database specified by <code>dbase</code>, applying the changes to the records that meet the <code>selector</code> condition.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Store the Result:</strong> Saves the result of the update operation in the variable specified by <code>varTarget</code>. The stored value will indicate whether the update was successful (True) or failed (False).</li>
      </ol>
      <h3>Example Usage</h3>
      <p>Suppose you want to update the <code>age</code> field to 31 for the user with <code>id</code> equal to 1 in a database called <code>myDatabase</code>.</p>
      <pre>
        <code>
          {`// Define variables
fields = "age"
fieldsValuesVariables = "newAge"
dbase = "myDatabase"
selector = "id = 1"
updateSuccess = ''

// Define the variable holding the new value
newAge = 31

// Call the command to update the record
ormAccessUpdate(fields, fieldsValuesVariables, dbase, selector, updateSuccess)

// Return the result of the update via addResult
addResult(updateSuccess)`}
        </code>
      </pre>
      <p>In this example, the <code>ormAccessUpdate()</code> command will update the <code>age</code> field in the <code>myDatabase</code> database for the record where <code>id = 1</code>. The new value for <code>age</code> is 31, stored in the <code>newAge</code> variable. The <code>updateSuccess</code> variable will store the result of the operation (whether it was successful or not), and this variable will be returned via <code>addResult(updateSuccess)</code>.</p>
    </div>
  </Fragment>
);

export default Appendices_20;
