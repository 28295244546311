import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h4>1.1.4 Booleans (bool)</h4>
  <p>
    Booleans represent truth or falsehood values. In AVAP™, they are defined using the <code>bool</code> data type.
  </p>
  <pre>
    <code>
{`true_value = True\nfalse_value = False`}
    </code>
  </pre>
</div>
  </Fragment>
);
export default Intro_1;
