import React, { FunctionComponent, useState, useEffect } from 'react'; 
import { Container, Row, Col,  Modal, Button, Form, Spinner, Card, Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEdit } from '@fortawesome/free-solid-svg-icons';
import { prependOnceListener } from 'process';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { parseToCoin } from '../../services/AmountService';
//import ObexProjectsRequestHandler from '../../handlers/ObexProjectsRequestsHandler';
import SessionService from '../../services/SessionsService';
import { message } from 'antd';
import axios from 'axios';


type AddonsProps = {
  show: boolean,
  handleClose: any,
  handleLogo: any,
  nameProject: string,
  planID: string,
  priceMonthly: string,
  priceYear: string,
  logoname: string
}

const ModifyCompanyLogo:FunctionComponent<AddonsProps> = props => {

  const { show, handleClose, handleLogo, nameProject, planID, priceMonthly, priceYear ,logoname} = props;


  const [pricePlanMontly, setPricePlanMontly] = useState(priceMonthly);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [projecstPlanInfo, setProjecstPlanInfo] = useState([]);
  const [requestsPlanInfo, setRequestsPlanInfo] = useState([]);
  const [selectedProject, setProject] = useState(0);
  const [selectedTx, setTx] = useState(0);
  const [projectPlanPriceMonthly, setProjectPriceMonthly] = useState(0);
  const [projectPlanPriceYear, setProjectPriceYear] = useState(0);
  const [txPlanPriceMonthly, setTxPriceMonthly] = useState(0);
  const [txPlanPriceYear, setTxPriceYear] = useState(0);
  const [txDesc, setTxDesc] = useState('');
  const [projectDesc, setProjectDesc] = useState('');
  var [Message, setMessage] = useState(priceYear);
  const [sending, setSending] = useState(false);
  var [Subject, setSubject] = useState(priceMonthly);
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [noUpload, setNoUpload] = useState(true);
  const [Image, setImage] = useState(); 
  const [ImageSelected, setImageSelected] = useState('');

  const [Logoname, setLogoname] = useState(logoname);


  const history = useHistory();

const Cierra = (e) =>{
  setMessage('');
  setSubject('');
  handleClose()
}

  const changeSubject = (e) => {
    const newSubject = e.target.value;
    setSubject(newSubject);
  }


const handleEnteredPhone= (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    try{
      setSubject(value);
    }
    finally{

    }
  }


  const changeMessage = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);
  }


  const uploadFile = async (event) => {
    try {
      event.preventDefault();
      event.stopPropagation();
      //console.log("E "+ event.value);
      //console.log(event.target.files);

    } finally {}
  }

  const handleSubmitMessage = async () => {
    try {

      setSending(true);
      //const result = await ObexProjectsRequestHandler.get(`/ws/users.py/data?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&company_name=${Subject}&save=true`);
    
     // const {status,message} = result
     // if (status){
     //   setSuccess("Datos modificados con éxito.");
     //   setMessage('');
     //   setSubject('');
     // }
      
      setSending(false);
      //handleClose();

    } catch (error) {

      setError(error)
    }
    //SessionService.DatosEmpresa["company_name"] = Subject

   // handleEnName(Subject);
    setTimeout(function() { 
      setSuccess('');
      setError('');

      handleClose();
      }.bind(this), 2000)

  }

  const handleUploaded = async (file) =>{
    try {
      setSending(true);
      //const result = await ObexProjectsRequestHandler.get(`/ws/users.py/data?id_usuario=${SessionService.IdUsuario}&id_sesion=${SessionService.SessionID}&codigo_pais=ES&id_canal=29&url_imagen=https://api.101obex.com/static/${file}&save=true`);
      //console.log(result);
      //handleLogo(`https://api.101obex.com/static/${file}`)
      /*const {status,message} = result
      if (status){
        setSuccess("Datos modificados con éxito.");
        setMessage('');
        setSubject('');
      }
      */


      // AvatarImageHeader


      const output = document.getElementById('AvatarImageHeader') as HTMLImageElement;
      const output2 = document.getElementById('logo_settings') as HTMLImageElement;

      output.src = file;
      output2.src = file;


      setSending(false);
      //handleClose();

    } catch (error) {

      setError(error)
    }
    //SessionService.DatosEmpresa["url_imagen"] = `http://api.101obex.com/static/${file}`

    //handleEnName(Subject);
    setTimeout(function() { 
      setSuccess('');
      setError('');
      
      handleClose();
      //history.push(`/admin`);
      }.bind(this), 2000)

  }

  


  const onChange = (event) => {
    const value = event.target.value;

    // this will return C:\fakepath\somefile.ext

  
    const files = event.target.files;
  
    //this will return an ARRAY of File object



    const input = event.target;

    const reader = new FileReader();
    reader.onload = function(){
      const dataURL = reader.result;
      const output = document.getElementById('output') as HTMLImageElement;
      //document.getElementById('output').setAttribute( 'src',dataURL);
      output.src = dataURL.toString();
      const safedstring = dataURL.toString(); //.replace(/^[^,]+,/, '');//encodeURIComponent(dataURL.toString());
      const base64SafedString = btoa(safedstring);
      //console.log(base64SafedString);
      setImageSelected(base64SafedString);






    };
    reader.readAsDataURL(input.files[files.length-1]);

    //setImage(files[(files.length)-1].name);

    setNoUpload(false);

  }

  async function showname () {
    const name = document.getElementById('exampleFormControlFile1') as HTMLInputElement; 
    const file = name.files[(name.files.length)-1];
    const reader = new FileReader();
    const formdata = new FormData()
    formdata.append('image', file);

    //  const response = axios.post(`http://45.77.187.211:4040/drop_file?file=${name.files[(name.files.length)-1].name}`, formdata, {
    //    headers: {
    //      'Content-Type': 'multipart/form-data'
    //    }
    //  });
    //console.log('---------------------');
    //console.log(ImageSelected);
    const payload = { image: ImageSelected };
    //console.log(payload);
    const result = await ObexRequestHandler.post('/update_avatar', payload);

      //console.log(result);
      handleUploaded(atob(ImageSelected));
      SessionService.imageContent = atob(ImageSelected);
  }

  const envia = (e) => {
    const data = new FormData() 
    data.append('file', e.state.selectedFile)
}


  useEffect(() => {
    //setSubject(priceMonthly);

  }, [])
  
  return(

    

    <Modal style={{ marginTop:'5rem' }} dialogClassName="addons-dialog themeDialog w-75 obex-dialog py-3 obex-dialog"
      show={show} 
      onHide={Cierra}
      animation={false}
      onSubmit={uploadFile}
      >
      <Modal.Header closeButton>
      <h2>Select an image for your avatar</h2>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
        <Form className="pb-4">
          <Form.Group>
            <Row>
              <Col>
            <Form.File id="exampleFormControlFile1" label="" onChange={onChange}/>
            </Col>
            <Col>
            <img id='output' width="100"/>
            </Col>
            </Row>
          </Form.Group>

        </Form>

        </Container>

        <Modal.Footer className="">
          <Row className="pt-4 mx-auto">
            <Col className="mx-auto">
          <Button disabled={noUpload} className="btn-green mx-auto pl-4 pr-4" variant="primary" onClick={showname}>
            Save
          </Button>
          </Col>
          </Row>
          </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};

export default ModifyCompanyLogo;