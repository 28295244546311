import React from 'react';
import './chapter.css'
const Chapter9_2 = () => (
    <div>
        <h2>4.2. Names and Bindings</h2>
        <h3>4.2.1. Name Binding</h3>
        <p>
            Names in AVAP refer to values and are introduced through name binding operations. The following constructs bind names:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Formal parameters of functions.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Function definitions.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Assignment expressions.</li>
        </ul>
        <p>
            Name binding is performed using the <code>addVar(value, variable)</code> function, which assigns the value to the specified variable. There are no class declarations or complex targets in AVAP. Only functions and direct assignments to variables are valid code blocks.
        </p>
    </div>
);

export default Chapter9_2;
