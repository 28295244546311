import React, { FunctionComponent } from 'react'; // we need this to make JSX compile
import { Alert } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faExclamationTriangle, faCheck  } from '@fortawesome/free-solid-svg-icons';
import { types } from 'react-markdown';



type ObexToastProps = {
  type: string,
  msg: string,
  clean?: () => void
};

const ObexToast:FunctionComponent<ObexToastProps> = ({ type, msg, clean }) => {

    /*const errorAlert = <Alert className='obex-alert-danger'>
      <FontAwesomeIcon icon={faExclamationCircle}/>&nbsp;{msg}
    </Alert>

    const successAlert = <Alert className='obex-alert-success'>
      <FontAwesomeIcon icon={faCheck}/>&nbsp;{msg}
    </Alert>   

    const warningAlert = <Alert className='obex-alert-warning'>
      <FontAwesomeIcon icon={faExclamationTriangle}/>&nbsp;{msg}
    </Alert>   
    */

   // Type title references
   const ttr = {
      error: 'Error',
      success: 'Success',
      warning: 'Warning!'
    }

   // Type icon references
   const tir = {
    error: faExclamationCircle,
    success: faCheck,
    warning: faExclamationTriangle
  }

  // Type style references
  const tsr = {
    error: 'obex-alert-danger',
    success: 'obex-alert-success',
    warning: 'obex-alert-warning'
  }
   
    const toast = <div style={{
        position: "fixed",
        bottom: "0",
        right: "0",
        marginBottom: "10px",
        marginRight: "10px",
        minWidth: "280px",
        height: "auto"
      }}> 
        <Toast onClose={clean} show={msg.length > 0} delay={3000} autohide>

          <Toast.Header>
            <FontAwesomeIcon className={tsr[type]} icon={tir[type]}/>&nbsp;
            <strong className="mr-auto">{ttr[type]}</strong>
            {/*<small>11 mins ago</small>*/}
          </Toast.Header>

          <Toast.Body>
            {msg}
          </Toast.Body>

        </Toast>
      </div>

    return toast;
}

export default ObexToast;