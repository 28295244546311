import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const CreateTeamForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        description: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Team Data Submitted:", formData);
        // Aquí puedes añadir la lógica de envío al backend
    };

    const handleCancel = () => {
        // Reinicia el formulario a sus valores iniciales
        setFormData({
            name: '',
            description: '',
        });
    };

    return (
        <Form className="cloudForm" onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
            <h2>Create a Team</h2>
            <p>General information helps identify and manage this Team.</p>

            {/* Name Field */}
            <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter a team name"
                    required
                />
            </Form.Group>

            {/* Description Field */}
            <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                    as="textarea"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Enter some description"
                    rows={3}
                />
            </Form.Group>

            {/* Fixed Buttons */}
            <div className="d-flex justify-content-between mt-4">
                <Button variant="primary" type="submit">Save Team</Button>
                <Button variant="outline-secondary" onClick={handleCancel}>Cancel</Button>
            </div>
        </Form>
    );
};

export default CreateTeamForm;
