import React, { FunctionComponent, useState, useEffect, useRef, Fragment } from 'react';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import ObexAlert from '../../../components/basicComponents/ObexAlerts';
import { getToday, getWeekAgo, dateToString } from '../../../services/TimeService';
import '../../../assets/css/dashboard.css';
import ConsumptionCategoryChart from './ConsumptionCategoryChart';

const baseDataset = {
    fill: true,
    lineTension: 0.3,
    backgroundColor: 'rgba(225, 204,230, .3)',
    borderColor: 'rgb(205, 130, 158)',
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBorderColor: 'rgb(205, 130,1 58)',
    pointBackgroundColor: 'rgb(255, 255, 255)',
    pointBorderWidth: 10,
    pointHoverRadius: 5,
    pointHoverBackgroundColor: 'rgb(0, 0, 0)',
    pointHoverBorderColor: 'rgba(220, 220, 220,1)',
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
}

type chartProps = {
    projects: any,
    title: string
  }

const ConsumptionProjectChart:FunctionComponent<chartProps> = (props) => {

    const { title, projects } = props;

    const aWeekAgo = getWeekAgo();
    const today =  getToday();
    const [chartData, setChartData] = useState({});
    const [from, setFrom] = useState(aWeekAgo);
    const [to, setTo] = useState(today);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [optionType, setOptionType] = useState("Requests");
    const [timeSelected, setTimeSelected] = useState("Day");

    const [textTitle, setTextTitle] = useState("Projects");
    const [projectSelected, setProjectSelected] = useState(title);

    const optionsProjects = projects.map(project => <option value={project.name}>{project.description}</option>);

    const handleProject = (e) => {
        setTextTitle(e.target.value)
        setProjectSelected(e.target.value);
      }

    const [maxValue, setMaxValue] = useState(10);

    const getData = async () => {
        try {
            setError('');
            setLoading(true);
            var projectos = await ObexRequestHandler.get('/projects');
            if (projectos.data.length>0)  {
            const params = { from, to };
            const result = await ObexRequestHandler.get('/movements_between', params);
            const { success, data, message } = result;
            if (!success) {
                setError(message);
            } else {
                const { movements, dateRange } = data;
                const labels = movements.map(e => e.date);
                const values = movements.map(e => e.count);
                const chartData = { 
                    labels: labels,
                    datasets: []
                 };
                setMaxValue(Math.max.apply(null, values));
                chartData.datasets.push(Object.assign(baseDataset, { label: 'Request consumption', data: values }));
                setChartData(chartData);
            }
          }
        } catch (error) {
            setError(error.message || 'Error while fetching data');
        }
        setLoading(false);
    }

    const options = {
      title:{
        display:false,
      },
      legend:{
        display:false,
        position:'right'
      },
      scales: {
        yAxes: [{
          ticks: {
            min: 0,
            max: maxValue,
            stepSize: 1
          }
        }]
      },
      responsive: true
    };

    const handleFrom = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setFrom(e.target.value); 
    }

    const handleTo = (e) => {
        e.stopPropagation();
        e.preventDefault();      
        setTo(e.target.value);
    }

    const handleOptionType = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setOptionType(e.target.value);
    }

    const handleFilterSelected = (e) => {
      e.preventDefault();
      e.stopPropagation();
      var date = new Date;
      const dateTo = dateToString(date);
      setTo(dateTo);
      const selected = e.target.value;
      setTimeSelected(selected);
      if (selected == "day"){
        date.setDate(date.getDate() - 1);
      }
      if (selected == "week"){
        date.setDate(date.getDate() - 7);
      }
      if (selected == "month"){
        date.setMonth(date.getMonth() - 1);
      }
      if (selected == "quarter"){
        date.setDate(date.getDate() - 90);
      }
      const dateFrom = dateToString(date);
      setFrom(dateFrom);
    }

    useEffect(() => {
      getData();
    }, [from, to]);


    const handleUpdateData = async (e) => {
      e.preventDefault();
      e.stopPropagation();
      await getData();
    }

    useEffect(() => {
      getData();
    }, []);

    return (
      <Fragment>
        <div className='graphWrapper'>
        <Form className="obex-form stats-grafic-form">
          <Row>
            <Col>
              <Form.Row className="mb-3">


              {/*}  
              <Col md="2" className="ml-auto">
              {/*}
            <Form.Group>
              <Form.Control as="select" custom onChange={handleProject}>
                {optionsProjects}
              </Form.Control>
            </Form.Group>
            */}
   {/*}       </Col>

*/}


                <Col>
                  <Form.Group>
                    <Form.Control as="select" custom onChange={handleOptionType}>
                      <option value="Request">Requests</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Control onChange={handleFilterSelected} as="select" custom>
                      <option value="day">Day</option>
                      <option value="minute">Minute</option>
                      <option value="week">Week</option>
                      <option value="month">Month</option>
                      <option value="quarter">Quarter</option>
                      {/* <option>Year</option> */}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  {/*<Form.Control type="date" value={from} onChange={handleFrom}/>*/}
                </Col>
                <Col>
                  {/*<Form.Control type="date" value={to} onChange={handleTo}/>*/}
                </Col>
                
                {/*}
                <Col>

                
                  <Button style={{paddingLeft:'0px', paddingRight:'0px'}} type="submit" className="custom-button-blue textBold btn-block" onClick={handleUpdateData}>
                    Update
                    {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/>}
                  </Button>
                </Col>
                */}
              </Form.Row>
            </Col>
          </Row>
        </Form>  
        <Row>
          <Col>
            <ObexAlert type='error' msg={error}/>
          </Col>
        </Row>
        <Row>
          <Col md="11" className="p-1">
            <Line data={chartData} options={options} height={13} width={40}/>
          </Col>
        </Row>
        {/*}
        <Row className="my-2">
          <Col md="12"className="text-center">
            <h4>{optionType} per {timeSelected}</h4>
          </Col>
        </Row>
        */}

        </div>
        


      </Fragment>
    );

}

export default ConsumptionProjectChart;