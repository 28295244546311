import React from 'react';

// Iconos importados
import AVAPIcon from '../../../assets/img/logos/icon_avap.svg';
import AVAPIDIcon from '../../../assets/img/logos/icono_ID.svg';
import AVAPSpecsIcon from '../../../assets/img/logos/icon_specs.svg';
import AVAPPublisherIcon from '../../../assets/img/logos/icono_publisher.svg';
import AVAPMarketplaceIcon from '../../../assets/img/logos/icon_marketplace.svg';
import AVAPCommanderIcon from '../../../assets/img/logos/icon_commander.svg';
import AVAPDeveloperPortalIcon from '../../../assets/img/logos/icono_DS.svg';
import AVAPManagementConsoleIcon from '../../../assets/img/logos/icon_APIControVersion.svg';
import AVAPCollaborativeNetworkIcon from '../../../assets/img/logos/icono_CollaborativeN.svg';


//ui icons
 import create from   '../../../assets/img/ui_icons/icono-gettingStarter.svg';
 import download from   '../../../assets/img/ui_icons/icono-gettingStarter_4.svg';
 import devToken from   '../../../assets/img/ui_icons/icono-gettingStarter_3.svg';
 import firstApi from   '../../../assets/img/ui_icons/icono-gettingStarter_2.svg';
 import devPortal from   '../../../assets/img/ui_icons/icono-gettingStarter_1.svg';


// Configuración de los íconos
const defaultWorkspaceItems = [
    { name: 'AVAP', icon: AVAPIcon, url: '/avap' },
    { name: 'AVAP ID', icon: AVAPIDIcon, url: '/avap-id' },
    { name: 'AVAP Specs', icon: AVAPSpecsIcon, url: '/avap-specs' },
    { name: 'AVAP Publisher', icon: AVAPPublisherIcon, url: '/avap-publisher' },
    { name: 'AVAP Marketplace', icon: AVAPMarketplaceIcon, url: '/avap-marketplace' },
    { name: 'AVAP Commander', icon: AVAPCommanderIcon, url: '/avap-commander' },
    { name: 'AVAP Developer Portal', icon: AVAPDeveloperPortalIcon, url: '/avap-developer-portal' },
    { name: 'AVAP Management Console', icon: AVAPManagementConsoleIcon, url: '/avap-management-console' },
    { name: 'AVAP Collaborative Network', icon: AVAPCollaborativeNetworkIcon, url: '/avap-collaborative-network' },
];
const WorkspaceMenu = ({ show, handleClose, workspaceItems = defaultWorkspaceItems }) => (
    <div className={`workspace-menu-dropdown ${show ? 'show' : ''}`}>
        <div className="workspace-menu-header">
            <h5>AVAP WORKSPACE</h5>
            {/* <button onClick={handleClose} className="close-button">✕</button> */}
        </div>
        <div className="workspace-menu-body">
            <div className="workspace-icons">
                {/* Mapear la configuración de los íconos */}
                {workspaceItems.map((item, index) => (
                    <a key={index} href={item.url} className="workspace-item">
                        <img src={item.icon} alt={item.name} className="workspace-icon" />
                        <p>{item.name}</p>
                    </a>
                ))}
            </div>
            <div style={{
                    paddingTop:'20px',
                    marginBottom: '-20px',
                    paddingBottom: '20px',
                    borderBottomLeftRadius: '10px',
                    borderBottomRightRadius: '10px'

            }} className="workspace-linksContainer mt-3">
                <div className="subtitle">Getting Started</div>
                <div className="workspace-links">
                    <a href="#"> <img src={create} alt="" className="subIcon" />Create your AVAP ID</a>
                    <a href="#"> <img src={download} alt="" className="subIcon" />Download basic software</a>
                    <a href="#"><img src={devToken} alt="" className="subIcon" />Create your Developer Token</a>
                    <a href="#"><img src={firstApi} className="subIcon" />Create your first API Project</a>
                    <a href="#" className="developer-portal-link">Developer Portal <img src={devPortal} alt="" className="subIcon" /></a>
                </div>
            </div>
        </div>
    </div>
);

export default WorkspaceMenu;
