import React from 'react';
import './chapter.css'
const Chapter9_22 = () => (
    <div>
        <h2>Implementation Details in AVAP</h2>
        <p>
            In AVAP, variables are stored as strings, and lists and objects are managed using specific commands:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <strong>Lists</strong>: To generate a list, use <code>variableToList(variable, list)</code>. To retrieve an item from the list, use <code>itemFromList(list, index, variable_to_store_item)</code>. To get the number of items in the list, use <code>getListLen(list, var_to_store_list_length)</code>.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <strong>Objects (dictionaries)</strong>: An object is created with <code>AddvariableToJSON(key, value, object_variable)</code>. To retrieve a key from the object, use <code>variableFromJSON(object_variable, key, var_to_store_key_value)</code>.
            </li>
        </ul>
    </div>
);

export default Chapter9_22;
