import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h3>1.2 Advantages of AVAP™ as a Dynamic Language</h3>
      <p>As a dynamic programming language, AVAP™ offers several advantages, including:</p>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Greater flexibility and adaptability in program development.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Faster writing and execution of code.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Facilitates experimentation and exploration of solutions.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Allows for rapid feedback during development.</li>
      </ul>
    </div>
  </Fragment>
);
export default Intro_1;
