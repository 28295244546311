import React, { Fragment } from 'react';
import './chapter.css'
const Chapter10_4 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>6.3 Result</h2>
      <p>
        The result returned by the API after executing the above code is as follows:
      </p>
      <pre>
        <code>
            {`{ 
            status
            , elapsed:0.008270740509033203, result: { result:1 } 
            }`}
        </code>
      </pre>
      <p>
        This result indicates that the execution was successful (<code>status:true</code>) and that the value of <code>result</code> is 1.
      </p>
    </div>
  </Fragment>
);

export default Chapter10_4;