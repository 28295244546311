import React, { Fragment } from 'react';

const Collaborative_Network = () => (
  <Fragment>
     <div>
    <h2>Getting Started with AVAP Collaborative Network</h2>
    
    <h3>A Global Peer-to-Peer API Network</h3>
    <p>
      AVAP Collaborative Network (<a href="http://www.avapcollaborativenetwork.com/">www.avapcollaborativenetwork.com</a>) is a revolutionary peer-to-peer network that enables users worldwide to contribute their AVAP Virtual Servers (AVS) and AVAP Interpreter & Language Servers to a global network. This network enhances routing, computation, and processing power, enriching the innovative international API development and publishing infrastructure provided by AVAP Collaborative Network.
    </p>

    <h3>Joining the Network</h3>
    <p>To integrate your servers into the AVAP Collaborative Network, follow these steps:</p>
    <ol>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
        <strong>Download the Software:</strong> Obtain the necessary software for AVAP Virtual Server and AVAP Interpreter & Language Server from the AVAP ID portal.
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
        <strong>Install the Software:</strong> Install the downloaded software on your servers.
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
        <strong>Set Up Peer-to-Peer Mode:</strong>
        <p>Use the following commands to launch your servers in peer-to-peer mode:</p>
        <pre>
          avs –serve -p2p -l credentials_file.key<br />
          avap –serve -p2p -l credentials_file.key
        </pre>
        <p>These commands will initiate your servers in the network, making them available for peer-to-peer interactions.</p>
      </li>
    </ol>

    <h3>Configuring Your Servers</h3>
    <p>
      <strong>Environment Configuration:</strong> AVAP Collaborative Network operates with three pre-configured environments:
    </p>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Local</strong></li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Test</strong></li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Live</strong></li>
    </ul>
    <p>
      You can choose the environment that suits your needs. Each environment uses default ports, which can be customized using the -p flag:
    </p>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>AVAP Virtual Server Ports:</strong>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Local: 80</li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Test: 81</li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Live: 82</li>
        </ul>
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>AVAP Interpreter & Language Server Ports:</strong>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Local: 9000</li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Test: 9001</li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Live: 9002</li>
        </ul>
      </li>
    </ul>
    <p>To change the ports, use the command:</p>
    <pre>
      avs –serve -p2p -p #port -l credentials_file.key<br />
      avap –serve -p2p -p #port -l credentials_file.key
    </pre>

    <h3>Important Considerations</h3>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
        <strong>Port Configuration:</strong> Ensure that the default or custom ports are open in your router, firewall, and any other security devices. Consult your router and firewall documentation for instructions on opening ports.
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
        <strong>VPN Usage:</strong> Using a VPN can impact service performance, cause malfunction, or block your servers from the AVAP Collaborative Network. If you must use a VPN, ensure that the public IP assigned by the VPN provider matches the public IP of your router. To configure this, use the following command:
        <pre>
          EXPORT EXTERNAL_IP=your_router_public_IP
        </pre>
        <p>This command sets the VPN's public IP as the outgoing IP and your router's IP as the incoming IP, ensuring your servers are accessible through the peer-to-peer network.</p>
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
        <strong>Geolocation and Mapping:</strong> The geolocation system will map your server's location based on the IP address provided by the VPN. This location will be displayed on the AVAP Collaborative Network dashboard.
      </li>
    </ul>

    <h3>Monitoring and Contribution</h3>
    <p>
      <strong>Server Availability:</strong> Check the availability and status of AVAP Virtual Servers and AVAP Interpreter & Language Servers in different environments at <a href="http://www.avapcollaborativenetwork.com/">www.avapcollaborativenetwork.com</a>. You can also view your server on the map and contribute your resources to expand the network's capacity and provide service power to the global user community.
    </p>

    <h3>Additional Resources</h3>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>For more information about AVAP Collaborative Network, visit AVAP Alexandria at <a href="https://alexandria.avapframework.com/">alexandria.avapframework.com</a>.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Join the AVAP Communities at <a href="https://communities.avapframework.com/">communities.avapframework.com</a> to connect with other users and stay updated.</li>
    </ul>
  </div>
  </Fragment>
);
export default Collaborative_Network;
