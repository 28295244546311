import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';


const Agregar_usuarios_a_la_comunidad_de_un_afiliado_EN = () => 
  {
    const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{
        "status":"<boolean>",
        "codtran":"<string>",
        "elapsed":"<float>"
     }`,
      copied: false
    },
    answe_ok2: {
      title:"Answer ko",
      type: "json",
      code: `{ 
        "status": true, 
        "level": <string>, 
        "data": { 
          "fecha_nacimiento": <string>, 
          "e_mail": <string>, 
          "gender": <integer>, 
          "surname2": <string>, 
          "surname1": <string>, 
          "name": <string>, 
          "phone": <string> }, 
          "message": <string>, 
          "error": <string> 
        }`,
      copied: false
    }
  };
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: ` import requests url = "URL_BASE/ws/ws_soluciones.py/gestorsoluciones?country_code=MX&user_id=2&session_id=439-InjyJc39dwPumPisHS5xgw==&command=list&solution_type=3&opened=True&closed=True" 
      payload = {} 
      files = {} 
      headers= {} 
      response = requests.request("GET", url, headers=headers, data = payload, files = files) 
      print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: ` import requests url = "URL_BASE/ws/ws_soluciones.py/gestorsoluciones?country_code=MX&user_id=2&session_id=439-InjyJc39dwPumPisHS5xgw==&command=list&solution_type=3&opened=True&closed=True" 
      payload = {} 
      files = {} 
      headers= {} 
      response = requests.request("GET", url, headers=headers, data = payload, files = files) 
      print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: ` var request = require('request');
    var options = {
    'method': 'GET',
    'url': 'URL_BASE/ws/ws_soluciones.py/gestorsoluciones?country_code=MX&user_id=2&session_id=439-InjyJc39dwPumPisHS5xgw==&command=list&solution_type=3&opened=True&closed=True',
    'headers': {},
    formData: {}
    };
    request(options, function (error, response) { 
    if (error) throw new Error(error);
    console.log(response.body);
    });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: ` var formdata = new FormData();
    var requestOptions = {
    method: 'GET',
    body: formdata,
    redirect: 'follow'
    };
    fetch("URL_BASE/ws/ws_soluciones.py/gestorsoluciones?country_code=MX&user_id=2&session_id=439-InjyJc39dwPumPisHS5xgw==&command=list&solution_type=3&opened=True&closed=True", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request GET 'URL_BASE/ws/ws_soluciones.py/gestorsoluciones?country_code=MX&user_id=2&session_id=439-InjyJc39dwPumPisHS5xgw==&command=list&solution_type=3&opened=True&closed=True'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 >
        Add Users to an Affiliate's Community
      </h1>
        <p>This service is used to add a user to the community of an affiliate.</p>
        <p>
          <span className="label post">POST:</span>
          <span> </span> <code>URL_BASE + /ws/comunidad.py/altacomunidad</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <div className='overflow-auto'>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  ISO 3166 alpha-2
                </a>
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Identifier of the user making the request &#40;Admin or
                Affiliate&#41;
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">phone</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                Phone number of the user we want to add to the affiliate community
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">create</td>
              <td align="center">Boolean</td>
              <td align="center">Yes</td>
              <td align="center">
                Register the user in the community, or perform a validation of the
                indicated user
              </td>
              <td align="center">N/A</td>
            </tr>
          </tbody>
        </table>
        </div>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>codtran:</code> Transaction code that identifies the executed
            operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok2}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>level:</code> Error importance level.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>data:</code> An object that contains user data that can be
            modified on this endpoint when calling with the create parameter{' '}
            <code>true</code>.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code> Error message.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          This service allows adding a user to the Affiliate&#39;s community. In
          order for a user to register through this endpoint it is necessary that
          the affiliate who executes it has the attribute:{' '}
          <code>profile_id_by_default</code> with the value of the profile being
          that of the user&#39;s of the affiliate registerer. If the registration is
          executed, a temporary pin will be sent to the user via SMS, and when
          trying to login with that user and pin, an error will be returned
          indicating that the password must be modified. If the <code>create</code>{' '}
          parameter is sent with the value <code>false</code>, a validation of the
          user&#39;s status will occur in which it will indicate if it exists, if it
          already belongs to the affiliate&#39;s community, or if it does not exist
          and should be given as registered. All of this can be executed from the
          same endpoint and is only accessible to Affiliate users and
          Administrators.
        </p>
        </div>

      <div className='col-md-5'>
        <h2 >Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        </div>
        </div>
        
      </Fragment>
      
    );
  }
  
export default Agregar_usuarios_a_la_comunidad_de_un_afiliado_EN;
