import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Image, Form, Button, InputGroup, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useHistory, useParams, Link } from 'react-router-dom';

import EmailIcon from '../../assets/img/emailiconlogin.png';
import Logo from '../../assets/img/newAvapCloud/logo_Cloud@2x.png';

// Simulación de "base de datos" (JSON)
import portalData from './mockData.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { check } from 'prettier';
import Cookies from 'universal-cookie';

const CloudLoginForm = () => {
  const { id } = useParams(); // ID dinámico del portal
  const [portal, setPortal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const history = useHistory();
  const cookies = new Cookies();

  const checkLogin = async (e)=>{
    e.preventDefault();
    e.stopPropagation();

    try{
      const payload = {
        username: email, 
        password: password, 
        dev_portal_id: portal.portal_id
      };
      const login = await ObexRequestHandler.post('/publishing/dev_portal_login', payload);

      console.log(login);

      if (login.status !== 401){

        cookies.set('user', JSON.stringify({ username:email, user_id:login.data.id }), { path: `/devportal/${id}`, expires: new Date(Date.now() + (10 * 60 * 1000)) }); 
        
        console.log("PORTAL");

        console.log(portal);
        console.log("-.-.-.-.");
        cookies.set('portal', JSON.stringify(portal), { path: `/devportal/${id}`, expires: new Date(Date.now() + (10 * 60 * 1000)) }); 

        console.log({ username:email, user_id:login.data.id })
        history.push(`/devportal/${id}/portal`)
      } else {

        setError('Login failed');
        const timeout = setTimeout(() => {
          setError('')
        }, 3000);

      }

    }catch(error){
      console.log(error)
    }

  }

  const DevPortalExists = async ()=>{

    try{
    const res = await ObexRequestHandler.get(`/publishing/get_dev_portal_config/${id}`, {}, true);

    const { data } = res;
    if (data.id!=undefined) {

      setPortal(data)
      console.log('----------------x------x----x--x--x-xx')
      console.log(data);
      console.log('----------------x------x----x--x--x-xx')
      
    }
    

    console.log('SETEANDO COOKIES')

    cookies.remove('portal', { path: `/devportal/${id}` });
    cookies.remove('user', { path: `/devportal/${id}` });
    console.log(data)
    cookies.set('portal', JSON.stringify(data), { path: `/devportal/${id}`, expires: new Date(Date.now() + (10 * 60 * 1000)) }); 
    cookies.set('portal', JSON.stringify({ id: data.id, portal_id: data.portal_id, theme: data.theme, name: data.name }), { path: `/devportal/${id}`, expires: new Date(Date.now() + (10 * 60 * 1000)) }); 
    cookies.set('logo', JSON.stringify({ logo:data.logo }), { path: `/devportal/${id}`, expires: new Date(Date.now() + (10 * 60 * 1000)) }); 

    if (data.visibility === false) {

      cookies.set('user', JSON.stringify({ username:'guest', user_id:0 }), { path: `/devportal/${id}`, expires: new Date(Date.now() + (10 * 60 * 1000)) }); 
        
      cookies.set('portal', JSON.stringify(data), { path: `/devportal/${id}`, expires: new Date(Date.now() + (10 * 60 * 1000)) }); 

      
      history.push(`/devportal/${id}/portal`)

    }

    } catch (error) {

      console.log(error)
    }
  }


  useEffect(() => {
    // Simula la carga de datos desde el JSON

    const matchedPortal = ''; //portalData.portals.find((p) => p.id === id);
    console.log(matchedPortal);
    if (matchedPortal) {
      setPortal(matchedPortal);
    } else {
      setPortal('NOT_FOUND');
    }
    setLoading(false);


    DevPortalExists();


  }, [id]);

  // Si todavía está cargando, no muestra nada (ya no queremos "Loading...")
  if (loading) {
    return null;
  }

  // Si no se encontró el portal, mostrar mensaje "Portal no encontrado"
  if (portal === 'NOT_FOUND') {
    return <div>Portal no encontrado</div>;
  }

  return (
    <div
      className="text-center d-flex flex-column containerLogin"
      style={{ backgroundColor: portal.theme.backgroundColor }}
    >
      <Container className="vh-100 d-flex flex-column vw-100">
        <Row className="pt-5 rowLogin" style={{ minHeight: '100vh' }}> {/* 74vh */}
          <div className="loginForm">
            <Row className="justify-content-center" style={{ marginTop: '7rem' }}>
              <Col>
                {/* Logotipo del portal */}
                <Image src={portal.logo} fluid width="300px" style={{ marginBottom: '60px' }} />
                <h6
                  className="text-boldLogin"
                  style={{ color: portal.theme.textColor }}
                >
                  Sign in to {portal.name} Dev Portal.
                </h6>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form className="obex-form theForm">
                  {/* Campo Email */}
                  <Form.Group controlId="formBasicEmail">
                    <InputGroup className="mb-3">
                      <Form.Control
                        required
                        type="email"
                        placeholder="Email"
                        className="borderRadiusLogin"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{
                          borderColor: portal.theme.primaryColor,
                          color: portal.theme.textColor
                        }}
                      />
                    </InputGroup>
                  </Form.Group>

                  {/* Campo Password */}
                  <Form.Group controlId="formBasicPassword">
                    <InputGroup className="">
                      <Form.Control
                        required
                        type={showPassword ? 'text' : 'password'}
                        placeholder="Password"
                        className="borderRadiusLeftLogin"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{
                          borderColor: portal.theme.primaryColor,
                          color: portal.theme.textColor
                        }}
                      />
                      <InputGroup.Append>
                        <Button
                          className="btnNew"
                          onClick={() => setShowPassword(!showPassword)}
                          style={{
                            background: "white",
                            borderLeft: "0px",
                            borderRight: "none",
                            borderTop: `1px solid ${portal.theme.primaryColor}`,
                            borderBottom: `1px solid ${portal.theme.primaryColor}`,
                            width: '40px'
                          }}
                        >
                          {showPassword ?
                            <FontAwesomeIcon icon={faEyeSlash} style={{ color: "#AFAFAF" }} /> :
                            <FontAwesomeIcon icon={faEye} style={{ color: "#AFAFAF" }} />}
                        </Button>
                      </InputGroup.Append>
                      <button
                        type="submit"
                        className="borderRadiusRightLogin btnNew btn-block"
                        onClick={checkLogin} 
                        style={{ width: '40px', backgroundColor: "white", borderLeft: `1px solid ${portal.theme.primaryColor}` }}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill='gray' width="15px" height="15px" viewBox="0 0 448 512">
                          <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 
                          0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 
                          0-32 14.3-32 32s14.3 32 32 32l306.7 
                          0L233.4 393.4c-12.5 12.5-12.5 32.8 
                          0 45.3s32.8 12.5 45.3 0l160-160z"/>
                        </svg>
                      </button>
                    </InputGroup>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
<Row>
{error &&
<Col>
<Alert variant='danger'>{error}</Alert>
</Col>

}

</Row>
            {/* Sección Remember me y links */}
            <Row>
              <Col style={{ color: portal.theme.textColor, fontSize: "14px", marginBottom: "7rem" }}>
                <div className="d-flex align-items-center flex-column mt-4">
                  <div className="pb-2">
                    <div className="containerPri">
                      <div className="checkbox-container1 pr-2">
                        <input type="checkbox" />
                      </div>
                      <div>
                        <div
                          style={{ marginTop: "2px", color: portal.theme.textColor }}
                          className="theblack"
                        >
                          Remember me
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="cursor-pointer"
                    style={{ color: portal.theme.secondaryColor }}
                  >
                    Forgotten your password?
                  </div>
                  {/*}
                  <a href="#create-id" style={{ color: portal.theme.secondaryColor }}>
                    Create {portal.name} ID
                  </a>*/}
                </div>
              </Col>
            </Row>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default CloudLoginForm;
