import React, { Fragment } from 'react';
import './chapter.css'
const Chapter14_4 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>Practical Example</h2>
      <p>Below is a practical example illustrating the definition and invocation of a function in AVAP™:</p>
      
      <h3>Definition of a Function to Calculate the Area of a Circle</h3>
      <pre>
        <code>
          {`def calculate_circle_area(radius):
    return 3.14 * radius ** 2`}
        </code>
      </pre>
      
      <h3>Calling the Function</h3>
      <pre>
        <code>
          {`circle_radius = 5
area = calculate_circle_area(circle_radius)
print("The area of the circle is:", area)`}
        </code>
      </pre>
      
      <h3>Output:</h3>
      <pre>
        <code>
          {`The area of the circle is: 78.5`}
        </code>
      </pre>
    </div>
  </Fragment>
);

export default Chapter14_4;
