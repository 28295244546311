import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
          <div>
      <h4>1.1.2 Automatic Memory Management</h4>
      <p>
        AVAP™ uses an automatic garbage collector to manage memory dynamically. This means that developers do not have to worry 
        about manually allocating and freeing memory, which simplifies the development process and reduces the likelihood of 
        memory management-related errors.
      </p>
      <pre>
        <code>
          {`# Example of automatic memory management:
list = [1, 2, 3, 4, 5]
# There is no need to free the memory of the list after use`}
        </code>
      </pre>
    </div>
  </Fragment>
);
export default Intro_1;
