import React, { FunctionComponent, useState, useEffect } from 'react'; 
import { Row, Col, Card, Button, Image } from 'react-bootstrap';
import AddonsModal from './AddonModal';
import iconoItem from '../../assets/img/piezal.png';
import { useHistory } from 'react-router-dom';

type PlanCardProps = {
  data: any,
  planSelected?: number,
  activated: boolean,
  upgrade: boolean
};


const PlanCard:FunctionComponent<PlanCardProps> = (props: PlanCardProps) => {

  const activated = props.activated
  const upgrade = props.upgrade
  const [loadplan, setPlan] = useState(0)

  const [dissolve, setDisolve] = useState(10);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [pricePlan, setPrice] = useState('');
  const [description, setDescription] = useState('');
  const [footer, setFooter] = useState('');
  const [estilo, setEstilo] = useState('text-center planCard');
  const [botonEstilo, setBotonEstilo] = useState({});
  const [botonTipo, setBotonTipo] = useState('plans_button text-center');
  const history = useHistory();
  const handleModal = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setPlan(parseInt(event.target.value));
    if (event.target.value>3) history.push('https://www.101obex.com/#comp-jxad0a3m');
    else handleShow();
  };

  const addPrice = () => {
    if (props.data.monthly_price == 0){
      setPrice('Free');
    }else if(props.data.monthly_price == null){
      setPrice('Contact us');
    }else{
      setPrice('$'+props.data.monthly_price);
    }
  }

  useEffect(() => {
    if (props.planSelected==props.data.id){
      setPlan(props.planSelected);

      handleShow();
    }
    if (props.data.name==='Test') {setDescription('The test plan is the perfect place to start designing, developing, and testing APIs.');
        setFooter('');
        setDisolve(8);
  }
    if (props.data.name=='Startup') {setDescription('Startup plan is the best way to start your first project and streamline your developer team.');
    setDisolve(7);
  }
    if (props.data.name=='Business') {setDescription('Take full advantage of the power of 101OBeX and get the most out of the platform.');
    setDisolve(2);
  }
    if (props.data.name=='Enterprise') {setDescription('Access special functions and configure 101OBeX based on your needs.');
    setDisolve(2);
    
  }

  if (activated) {
    if (upgrade) setEstilo('text-center planCardUpdate'); else setEstilo('text-center planCard');
    setBotonEstilo({});        
    setBotonTipo('plans_button text-center')     
} else {
  if (upgrade) setEstilo('text-center planCardUpdate inactive'); else setEstilo('text-center planCard inactive');
  setBotonEstilo({ backgroundColor: '#474747', borderColor: '#474747' })
  setBotonTipo('plans_button_deactivated text-center');
}

    addPrice();
  }, []);

  return(
    
    <Card className={estilo}>
      

      {(loadplan > 0 && activated) && 
      <AddonsModal 
        show={show}
        handleClose={handleClose}
        nameProject={props.data.name}
        planID={loadplan}
        priceMonthly={props.data.monthly_price}
        priceYear={props.data.annual_price}
        upgrade={upgrade}
        cloud
      />}
      <Card.Body className="px-2 d-flex flex-column" >
        <Card.Title style={{ color:'#7abcb0' }}> 
          {props.data.name}
        </Card.Title>
        <Card.Text style={{ height:'15vh' }}>
          <span style={{ fontSize:'0.9em' }}>{description}</span>
        </Card.Text>
        <Card.Text  className={'plan-price align-items-center d-flex justify-content-center' +
          (props.data.monthly_price == null? ' contact-sales': '')} >
            {(props.data.monthly_price!=null && props.data.monthly_price!=0) && 
            <div style={{ fontSize:'23px', maxWidth:'25px', textAlign:'initial', marginTop:'4px', fontFamily: 'inherit'}}>$</div>}
          {pricePlan.replace('$', '')}
          {(props.data.monthly_price!=null && props.data.monthly_price!=0) && 
          <div style={{ fontSize:'8px', maxWidth:'25px', textAlign:'initial', marginTop:'4px', fontFamily: 'inherit' }}>user month</div>}
        </Card.Text>
        <Card.Text className="plan-descrip-request mt-auto">
          {footer}
          <Row style={{ marginLeft:'20px', fontFamily:'Arial' }}><b>Features:</b></Row>
          <Row style={{}}>
            <Col style={{ maxWidth:'20px' }}>
            <Image style={{ width:'15px', height:'15px', marginTop:'4px' }} src={iconoItem}/>
            </Col>
            <Col style={{ textAlign:'start' }}>
            1 active project slot of 1 project max.
            </Col>
          </Row>
          <Row style={{}}>
          <Col style={{ maxWidth:'20px' }}>
              <Image style={{ width:'15px', height:'15px', marginTop:'1px' }}src={iconoItem}/>
          </Col>
          <Col style={{ textAlign:'start' }}>
              100 requests of 3.100 max.</Col></Row>
          
          <Row style={{}}>
          <Col style={{ maxWidth:'20px' }}>
              <Image style={{ width:'15px', height:'15px', marginTop:'1px' }}src={iconoItem}/>
          </Col>
          <Col style={{ textAlign:'start' }}>
              25Mb storage of 25Mb max.</Col></Row>
          <Row style={{}}>
          <Col style={{ maxWidth:'20px' }}>
              <Image style={{ width:'15px', height:'15px', marginTop:'1px' }}src={iconoItem}/>
          </Col>
          <Col style={{ textAlign:'start' }}>
              Sandbox.</Col></Row>
          <Row style={{}}>
          <Col style={{ maxWidth:'20px' }}>
              <Image style={{ width:'15px', height:'15px', marginTop:'1px' }}src={iconoItem}/>
          </Col>
          
          { dissolve == 8 ?
          <Col className='plan-descrip inactive' style={{ textAlign:'start', opacity:'0.3' }}>
              Encrypt your projects.
          </Col>
          :
          <Col style={{ textAlign:'start' }}>
          Encrypt your projects.
      </Col>
}
          
          
          </Row>
          <Row style={{}}>
          <Col style={{ maxWidth:'20px' }}>
              <Image style={{ width:'15px', height:'15px', marginTop:'1px' }}src={iconoItem}/>
              </Col>
          
              {dissolve==8 ?
          <Col className='plan-descrip inactive' style={{ textAlign:'start', opacity:'0.3' }}>
              Access to Professional support.
          </Col>:
                    <Col style={{ textAlign:'start' }}>
                    Access to Professional support.
                </Col>
}
          
          </Row>
          <Row style={{}}>
          <Col style={{ maxWidth:'20px' }}>
              <Image style={{ width:'15px', height:'15px', marginTop:'1px' }}src={iconoItem}/>
              </Col>

              {(dissolve == 8 || dissolve == 7) ?
          <Col className='plan-descrip inactive' style={{ textAlign:'start', opacity:'0.3' }}>
              Work with staging projects.
          </Col>:
          <Col style={{ textAlign:'start' }}>
          Work with staging projects.
      </Col>
              }
          
          </Row>
          <Row style={{}}>
          <Col style={{ maxWidth:'20px' }}>
              <Image style={{ width:'15px', height:'15px', marginTop:'1px' }}src={iconoItem}/>
              </Col>
              {(dissolve == 8 || dissolve == 7 || dissolve == 6) ?
          <Col className='plan-descrip inactive' style={{ textAlign:'start', opacity:'0.3' }}>
              Work with your own database.
          </Col>
          :
          <Col style={{ textAlign:'start' }}>
              Work with your own database.
          </Col>
              }
          
          </Row>
        </Card.Text>
        <div className="text-center">
          {(props.data.monthly_price==null) ?
          <Button  active={!activated} disabled={!activated} style={{backgroundColor: '#7aa0d7'}} value={props.data.id} className={botonTipo} onClick={handleModal}>
            Contact Us
          </Button>
          :  
          (
            (props.data.monthly_price==0) ?
          <Button  active={!activated} disabled={!activated} style={{backgroundColor: '#7aa0d7'}} value={props.data.id} className={botonTipo} onClick={handleModal}>
          Sign Up
        </Button>:
                  <Button  active={!activated} disabled={!activated} style={{backgroundColor: '#7aa0d7'}} value={props.data.id} className={botonTipo} onClick={handleModal}>
                  Buy Now
                </Button>
            
          )
        }
        </div>
      </Card.Body>
    </Card>

      

  );
      
};

{/* <p className="plan-descrip active">Ideal for individual develpoers</p>
  <p className="plan-descrip active">Developes with a full access</p>
  <p className="plan-descrip active">Begin collaborating with your team</p>
  <p className="plan-descrip active">Accelerate development with powerfull tools</p>
  <p className="plan-descrip active">Authenticate users with SSO</p> */}


export default PlanCard;