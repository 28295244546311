import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const Obtain_multiple_users_profiles = () => 
{
  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: ` {
        "status": <boolean>,
        "profiles": [
            {
                "name": <string>,
                "code": <string>,
                "id": <integer>
            }
        ],
        "elapsed": <float>
    }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
    url = "URL_BASE/ws/util.py/profiles?country_code=MX&user_id=4532&session_id=413-JRdxDQvO-zPMcwLeptmOyA=="
    payload = {}
    files = {}
    headers= {}
    response = requests.request("GET", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
    url = "URL_BASE/ws/util.py/profiles?country_code=MX&user_id=4532&session_id=413-JRdxDQvO-zPMcwLeptmOyA=="
    payload = {}
    files = {}
    headers= {}
    response = requests.request("GET", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: ` var request = require('request');
    var options = {
        'method': 'GET',
        'url': 'URL_BASE/ws/util.py/profiles?country_code=MX&user_id=4532&session_id=413-JRdxDQvO-zPMcwLeptmOyA==',
        'headers': {},
        formData: {}
    };
    request(options, function (error, response) { 
        if (error) throw new Error(error);
        console.log(response.body);
    });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: ` var formdata = new FormData();
    var requestOptions = {
        method: 'GET',
        body: formdata,
        redirect: 'follow'
    };
    fetch("URL_BASE/ws/util.py/profiles?country_code=MX&user_id=4532&session_id=413-JRdxDQvO-zPMcwLeptmOyA==", requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: ` curl --location --request GET 'URL_BASE/ws/util.py/profiles?country_code=MX&user_id=4532&session_id=413-JRdxDQvO-zPMcwLeptmOyA%3D%3D'`
      }
    ]
  }

  
  return(
    <Fragment>
            <div className='container-fluid row'>
        <div className='col-md-7'>

      <h1 >
        Obtain multiple users&#39; profiles
      </h1>
      <p>This service is used to obtain users&#39; profiles.</p>
      <p>
        <span className="label get">GET:</span>
        <span></span> <code>URL_BASE + /ws/util.py/profiles</code>
      </p>
      <h2 >Receives:</h2>
      <p>
        All parameters are sent in the querystring of the call, so a percentage
        encoding for URI must be applied &#40;aka URL encoding&#41;.
      </p>
      <div className='overflow-auto'>
      <table>
        <thead>
          <tr>
            <th align="left">Parameter</th>
            <th align="center">Format</th>
            <th align="center">Obligatory</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
            <th align="center">Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="left">country_code</td>
            <td align="center">
              ISO 3166 alpha-2 &#40;
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                https://www.iso.org/glossary-for-iso-3166.html
              </a>
              &#41;
            </td>
            <td align="center">Yes</td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
            <td align="center">ES &#40;Spain&#41;</td>
          </tr>
          <tr>
            <td align="left">user_id</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">Identifier of the user making the request</td>
            <td align="center">N/A</td>
            <td align="center">4532</td>
          </tr>
          <tr>
            <td align="left">session_id</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send signature to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Obtained by identifying the user with ID user_id on the platform
            </td>
            <td align="center">N/A</td>
            <td align="center">385-XadgYoWixbzN_wqWc3Yyog==</td>
          </tr>
          <tr>
            <td align="left">signature</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send session_id to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Enables you to sign the request without having to log in
            </td>
            <td align="center">N/A</td>
            <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
          </tr>
        </tbody>
      </table>
      </div>

      <h2 >Returns:</h2>
      <p>
        Depending on the result of the operation, this service can return two
        different JSON:
      </p>
      <h3 >Answer JSON OK:</h3>
  
      <div className='mb-3'>
        <DisplayCode1 codeToshow={codes.answe_ok1}/>
      </div>
      <h2 >Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li>
          <code>profiles:</code> List of the profiles.
        </li>
        <li>
          <code>name:</code> Profile name.
        </li>
        <li>
          <code>code:</code> Profile role name
        </li>
        <li>
          <code>id:</code> Profile identifier.
        </li>
        <li>
          <code>elapsed:</code> Operation execution time.
        </li>
      </ul>
      <h3 >Answer JSON KO:</h3>
  
      <div className='mb-3'>
        <DisplayCodeKo/>
      </div>
      <h2 >Business logic:</h2>
      <p>By means of this service all the system profiles are obtained.</p>
      </div>
        <div className='col-md-5'>
      <h2 >Example requests:</h2>
      <div className='mb-3 effectStickySections'>
      <DisplayCode2 codeToshow={codesMutiple}/>
      </div>
      
        </div>

      </div>
    </Fragment>
  );
}

export default Obtain_multiple_users_profiles;
