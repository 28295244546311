import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './components/sidebar';
import Topbar from './components/topbar';

import chevron from '../../assets/img/ui_icons/chevron.svg';
import calendar from '../../assets/img/ui_icons/cld_calendar.svg';
import placeholder from '../../assets/img/ui_icons/cld_placeholder.svg';
import manageApi from '../../assets/img/ui_icons/cld_apiManage.svg';
import Storage from '../../assets/img/ui_icons/cld_storage.svg';

//widgets
import ApiProjects from './widgets/ApiProject';
import Request from './widgets/request';
import Team from './widgets/team';
import Developers from './widgets/Developers';
import Learning from './widgets/Learning';
import SessionService from '../../services/SessionsService';

function Dashboard() {
    // Leer el valor inicial desde localStorage o usar `true` para modo oscuro por defecto
    const initialTheme = localStorage.getItem('darkMode') === 'true' || localStorage.getItem('darkMode') === null;
    const [isCollapsed, setIsCollapsed] = useState(SessionService.ISCOLLAPSED);
    const [isMobileOpen, setIsMobileOpen] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(initialTheme);

    useEffect(() => {
        // Seleccionar el elemento #dashboardWrapper y actualizar la clase dark-mode
        const dashboardWrapper = document.getElementById('dashboardWrapper');
        if (dashboardWrapper) {
            if (isDarkMode) {
                dashboardWrapper.classList.add('dark-mode');
            } else {
                dashboardWrapper.classList.remove('dark-mode');
            }
        }
    }, [isDarkMode]);

    const toggleSidebar = () => {
        SessionService.ISCOLLAPSED = !isCollapsed;
        setIsCollapsed(!isCollapsed);
    };

    const toggleMobileSidebar = () => {
        setIsMobileOpen(!isMobileOpen);
    };

    const toggleTheme = () => {
        setIsDarkMode((prevMode) => {
            const newMode = !prevMode;
            localStorage.setItem('darkMode', String(newMode)); // Guardar el estado en localStorage
            return newMode;
        });
    };


    // Estado para controlar la visibilidad del menú y el valor seleccionado
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Period Of Time");

    // Opciones del menú
    const options = ["Organization 1", "Organization 2", "Organization 3"];

    // Función para manejar la selección de una opción
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false); // Cerrar el menú después de seleccionar
    };

    return (
        <div className="d-flex" id="dashboardWrapper">
            {/* Sidebar */}
            <Sidebar isCollapsed={isCollapsed} isMobileOpen={isMobileOpen} />

            {/* Page Content */}
            <div id="page-content-wrapper" className="w-100">
                <Topbar
                    toggleSidebar={toggleSidebar}
                    toggleMobileSidebar={toggleMobileSidebar}
                    toggleTheme={toggleTheme}
                    isCollapsed={isCollapsed}
                    isDarkMode={isDarkMode}
                />
                <div id='content' className="container-fluid mt-0">
                    <div className="row">
                        <div className="col-12">
                            <h1 className='title margin-y-24px'><img src="" alt="" /><span>Dashboard</span></h1>
                            <div className='customBorder' />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-8">
                            <h1 className='subtitle margin-y-24px'><img src="" alt="" /><span>Organization: 101OBeX Corp</span></h1>
                        </div>
                        <div className="col-md-4">
                            <div className="customSelectCalendar" onClick={() => setIsOpen(!isOpen)}>
                                <span className="icon">
                                    <img src={calendar} alt="" />
                                </span>
                                <span className="text">{selectedOption}</span>
                                <span className="arrow"><img src={chevron} alt="" /></span>

                                {isOpen && (
                                    <div className="options">
                                        {options.map((option, index) => (
                                            <div
                                                key={index}
                                                className="option"
                                                onClick={() => handleOptionClick(option)}
                                            >
                                                {option}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row  mx-n2">
                        <div className="col-md-8">
                            <div className="row">
                                <div className="col-md-6 px-2">
                                    <ApiProjects />
                                </div>
                                <div className="col-md-6 px-2">
                                    <div className="item h-type-1">
                                        <div className="title">
                                            <span><img src={manageApi} alt="" /></span>
                                            <p> Managed APIs</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-6 px-2">
                                    <Request />
                                </div>
                                <div className="col-md-6 px-2">
                                    <div className="item h-type-2">
                                        <div className="title">
                                            <span><img src={Storage} alt="" /></span>
                                            <p>Storage</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-3 ">
                                <div className="col-12 px-2">
                                    <div className="item h-type-4 ">
                                        <div className='flex-full '>
                                            <p className='w-100 txt-bold text-center m-0'>You don’t have data to show</p>
                                            <p className='w-100 text-center' >When you have data to show, you’ll find here.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 px-2">
                                    <Team />
                                </div>
                                <div className="col-lg-6 col-md-12 px-2">
                                    <Developers />
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-12 px-2">
                                    <Learning />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
