import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Table, Card, Button, Spinner, Accordion } from 'react-bootstrap';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { useParams } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type ProjectTransactionsInfoProps = {
  projects: Array<any>
};

const ProjectTransactionsInfo: FunctionComponent<ProjectTransactionsInfoProps> = (props) => {
  const { projects } = props;
  const { project } = useParams();
  const [stats, setStats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userProjects, setProjects] = useState([]);
  const [activeKey, setActiveKey] = useState('0');
  const [isOpen, setIsOpen] = useState(true);
  const [moreThan9, setMoreThan9] = useState(false);
  const [allProjecs, setAllProjects] = useState([]);
  const [seeMore, setSeeMore] = useState(true);

  const getProjectInfo = async () => {
    try {
      setLoading(true);
      const result = await ObexRequestHandler.get(`/movements_stats/${project}`);
      const pro = projects ? projects : (await ObexRequestHandler.get('/projects')).data || []; // Si no recibe los projects por los props los rescatamos por request
      setProjects(pro);

      const { success, data, message } = result;
      if (!success) throw { message };
      if(data.stats.length > 9){

        let only9 = data.stats.slice(0,9);
        setStats(only9);
        setMoreThan9(true);
        setAllProjects(data.stats);
      }else{

        setStats(data.stats);
      }
    } catch (error) {
      console.error('ERROR FETCHING STATS ', error);
    }
    setLoading(false);
  };

  const renderStats = stats.map((s, index) => (
    <tr key={index}>
      <td className='px-0'>{s.name}</td>
      <td className='px-0 text-right'>{s.count}</td>
    </tr>
  ));

  const loadingSpinner = (

      <div  className=" mx-auto d-flex justify-content-center" style={{width:"149%"}}>
        <Spinner animation="border" />
      </div>
  );

  const handleToggle = () => {
    setActiveKey(isOpen ? '' : '0');
    setIsOpen(!isOpen);
  };

  const chargeAll = ()=>{
    // debugger
    setStats(allProjecs);
    setSeeMore(false);
  }

  useEffect(() => {
    getProjectInfo();
  }, [project]);

  return (
    <>
      <BrowserView>
    <DashLayout sider={DashRouters} active={'projects'}>
      <Row className="mb-5 mt-5 pt-4">
        <Col sm={5}>
          <Accordion activeKey={activeKey}>
            <Card style={{borderRadius:"11px"}}>
              <Card.Header className='px-5 bg-white py-3'>
                <div className='d-flex justify-content-between'>
                  <div className=' text-bold' style={{fontSize:"1.1rem"}} aria-expanded={activeKey === '0'}>
                    Project {project} transactions
                  </div>
                  <div style={{ cursor: "pointer" }} onClick={handleToggle}>
                    {isOpen ? (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="50px" height="17px">
                        <path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="50px" height="17px">
                        <path d="M278.6 406.6c-12.5 12.5-32.8 12.5-45.3 0l-192-192c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L256 338.7l169.4-169.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-192 192z" />
                      </svg>
                    )}
                  </div>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body className='pt-1 px-5'>
                  <Table responsive>
                    <thead style={{ 
              fontFamily: 'Regular Intro Bold',
              textAlign:'start',
              backgroundColor:'transparent',
              color:'black',
              borderWidth:'1px',
              borderRadius:'15px',
              borderStyle:'hidden'
             }} className="  bg-white  text-dark">
                      <tr >
                        <th className='border-bottom pl-0 text-left'>Name</th>
                        <th className='border-bottom px-0 text-right'>Requests</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? loadingSpinner : renderStats}
                    </tbody>
                  </Table>
                  {moreThan9 && seeMore && (
                    <div className='d-flex justify-content-end'>
                      <div className='text-info pe-auto' role="button" onClick={chargeAll}>
                        see more ...
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
    </DashLayout>
    </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  );
};

export default ProjectTransactionsInfo;
