import React, { useState } from "react";


import icn_item from '../../../assets/img/ui_icons/ri_team-line.svg';
import chevron from '../../../assets/img/ui_icons/chevron.svg';


function ApiProject() {

    // Estado para controlar la visibilidad del menú y el valor seleccionado
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Dev Team");

    // Opciones del menú
    const options = ["Dev Team", "Team 2", "Design Team"];

    // Función para manejar la selección de una opción
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false); // Cerrar el menú después de seleccionar
    };



    return (
        <div id='w_apiProject' className="item h-type-3">
            <div className="title">
                <span><img src={icn_item} alt="" /></span>
                <p>Dev Team</p>
            </div>

            <div className="flex-full">
                <div className="selectWrapper mb-3">
                    <div className="custom-select" onClick={() => setIsOpen(!isOpen)}>
                        <span className="icon">
                        </span>
                        <span className="text">{selectedOption}</span>
                        <span className="arrow"><img src={chevron} alt="" /></span>

                        {isOpen && (
                            <div className="options">
                                {options.map((option, index) => (
                                    <div
                                        key={index}
                                        className="option"
                                        onClick={() => handleOptionClick(option)}
                                    >
                                        {option}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

                <div className="data">
                    <div className="dataContent">
                        <span> <div className="dot green"></div>Active</span>
                        <p>152</p>
                    </div>

                    <div className="dataContent">
                        <span><div className="dot red"></div>Inactive</span>
                        <p>15</p>
                    </div>

                    <div className="selectWrapper">
                    </div>

                </div>
            </div>

            <div className="bottom">
                <div className="label">152 in use of 200</div>
            </div>
        </div>
    );
}

export default ApiProject;