import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NameDivState {
    name: boolean;
}

const initialState: NameDivState = {
    name: false,
};

export const updateSidebarGreen = createSlice({
    name: 'updateSidebarGreen',
    initialState,
    reducers: {
        UpdatedName: (state, action: PayloadAction<boolean>) => {
            console.log('Reducer: isUpdatedName', action.payload); // Agrega un log aquí
            state.name = action.payload;
        },
    },
});

export const { UpdatedName } = updateSidebarGreen.actions;

export default updateSidebarGreen.reducer;
