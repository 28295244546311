import MobileLayout from "../functionalComponents/Mobile_Layout/MobileLayout";
import React, { FunctionComponent, useState } from 'react';
import { Row, Col, Alert, Card, Button, Form, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faCheck, faDesktop, faMobileAlt } from "@fortawesome/free-solid-svg-icons";
import avapID from '../../assets/img/AVAP_Cloud.svg'
import fondo_ID from '../../assets/img/Fondo_cloud.svg'
import ilustracionnotMobileID from '../../assets/img/ilustracion_notMobile_Cloud.svg'


type privateInfProps = {
};

const DisclaimerCloud:FunctionComponent<privateInfProps> = ({ }) => {


  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);


  // useEffect(() => {/**/})

  return(
    
      <div style={{textAlign:"center", margin: 0, padding: 0, display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <div style={{ marginTop:"-80px",backgroundImage: `url(${fondo_ID})`, backgroundPosition:"center",backgroundRepeat: "no-repeat",height:"500px",backgroundSize:"cover", }}>  
      <p style={{fontSize:"30pt", color:"#0473BF", fontWeight:"bold", marginTop:"150px"}}><b>We're sorry!</b></p>     
        <div style={{}}>
          
          <img src={ilustracionnotMobileID} alt="" />
        </div>
       </div>
       <div style={{marginTop:"50px"}}>
        <img style={{width:"50%", marginBottom:"50px"}} src={avapID} alt="" />
       </div>
       <div>
        <p style={{fontSize:"17pt", color:"#323031", marginRight:"80px", marginLeft:"80px"}}><b>This site is not designed for mobile devices.</b></p>
       </div>
       <div>
        <p style={{fontSize:"13pt", color:"#898989", marginLeft:"68px", marginRight:"68px"}}><b>Please visit us on a desktop browser to enjoy the full experience.</b></p>
        <p style={{fontSize:"13pt", color:"#898989",marginTop:"-15px"}}>Thank you for understanding.</p>
       </div>
       <footer style={{ 
        backgroundColor: '#F5F5F5', 
        fontSize: '19pt', 
        color: '#316952', 
        textAlign: 'center', 
        padding: '20px', 
        marginTop: 'auto', 
        width: '100%' 
    }}>
      
        <div>
          <a href="https://avapcloud.com/cloud_login" style={{fontSize:"19pt", color:"#0473BF"}}>avapcloud.com</a>
        </div>
       </footer>
       </div>
   
  )
}

export default DisclaimerCloud;