import React, { Fragment } from 'react';
import './chapter.css'
const Chapter7_2 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>3.1 Line Comments</h2>
      <p>
        Line comments in AVAP™ are used to add brief annotations or explanations to a specific line of code. These comments begin with the <code>//</code> symbol and continue until the end of the line. Everything following <code>//</code> is considered a comment and is ignored by the compiler.
      </p>
      <pre>
        <code>
// This is a line comment in AVAP™ 
int x = 5; // You can also add comments at the end of a line of code
        </code>
      </pre>
      <p>
        Line comments are useful for providing quick clarifications about the code and improving its readability.
      </p>
    </div>
  </Fragment>
);

export default Chapter7_2;
