import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const ApiProductForm: React.FC<{ onSubmit: (data: any) => void }> = ({ onSubmit }) => {
	const [formData, setFormData] = useState({
		name: '',
		version: '',
		description: '',
		authentication: '',
		apiProduct: '',
	});

	// Datos locales
	const apiProducts = [
		{ id: 'api1', name: 'User Management API' },
		{ id: 'api2', name: 'Order Management API' },
	];

	const authOptions = [
		{ value: 'basic', label: 'Basic Authentication' },
		{ value: 'oauth', label: 'OAuth 2.0' },
	];

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target;
		setFormData((prev) => ({ ...prev, [name]: value }));
	};

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		onSubmit(formData);
	};

	return (
		<Form onSubmit={handleSubmit}>
			<Form.Group controlId="name">
				<Form.Label>Name</Form.Label>
				<Form.Control
					type="text"
					name="name"
					value={formData.name}
					onChange={handleChange}
				/>
			</Form.Group>
			<Form.Group controlId="version">
				<Form.Label>Version</Form.Label>
				<Form.Control
					type="text"
					name="version"
					value={formData.version}
					onChange={handleChange}
				/>
			</Form.Group>
			<Form.Group controlId="description">
				<Form.Label>Description</Form.Label>
				<Form.Control
					as="textarea"
					name="description"
					value={formData.description}
					onChange={handleChange}
				/>
			</Form.Group>
			<Form.Group controlId="authentication">
				<Form.Label>Authentication</Form.Label>
				<Form.Control
					as="select"
					name="authentication"
					value={formData.authentication}
					onChange={handleChange}
				>
					<option value="">Select an Authentication</option>
					{authOptions.map((auth) => (
						<option key={auth.value} value={auth.value}>
							{auth.label}
						</option>
					))}
				</Form.Control>
			</Form.Group>
			<Form.Group controlId="apiProduct">
				<Form.Label>API Product</Form.Label>
				<Form.Control
					as="select"
					name="apiProduct"
					value={formData.apiProduct}
					onChange={handleChange}
				>
					<option value="">Select an API Product</option>
					{apiProducts.map((product) => (
						<option key={product.id} value={product.id}>
							{product.name}
						</option>
					))}
				</Form.Control>
			</Form.Group>
			<Button type="submit">Submit</Button>
		</Form>
	);
};

export default ApiProductForm;
