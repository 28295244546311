import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Obtener_Campos_Nivel_KYC_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{
          "status": <boolean>,
          "fields": [
          {
          "style": <string>,
          "options": [
          [
          <string>,
          <string>
          ]
          ],
          "id": <string>,
          "type": <string>,
          "longitude": <integer>,
          "field_label": <string>
          }
          ],
          "level_kyc": <integer>,
          "form_header": <string>,
          "requires_an_operator": <boolean>,
          "elapsed": <float>
          }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{
          "status": false,
          "level": <string>,
          "message": <string>,
          "error": <string>
          }`,
        copied: false
      },
      answe_logic: {
        title:"Answer ok",
        type: "json",
        code: `{
          "status": <boolean>,
          "level": <string>,
          "message": <string>,
          "error": <string>
          "datos_error_extra": {
          "campos_kyc": {
          "form_header": <string>,
          "fields": [
          {
          "style": <string>,
          "options": [
          [
          <string>,
          <string>
          ]
          ],
          "id": <string>,
          "type": <string>,
          "longitude": <integer>,
          "field_label": <string>
          }
          ],
          "requires_an_operator": <boolean>,
          "level_kyc": <integer>
          }
          },
          }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests
        url = "URL_BASE/ws/users.py/acreditar_nivel_kyc?country_code=MX&user_id=4532&session_id=392-mMZlY8qUpq-E70WSMh22DA=="
        payload = {}
        headers= {}
        response = requests.request("GET", url, headers=headers, data = payload)
        print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests
          url = "URL_BASE/ws/users.py/acreditar_nivel_kyc?country_code=MX&user_id=4532&session_id=392-mMZlY8qUpq-E70WSMh22DA=="
          payload = {}
          headers= {}
          response = requests.request("GET", url, headers=headers, data = payload)
          print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'GET',
              'url': 'URL_BASE/ws/users.py/acreditar_nivel_kyc?country_code=MX&user_id=4532&session_id=392-mMZlY8qUpq-E70WSMh22DA==',
              'headers': {
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var requestOptions = {
                method: 'GET',
                redirect: 'follow'
                };
                fetch("URL_BASE/ws/users.py/acreditar_nivel_kyc?country_code=MX&user_id=4532&session_id=392-mMZlY8qUpq-E70WSMh22DA==", requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request GET 'URL_BASE/ws/users.py/acreditar_nivel_kyc?country_code=MX&user_id=4532&session_id=392-mMZlY8qUpq-E70WSMh22DA%3D%3D'`
        }
      ]
    }
  
  
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="obtain-kyc-level-fields">Obtain KYC level Fields</h1>
        <p>
          This service is used to obtain the necessary fields to perform a KYC
          &#40;Know Your Custommer&#41; level upgrade.
        </p>
        <p>
          <span className="label get">GET:</span>
          <span> </span> <code>URL_BASE + /ws/users.py/acreditar_nivel_kyc</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All parameters are sent in the querystring of the call, so a percentage
          encoding for URI must be applied &#40;aka URL encoding&#41;.
        </p>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  ISO 3166 alpha-2
                </a>
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">Identifier of the user making the request</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
            </tr>
          </tbody>
        </table>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>fields:</code> List with each of the fields that will make up the
            form, to make the KYC upgrade.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>style:</code> The value <code>&quot; 1 &quot;</code> or{' '}
            <code>&quot; &quot;</code>, and indicates if the field is displayed at
            50% of size with respect to the rest of the form fields.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>id:</code> Html identifier of the field.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>type:</code> Contains the value
            <code>&quot;numeric_text&quot;</code>,{' '}
            <code>&quot;free_text&quot;</code>,{' '}
            <code>&quot;multiple_selection&quot;</code>,{' '}
            <code>&quot;date&quot;</code>, <code>&quot;archive&quot;</code>.
            Indicates the type of data that the field accepts.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>longitude:</code> Maxlength of the data that the field supports..
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>field_label:</code> Text to display in the label of each field of
            the form.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>options:</code> Only available for fields of type{' '}
            <code>&quot;multiple_selection&quot;</code>. List that contains a list
            for each element of the drop-down, indicating the value of the field in
            the first element of each list, and the text in the second.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>level_kyc:</code> Level accessed after completing the form.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>form_header:</code> Form name.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>requires_an_operator:</code> Indicates if the intervention of an
            operator is necessary to validate the data entered.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.{' '}
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>level:</code> Error importance level.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code> Error message.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          If there is any user who is not at KYC level 1 after registering, the
          first time a <code>Login</code> is performed with that user an error JSON
          will be returned in which the <code>datos_error_extra</code> structure
          will be added to be able to manage rising to level 1:
        </p>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_logic}/>
          </div>
        </div>

      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
      
        
        </div>
        </div>
      </Fragment>
    );
  }
export default Obtener_Campos_Nivel_KYC_EN;
