import React, { useState } from "react";
import { FaBuilding } from "react-icons/fa";
import menu from './menuIcon.svg';
import notify from '../../../assets/img/ui_icons/cld_notify.svg';
import book from '../../../assets/img/ui_icons/cld_book.svg';
import chevron from '../../../assets/img/ui_icons/chevron.svg';

import { ReactComponent as Book } from '../../../assets/img/ui_icons/cld_book.svg';


function Topbar({ toggleSidebar, toggleMobileSidebar, toggleTheme, isCollapsed, isDarkMode }) {
    // Estado para controlar la visibilidad del menú y el valor seleccionado
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Select Organization");

    // Opciones del menú
    const options = ["Organization 1", "Organization 2", "Organization 3"];

    // Función para manejar la selección de una opción
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false); // Cerrar el menú después de seleccionar
    };


    return (
        <nav className="cloudNav">
            <div className="sidebarbtnWrapper">
                {/* Mobile Sidebar Toggle */}
                <button className="menu d-md-none" onClick={toggleMobileSidebar}>
                    <img src={menu} alt="menu icono" />
                </button>

                {/* Desktop Sidebar Toggle */}
                <button className="menu ml-3 d-none d-md-block" onClick={toggleSidebar}>
                    {isCollapsed ? '' : ''}
                    <img src={menu} alt="menu icono" />
                </button>
            </div>

            <div className="actionsWrapper">
                <div className="custom-select" onClick={() => setIsOpen(!isOpen)}>
                    <span className="icon">
                        <FaBuilding />
                    </span>
                    <span className="text">{selectedOption}</span>
                    <span className="arrow"><img src={chevron} alt="" /></span>

                    {isOpen && (
                        <div className="options">
                            {options.map((option, index) => (
                                <div
                                    key={index}
                                    className="option"
                                    onClick={() => handleOptionClick(option)}
                                >
                                    {option}
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <a className='customButton' href="">
                    Upgrade
                </a>
                <a className='icon docs' href="">
                    <img src={book} alt="icono de documentación" />

                </a>
                <a className='icon notifications' href="">
                    <img src={notify} alt="icono de notificaciones" />
                </a>
                <div className="profileImg"></div>


            </div>


            {/* Theme Toggle Button */}
            {/* <button className="btn btn-secondary ml-auto" onClick={toggleTheme}>
                {isDarkMode ? 'Light Mode' : 'Dark Mode'}
            </button>  */}
        </nav>
    );
}

export default Topbar;
