import React, { Fragment } from 'react';
import './chapter.css'
const Chapter7_4 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>3.3 Documentation Comments</h2>
      <p>
        AVAP™ also supports documentation comments, which are used to automatically generate documentation from the source code. These comments begin with <code>///</code> and are used to describe the functionality of classes, methods, variables, and other elements of the source code.
      </p>
      <pre>
        <code>
/// This function adds two integers and returns the result.
/// \param a The first integer.
/// \param b The second integer.
/// \return The sum of the two numbers.
int sum(int a, int b)  return a + b; 
        </code>
      </pre>
      <p>
        Documentation comments are essential for maintaining up-to-date and detailed documentation of the code, which facilitates its understanding and use by other developers.
      </p>
    </div>
  </Fragment>
);

export default Chapter7_4;