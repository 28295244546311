import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h1>Appendix</h1>
      <h2>Function Glossary</h2>
    </div>
  </Fragment>
);

export default Appendices_1;
