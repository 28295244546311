import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h2>2.2 Types of Variables in AVAP™</h2>
  <p>
    In AVAP™, there are two main types of variables: <strong>local</strong> and <strong>global</strong>.
  </p>
</div>
  </Fragment>
);
export default Intro_1;
