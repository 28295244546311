import React, { FunctionComponent, useEffect, useState } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import MarkdownEditor from "./components/EasyMDE ";


interface DocumentProps {
    setDocumentContent: (content) => void
    documentationContent: string
    opened: any
    isEditing: any
}

/*

interface DrawerProps {
  isOpen: boolean;
  toggleSidebar: () => void;
  name?: string; // Nueva prop opcional `name`
  subtitle?: string;
  children: React.ReactNode;
}

const Drawer: React.FC<DrawerProps> = ({ isOpen, toggleSidebar, name, children, subtitle }) => {

*/



const DevPortalDocumentation:FunctionComponent<DocumentProps> = ({isEditing, setDocumentContent, documentationContent, opened}) => {
    
    const [docContent, setDocContent] = useState('');

    const handleSave = async () => {
        setDocumentContent(docContent);
        //console.log('Saved content:', documentationContent);
        //backend logica
    };

    useEffect(()=>{
        console.log("BORRANDO")
        setDocContent('');
    }, [opened])

    return (
        <Form>
            <Form.Group controlId="documentationEditor" className="mb-4">
                <Form.Label>API Documentation</Form.Label>
                <MarkdownEditor
                
                    value={docContent || (isEditing ? documentationContent:'')}
                    onChange={setDocContent}
                    
                />
            </Form.Group>
            <Row>
                  <Col md='6'>
            <Button variant="primary" style={{ width:'100%' }} onClick={handleSave}>
                Save
            </Button>
            </Col>
            </Row>
        </Form>
    );
};

export default DevPortalDocumentation;
