import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';


import fondo from '../../assets/img/landingPage/fondoApps.png';
import dashboard from '../../assets/img/landingPage/cloudDashboard.png';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'

import features_1 from '../../assets/img/landingPage/release/APIRelease_01.png'
import features_2 from '../../assets/img/landingPage/release/APIRelease_02-.png'
import features_3 from '../../assets/img/landingPage/release/APIRelease03.png'


import icn_1 from '../../assets/img/landingPage/icn_vision.png'
import icn_2 from '../../assets/img/landingPage/icn_mission.png'
import icn_3 from '../../assets/img/landingPage/icono1.png'

import rocket from '../../assets/img/landingPage/rocket.png'
import tablet from '../../assets/img/landingPage/tabletApi.png'

import logo from '../../assets/img/logos/AVAP_APIReleaseManager_white.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'


import user from '../../assets/img/ui_icons/Icon fa-regular-user.svg'
import download from '../../assets/img/ui_icons/Icon akar-download.svg'
import key from '../../assets/img/ui_icons/Icon akar-key.svg'
import doc from '../../assets/img/ui_icons/fa-doc.svg'

import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'

import Favicon from '../../assets/img/Favicon_AVAP_Framework.png'

const PublisherProduct = () => {

    useEffect(() => {
        // favicon 
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = Favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);
    
        // clean favicon 
        return () => {
          document.head.removeChild(link);
        };
      }, []);

    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);



    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center pt-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">Simplified Management of API Releases</h1>
                    <p className="fl-subtitle">
                        Ensure consistent performance, seamless updates, and streamlined collaboration
                    </p>
                    <a className="glowBtn-dark fakeHide" href="#"><span className="btnInner">  Start with AVAP Cloud</span></a>
                </section>

                {/* Powerful API Platform */}
                <section className="container text-center pb-5">
                    <div className="row">
                        <div className="co">
                            {/* <img
                                src="https://placehold.co/1026x423"
                                alt="Platform Placeholder"
                                className="img-fluid mt-4"
                            /> */}
                            <p className="fl-subtitle mb-3">
                                Efficient management and seamless deployment of API releases
                            </p>
                            <p className="fl-text">
                                AVAP Release Manager allows you to manage API releases directly from your code repository. It tracks the latest version, automates testing, and ensures proper documentation is in place before deployment. Once tested, you can easily deploy to the desired environment, maintaining consistent performance across all stages.

                            </p>
                        </div>
                    </div>

                </section>

                <div className="w-100 fl-divider" style={{ backgroundImage: `url(${dividerbg})` }}>

                </div>



                <section className="container">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center mb-5">Key Features</div>
                        </div>
                    </div>
                </section>

                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                                style={{ height: '400px' }}
                            />
                        </div>
                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">User-Friendly Interface</h3>
                            <p className="fl-text">
                                Intuitive and easy-to-navigate dashboard designed to streamline release management. It gives you a clear overview of current releases and deployment status. With visual workflows and simplified processes, developers can quickly configure and monitor releases, ensuring seamless deployment.

                            </p>
                        </div>
                    </div>

                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Release Control & Management</h3>
                            <p className="fl-text">
                                Handle multiple API releases, ensuring that new features and improvements are deployed without disrupting existing services.
                                The system automates the entire release lifecycle, from preparation to roll-out, and ensures backward compatibility for a
                                smooth transition between release versions.
                            </p>
                        </div>

                        <div className="col-md-6 text-center">
                            <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                                style={{ height: '400px' }}
                            />
                        </div>
                    </div>
                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center">
                            <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>


                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">API Testing for Reliable Releases</h3>
                            <p className="fl-text">
                                AVAP Release Manager performs API testing to ensure functionality and performance before deployment, guaranteeing that every release works seamlessly and meets quality standards.
                            </p>
                        </div>

                    </div>
                </section>

                <section className="container fl-z-index-2 position-relative mb-8">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center mb-3">
                                Key Benefits
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Seamless Deployment

                                </p>
                                <p className="fl-text">
                                    Effortlessly manage API releases across environments, ensuring smooth transitions without disruption to existing services.


                                    <br />
                                    &nbsp;
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Quality Assurance
                                </p>
                                <p className="fl-text">
                                    Perform thorough API testing to validate functionality and performance, ensuring every release meets quality standards.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Version Control
                                </p>
                                <p className="fl-text">
                                    Maintain full control over API versions, ensuring backward compatibility and easy rollback in case of issues.
                                    <br />
                                    &nbsp; <br />
                                    &nbsp;
                                </p>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default PublisherProduct;
