import React from 'react';
import './chapter.css'
const Chapter13_5 = () => (
  <div>
    <h2>Operation</h2>
    <p>
      When an include is found in an AVAP™ file, the interpreter searches for the specified file and incorporates it into the current file at compile time. This means that all the code contained in the included file will be available for use in the current file.
    </p>
  </div>
);

export default Chapter13_5;