import React, { FunctionComponent, Component, useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Modal, Form, Button  } from 'react-bootstrap';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import ObexAlert from '../basicComponents/ObexAlerts';

type verifySubProps = {
  showModal: boolean,
  onCloseModal: () => void,
  handleSubmit: (e) => void,
  loading: boolean,
  error: string,
  success: string,
  name_to_check
}


const VerifySubModal:FunctionComponent<verifySubProps> = (props) => {
  const [visitado, setVisitado] = useState(false);
  const { showModal, onCloseModal, handleSubmit, loading, error, success , name_to_check} = props;
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const CheckEntry = (value) =>{

    if (value==name_to_check) setSubmitDisabled(false)

  }


  useEffect(() => {
    if (!visitado){
      if (document.getElementById('entrada-1')!=null){
      document.getElementById('entrada-1').focus();
      setVisitado(true);
      }
    }

  }, [])

  return(
    <Modal dialogClassName="addons-dialog obex-dialog"
      show={showModal} 
      onHide={onCloseModal}
      animation={false}
      scrollable>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-start">
          <Row>
            <Col md="12" className="addons-title">
              <span>Cancel Subscription</span>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      {/*<div className="border-top border-black my-2 mx-3"></div> */}
      <Modal.Body className="pt-0">

      <Row>
          <Col md="9" className="text-start">
           <Row >
            <span
            className='tm-text'
            style={{
              paddingLeft:'15px',
              marginTop:'16px'
            }}
            >If there are associated staging projects they will also be eliminated. Please type '{name_to_check}' in order to confirm.</span>
            </Row>
            </Col>
          </Row>

        <Form>
        <Form.Row>
            <Col><span> 
              </span></Col>
            </Form.Row>
              <Form.Row id='entrada-1'>
              <Col md="6" className="mt-3">
              <input id='entrada' style={{
                width:'100%', 
                backgroundColor:'white'
              }} className='form-control' type="text"  name="name"  
              onChange={(e) => {
                const { value } = e.target;
                CheckEntry(value)
              }}
              />
              </Col>
              </Form.Row>


          <Form.Row style={{
            width: '150px',
            marginLeft: 'auto',
            marginRight: '15px',
            marginTop: '15px',
          }}>
            <Col className="text-end">
            {/*}
              <ButtonSubmit 
                loading={loading}
                success={success}
                error={error}
                handleSubmit={handleSubmit}
                submitDisabled={submitDisabled}
                textButton={'Confirm Cancel'}/> */}
<button value="5" className="customButton-new w-100 textBold" onClick={(e) => handleSubmit(e)}>Confirm Cancel</button>


            </Col>
          </Form.Row>
          <Row>
            <Col>
              <ObexAlert type='error' msg={error}/>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  )
}

export default VerifySubModal;