import React, { FunctionComponent, useState } from 'react';
import { Row, Col, Image, Form, Button, Spinner, Alert, InputGroup } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Footer from '../../newViews/landing_framework/landingComponents/Footer';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
// import Footer from '../functionalComponents/DashboardLayout/FooterMenuAccess';
import Logo from '../../../src/assets/img/logotipo_ID_1.png';
import infol from '../../../src/assets/img/newDashboard/informacion.png';

type ChangePasswordProps = {
  userLabel?: string,
  passLabel?: string,
  rememberCheckboxLabel?: string,
  submitButtonLabel?: string,
  missingUserMsg?: string,
  missingPassMsg?: string,
  loginFailedMsg?: string
};

const ChangePassword: FunctionComponent<{}> = () => {

  const { token } = useParams();

  // Estados para la lógica del formulario
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Controla la visibilidad del password
  const [showRepeatPassword, setShowRepeatPassword] = useState(false); // Controla la visibilidad de repeat password

  // Lógica para manejar el envío del formulario
  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      setLoading(true);
      setSuccess('');
      setError('');
      if (password !== repeatPassword) throw { message: 'Passwords do not match, try again' };
      const payload = { token, password };
      const result = await ObexRequestHandler.post('/reset_password', payload);
      const { success, message, data } = result;
      if (!success) throw { message };
      setSuccess(data);

      // Redirigir al login después de 4 segundos
      setTimeout(function () {
        const path = '/login';
        window.location.href = path;
      }, 4000);

    } catch (error) {
      console.error('ERROR WHILE CHANGING PASSWORD', error);
      setError(error.message || error);
    }
    setLoading(false);
  };

  // Render del componente
  return (
    <>
      {/* Estructura principal */}
      <div className="text-center d-flex flex-column containerLogin">
        <div className="justify-content-center rowLogin" style={{ marginBottom: '150px', marginTop: '50px' }}>
          <div className="login-form px-5 pt-4">
            <Row className="justify-content-center mt-5">
              <Col>
                <Image
                  src={Logo}
                  fluid
                  width="180"
                  style={{ marginBottom: '60px' }}
                />
                <h3 className="text-boldLogin mb-4">Reset your password</h3>
                <h6 className="text-boldLogin mb-3">Enter a new password for your account.</h6>
              </Col>
            </Row>

            <Form onSubmit={handleSubmit} className="obex-form theForm">
              {/* Input para la nueva contraseña */}
              <Form.Group controlId="formBasicPassword">
                <InputGroup>
                  <Form.Control
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    type={showPassword ? 'text' : 'password'}
                    placeholder="New password"
                    className="borderRadiusLeftLogin"
                    value={password}
                  />
                  <InputGroup.Append>
                    <Button
                      className="btnNew"
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        background: 'white',
                        borderLeft: '0px',
                        borderRight: 'none',
                        borderTop: '1px #ced4da solid',
                        borderBottom: '1px #ced4da solid',
                        width: '40px',
                      }}
                    >
                      {showPassword ? (
                        <FontAwesomeIcon icon={faEyeSlash} style={{ color: '#AFAFAF' }} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} style={{ color: '#AFAFAF' }} />
                      )}
                    </Button>
                    <Button
                      style={{ width: '40px' }}
                      type="button"
                      className="btnNew borderRadiusRightLogin info-icon-container "
                    >
                      <div className="borderRadiusRightLogin btn-block">
                        <img
                          src={infol}
                          alt="Info"
                          width="15px"
                          height="15px"
                          className="info-icon"
                          style={{ marginTop: '1px', cursor: 'pointer' }}
                        />
                        <div className="tooltip-text" style={{ zIndex: 9999999 }}>
                          <ul>
                            <li>8 or more characters</li>
                            <li>Upper & lowercase letters</li>
                            <li>At least one number</li>
                          </ul>
                        </div>
                      </div>
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>

              {/* Input para confirmar la nueva contraseña */}
              <Form.Group controlId="formBasicRepeatPassword">
                <InputGroup>
                  <Form.Control
                    onChange={(e) => setRepeatPassword(e.target.value)}
                    required
                    type={showRepeatPassword ? 'text' : 'password'}
                    placeholder="Confirm new password"
                    className="borderRadiusLeftLogin"
                    value={repeatPassword}
                  />
                  <InputGroup.Append>
                    <Button
                      className="btnNew"
                      onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                      style={{
                        background: 'white',
                        borderLeft: '0px',
                        borderRight: 'none',
                        borderTop: '1px #ced4da solid',
                        borderBottom: '1px #ced4da solid',
                        width: '40px',
                      }}
                    >
                      {showRepeatPassword ? (
                        <FontAwesomeIcon icon={faEyeSlash} style={{ color: '#AFAFAF' }} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} style={{ color: '#AFAFAF' }} />
                      )}
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>

              {/* Botón para continuar */}
              <Button
                type="submit"
                className="customButton-green mt-4"
                style={{ display: 'block' }}
              >
                Continue
                {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
              </Button>

              {/* Mensajes de error y éxito */}
              {error.length > 0 && <Alert variant="danger">{error}</Alert>}
              {success.length > 0 && <Alert variant="success">{success}</Alert>}
            </Form>
          </div>
        </div>

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};

export default ChangePassword;

/*
NOTAS:
- Este componente integra "ojitos" para los campos de contraseña, utilizando FontAwesome para los íconos.
- El diseño incluye botones de ayuda (tooltip) que no interfieren con los íconos de visibilidad.
*/
