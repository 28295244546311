import React from 'react';
import './chapter.css'
const Chapter9_1 = () => (
    <div>
        <h1>Execution Model in AVAP</h1>
        <h2>4.1. Structure of a Program</h2>
        <p>
            A program in AVAP is built from code blocks that execute linearly. A block is a section of the AVAP program text that executes as a unit. Code blocks in AVAP include:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>A script file.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>The body of a function.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>An import statement for additional files.</li>
        </ul>
        <p>
            Each line of code in AVAP is considered a block and executes sequentially. There is no interactive execution, deferred execution, or object classes.
        </p>
    </div>
);

export default Chapter9_1;
