import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import "./Privacy.css";
import "./../NewDashboard.css";
import icon_analitic from "../../../assets/img/newDashboard/icono-analytics.png";
import icon_colab from "../../../assets/img/newDashboard/logotipo-collaborativeN.png";
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import { Modal, Toast } from 'react-bootstrap';

const Privacy = () => {
  let { path, url } = useRouteMatch();
  const [privacyInfo, setPriInfo] = useState([]);
  const [avapcn, setAvapcn] = useState([]);
  const [avapanalytic, setAvapanalytic] = useState([]);
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [hideIpChecked, setHideIpChecked] = useState(false);
  const [avapAnalyticsChecked, setAvapAnalyticsChecked] = useState(false);  

  const [showToast, setShowToast] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [whereWantGo, setWhereWantGo] = useState("");
  

   const toggleShowToast = () => setShowToast(!showToast);


  const history = useHistory();

  const postAvapcn = async (value) => {
    try {
      const result = (await ObexRequestHandler.post('/set_avap_cn', {
        hide_ip: value
      })).data;
      setAvapcn(result);
      console.log('postAvapcn llamado correctamente');
    } catch (error) {
      console.error('ERROR GET Avap cn', error);
    }
  }

  const postAvapanalytic = async (value) => {
    try {
      const result = (await ObexRequestHandler.post('/set_avap_analytics', {
        allow_avap_analytics: value
      })).data;
      setAvapanalytic(result);
      console.log('postAvapanalytic llamado correctamente');
    } catch (error) {
      console.error('ERROR GET Avap analytic', error);
    }
  }

  const applyChanges = () => {
    
      postAvapcn(hideIpChecked);
      postAvapanalytic(avapAnalyticsChecked);
   
      setShowToast(false);
      setShouldBlockNavigation(false)
  };

  const NoapplyChanges = async() => {
    
    setShowModal(false); // Ocultar el modal
    setShowToast(false);
    await setShouldBlockNavigation(false)
  };

  const handleConfirmNavigation = async () => {
    // debugger
    setShowModal(false); // Ocultar el modal
    setShowToast(false);
    await setShouldBlockNavigation(false)
    history.push(whereWantGo);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = (await ObexRequestHandler.get('/privacy_info')).data;
        console.log('GET /privacy_info result:', result);
        setHideIpChecked(result.hide_ip);
        setAvapAnalyticsChecked(result.avap_analytics);
        setInitialDataLoaded(true);
      } catch (error) {
        console.error('ERROR GET PRIVACY INFO', error);
      }
    };
    fetchData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Verificar si se debe bloquear la navegación
    // debugger
    if (shouldBlockNavigation==true) {
      // Bloquear la navegación al componente
      const unblock = history.block((location, action) => {
        setShowModal(true); // Mostrar el modal al intentar navegar

        // Obtener la ruta hacia donde se intenta navegar
        const destinationPath = location.pathname;

        setWhereWantGo(destinationPath);

        return false; // Devolver false para bloquear la navegación
      });

      return () => {
        // Desbloquear la navegación al salir del componente
        unblock(); // Llamar a unblock para desbloquear la navegación
      };
    }
  }, [history, showToast]);

  return (
    <div>
        <div className='custom_warning'>
        <Toast show={showToast} onClose={toggleShowToast} className="custom_toast">

        <Toast.Body className='custom_toast_body'>
            <div className='d-flex justify-content-end'>
              <div className='mr-2'>Data modified, would you like to save it?</div>
              <div className='mr-2 textBold' onClick={applyChanges} style={{cursor:"pointer"}}>Yes</div>
              <div className='textBold' onClick={NoapplyChanges} style={{cursor:"pointer"}}>No</div>
            </div>
          </Toast.Body>
        </Toast>
      </div>
      
      <h2>Privacy</h2>
      <div>
        <p>Set your privacy preferences. Help AVAP improve by sharing private analytics from your devices and account.</p>
      </div>
      
      <div className="grid-container" style={{width:'65vw'}}>
        <div className="boxShadowCard borderRadius">
          <div className='py-3 d-flex flex-column'>
            <div className='d-flex boxShadowCardBottomTitle pl-3 pb-3'>
              <div className='pr-4'>
                <div className='sizeImgTitleCard'>
                  <img src={icon_colab} alt="" width={"100%"} height={"100%"} />
                </div>
              </div>
              <div className='fontSizeTitleCard textBold'>AVAP Collaborative Network</div>
            </div>
            <div className='d-flex flex-column align-self-center widthContentCard pt-3'>
              <div className='d-flex flex-column'>
                <div className='fontText2 textBold'>Keep your IP address private on the platform</div>
              </div>
              <div className="containerPri" style={{ marginTop: "30px" }}>
                <div className="checkbox-container" style={{ marginTop: "1px" }}>
                  <input 
                    type="checkbox" 
                    checked={hideIpChecked} 
                    onChange={(e) => {
                      setHideIpChecked(e.target.checked);
                      setShowToast(true);
                      setShouldBlockNavigation(true)
                    }} 
                    style={{ marginBottom: "1px" }} 
                  />
                </div>
                <div>
                  <div style={{ marginLeft: "10px" }}>Hide my IP</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="boxShadowCard borderRadius">
          <div className='py-3 d-flex flex-column'>
            <div className='d-flex boxShadowCardBottomTitle pl-3 pb-3'>
              <div className='pr-4'>
                <div className='sizeImgTitleCard'>
                  <img src={icon_analitic} alt="" width={"100%"} height={"100%"} style={{ marginTop: "3px" }}/>
                </div>
              </div>
              <div className='fontSizeTitleCard textBold'>AVAP Analytics</div>
            </div>
            <div className='d-flex flex-column align-self-center widthContentCard pt-3'>
              <div className='d-flex flex-column'>
                <div className='fontText2 textBold'>You can help AVAP improve them by sharing privatized analytics from your devices and your account</div>
              </div>
              <div className="containerPri" style={{ marginTop: "10px" }}>
                <div className="checkbox-container" style={{ marginTop: "1px" }}>
                  <input 
                    type="checkbox" 
                    checked={avapAnalyticsChecked} 
                    onChange={(e) => {
                      setAvapAnalyticsChecked(e.target.checked);
                      setShowToast(true);
                      setShouldBlockNavigation(true)
                    }} 
                  />
                </div>
                <div>
                  <div style={{ marginLeft: "10px" }}>Share your AVAP data</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal style={{ marginTop:'7rem' }} show={showModal} onHide={handleClose}>
        <Modal.Body className='colorBlack'>Do you want leave without saving your changes?</Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary custom-button  textBold" onClick={handleConfirmNavigation}>
            Yes
          </button>

          <button className="btn btn-primary custom-button  textBold" onClick={handleClose}>
            No
          </button>
          
        </Modal.Footer>
      </Modal>
      </div>
    </div>
  );
}

export default Privacy;
