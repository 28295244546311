import React, { useState, useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';



import fondo from '../../assets/img/landingPage/fondoApps.png';


import features_1 from '../../assets/img/landingPage/brunix/Brunix_01.png'
import features_2 from '../../assets/img/landingPage/brunix/Brunix_02.png'
import features_3 from '../../assets/img/landingPage/brunix/Brunix_03.png'


import home_img from '../../assets/img/landingPage/brunix/home.png'

import logo from '../../assets/img/logos/AVAP_AI_white.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'
import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'

import img_ai1 from '../../assets/img/landingPage/ContextualMemory_0.5.png'

import Favicon from '../../assets/img/Favicon_AVAP_Framework.png'

const BrunixProduct = () => {

    const [selectedFeature, setSelectedFeature] = useState('realTime');

    let imageSrc;
    if (selectedFeature === 'realTime') {
        imageSrc = features_1;
    } else if (selectedFeature === 'askQuestions') {
        imageSrc = features_2;
    } else if (selectedFeature === 'organizedThread') {
        imageSrc = features_3;
    }

    
  useEffect(() => {
    // favicon 
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = Favicon; // Usa la ruta importada directamente
    document.head.appendChild(link);

    // clean favicon 
    return () => {
      document.head.removeChild(link);
    };
  }, []);


    useEffect(() => {
	
		document.body.classList.add('dark-bg');
		return () => {
			document.body.classList.remove('dark-bg');
		};

	}, []);



    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center pt-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">Artificial Intelligence where you are the copilot</h1>
                    <p className="fl-subtitle">
                        Integrated into the core of AVAP, efficiency <br />  and productivity in API development.
                    </p>
                    <a className="glowBtn-dark fakeHide" href="#"><span className="btnInner">  Start with AVAP Cloud</span></a>


                </section>

                {/* Powerful API Platform */}
                <section className="text-center pb-5 container" style={{ marginTop: '-70px' }}>
                    <div className="row">
                        <div className="col">

                            <img
                                src={home_img}
                                alt="Platform Placeholder"
                                className="img-fluid mt-4 mb-4"
                            />
                            <h2 className="fl-subtitle">Revolutionizes AI-driven Development</h2>
                            <p className="fl-text">
                                Brunix revolutionizes AI-driven development by facilitating real-time conversations,
                                enabling users to ask questions and brainstorm collaboratively. Brunix is more than a prompt,
                                it maintains an organized discussion thread, ensuring developers can track their thought processes seamlessly.
                                By incorporating personal contexts,
                                Brunix tailors its support to each user’s unique needs, resulting in relevant recommendations.
                            </p>
                        </div>
                    </div>
                </section>

                {/* AI */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <h3 className="fl-title">AI-driven development</h3>
                            <div className="pills" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                <div
                                    className={`fl-darkButton fl-centered-btn ${selectedFeature === 'realTime' ? 'active' : ''}`}
                                    onClick={() => setSelectedFeature('realTime')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Real-time conversations
                                </div>
                                <div
                                    className={`fl-darkButton fl-centered-btn ${selectedFeature === 'askQuestions' ? 'active' : ''}`}
                                    onClick={() => setSelectedFeature('askQuestions')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Ask questions and brainstorm collaboratively
                                </div>
                                <div
                                    className={`fl-darkButton fl-centered-btn ${selectedFeature === 'organizedThread' ? 'active' : ''}`}
                                    onClick={() => setSelectedFeature('organizedThread')}
                                    style={{ cursor: 'pointer' }}
                                >
                                    Organized discussion thread
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 text-center">
                            <img
                                src={imageSrc}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <div className="w-100 fl-divider" style={{ backgroundImage: `url(${dividerbg})` }}>

                </div>

                <section className="container">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center mb-5">Artificial Intelligence is in our DNA
                            </div>
                        </div>
                    </div>
                </section>


                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-4 text-center">
                            <img
                                src={features_1}
                                alt="Contextual memory"
                                className="img-fluid"
                            />
                        </div>

                        <div className="col-md-8 pl-5 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Contextual Memory</h3>
                            <p className="fl-text">
                                Brunix maintains a rich, continuous memory of your entire development process.
                                Unlike traditional AI systems, Brunix tracks all interactions and incorporates
                                ongoing conversations, the specific AVAP context, and user-provided personal contexts.
                                This allows Brunix to deliver highly relevant, tailored support that
                                evolves with your project, providing more accurate suggestions and helping you troubleshoot more effectively.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-8 pl-5 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Seamless Integration with AVAP</h3>
                            <p className="fl-text">
                                Brunix is tightly integrated with AVAP Framework,
                                meaning it understands your specific deployment, team structures,
                                and organizational contexts. It recognizes your AVAP developer token information and personal project details,
                                allowing it to offer context-aware recommendations for both your code and the surrounding infrastructure.
                                This ensures that Brunix’s is deeply aligned with your workflow,
                                resulting in more efficient and accurate development processes.
                            </p>
                        </div>
                        <div className="col-md-4 text-center">
                            <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            />
                        </div>

                    </div>
                </section>


                <div className="bottomWrapper position-relative d-flex justify-content-center">
                <img src={gradientbottom} alt="" className="gradient gradientBottom opacity-5" />
                    <section className="container py-5 gradient-d fl-br-20 mt-5 mb-8">
                        <div className="row align-items-center">

                            <div className="col-md-4 text-center">
                                <img
                                    src={features_3}
                                    alt="Proxy Placeholder"
                                    className="img-fluid"
                                />
                            </div>

                            <div className="col-md-8 pl-5 pr-5">
                                <span className="fl-label">Key Features</span>
                                <h3 className="fl-subtitle">Predictive Coding Assistance</h3>
                                <p className="fl-text">
                                    Brunix analyzes the comments within your code and identifies patterns in your workflow.
                                    It uses this data to proactively suggest solutions, potential optimizations,
                                    or even flag potential issues before they arise. Whether you’re brainstorming or in the
                                    implementation phase, Brunix anticipates your needs, helping streamline the coding process
                                    and enabling faster, more efficient development cycles.
                                </p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default BrunixProduct;
