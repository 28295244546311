import React, { Fragment } from 'react';

const My_First_API = () => (
  <Fragment>
     <div>
    <h2>Creating My First API Project in AVAP Cloud</h2>

    <h3>API Project</h3>
    <p>
      APIs in AVAP Framework are organized into API Products. To create an API or a set of APIs, it is necessary to create an API Project. It is at the moment of publishing through the API Publisher tool that an API is associated with a new or existing API Product.
    </p>
    <p>
      Creating an API Product can be done in on-premise deployments or in cloud deployments through AVAP Cloud in any of its memberships: Professional, Business, or Enterprise.
    </p>
    <p>
      In this document, we will explain how to create an API Project in AVAP Cloud for any of its memberships: Professional, Business, or Enterprise. To create an API in an AVAP Cloud deployment, you need to have an active membership in any of its modalities.
    </p>
    <p>
      Each existing membership has a maximum number of API Project slots. A user with an active AVAP Cloud membership has a maximum number of slots for creating API Projects, a number of available slots contracted for the creation of API Projects, and a number of slots in use with created API Projects.
    </p>

    <ol>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Log in at <a href="http://www.avapcloud.com/">www.avapcloud.com</a> and access the AVAP Cloud portal.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>In the default Dashboard section, you can find the status of your API Project slots.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Go to the API Projects section.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Create your new API Project by clicking on the Add new button.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Assign a name to your API Project.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>An API Project is obligatorily tied to a database, as it is where all the information required by the project is stored persistently. At this point, the user can determine whether to use the platform’s default database or choose their own by checking the User your own database box. Currently, if the user wishes to use their own database, they must configure access to a Postgres v10 or later database server.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>If the user does not wish to use their own database, they should select from the available locations in AVAP Cloud where they want the platform to create the database.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Once the API Project has been created and initialized, the user will be able to access it and start creating APIs through any of the available IDEs (Microsoft Visual Studio Code with AVAP extensions or AVAP Dev Studio).</li>
    </ol>

    <p>
      All information related to your API Projects will automatically appear when you open your IDE, whether it is Microsoft Visual Studio Code or AVAP Dev Studio. For Microsoft Visual Studio Code, remember that it is necessary to install the AVAP extensions.
    </p>
    <p>
      You can find more information related to AVAP ID or AVAP CLI at AVAP Alexandria (<a href="http://alexandria.avapframework.com/">alexandria.avapframework.com</a>) and connect with the user community at AVAP Communities (<a href="http://communities.avapframework.com/">communities.avapframework.com</a>).
    </p>
  </div>
  </Fragment>
);
export default My_First_API;
