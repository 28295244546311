import React from 'react';
import './chapter.css'
const Chapter12_4 = () => (
  <div>
    <h2>Internal Operation</h2>
    <p>
      Internally, the <code>addParam()</code> function constructs the querystring for the API call by adding the specified parameters along with their corresponding values. This querystring is passed to the API, which uses it to process the request and return the appropriate response.
    </p>
  </div>
);

export default Chapter12_4;
