import React, { Fragment } from 'react';

const AVAP_Organizations = () => (
  <Fragment>
    <div className='body-md'>
    <h2>Getting Started with AVAP Organizations</h2>

    <h3>AVAP Organizations</h3>
    <p>
      In the AVAP Framework, the Organization entity is essential for managing and developing APIs. It facilitates the administration of resources, teams, and projects, ensuring efficient management and proper control throughout the API lifecycle. This guide provides a detailed overview of how organizations optimize project management, apply security measures, and control project ownership.
    </p>

    <h4>What is an Organization in AVAP Framework</h4>
    
    <h5>Definition</h5>
    <p>
      An Organization in the AVAP Framework is a structural unit that groups resources, teams, and projects within the platform. It allows for efficient and secure management of API development projects, as well as task and responsibility allocation.
    </p>

    <h5>Key Features</h5>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Resource Grouping:</strong>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>API Projects, API Products, and APIs:</strong> Manages and organizes various organizational resources within a cohesive unit.</li>
        </ul>
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Team Administration:</strong>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Development Teams:</strong> Create and manage teams of developers collaborating on specific projects.</li>
        </ul>
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Project Management:</strong>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>API Projects:</strong> Organizes and manages API development projects within the organization.</li>
        </ul>
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Access Control:</strong>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Permissions and Roles:</strong> Defines permissions and roles for users within the organization, ensuring controlled access to resources and projects.</li>
        </ul>
      </li>
    </ul>

    <h4>Organizational Structure in AVAP Framework</h4>
    
    <h5>Account Owner</h5>
    <p>
      <strong>Definition:</strong> The Account Owner is the user who originally created the account in the AVAP Framework.
    </p>
    <p>
      <strong>Responsibilities:</strong>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Complete control over the account and its resources.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Management of one or more organizations based on the license, membership, and/or deployment type.</li>
      </ul>
    </p>
    <p>
      <strong>Capabilities of the Account Owner:</strong>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Multiple Organizations:</strong> The owner can manage several organizations, facilitating the administration of multiple units within the platform.</li>
      </ul>
    </p>

    <h5>Hierarchy of Structure</h5>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Account Owner:</strong> Full control over the account and ability to manage multiple organizations.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Organizations:</strong> Group resources, teams, and projects.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Developers:</strong> Users assigned to teams and projects within the organization.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Development Teams:</strong> Groups of developers working together on specific projects.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>API Projects:</strong> API development projects organized and managed within the organization.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>API Products:</strong> Sets of APIs offered as products.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>APIs:</strong> Programming interfaces developed and managed within the organization.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Permissions and Roles:</strong>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Administrators:</strong> Complete management of the organization.</li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Developers:</strong> Permissions to develop and manage APIs.</li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Readers:</strong> Access to view information.</li>
        </ul>
      </li>
    </ul>

    <h5>Benefits of Organizations in API Project Management</h5>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Project Management:</strong>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Entity Structuring:</strong> Managers can create and organize API Projects within the organizational entity, facilitating centralized and orderly management.</li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Resource Assignment:</strong> Allows the assignment of developers and development teams to specific projects, optimizing planning and execution.</li>
        </ul>
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Security Application:</strong>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Access Control:</strong> Defines and manages permissions and roles to protect the integrity of projects and resources.</li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Development Security:</strong> Ensures that only authorized users can access and modify projects, maintaining a secure environment during development and deployment.</li>
        </ul>
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Project Ownership Control:</strong>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Ownership and Management:</strong> Ensures proper control of project ownership throughout all stages: development, deployment, and production.</li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Transparency and Auditing:</strong> Provides clear tracking of who has access and control over each project.</li>
        </ul>
      </li>
    </ul>

    <h5>Creating a New Organization</h5>
    <p>
      <strong>Create a New Organization (available only in AVAP Cloud Enterprise or AVAP Enterprise versions)</strong>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Action:</strong> In the AVAP Cloud Dashboard or through the AVAP Management Console for AVAP Enterprise versions, select Add Organization.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Required Information:</strong> Organization Name and Description.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Confirmation:</strong> Review the details and confirm the creation.</li>
      </ul>
    </p>

    <p>
      For more information related to working with Organizations, visit <a href="http://alexandria.avapframework.com/">AVAP Alexandria</a> and connect with the user community at <a href="http://communities.avapframework.com/">AVAP Communities</a>.
    </p>
  </div>
  </Fragment>
);
export default AVAP_Organizations;
