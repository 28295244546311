import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h4>1.4 Summary</h4>
  <p>
    Data types in AVAP™ are fundamental for program development. They allow for the storage and manipulation of different types of values, such as numbers, text, and truth values. With a solid understanding of data types and how they are used in program development, developers can create robust and functional applications in AVAP™.
  </p>
</div>
  </Fragment>
);
export default Intro_1;
