import React from 'react';
import './chapter.css'
const Chapter9_45 = () => (
    <div>
        <h2>Capture Patterns</h2>
        <p>
            In AVAP, capture patterns are used to bind the subject's value to a name. The syntax for a capture pattern is:
        </p>
        <pre><code>capture_pattern ::=  NAME</code></pre>
        <p>
            Capture patterns always succeed and bind the value of the subject to the specified name.
        </p>
        <p>
            Here’s how you might use capture patterns in AVAP:
        </p>
        <pre><code>match value:
    case x:
        print(f"Captured value: x")</code></pre>
        <p>
            In this example:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>case x:</code> captures whatever value is in <code>value</code> and binds it to the name <code>x</code>. The pattern always succeeds.</li>
        </ul>
        <p>
            Capture patterns are useful when you want to extract and use the value of the subject within your code, regardless of what that value is.
        </p>
    </div>
);

export default Chapter9_45;
