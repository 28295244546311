import React, { useState } from 'react';
import { Dropdown, Button, Row, Col } from 'react-bootstrap';
import DashLayout from "../functionalComponents/DashboardLayout/DashboardLayout";
import DashRouters from "../../configs/routers/dashboard-routers.json";

import { FaChevronUp, FaEllipsisV } from 'react-icons/fa';

interface API {
  name: string;
  id: string;
}

interface Product {
  name: string;
  apis: API[];
}

interface Environment {
  name: string;
  products: Product[];
}

interface Proxy {
  id: string;
  name: string;
  environments: Environment[];
}

const ProxyManagement: React.FC = () => {

  const [availableEnvironments] = useState(['Production', 'Testing', 'Development']);
  const [availableProducts] = useState([
    { name: 'Atlas Navigation', apis: ['Route Planner API', 'Location Lookup API'] },
    { name: 'PayFlex', apis: ['Transaction Processor API', 'Refund Manager API'] },
    { name: 'WeatherStation', apis: ['Current Weather API', 'Forecast API', 'Historical Weather Data API'] },
    { name: 'FinanceHub', apis: ['Stock Price API', 'Currency Exchange API', 'Market Trends API'] },
    { name: 'GeoExplorer', apis: ['Geolocation API', 'Map Services API', 'Route Optimization API', 'Traffic Data API'] }
  ]);

  // Initialize proxies as an empty array
  const [proxies, setProxies] = useState<Proxy[]>([]);

  // Available proxies to add
  const [availableProxies, setAvailableProxies] = useState<Proxy[]>([
    {
      id: 'proxy1',
      name: 'Proxy 1',
      environments: []
    },
    {
      id: 'proxy2',
      name: 'Proxy 2',
      environments: []
    },
    {
      id: 'proxy3',
      name: 'Proxy 3',
      environments: []
    }
  ]);

  const [visibleProxy, setVisibleProxy] = useState<Set<string>>(new Set());
  const [visibleEnvironment, setVisibleEnvironment] = useState<Record<string, Set<string>>>({});

  // Search inputs for dropdowns
  const [proxySearchInput, setProxySearchInput] = useState('');
  const [ambientSearchInputs, setAmbientSearchInputs] = useState<Record<string, string>>({});
  const [productSearchInputs, setProductSearchInputs] = useState<Record<string, Record<string, string>>>({});
  const [apiSearchInputs, setApiSearchInputs] = useState<Record<string, Record<string, Record<string, string>>>>({});

  const handleToggleProxyVisibility = (proxyId: string) => {
    setVisibleProxy((prevState) => {
      const newState = new Set(prevState);
      if (newState.has(proxyId)) {
        newState.delete(proxyId);
      } else {
        newState.add(proxyId);
      }
      return newState;
    });
  };

  const handleToggleEnvironmentVisibility = (proxyId: string, environmentName: string) => {
    setVisibleEnvironment((prevState) => {
      const proxyEnvironments = prevState[proxyId] || new Set();
      const newProxyEnvironments = new Set(proxyEnvironments);
      if (newProxyEnvironments.has(environmentName)) {
        newProxyEnvironments.delete(environmentName);
      } else {
        newProxyEnvironments.add(environmentName);
      }
      return { ...prevState, [proxyId]: newProxyEnvironments };
    });
  };

  const handleAddEnvironment = (proxyId: string, environmentName: string) => {
    setProxies(prevProxies =>
      prevProxies.map(proxy =>
        proxy.id === proxyId
          ? {
            ...proxy,
            environments: [...proxy.environments, { name: environmentName, products: [] }]
          }
          : proxy
      )
    );
    // Expand the proxy
    setVisibleProxy(prevVisibleProxy => new Set(prevVisibleProxy).add(proxyId));
  };

  const handleDeleteEnvironment = (proxyId: string, environmentName: string) => {
    setProxies(prevProxies =>
      prevProxies.map(proxy =>
        proxy.id === proxyId
          ? {
            ...proxy,
            environments: proxy.environments.filter(env => env.name !== environmentName)
          }
          : proxy
      )
    );
  };

  const handleAddProduct = (proxyId: string, environmentName: string, productName: string) => {
    setProxies(prevProxies =>
      prevProxies.map(proxy =>
        proxy.id === proxyId
          ? {
            ...proxy,
            environments: proxy.environments.map(env =>
              env.name === environmentName
                ? {
                  ...env,
                  products: [...env.products, { name: productName, apis: [] }]
                }
                : env
            )
          }
          : proxy
      )
    );
    // Expand the proxy and environment
    setVisibleProxy(prevVisibleProxy => new Set(prevVisibleProxy).add(proxyId));
    setVisibleEnvironment(prevVisibleEnv => {
      const envSet = prevVisibleEnv[proxyId] || new Set();
      envSet.add(environmentName);
      return { ...prevVisibleEnv, [proxyId]: envSet };
    });
  };

  const handleDeleteProduct = (proxyId: string, environmentName: string, productName: string) => {
    setProxies(prevProxies =>
      prevProxies.map(proxy =>
        proxy.id === proxyId
          ? {
            ...proxy,
            environments: proxy.environments.map(env =>
              env.name === environmentName
                ? {
                  ...env,
                  products: env.products.filter(product => product.name !== productName)
                }
                : env
            )
          }
          : proxy
      )
    );
  };

  const handleAddAPI = (proxyId: string, environmentName: string, productName: string, apiName: string) => {
    setProxies(prevProxies =>
      prevProxies.map(proxy =>
        proxy.id === proxyId
          ? {
            ...proxy,
            environments: proxy.environments.map(env =>
              env.name === environmentName
                ? {
                  ...env,
                  products: env.products.map(product =>
                    product.name === productName
                      ? {
                        ...product,
                        apis: product.apis.some(api => api.name === apiName)
                          ? product.apis // Do not add if already exists
                          : [...product.apis, { name: apiName, id: apiName.toLowerCase().replace(/\s+/g, '-') }]
                      }
                      : product
                  )
                }
                : env
            )
          }
          : proxy
      )
    );
  };

  const handleDeleteAPI = (proxyId: string, environmentName: string, productName: string, apiId: string) => {
    setProxies(prevProxies =>
      prevProxies.map(proxy =>
        proxy.id === proxyId
          ? {
            ...proxy,
            environments: proxy.environments.map(env =>
              env.name === environmentName
                ? {
                  ...env,
                  products: env.products.map(product =>
                    product.name === productName
                      ? {
                        ...product,
                        apis: product.apis.filter(api => api.id !== apiId)
                      }
                      : product
                  )
                }
                : env
            )
          }
          : proxy
      )
    );
  };

  const handleAddAllAPIs = (proxyId: string, environmentName: string, productName: string) => {
    const productApis = availableProducts.find(p => p.name === productName)?.apis || [];
    setProxies(prevProxies =>
      prevProxies.map(proxy =>
        proxy.id === proxyId
          ? {
            ...proxy,
            environments: proxy.environments.map(env =>
              env.name === environmentName
                ? {
                  ...env,
                  products: env.products.map(product =>
                    product.name === productName
                      ? {
                        ...product,
                        apis: [
                          ...product.apis,
                          ...productApis
                            .filter(apiName => !product.apis.some(api => api.name === apiName))
                            .map(apiName => ({ name: apiName, id: apiName.toLowerCase().replace(/\s+/g, '-') }))
                        ]
                      }
                      : product
                  )
                }
                : env
            )
          }
          : proxy
      )
    );
  };

  const getAvailableApis = (productName: string, environment: Environment) => {
    const product = environment.products.find(p => p.name === productName);
    if (!product) return [];

    const allApisInUse = [].concat(...environment.products.map(p => p.apis.map(api => api.name)));

    return availableProducts
      .find(p => p.name === productName)
      ?.apis.filter(apiName => !allApisInUse.includes(apiName)) || [];
  };

  // Function to handle adding a new proxy from available proxies
  const handleAddProxy = (proxyToAdd: Proxy) => {
    setProxies([...proxies, proxyToAdd]);
    setAvailableProxies(availableProxies.filter(p => p.id !== proxyToAdd.id));
    setProxySearchInput(''); // Reset search input
  };

  // Function to handle deleting a proxy
  const handleDeleteProxy = (proxyId: string) => {
    const deletedProxy = proxies.find(proxy => proxy.id === proxyId);
    setProxies(prevProxies => prevProxies.filter(proxy => proxy.id !== proxyId));
    if (deletedProxy) {
      setAvailableProxies([...availableProxies, deletedProxy]);
    }
  };

  return (
    <DashLayout sider={DashRouters} active="proxies" sublinks={[]}>
      <Row id="topHeader">
        <Col md="10">
          <div className="cld_wrapperTitle ">
            <span className="cld_title w-100 margin-y-24px">Proxies Management</span>
          </div>
        </Col>

        <Col md='2' className=' align-content-center'>
          <Dropdown className=''>
            <Dropdown.Toggle variant="primary"
              className='customButton-blue px-4 float-right resBtn text-white'
              style={{ width: '225px' }}>
              Add Proxy
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <div className="dropdown-search-input" style={{ padding: '8px' }}>
                <input
                  type="text"
                  placeholder="Search..."
                  value={proxySearchInput}
                  onChange={(e) => setProxySearchInput(e.target.value)}
                  onClick={(e) => e.stopPropagation()} // prevent closing the dropdown when clicking on input
                  style={{ width: '100%', padding: '4px' }}
                />
              </div>
              {availableProxies.filter(proxy => proxy.name.toLowerCase().includes(proxySearchInput.toLowerCase())).length === 0 ? (
                <Dropdown.Item disabled>No proxies available</Dropdown.Item>
              ) : (
                availableProxies.filter(proxy => proxy.name.toLowerCase().includes(proxySearchInput.toLowerCase())).map((proxy) => (
                  <Dropdown.Item
                    key={proxy.id}
                    onClick={() => handleAddProxy(proxy)}
                  >
                    {proxy.name}
                  </Dropdown.Item>
                ))
              )}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      <Row className='mt-5'>
        <Col>
          <div className="proxies-container">
            {proxies.map((proxy) => (
              <div key={proxy.id} className="proxy-card">
                <div className="proxy-header">
                  <span className="proxy-title">{proxy.name}</span>
                  <div className="wrapper d-flex align-items-center">
                    <Dropdown>
                      <Dropdown.Toggle variant='primary' className="customLink">
                        <FaEllipsisV size="16px" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={(event) => {
                            event.preventDefault(); // Prevents the dropdown from closing
                          }}
                          as="div"
                          style={{ cursor: 'default' }}
                          className='transparentItem'
                        >
                          Add Ambient
                          <div className="dropdown-search-input" style={{ padding: '8px' }}>
                            <input
                              type="text"
                              placeholder="Search..."
                              value={ambientSearchInputs[proxy.id] || ''}
                              onChange={(e) => {
                                setAmbientSearchInputs({
                                  ...ambientSearchInputs,
                                  [proxy.id]: e.target.value
                                });
                              }}
                              onClick={(e) => e.stopPropagation()} // prevent closing the dropdown when clicking on input
                              style={{ width: '100%', padding: '4px' }}
                            />
                          </div>
                          <div className="overflowList">
                            {availableEnvironments
                              .filter(env =>
                                !proxy.environments.some(e => e.name === env) &&
                                env.toLowerCase().includes((ambientSearchInputs[proxy.id] || '').toLowerCase())
                              )
                              .map((env) => (
                                <Dropdown.Item
                                  key={env}
                                  onClick={() => {
                                    handleAddEnvironment(proxy.id, env);
                                    setAmbientSearchInputs(prev => ({ ...prev, [proxy.id]: '' })); // reset search input after adding
                                  }}
                                >
                                  {env}
                                </Dropdown.Item>
                              ))
                            }
                          </div>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item
                          onClick={() => handleDeleteProxy(proxy.id)}
                        >
                          Delete Proxy
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <div
                      className='toggle ml-3 cursor-pointer'
                      onClick={() => handleToggleProxyVisibility(proxy.id)}
                      style={{
                        transition: 'transform 0.3s ease', // Animación de rotación
                        transform: visibleProxy.has(proxy.id) ? 'rotate(180deg)' : 'rotate(90deg)',
                      }}
                    >
                      <FaChevronUp size="16px" />
                    </div>
                  </div>
                </div>

                {visibleProxy.has(proxy.id) && (
                  <div>
                    {proxy.environments.length === 0 ? (
                      <p className="no-config text-gray-500">No environments configured</p>
                    ) : (
                      proxy.environments.map((env) => (
                        <div key={env.name} className="environment-container">
                          <div className="environment-header">
                            <div className='mainWrapper'>
                              <div className="wrapper">
                                <div className="envLabel">Ambient  <span className="envName">{env.name}</span></div>
                              </div>
                              <div className='d-flex'>

                                <Dropdown>
                                  <Dropdown.Toggle variant='link' className="customLink pl-3">
                                    <FaEllipsisV size="16px" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      onClick={(event) => {
                                        event.preventDefault(); // Prevents the dropdown from closing
                                      }}
                                      as="div"
                                      style={{ cursor: 'default' }}
                                      className='transparentItem'
                                    >
                                      Add Product
                                      <div className="dropdown-search-input" style={{ padding: '8px' }}>
                                        <input
                                          type="text"
                                          placeholder="Search..."
                                          value={productSearchInputs[proxy.id]?.[env.name] || ''}
                                          onChange={(e) => {
                                            setProductSearchInputs(prev => ({
                                              ...prev,
                                              [proxy.id]: {
                                                ...prev[proxy.id],
                                                [env.name]: e.target.value
                                              }
                                            }));
                                          }}
                                          onClick={(e) => e.stopPropagation()} // prevent closing the dropdown when clicking on input
                                          style={{ width: '100%', padding: '4px' }}
                                        />
                                      </div>
                                      <div className=" overflowList">
                                        {availableProducts
                                          .filter(product =>
                                            !env.products.some(p => p.name === product.name) &&
                                            product.name.toLowerCase().includes((productSearchInputs[proxy.id]?.[env.name] || '').toLowerCase())
                                          )
                                          .map((product) => (
                                            <Dropdown.Item
                                              key={product.name}
                                              onClick={() => {
                                                handleAddProduct(proxy.id, env.name, product.name);
                                                setProductSearchInputs(prev => ({
                                                  ...prev,
                                                  [proxy.id]: {
                                                    ...prev[proxy.id],
                                                    [env.name]: ''
                                                  }
                                                }));
                                              }}
                                            >
                                              {product.name}
                                            </Dropdown.Item>
                                          ))
                                        }
                                      </div>
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item
                                      onClick={() => handleDeleteEnvironment(proxy.id, env.name)}
                                    >
                                      Delete Ambient
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>

                                <div
                                  className='toggle ml-3 cursor-pointer'
                                  onClick={() => handleToggleEnvironmentVisibility(proxy.id, env.name)}
                                  style={{
                                    transition: 'transform 0.3s ease', // Animación de rotación
                                    transform: visibleEnvironment[proxy.id]?.has(env.name) ? 'rotate(180deg)' : 'rotate(90deg)',
                                  }}
                                >
                                  <FaChevronUp size="16px" />
                                </div>
                              </div>
                            </div>

                            {visibleEnvironment[proxy.id]?.has(env.name) && (
                              <div className="scrollWrapper">
                                {env.products.map((product) => (
                                  <div key={product.name} className="product-container">
                                    <div className="product-header flex justify-between items-center">
                                      <div className="wrapper">
                                        <div className="envLabel">Product</div>
                                        <h6 className="info">{product.name}</h6>
                                      </div>
                                    </div>

                                    <div>
                                      <ul className="api-list">
                                        <li className='envLabel'>API</li>
                                        {product.apis.map((api) => (
                                          <li key={api.id} className="api-item">
                                            <span>{api.name}</span>
                                            <Button
                                              variant="outline-danger"
                                              size="sm"
                                              onClick={() => handleDeleteAPI(proxy.id, env.name, product.name, api.id)}
                                              className="bg-transparent hover:bg-red-500 hover:text-white text-red-500 font-medium py-1 px-2 rounded"
                                            >
                                              X
                                            </Button>
                                          </li>
                                        ))}
                                      </ul>
                                      <Dropdown>
                                        <Dropdown.Toggle className="customLink">
                                          + Add API
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                          <div className="dropdown-search-input" style={{ padding: '8px' }}>
                                            <input
                                              type="text"
                                              placeholder="Search..."
                                              value={apiSearchInputs[proxy.id]?.[env.name]?.[product.name] || ''}
                                              onChange={(e) => {
                                                setApiSearchInputs(prev => ({
                                                  ...prev,
                                                  [proxy.id]: {
                                                    ...prev[proxy.id],
                                                    [env.name]: {
                                                      ...prev[proxy.id]?.[env.name],
                                                      [product.name]: e.target.value
                                                    }
                                                  }
                                                }));
                                              }}
                                              onClick={(e) => e.stopPropagation()} // prevent closing the dropdown when clicking on input
                                              style={{ width: '100%', padding: '4px' }}
                                            />
                                          </div>
                                          {(() => {
                                            const availableApis = getAvailableApis(product.name, env);
                                            const apiSearchInput = apiSearchInputs[proxy.id]?.[env.name]?.[product.name] || '';
                                            const filteredApis = availableApis.filter(apiName =>
                                              apiName.toLowerCase().includes(apiSearchInput.toLowerCase())
                                            );

                                            if (filteredApis.length > 1) {
                                              return (
                                                <Dropdown.Item
                                                  onClick={() => {
                                                    handleAddAllAPIs(proxy.id, env.name, product.name);
                                                    setApiSearchInputs(prev => ({
                                                      ...prev,
                                                      [proxy.id]: {
                                                        ...prev[proxy.id],
                                                        [env.name]: {
                                                          ...prev[proxy.id]?.[env.name],
                                                          [product.name]: ''
                                                        }
                                                      }
                                                    }));
                                                  }}
                                                >
                                                  Add All APIs
                                                </Dropdown.Item>
                                              );
                                            }

                                            if (filteredApis.length === 0) {
                                              return <Dropdown.Item disabled>No APIs available</Dropdown.Item>;
                                            }

                                            return filteredApis.map((apiName) => (
                                              <Dropdown.Item
                                                key={apiName}
                                                onClick={() => {
                                                  handleAddAPI(proxy.id, env.name, product.name, apiName);
                                                  setApiSearchInputs(prev => ({
                                                    ...prev,
                                                    [proxy.id]: {
                                                      ...prev[proxy.id],
                                                      [env.name]: {
                                                        ...prev[proxy.id]?.[env.name],
                                                        [product.name]: ''
                                                      }
                                                    }
                                                  }));
                                                }}
                                                className="hover:bg-gray-200 rounded"
                                              >
                                                {apiName}
                                              </Dropdown.Item>
                                            ));
                                          })()}
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>

                                    <Dropdown className='dropdowncustomPosition'>
                                      <Dropdown.Toggle variant='link' className="customLink">
                                        <FaEllipsisV size="16px" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() => handleDeleteProduct(proxy.id, env.name, product.name)}
                                        >
                                          Delete Product
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </DashLayout>
  );
};

export default ProxyManagement;
