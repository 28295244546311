import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_6 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>getDateTime()</h2>
      <p>
        The <code>getDateTime()</code> command retrieves the current date and time, formats it according to a specified format, applies an optional time adjustment, and converts it to a specific time zone before storing the result in a target variable. It is useful for obtaining and manipulating the current date and time in different formats and time zones.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Format</strong> <br />
          Type: <code>var</code> <br />
          Description: A format string that defines how the resulting date and time should be presented. This string follows the date and time formatting conventions used in Python. Some of the most common symbols include:
          <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>%Y</code>: Year with four digits (e.g., 2024)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>%m</code>: Month with two digits (01 to 12)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>%d</code>: Day of the month with two digits (01 to 31)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>%H</code>: Hour in 24-hour format (00 to 23)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>%M</code>: Minutes (00 to 59)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>%S</code>: Seconds (00 to 59)</li>
          </ul>
          For example, the format <code>"%Y-%m-%d %H:%M:%S"</code> will present the date and time as <code>2024-08-25 14:30:00</code>. It can be a direct value or a variable containing the desired format.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>TimeDelta</strong> <br />
          Type: <code>var</code> <br />
          Description: An optional value representing a time adjustment (positive or negative) applied to the current date and time before conversion. This value can be provided directly or through a variable and is expressed in seconds.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>TimeZone</strong> <br />
          Type: <code>var</code> <br />
          Description: The time zone to which the date and time should be converted. This value can be a time zone identifier provided directly or through a variable. Some common time zones include:
          <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>"UTC"</code>: Coordinated Universal Time</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>"America/New_York"</code>: U.S. Eastern Time (EST/EDT)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>"America/Los_Angeles"</code>: U.S. Pacific Time (PST/PDT)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>"Europe/London"</code>: London Time (GMT/BST)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>"Europe/Madrid"</code>: Madrid Time (CET/CEST)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>"Asia/Tokyo"</code>: Tokyo Time (JST)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>"Australia/Sydney"</code>: Sydney Time (AEST/AEDT)</li>
          </ul>
          You can use any time zone recognized by the <code>pytz</code> library in Python, which includes most time zones worldwide.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>TargetVariable</strong> <br />
          Type: <code>var</code> <br />
          Description: The variable in which the resulting date and time from the operation will be stored. Unlike the other parameters, this must be a variable and not a direct value.
        </li>
      </ul>
      <h3>Usage Example</h3>
      <pre>
        <code>
          {`// Direct call with values:
            getDateTime('%Y-%m-%d %H:%M:%S', 3600, 'UTC', currentTime)

            // Call using variables:
            format = '%Y-%m-%d %H:%M:%S'
            adjustment = 3600
            timeZone = 'America/New_York'
            getDateTime(format, adjustment, timeZone, currentDateTime)`}
        </code>
      </pre>
      <p>
        In the first example, the current date and time are retrieved, adjusted by 3600 seconds (1 hour), converted to UTC, and stored in the variable <code>currentTime</code>. In the second example, variables are used to define the format, time adjustment, and time zone, with the result stored in the <code>currentDateTime</code> variable.
      </p>
    </div>
  </Fragment>
);

export default Appendices_6;
