import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../components/DisplayCodeKo/DisplayCodeKo';

const Obtener_datos_usuario_EN = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
          "status": <boolean>,
          "certified": <boolean>,
          "curp": <string>,
          "place_of_birth": <string>,
          "nationality": <string>,
          "certification_data": {},
          "surname2": <string>,
          "surname1": <string>,
          "language_id_language": <string>,
          "sexo": <integer>,
          "state": <string>,
          "id": <string>,
          "address": <string>,
          "date_of_birth": <string>,
          "channel_id": <string>,
          "ocupation": <integer>,
          "nick": <string>,
          "private_key": <string>,
          "email": <string>,
          "rfc": <string>,
          "alias": <string>,
          "name": <string>,
          "language": <string>,
          "telefono": <string>,
          "elapsed": <float>
      }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
    url = "URL_BASE/ws/users.py/data?country_code=MX&user_id=2&session_id=393--ZCAQsFyJF95Q2YpqRNLug=="
    payload = {}
    headers= {}
    response = requests.request("GET", url, headers=headers, data = payload)
    print(response.text.encode('utf8'))`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
    url = "URL_BASE/ws/users.py/data?country_code=MX&user_id=2&session_id=393--ZCAQsFyJF95Q2YpqRNLug=="
    payload = {}
    headers= {}
    response = requests.request("GET", url, headers=headers, data = payload)
    print(response.text.encode('utf8'))`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'GET',
  'url': 'URL_BASE/ws/users.py/data?country_code=MX&user_id=2&session_id=393--ZCAQsFyJF95Q2YpqRNLug==',
  'headers': {
  }
  };
  request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
  });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var requestOptions = {
  method: 'GET',
  redirect: 'follow'
  };
  fetch("URL_BASE/ws/users.py/data?country_code=MX&user_id=2&session_id=393--ZCAQsFyJF95Q2YpqRNLug==", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request GET 'URL_BASE/ws/users.py/data?country_code=MX&user_id=2&session_id=393--ZCAQsFyJF95Q2YpqRNLug%3D%3D'`
      }
    ]
  }

  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >Obtain User Data</h1>
          <p>
            This service is used to obtain the data of the user logged into the
            system.
          </p>
          <p>
            <span className="label get">GET:</span>
            <span> </span> <code>URL_BASE + /ws/users.py/data</code>
          </p>
          <h2 >Receives:</h2>
          <p>
            All parameters are sent in the querystring of the call, so a percentage
            encoding for URI must be applied &#40;aka URL encoding&#41;.
          </p>
          <table>
            <thead>
              <tr>
                <th align="left">Parameter</th>
                <th align="center">Format</th>
                <th align="center">Obligatory</th>
                <th align="center">Description</th>
                <th align="center">Default value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="left">country_code</td>
                <td align="center">
                  <a href="https://www.iso.org/glossary-for-iso-3166.html">
                    ISO 3166 alpha-2
                  </a>
                </td>
                <td align="center">Yes</td>
                <td align="center">Country code in which the user resides</td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">user_id</td>
                <td align="center">Integer</td>
                <td align="center">Yes</td>
                <td align="center">Identifier of the user making the request</td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">session_id</td>
                <td align="center">String</td>
                <td align="center">
                  Yes &#40;if you do not send signature to &#39;Access
                  Service&#39;&#41;
                </td>
                <td align="center">
                  Obtained by identifying the user with ID user_id on the platform
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">signature</td>
                <td align="center">String</td>
                <td align="center">
                  Yes &#40;if you do not send session_id to &#39;Access
                  Service&#39;&#41;
                </td>
                <td align="center">
                  Enables you to sign the request without having to log in
                </td>
                <td align="center">N/A</td>
              </tr>
            </tbody>
          </table>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>
          <h3 >Answer JSON OK:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2 >Where:</h2>
          <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>certified:</code> Shows if the user is certified &#40;true&#41; or
              not &#40;false&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>curp:</code> Unique Population Registry Code.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>place_of_birth:</code> User&#39;s birth Place.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>nationality:</code> User&#39;s birth state.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>certification_data:</code> JSON with the user&#39;s certified data
              &#40;not modifiable&#41;
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>surname2:</code> User&#39;s maternal last name.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>surname1:</code> User&#39;s paternal last name.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>language_id_language:</code> User language identifier.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>state:</code> Name of the user&#39;s state of residence.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>id:</code> User identifier.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>address:</code> User address.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>date_of_birth:</code> Date in format dd/mm/YYYY.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>channel_id:</code> Channel from which the request is made.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>ocupation:</code> Labor sector in which the user is.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>nick:</code> User name.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>private_key:</code> Secret password of the user necessary to
              access the platform without logging in.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>email:</code>User email address.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>rfc:</code> Federal Taxpayer Registry.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>alias:</code> User name.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>name:</code> User name.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>language:</code> User language name.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>telefono:</code> User phone number.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>elapsed:</code> Operation execution time.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Business logic:</h2>
          <p>
            By means of this service the data of the user logged into the system is
            obtained.
          </p>
        </div>

        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>
        
        </div>
      </div>
    </Fragment>
  );
}
export default Obtener_datos_usuario_EN;
