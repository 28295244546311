import React from 'react';
import './chapter.css'
const Chapter9_35 = () => (
    <div>
        <h2>Continue Statement</h2>
        <p>
            In AVAP, the <strong>continue</strong> statement is used to proceed with the next iteration of the closest enclosing loop. The syntax for the <strong>continue</strong> statement is as follows:
        </p>
        <pre>
            <code>
                continue
            </code>
        </pre>
        <p>
            The <strong>continue</strong> statement can only syntactically occur nested within a <code>for</code> or <code>while</code> loop, but not within a function or class definition inside that loop.
        </p>
        <p>
            When <strong>continue</strong> is used within a loop that is also handling exceptions with a <code>try</code> statement containing a <code>finally</code> clause, the <code>finally</code> clause is executed before the next iteration of the loop begins.
        </p>
        <pre>
            <code>
                for i in range(10):
                    try:
                        if i % 2 == 0:
                            continue
                        print(i)
                    finally:
                        print("In finally clause")
                print("Loop ended")
            </code>
        </pre>
        <p>
            In this example, the <code>continue</code> statement will skip the current iteration when <code>i</code> is even, but before moving to the next iteration, the <code>finally</code> clause will print "In finally clause." For odd numbers, the loop will print the number and then "In finally clause." After the loop finishes, "Loop ended" will be printed.
        </p>
    </div>
);

export default Chapter9_35;
