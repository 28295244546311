import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../components/DisplayCodeKo/DisplayCodeKo';

const Repetir_transaccion_EN = () => {


  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status": true,
        "signed": <boolean>,
        "invoice": <integer>,
        "currency_data": {
            "abbreviation": <string>,
            "suffix": <string>,
            "format": <string>,
            "symbol": <string>,
            "prefix": <string>,
            "decimals": <decimal>,
            "id": <integer>
        },
        "elapsed": <float>,
        "raw_amount": <decimal>,
        "currenncy": <string>,
        "commission_user_card": <decimal>,
        "codtran": <string>,
        "user_commission": <decimal>,
        "amount": <string>
    }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
    url = "URL_BASE/ws/movimientos.py/repetirtransaccion"
    payload = {'country_code': 'MX',
    'user_id': '4532',
    'session_id': '385-XadgYoWixbzN_wqWc3Yyog==',
    'codtran': '977fe3d70fe0f0ead1060c2ef2292276'}
    files = [
    ]
    headers= {}
    response = requests.request("POST", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
    url = "URL_BASE/ws/movimientos.py/repetirtransaccion"
    payload = {'country_code': 'MX',
    'user_id': '4532',
    'session_id': '385-XadgYoWixbzN_wqWc3Yyog==',
    'codtran': '977fe3d70fe0f0ead1060c2ef2292276'}
    files = [
    ]
    headers= {}
    response = requests.request("POST", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'POST',
  'url': 'URL_BASE/ws/movimientos.py/repetirtransaccion',
  'headers': {
  },
  formData: {
  'country_code': 'MX',
  'user_id': '4532',
  'session_id': '385-XadgYoWixbzN_wqWc3Yyog==',
  'codtran': '977fe3d70fe0f0ead1060c2ef2292276'
  }
  };
  request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
  }); `
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var formdata = new FormData();
  formdata.append("country_code", "MX");
  formdata.append("user_id", "4532");
  formdata.append("session_id", "385-XadgYoWixbzN_wqWc3Yyog==");
  formdata.append("codtran", "977fe3d70fe0f0ead1060c2ef2292276");
  var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
  };
  fetch("URL_BASE/ws/movimientos.py/repetirtransaccion", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request POST 'URL_BASE/ws/movimientos.py/repetirtransaccion' 
  --form 'country_code=MX' 
  --form 'user_id=4532' 
  --form 'session_id=385-XadgYoWixbzN_wqWc3Yyog==' 
  --form 'codtran=977fe3d70fe0f0ead1060c2ef2292276'`
      }
    ]
  }

  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 id="repeat-transaction">Repeat Transaction</h1>
          <p>
            This service is used to repeat a transaction already made by the user.
          </p>
          <p>
            <span className="label get">GET:</span>
            <span> </span>{' '}
            <code>URL_BASE + /ws/movimientos.py/repetirtransaccion</code>
          </p>
          <h2 id="receives">Receives:</h2>
          <p>
            All the parameters that the service receives must be indicated in the body
            of the request.
          </p>
          <table>
            <thead>
              <tr>
                <th align="left">Parameter</th>
                <th align="center">Format</th>
                <th align="center">Obligatory</th>
                <th align="center">Description</th>
                <th align="center">Default value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="left">country_code</td>
                <td align="center">
                  <a href="https://www.iso.org/glossary-for-iso-3166.html">
                    ISO 3166 alpha-2
                  </a>
                </td>
                <td align="center">Yes</td>
                <td align="center">Country code in which the user resides</td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">user_id</td>
                <td align="center">Integer</td>
                <td align="center">Yes</td>
                <td align="center">
                  Identifier of the user making the request &#40;Admin or
                  Affiliate&#41;
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">session_id</td>
                <td align="center">String</td>
                <td align="center">
                  Yes &#40;if you do not send signature to &#39;Access
                  Service&#39;&#41;
                </td>
                <td align="center">
                  Obtained by identifying the user with ID user_id on the platform
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">firma</td>
                <td align="center">String</td>
                <td align="center">
                  Yes &#40;if you do not send session_id to &#39;Access
                  Service&#39;&#41;
                </td>
                <td align="center">
                  Enables you to sign the request without having to log in
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">codtran</td>
                <td align="center">String</td>
                <td align="center">
                  Yes &#40;if you do not send the phone number of the user signing the
                  operation&#41;
                </td>
                <td align="center">Code of the operation to sign</td>
                <td align="center">N/A</td>
              </tr>
            </tbody>
          </table>
          <h2 id="returns">Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>
          <h3 id="answer-json-ok">Answer JSON OK:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h3 id="where">Where:</h3>
          <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>signed:</code> Shows if the signature has been correctly processed
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>invoice:</code> Invoice number associated to the transaction.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>currency_data:</code> Contains the different currency details used
              in the operation.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>abbreviation:</code> The abbreviated name of the currency
              &#40;EUR, MXN&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>suffix:</code> Indicates the suffix that is applied in the
              currency format &#40;pesos, euros&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>format:</code> The full format that is applied to the currency, it
              includes the suffix and the prefix.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>symbol:</code> The symbol associated to the currency &#40;€, ¢,
              $&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>prefix:</code> The prefix that is applied in the currency format
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>decimals:</code> The maximum number of decimal places to be
              included in the currency format.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>id:</code> Currency identifier in BBDD.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>elapsed:</code>Operation execution time.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>raw_amount:</code> Amount of the operation in negative without
              applying the format.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>currenncy:</code> Short name of the currency used in the
              operation, it matches the abbreviation.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>commission_user_card:</code> Commission that would be applied if
              the payment is made by card.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>codtran:</code> Transaction code that identifies the executed
              operation.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>user_commission:</code> Commission that will be applied to the
              user.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>amount:</code> Amount of the operation in negative with the format
              applying.
            </li>
          </ul>
          <h3 id="answer-json-ko">Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 id="business-logic">Business logic:</h2>
          <p>
            The transaction indicated with codtran is repeated, provided that in the
            detail of the user&#39;s activity it is indicated that its repetition is
            possible.
          </p>

        </div>
        <div className='col-md-5'>
          <h2 id="example-request">Example request:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>

      </div>

    </Fragment>
  );
}
export default Repetir_transaccion_EN;
