import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h2>Practical Example</h2>
  <p>
    Below is a practical example that illustrates the use of the data model in AVAP™:
  </p>
  <pre>
    <code>
{`
# Definition of a list
example_list = [1, 2, 3, 4, 5]

# Accessing individual elements
print(example_list[0])  # Output: 1

# Slicing to get a sublist
sublist = example_list[2:4]
print(sublist)  # Output: [3, 4]

# List methods
example_list.append(6)
print(example_list)  # Output: [1, 2, 3, 4, 5, 6]
    `}
    </code>
  </pre>
</div>
  </Fragment>
);
export default Intro_1;
