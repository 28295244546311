import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';

const InviteUserForm = () => {
    const [formData, setFormData] = useState({
        email: '',
        selectedTeams: [],
        filter: '',
    });

    // Sample list of teams
    const availableTeams = ['Engineering', 'Product', 'Marketing', 'Sales'];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleTeamSelection = (team) => {
        setFormData((prevData) => {
            const selectedTeams = prevData.selectedTeams.includes(team)
                ? prevData.selectedTeams.filter((t) => t !== team)
                : [...prevData.selectedTeams, team];
            return { ...prevData, selectedTeams };
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("User Invitation Data Submitted:", formData);
        // Aquí puedes añadir la lógica de envío al backend
    };

    const handleCancel = () => {
        setFormData({
            email: '',
            selectedTeams: [],
            filter: '',
        });
    };

    const filteredTeams = availableTeams.filter((team) =>
        team.toLowerCase().includes(formData.filter.toLowerCase())
    );

    return (
        <Form className="cloudForm" onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
            <h2>Invite a New User</h2>
            <p>Provide an email address to invite a user to your organization. Upon inviting, we will send an invitation email with an account creation link expiring in 72 hours.</p>

            {/* Email Field */}
            <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter an email address"
                    required
                />
            </Form.Group>

            {/* Filter Field */}
            <Form.Group controlId="filter">
                <Form.Label>Team</Form.Label>
                <Form.Control
                    type="text"
                    name="filter"
                    value={formData.filter}
                    onChange={handleChange}
                    placeholder="Filter by team name"
                />
            </Form.Group>

            {/* Teams Selection */}
            <Form.Group controlId="selectedTeams">
                <Form.Label>Select one or more teams to add this user to for access</Form.Label>
                <div className="team-options">
                    {filteredTeams.map((team) => (
                        <Form.Check
                            type="checkbox"
                            label={team}
                            key={team}
                            onChange={() => handleTeamSelection(team)}
                            checked={formData.selectedTeams.includes(team)}
                        />
                    ))}
                </div>
            </Form.Group>

            {/* Fixed Buttons */}
            <div className="d-flex justify-content-between mt-4">
                <Button variant="primary" type="submit">Send Invitation</Button>
                <Button variant="outline-secondary" onClick={handleCancel}>Cancel</Button>
            </div>
        </Form>
    );
};

export default InviteUserForm;
