import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_7 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>encodeMD5()</h2>
      <p>
        The <code>encodeMD5()</code> command generates an MD5 hash of the provided string and stores the result in a target variable. MD5 is a cryptographic hash function that produces a 128-bit value (32 hexadecimal characters), commonly used to verify data integrity.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>SourceVariable</strong> <br />
          Type: <code>var</code> <br />
          Description: The variable containing the text string to be encoded in MD5. It can be a direct value or a variable storing the input string.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>TargetVariable</strong> <br />
          Type: <code>var</code> <br />
          Description: The variable in which the resulting MD5 hash will be stored. Unlike the <code>SourceVariable</code> parameter, this must be a variable and not a direct value.
        </li>
      </ul>
      <h3>Usage Example</h3>
      <pre>
        <code>
          {`// Direct call with values:
encodeMD5('example_string', md5Hash)

// Call using variables:
text = 'example_string'
hashVariable = 'md5Hash'
encodeMD5(text, hashVariable)`}
        </code>
      </pre>
      <p>
        In the first example, an MD5 hash is generated from the string <code>'example_string'</code> and stored in the <code>md5Hash</code> variable. In the second example, a variable <code>text</code> is used to define the input string and another variable <code>hashVariable</code> is used to store the resulting MD5 hash.
      </p>
    </div>
  </Fragment>
);

export default Appendices_7;
