import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../components/DisplayCodeKo/DisplayCodeKo';

const Orden_de_cargo_EN = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status": true,
        "signed": <boolean>,
        "invoice": <integer>,
        "currency_data": {
            "abbreviation": <string>,
            "suffix": <string>,
            "format": <string>,
            "symbol": <string>,
            "prefix": <string>,
            "decimals": <decimal>,
            "id": <integer>
        },
        "elapsed": <float>,
        "raw_amount": <decimal>,
        "currency": <string>,
        "commission_user_card": <decimal>,
        "codtran": <string>,
        "user_commission": <decimal>,
        "amount": <string>
    }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
    url = "URL_BASE/ws/ws_soluciones.py/ordendecargo"
    payload = {'country_code': 'MX',
    'user_id': '2',
    'session_id': '441-pmS-Jeol-4gqHpLtVcB6mA==',
    'concept': 'test charge',
    'solution_id': '8',
    'destinatary_id': '78',
    'amount': '10'}
    files = []
    headers= {}
    response = requests.request("POST", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
    url = "URL_BASE/ws/ws_soluciones.py/ordendecargo"
    payload = {'country_code': 'MX',
    'user_id': '2',
    'session_id': '441-pmS-Jeol-4gqHpLtVcB6mA==',
    'concept': 'test charge',
    'solution_id': '8',
    'destinatary_id': '78',
    'amount': '10'}
    files = []
    headers= {}
    response = requests.request("POST", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'POST',
  'url': 'URL_BASE/ws/ws_soluciones.py/ordendecargo',
  'headers': {},
  formData: {
  'country_code': 'MX',
  'user_id': '2',
  'session_id': '441-pmS-Jeol-4gqHpLtVcB6mA==',
  'concept': 'test charge',
  'solution_id': '8',
  'destinatary_id': '78',
  'amount': '10'
  }
  };
  request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
  });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var formdata = new FormData();
  formdata.append("country_code", "MX");
  formdata.append("user_id", "2");
  formdata.append("session_id", "441-pmS-Jeol-4gqHpLtVcB6mA==");
  formdata.append("concept", "test charge");
  formdata.append("solution_id", "8");
  formdata.append("destinatary_id", "78");
  formdata.append("amount", "10");
  var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
  };
  fetch("URL_BASE/ws/ws_soluciones.py/ordendecargo", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request POST 'URL_BASE/ws/ws_soluciones.py/ordendecargo' 
  --form 'country_code=MX' 
  --form 'user_id=2' 
  --form 'session_id=441-pmS-Jeol-4gqHpLtVcB6mA==' 
  --form 'concept=test charge' 
  --form 'solution_id=8' 
  --form 'destinatary_id=78' 
  --form 'amount=10'`
      }
    ]
  }

  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >Charge Order</h1>
          <p>
            This service is used to charge a user of the system using a charge order
            type solution.
          </p>
          <p>
            <span className="label post">POST:</span>
            <span> </span> <code>URL_BASE + /ws/ws_soluciones.py/ordendecargo</code>
          </p>
          <h2 >Receives:</h2>
          <p>
            All the parameters that the service receives must be indicated in the body
            of the request.
          </p>
          <div className='overflow-auto'>

            <table>
              <thead>
                <tr>
                  <th align="left">Parameter</th>
                  <th align="center">Format</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                  <th align="center">Example</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="left">country_code</td>
                  <td align="center">
                    ISO 3166 alpha-2 &#40;
                    <a href="https://www.iso.org/glossary-for-iso-3166.html">
                      https://www.iso.org/glossary-for-iso-3166.html
                    </a>
                    &#41;
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">Country code in which the user resides</td>
                  <td align="center">N/A</td>
                  <td align="center">ES &#40;Spain&#41;</td>
                </tr>
                <tr>
                  <td align="left">user_id</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">Identifier of the user making the request</td>
                  <td align="center">N/A</td>
                  <td align="center">4532</td>
                </tr>
                <tr>
                  <td align="left">session_id</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send signature to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Obtained by identifying the user with ID user_id on the platform
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">385-XadgYoWixbzN_wqWc3Yyog==</td>
                </tr>
                <tr>
                  <td align="left">signature</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send session_id to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Enables you to sign the request without having to log in
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
                </tr>
                <tr>
                  <td align="left">destinatary_id</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Identifier of the user that is going to be charged
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">1234</td>
                </tr>
                <tr>
                  <td align="left">solution_id</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">Charge Order Solution Identifier</td>
                  <td align="center">N/A</td>
                  <td align="center">1</td>
                </tr>
                <tr>
                  <td align="left">amount</td>
                  <td align="center">String</td>
                  <td align="center">No</td>
                  <td align="center">Amount of the charge to be applied to the user</td>
                  <td align="center">N/A</td>
                  <td align="center">10</td>
                </tr>
                <tr>
                  <td align="left">concept</td>
                  <td align="center">Boolean</td>
                  <td align="center">No</td>
                  <td align="center">
                    Concept associated with the charge to be made to the user
                  </td>
                  <td align="center">N/A</td>
                  <td align="center"></td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>
          <h3 >Answer JSON OK:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2 >Where:</h2>
          <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>signed:</code> Shows if the signature has been successfully
              processed&#40;true&#41; or not &#40;false&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>invoice:</code> Invoice number associated to the operation.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>currency_data:</code> Contains the different details of the
              currency used in the operation.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>abbreviation:</code> The abbreviated name of the currency&#40;EUR,
              MXN&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>suffix:</code> Indicates the suffix that is applied in the
              currency format &#40;pesos, euros&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>format:</code> The full format that applies to the currency, it
              includes the suffix and the prefix.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>symbol:</code> The symbol associated to the currency &#40;€, ¢,
              $&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>prefix:</code> The prefix that is applied in the currency format.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>decimals:</code> The maximum number of decimal places that will be
              included in the currency format.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>id:</code> Identifier of the currency in BBDD.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>elapsed:</code> Operation execution time.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>raw_amount:</code> Negative transaction amount without applying
              the format.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>currency:</code> Short name of the currency used in the operation,
              it matches the abbreviation.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>commission_user_card:</code> Commission that would be applied if
              the payment is made by card.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>codtran:</code> Transaction code that identifies the executed
              operation.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>user_commission:</code> Commission that will be applied to the
              user.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>amount:</code> Amount of the operation applying the format.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Business logic:</h2>
          <p>
            This service allows a user to be charged without the need for a signature.
            This signature occurs when the user signs the contract to the charge order
            &#40;<code>authorize_solution</code>&#41;.
          </p>

        </div>
        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>

      </div>


    </Fragment>
  );
}
export default Orden_de_cargo_EN;
