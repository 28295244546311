import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const Prices_of_Different_Services = () => 
{

  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{
        "status":true,
        "commission_fmt":"<string>",
        "commission":"<decimal>",
        "elapsed":"<float>"
     }`,
      copied: false
    },
    answe_ok2: {
      title:"Answer ok",
      type: "json",
      code: `{
        "status":true,
        "codtran":"<string>",
        "result":{
           "origins":[
              {
                 "<Origin_of_Funds>"
              }
           ],
           "creators":[
              
           ]
        }"elapsed":"<float>"
     }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: ` import requests url = "URL_BASE/ws/util.py/precios_servicio?country_code=MX&user_id=4532&session_id=446-t5bsnhEOhn_1eUnifUk7xQ==&point_of_entry=moneysending&amount=1&only_commission=true" 
    payload = {} 
    files = {} 
    headers= {} 
    response = requests.request("GET", url, headers=headers, data = payload, files = files) 
    print(response.text.encode('utf8'))`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests url = "URL_BASE/ws/util.py/precios_servicio?country_code=MX&user_id=4532&session_id=446-t5bsnhEOhn_1eUnifUk7xQ==&point_of_entry=moneysending&amount=1&only_commission=true" 
    payload = {} 
    files = {} 
    headers= {} 
    response = requests.request("GET", url, headers=headers, data = payload, files = files) 
    print(response.text.encode('utf8'))`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: ` var request = require('request');
 var options = {
 'method': 'GET',
 'url': 'URL_BASE/ws/util.py/precios_servicio?country_code=MX&user_id=4532&session_id=446-t5bsnhEOhn_1eUnifUk7xQ==&point_of_entry=moneysending&amount=1&only_commission=true',
 'headers': {},
 formData: {}
 };
 request(options, function (error, response) {
 if (error) throw new Error(error);
 console.log(response.body);
 });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: ` var formdata = new FormData();
 var requestOptions = {
 method: 'GET',
 body: formdata,
 redirect: 'follow'
 };
 fetch("URL_BASE/ws/util.py/precios_servicio?country_code=MX&user_id=4532&session_id=446-t5bsnhEOhn_1eUnifUk7xQ==&point_of_entry=moneysending&amount=1&only_commission=true", requestOptions)
 .then(response => response.text())
 .then(result => console.log(result))
 .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: ` curl --location --request GET 'URL_BASE/ws/util.py/precios_servicio?country_code=MX&user_id=4532&session_id=446-t5bsnhEOhn_1eUnifUk7xQ==&point_of_entry=moneysending&amount=1&only_commission=true'`
      }
    ]
  }

  return(
    <Fragment>
            <div className='container-fluid row'>
        <div className='col-md-7'>

      <h1 >Prices of Different Services</h1>
      <p>
        This service is used to obtain the total price of the user&#39;s use of a
        service.{' '}
      </p>
      <p>
        <span className="label get">GET:</span>
        <span> </span> <code>URL_BASE + /ws/util.py/precios_servicio</code>
      </p>
      <h2 >Receives:</h2>
      <p>
        All parameters are sent in the querystring of the call, so a percentage
        encoding for URI must be applied &#40;aka URL encoding&#41;.
      </p>
      <div className='overflow-auto'>
      <table>
        <thead>
          <tr>
            <th align="left">Parameter</th>
            <th align="center">Format</th>
            <th align="center">Obligatory</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
            <th align="center">Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="left">country_code</td>
            <td align="center">
              ISO 3166 alpha-2 &#40;
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                https://www.iso.org/glossary-for-iso-3166.html
              </a>
              &#41;
            </td>
            <td align="center">Yes</td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
            <td align="center">ES &#40;Spain&#41;</td>
          </tr>
          <tr>
            <td align="left">user_id</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">
              Identifier of the user making the request &#40;Admin or
              Affiliate&#41;
            </td>
            <td align="center">N/A</td>
            <td align="center">4532</td>
          </tr>
          <tr>
            <td align="left">session_id</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send signature to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Obtained by identifying the user with ID user_id on the platform
            </td>
            <td align="center">N/A</td>
            <td align="center">385-XadgYoWixbzN_wqWc3Yyog==</td>
          </tr>
          <tr>
            <td align="left">signature</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send session_id to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Enables you to sign the request without having to log in
            </td>
            <td align="center">N/A</td>
            <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
          </tr>
          <tr>
            <td align="left">point_of_entry</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">
              Entry point of the service that we want to know how much commission
              will be charged to the user
            </td>
            <td align="center">N/A</td>
            <td align="center">moneysending</td>
          </tr>
          <tr>
            <td align="left">amount</td>
            <td align="center">Decimal</td>
            <td align="center">Yes</td>
            <td align="center">
              Amount that would be used to consume the original service
            </td>
            <td align="center">N/A</td>
            <td align="center">12.50</td>
          </tr>
          <tr>
            <td align="left">only_commission</td>
            <td align="center">Boolean</td>
            <td align="center">No</td>
            <td align="center">
              Indicates if only the cost of the service commission is returned, or
              all the service data
            </td>
            <td align="center">False</td>
            <td align="center">True</td>
          </tr>
          <tr>
            <td align="left">currency</td>
            <td align="center">String</td>
            <td align="center">No</td>
            <td align="center">Currency abbreviation</td>
            <td align="center">MXN</td>
            <td align="center">MXN</td>
          </tr>
        </tbody>
      </table>
      </div>

      <h2 >Returns:</h2>
      <p>
        Depending on the result of the operation, this service can return two
        different JSON:
      </p>
      <h3 >
        Answer JSON OK, if it indicates <code>only_commission</code> is True:
      </h3>
  
      <div className='mb-3'>
        <DisplayCode1 codeToshow={codes.answe_ok1}/>
      </div>
      <h2 >Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li>
          <code>commission_fmt:</code> Commission amount with the currency format.
        </li>
        <li>
          <code>commission:</code> Commission amount without format.
        </li>
        <li>
          <code>elapsed:</code> Operation execution time.
        </li>
      </ul>
      <h3 >
        Answer JSON OK, if <code>only_commission</code> is not indicated True:
      </h3>
  
      <div className='mb-3'>
        <DisplayCode1 codeToshow={codes.answe_ok2}/>
      </div>
      <h2 >Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li>
          <code>codtran:</code> Transaction code that identifies the executed
          operation.
        </li>
        <li>
          <code>result:</code> Executed service answer.
        </li>
        <li>
          <code>origins:</code> List with the origin of the user&#39;s funds. View
          structure on the endpoint <code>origin_of_funds</code>.
        </li>
        <li>
          <code>creators:</code> List of types of sources of funds that the user
          can create.
        </li>
        <li>
          <code>elapsed:</code> Operation execution time.
        </li>
      </ul>
      <h3 >Answer JSON KO:</h3>
  
      <div className='mb-3'>
        <DisplayCodeKo/>
      </div>
      <h2 >Business logic:</h2>
      <p>
        This service returns a list with each of the movements of the large issuer
        user between two given dates.
      </p>
      </div>
        <div className='col-md-5'>
      <h2 >Example requests:</h2>
      <div className='mb-3 effectStickySections'>
      <DisplayCode2 codeToshow={codesMutiple}/>
      </div>
      
        </div>

      </div>
    </Fragment>
  );
}
export default Prices_of_Different_Services;
