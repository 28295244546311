import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';
import "./../../../../assets/css/effectStickySections.css"

const Obtain_Name_Of_Affiliate = () => 
{
  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{
        "status":"<boolean>",
        "codtran":"<string>""affiliates":[
           {
              "phone":"<string>",
              "affiliate_id":"<integer>",
              "country":"<string>",
              "name":"<string>",
              "other_data":"<string>",
              "allow_prefunding":"<boolean>"
           }
        ],
        "elapsed":"<float>"
     }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: ` import requests url = "URL_BASE/ws/util.py/affiliates?country_code=MX&id_usuario=4532&id_sesion=413-JRdxDQvO-zPMcwLeptmOyA==" 
    payload = {} 
    files = {} 
    headers= {} 
    response = requests.request("GET", url, headers=headers, data = payload, files = files) 
    print(response.text.encode('utf8'))`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests url = "URL_BASE/ws/util.py/affiliates?country_code=MX&id_usuario=4532&id_sesion=413-JRdxDQvO-zPMcwLeptmOyA==" 
    payload = {} 
    files = {} 
    headers= {} 
    response = requests.request("GET", url, headers=headers, data = payload, files = files) 
    print(response.text.encode('utf8'))`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: ` var request = require('request');
  var options = {
  'method': 'GET',
  'url': 'URL_BASE/ws/util.py/affiliates?country_code=MX&id_usuario=4532&id_sesion=413-JRdxDQvO-zPMcwLeptmOyA==',
  'headers': {},
  formData: {}
  };
  request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
  });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: ` var formdata = new FormData();
  var requestOptions = {
  method: 'GET',
  body: formdata,
  redirect: 'follow'
  };
  fetch("URL_BASE/ws/util.py/affiliates?country_code=MX&id_usuario=4532&id_sesion=413-JRdxDQvO-zPMcwLeptmOyA==", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: `curl --location --request GET 'URL_BASE/ws/util.py/affiliates?country_code=MX&id_usuario=4532&id_sesion=413-JRdxDQvO-zPMcwLeptmOyA%3D%3D'`
      }
    ]
  }


  return(
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>

      <h1 >
        Obtain name&#40;s&#41; of Affiliate&#40;s&#41;
      </h1>
      <p>This service is used to obtain the affiliate.</p>
      <p>
        <span className="label get">GET:</span>{' '}
        <code>URL_BASE + /ws/util.py/affiliates</code>
      </p>
      <h2 >Receives:</h2>
      <p>
        All parameters are sent in the querystring of the call, so a percentage
        encoding for URI must be applied &#40;aka URL encoding&#41;.
      </p>
      <table>
        <thead>
          <tr>
            <th align="left">Parameter</th>
            <th align="center">Format</th>
            <th align="center">Obligatory</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
            <th align="center">Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="left">country_code</td>
            <td align="center">
              ISO 3166 alpha-2 &#40;
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                https://www.iso.org/glossary-for-iso-3166.html
              </a>
              &#41;
            </td>
            <td align="center">Yes</td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
            <td align="center">ES &#40;Spain&#41;</td>
          </tr>
        </tbody>
      </table>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>
          <h3 >Answer JSON OK:</h3>
          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
          <h2 >Where:</h2>
          <ul>
            <li>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li>
              <code>codtran:</code> Transaction code that identifies the executed
              operation.
            </li>
            <li>
              <code>affiliates:</code> List of affiliates of the system.
            </li>
            <li>
              <code>phone:</code> Affiliate phone number.
            </li>
            <li>
              <code>affiliate_id:</code> Affiliate identifier.
            </li>
            <li>
              <code>country:</code> Affiliate Country Code.
            </li>
            <li>
              <code>name:</code> Affiliate name.
            </li>
            <li>
              <code>other_data:</code> Other affiliate data.
            </li>
            <li>
              <code>allow_prefunding:</code> Boolean that indicates if prefunding is
              allowed in the affiliate.
            </li>
            <li>
              <code>elapsed:</code> Operation execution time.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>
      
          <div className='mb-3'>
            <DisplayCodeKo/>
          </div>
          <h2 >Business logic:</h2>
          <p>
            By means of this service all the affiliates of the system are obtained.
          </p>
          </div>

      <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
      </div>
      </div>
    </Fragment>
  );
  <div className='overflow-auto'></div>
}
export default Obtain_Name_Of_Affiliate;

