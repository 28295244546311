import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define el tipo de estado inicial
export interface NameDivState {
    name: string;
    sendToNameSection: string;
    sendToNameSubSection: string;
    getToSectionAlgolia: string;
    showContentInDocs: boolean;
}

const initialState: NameDivState = {
    name: '', // Nombre de usuario inicialmente vacío
    sendToNameSection: "",
    sendToNameSubSection: "",
    getToSectionAlgolia: "",
    showContentInDocs: false
};

export const nameDiv = createSlice({
    name: 'nameDiv',
    initialState,
    reducers: {
        updateName: (state, action: PayloadAction<string>) => {
            state.name = action.payload; // Actualiza el nombre del usuario con el valor proporcionado
        },
        updateSendToNameSection: (state, action: PayloadAction<string>) => {
            state.sendToNameSection = action.payload;
        },
        updateSendToNameSubSection: (state, action: PayloadAction<string>) => {
            state.sendToNameSubSection = action.payload;
        },
        updateGetToSectionAlgolia: (state, action: PayloadAction<string>) => {
            state.getToSectionAlgolia = action.payload;
        },
        updateShowContentInDocs: (state, action: PayloadAction<boolean>) => {
            state.showContentInDocs = action.payload;
        },
    },
});

// Exporta las acciones generadas por createSlice
export const { updateName, updateSendToNameSection, updateSendToNameSubSection, updateGetToSectionAlgolia, updateShowContentInDocs } = nameDiv.actions;

// Exporta el reducer generado por createSlice
export default nameDiv.reducer;
