import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h4>1.3 Operations with Data Types</h4>
  <p>
    In AVAP™, just like in Python, it is possible to perform operations with different data types. For example:
  </p>
  <pre><code>
# Operations with integers <br />
a = 10 <br />
b = 5 <br />
sum = a + b <br />
difference = a - b <br /><br />
# Operations with floating-point numbers <br />
c = 3.5 <br />
d = 2.0 <br />
product = c * d <br />
division = c / d <br /><br />
# Operations with strings <br />
text1 = "Hello" <br />
text2 = "world" <br />
concatenation = text1 + " " + text2
  </code></pre>
</div>
  </Fragment>
);
export default Intro_1;
