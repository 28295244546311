import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';


import fondo from '../../assets/img/landingPage/fondoApps.png';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'

import features_1 from '../../assets/img/landingPage/platon/Platon01.png'
import features_2 from '../../assets/img/landingPage/platon/Platon02.png'
import features_3 from '../../assets/img/landingPage/platon/Platon03.png'




import logo from '../../assets/img/logos/AVAP-Platon_white.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'




import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'
import Favicon from '../../assets/img/Favicon_AVAP_Framework.png'


const VersionControlProduct = () => {

    useEffect(() => {
        // favicon 
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = Favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);
    
        // clean favicon 
        return () => {
          document.head.removeChild(link);
        };
      }, []);

    useEffect(() => {
	
		document.body.classList.add('dark-bg');
		return () => {
			document.body.classList.remove('dark-bg');
		};

	}, []);
    
    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center pt-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">Programming Language Architecture <br /> for True Open virtualizatioN</h1>
                    <p className="fl-subtitle mt-5">
                        Open-source virtual programming kernel, <br /> powering innovation
                    </p>
                          <a className="glowBtn-dark fakeHide" href="#"><span className="btnInner">  Start with AVAP Cloud</span></a>
                </section>

                {/* Powerful API Platform */}
                <section className="container text-center pb-5">
                    <div className="row">
                        <div className="col">
                            {/* <img
                                src="https://placehold.co/1026x423"
                                alt="Platform Placeholder"
                                className="img-fluid mt-4 mb-5"
                            /> */}
                            <p className="fl-title mb-3">
                            A Powerful Platform for Next-Gen Programming Languages
                            </p>
                            <p className="fl-text">
                                PLATON is the foundational engine behind the future of programming, offering a next-generation virtual programming language kernel and specifications that push the boundaries of software development.
                                <br /><br />
                                As an open-source project, PLATON thrives on collaboration, inviting developers from across the globe to contribute, innovate, and refine its capabilities. PLATON enables the creation of cutting-edge programming languages and tools, ensuring that developers have the freedom to build with transparency and adaptability.
                            </p>
                        </div>
                    </div>

                </section>



                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Open-Source Collaboration</h3>
                            <p className="fl-text">
                            PLATON is an open-source platform, inviting global contributions from developers to shape its evolution. This community-driven approach ensures constant innovation and transparency, providing users with a flexible and evolving kernel for their programming needs.

                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Next-Gen Virtual Language</h3>
                            <p className="fl-text">
                            PLATON’s next-generation virtual programming language is designed to support the creation and management of powerful and efficient programming languages. Its virtual infrastructure enables dynamic configurations in real time, separating functionality from technical details. By managing technical aspects in the cloud, PLATON allows systems to evolve without requiring code changes, recompilation, or redeployment.
                            </p>
                        </div>

                        <div className="col-md-6 text-center">
                            <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-8 fl-z-index-2 position-relative ">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center">
                            <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>


                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Customizable and Scalable Kernel</h3>
                            <p className="fl-text">
                            PLATON provides a highly customizable and scalable kernel, enabling developers to build tailored programming languages and tools. Its architecture is designed to evolve with changing tech landscapes, ensuring that it meets the growing demands of modern software development.
                            </p>
                        </div>

                    </div>
                </section>

                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default VersionControlProduct;
