import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_9 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>getQueryParamList()</h2>
      <p>
        The <code>getQueryParamList()</code> command extracts the query parameters from the current HTTP request and stores a list of these parameters in a target variable. This is useful for handling and processing query parameters in web applications.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>TargetVariable</strong> <br />
          Type: <code>var</code> <br />
          Description: The variable in which the extracted query parameter list will be stored. This should be a variable where the command's result will be saved.
        </li>
      </ul>
      <h3>Command Flow</h3>
      <ol>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Parameter Extraction:</strong> Accesses the query parameters from the current HTTP request.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>List Construction:</strong> Creates a list containing dictionaries, where each dictionary represents a query parameter and its associated value.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Result Storage:</strong> Saves the list of parameters in the variable specified by <code>TargetVariable</code>.</li>
      </ol>
      <h3>Usage Example</h3>
      <p>Suppose the HTTP query has the following parameters: <code>?user=alice&age=30</code>.</p>
      <pre>
        <code>
          {`// Define the variable to store the result
            queryParamsList = []

            // Call the command to extract query parameters
            getQueryParamList(queryParamsList)

            // Return the list of query parameters via addResult
            addResult(queryParamsList)`}
        </code>
      </pre>
      <p>Given the query string <code>?user=alice&age=30</code>, the <code>getQueryParamList()</code> command will generate the following list of parameters:</p>
      <pre>
        <code>
          {`[
    {"user": "alice"},
    {"age": "30"}
]`}
        </code>
      </pre>
    </div>
  </Fragment>
);

export default Appendices_9;
