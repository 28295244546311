// DevPortaCatalog.tsx
import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Image,
  Form,
  Card,
  Table,
  Dropdown,
} from "react-bootstrap";
import { FaTh, FaList } from "react-icons/fa";
import Drawer from "../avapCloud/components/Drawer";
import CreateAppForm from "./CreateAppForm";
import AddUsersForm from "./AddUsersForm";
import { useHistory, useParams, Link } from "react-router-dom";
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import Cookies from 'universal-cookie';

import sun from '../../assets/img/ui_icons/sun.svg';
import moon from '../../assets/img/ui_icons/moon.svg';

const cookies = new Cookies();

//interfaces
interface App {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  apiProducts: string[];
}

interface ApiProduct {
  id: number;
  title: string;
  description: string;
  latestVersion: string;
  details: string;
}

interface Portal {
  portal_id: string;
  id: string;
  name: string;
  logo: string;
  theme: {
    primaryColor: string;
    secondaryColor: string;
    backgroundColor: string;
    textColor: string;
  };
  user: {
    name: string;
    photo: string;
  };
  apps: App[];
  apiProducts: ApiProduct[];
}

interface ButtonProps {
  text: string;
  theme: {
    primaryColor: string;
  };
  onClick?: () => void;
  className?: string;
}

// botón dinámico
const DynamicButton: React.FC<ButtonProps> = ({ text, theme, onClick, className }) => {
  const buttonStyle = {
    backgroundColor: theme.primaryColor,
    color: "#fff",
    border: "none",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  };

  return (
    <button style={buttonStyle} onClick={onClick} className={className}>
      {text}
    </button>
  );
};

const DevPortaCatalog: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const portalIdentifier = id.startsWith('/_') ? id.slice(2) : id;
  const history = useHistory();
  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark';
  });

  const [portal, setPortal] = useState<Portal | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [viewMode, setViewMode] = useState<'card' | 'list'>('card');
  const [filteredApps, setFilteredApps] = useState<App[]>([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerAddUsersOpen, setDrawerAddUsersOpen] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState<ApiProduct[]>([]);
  const [apiProducts, setApiProducts] = useState<ApiProduct[]>([]); // Definición del estado
  const [userName, setUserName] = useState('unknown');
  const [userID, setUserId] = useState<number>(0);
  const [selectedApp, setSelectedApp] = useState<App | {}>({});
  const [portal_id, set_portal_id] = useState<string>('');
  const [Logo, setLogo] = useState<string>(''); // Imagen por defecto vacía

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    DevPortalExists();
  }, [id]);

  const toggleDarkMode = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem('theme', newTheme ? 'dark' : 'light');
  };

  const setFavicon = (url: string) => {
    let link = document.querySelector("link[rel='icon']") as HTMLLinkElement;
  
    if (!link) {
      // Si no existe, lo creamos
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }
  
    // Cambiamos la URL del favicon
    link.href = url;
  };

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  const DevPortalExists = async () => {
    setLoading(true);
    setError(null);
    try {
      const portalData = cookies.get('portal');
      const user = cookies.get('user');

      if (!portalData) {
        history.replace(`/devportal/${id}/login`);
        return;
      }

      if (!portalData.portal_id) {
        setError("El portal no tiene portal_id");
        history.replace(`/devportal/${id}/login`);
        return;
      }

      set_portal_id(portalData.portal_id);

      document.title = portalData.name;
      setFavicon(portalData.logo);

      // Extraer "id" sin "/_"
      const portalIdentifier = portalData.id.startsWith('/_') ? portalData.id.slice(2) : portalData.id;

      try {
        const logoRes = await ObexRequestHandler.get(`/publishing/get_dev_portal_logo/${portalIdentifier}`);
        setLogo(logoRes.data.logo);
      } catch (logoError) {
        console.error("Error al obtener el logo del portal:", logoError);
        // Opcional: Usar el logo predeterminado de la cookie si falla la llamada al API
        setLogo(portalData.logo);
      }

      setPortal(portalData);

      if (user && user.username) {
        setUserName(user.username);
        setUserId(user.user_id);
      }

      await DevPortalAppsExists(portalData.portal_id);
      await DevPortalProductsExists(portalData.portal_id);
    } catch (err) {
      console.error("Error en DevPortalExists:", err);
      setError("Hubo un problema al cargar el portal.");
    } finally {
      setLoading(false);
    }
  };

  const DevPortalProductsExists = async (portal_id: string) => {
    try {
      const res = await ObexRequestHandler.get(`/publishing/get_dev_portal_products/${portal_id}`, {}, true);
      const data = res.data;
      if (Array.isArray(data) && data.length > 0) {
        const mapped = data.map((prod: any) => ({
          id: prod.product_id,
          title: prod.avap_api_product_name,
          description: prod.dev_portal_published_description,
          latestVersion: 'v0.1',
          details: prod.dev_portal_published_description + prod.avap_api_product_documentation
        }));
        setApiProducts(mapped);
        setFilteredProducts(mapped);
      } else {
        setApiProducts([]);
        setFilteredProducts([]);
      }
    } catch (error) {
      console.error("Error al obtener los productos del portal:", error);
    }
  };

  const DevPortalAppsExists = async (portal_id: string) => {
    try {
      const res = await ObexRequestHandler.get(`/publishing/get_dev_portal_apps/${portal_id}`, {}, true);
      const { data } = res;
      if (data.length > 0) {
        const apps = data.map((prod: any) => ({
          id: prod.app_id,
          name: prod.app_name,
          description: prod.app_description,
          createdAt: prod.app_created_at,
          apiProducts: prod.api_products.map((pp: any) => pp.product_name),
          apiProductsID: prod.api_products.map((pp: any) => pp.product_id.toString()),
          tokens: prod.tokens.map((pp: any) => pp.token),
          tokensId: prod.tokens.map((pp: any) => pp.id)
        }));
        setFilteredApps(apps);
      } else {
        setFilteredApps([]);
      }
    } catch (error) {
      console.log("Error al obtener las apps del portal:", error);
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    if (portal) {
      const filtered = portal.apps.filter((app) =>
        app.name.toLowerCase().includes(term)
      );
      setFilteredApps(filtered);
    }
  };

  const handleRemoveApp = async (app_id: string) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar esta App?')) {
      const payload = {
        devportal_id: portal_id,
        app_id: app_id
      };
      try {
        const res = await ObexRequestHandler.post('/publishing/delete_dev_portal_app', payload);
        if (res) {
          DevPortalExists();
        }
      } catch (error) {
        console.error("Error al eliminar la app:", error);
      }
    }
  };

  const toggleViewMode = () => {
    setViewMode((prevMode) => (prevMode === "card" ? "list" : "card"));
  };

  if (loading) return <div>Cargando...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!portal) return <div>Cargando Portal...</div>;

  return (
    <div id="devPortalWrapper">
      {/* Barra superior */}
      <header
        className="d-flex justify-content-between align-items-center p-3"
        style={{ backgroundColor: isDarkMode ? '#333' : portal.theme.backgroundColor }}
      >
        <div className="d-flex align-items-center">
          <a onClick={() => history.push(`/devportal/${id}/portal`)}>
            <Image src={Logo} alt="Logo" style={{ height: "50px", marginRight: "10px" }} />
          </a>
          <h4 className="m-0" style={{ color: isDarkMode ? '#fff' : portal.theme.textColor }}>{portal.name}</h4>
        </div>

        {/* Botón y Dropdown del usuario */}
        <div className="d-flex align-items-center">
          <DynamicButton
            className="mr-3"
            text="Crear App"
            theme={portal.theme}
            onClick={() => {
              setDrawerOpen(true)
              setSelectedApp({})
            }}
          />

          {/* Enlace "My Apps"*/}
          <Link
            to={`/devportal/${id}/catalog`}
            className="mx-3"
            style={{
              color: portal.theme.primaryColor,
              fontSize: "16px",
              textDecoration: "none",
            }}
          >
            Mis Apps
          </Link>

          <Dropdown>
            <Dropdown.Toggle  id="dropdown-basic">
              <span style={{ color:  isDarkMode ? '#fff' : portal.theme.textColor, marginRight: '10px' }}>
                {userName}
              </span>
              {/* <Image
                src={portal.user.photo || ''}
                alt=""
                style={{ height: '40px', borderRadius: '50%' }}
              /> */}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#/profile">Perfil</Dropdown.Item>
              <Dropdown.Item onClick={() => {
                cookies.remove('user');
                cookies.remove('portal');
                history.push(`/devportal/${portal.id}/login`);
              }}>Cerrar Sesión</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <button
            onClick={toggleDarkMode}
            className="workspace-button"
          >
            <img style={{ width: '20px' }} src={isDarkMode ? sun : moon} alt="Workspace Menu" />
          </button>
        </div>
      </header>

      {/* Barra central */}
      <div
        className="text-center p-4 dp-banner"
        style={{ backgroundColor: portal.theme.primaryColor, color: "#fff" }}
      >
        <h2>¡Bienvenido a nuestro Portal de API!</h2>
        <p>Comienza a construir e innovar con nuestras APIs</p>
        <Form.Control
          type="text"
          placeholder="Busca tus apps..."
          value={searchTerm}
          onChange={handleSearch}
          className="w-50 mx-auto"
        />
      </div>

      {/* Área de Apps */}
      <Container className="my-4">
        <Row className="mb-3">
          <Col>
            <h4>Mis Apps</h4>
          </Col>
          <Col className="justify-content-end d-flex">
            <Button  onClick={toggleViewMode}>
              {viewMode === "card" ? <FaList /> : <FaTh />}
            </Button>
          </Col>
        </Row>

        {/* Manejar el estado cuando no hay Apps */}
        {filteredApps.length === 0 ? (
          <div className="text-center my-5">
            <p>No tienes apps. ¡Añade una nueva app para empezar!</p>
            <DynamicButton
              text="Añadir Nueva App"
              theme={portal.theme}
              onClick={() => setDrawerOpen(true)}
            />
          </div>
        ) : (
          // Vista de apps
          viewMode === "card" ? (
            <Row>
              {filteredApps.map((app) => (
                <Col md={4} key={app.id} className="mb-4">
                  <Card>
                    <Card.Body>
                      <Card.Title>{app.name}</Card.Title>
                      <Card.Text>
                        {app.description}
                      </Card.Text>
                      <Card.Text>
                        <small className="text-muted">Creado el: {app.createdAt}</small>
                      </Card.Text>
                      {/* Mostrar API Products asociados */}
                      {app.apiProducts.length > 0 && (
                        <div>
                          <strong>API Products:</strong>
                          <ul>
                            {app.apiProducts.map((api, index) => (
                              <li key={index}>{api}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {/* Botones adicionales */}
                      <div className="dropDownCards">
                        <Dropdown className='my-auto'>
                          <Dropdown.Toggle className="organizations" >
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setSelectedApp(app);
                                setDrawerOpen(true)
                              }}
                            >
                              Editar
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => { handleRemoveApp(app.id) }}>
                              Eliminar
                            </Dropdown.Item>

                            {/* Nueva opción "Add Users" */}
                            <Dropdown.Item
                              onClick={() => {
                                setSelectedApp(app);
                                setDrawerAddUsersOpen(true);
                              }}
                            >
                              Añadir Usuarios
                            </Dropdown.Item>

                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          ) : (
            <Table className="cld_tableWrapper" hover>
              <thead>
                <tr className="tableRow">
                  <th className="">Nombre</th>
                  <th className="">Descripción</th>
                  <th className="">Creado El</th>
                  <th className="">API Products</th>
                  <th className="">Acciones</th>
                </tr>
              </thead>
              <tbody className="tableRow">
                {filteredApps.map((app) => (
                  <tr key={app.id}>
                    <td>{app.name}</td>
                    <td>{app.description}</td>
                    <td>{app.createdAt}</td>
                    <td>
                      {app.apiProducts.length > 0 ? (
                        <ul>
                          {app.apiProducts.map((api, index) => (
                            <li key={index}>{api}</li>
                          ))}
                        </ul>
                      ) : (
                        "Ninguno"
                      )}
                    </td>
                    <td>
                      <div className="dropDownTable">
                        <Dropdown className='my-auto'>
                          <Dropdown.Toggle className="organizations" >
                            <FontAwesomeIcon icon={faEllipsisV} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                setSelectedApp(app);
                                setDrawerOpen(true)
                              }}
                            >
                              Editar
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => { handleRemoveApp(app.id) }}>
                              Eliminar
                            </Dropdown.Item>

                            {/* Nueva opción "Add Users" */}
                            <Dropdown.Item
                              onClick={() => {
                                setSelectedApp(app);
                                setDrawerAddUsersOpen(true);
                              }}
                            >
                              Añadir Usuarios
                            </Dropdown.Item>

                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )
        )}
      </Container>

      {/* Drawer para Crear Apps */}
      <Drawer
        isOpen={drawerOpen}
        toggleSidebar={() => {
          setDrawerOpen(false)
          setSelectedApp({})
        }}
        name="Crear App"
      >
        {portal && (
          <CreateAppForm
            app_data={selectedApp}
            devportal_id={portal_id}
            onSubmit={() => {
              setDrawerOpen(false);
              DevPortalExists();
              DevPortalProductsExists(portal_id);
            }}
          />
        )}
      </Drawer>

      {/* Add Users */}
      <Drawer
        isOpen={drawerAddUsersOpen}
        toggleSidebar={() => setDrawerAddUsersOpen(false)}
        name="Añadir Usuarios"
      >
        <AddUsersForm />
      </Drawer>
    </div>
  );
};

export default DevPortaCatalog;
