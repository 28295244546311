import React from 'react';
import './chapter.css'
const Chapter9_39 = () => (
    <div>
        <h2>The if Statement</h2>
        <p>
            The <code>if</code> statement in AVAP is used for conditional execution. The syntax is as follows:
        </p>
        <pre>
            <code>
                if (variable, variableValue, comparator, expression): 
                code to execute
            </code>
        </pre>
        <p>
            This statement evaluates the condition specified by the <code>variable</code>, <code>variableValue</code>, <code>comparator</code>, and <code>expression</code>. It selects exactly one of the suites (blocks of code) by evaluating the expressions one by one until a true condition is found. The corresponding suite is then executed. If all conditions are false, no suites are executed.
        </p>
    </div>
);

export default Chapter9_39;
