import React from 'react';
import './chapter.css'
const Chapter9_46 = () => (
    <div>
        <h2>Wildcard Patterns</h2>
        <p>
            In AVAP, wildcard patterns are used to match any value without binding it to a name. The syntax for a wildcard pattern is:
        </p>
        <pre><code>wildcard_pattern ::=  '_'</code></pre>
        <p>
            Wildcard patterns always succeed and do not create any bindings. They are useful when you want to ignore the value of the subject and only care about whether it matches a certain pattern.
        </p>
        <p>
            Here’s how you might use wildcard patterns in AVAP:
        </p>
        <pre><code>match value:
    case _:
        print("Matched any value")</code></pre>
        <p>
            In this example:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>case _:</code> matches any value and does not bind it to a name. The pattern always succeeds, and the code within this case will be executed regardless of the value.</li>
        </ul>
        <p>
            Wildcard patterns are particularly useful when you need to handle a broad range of possibilities and are only interested in whether a value fits a general condition, not in the value itself.
        </p>
    </div>
);

export default Chapter9_46;

