import React, { FunctionComponent, Component, useEffect, useState, Fragment } from 'react';
import { Row, Col, Card, Modal, Form, Button, Alert, Spinner, FormControl  } from 'react-bootstrap';
import ButtonSubmit from '../basicComponents/buttonSubmit';
import ObexAlert from '../basicComponents/ObexAlerts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import Cookies from 'universal-cookie';

type ModalProps = {
  showModal: boolean,
  onCloseModal: () => void,
  handleSubmit: (e) => void,
  set_cliente_email: (value) => void,
  SetclientAssigned: (value) => void,
  setCliente: (value) => void,
  setLoadingCli: (value) => void,
  setFormulario: (value) => void,
  setFormulario2: (value) => void,
  setCreateOSelect: (value) => void,
  setSubmitDisabled: (value) => void,
  submitDisabled: boolean,
  creatOselect:boolean,
  Formulario: boolean,
  Formulario2: boolean,
  cliente_email: string,
  clientAssigned: string,
  cliente: string,
  loadingCli: boolean,
  loading: boolean,
  error: string,
  success: string,
  name_to_check: string,
  title_to_show: string,
  username: string,
}

const GoToProductionModal:FunctionComponent<ModalProps> = (props:ModalProps) => {
  
  const { showModal, onCloseModal, handleSubmit, set_cliente_email, SetclientAssigned, setCliente, setLoadingCli, 
    setFormulario, setFormulario2, setCreateOSelect, setSubmitDisabled, submitDisabled, creatOselect, Formulario, Formulario2, cliente_email, clientAssigned, 
    cliente, loadingCli, loading, error, success, name_to_check, title_to_show, username } = props;
  // const [submitDisabled, setSubmitDisabled] = useState(true);

  const [valor_a_comparar, setValorAComparar] = useState('');
 
  const [countrie, setCountry] = useState('');
  
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);

  const [successCreate2, setSuccessCreate2] = useState('');
  const [errorCreate2, setErrorCreate2] = useState('');
  const [loadingCreate2, serLoadingCreate2] = useState(false)


// //


const [addressLine1, setAddressLine1] = useState('');
const [addressLine2, setAddressLine2] = useState('');
const [postalCode, setPostalCode] = useState('');

const [addressLine1B, setAddressLine1B] = useState('');
const [addressLine2B, setAddressLine2B] = useState('');
const [postalCodeB, setPostalCodeB] = useState('');


const handleAddressLine1 = (e) => {
  e.preventDefault();
  e.stopPropagation();
  const { value } = e.target;
  setAddressLine1(value);
  //setSubmitDisabled(false);
}

const handleAddressLine2 = (e) => {
  e.preventDefault();
  e.stopPropagation();
  const { value } = e.target;
  setAddressLine2(value);
  //setSubmitDisabled(false);
}

const handlePostalCode = (e) => {
  e.preventDefault();
  e.stopPropagation();
  const { value } = e.target;
  setPostalCode(value);
  //setSubmitDisabled(false);
}


const handleAddressLine1B = (e) => {
  e.preventDefault();
  e.stopPropagation();
  const { value } = e.target;
  setAddressLine1B(value);
  //setSubmitDisabled(false);
}

const handleAddressLine2B = (e) => {
  e.preventDefault();
  e.stopPropagation();
  const { value } = e.target;
  setAddressLine2B(value);
  //setSubmitDisabled(false);
}

const handlePostalCodeB = (e) => {
  e.preventDefault();
  e.stopPropagation();
  const { value } = e.target;
  setPostalCodeB(value);
  //setSubmitDisabled(false);
}

// //




  

  const [cityId, setCity] = useState('');
  const [locality, setLocality] = useState('');

  const countriesList = countries.map(c => <option value={c.country_code} selected={locality == c.country_code }>{c.country}</option>);
  const citiesList = cities.map(c => <option value={c.id} >{c.city}</option>);

  const handleAccountName = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    set_cliente_email(value);
    //setSubmitDisabled(false);
  }

  const CheckClient = async ()=>{

    

    const result = await ObexRequestHandler.get(`/client/${cliente_email}/username`, {}, true);
    const { success, data } = result;
    if (success) {
        console.log(data);
        setCreateOSelect(false);
        setSubmitDisabled(false);
    }
  }

  const CheckEntry = (value) =>{

    if (value==name_to_check) setSubmitDisabled(false)

  }

  const closeView = ()=>{
    setAddressLine1('');
    setAddressLine2('');
    setPostalCode('');
    setAddressLine1B('');
    setAddressLine2B('');
    setPostalCodeB('');
    setLoadingCli(false);
    setSuccessCreate2('');
    onCloseModal();
  }

  const submitView = (e)=>{
    setAddressLine1('');
    setAddressLine2('');
    setPostalCode('');
    setAddressLine1B('');
    setAddressLine2B('');
    setPostalCodeB('');
    setLoadingCli(false);
    setSuccessCreate2('');
    handleSubmit(e);
  }

  const handleCreateClient = async () => {
    
    serLoadingCreate2(true);
    console.log(cliente_email);

    //getClient();


    const payload = { name:cliente_email, password:'', email:cliente_email, address:addressLine1+addressLine2, city:cityId, country:countrie, zip:postalCode, statement:'' };
    const result = await ObexRequestHandler.post('/client', payload);
    const { success, message, data } = result;
    serLoadingCreate2(false);
    if (!success) throw { message };
    setSuccessCreate2(data);
    serLoadingCreate2(true);
    SetclientAssigned(cliente_email)
    setCliente(cliente_email);
  }

  const handleCreateClient2 = async () => {

    const payload = { name:cliente_email, password:'', email:cliente_email, address:addressLine1B+addressLine2B, city:cityId, country:countrie, zip:postalCode, statement:'' };
    const result = await ObexRequestHandler.post('/client', payload);
    const { success, message, data } = result;

    if (!success) throw { message };


    SetclientAssigned(cliente_email)

    setCliente(cliente_email);

    const ele = document.getElementById('custom-switch') as HTMLInputElement;
    ele.checked = true;


  }






  const getCountries = async () => {
    try {
      const result = (await ObexRequestHandler.get('/countries')).data;
      setCountries(result);
    } catch (error) {
      console.error('ERROR FETCHING COUNTRIES ', error);
    }
  }

  const handleCountry = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;

    setCountry(value);
    const cities = (await ObexRequestHandler.get(`/cities?country_code=${value}`)).data;
    e.preventDefault();
    e.stopPropagation();
    setCities(cities);
    setSubmitDisabled(false);
  }

  const handleCity = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    setCity(value);
    setSubmitDisabled(false);
  }



  const getClient = async () =>{

    const cookies = new Cookies();
    const userInfo = cookies.get('101Obex');

    setLoadingCli(false);
    set_cliente_email(userInfo.email);
    const cliente = (await ObexRequestHandler.get(`/client/${userInfo.email}/username`));

    if (cliente.success){
      // console.log('CLI')
      // console.log(cliente.data);
      setCliente(cliente.data.username);
      // set_cliente_email(cliente.data.username)
      SetclientAssigned(cliente.data.username);
      
    } else {
      // console.log('NO CLI')
      setCliente('');
    }
    
    

    console.log(cliente.data)
    setLoadingCli(false);
  }


  useEffect(() => {
    getCountries();
    getClient();
  }, []);

  return(
    <Modal dialogClassName="addons-dialog obex-dialog"
      show={showModal} 
      onHide={closeView}
      animation={false}
      scrollable>
      <Modal.Header closeButton className="border-0 pb-0">
        <Modal.Title className="text-center mx-auto">
          <Row>
            <Col md="12" className="addons-title">
              <span>Fill {title_to_show} Production Data</span>
            </Col>
          </Row>
        </Modal.Title>
      </Modal.Header>
      <div className="border-top border-black my-2 mx-3"></div>
      <Modal.Body className="pt-0">
        <Row>
          <Col md="8" className="text-center mx-auto" style={{textAlign:'center'}}>
           <Row style={{textAlign:'center'}}>
            <span style={{textAlign:'center'}}> Please fill the info below to start go to production process</span>
            </Row>
            </Col>
          </Row>


        <Row>

        <Col style={{justifyContent:'center', marginLeft:'20px', marginRight:'20px'}}>
          <Form className="obex-form" onSubmit={submitView}>
                          <Form.Row className="mb-3">
                            <Form.Label column sm="12" className="obex-form-label">
                              Website URL
                            </Form.Label>
                            <Col md="12">
                              <Form.Control required type="email" value={null} placeholder="http://..." onChange={null} />
                            </Col>
                          </Form.Row>

          </Form>


          </Col>
          </Row>


          <Col>


          { cliente == '' ?
          <Row>
          <Col md="8" className="text-center mx-auto" >
           <Row style={{ marginLeft:'10px' }}>
            <span>Note, that you havent a client account and must create one</span>
            </Row>
            </Col>
            <Col md="4">
            <Form.Check // prettier-ignore
                  type="switch"
                  id="custom-switch2"
                  label="Assign to other client"
                  onChange={(e) => {
                    setCreateOSelect(true);
                    setSubmitDisabled(true);
                    setFormulario(e.target.checked)

                    if (e.target.checked) {
                      SetclientAssigned('');
                      set_cliente_email('');
                  } else {
                    SetclientAssigned(cliente);
                    getClient();
                  }
                  }
                  }
                />
            </Col>
          </Row> :
                    <Row>

                      <Col md="12">
                      <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            label="Assign to other client"
                            onChange={(e) => {
                              setCreateOSelect(true);
                              setFormulario(e.target.checked)
                              setSubmitDisabled(true);
                              if (e.target.checked) {
                                SetclientAssigned(''); 
                                set_cliente_email('');
                              }
                              else {
                                getClient();
                                SetclientAssigned(cliente);
                              }
                            }
                            }
                          />
                      </Col>
                    </Row>
}



<hr></hr>

{clientAssigned !='' &&

            <><Row style={{ textAlign: 'center' }}>
              <Col>
                <b style={{ fontFamily: 'Regular Intro Bold Alt', fontSize: '1.2em' }}>Project assigned to client {clientAssigned}</b>
              </Col>
            </Row><hr></hr></>

}

{(Formulario && clientAssigned == '') &&

            <Row>
              <Col>
                <Card className="product-detail-card mb-3">
                  <Card.Body className="p-3">
                    <Row>
                      <Col className="mx-auto">
                        <Form className="obex-form" /*onSubmit={handleSubmit}*/>
                          <Form.Row className="mb-3">
                            <Form.Label column sm="12" className="obex-form-label">
                              New Account Name (email)
                            </Form.Label>
                            <Col md="12">
                              <Form.Control required type="email" value={cliente_email} placeholder="Account Name (email)" onChange={handleAccountName} onBlur={CheckClient}/>
                            </Col>
                          </Form.Row>
                          <Form.Row className="mb-3">
                            <Form.Label column sm="12" className="obex-form-label">
                              Business Address
                            </Form.Label>
                            <Col md="12">
                              <Form.Control required type="text" value={addressLine1B} placeholder="Addresss line" onChange={handleAddressLine1B} />
                            </Col>
                          </Form.Row>
                          <Form.Row className="mb-3">
                            <Col md="9">
                              <Form.Control type="text" value={addressLine2B} placeholder="Addres line 2" onChange={handleAddressLine2B} />
                            </Col>
                            <Col md="3">
                              <Form.Control required type="number" value={postalCodeB} placeholder="Postal code" onChange={handlePostalCodeB} />
                            </Col>
                          </Form.Row>
                          <Form.Row className="mb-3">
               
                            <Col md="4">
                              <Form.Control required as="select" placeholder='Country' custom onChange={handleCountry}>
                                <option value="" selected disabled hidden>Choose country</option>
                                {countriesList}
                              </Form.Control>
                            </Col>
                            <Col md="8">
                              <Form.Control required as="select" placeholder='City' custom onChange={handleCity}>
                                {cities.length > 0
                                  ? citiesList
                                  : <option value="" selected disabled hidden>Choose a city</option>}
                              </Form.Control>
                            </Col>
                          </Form.Row>

                          <Form.Row className="mb-3">
                            <Form.Label column sm="12" className="obex-form-label">
                              Statement descriptor
                            </Form.Label>
                            <Col md="12">
                              <Form.Control
                                required
                                type="text"
                                value={null}
                                placeholder="Statement descriptor"
                                onChange={null} />
                            </Col>
                          </Form.Row>
                          
                        </Form>

                        <Row className="mt-3">
                            <Col md="4" className="ml-auto">


{ creatOselect ?

                              <Button type="button" disabled={submitDisabled} className="btn-block obex-btn btn-green" onClick={()=>{
                                  handleCreateClient2()
                                  }}>                       
                                  Create {success && <FontAwesomeIcon icon={faCheck} />}
                                  {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                              </Button>:


                              <Button type="button" disabled={submitDisabled} className="btn-block obex-btn btn-green" onClick={()=>{
                                     
                                    SetclientAssigned(cliente_email)

                                    setCliente(cliente_email);

                                    const ele = document.getElementById('custom-switch') as HTMLInputElement;
                                    ele.checked = true;

                                  }}>                       
                                  Select {success && <FontAwesomeIcon icon={faCheck} />}
                                  {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                              </Button>

                                }


                            </Col>
                            {error.length > 0 && (
                              <Alert variant='danger'>
                                {error}
                              </Alert>)}
                            {success.length > 0 && (
                              <Alert variant='success'>
                                {success}
                              </Alert>)}
                          </Row>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

                            }

{((Formulario2 || (cliente == '')) && !Formulario && clientAssigned == '')&&

<Row>
  <Col>
    <Card className="product-detail-card mb-3">
      <Card.Body className="p-3">
        <Row>
          <Col className="mx-auto">
            <Form className="obex-form">
              <Form.Row className="mb-3">
                <Form.Label column sm="12" className="obex-form-label">
                  Account Name (email)
                </Form.Label>
                <Col md="12">
                  <Form.Control required type="email" value={cliente_email} placeholder="Account Name (email)" onChange={null} />
                </Col>
              </Form.Row>
              <Form.Row className="mb-3">
                <Form.Label column sm="12" className="obex-form-label">
                  Business Address
                </Form.Label>
                <Col md="12">
                  <Form.Control required type="text" value={addressLine1} placeholder="Addresss line" onChange={handleAddressLine1} />
                </Col>
              </Form.Row>
              <Form.Row className="mb-3">
                <Col md="9">
                  <Form.Control type="text" value={addressLine2} placeholder="Addres line 2" onChange={handleAddressLine2} />
                </Col>
                <Col md="3">
                  <Form.Control required type="number" value={postalCode} placeholder="Postal code" onChange={handlePostalCode} />
                </Col>
              </Form.Row>
              <Form.Row className="mb-3">
   
                <Col md="4">
                  <Form.Control required as="select" placeholder='Country' custom onChange={handleCountry}>
                    <option value="" selected disabled hidden>Choose country</option>
                    {countriesList}
                  </Form.Control>
                </Col>
                <Col md="8">
                  <Form.Control required as="select" placeholder='City' custom onChange={handleCity}>
                    { cities.length > 0
                      ? citiesList
: <option value="" selected disabled hidden>Choose a city</option>}
                  </Form.Control>
                </Col>
              </Form.Row>


              <Form.Row className="mb-3">
                <Form.Label column sm="12" className="obex-form-label">
                  Statement descriptor
                </Form.Label>
                <Col md="12">
                  <Form.Control
                    required
                    type="text"
                    value={null}
                    placeholder="Statement descriptor"
                    onChange={null} />
                </Col>
              </Form.Row>

            </Form>
            <Row className="mt-3">
                <Col md="4" className="ml-auto">
                  <Button className="btn-block obex-btn btn-green" onClick={handleCreateClient}>
                    Create {successCreate2 && <FontAwesomeIcon icon={faCheck} 
                    />}
                    {loadingCreate2 && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                  </Button>
                </Col>
                {errorCreate2.length > 0 && (
                  <Alert variant='danger'>
                    {errorCreate2}
                  </Alert>)}
                {successCreate2.length > 0 && (
                  <Alert variant='success'>
                    {successCreate2}
                  </Alert>)}
              </Row>
          </Col>
        </Row>
      </Card.Body>
    </Card>








  </Col>
</Row>

                }

<Row className="mt-3">
                <Col md="4" className="ml-auto">
                  <Button className="btn-block obex-btn btn-blue" onClick={submitView} disabled={clientAssigned == ''}>
                    Go to Producction {success && <FontAwesomeIcon icon={faCheck} 
                    />}
                    {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                  </Button>
                </Col>
                {error.length > 0 && (
                  <Alert variant='danger'>
                    {error}
                  </Alert>)}
                {success.length > 0 && (
                  <Alert variant='success'>
                    {success}
                  </Alert>)}
              </Row>

          </Col>

      </Modal.Body>
    </Modal>
  )
}

export default GoToProductionModal;