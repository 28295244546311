import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h4>2.3.1 addVar() Function</h4>
  <p>
    The <code>addVar()</code> function is used to declare global variables within the scope of an API. Its syntax is as follows:
  </p>
  <pre><code>addVar(variable_name, value)</code></pre>
  <p>Where:</p>
  <ul>
    <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>variable_name</strong> is the name of the variable to be declared.</li>
    <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>value</strong> is the initial value to be assigned to the variable (optional).</li>
  </ul>
</div>
  </Fragment>
);
export default Intro_1;
