import React from 'react';
import './chapter.css'
const Chapter13_9 = () => (
  <div>
    <h2>Function Libraries Function Products</h2>
    <p>
      In AVAP™, there are a series of function libraries grouped by categories called <strong>Function Products</strong> that complement the base AVAP™ language and leverage the power of AVS servers for distribution. Through Function Products, developers can extend the functionality of AVAP™ by incorporating specialized libraries tailored to different needs and applications.
    </p>
    <p>
      Function Products provide a way to access advanced features and capabilities not available in the core language, offering a robust framework for building complex and scalable solutions. These libraries are designed to integrate seamlessly with AVAP™, enhancing the development process and enabling more efficient and effective project execution.
    </p>
  </div>
);

export default Chapter13_9;
