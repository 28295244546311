import React, { useState } from "react";
import { Doughnut } from 'react-chartjs-2';

import icn_item from '../../../assets/img/ui_icons/cld_developers.svg';
import chevron from '../../../assets/img/ui_icons/chevron.svg';


function Developers() {

    const progress = 50;

 
    const circleStyle = {
        background: `conic-gradient(#4c9aff ${progress * 3.6}deg, rgba(224, 224, 224, 0.25) 0deg)`,
    };


    return (
        <div id='w_apiProject' className="item h-type-3">
            <div className="title">
                <span><img src={icn_item} alt="" /></span>
                <p>Developers</p>
            </div>

            <div className="flex-full">
                <div className="data">
                    <div className="dataContent">
                        <span>Devs</span>
                        <p>152</p>
                    </div>
                </div>

                <div className="progressChart" style={circleStyle}>
                    <div className="circlebg"></div>
                    <span className="label">{progress}%</span>
                </div>



            </div>

            <div className="bottom">
                <div className="label">152 in use of 200</div>
            </div>
        </div>
    );
}

export default Developers;