import React, { useState } from 'react';
import { Form, Button, Collapse } from 'react-bootstrap';


const ComplexApiForm = () => {
    const [openInfo, setOpenInfo] = useState(false);
    const [openUpstream, setOpenUpstream] = useState(false);
    const [openServer, setOpenServer] = useState(false);
    const [openMiddleware, setOpenMiddleware] = useState(false);
    const [showNewEventForm, setShowNewEventForm] = useState(false);

    const [formData, setFormData] = useState({
        apiName: '',
        apiUrl: '',
        expirationDate: '',
        apiType: '',
        gatewayStatus: '',
        access: '',
        targetUrl: '',
        listenPath: '',
        toggles: {
            apiRateLimiting: false,
            serviceDiscovery: false,
            upstreamCertificates: false,
            certificatePinning: false,
            stripListenPath: false,
            authentication: false,
            clientCertificates: false,
            detailedLogs: false,
            detailedTracing: false,
            customDomain: false,
            trafficLogs: false,
            contextVariables: false,
            transformRequestHeaders: false,
            transformResponseHeaders: false,
        },
    });

    const handleToggleChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            toggles: { ...prevData.toggles, [name]: checked },
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    return (
        <Form className="cloudForm">
            <h2>API Configuration</h2>

            {/* Info Collapse */}
            <Button  className='collapseBtn' variant="link" onClick={() => setOpenInfo(!openInfo)}>
                Info
            </Button>
            <Collapse in={openInfo}>
                <div>
                    <Form.Group controlId="apiName">
                        <Form.Label>API Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="apiName"
                            value={formData.apiName}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="apiUrl">
                        <Form.Label>API URL</Form.Label>
                        <Form.Text className="text-muted">
                            You haven't connected. Add segment tags, use a hybrid gateway URL or a custom domain.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="expirationDate">
                        <Form.Label>Expiration Date</Form.Label>
                        <Form.Control
                            type="date"
                            name="expirationDate"
                            value={formData.expirationDate}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="apiType">
                        <Form.Label>API Type</Form.Label>
                        <Form.Control
                            as="select"
                            name="apiType"
                            value={formData.apiType}
                            onChange={handleInputChange}
                        >
                            <option>HTTP OAS</option>
                            <option>Other Type</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="gatewayStatus">
                        <Form.Label>Gateway Status</Form.Label>
                        <Form.Control
                            as="select"
                            name="gatewayStatus"
                            value={formData.gatewayStatus}
                            onChange={handleInputChange}
                        >
                            <option>Select status</option>
                            <option>Active</option>
                            <option>Inactive</option>
                        </Form.Control>
                    </Form.Group>
                    <Form.Group controlId="access">
                        <Form.Label>Access</Form.Label>
                        <Form.Control
                            as="select"
                            name="access"
                            value={formData.access}
                            onChange={handleInputChange}
                        >
                            <option>Select access</option>
                            <option>Public</option>
                            <option>Private</option>
                        </Form.Control>
                    </Form.Group>
                </div>
            </Collapse>

            {/* Upstream Collapse */}
            <Button  className='collapseBtn' variant="link" onClick={() => setOpenUpstream(!openUpstream)}>
                Upstream
            </Button>
            <Collapse in={openUpstream}>
                <div>
                    <Form.Group controlId="targetUrl">
                        <Form.Label>Target URL</Form.Label>
                        <Form.Control
                            type="url"
                            name="targetUrl"
                            value={formData.targetUrl}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    {["apiRateLimiting", "serviceDiscovery", "upstreamCertificates", "certificatePinning"].map((toggleName) => (
                        <Form.Group key={toggleName} controlId={toggleName} className="d-flex align-items-center">
                            <Form.Label className="mr-2">{toggleName.replace(/([A-Z])/g, " $1")}</Form.Label>
                            <label className="custom-toggle">
                                <input
                                    type="checkbox"
                                    name={toggleName}
                                    checked={formData.toggles[toggleName]}
                                    onChange={handleToggleChange}
                                />
                                <span className="slider"></span>
                            </label>
                        </Form.Group>
                    ))}
                </div>
            </Collapse>

            {/* Server Collapse */}
            <Button  className='collapseBtn' variant="link" onClick={() => setOpenServer(!openServer)}>
                Server
            </Button>
            <Collapse in={openServer}>
                <div>
                    <Form.Group controlId="listenPath">
                        <Form.Label>Listen Path</Form.Label>
                        <Form.Control
                            type="text"
                            name="listenPath"
                            value={formData.listenPath}
                            onChange={handleInputChange}
                        />
                    </Form.Group>
                    {["stripListenPath", "authentication", "clientCertificates"].map((toggleName) => (
                        <Form.Group key={toggleName} controlId={toggleName} className="d-flex align-items-center">
                            <Form.Label className="mr-2">{toggleName.replace(/([A-Z])/g, " $1")}</Form.Label>
                            <label className="custom-toggle">
                                <input
                                    type="checkbox"
                                    name={toggleName}
                                    checked={formData.toggles[toggleName]}
                                    onChange={handleToggleChange}
                                />
                                <span className="slider"></span>
                            </label>
                        </Form.Group>
                    ))}
                </div>
            </Collapse>

            {/* Middleware Collapse */}
            <Button  className='collapseBtn' variant="link" onClick={() => setOpenMiddleware(!openMiddleware)}>
                Middleware
            </Button>
            <Collapse in={openMiddleware}>
                <div>
                    {["trafficLogs", "contextVariables", "transformRequestHeaders", "transformResponseHeaders"].map((toggleName) => (
                        <Form.Group key={toggleName} controlId={toggleName} className="d-flex align-items-center">
                            <Form.Label className="mr-2">{toggleName.replace(/([A-Z])/g, " $1")}</Form.Label>
                            <label className="custom-toggle">
                                <input
                                    type="checkbox"
                                    name={toggleName}
                                    checked={formData.toggles[toggleName]}
                                    onChange={handleToggleChange}
                                />
                                <span className="slider"></span>
                            </label>
                        </Form.Group>
                    ))}
                </div>
            </Collapse>
            
            <Button type="submit" className="btn btn-primary">Submit</Button>
        </Form>
    );
};

export default ComplexApiForm;
