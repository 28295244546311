import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_4 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>getTimeStamp()</h2>
      <p>
        The <code>getTimeStamp()</code> command converts a date and time string, given in a specific format, to a timestamp value. Additionally, it allows for an optional time adjustment before storing the result in a target variable. This command is useful for converting human-readable date and time representations to a numeric timestamp format, which can be used in calculations or time comparisons.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>DateString</strong> <br />
          Type: <code>var</code> <br />
          Description: A string representing a date and time. This string must follow the format specified in the Format parameter. It can be a direct value or a variable containing the date string.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Format</strong> <br />
          Type: <code>var</code> <br />
          Description: A format string that defines how to interpret the date and time string (<code>DateString</code>). This string follows Python's conventions for formatting and parsing dates and times. Some common symbols include:
          <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>%Y</code>: Year with four digits (e.g., 2024)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>%m</code>: Month with two digits (01 to 12)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>%d</code>: Day of the month with two digits (01 to 31)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>%H</code>: Hour in 24-hour format (00 to 23)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>%M</code>: Minutes (00 to 59)</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>%S</code>: Seconds (00 to 59)</li>
          </ul>
          For example, to interpret the string <code>"2024-08-25 14:30:00"</code>, the format <code>%Y-%m-%d %H:%M:%S</code> would be used. It can be a direct value or a variable containing the format.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>TimeDelta</strong> <br />
          Type: <code>var</code> <br />
          Description: An optional value representing a time adjustment (positive or negative) applied to the timestamp after conversion. This value can be provided directly or through a variable and is expressed in seconds.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>TargetVariable</strong> <br />
          Type: <code>var</code> <br />
          Description: The variable where the resulting timestamp from the conversion will be stored. Unlike the other parameters, this must be a variable and not a direct value.
        </li>
      </ul>
      <h3>Usage Example</h3>
      <pre>
        <code>
          {`// Direct call with values:
            getTimeStamp('2024-08-25 14:30:00', '%Y-%m-%d %H:%M:%S', 3600, generatedTimestamp)

            // Call using variables:
            date = '2024-08-25 14:30:00'
            format = '%Y-%m-%d %H:%M:%S'
            adjustment = 3600
            getTimeStamp(date, format, adjustment, generatedTimestamp)`}
        </code>
      </pre>
      <p>
        In the first example, the date and time string <code>"2024-08-25 14:30:00"</code> is converted to a timestamp, applying a 3600-second (1-hour) adjustment, and the result is stored in the variable <code>generatedTimestamp</code>. In the second example, variables are used to define the date, format, and adjustment.
      </p>
    </div>
  </Fragment>
);

export default Appendices_4;
