import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h1>Chapter 5: Data Types</h1>
  <p>
    In this chapter, we will explore the data types available in AVAP™. Data types are fundamental in programming as they determine what kind of values can be stored in a variable and what operations can be performed with those values. Throughout this chapter, we will discuss the basic data types in AVAP™ and how they are used in program development.
  </p>
</div>
  </Fragment>
);
export default Intro_1;
