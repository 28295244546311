import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import {  Modal, Button, Container, Row, Col, Form, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import PaymentConfirmation from './paymentConfirmation';
import FetchingSpinner from '../basicComponents/loaderModal';
import { amountToEur, amountToCoin } from '../../services/AmountService';
import AddCreditCard from './addCreditCard';


type AddProjectModalSelProps = {
  shown: boolean,
  onCloseAction: () => void,
  onSuccess: () => void
}

const AddProjectModalSel:FunctionComponent<AddProjectModalSelProps> = (props:AddProjectModalSelProps) => {
    
  const { shown, onCloseAction, onSuccess } = props;

  const [showInputProject, setShowProjectInput] = useState(false);
  const [inputPayout, setInputPayout] = useState(0);
  const [autoRechargePayoutPerBalance, setAutoRechargePayoutPerBalance] = useState(0);
  const [autoRechargerPerMonth, setAutoRechargePerMonth] = useState(false);
  const [validInputPayout, setValidInputLayout] = useState(false);
  const [serviceSelected, setServiceSelected] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [AddResume, setAddResume] = useState(true);
  const [continueSelected, setContinueSelected] = useState(false);
  const [selection, setSelection] = useState(0);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState({ name: '' });
  const [toCharge, setToCharge] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [eFactor, setEFactor] = useState(0);
  const [currency, setCurrecy] = useState('');
  const [BalanceAmmount, SetBalanceAmount] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [cards_, setCards] = useState([]);
  const [card_id_stripe, setCardID] = useState('');
  const [newadded_card, setAddedCard] = useState('');

  const getCards = async () => {
    try {
      const result = (await ObexRequestHandler.get('/cards', {}, true)).data || {};
      const { defaultCard, cards } = result;
      const pmCards = [];
      if (cards) {
        for (const c of cards) {
          const isDefault = c.id === defaultCard;
          c.defaultCard = isDefault;
          isDefault ? pmCards.unshift(c) : pmCards.push(c);
          if(cards_.some(e => e.id == c.id)){
            //console.log('exists');
          }else{
            setAddedCard(c.id);
          }
        }
      }
      setCards(pmCards);
  
    } catch (error) {
      console.error('ERROR FETCHING CARDS ', error);
      throw error;
    }
  }
  const handleModal = () => {
    setShowModal(!showModal);
    //onCloseAction();
    getCards();

  };
  

  const handleCard = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    if (value=='addCard') {
      handleModal();
    } else {
      setCardID(value);

    }
    console.log(value);
    e.preventDefault();
    e.stopPropagation();
  
  }

  const handleGoBack = () =>{setContinueSelected(!continueSelected)}

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    //setShowProjectInput(true);
    await chargeBalance();
    onSuccess();
  }

  const getCurrencie = async (p) => {
    const result = await ObexRequestHandler.get(`/currencie/${p.name}`);
    setEFactor(result['data']['cambio_compra']);
    setCurrecy(result['data']['name']);

    //setShowAddbalancePlugin(true);
   // setProject(p);
   setToCharge(p.name);
  }

  const chargeBalance = async () => {
    try {
      setLoading(true);
      const payload = { amount: inputPayout,  card_stripe_id: card_id_stripe };
      await ObexRequestHandler.post(`/projects/${project.name}/balance`, payload);

    } catch (error) {
      alert('ERROR CHARGING BALANCE');
      console.error('ERROR WITHDRAWING MONEY ', error);
    }
    setLoading(false);
  }


  const getProjectsInfo = async () => {
    try {
      setLoading(true);
      const result = (await ObexRequestHandler.get('/projects')).data;
      setProjects(result);
    } catch(error) {
      console.error('ERROR FETCHING PROJECTS INFO ', error);
    }
    setLoading(false);
  }

  const startPayment = () => {
    setLoading(true);
    setSuccess('');
    setError('');
  }

  const addBalanceAction = async () => {
    try{
      startPayment();
      const payload = { amount: inputPayout };
      const result = await ObexRequestHandler.post('/charge_balance', payload);
      const { success, message, data } = result;
      if (!success) throw { message };
      setSuccess(data);
      setPaymentSuccess(true);
      setContinueSelected(false);
    } catch (error) {
      setError(error.message || error);
      console.error('ERROR BUYING EXTRA REQUESTS ', error)
    }
    setLoading(false);
  }
  
  const selectAddonSelection = (event) => {
    const value = parseInt(event.target.value);
    event.preventDefault();
    event.stopPropagation();
    setSelection(value);
  }

  const removeAddonSelection = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setSelection(0);
  }

  const handleRechargePerMonth = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAutoRechargePerMonth(!autoRechargerPerMonth);
  }

  const handleAutoRecharge = (event) => {
    const { value } = event.target;
    event.preventDefault();
    event.stopPropagation();
    setAutoRechargePayoutPerBalance(value);
  }

  const handleInputPayout = (event) => {
    const { value } = event.target;
    event.preventDefault();
    event.stopPropagation();
    setInputPayout(value);
    setServiceSelected(`Adding ${value} to the balance.`);
 

  }

  const ADD = async () =>{
    await chargeBalance();
    onSuccess();
  }

  const ProcessAdd = (p) =>{
    setAddResume(false);
    let total=BalanceAmmount.replace(',', '');
    total = total.replace('.', '');
    const totalInt = parseInt(total);
    setInputPayout(totalInt);
    setProject(p);
    //ADD();

  }




  
  const cambiaRango = (e) => {

    const letters = /^[A-Za-z]+$/;
    if(e.target.value.match(letters)) { e.target.value='';} else {

    let t = e.target.value.replace(',', '');
    t = t.replace('.', '');
    SetBalanceAmount(new Intl.NumberFormat('de-DE').format(t));
    if ((new Intl.NumberFormat('de-DE').format(t))=='NaN') {SetBalanceAmount('');e.target.value ='';} else
    {e.target.value = new Intl.NumberFormat('de-DE').format(t);}
    }
  }

  const projectWasCreated = () => {
    setShowProjectInput(false);
    onSuccess();
  }

  const checkValidAmount = (amount) => { 
    console.log(`valid amount ${amount} : `, checkValidAmount)
    return amount.match('/d*.?d*/')
  }

  const autoRechargePerBalanceMarked = () => selection === 3;

  const inputPayoutIntroduced = () => inputPayout > 0;
  const autoRechargeIntroduce = () => autoRechargePayoutPerBalance > 0;

  const inputsAreValid = () => {
    const valuesIntroduced = (inputPayoutIntroduced() && autoRechargerPerMonth)
    || (autoRechargePerBalanceMarked() && autoRechargeIntroduce()) 
    || inputPayoutIntroduced();
    return valuesIntroduced;
  }

  // TODO mirar los values de los planes que sean dinamicos y se manden dinamicos al handler no hardcodeados
  
  const modalBodyWhenProjectSelected =  <Modal.Body>
  <Container fluid>
    <Form className="obex-form">
      <Row className={'addon addons-border py-3 px-4 mb-4 position-relative' +
      (inputPayoutIntroduced() && ' addons-active')}>
      {inputPayoutIntroduced() && <FontAwesomeIcon icon={faCheck} />}
        <Col md="8">
          <Row>
            <Col md="12" className="addons-title">
              <span>Input to payout</span>
            </Col>
            <Col md="12">
              <span>Take full control over your deliverability, distribute your mail traffic across multiple IPs</span>
            </Col>
          </Row>
        </Col>
        <Col md="4" className="my-auto">
          <Form.Row>
            <Col className="">
              <Form.Control required type="number" placeholder="Amount"  onChange={handleInputPayout}/>
            </Col>
          </Form.Row>
        </Col>
      </Row>

      <Row className={'addon addons-border py-3 px-4 mb-4 position-relative' +
      (autoRechargerPerMonth && ' addons-active')}>
      {autoRechargerPerMonth && <FontAwesomeIcon icon={faCheck} />}
        <Col md="8">
          <Row>
            <Col md="12" className="addons-title">
              <span>Auto recharge every month</span>
            </Col>
            <Col md="12">
              <span>Gain further insights with additional history and API access</span>
            </Col>
          </Row>
        </Col>
        <Col md="4" className="my-auto">
          <Form.Row>
            <Col>
              {autoRechargerPerMonth ? 
                <Button value="2" className="btn-block btn-remove-addons" onClick={handleRechargePerMonth}>Remove</Button>:
                <Button value="2" className="custom-button-blue w-100 textBold" onClick={handleRechargePerMonth}>Add</Button>
              }
            </Col>
          </Form.Row>
        </Col>
      </Row>

      <Row className={'addon addons-border py-3 px-4 mb-4 position-relative' +
      ((autoRechargePerBalanceMarked() && autoRechargeIntroduce()) && ' addons-active')}>
      {(autoRechargePerBalanceMarked() && autoRechargeIntroduce()) && <FontAwesomeIcon icon={faCheck} />}
        <Col md="8">
          <Row>
            <Col md="12" className="addons-title">
              <span>Auto recharge per balance</span>
            </Col>
            <Col md="12">
              <span>Take full control over your deliverability distribute your mail traffic across multiple IPs</span>
            </Col>
          </Row>
        </Col>
        <Col md="4">
          <Form.Row>
            <Col md="12" className="mb-3">
              <Form.Control type="number" placeholder="Amount" onChange={handleAutoRecharge}/>
            </Col>
            <Col md="12">
              {autoRechargePerBalanceMarked() ? 
                <Button value="3" className="btn-block btn-remove-addons" onClick={removeAddonSelection}>Remove</Button>:
                <Button value="3" className="btn-block btn-add btn-add-green" onClick={selectAddonSelection}>Add</Button>
              }
            </Col>
          </Form.Row>
        </Col>
      </Row>

      <Form.Row className="mb-2">
        <Col className="text-center">
          {(inputsAreValid()) ? 
          <Button disabled={loading} className="btn-submit-addons with-add" onClick={handleSubmit}>
            {loading && <Fragment>&nbsp; <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }}/> </Fragment>}
            Continue to Checkout
          </Button> :
          <Button className="btn-submit-addons">Select an option or introduce a value</Button>}
        </Col>
      </Form.Row>

      <Row className="mb-2">
          <Col className="text-center">
            <Button className="btn-submit-addons" onClick={() => setProject({ name:'' })}>Back to Projects</Button>
          </Col>
      </Row>

      <Row>
        <Col className="text-center">
          <span>You'll add {amountToCoin(inputPayout*eFactor)} {currency} in your account balance. *Taxes and fees may apply</span>
        </Col>
      </Row>

    </Form>
  </Container>
  </Modal.Body>
  

  const projectsCards = projects.map(p => 
    <Row key={'addbalance'+p.id_origen} className="addon addons-border py-3 mb-4 position-relative">
      <Col md="4">
        <Row>
          <Col className="addons-title">
            <span>{p.description}</span>
          </Col>
        </Row>
        <Row>
          <Col>
            <span>{`Current balance is ${amountToCoin(p.balance)} EUR`}</span>
          </Col>
        </Row>  
      </Col>
      <Col md="2" className="price-addons text-left my-auto" >
      <input style={{ maxWidth:'6em' }} hidden={toCharge!=p.name} value={BalanceAmmount} onChange={cambiaRango}></input>
      </Col>
      <Col md="4" className="price-addons text-right my-auto">
        <span>{`${amountToEur(p.balance)} EUR`}</span>
      </Col>
      <Col md="2" className="my-auto">
        {toCharge=='' ?
          <Button className="btn-block btn-add btn-add-green" onClick={() => getCurrencie(p)}>Select</Button>:
          <>
          {toCharge==p.name ?
            <Button className="btn-block btn-add btn-add-green" onClick={()=>{ProcessAdd(p)}}>Add</Button>:
            <Button disabled={true} className="btn-block btn-add btn-add-green" onClick={null}>Select</Button>
          }
        </>  
      }
      </Col>
    </Row>
  )

  const selectProjectModalBody = <Modal.Body>
    <Container fluid>
      {loading ? <FetchingSpinner/> :
      <Fragment>
        { projects.length > 0 ?
          projectsCards
        : <span></span>
        }
      </Fragment>
    }
    </Container>
  </Modal.Body>

  useEffect(() => {
    getProjectsInfo();
    getCards();
  }, []);

  return(
  <>
    <Modal dialogClassName="addons-dialog w-75"
      show={shown}
      onHide={onCloseAction}
      animation={false}>

        {AddResume ?
      <><Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title className="text-center w-100">
            <Row>
              <Col className="addons-title">
                <span>Add balance to your account</span>
              </Col>
            </Row>
            <Row>
              <Col md="10" className="mx-auto text-center modal-subtitle">
                <span className="text-center d-block">
                  {project.name.length > 0 ? 'Select input and continue to checkout. You can configure auto recharge.' : 'Select a project to add a balance.'}
                </span>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
          <span>
            {project.name.length > 0 ? modalBodyWhenProjectSelected : selectProjectModalBody}
          </span></>
      :
      <>
      <Modal.Title className="text-center w-100">
            <Row>
              <Col className="addons-title">
                <span>Add Balance</span>
              </Col>
            </Row>
            <Row>
              <Col md="10" className="mx-auto text-center modal-subtitle">
                <span className="text-center d-block">
                  Review payment detail and proceed.
                </span>
              </Col>
            </Row>
          </Modal.Title>
      <PaymentConfirmation
      actionSubmit={ADD}
      handleGoBack={()=>{setProject({ name:'' });setAddResume(true)}}
      nameServiceSelected={`Add balance to project ${project.name}`}
      handleAnnualPayment={null}
      amountMonth={0}
      amountYear={inputPayout}
      processingPayment={loading}
      success={success}
      error={error}
      isBlue={true}
      cards={cards_}
      handleCard={handleCard}
      addedCard={newadded_card}
      spanish={false}
      ></PaymentConfirmation>
      </>
      }


    </Modal>
      
      {showModal && <AddCreditCard show={showModal} handleClose={handleModal} isGreen={false} spanish={false}/>}
      </>
  );
}

export default AddProjectModalSel;


