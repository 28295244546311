import React from 'react';
import './chapter.css'
const Chapter9_5 = () => (
    <div>
        <h2>4.4. Exceptions</h2>
        <p>
            Exceptions in AVAP allow for the handling of errors or exceptional conditions. An exception is raised when an error is detected; it can be handled by the surrounding code block or by any code block that directly or indirectly invoked the block where the error occurred.
        </p>
        <p>
            The AVAP interpreter raises an exception when it detects a runtime error. An AVAP program can also explicitly raise an exception using the <code>raise</code> statement. Exception handlers are specified with the <code>try ... except</code> statement.
        </p>
        <p>Example of exception handling:</p>
        <pre>
            <code>
{`try: 
    addVar(10 / 0, result)
except ZeroDivisionError: 
    print("Cannot divide by zero.")
`}
            </code>
        </pre>
        <p>
            In this example, if a division by zero occurs, a <code>ZeroDivisionError</code> exception is raised and handled by the <code>except</code> block.
        </p>
        <p>
            This structure ensures that AVAP programs execute in a sequential and predictable manner, without advanced dynamic or deferred execution features, maintaining simplicity and clarity in name binding and import handling.
        </p>
    </div>
);

export default Chapter9_5;
