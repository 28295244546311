import React, { Fragment } from 'react';
import './chapter.css'
const Infrastructure_AVS = () => (
  <Fragment>
    <div className='body-md'>
      <h2>1.1 Virtuality Principle in AVAP</h2>
      <p>The principle of virtuality in AVAP™ is based on several key aspects:</p>
    </div>
  </Fragment>
);
export default Infrastructure_AVS;
