import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col, Container, Image, Form, Button, Spinner, Alert } from 'react-bootstrap';
import PasswordRecovery from '../Modals/passwordRecovery';
import Logo from '../../assets/img/invitedeveloper.png';
import Logo2 from '../../assets/img/ilustracion-serpentina.png'
import LogoCubos from '../../assets/img/cabecerainvite.png'
import VerificationNotice from './VerificationNotice';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import Footer from '../functionalComponents/DashboardLayout/FooterMenuAccess';
import './style.css';
import NewPassword from '../Modals/setNewPassword';
import NewPasswordClient from '../Modals/setNewPasswordClient';
import PasswordRecoveryClientModal from '../Modals/passwordRecoveryClient';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type AuthProps = {
    Token?: number
  }

const ConfirmClient:FunctionComponent<AuthProps> = (props: AuthProps) => {

    const { 
      Token
    } = props;
  
  const [codeResult, setCodeResult] = useState('');
  const [mail, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [signining, setSignining] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showRecovery, setShowRecovery] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(true);
  const [stillLoaded, SetStillLoaded] = useState(false);
  const [mostrado, setMostrado] = useState(false);

  const handleCloseRecovery = () => setShowRecovery(false);
  const handleCloseNewPassword = () => setShowNewPassword(false);
  const handleShowRecovery = (e) => {
    e.preventDefault(); 
    setShowRecovery(true);
  };

  const handleShowNewPassword = (e) => {
    e.preventDefault(); 
    setShowNewPassword(true);
  };

  const history = useHistory();

  function routeDashboard() {
    history.push({
      pathname: '/admin'
    })
  }

  function routeHome() {
    const path = '/login';
    window.location.href = path;
  }

  function routePlans() {
    const path = '/Plans';
    window.location.href = path;
  }

  function routePrivate() {
    history.push({
      pathname: '/private'
    })
    
  }

  const redirectPage =  (signindata) =>{

      if (signindata['id_perfil']==6) routeDashboard();
      else if (signindata['id_perfil']==1) routePrivate();
      else routePlans();
    
  };

  const handleSubmitSignip = async (event) => {
    if (mostrado){
    {
    console.log(event);
    setSignining(true);
    const result = await ObexRequestHandler.get(`/accept_production?email=${mail}&password=${password}&token=${Token}`);
    console.log(result);
    if (result.success){
        if (result.data.code){
            const gg = document.getElementById('registro_entrada');
            const hh = document.getElementById('registro_entrada_success');
            gg.hidden = true;
            hh.hidden = false;
            setCodeResult(result.data.code);
        } else {
        setError(result.data.message);
        }
    } else {
        setError(result.message);
    }
    setSignining(false);
  }
} else {
  setMostrado(true);
}
  };


  const handleSubmitSignup = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    routePlans();    
  };


  const changeMail = (e) => {
    const newMail = e.target.value;
    setMail(newMail);
  }

  const changePass = (e) => {
    const newPass = e.target.value;
    setPassword(newPass);
  }

  return(
    <>
      <BrowserView>
    




    <Container fluid className="text-center d-flex flex-column min-vh-100">
    {stillLoaded ?
      <Row className="h-100" >
          <Col md="1" className="mx-auto d-flex align-items-center" style={{ marginTop: '25em' }}>
            <Spinner animation="border"/>
          </Col>
        </Row>:
      <><Row className="justify-content-center pt-5 mt-5" style={{display: 'grid'}}>
                      <Image  src={LogoCubos} fluid                             
                            height="220"
                            style={{ /*maxWidth: '800px'*/ height: '220px'}}
                            />
          <div id = 'registro_entrada' className="border rounded px-5 pt-3 pb-4 login-form" style={{ margin:'auto', minWidth: '600px' }}>
            <Row className="justify-content-center my-4">
              <Col >
              {/*<Image src='https://developer.101obex.com/static_images/correo-cubos.png' ></Image>*/}




                <Image onClick={routeHome} src={Logo} fluid                             
                            width="180"
                            style={{ marginBottom: '30px' }}
                            />
                {/*<h1><b>CENTURIAPP</b></h1>*/}
              </Col>
            </Row>
            <Row>
              <Col style={{ marginBottom: '30px' }}>
            <div style={{fontWeight: 500, fontSize: '18px', color: 'black' }}>You have requested to signup </div>
            </Col>
            </Row>
            <Row>
              <Col style={{ marginBottom: '30px' }}>
            <div style={{ fontWeight: 500, fontSize: '18px', color: '#7abcb0', marginTop:'-30px' }}>as 101OBeX Client</div>
            </Col>
            </Row>

            <Row>
              <Col style={{ marginBottom: '30px' }}>
            <div style={{ fontWeight: 500, fontSize: '13px', marginTop:'-10px' }}>Login to accept</div>
            </Col>
            </Row>


            <Row>
              <Col>
              
                <Form className="obex-form" style={{maxWidth:'80%', margin: 'auto'}}>

                  { mostrado &&
                  <><Form.Group controlId="formBasicEmail">
                      <Form.Control required type="email" placeholder="eMail account" onChange={changeMail} />
                    </Form.Group><Form.Group controlId="formBasicPassword">
                        <Form.Control required type="password" placeholder="Password" onChange={changePass} />
                      </Form.Group></>

}
                  <Button className="my-2 btn-green btn-block" onClick={handleSubmitSignip} disabled={signining}>
                    {signining ? 'Accessing' : 'Login'}
                    {signining && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                  </Button>
                  {error.length > 0 && (
                    <Alert variant='danger'>
                      {error}
                    </Alert>)}

                    <Row>
                      <Button className="my-2" style= {{ backgroundColor: 'transparent', borderColor: 'black', width:'100%', marginLeft:'15px', marginRight:'15px'}}>
                        <a className="" style={{ color: 'black' }} onClick={handleShowNewPassword}>First time?</a>
                      </Button>
                    </Row>


                </Form>
              </Col>
            </Row>
            <Row>
              <Col className="my-2">
                <a className="link_forgetpass" onClick={handleShowRecovery}>Are you having problems with signing in?</a>
              </Col>
            </Row>

            {/*
    <Row>
      <Col className="my-3">
        <div className="border-top divider-login"></div>
      </Col>
    </Row>
    <Row>
      <Col className="my-2">
      <Form>
          <Button type="submit" variant="light" className="btn-block singin" onClick={handleSubmitSignup}>
            Create account
          </Button>
        </Form>
      </Col>
    </Row>
    */}
          </div>


          <div id = 'registro_entrada_success' className="border rounded px-5 pt-3 pb-4 login-form" hidden={true}>
            <Row className="justify-content-center my-4">
              <Col >
                <Image onClick={routeHome} src={Logo2} fluid                             
                            width="180"
                            style={{ marginBottom: '60px' }}
                            />
                <h1><b>REGISTRATION ACEPTED</b></h1>
                <Row style={{ justifyContent:'center' }}>
                    Your Registration was successfull.
                </Row>

                <Row style={{ justifyContent:'center' }}>
                  You can close this windows.
                </Row>
              </Col>
            </Row>

          </div>
          <PasswordRecoveryClientModal show={showRecovery} handleClose={handleCloseRecovery} />
          <NewPasswordClient show={showNewPassword} handleClose={handleCloseNewPassword} Token={Token} />
        </Row><VerificationNotice
            mail={mail}
            show={show}
            handleClose={handleClose} /><Row className="mt-auto">
            <Col className="px-0">
              <Footer />
            </Col>
          </Row></> 
}
    </Container>

    </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  );
}
export default ConfirmClient;