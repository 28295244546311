import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>Chapter 2: Notation in AVAP™</h2>
      <h3>Introduction</h3>
      <p>
        Notation in AVAP™ refers to the conventions and rules used to write and format code in the AVAP™ programming language. 
        Notation is essential to ensure code readability and comprehension, as well as to establish a coherent and consistent syntax across all projects.
      </p>
    </div>
  </Fragment>
);
export default Intro_1;
