import React, { Fragment } from 'react';
import './chapter.css'
const Chapter8_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h1>Expressions in AVAP™</h1>
      <h2>Introduction</h2>
      <p>
        Expressions in AVAP™ are combinations of values, variables, operators, and function calls that can be evaluated to produce a result. 
        Just like in Python, expressions in AVAP™ can be simple or complex, and they can contain a variety of elements that manipulate and process data.
      </p>
    </div>
  </Fragment>
);

export default Chapter8_1;

