import React from 'react';
import './chapter.css'
const Chapter9_38 = () => (
    <div>
        <h2>Control Flow Structures in AVAP</h2>
        <p>
            In AVAP, control flow structures include conditional statements and loops, which allow you to control the flow of execution based on conditions and iterate over a range of values.
        </p>
        <h3>If Statements</h3>
        <p>
            The syntax for an if statement in AVAP is:
        </p>
        <pre>
            <code>
                if (variable, variableValue, comparator, expression): 
                code to execute
            </code>
        </pre>
        <p>
            This structure checks if the condition (variable compared to variableValue with the given comparator) is true, and if so, executes the block of code.
        </p>
        <h3>Loops</h3>
        <p>
            The syntax for a loop in AVAP is:
        </p>
        <pre>
            <code>
                startLoop(variable, from, to) 
                code to execute 
                endLoop()
            </code>
        </pre>
        <p>
            This structure initiates a loop where the variable iterates from the 'from' value to the 'to' value, executing the code block for each iteration.
        </p>
    </div>
);

export default Chapter9_38;
