import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_5 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>getRegex()</h2>
      <p>
        The <code>getRegex()</code> command searches for matches in a source string using a regular expression (regex) pattern and stores the result in a target variable. This command is useful for extracting specific parts of a string that match a defined pattern, such as email addresses, phone numbers, or any other structure defined by a regex.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>SourceVariable</strong> <br />
          Type: <code>variable</code> <br />
          Description: The variable containing the source string in which to search for regex pattern matches. This string is the text on which the regex search will be applied.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>rePattern</strong> <br />
          Type: <code>variable</code> <br />
          Description: The variable containing the regular expression (regex) pattern that defines what to search for in the source string. This pattern should follow standard regex rules, allowing the specification of sequences of characters to identify in the source string.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>TargetVariable</strong> <br />
          Type: <code>variable</code> <br />
          Description: The variable where the search result will be stored. Depending on the context and the pattern used, the result could be the first match found, all matches, or even specific groups within the match.
        </li>
      </ul>
      <h3>Usage Example</h3>
      <pre>
        <code>
          {`// Direct call with values:
            sourceText = "Email: user@example.com and phone: 123-456-7890"
            pattern = r"\\b\\d{3}-\\d{3}-\\d{4}\\b"
            getRegex(sourceText, pattern, phoneNumber)

            // Call using variables:
            sourceText = "Visit our website at https://www.example.com for more information."
            regexPattern = r"https?://\\S+"
            getRegex(sourceText, regexPattern, foundURL)`}
        </code>
      </pre>
      <p>
        In the first example, a phone number in the format <code>123-456-7890</code> is searched in the <code>sourceText</code> string and the result is stored in the <code>phoneNumber</code> variable. In the second example, a URL is extracted from the <code>sourceText</code> string using a regex that identifies URL patterns, and the result is stored in the <code>foundURL</code> variable.
      </p>
    </div>
  </Fragment>
);

export default Appendices_5;
