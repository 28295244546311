import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../components/DisplayCodeKo/DisplayCodeKo';

const Listado_de_solicitudes_KYC_EN = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: ` {
          "status": <boolean>,
          "result": {
              "count": <integer>,
              "limit": <integer>,
              "accounts": [
                  {
                      "solicitude": {
                          "level": <integer>,
                          "date": <string>,
                          "status": <integer>
                      },
                      "user": {
                          "nick": <string>,
                          "profile": <string>,
                          "phone": <string>,
                          "level": <string>
                      }
                  }
              ],
              "page": <integer>,
              "total_pages": <integer>
          },
          "elapsed": <float>
      }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
    url = "URL_BASE/ws/admin.py/listado_kyc?country_code=MX&user_id=2&session_id=393--ZCAQsFyJF95Q2YpqRNLug=="
    payload = {}
    headers= {}
    response = requests.request("GET", url, headers=headers, data = payload)
    print(response.text.encode('utf8'))`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
    url = "URL_BASE/ws/admin.py/listado_kyc?country_code=MX&user_id=2&session_id=393--ZCAQsFyJF95Q2YpqRNLug=="
    payload = {}
    headers= {}
    response = requests.request("GET", url, headers=headers, data = payload)
    print(response.text.encode('utf8'))`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'GET',
  'url': 'URL_BASE/ws/admin.py/listado_kyc?country_code=MX&user_id=2&session_id=393--ZCAQsFyJF95Q2YpqRNLug==',
  'headers': {
  }
  };
  request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
  });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var requestOptions = {
  method: 'GET',
  redirect: 'follow'
  };
  fetch("URL_BASE/ws/admin.py/listado_kyc?country_code=MX&user_id=2&session_id=393--ZCAQsFyJF95Q2YpqRNLug==", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request GET 'URL_BASE/ws/admin.py/listado_kyc?country_code=MX&user_id=2&session_id=393--ZCAQsFyJF95Q2YpqRNLug%3D%3D'`
      }
    ]
  }

  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >List of Pending KYC requests</h1>
          <p>
            This service is used to obtain a list of all the requests pending to be
            approved.
          </p>
          <p>
            <span className="label get">GET:</span>
            <span> </span> <code>URL_BASE + /ws/admin.py/listado_kyc</code>
          </p>
          <h2 >Receives:</h2>
          <p>
            All parameters are sent in the querystring of the call, so a percentage
            encoding for URI must be applied &#40;aka URL encoding&#41;.
          </p>
          <table>
            <thead>
              <tr>
                <th align="left">Parameter</th>
                <th align="center">Format</th>
                <th align="center">Obligatory</th>
                <th align="center">Description</th>
                <th align="center">Default value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="left">country_code</td>
                <td align="center">
                  <a href="https://www.iso.org/glossary-for-iso-3166.html">
                    ISO 3166 alpha-2
                  </a>
                </td>
                <td align="center">Yes</td>
                <td align="center">Country code in which the user resides</td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">user_id</td>
                <td align="center">Integer</td>
                <td align="center">Yes</td>
                <td align="center">Identifier of the user making the request</td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">session_id</td>
                <td align="center">String</td>
                <td align="center">
                  Yes &#40;if you do not send signature to &#39;Access
                  Service&#39;&#41;
                </td>
                <td align="center">
                  Obtained by identifying the user with ID user_id on the platform
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">signature</td>
                <td align="center">String</td>
                <td align="center">
                  Yes &#40;if you do not send session_id to &#39;Access
                  Service&#39;&#41;
                </td>
                <td align="center">
                  Enables you to sign the request without having to log in
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">page</td>
                <td align="center">Integer</td>
                <td align="center">No</td>
                <td align="center">Requested page number</td>
                <td align="center">1</td>
              </tr>
              <tr>
                <td align="left">limit</td>
                <td align="center">Integer</td>
                <td align="center">No</td>
                <td align="center">Number of results per page</td>
                <td align="center">10</td>
              </tr>
            </tbody>
          </table>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>
          <h3 >Answer JSON OK:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2 >Where:</h2>
          <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>result:</code> Container object for the service response.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>count:</code> Number of records that come within the object{' '}
              <code>accounts</code>.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>limit:</code> Maximum number of records that can come for each
              page.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>accounts:</code> Listing of each request and user for review,
              approval and rejection.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>solicitude:</code> Request details.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>level:</code> Level of KYC that will be accessed when this request
              is approved.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>date:</code> Timestamp of the moment the request was made.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>status:</code> Indicates the status of the request &#40;-1:
              Rejected, 0: In process&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>user:</code> Indicates the data of the user who made the request.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>nick:</code> User nickname.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>profile:</code> Name of the profile associated with the user.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>phone:</code> User&#39;s phone number.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>level:</code> The user&#39;s current KYC level.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>page:</code> Current page number of results.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>total_pages:</code> Number of pages available to request.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>elapsed:</code> Operation execution time.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>
          <h2 >Business logic:</h2>
          <p>
            This service provides a list with all rejected requests and those that are
            in process in order to be able to select them for review with the endpoint{' '}
            <code>administrate_kyc_solicitude</code>. It is only accessible to
            Administrator users.
          </p>
        </div>

        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>
      </div>
    </Fragment>
  );
}
export default Listado_de_solicitudes_KYC_EN;
