import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h3>2.2.1 Local Variables</h3>
  <p>
    Local variables are those that are declared within a function or block of code and are only available within that scope. They have a limited scope, and their lifespan is restricted to the execution time of the block in which they are declared. Local variables are used to store temporary or intermediate data needed to perform calculations or execute operations within a function.
  </p>
</div>
  </Fragment>
);
export default Intro_1;
