import React from 'react';
import './chapter.css'
const Chapter9_29 = () => (
    <div>
        <h2>Simple Statements</h2>
        <p>
            In AVAP, a simple statement consists of a single logical line. Multiple simple statements can be placed on a single line, separated by semicolons. The syntax for simple statements is:
        </p>
        <pre><code>simple_stmt ::=  expression_stmt
                       | assert_stmt
                       | assignment_stmt
                       | augmented_assignment_stmt
                       | annotated_assignment_stmt
                       | pass_stmt
                       | del_stmt
                       | return_stmt
                       | yield_stmt
                       | raise_stmt
                       | break_stmt
                       | continue_stmt
                       | import_stmt
                       | future_stmt
                       | global_stmt
                       | nonlocal_stmt
                       | type_stmt</code></pre>
        <p>
            Here’s a brief overview of each type of simple statement:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Expression Statement (<code>expression_stmt</code>):</strong> Executes an expression, which can be used for operations or calling functions.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Assert Statement (<code>assert_stmt</code>):</strong> Used for debugging purposes to test conditions.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Assignment Statement (<code>assignment_stmt</code>):</strong> Assigns values to variables or data structures.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Augmented Assignment Statement (<code>augmented_assignment_stmt</code>):</strong> Performs an operation on a variable and assigns the result back to the variable (e.g., <code>x += 1</code>).</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Annotated Assignment Statement (<code>annotated_assignment_stmt</code>):</strong> Used for assigning values with annotations (e.g., type hints).</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Pass Statement (<code>pass_stmt</code>):</strong> A placeholder that does nothing; used for syntactic requirements.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Del Statement (<code>del_stmt</code>):</strong> Deletes variables, items, or attributes.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Return Statement (<code>return_stmt</code>):</strong> Exits a function and optionally returns a value.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Yield Statement (<code>yield_stmt</code>):</strong> Produces a value from a generator function.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Raise Statement (<code>raise_stmt</code>):</strong> Raises exceptions for error handling.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Break Statement (<code>break_stmt</code>):</strong> Exits the closest enclosing loop.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Continue Statement (<code>continue_stmt</code>):</strong> Skips the current iteration of the closest enclosing loop.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Import Statement (<code>import_stmt</code>):</strong> Imports modules or specific components from modules.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Future Statement (<code>future_stmt</code>):</strong> Enables features from future versions of Python.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Global Statement (<code>global_stmt</code>):</strong> Declares variables as global within a function.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Nonlocal Statement (<code>nonlocal_stmt</code>):</strong> Declares variables as non-local, affecting scope in nested functions.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Type Statement (<code>type_stmt</code>):</strong> Declares or checks types (e.g., type hints).</li>
        </ul>
        <p>
            Each simple statement performs a specific task and contributes to the overall functionality of the AVAP program.
        </p>
    </div>
);

export default Chapter9_29;
