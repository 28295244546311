import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_12 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>variableFromJSON()</h2>
      <p>
        The <code>variableFromJSON()</code> command extracts the value associated with a specific key from a JSON object and stores the result in a target variable. This command is useful for accessing values within a JSON object.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>SourceVariable</strong> <br />
          Type: <code>var</code> <br />
          Description: The variable containing the JSON object from which a value is to be extracted. It can be a variable that stores the JSON object or a direct value representing the JSON object.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>key</strong> <br />
          Type: <code>value</code> <br />
          Description: The key whose value is to be extracted from the JSON object. It must be a value that represents the key within the JSON object.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>TargetVariable</strong> <br />
          Type: <code>var</code> <br />
          Description: The variable where the extracted value will be stored. It must be a variable that will receive the value associated with the specified key in the JSON object.
        </li>
      </ul>
      <h3>Command Flow</h3>
      <ol>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Access the JSON Object:</strong> Access the JSON object stored in the <code>SourceVariable</code>.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Extract the Value:</strong> Retrieve the value associated with the <code>key</code> within the JSON object.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Store the Result:</strong> Save the extracted value in the variable specified by <code>TargetVariable</code>.</li>
      </ol>
      <h3>Usage Example</h3>
      <p>Suppose the JSON object in <code>jsonData</code> is <code>"name": "Alice", "age": 30</code> and you want to extract the value associated with the key <code>"name"</code>.</p>
      <pre>
        <code>
          {`// Variable definitions
jsonData = {"name": "Alice", "age": 30}
nameValue = ''

// Call the command to extract the value associated with the key "name"
variableFromJSON(jsonData, "name", nameValue)

// Return the extracted value through addResult
addResult(nameValue)`}
        </code>
      </pre>
      <p>Since the value associated with the key <code>"name"</code> in the JSON object <code>jsonData</code> is <code>"Alice"</code>, the <code>variableFromJSON()</code> command will extract <code>"Alice"</code> and store it in the variable <code>nameValue</code>. The <code>nameValue</code> variable will be returned through <code>addResult(nameValue)</code>, resulting in the following output:</p>
      <pre>
        <code>
          {`"Alice"`}
        </code>
      </pre>
    </div>
  </Fragment>
);

export default Appendices_12;

