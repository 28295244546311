import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { useParams } from "react-router-dom";

const cookies = new Cookies();

const DynamicRouteWrapper = ({
  defaultComponent: DefaultComponent,
  alternativeComponent: AlternativeComponent
}: any) => {
  const [layout, setLayout] = useState<number | null>(null);
  const { id } = useParams(); // Obtén el parámetro dinámico de la URL

  useEffect(() => {
    const fetchLayout = async () => {
      // cookie data
      const storedPortal = cookies.get('portal');

      if (storedPortal && storedPortal.portal_layout) {
        //  
        setLayout(parseInt(storedPortal.portal_layout, 10));
      } else {
        // por default 1
        setLayout(1);
      }
    };

    fetchLayout();
  }, [id]);

  if (layout === null) return <div>loading...</div>; 

  // 1 = Layout Original, 2 = Layout Alternativo
  const ComponentToRender = layout === 1 ? DefaultComponent : AlternativeComponent;

  return <ComponentToRender />;
};

export default DynamicRouteWrapper;
