import React, { Fragment } from 'react';
import './chapter.css'
const Chapter7_5 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>3.4 Best Practices</h2>
      <p>
        When using comments in AVAP™, it is important to follow some best practices:
      </p>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Use comments moderately and only when necessary to clarify the code.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Keep comments updated as the code evolves.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Write clear and concise comments that are easy for other developers to understand.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Avoid redundant or unnecessary comments that do not provide useful information to the reader.</li>
      </ul>
    </div>
  </Fragment>
);

export default Chapter7_5;
