import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Image, Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';

import logo_framework from '../../../assets/img/avap_logo.png';

interface HeaderProps {
  isDashboardBlue?: any; // Aquí especificas que 'sider' puede ser de cualquier tipo
}

// Utilizamos forwardRef para permitir el uso de referencias desde el componente padre
const BrowserNavbar = forwardRef((props: HeaderProps, ref) => {
  let { path, url } = useRouteMatch();
  const history = useHistory();

  const [avapBut, setavapBut] = useState(true);
  const [vsBut, setvsBut] = useState(false);
  const [gBut, setgBut] = useState(false);
  const [dsBut, setdsBut] = useState(false);
  const [cloudBut, setcloudBut] = useState(false);
  const [CnBut, setcnBut] = useState(false);

  const [menu1, setmenu1] = useState(false);
  const [menu2, setmenu2] = useState(false);
  const [menu3, setmenu3] = useState(false);
  const [menu4, setmenu4] = useState(false);
  const [menu5, setmenu5] = useState(false);

  // Función para limpiar todos los menús
  const cleanMenuAll = () => {
    setmenu1(false);
    setmenu2(false);
    setmenu3(false);
    setmenu4(false);
    setmenu5(false);
  };

  // Función para limpiar todos los botones
  const cleanAll = () => {
    setavapBut(false);
    setvsBut(false);
    setgBut(false);
    setdsBut(false);
    setcloudBut(false);
    setcnBut(false);
  };

  // Exponemos las funciones cleanMenuAll y cleanAll a través de la referencia
  useImperativeHandle(ref, () => ({
    cleanMenuAll,
    cleanAll,
  }));




  return (
    <Navbar className='navbar' style={{ position: 'fixed', width: '100%' }}>
      <Container fluid="lg" className="">
        <Navbar.Brand href="/framework" style={{}}>
          <div className="brandContainer" style={{ backgroundImage: `url(${logo_framework})`, }}></div>
        </Navbar.Brand>
        <Nav className='navWidth mr-auto'>
          <Nav.Link onClick={() => { cleanMenuAll(); setmenu1(!menu1) }} >Products <div hidden={!menu1} style={{ position: 'absolute', backgroundColor: '#d28e22', height: '4px', width: '70px', marginTop: '12px' }}></div></Nav.Link>
          <Nav.Link onClick={() => { cleanMenuAll(); setmenu2(!menu2) }} >Services <div hidden={!menu2} style={{ position: 'absolute', backgroundColor: '#d28e22', height: '4px', width: '70px', marginTop: '12px' }}></div></Nav.Link>
          <Nav.Link onClick={() => { history.push('/ai'); cleanMenuAll() }} >AI Brunix <div hidden={!menu3} style={{ position: 'absolute', backgroundColor: '#d28e22', height: '4px', width: '70px', marginTop: '12px' }}></div></Nav.Link>
          <Nav.Link onClick={() => { history.push('/avapid'); cleanMenuAll() }} >AVAP ID <div hidden={!menu3} style={{ position: 'absolute', backgroundColor: '#d28e22', height: '4px', width: '70px', marginTop: '12px' }}></div></Nav.Link>
          <Nav.Link onClick={() => { cleanMenuAll(); setmenu4(!menu4) }} >Resources<div hidden={!menu4} style={{ position: 'absolute', backgroundColor: '#d28e22', height: '4px', width: '70px', marginTop: '12px' }}></div></Nav.Link>
          <Nav.Link onClick={() => { cleanMenuAll(); setmenu5(!menu5) }} >Company  <div hidden={!menu5} style={{ position: 'absolute', backgroundColor: '#d28e22', height: '4px', width: '70px', marginTop: '12px' }}></div></Nav.Link>
        </Nav>

        <div className="form-inline">
          <button
            className='custom-button-green textBold resButton'
            onClick={() => { history.push('/login') }}
          >
            <span className='navHide-inverse'>Sign in with your AVAP ID</span>
            <span className='navHide'>Sign in</span>
          </button>
        </div>
      </Container>

      {menu1 &&
        <><div style={{
          width: '80%',
          marginLeft: '10%',
          marginRight: 'auto',
          marginTop: '52px',
          position: 'absolute',
          height: '2px',
          backgroundColor: '#ebebeb'
        }}></div>
          <div className='fade-in-image linksBox'>


            <div style={{
              width: '100%',
              display: 'flex'
            }}>

              <div style={{
                width: '33%'
              }}>
                <div className='landingFont3'
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '30px',
                    cursor: 'pointer'
                  }}
                >
                  Build Virtual APIs
                </div>
                <div style={{
                  height: '1px',
                  backgroundColor: '#d28e22',
                  marginLeft: '20px',
                  marginRight: '20px',
                  cursor: 'pointer'
                }}>
                </div>

                <div style={{
                  paddingLeft: '20px',
                  paddingTop: '10px',
                  color: '#969696',
                  cursor: 'pointer'
                }} onClick={() => { history.push('/avap') }}>
                  AVAP (Advanced Virtual API Programming)
                </div>
                <div onClick={() => { history.push('/vsc-extensions') }}
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '10px',
                    color: '#969696',
                    cursor: 'pointer'
                  }}>
                  AVAP API Extensions for Microsoft Visual Code
                </div>
                <div style={{
                  paddingLeft: '20px',
                  paddingTop: '10px',
                  color: '#969696',
                  cursor: 'pointer'
                }} onClick={() => { history.push('/devstudio') }}>
                  AVAP Dev Studio
                </div>



              </div>

              <div style={{
                width: '33%'
              }}>
                <div className='landingFont3'
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '30px',
                    cursor: 'pointer'
                  }}
                >
                  Test & Deliver APIs
                </div>
                <div style={{
                  height: '1px',
                  backgroundColor: '#d28e22',
                  marginLeft: '20px',
                  marginRight: '20px',
                  cursor: 'pointer'
                }}>
                </div>

                <div onClick={() => { history.push('/version-control') }}
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '10px',
                    color: '#969696',
                    cursor: 'pointer'
                  }}>
                  AVAP API Version Control
                </div>
                <div onClick={() => { history.push('/api-publisher') }}
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '10px',
                    color: '#969696',
                    cursor: 'pointer'
                  }}>
                  AVAP API Publisher
                </div>

              </div>


              <div style={{
                width: '33%'
              }}>
                <div className='landingFont3'
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '30px',
                    cursor: 'pointer'
                  }}
                >
                  Run APIs
                </div>
                <div style={{
                  height: '1px',
                  backgroundColor: '#d28e22',
                  marginLeft: '20px',
                  marginRight: '20px',
                  cursor: 'pointer'
                }}>
                </div>
                {/*}
<div style={{
    paddingLeft: '20px',
    paddingTop: '10px',
    color: '#969696',
    cursor: 'pointer'
}}>
    AVAP Virtual Server
</div> */}
                {/*}    <div style={{
    paddingLeft: '20px',
    paddingTop: '10px',
    color: '#969696',
    cursor: 'pointer'
}}>
    AVAP Community Edition
</div>*/}
                <div style={{
                  paddingLeft: '20px',
                  paddingTop: '10px',
                  color: '#969696',
                  cursor: 'pointer'
                }} onClick={() => { history.push('/avs') }}>
                  AVAP Virtual Server
                </div>
                <div style={{
                  paddingLeft: '20px',
                  paddingTop: '10px',
                  color: '#969696',
                  cursor: 'pointer'
                }} onClick={() => { history.push('/avap') }}>
                  AVAP Interpreter & Language Server
                </div>
                <div style={{
                  paddingLeft: '20px',
                  paddingTop: '10px',
                  color: '#969696',
                  cursor: 'pointer'
                }} onClick={() => { history.push('/gateway') }}>
                  Gateway Advanced
                </div>
              </div>



            </div>

            <div style={{
              width: '100%',
              display: 'flex'
            }}>

              <div style={{
                width: '33%'
              }}>
                <div className='landingFont3'
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '30px',
                    cursor: 'pointer'
                  }}
                >
                  Power Up APIs
                </div>
                <div style={{
                  height: '1px',
                  backgroundColor: '#d28e22',
                  marginLeft: '20px',
                  marginRight: '20px',
                  cursor: 'pointer'
                }}>
                </div>


                <div onClick={() => { history.push('/avap-marketplace') }}
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '10px',
                    color: '#969696',
                    cursor: 'pointer'
                  }}>
                  AVAP Marketplace
                </div>
                <div onClick={() => { history.push('/avap-connectors') }}
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '10px',
                    color: '#969696',
                    cursor: 'pointer',
                    marginBottom: '34px',
                  }}

                >
                  AVAP Connectors
                </div>
                {/*}    <div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
marginBottom:'34px',
cursor:'pointer'
}}>
AVAP Virtual Machine
</div>*/}



              </div>
              {/*}
<div style={{
width:'33%'
}}>
<div className='landingFont3'
style={{
paddingLeft:'20px',
paddingTop:'30px'
}}
>
Extend APIs
</div>
<div style={{
height:'1px',
backgroundColor:'#d28e22',
marginLeft:'20px',
marginRight:'20px',
cursor:'pointer'
}}>
</div>

<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Plug Ins
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
marginBottom:'34px',
cursor:'pointer'
}}>
Connectors
</div>


</div>


*/}



            </div>


          </div></>
      }

      {menu2 &&
        <><div style={{
          width: '80%',
          marginLeft: '10%',
          marginRight: 'auto',
          marginTop: '52px',
          position: 'absolute',
          height: '2px',
          backgroundColor: '#ebebeb'
        }}></div><div className='fade-in-image linksBox' >


            <div style={{
              width: '100%',
              display: 'flex'
            }}>

              <div style={{
                width: '33%'
              }}>
                <div className='landingFont3'
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '30px',
                    cursor: 'pointer'
                  }}
                >
                  Professional
                </div>
                <div style={{
                  height: '1px',
                  backgroundColor: '#d28e22',
                  marginLeft: '20px',
                  marginRight: '20px',
                  cursor: 'pointer'
                }}>
                </div>

                <div style={{
                  paddingLeft: '20px',
                  paddingTop: '10px',
                  color: '#969696',
                  cursor: 'pointer'
                }}
                  onClick={() => { history.push('/collaborative') }}
                >
                  AVAP Collaborative Network
                </div>
                <div onClick={() => { history.push('/cloud') }}
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '10px',
                    color: '#969696',
                    cursor: 'pointer',
                    marginBottom: '34px'
                  }}>
                  AVAP Cloud Professional
                </div>
                {/*}
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Monolith to Microservices
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Power OpenAI Applications
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer',
marginBottom:'35px'
}}>
Mobile Application API Development
</div>
*/}


              </div>

              <div style={{
                width: '33%'
              }}>
                <div className='landingFont3'
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '30px',
                    cursor: 'pointer'
                  }}
                >
                  Business
                </div>
                <div style={{
                  height: '1px',
                  backgroundColor: '#d28e22',
                  marginLeft: '20px',
                  marginRight: '20px',
                  cursor: 'pointer'
                }}>
                </div>

                <div onClick={() => { history.push('/cloud') }}
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '10px',
                    color: '#969696',
                    cursor: 'pointer'
                  }}>
                  AVAP Cloud Business
                </div>
                {/*}
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Secure and Govern APIs
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
API Gateway for Istio
</div>
*/}
              </div>


              <div style={{
                width: '33%'
              }}>
                <div className='landingFont3'
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '30px',
                    cursor: 'pointer'
                  }}
                >
                  Corporate
                </div>
                <div style={{
                  height: '1px',
                  backgroundColor: '#d28e22',
                  marginLeft: '20px',
                  marginRight: '20px',
                  cursor: 'pointer'
                }}>
                </div>

                <div onClick={() => { history.push('/cloud') }}
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '10px',
                    color: '#969696',
                    cursor: 'pointer'
                  }}>
                  AVAP Cloud Enterprise
                </div>
                {/*}
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Decentralized Load Balancing
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Zero Trust Security
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Observability
</div>
*/}
              </div>

            </div>
          </div></>
      }

      {menu3 &&

        <><div style={{
          width: '80%',
          marginLeft: '10%',
          marginRight: 'auto',
          marginTop: '52px',
          position: 'absolute',
          height: '2px',
          backgroundColor: '#ebebeb'
        }}></div><div className='fade-in-image linksBox'>


            <div style={{
              width: '100%',
              display: 'flex'
            }}>

              <div style={{
                width: '33%'
              }}>
                <div className='landingFont3'
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '30px',
                    cursor: 'pointer'
                  }}
                >
                  {/*} Partner Ecosystem*/}
                </div>
                <div style={{
                  height: '1px',
                  display: 'none',
                  backgroundColor: '#d28e22',
                  marginLeft: '20px',
                  marginRight: '20px',
                  cursor: 'pointer'
                }}>
                </div>



              </div>

              <div style={{
                width: '33%'
              }}>
                <div className='landingFont3'
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '30px',
                    cursor: 'pointer'
                  }}
                >
                  {/*Find a Partner*/}
                </div>
                <div style={{
                  height: '1px',
                  display: 'none',
                  backgroundColor: '#d28e22',
                  marginLeft: '20px',
                  marginRight: '20px',
                  cursor: 'pointer'
                }}>
                </div>



              </div>


              <div style={{
                width: '33%'
              }}>
                <div className='landingFont3'
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '30px',
                    cursor: 'pointer'
                  }}
                >
                  {/*Become a partner*/}
                </div>
                <div style={{
                  height: '1px',
                  display: 'none',
                  backgroundColor: '#d28e22',
                  marginLeft: '20px',
                  marginRight: '20px',
                  cursor: 'pointer'
                }}>
                </div>


              </div>
            </div>


            <div style={{
              width: '100%',
              display: 'flex'
            }}>

              <div style={{
                width: '33%'
              }}>
                <div className='landingFont3'
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '30px',
                    cursor: 'pointer'
                  }}
                >
                  {/*Partner Login*/}
                </div>
                <div style={{
                  height: '1px',
                  backgroundColor: 'transparent',
                  marginLeft: '20px',
                  marginRight: '20px',
                  cursor: 'pointer',
                  marginBottom: '35px'
                }}>
                </div>
              </div>
            </div>
          </div></>
      }

      {menu4 &&
        <><div style={{
          width: '80%',
          marginLeft: '10%',
          marginRight: 'auto',
          marginTop: '52px',
          position: 'absolute',
          height: '2px',
          backgroundColor: '#ebebeb'
        }}></div><div className='fade-in-image linksBox' >


            <div style={{
              width: '100%',
              display: 'flex'
            }}>

              <div style={{
                width: '33%'
              }}>
                <div className='landingFont3'
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '30px',
                    cursor: 'pointer'
                  }}
                >
                  Developer Support
                </div>
                <div style={{
                  height: '1px',
                  backgroundColor: '#d28e22',
                  marginLeft: '20px',
                  marginRight: '20px',
                  cursor: 'pointer'
                }}>
                </div>

                <div style={{
                  paddingLeft: '20px',
                  paddingTop: '10px',
                  color: '#969696',
                  cursor: 'pointer'
                }}
                  onClick={() => { history.push('/default') }}
                >
                  AVAP Developer Portal
                </div>
                <div style={{
                  paddingLeft: '20px',
                  paddingTop: '10px',
                  color: '#969696',
                  cursor: 'pointer',
                  marginBottom: '34px'
                }}
                  onClick={() => { window.open('https://speed-computing-1656.my.site.com/communities/s/', '_blank') }}
                >
                  AVAP Communities
                </div>
                {/*}
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Case Studies
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Blog
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Content Library
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Learning Center
</div>

*/}

              </div>

              <div style={{
                width: '33%'
              }}>
                <div className='landingFont3'
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '30px',
                    cursor: 'pointer'
                  }}
                >
                  Knowledge Center
                </div>
                <div style={{
                  height: '1px',
                  backgroundColor: '#d28e22',
                  marginLeft: '20px',
                  marginRight: '20px',
                  cursor: 'pointer'
                }}>
                </div>

                <div style={{
                  paddingLeft: '20px',
                  paddingTop: '10px',
                  color: '#969696',
                  cursor: 'pointer'
                }}
                  onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US') }}
                >
                  AVAP Alexandria
                </div>
                <div style={{
                  paddingLeft: '20px',
                  paddingTop: '10px',
                  color: '#969696',
                  cursor: 'pointer'
                }}
                  onClick={() => { window.open('http://blog.avapframework.com') }}
                >
                  AVAP Blog
                </div>
                {/*}
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
AVAP Gateway
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
AVAP Mesh
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
AVAP AI Gateway
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
AVAP Insomnia
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
AVAP Plugin Hub
</div>
*/}
              </div>
              <div style={{
                width: '33%'
              }}>
                <div className='landingFont3'
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '30px',
                    cursor: 'pointer'
                  }}
                >
                  API Products
                </div>
                <div style={{
                  height: '1px',
                  backgroundColor: '#d28e22',
                  marginLeft: '20px',
                  marginRight: '20px',
                  cursor: 'pointer'
                }}>
                </div>


                <div onClick={() => { history.push('/avap-marketplace') }}
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '10px',
                    color: '#969696',
                    cursor: 'pointer'
                  }}>
                  AVAP Marketplace
                </div>
              </div>
              {/*}
<div style={{
width:'33%'
}}>
<div className='landingFont3'
style={{
paddingLeft:'20px',
paddingTop:'30px',
cursor:'pointer'
}}
>
Community
</div>
<div style={{
height:'1px',
backgroundColor:'#d28e22',
marginLeft:'20px',
marginRight:'20px',
cursor:'pointer'
}}>
</div>

<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
About Us
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Join the Community
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Livestreams & Events
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Champions
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Discuss
</div>
</div>

</div>

<div style={{
width:'100%',
display:'flex'
}}>

<div style={{
width:'33%'
}}>
<div className='landingFont3'
style={{
paddingLeft:'20px',
paddingTop:'30px',
cursor:'pointer'
}}
>
Developer
</div>
<div style={{
height:'1px',
backgroundColor:'#d28e22',
marginLeft:'20px',
marginRight:'20px',
cursor:'pointer'
}}>
</div>


<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Contributor Program
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer',
marginBottom:'34px',
}}>
GitHub
</div>

</div>

<div style={{
width:'33%'
}}>
<div className='landingFont3'
style={{
paddingLeft:'20px',
paddingTop:'30px'
}}
>
Academy
</div>
<div style={{
height:'1px',
backgroundColor:'#d28e22',
marginLeft:'20px',
marginRight:'20px',
cursor:'pointer'
}}>
</div>

<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Certification
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
marginBottom:'34px',
cursor:'pointer'
}}>
Education
</div>
</div> */}
            </div>

          </div></>
      }
      {menu5 &&
        <><div style={{
          width: '80%',
          marginLeft: '10%',
          marginRight: 'auto',
          marginTop: '52px',
          position: 'absolute',
          height: '2px',
          backgroundColor: '#ebebeb'
        }}></div><div className='fade-in-image linksBox'>


            <div style={{
              width: '100%',
              display: 'flex'
            }}>

              <div style={{
                width: '33%',
              }}>
                <div className='landingFont3'
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '30px',
                    cursor: 'pointer'
                  }}
                >
                  Company
                </div>
                <div style={{
                  height: '1px',
                  backgroundColor: '#d28e22',
                  marginLeft: '20px',
                  marginRight: '20px',
                  cursor: 'pointer',
                }}>
                </div>

                <div onClick={() => { history.push('/about') }}
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '10px',
                    color: '#969696',
                    cursor: 'pointer'
                  }}>
                  About Us
                </div>
                <div style={{
                  paddingLeft: '20px',
                  paddingTop: '10px',
                  color: '#969696',
                  cursor: 'pointer',
                  marginBottom: '34px'
                }} onClick={() => { window.open('mailto: info@101obex.com', '_self') }}>
                  Contact Us
                </div>
                {/*}
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Press Room
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Investors
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Contact Us
</div>
*/}

              </div>

              <div style={{
                width: '33%'
              }}>
                <div className='landingFont3'
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '30px',
                    cursor: 'pointer'
                  }}
                >
                  Legal
                </div>
                <div style={{
                  height: '1px',
                  backgroundColor: '#d28e22',
                  marginLeft: '20px',
                  marginRight: '20px',
                  cursor: 'pointer'
                }}>
                </div>

                <div onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/avap-collaborative-network-terms-conditions-MCXYCPQ5L53JDSFN4HXYI66J2764?language=en_US') }}
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '10px',
                    color: '#969696',
                    cursor: 'pointer'
                  }}>
                  Terms & Conditions AVAP Collaborative Networks
                </div>
                <div onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/avap-cloud-professional-business-terms-conditions-MCOYQYTA5VQNDZDAS5UGXSU3GEV4?language=en_US') }}
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '10px',
                    color: '#969696',
                    cursor: 'pointer'
                  }}>
                  Terms & Conditions AVAP Cloud
                </div>
                <div onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/privacy-policy-MCSYZARSDENBDAVITQIIO6OWFYLI?language=en_US') }}
                  style={{
                    paddingLeft: '20px',
                    paddingTop: '10px',
                    color: '#969696',
                    cursor: 'pointer',
                    marginBottom: '34px'
                  }}>
                  Privacy Policy
                </div>
                {/*}
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Documentation
</div>


</div>


<div style={{
width:'33%'
}}>
<div className='landingFont3'
style={{
paddingLeft:'20px',
paddingTop:'30px',
cursor:'pointer'
}}
>
Events
</div>
<div style={{
height:'1px',
backgroundColor:'#d28e22',
marginLeft:'20px',
marginRight:'20px',
cursor:'pointer'
}}>
</div>

<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
API Summit by AVAP
</div>
<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Upcomming Events
</div>

</div>

</div>

<div style={{
width:'100%',
display:'flex'
}}>

<div style={{
width:'33%'
}}>


</div>

<div style={{
width:'33%'
}}>

</div>

<div style={{
width:'33%',
marginTop:'-100px',
marginBottom:'35px'
}}>
<div className='landingFont3'
style={{
paddingLeft:'20px',
paddingTop:'30px'
}}
>
Security
</div>
<div style={{
height:'1px',
backgroundColor:'#d28e22',
marginLeft:'20px',
marginRight:'20px',
cursor:'pointer'
}}>
</div>

<div style={{
paddingLeft:'20px',
paddingTop:'10px',
color:'#969696',
cursor:'pointer'
}}>
Trust and Compilance
</div>

*/}

              </div>

            </div>
          </div></>
      }

    </Navbar>
  );

});

export default BrowserNavbar;