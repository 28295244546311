import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
        <div>
      <h3>Specific Notation Rules</h3>
      <p>
        In addition to general conventions, AVAP™ follows specific notation rules for different elements of the language, including:
      </p>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Variables</strong>: Variable names should be descriptive and meaningful, using lowercase letters and underscores to separate words if necessary for readability (e.g., <code>variable_name</code>).
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Functions</strong>: Function names should follow the same conventions as variables, with the addition of parentheses to indicate function parameters (e.g., <code>function_name(parameter1, parameter2)</code>).
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Constants</strong>: Constants are typically written in uppercase letters with underscores separating words (e.g., <code>EXAMPLE_CONSTANT</code>).
        </li>
      </ul>
      <p>
        The descriptions of lexical analysis and syntax use a modified{' '}
        <a href="https://en.wikipedia.org/wiki/Backus%E2%80%93Naur_form" target="_blank" rel="noopener noreferrer">
          Backus–Naur form (BNF)
        </a>{' '}
        grammar notation. This uses the following style of definition:
      </p>
      <pre>
        {`<program> ::= <statement_list>
<statement_list> ::= <statement> | <statement> <statement_list>
<statement> ::= <global_assignment> | <local_assignment> | <command>
<global_assignment> ::= "addVar(" <string_value> "," <variable_name> ")"
<local_assignment> ::= <variable_name> "=" <value>
<string_value> ::= "\"" <string_content> "\""
<string_content> ::= <string_part> | <string_part> <string_content>
<string_part> ::= <text> | <variable_reference>
<text> ::= <character> | <character> <text>
<variable_reference> ::= "${" <variable_name> "}"
<variable_name> ::= <letter> | <letter> <variable_name>
<value> ::= <string_value> | <number> | <expression>
<number> ::= <digit> | <digit> <number>
<expression> ::= <value> | <value> <operator> <value>
<operator> ::= "+" | "-" | "*" | "/"
<command> ::= <any_valid_command_syntax>
<character> ::= any character except \`" \` and \`\\\`
<letter> ::= "a" | "b" | "c" | "d" | "e" | "f" | "g" | "h" | "i" | "j" | "k" | "l" | "m" | "n" | "o" | "p" | "q" | "r" | "s" | "t" | "u" | "v" | "w" | "x" | "y" | "z" | "A" | "B" | "C" | "D" | "E" | "F" | "G" | "H" | "I" | "J" | "K" | "L" | "M" | "N" | "O" | "P" | "Q" | "R" | "S" | "T" | "U" | "V" | "W" | "X" | "Y" | "Z" | "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9" | "_"
<digit> ::= "0" | "1" | "2" | "3" | "4" | "5" | "6" | "7" | "8" | "9"`}</pre>
    </div>
  </Fragment>
);
export default Intro_1;
