type UserSessionData = {
  id_sesion: string,
  id_usuario: string
}

class SessionStorageHandler {

  private static userSessionDataKey = 'userSessionData';
  
  public static setUserSessionData(sessionId: string, userId: string):void {
    const userSessionData:UserSessionData = {
      id_sesion: sessionId,
      id_usuario: userId
    };
    sessionStorage.setItem(this.userSessionDataKey, JSON.stringify(userSessionData));
  }

  public static getUserSessionData(): UserSessionData {
    const userSessionData:any = sessionStorage.getItem(this.userSessionDataKey);
    return JSON.parse(userSessionData);
  }

  public static deleteUserSessionData(): void {
    sessionStorage.removeItem(this.userSessionDataKey);
  }

}

export default SessionStorageHandler;