import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';

const EditLabelsForm = () => {
    const [formData, setFormData] = useState({
        version: '1.0',
        labels: [{ key: '', value: '' }],
    });

    const handleVersionChange = (e) => {
        if (e.target) {
            setFormData((prevData) => ({
                ...prevData,
                version: e.target.value,
            }));
        }
    };

    const handleLabelChange = (index, field, value) => {
        const updatedLabels = [...formData.labels];
        updatedLabels[index][field] = value;
        setFormData((prevData) => ({
            ...prevData,
            labels: updatedLabels,
        }));
    };

    const handleAddLabel = () => {
        if (formData.labels.length < 50) {
            setFormData((prevData) => ({
                ...prevData,
                labels: [...prevData.labels, { key: '', value: '' }],
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault(); 
        console.log("Form Data Submitted:", formData);
    };

    return (
        <Form className="cloudForm" onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
            <h2>Edit Labels</h2>
            <p>A maximum of 50 user-defined labels are allowed. For syntax rules, <a href="#">View Documentation</a></p>

            {/* Version Field */}
            <Form.Group controlId="version">
                <Form.Label>Version</Form.Label>
                <Form.Control
                    type="text"
                    value={formData.version}
                    onChange={handleVersionChange}
                    placeholder="e.g., 1.0"
                />
            </Form.Group>

            {/* Labels Section */}
            <h3>Labels</h3>
            {formData.labels.map((label, index) => (
                <Row key={index} className="align-items-center">
                    <Col>
                        <Form.Group controlId={`label-key-${index}`}>
                            <Form.Label>Key</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter a key"
                                value={label.key}
                                onChange={(e) => handleLabelChange(index, 'key', e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId={`label-value-${index}`}>
                            <Form.Label>Value</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter a value"
                                value={label.value}
                                onChange={(e) => handleLabelChange(index, 'value', e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
            ))}
            <Button variant="outline-primary" onClick={handleAddLabel}>
                Add Label
            </Button>

            {/* Submit Button */}
            <div className="d-flex justify-content-end mt-4">
                <Button variant="primary" type="submit">Save Changes</Button>
            </div>
        </Form>
    );
};

export default EditLabelsForm;
