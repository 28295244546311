import React, { useState } from "react";
import { Form, Row, Col, Button, ListGroup, InputGroup, FormControl, Alert } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";

const AddUsersForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [emails, setEmails] = useState<string[]>([]);
  const [error, setError] = useState<string>("");

  const isValidEmail = (email: string) => {
    // Expresión regular para validar el formato del correo electrónico
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleAddEmail = () => {
    const trimmedEmail = email.trim();
    
    if (!trimmedEmail) {
      setError('Email input shouldn\'t be empty');
      return;
    }

    if (!isValidEmail(trimmedEmail)) {
      setError('Please adda valid email');
      return;
    }

    if (emails.includes(trimmedEmail)) {
      setError('This email is already on the list');
      return;
    }

    setEmails([...emails, trimmedEmail]);
    setEmail('');
    setError('');
  };

  const handleDeleteEmail = (index: number) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
  };

  const handleEditEmail = (index: number) => {
    const currentEmail = emails[index];
    const editedEmail = prompt('add a new email:', currentEmail);
    if (editedEmail) {
      const trimmedEmail = editedEmail.trim();
      if (!isValidEmail(trimmedEmail)) {
        setError('Please add a valid email');
        return;
      }
      if (emails.includes(trimmedEmail) && trimmedEmail !== currentEmail) {
        setError('This email is already on the list');
        return;
      }
      const updatedEmails = [...emails];
      updatedEmails[index] = trimmedEmail;
      setEmails(updatedEmails);
      setError("");
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Aquí puedes manejar el envío de las invitaciones
    // Por ahora, solo resetear la lista y mostrar un mensaje
    alert('Invitations sent');
    setEmails([]);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="emailInput" className="mb-3">
        <Form.Label>Email</Form.Label>
        <InputGroup>
          <FormControl
            type="email"
            placeholder="Add email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button variant="primary" onClick={handleAddEmail}>
            Add
          </Button>
        </InputGroup>
      </Form.Group>

      {error && (
        <Alert variant="danger" onClose={() => setError("")} dismissible>
          {error}
        </Alert>
      )}

      {emails.length > 0 && (
        <ListGroup className="mb-3">
          {emails.map((mail, index) => (
            <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
              {mail}
              <div>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  className="me-2"
                  onClick={() => handleEditEmail(index)}
                >
                  <FaEdit />
                </Button>
                <Button
                                        //variant="danger"
                                        style={{backgroundColor:'transparent', borderColor:'transparent'}}
                  size="sm"
                  onClick={() => handleDeleteEmail(index)}
                >
                 {/* <FaTrash />*/}
                 x
                </Button>
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}

      {emails.length === 0 && (
        <p className="text-muted">
          No Emails added
        </p>
      )}

      <Row className="mt-4">
        <Col md="6">
          <Button className="w-100" variant="success" type="submit" disabled={emails.length === 0}>
            Send invitation
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AddUsersForm;
