import React from 'react';
import './chapter.css'
const Chapter9_7 = () => (
    <div>
        <h2>5.1. Import Rules</h2>
        <ol>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <strong>Position of Import</strong>: The <code>import</code> statement must be placed at the exact location where the content of the imported file is to be included. The content of the imported file is executed linearly along with the original file.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <strong>Import Error</strong>: If the file specified in the <code>import</code> statement is not found, a <code>FileNotFoundError</code> is raised.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <strong>Scope of Imports</strong>: The functions and variables from the imported file are added to the local scope of the original file at the point of import. This means they can be accessed as if they were defined in the same file.
            </li>
        </ol>
    </div>
);

export default Chapter9_7;
