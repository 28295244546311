import React, { FunctionComponent, useEffect, useState, Fragment } from 'react';
import Logo from '../../../assets/img/logodeveloper-pequeño.png';
import faUserCircle2 from '../../../assets/img/icono.png';
import { Row, Col, Dropdown, Form, Image, Nav, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory } from 'react-router-dom';
import SessionExpired from '../../../components/Modals/sessionExpired';
import InactivityModal from '../../../components/Modals/inactivityModal';
import Cookies from 'universal-cookie';
import SessionService from '../../../services/SessionsService';
import ReactGA from 'react-ga4';
import AddOrganizationModal from '../../../components/Modals/addOrganizationModal';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import logo from "../../../assets/img/newDashboard/logo_AVAPID.png"
import icono_menu_flecha_abajo from "../../../assets/img/newDashboard/icono_menu_flecha_abajo.png"
import config from '../../../configs/appConfig.json'

// import './NewHeaderMenu.css';

import allDataSidebarApis from "../../../configs/routers/new-apis-router";

//redux
import { useDispatch, useSelector } from 'react-redux';
import { updateSendToNameSection } from '../../../redux/slices/KnowDivInScreen';

//../../Modals/sessionExpired

const HeaderAVAPCLOUD: FunctionComponent<{}> = () => {

  const history = useHistory();
  const [isLoggedIn, setLogged] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [inactivity, setInactivity] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [nombreOrganizacion, setNameOrg] = useState('');
  const [currentOrganizations, setCurrentOrganizations] = useState(0);
  const [maxOrganizations, setMaxOrganizations] = useState(0);
  const cookies = new Cookies();

  const [usersuscriptor, setusersuscriptor] = useState(true);
  const [developer, setDeveloper] = useState(false);
  const [client, setClient] = useState(false);

  const dispatch = useDispatch()

  const analyticsTags = [
    'G-KKCW4SZLW4',

  ];
  //ReactGA.initialize('UA-170480071-2');





  const handleLogout = (e) => {
    e.preventDefault();
    e.stopPropagation();
    SessionService.logout();
    history.push('/login');

  }

  const getProjects = async () => {
    try {
      setProjects([]);
      let projects = null;
      if (SessionService.ORGANIZATIONS == null) {
        try{
        //projects = (await ObexRequestHandler.get('/organizations', {}, true)).data || [];
        projects = []
      } catch {projects = []}
        SessionService.ORGANIZATIONS = projects
      }
      else {
        projects = SessionService.ORGANIZATIONS;
      }
      if (projects.length == 0) {
        setNameOrg('My Company');
        SessionService.ORGANIZATION_NAME = 'My Company';
        SessionService.ORGANIZATION = -1;
      } else {
        if (projects.result.length == 0) {
          setNameOrg('My Company');
          SessionService.ORGANIZATION_NAME = 'My Company';
          SessionService.ORGANIZATION = -1;
        }
      }
      setCurrentOrganizations(1);
      setMaxOrganizations(1);
      setCurrentOrganizations(projects['result'].length || 0);
      setMaxOrganizations(projects['max']);

      setProjects(projects['result']);

      if (projects['result'].length > 0) {
        if (SessionService.ORGANIZATION_NAME == null) {
          setNameOrg(projects['result'][0]['name']);
          SessionService.ORGANIZATION_NAME = projects['result'][0]['name'];
          SessionService.ORGANIZATION = projects['result'][0]['id'];
        } else {
          setNameOrg(SessionService.ORGANIZATION_NAME);
        }
      } else {
        SessionService.ORGANIZATION_NAME = 'Sin Organización';
        SessionService.ORGANIZATION = -1;
      }

    } catch (error) {
      console.error('ERROR FETCHING PROJECTS ', error);


    }

  }


  useEffect(() => {

    

    if (config.url != 'https://avs-primary-pre.101obex.mooo.com'){
      ReactGA.initialize('G-KKCW4SZLW4');
      ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Cloud' })}//pageview(window.location.pathname + window.location.search);}

    const user = cookies.get('101Obex');
    if (user) {
      console.log('User data')
      console.log(user);
      setDeveloper(SessionService.Developer);
      setusersuscriptor(SessionService.User);
      setClient(SessionService.Client);
      if (performance.navigation.type == performance.navigation.TYPE_RELOAD) {
        SessionService.renewSession();
      }
      setLogged(true);
      getProjects();
    }

    console.log(allDataSidebarApis);
    
  }, [])

  // Como este componente siempre está presente ponemos aquí el listener del inactivityEvent para que lancé el modal
  document.addEventListener('inactivityEvent', (e) => {

    e.preventDefault();
    e.stopPropagation();
    setInactivity(true);
  }, false);

  document.addEventListener('organizationsChange', (e) => {

    e.preventDefault();
    e.stopPropagation();
    getProjects();

  }, false);

  //organizationsChange

  // Como este componente siempre está presente ponemos aquí el listener del sessionExpiredEvent para que lancé el modal
  document.addEventListener('sessionExpiredEvent', (e) => {

    if (sessionExpired) return;
    e.preventDefault();
    e.stopPropagation();
    setSessionExpired(true);
  }, false);

  const setName = (orga) => {

    setNameOrg(orga.name);
    SessionService.ORGANIZATION_NAME = orga.name;
    SessionService.ORGANIZATION = orga.id;
    history.push('/dashboard');
    return orga.name;
  }



  const renewSession = async (e) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      await SessionService.renewSession();
      setInactivity(false);
    } catch (error) {
      console.error(error);
    }
  }
  const handleModal = () => {

    setShowModal(!showModal);
  };

  const on_Change = (event) => {
    const value = event.target.value;

    // this will return C:\fakepath\somefile.ext
    console.log(value);

    const files = event.target.files;

    //this will return an ARRAY of File object
    console.log(files);


    const input = event.target;

    const reader = new FileReader();
    reader.onload = function () {
      const dataURL = reader.result;
      const output = document.getElementById('output') as HTMLImageElement;
      //document.getElementById('output').setAttribute( 'src',dataURL);
      output.src = dataURL.toString();
      console.log(dataURL)
    };
    reader.readAsDataURL(input.files[files.length - 1]);

    //setImage(files[(files.length)-1].name);

    //setNoUpload(false);

  }

  const getAndSetNameSection = (item: any) => {

    const path = item.links[0] ? item.links[0].path : "none";
    const nameDiv= item.id+"-"+path;
    dispatch(updateSendToNameSection(nameDiv));
    history.push('/apis/login/Accesos_EN');

  };

  return (
    <Fragment>
      <SessionExpired show={sessionExpired} handleHide={handleLogout} />
      <InactivityModal show={inactivity && !sessionExpired} renewSession={renewSession} closeSession={handleLogout} />
      <Row style={{ justifyContent: 'center' }} className={'py-0 fixed-top px-2 Newheader'} >

        <Row style={{ width: '100%' }}>

          {/* IM REMOVE THIS */}
          {/* <Col md={3} className={'pl-3 my-auto'}>
              <a href="/dashboard">
                <img className="logo" src={Logo}/>
              </a>
          </Col> */}

          <div className='styleLogo justify-content-center'>
            <div className='containerLogo'>
              <div>
                <img src={logo} alt="Logo" />
              </div>
            </div>
          </div>

          <Col>
            <div className='d-flex' style={{marginLeft:"30px",marginTop:"20px"}}>
              <div>
              <a className="link link-header fonsize14 color1Green" onClick={() => history.push('/docs/download/download_101cli')} style={{ textAlign: 'center' }} href="">Products</a>
              </div>
              <div style={{marginLeft:"20px"}}>
              <a className="link link-header fonsize14 color1Green" onClick={() => history.push('/docs/download/download_101cli')} style={{ textAlign: 'center' }} href="">Partners</a>
              </div>
              <div style={{marginLeft:"20px"}}>
              <a className="link link-header fonsize14 color1Green" onClick={() => history.push('/docs/download/download_101cli')} style={{ textAlign: 'center' }} href="">Resources</a>
              </div>
              <div style={{marginLeft:"20px"}}>
              <a className="link link-header fonsize14 color1Green" onClick={() => history.push('/docs/download/download_101cli')} style={{ textAlign: 'center' }} href="">Company</a>
              </div>
              <div style={{marginLeft:"20px"}}>
              <a className="link link-header fonsize14 color1Green" onClick={() => history.push('/docs/download/download_101cli')} style={{ textAlign: 'center' }} href="">Contact Sales</a>
              </div>
            </div>
           {/* <Row className="justify-content-center" style={{ marginTop: "10px", marginRight: "250px" }}>
              <Col xs="auto">
                <a className="link link-header fonsize14 color" onClick={() => history.push('/docs/download/download_101cli')} style={{ textAlign: 'center' }} href="">Products</a>
              </Col>
              <Col xs="auto">
                <a className="link link-header fonsize14 color" onClick={() => history.push('/docs/download/download_101cli')} style={{ textAlign: 'center' }} href="">Solutions</a>
              </Col>
              <Col xs="auto">
                <a className="link link-header fonsize14 color" onClick={() => history.push('/docs/download/download_101cli')} style={{ textAlign: 'center' }} href="">Resources</a>
              </Col>
              <Col xs="auto">
                <a className="link link-header fonsize14 color" onClick={() => history.push('/docs/download/download_101cli')} style={{ textAlign: 'center' }} href="">Company</a>
              </Col>
            </Row>*/}
          </Col>
          <Col className='d-flex align-items-center justify-content-end'>
            <Row className="justify-content-end" style={{  marginRight: "10px" }}>
              <div >
                {/* <a className=" link-header fonsize14 color" onClick={() => history.push('/apis/login/Accesos_EN')} style={{ textAlign: 'center', color:"#4b7b68" }}>API Reference</a> */}
                <Nav className='color'>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    title={
                      <span>
                        API Reference <img src={icono_menu_flecha_abajo} alt="custom arrow" className="custom-dropdown-icon" />
                      </span>
                    }
                    className='color1Green'
                    style={{ color: "#4b7b68" }}
                  >
                    {allDataSidebarApis.menus.map((item, index) => (
                      <NavDropdown.Item onClick={() => getAndSetNameSection(item)} className='text-capitalize custom-dropdown-item'>{item.id}</NavDropdown.Item>
                    ))}
                  </NavDropdown>
                </Nav>

              </div>
              {/*
              <div className='d-flex align-items-center marginLefItemsHeader'>
                <a className=" link link-header fonsize14 color1Green"   onClick={() => history.push('/docs/download/download_101cli')} style={{ textAlign: 'center',  }} >Docs</a>
              </div>
              */}
              {/*}
              <div className='d-flex align-items-center marginLefItemsHeader'>
                <a target="_blank" rel="noopener noreferrer " href="https://101obexworkspace.slack.com/" className=" link link-header fonsize14 color1Green" style={{ textAlign: 'center' }}>Support</a>
              </div>
              */}
              {/*}
              <div className='d-flex align-items-center'>
                <a className=" link link-header fonsize14 color1Green"  onClick={() => history.push('/login')} style={{ textAlign: 'center', }}>
                  <div className='link link-header fonsize14 color1Green'>
                    Sign Out <span className='fonsize12'>→</span>
                  </div>
                </a>
              </div>

              */}
            </Row>
          </Col>


          {isLoggedIn ?
            // <Col md={1} className="user text-right my-auto">
            //   <Dropdown className="mr-1">
            //     <Dropdown.Toggle className="link-user" id="obex-user-menu">{/*<FontAwesomeIcon icon={faUserCircle} size="2x"/>*/}<div style={{ height:'30px' }}><Image id='AvatarImageHeader' style={{ height:'30px' }} src={SessionService.imageContent}/></div></Dropdown.Toggle>
            //     <Dropdown.Menu>
            //       { usersuscriptor && <Dropdown.Item onClick={() => {
            //         history.push('/dashboard')}}><h5>Administrator Dashboard</h5></Dropdown.Item>}
            //       { developer && <Dropdown.Item onClick={() => {
            //         SessionService.ORGANIZATION_NAME = null;
            //         history.push('/devdashboard')}}>Developer Dashboard</Dropdown.Item>}
            //       { client && <Dropdown.Item onClick={() => {
            //         SessionService.ORGANIZATION_NAME = null;
            //         history.push('/clientdashboard')}}>Client Dashboard</Dropdown.Item>}
            //       <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
            //     </Dropdown.Menu>{' '}
            //   </Dropdown>
            // </Col>
            <></>
            :
            // <Col md={2} className="login text-right my-auto">
            //   <a style={{ height:'47px', width:'200px', marginRight:'-20px', paddingTop:'12px', fontSize:'16px' }} href="/login" className="link-login">Log into Developer Portal</a>
            // </Col>
            <></>
          }


          {/* <Col md={1}>
            <SelectLanguage/> 
          </Col>*/}


        </Row>
      </Row>

      {showModal && <AddOrganizationModal opened={showModal} updateTable={null} show={showModal} handleClose={handleModal} regions={[]} organization_devs={[]} />}


    </Fragment>
  );
}

export default HeaderAVAPCLOUD;