import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>Lexical Components in AVAP™</h2>
      <p>The lexical components in AVAP™ are similar to those in other programming languages like Python. Some of the most common lexical components in AVAP™ include:</p>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Keywords:</strong> These are reserved words that have a special meaning in the language and cannot be used as variable or function names. Examples of keywords in AVAP™ include <code>if</code>, <code>else</code>, <code>for</code>, <code>while</code>, <code>return</code>, among others.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Identifiers:</strong> These are names given to variables, functions, and other elements of the program by the programmer. Identifiers must follow certain formatting rules and cannot match keywords. For example, <code>variable</code>, <code>example_function</code>, <code>result</code> are examples of identifiers in AVAP™.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Operators:</strong> These are symbols used to perform operations in the program. Examples of operators in AVAP™ include <code>+</code>, <code>-</code>, <code>*</code>, <code>/</code>, <code>=</code>, <code>==</code>, <code>!=</code>, among others.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Literals:</strong> These represent constant values in the program, such as integers, floating-point numbers, text strings, and boolean values. Examples of literals in AVAP™ include <code>10</code>, <code>3.14</code>, <code>"text"</code>, <code>True</code>, <code>False</code>, among others.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Punctuation Symbols:</strong> These are special characters used to separate elements of the code and define the structure of the program. Examples of punctuation symbols in AVAP™ include <code>()</code>, <code>{}</code>, <code>[]</code>, <code>,</code>, <code>:</code>, <code>;</code>, among others.</li>
      </ul>
    </div>
  </Fragment>
);
export default Intro_1;
