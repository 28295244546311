import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';

const Remittance_Information = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status":true,
        "result":{
           "steps":[
              {
                 "parameters_next_step":[
                    "REFERENCE_REMITTANCE",
                    "REPEAT_REFERENCE_REMITTANCE"
                 ],
                 "fields":[
                    {
                       "encrypted":false,
                       "scanner":false,
                       "class":5,
                       "editable":true,
                       "label":"Reference number",
                       "required":true,
                       "longitude":40,
                       "type":"AN",
                       "set":1,
                       "alias":"Reference number",
                       "value":"",
                       "name":"REFERENCE_REMITTANCE"
                    },
                    {
                       "encrypted":false,
                       "scanner":false,
                       "class":5,
                       "editable":true,
                       "label":"REPEAT Reference number",
                       "required":true,
                       "longitude":40,
                       "type":"AN",
                       "set":1,
                       "alias":"REPEAT Reference number",
                       "value":"",
                       "name":"REPEAT_REFERENCE_REMITTANCE"
                    }
                 ],
                 "service_id_next_step":"1"
              },
              {
                 "parameters_next_step":[
                    "REFERENCE_REMITTANCE",
                    "IDENTIFICATION",
                    "NO_IDENTIFICATION"
                 ],
                 "fields":[
                    {
                       "encrypted":false,
                       "scanner":false,
                       "class":0,
                       "editable":false,
                       "required":false,
                       "longitude":8,
                       "type":"NM",
                       "set":0,
                       "alias":"Importe",
                       "value":"",
                       "name":"MONTO_A_ENTREGAR"
                    },
                    {
                       "encrypted":false,
                       "scanner":false,
                       "class":0,
                       "editable":false,
                       "label":"Nombre",
                       "required":false,
                       "longitude":30,
                       "type":"AN",
                       "set":1,
                       "alias":"Nombre",
                       "value":"",
                       "name":"NOMBRE"
                    },
                    {
                       "encrypted":false,
                       "scanner":false,
                       "class":0,
                       "editable":false,
                       "label":"Fathers surname",
                       "required":false,
                       "longitude":30,
                       "type":"AN",
                       "set":1,
                       "alias":"Fathers surname",
                       "value":"",
                       "name":"FATHER_SURNAME"
                    },
                    {
                       "encrypted":false,
                       "scanner":false,
                       "class":0,
                       "editable":false,
                       "label":"Mothers surname",
                       "required":false,
                       "longitude":30,
                       "type":"AN",
                       "set":1,
                       "alias":"Mothers surname",
                       "value":"",
                       "name":"MOTHER_SURNAME"
                    },
                    {
                       "encrypted":false,
                       "scanner":false,
                       "class":0,
                       "editable":false,
                       "label":"Birthdate",
                       "required":false,
                       "longitude":10,
                       "type":"AN",
                       "set":1,
                       "alias":"Birthdate",
                       "value":"",
                       "name":"BIRTHDATE"
                    },
                    {
                       "encrypted":false,
                       "scanner":false,
                       "class":0,
                       "editable":false,
                       "label":"Street",
                       "required":false,
                       "longitude":60,
                       "type":"AN",
                       "set":1,
                       "alias":"Street",
                       "value":"",
                       "name":"STREET"
                    },
                    {
                       "encrypted":false,
                       "scanner":false,
                       "class":0,
                       "editable":false,
                       "label":"Outdoor Number",
                       "required":false,
                       "longitude":10,
                       "type":"AN",
                       "set":1,
                       "alias":"Outdoor Number",
                       "value":"",
                       "name":"OUTDOOR_NUMBER"
                    },
                    {
                       "encrypted":false,
                       "scanner":false,
                       "class":0,
                       "editable":false,
                       "label":"Interior number",
                       "required":false,
                       "longitude":10,
                       "type":"AN",
                       "set":1,
                       "alias":"Interior number",
                       "value":"",
                       "name":"INTERIOR_NUMBER"
                    },
                    {
                       "encrypted":false,
                       "scanner":false,
                       "class":0,
                       "editable":false,
                       "label":"Colony",
                       "required":false,
                       "longitude":40,
                       "type":"AN",
                       "set":1,
                       "alias":"Colony",
                       "value":"",
                       "name":"COLONY"
                    },
                    {
                       "encrypted":false,
                       "scanner":false,
                       "class":0,
                       "editable":false,
                       "label":"CP",
                       "required":false,
                       "longitude":15,
                       "type":"AN",
                       "set":1,
                       "alias":"CP",
                       "value":"",
                       "name":"CP"
                    },
                    {
                       "encrypted":false,
                       "scanner":false,
                       "class":5,
                       "editable":true,
                       "label":"Reference number",
                       "required":true,
                       "longitude":40,
                       "type":"AN",
                       "set":1,
                       "alias":"Reference number",
                       "value":"",
                       "name":"REFERENCE_REMITTANCE"
                    },
                    {
                       "set":1,
                       "encrypted":false,
                       "alias":"Type of ID",
                       "opciones":[
                          "National Electoral Institute (INE/IFE)",
                          "passport",
                          "Military Primer",
                          "Professional certificate",
                          "Consular registration",
                          "INAPAM card",
                          "RFC",
                          "CURP",
                          "Driver's license",
                          "FM2 SRE INMI",
                          "FM3 SRE INMI",
                          "UNIQUE MILITARY IDENTITY CARD"
                       ],
                       "type":"CH",
                       "value":"",
                       "name":"IDENTIFICATION",
                       "class":8,
                       "required":true,
                       "longitude":40
                    },
                    {
                       "encrypted":false,
                       "scanner":false,
                       "class":5,
                       "editable":true,
                       "label":"ID number",
                       "required":true,
                       "longitude":20,
                       "type":"AN",
                       "set":1,
                       "alias":"ID number",
                       "value":"",
                       "name":"NO_IDENTIFICATION"
                    }
                 ],
                 "service_id_next_step":"17"
              }
           ]
        },
        "necesita_firma":false,
        "divisa":"MXN",
        "codtran":"<19361>",
        "elapsed":0.6837360858917236
     }`,
      copied: false
    },
    answe_ok2: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status":false,
        "level":"<str>",
        "message":"<str>",
        "error":{
           "code":"***",
           "message":"************"
        }
     }`,
      copied: false
    },
    answe_ok3: {
      title: "Request",
      type: "json",
      code: `curl "https://example.com/ws/pagodeservicios.py/infoservicio?country_code=MX&id_usuario=9&version_bills=2&id_servicio=32001"`,
      copied: false
    }
  };

  return (
    <Fragment>

      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >Remittance Information</h1>
          <p>
            This endpoint requests information about the required fields of the
            service, in this case 32001 &#40;Remittance&#41;. After obtaining the
            fields we proceed to perform the next step, remittance payment.
            <br></br>
            <span className="label post">GET:</span>
            <span> </span> <code>URL_BASE + /ws/pagodeservicios.py/infoservicio</code>
          </p>
          <h3 >Receives:</h3>
          <table>
            <thead>
              <tr>
                <th align="center">Parameter</th>
                <th align="center">Obligatory</th>
                <th align="center">Format</th>
                <th align="center">Description</th>
                <th align="center">Default value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="center">country_code</td>
                <td align="center">Yes</td>
                <td align="center">
                  <a href="https://www.iso.org/glossary-for-iso-3166.html">
                    ISO 3166 alpha-2
                  </a>
                </td>
                <td align="center">Country code in which the user resides</td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="center">channel_id</td>
                <td align="center">No</td>
                <td align="center">Numeric</td>
                <td align="center">Channel from which the request is made.</td>
                <td align="center">1 &#40;Web&#41;</td>
              </tr>
              <tr>
                <td align="center">version_bills</td>
                <td align="center">Yes</td>
                <td align="center">Numeric</td>
                <td align="center">Version used to consume the service</td>
                <td align="center">1</td>
              </tr>
            </tbody>
          </table>
          <p>
            <br></br>
          </p>

          <p>
            <br></br>
          </p>
          <h3 >Example OK answer:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h3 >Example KO answer:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok2} />
          </div>

        </div>
        <div className='col-md-5'>
          <h3 >Example requests:</h3>

          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode1 codeToshow={codes.answe_ok3} />
            </div>

          </div>

        </div>

      </div>

    </Fragment>
  );
}
export default Remittance_Information;
