import React from 'react';
import './chapter.css'
const Chapter9_26 = () => (
    <div>
        <h2>Binary Bitwise Operations</h2>
        <p>
            Each of the three binary bitwise operations has a different level of precedence:
        </p>
        <pre>
            <code>
{`and_expr ::= shift_expr | and_expr "&" shift_expr
xor_expr ::= and_expr | xor_expr "^" and_expr
or_expr ::= xor_expr | or_expr "|" xor_expr
`}
            </code>
        </pre>
        <p>
            * The <code>&</code> operator produces the bitwise AND of its arguments, which must be integers.
        </p>
        <p>
            * The <code>^</code> operator produces the bitwise XOR (exclusive OR) of its arguments, which must be integers.
        </p>
        <p>
            * The <code>|</code> operator produces the bitwise OR (inclusive OR) of its arguments, which must be integers.
        </p>
    </div>
);

export default Chapter9_26;
