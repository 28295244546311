import React, { Fragment } from 'react';
import './chapter.css'
const Chapter11_2 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>7.1 Syntax of the Loop Statement</h2>
      <p>The full syntax of the loop statement in AVAP™ is as follows:</p>
      <pre>
        <code>
          {`startLoop(control, start, end) // Code block to repeat endLoop()`}
        </code>
      </pre>
      <p>This syntax consists of three main parts:</p>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>control</strong>: This is the loop control variable used to track the progress of the loop. It is initialized with the starting value of the loop and is incremented with each iteration until it reaches the end value.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>start</strong>: This is the starting value of the loop. The loop begins at this value.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>end</strong>: This is the ending value of the loop. The loop terminates when the control variable reaches this value.</li>
      </ul>
    </div>
  </Fragment>
);

export default Chapter11_2;
