import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>Lexical Analysis Process</h2>
      <p>The lexical analysis process in AVAP™ consists of several steps:</p>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Scanning:</strong> The source code is read sequentially, and the lexical components are identified. Regular expressions are used to recognize patterns corresponding to keywords, identifiers, operators, etc.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Tokenization:</strong> The identified lexical components are converted into tokens, which are objects representing each component with its associated type and value.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Token Generation:</strong> The generated tokens are passed to the next step of the compilation or interpretation process for syntactic and semantic analysis.</li>
      </ul>
    </div>
  </Fragment>
);
export default Intro_1;
