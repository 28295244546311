import React, { useEffect, useState } from 'react';
import { Button, Row, Col, } from 'react-bootstrap';
const FormEdit = ({ row }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (row) {
      setName(row.name || '');
      setDescription(row.description || '');
    }
  }, [row]);

  return (
    <form className='cloudForm'>
      <div className="form-group">
        <label htmlFor="name">Name</label>
        <input
          type="text"
          className="form-control"
          id="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="form-group">
        <label htmlFor="description">Description</label>
        <textarea
          className="form-control"
          id="description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
      </div>

      <Row>
        <Col md='6'>
          <Button type="submit" variant='primary'  className="w-100">
            Save Changes
          </Button>
        </Col>
      </Row>
    </form>
  );
};

export default FormEdit;
