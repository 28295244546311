import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const ApiAccessApprovalForm = () => {
    const [formData, setFormData] = useState({
        manualApproval: false,
        multipleApiSubscriptions: false,
        thirdPartyRedirect: false,
        oauthRequestLimit: 0,
        dynamicClientRegistration: false,
    });

    const handleToggleChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: checked,
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: parseInt(value, 10),
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("API Access Approval Data:", formData);
        // Aquí puedes añadir la lógica de envío al backend
    };

    const handleCancel = () => {
        // Reinicia el formulario
        setFormData({
            manualApproval: false,
            multipleApiSubscriptions: false,
            thirdPartyRedirect: false,
            oauthRequestLimit: 0,
            dynamicClientRegistration: false,
        });
        console.log("Form reset");
    };

    return (
        <Form className="cloudForm" onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
            <h2>API Access Approval</h2>

            {/* Manual Access Approval */}
            <Form.Group controlId="manualApproval">
                <Form.Check
                    type="switch"
                    label="Manual access approval"
                    name="manualApproval"
                    checked={formData.manualApproval}
                    onChange={handleToggleChange}
                />
                <Form.Text className="text-muted">
                    Enable to manually approve all subscription requests in the ‘Key Request’ section.
                </Form.Text>
            </Form.Group>

            {/* Multiple API Subscriptions */}
            <Form.Group controlId="multipleApiSubscriptions">
                <Form.Check
                    type="switch"
                    label="Multiple API Subscriptions"
                    name="multipleApiSubscriptions"
                    checked={formData.multipleApiSubscriptions}
                    onChange={handleToggleChange}
                />
                <Form.Text className="text-muted">
                    User will be able to subscribe to any combination of exposed API Products of the same authentication type.
                </Form.Text>
            </Form.Group>

            {/* Third Party Redirect */}
            <Form.Group controlId="thirdPartyRedirect">
                <Form.Check
                    type="switch"
                    label="Third Party Redirect"
                    name="thirdPartyRedirect"
                    checked={formData.thirdPartyRedirect}
                    onChange={handleToggleChange}
                />
                <Form.Text className="text-muted">
                    Enable this option to redirect subscription requests to a third party.
                </Form.Text>
            </Form.Group>

            {/* Number of OAuth Requests Allowed */}
            <Form.Group controlId="oauthRequestLimit">
                <Form.Label>Number of OAuth Requests Allowed</Form.Label>
                <Form.Control
                    type="number"
                    name="oauthRequestLimit"
                    value={formData.oauthRequestLimit}
                    onChange={handleInputChange}
                    placeholder="Define number of allowed OAuth requests"
                />
                <Form.Text className="text-muted">
                    0 and -1 means unlimited amount
                </Form.Text>
            </Form.Group>

            {/* Dynamic Client Registration */}
            <Form.Group controlId="dynamicClientRegistration">
                <Form.Check
                    type="switch"
                    label="Dynamic Client Registration (BETA)"
                    name="dynamicClientRegistration"
                    checked={formData.dynamicClientRegistration}
                    onChange={handleToggleChange}
                />
                <Form.Text className="text-muted">
                    Allow third party identity providers to authorize access to APIs. Learn more about dynamic client registration here.
                </Form.Text>
            </Form.Group>

            {/* Fixed Buttons */}
            <div className="d-flex justify-content-between">
                <Button variant="primary" type="submit">
                    Save Settings
                </Button>
                <Button variant="outline-secondary" onClick={handleCancel}>
                    Cancel
                </Button>
            </div>
        </Form>
    );
};

export default ApiAccessApprovalForm;
