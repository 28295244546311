import React from 'react';
import './chapter.css'
const Chapter9_17 = () => (
    <div>
        <h2>6.2.5. List Displays</h2>
        <p>
            In AVAP, lists are generated and handled differently. To construct a list, the command <code>variableToList(variable, list)</code> is used, and an item from the list is retrieved with <code>itemFromList(list, index, variable_to_store_item)</code>. To get the number of elements in the list, <code>getListLen(list, var_to_store_list_length)</code> is used.
        </p>
        <p>
            The syntax for list displays is:
        </p>
        <pre>
            <code>
{`list_display ::=  "[" [starred_list | comprehension] "]"
`}
            </code>
        </pre>
        <p>
            A list display produces a new list object, whose content is specified by a list of expressions or a comprehension. When a list of expressions is provided, its elements are evaluated from left to right and placed in the list object in that order.
        </p>
    </div>
);

export default Chapter9_17;
