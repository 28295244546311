import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';

const Origenes_de_fondos_EN = () => 
  {
    const codes ={
      answe_1: {
        title:"Answer ok",
        type: "json",
        code: `
        { 
          "status": true,
          "codtran": <str>,
          "result": <operation_result>,
          "elapsed": <float>
          }`,
        copied: false
      },
      answe_2: {
        title:"Answer ok",
        type: "json",
        code: `
        {
          "status": false,
          "nivel": <str>,
          "message": <str>,
          "error": <str>
          }`,
        copied: false
      },
      answe_3: {
        title:"Answer ok",
        type: "json",
        code: `
        {
          "origins": [ <origins de fondos del usuario> ],
          "creators": [ <origins de fondos que puede crear el usuario> ]
          }`,
        copied: false
      },
      answe_4: {
        title:"Answer ok",
        type: "json",
        code: `
        {
          "balance_fmt": <str>,
          "balance_fmt_compacto": <str>,
          "balance": <str>,
          "real_balance": <str>,
          "withholdings": <booleano>,
          "retained_balances": [<list_of_withholdings>],
          "image": <str>,
          "class": <str>,
          "authorized": <bool>,
          "permit_errase": <bool>,
          "id": <int>,
          "permit_various": <bool>,
          "currency_id_currencies": <int>,
          "type": <int>,
          "permit_income": <bool>,
          "permit_charges": <bool>,
          "name": <str>, 
          "base_class": <str>,
          "pending_of_revision": <bool>
          }`,
        copied: false
      },
      answe_5: {
        title:"Answer ok",
        type: "json",
        code: `
        {
          "amount": <float>,
          "id": <str>
          }`,
        copied: false
      },
      answe_6: {
        title:"Answer ok",
        type: "json",
        code: `
        {
          "risk": <int>,
          "name": <str>,
          "class": <str>,
          "base_class": <str>,
          "id": <int>
          }`,
        copied: false
      },
      answe_7: {
        title:"Answer ok",
        type: "json",
        code: `
        {
          "origin_id_created": <int>
          }`,
        copied: false
      },
      answe_8: {
        title:"Answer ok",
        type: "json",
        code: `
        {
          "authorized": true,
          "origin_id_confirmed": <int>
          }`,
        copied: false
      },
      answe_9: {
        title:"Answer ok",
        type: "json",
        code: `
        {
          "movements": {<movements>}
          }`,
        copied: false
      },
      answe_10: {
        title:"Answer ok",
        type: "json",
        code: `
        {
          "transaction_code": <str>,
          "concept": <str>,
          "date": <IS0 8601>,
          "amount": <float>,
          "available_balance": <float>,
          "real_balance": <float>,
          "rfc": <str>,
          "business_name": <str>,
          "transaction_status": <int>,
          "currency_id": <int>,
          "origin_account_id": <int>,
          "destination_account_id": <int>,
          "origin_id_origin": <int>,
          "origin_id_destination": <int>,
          "error_id": <str>,
          "exception": <str>,
          "channel_id": <int>
          }`,
        copied: false
      },

    };
    return(
      <Fragment>
        <h1 id="services-published-by-avanquia">
          <strong>SERVICES PUBLISHED BY AVANQUIA</strong>
        </h1>
        <h1 id="1-versions">1. VERSIONS</h1>
        <table>
          <thead>
            <tr>
              <th align="left">Version</th>
              <th align="center">In charge</th>
              <th align="center">Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">0.1: Initial Version</td>
              <td align="center">IT Waynnovate</td>
              <td align="center">26/02/2020</td>
            </tr>
          </tbody>
        </table>
        <h1 id="2-services">2. SERVICES</h1>
        <ol>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>MANAGEMENT OF OWN FUNDS RESOURCES</li>
        </ol>
        <h2 id="service-identifier">Service identifier</h2>
        <p>
          <em>SER.INT.ORI.AVA.001 MANAGEMENT OF OWN FUNDS RESOURCES.</em>
        </p>
        <h2 id="description">Description</h2>
        <p>
          This service allows managing the funds sources of a given user. It
          implements the operations of listing, creation, deletion, confirmation,
          change of default wallet and reordering on the sources of funds of said
          user.
        </p>
        <h2 id="endpoints">
          <em>Endpoints</em>
        </h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>Preproduction</p>
            <p>
              {' '}
              <code>
                https://avanquia-prews.pademobile.com/ws/originsdefondos.py/gestor_origins_propios
              </code>
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>Production</p>
            <p>
              {' '}
              <code>
                https://appws.avanquia.com/ws/originsdefondos.py/gestor_origins_propios
              </code>
            </p>
          </li>
        </ul>
        <h2 id="published-operations">Published operations</h2>
        <p>
          This service allows to execute the following operations on funds sources:
        </p>
        <ol>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <span >List of funds sources</span>:
            </p>
            <p>
              {' '}
              Allows you to list both the funds sources belonging to the user &#40;
              <em>origins</em> list&#41; and the funds sources that you can add to
              your account &#40;list <em>creators</em>&#41;.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <span >
                Creation of funds sources
              </span>
              :
            </p>
            <p>
              {' '}
              The user can add a new source of funds to his account. This source of
              funds must be described in the <em>creators</em> list.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <span >Erase source of funds</span>:
            </p>
            <p>
              {' '}
              It allows marking one of the user&#39;s funds sources as deleted. The
              sources of funds are never deleted from the system, however, when
              marking it as deleted, it will not be added to the <em>
                origins
              </em>{' '}
              list or any other internal list of the system, so for practical
              purposes these sources of funds do not exist.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <span >
                Confirm sources of funds
              </span>
              :
            </p>
            <p>
              {' '}
              Certain types of fund sources require authorization before you can
              work with them &#40;for example, credit cards&#41;. This operation
              allows you to confirm a source of funds if its type supports it
              because, as will be seen later, some types of sources of funds are
              automatically confirmed during the creation process.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <span >
                Change default source of funds
              </span>
              :
            </p>
            <p>
              {' '}
              In certain services a parameter is accepted that allows to indicate
              the origin of funds with which to carry out the operation &#40;for
              example, sending money or buying in Bills&#41;. If this parameter is
              not indicated, the default source of funds is used.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <span >Reorder funds sources</span>:
            </p>
            <p>
              {' '}
              Allows you to change the order of the user&#39;s fund source list.
              <span > </span>
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <span >
                Movements made with a funds source
              </span>
              :
            </p>
            <p>
              {' '}
              Returns a list of movements made with the indicated source of funds.
              <span > </span>
            </p>
          </li>
        </ol>
        <h2 id="parameters">Parameters</h2>
        <p>
          All parameters are sent in the call&#39;s <em>querystring</em>, so{' '}
          <strong>percentage encoding for URIs</strong> &#40;aka{' '}
          <em>URL encoding</em>&#41; must be applied.
        </p>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h3 id="parameters-answers-and-exceptions-common-to-all-operations">
              Parameters, answers and exceptions common to all operations
            </h3>
            <ul>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <h4 id="common-parameters">Common parameters</h4>
                <table>
                  <thead>
                    <tr>
                      <th align="center">Parameter</th>
                      <th align="center">Obligatory</th>
                      <th align="center">Format</th>
                      <th align="center">Description</th>
                      <th align="center">Default value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td align="center">id_usuario</td>
                      <td align="center">Yes</td>
                      <td align="center">Numeric</td>
                      <td align="center">
                        ID of the user who owns the sources of funds with which to
                        work
                      </td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">country_code</td>
                      <td align="center">Yes</td>
                      <td align="center">
                        <a href="https://www.iso.org/glossary-for-iso-3166.html">
                          ISO 3166 alpha-2
                        </a>
                      </td>
                      <td align="center">
                        Country code in which the user was registered
                      </td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">session_id</td>
                      <td align="center">
                        Yes, if the parameter is not reported <em>firma</em>
                      </td>
                      <td align="center">Alphanumeric</td>
                      <td align="center">
                        Obtained by identifying the user with ID <em> user_id </em>{' '}
                        on the platform
                      </td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">firma</td>
                      <td align="center">
                        Yes, if the parameter is not reported <em>session_id</em>
                      </td>
                      <td align="center">Alphanumeric</td>
                      <td align="center">
                        Allows you to sign the petition, withour having to log in
                      </td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">command</td>
                      <td align="center">Yes</td>
                      <td align="center">Alphabetic</td>
                      <td align="center">
                        Operation to be carried out on the origins of the user&#39;s
                        funds
                      </td>
                      <td align="center">N/A</td>
                    </tr>
                    <tr>
                      <td align="center">channel_id</td>
                      <td align="center">No</td>
                      <td align="center">Numeric</td>
                      <td align="center">Channel from which the request is made</td>
                      <td align="center">1</td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ul>
          </li>
        </ul>
        <h6 id="table-2b1-list-of-common-parameters-for-the-service_manager_of_own_origins_">
          <strong>Table 2.b.1</strong>: List of common parameters for the
          service_Manager_of_own_origins_.
        </h6>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h4 id="common-answers">Common answers</h4>
            <p>
              {' '}
              Depending on the result of the call, this service can return two
              different <a href="https://www.json.org/">JSON</a>:
            </p>
            <ol>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <p>In case of success</p>
    
                <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_1}/>
          </div>
              </li>
            </ol>
          </li>
        </ul>
        <h3 id="where">Where</h3>
        <p>
          <code>operation_result</code> will be a JSON formed by the fields that the
          service returns according to the operation executed. In the following
          sections the content of this space will be specified for each operation.
        </p>
        <ol >
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>If an error occurs</p>
    
            <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_2}/>
          </div>
            <ul>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <h4 id="common-exceptions">Common exceptions</h4>
                <table>
                  <thead>
                    <tr>
                      <th align="center">Message</th>
                      <th align="center">Cause</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td align="center">
                        We have found a problem and are working to fix it ... sorry
                        for the inconvenience
                      </td>
                      <td align="center">Unhandled error in the application</td>
                    </tr>
                    <tr>
                      <td align="center">500: Internal Server Error</td>
                      <td align="center">
                        In order not to provide service information, a 500 error is
                        thrown if some mandatory parameter is not reported
                      </td>
                    </tr>
                    <tr>
                      <td align="center">500: Internal Server Error</td>
                      <td align="center">
                        You can also get such an error if an uncontrolled error
                        occurs on the server
                      </td>
                    </tr>
                  </tbody>
                </table>
              </li>
            </ul>
          </li>
        </ol>
        <h6 id="table-2b2-list-of-common-exceptions-for-the-service-_-management-of-own-funds-resources-_">
          <strong>Table 2.b.2</strong>: List of common exceptions for the service _
          Management of own funds resources _.
        </h6>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h3 id="parameters-answers-and-specific-exceptions-for-each-operation">
              Parameters, answers and specific exceptions for each operation
            </h3>
            <ul>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <h4 id="list-of-funds-sources">List of funds sources</h4>
                <p>
                  {' '}
                  The parameter <em>command</em> acquires the value{' '}
                  <strong>list</strong>.
                </p>
                <ul>
                  <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                    <h5 id="specific-parameters">Specific parameters</h5>
                    <table>
                      <thead>
                        <tr>
                          <th align="center">Parameter</th>
                          <th align="center">Obligatory</th>
                          <th align="center">Format</th>
                          <th align="center">Description</th>
                          <th align="center">Default value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td align="center">language</td>
                          <td align="center">No</td>
                          <td align="center">Alphabetic code</td>
                          <td align="center">
                            Language in which the lists of sources of funds will be
                            returned, if that language is supported
                          </td>
                          <td align="center">User language</td>
                        </tr>
                        <tr>
                          <td align="center">list</td>
                          <td align="center">No</td>
                          <td align="center">Alphabetic</td>
                          <td align="center">
                            Some services require two lists of sources of funds.
                            This parameter allows you to hide origins for any of
                            these two lists according to business rules
                          </td>
                          <td align="center">origin</td>
                        </tr>
                        <tr>
                          <td align="center">service</td>
                          <td align="center">No</td>
                          <td align="center">Alphabetic</td>
                          <td align="center">
                            Identifier of the service for which the listing is
                            executed
                          </td>
                          <td align="center">N/A</td>
                        </tr>
                        <tr>
                          <td align="center">type_of_origin_id</td>
                          <td align="center">No</td>
                          <td align="center">Numeric</td>
                          <td align="center">
                            If reported, returns only user fund sources of the
                            indicated type
                          </td>
                          <td align="center">N/A</td>
                        </tr>
                      </tbody>
                    </table>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
        <h6 id="table-2b3-list-of-specific-parameters-for-the-operation-list">
          <strong>Table 2.b.3</strong>: List of specific parameters for the
          operation <strong>list</strong>.
        </h6>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-answer">Specific answer</h5>
    
            <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_3}/>
          </div>
            <p>
              {' '}
              Each source of funds in the <em>origins</em> list is represented by a
              JSON with the following structure:
            </p>
    
            <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_4}/>
          </div>
          </li>
        </ul>
        <h3 id="where-1">Where:</h3>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>balance_fmt</em>: Formatted balance, with thousands / millions
            division, decimals and currency symbol.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>balance</em>: Available balance of the funds source.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>real_balance</em>: Total balance of the funds source, that is, the
            available balance plus all the held balance.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>withholdings</em>: Specifies whether the funds source has associated
            balance holds.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>retained_balances</em>: If the funds source has associated balance
            holds, each item in this list will store both the amount of the hold and
            the hold ID.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>image</em>: Image associated with the funds source.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>class</em>: Indicates the type of funds source.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>authorized</em>: It allows to know if they are possible to operate
            with the source of funds or not.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>permit_errase</em>: Each funds source, depending on its
            configuration, may or may not be deleted by the user.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>id</em>: Funds source ID.{' '}
            <span >Note</span>: This field has the
            following range of values: [1 - 2,147,483,647].
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>permit_various</em>: Specifies whether multiple funds source of the
            same type are allowed to exist at the same time.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>currency_id_currencies</em>: ID of the currency with which it works
            at the funds source.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>type: Funds source type ID.</li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>permit_income</em>: The funds source may or may not admit income.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>permit_charges</em>: The funds source may or may not admit charges.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>name</em>: Funds source name.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>base_class</em>: The class of a funds source defines its nature
            &#40;card, local source of funds…&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <em>pending_of_revision</em>: Indicates whether or not the origin is to
            be reviewed by an administrator or operator.
          </li>
        </ul>
        <p>
          Each element of the list <em>list_of_withholdings</em> will have the
          following structure:
        </p>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_5}/>
          </div>
        <p>
          The fields{' '}
          <em>
            fmt_balance, _fmt_compact_balance, balance, actual_balance, and
            held_balances
          </em>{' '}
          are returned as long as the balance of the source of funds is known. For
          example, for credit cards no information regarding the balance is
          returned.
        </p>
        <p>
          Each element of the <em>creators</em> list is a JSON that defines a type
          of source of funds:
        </p>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_6}/>
          </div>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-exceptions">Specific exceptions</h5>
            <p> Does not send specific exceptions</p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h4 id="funds-source-creation">Funds source creation</h4>
            <p>
              {' '}
              The parameter <em>command</em> acquires the value{' '}
              <strong>crear</strong>.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-parameters-1">Specific parameters</h5>
            <table>
              <thead>
                <tr>
                  <th align="center">Parameters</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Format</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">source_id_or_profile</td>
                  <td align="center">
                    Yes, if not reported <em>template_id</em>
                  </td>
                  <td align="center">Numeric</td>
                  <td align="center">
                    Ensures that the user has permissions on the type of funds
                    source to create
                  </td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="center">template_id</td>
                  <td align="center">
                    Yes, if not reported <em>source_id_or_profile</em>
                  </td>
                  <td align="center">Numeric</td>
                  <td align="center">
                    Same role as <em>source_id_or_profile.</em> Maintained for
                    backward compatibility
                  </td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="center">anioexpiracion</td>
                  <td align="center">Yes for card-type origins</td>
                  <td align="center">Numeric, YYYY</td>
                  <td align="center">Card expiration year</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="center">mesexpiracion</td>
                  <td align="center">Yes for card-type origins</td>
                  <td align="center">Numeric, MM</td>
                  <td align="center">Card expiration month</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="center">cardnumber</td>
                  <td align="center">Yes for card-type origins</td>
                  <td align="center">Numeric</td>
                  <td align="center">Card number</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="center">validationcode</td>
                  <td align="center">Yes for card-type origin</td>
                  <td align="center">Numeric</td>
                  <td align="center">Card validation code</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="center">name</td>
                  <td align="center">Yes</td>
                  <td align="center">Alphabetic</td>
                  <td align="center">Name of the new funds source</td>
                  <td align="center">N/A</td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
        <h6 id="table-2b4-list-of-specific-parameters-for-the-create-operation">
          <strong>Table 2.b.4</strong>: List of specific parameters for the{' '}
          <strong>create</strong> operation.
        </h6>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-answer-1">Specific answer</h5>
          </li>
        </ul>
        <p>Returns the ID of the source of funds created on the call.</p>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_7}/>
          </div>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-exceptions-1">Specific exceptions</h5>
            <table>
              <thead>
                <tr>
                  <th align="center">Message</th>
                  <th align="center">Cause</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">
                    The origin_id <em>source_id_or_profile</em> is not allowed in
                    the account &lt;account&gt;
                  </td>
                  <td align="center">
                    The user does not have permissions to create a source of the
                    selected type
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    The origin_id <em>source_id_or_profile</em> is not allowed in
                    the account &lt;account&gt;
                  </td>
                  <td align="center">
                    The source of funds is created only during the registration
                    process
                  </td>
                </tr>
                <tr>
                  <td align="center">The purse is already created</td>
                  <td align="center">
                    One or more key data &#40;for example, card number&#41; provided
                    to create the wallet is already present in another source of
                    funds of the same type
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    It is not possible to create this source of funds without being
                    certified
                  </td>
                  <td align="center">
                    A non-certified user wants to create a risk fund source &#40;for
                    example, credit card&#41;
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
        <h6 id="table-2b5-list-of-specific-exceptions-for-the-crear-operation">
          <strong>Table 2.b.5</strong>: List of specific exceptions for the{' '}
          <strong>crear</strong> operation
        </h6>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h4 id="funds-sources-errase">Funds sources errase</h4>
          </li>
        </ul>
        <p>
          The parameter <em>command</em> acquires the value <strong>errase</strong>
        </p>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-parameter">Specific parameter</h5>
            <table>
              <thead>
                <tr>
                  <th align="center">Parameter</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Format</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                  <th align="center">Examples</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">source_id_or_profile</td>
                  <td align="center">Yes, if not reported template_id</td>
                  <td align="center">Numeric</td>
                  <td align="center">Funds source ID to errase</td>
                  <td align="center">N/A</td>
                  <td align="center">189, 17673, 9872</td>
                </tr>
                <tr>
                  <td align="center">template_id</td>
                  <td align="center">Yes, if not reported source_id_or_profile</td>
                  <td align="center">Numeric</td>
                  <td align="center">
                    Same role as source_id_or_profile. Maintained for backward
                    compatibility
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">189, 17673, 9872</td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
        <h6 id="table-2b6-list-of-specific-parameters-for-the-operation-errase">
          <strong>Table 2.b.6</strong>: List of specific parameters for the
          operation <strong>errase</strong>
        </h6>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="answer">Answer</h5>
            <p> Empty.</p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-exceptions-2">Specific exceptions</h5>
            <table>
              <thead>
                <tr>
                  <th align="center">Message</th>
                  <th align="center">Cause</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">
                    The origin_id <em>source_id_or_profile</em> it is not allowed in
                    the account &lt;account&gt;
                  </td>
                  <td align="center">
                    The funds source indicated in <em>source_id_or_profile</em> does
                    not exist or does not belong to the user who performs the
                    operation
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    The origin_id <em>source_id_or_profile</em> it is not allowed in
                    the account &lt;account&gt;
                  </td>
                  <td align="center">
                    The type of funds source to be deleted does not allow it to be
                    deleted
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    The card could not be deleted: Please contact customer service
                    at the number &lt;number&gt;
                  </td>
                  <td align="center">
                    An attempt has been made to delete a card-type source of funds,
                    but the payment gateway returns an error
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
        <h6 id="table-2b7-list-of-specific-exceptions-for-the-operation-errase">
          <strong>Table 2.b.7</strong>: List of specific exceptions for the
          operation <strong>errase</strong>.
        </h6>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h4 id="activate-fund-sources">Activate fund sources</h4>
            <p>
              {' '}
              The parameter <em>command</em> acquires the value{' '}
              <strong>confirmed</strong>.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-parameters-2">Specific parameters</h5>
            <table>
              <thead>
                <tr>
                  <th align="center">Parameter</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Format</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">source_id_or_profile</td>
                  <td align="center">
                    Yes, if not reported <em>template_id</em>
                  </td>
                  <td align="center">Numeric</td>
                  <td align="center">Funds source ID to activate</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="center">template_id</td>
                  <td align="center">
                    Yes, if not reported <em>source_id_or_profile</em>
                  </td>
                  <td align="center">Numeric</td>
                  <td align="center">
                    Same role as <em>source_id_or_profile.</em> Maintained for
                    backward compatibility
                  </td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="center">amount1</td>
                  <td align="center">Yes for card-type origins</td>
                  <td align="center">Numeric</td>
                  <td align="center">
                    Decimals &#40;cents&#41; of the first validation charge. Only
                    for card-type origins
                  </td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="center">amount2</td>
                  <td align="center">Yes for card-type origins</td>
                  <td align="center">Numeric</td>
                  <td align="center">
                    Decimals &#40;cents&#41; of the second validation charge. Only
                    for card-type origins
                  </td>
                  <td align="center">N/A</td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
        <h6 id="table-2b8-list-of-specific-parameters-for-the-operation-confirmed">
          <strong>Table 2.b.8</strong>: List of specific parameters for the
          operation <strong>confirmed</strong>
        </h6>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-answer-2">Specific answer</h5>
          </li>
        </ul>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_8}/>
          </div>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-exceptions-3">Specific exceptions</h5>
            <table>
              <thead>
                <tr>
                  <th align="center">Message</th>
                  <th align="center">Cause</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">
                    The origin_id <em>source_id_or_profile</em> is not allowed in
                    the account &lt;account&gt;
                  </td>
                  <td align="center">
                    The funds source indicated in <em>source_id_or_profile</em> does
                    not exist or does not belong to the user who performs the
                    operation
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    Incorrect data in the card confirmation, please try again
                  </td>
                  <td align="center">
                    The data provided to confirm a card-type origin is incorrect
                  </td>
                </tr>
                <tr>
                  <td align="center">Attempts to confirm the card passed</td>
                  <td align="center">
                    The limit of attempts to confirm a card origin has been exceeded
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
        <h6 id="table-2b9-list-of-specific-exceptions-for-the-operation-errase">
          <strong>Table 2.b.9</strong>: List of specific exceptions for the
          operation <strong>errase</strong>.
        </h6>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h4 id="change-funds-source-defaults">Change funds source defaults</h4>
          </li>
        </ul>
        <p>
          The parameter <em>command</em> acquires de value{' '}
          <strong>change_by_defect</strong>.
        </p>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-paramters">Specific paramters</h5>
            <table>
              <thead>
                <tr>
                  <th align="center">Parameter</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Format</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">source_id_or_profile</td>
                  <td align="center">
                    Yes, if not reported <em>template_id</em>
                  </td>
                  <td align="center">Numeric</td>
                  <td align="center">Funds source ID to activate</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="center">template_id</td>
                  <td align="center">
                    Yes, if not reported <em>source_id_or_profile</em>
                  </td>
                  <td align="center">Numeric</td>
                  <td align="center">
                    Same role as <em>source_id_or_profile.</em> Maintained for
                    backward compatibility
                  </td>
                  <td align="center">N/A</td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
        <h6 id="table-2b10-list-of-specific-parameters-for-the-operation-change_by_defect">
          <strong>Table 2.b.10</strong>: List of specific parameters for the
          operation <strong>change_by_defect</strong>.
        </h6>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-answer-3">Specific answer</h5>
            <p> Empty.</p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-exception">Specific exception</h5>
            <table>
              <thead>
                <tr>
                  <th align="center">Message</th>
                  <th align="center">Cause</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">
                    The origin_id <em>source_id_or_profile</em> is not allowed in
                    the account &lt;account&gt;
                  </td>
                  <td align="center">
                    The indicated source of funds <em>source_id_or_profile</em> does
                    not exist or does not belong to the user who performs the
                    operation
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    The origin_id <em>source_id_or_profile</em> is not allowed in
                    the account &lt;account&gt;
                  </td>
                  <td align="center">
                    Due to backward compatibility with types of sources of old
                    funds.
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
        <h6 id="table-2b11-list-of-specific-exceptions-for-the-operation-errase">
          <strong>Table 2.b.11</strong>: List of specific exceptions for the
          operation <strong>errase</strong>.
        </h6>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h4 id="reorder-fund-sources">Reorder Fund Sources</h4>
            <p>
              {' '}
              The parameter <em>command</em> acquires value <strong>reorder</strong>
              .
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-parameters-3">Specific parameters</h5>
            <table>
              <thead>
                <tr>
                  <th align="center">Parameter</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Format</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">new_order</td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Alphanumeric. New order separated by commas
                  </td>
                  <td align="center">New order of user origins</td>
                  <td align="center">N/A</td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
        <h6 id="table-2b12-list-of-specific-parameters-for-the-operation-reorder">
          <strong>Table 2.b.12</strong>: List of specific parameters for the
          operation <strong>reorder</strong>.
        </h6>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-answer-4">Specific answer</h5>
            <p> Empty.</p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-exceptions-4">Specific exceptions</h5>
            <table>
              <thead>
                <tr>
                  <th align="center">Message</th>
                  <th align="center">Cause</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">
                    The origin_id <em>source_id_or_profile</em> is not allowed in
                    the account &lt;account&gt;
                  </td>
                  <td align="center">
                    Some of the sources of funds in the list does not exist or does
                    not belong to the user who performs the operation
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
        <h6 id="table-2b13-list-of-specific-exceptions-for-the-operation-reorder">
          <strong>Table 2.b.13</strong>: List of specific exceptions for the
          operation <strong>reorder</strong>.
        </h6>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h4 id="movements-of-funds-sources">Movements of funds sources</h4>
            <p>
              {' '}
              The parameter <em>command</em> acquires the value{' '}
              <strong>movements</strong>.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-parameters-4">Specific parameters</h5>
            <table>
              <thead>
                <tr>
                  <th align="center">Parameter</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Format</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">source_id_or_profile</td>
                  <td align="center">Yes</td>
                  <td align="center">Numeric</td>
                  <td align="center">
                    Funds source from which the information is required
                  </td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="center">date_desde</td>
                  <td align="center">No</td>
                  <td align="center">
                    <a href="https://www.iso.org/iso-8601-date-and-time-format.html">
                      IS0 8601
                    </a>
                  </td>
                  <td align="center">Start date of the movement list</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="center">date_hasta</td>
                  <td align="center">No</td>
                  <td align="center">
                    <a href="https://www.iso.org/iso-8601-date-and-time-format.html">
                      IS0 8601
                    </a>
                  </td>
                  <td align="center">End date of the movement list</td>
                  <td align="center">N/A</td>
                </tr>
                <tr>
                  <td align="center">pagina</td>
                  <td align="center">No</td>
                  <td align="center">Numeric</td>
                  <td align="center">Page to start from</td>
                  <td align="center">1</td>
                </tr>
                <tr>
                  <td align="center">limite</td>
                  <td align="center">No</td>
                  <td align="center">Numeric</td>
                  <td align="center">Number of results per page</td>
                  <td align="center">10</td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
        <h6 id="table-2b16-list-of-specific-parameters-for-the-operation-movements">
          <strong>Table 2.b.16</strong>: List of specific parameters for the
          operation <strong>movements</strong>.
        </h6>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-answer-5">Specific answer</h5>
    
            <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_9}/>
          </div>
          </li>
        </ul>
        <h3 id="where-2">Where:</h3>
        <p>
          The field <em>movements</em> will return a list of JSON. Each of these
          JSON will have the following content:
        </p>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_10}/>
          </div>
        <h3 id="where-3">Where:</h3>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>transaction_code</em>: Transaction code
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>concept</em>: Transaction object.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>date</em>: Date on which the movement is made.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>amount</em>: Amount of movement. Includes commissions.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>balance_disponible</em>: Available balance at the source of funds
              that originates the movement.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>real_balance</em>: Actual balance of the source of funds that
              originates the movement..
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>rfc</em>: RFC of the account that originates the movement.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>business_name</em>: The business social reason. It corresponds to
              the name associated with it &#40;<em>name</em> parameter informed in
              the call to the registration service&#41;.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>transaction_status</em>: Status of the transaction associated with
              the movement. See table Annex 1 in section{' '}
              <a href="#d-annex-32">Annex</a>, line{' '}
              <em>estados de transacciones</em>.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>currency_id</em>: Identifier of the currency in which the movement
              is made.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>account_id_origin</em>: Unique identifier of the source account.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>account_id_destination</em>: Unique identifier of the target
              account.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>origin_id_origin</em>: Unique identifier of the source of funds
              with which the movement originates.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>origin_id_destination</em>: Unique identifier of the source of
              funds that receives the amount of the operation.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>error_id</em>: Identifier of the error that caused the operation
              to abort. <strong>OPTIONAL</strong>.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>exception</em>: Detail of the error that caused the operation to
              abort. <strong>OPTIONAL</strong>.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <em>channel_id</em>: Channel through which the movement was made..
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h5 id="specific-exceptions-5">Specific exceptions</h5>
            <table>
              <thead>
                <tr>
                  <th align="center">Message</th>
                  <th align="center">Cause</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">
                    The origin_id <em>source_id_or_profile</em> does not exist
                  </td>
                  <td align="center">
                    The source of funds indicated in <em>source_id_or_profile</em>{' '}
                    does not exist
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              {' '}
              <strong>Table 2.b.17</strong>: List of specific exceptions for the
              operation <strong>movements</strong>.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <h3 id="business-logic">Business logic:</h3>
            <p>
              {' '}
              This section details some particularities related to this service that
              it is advisable to take into account.{' '}
            </p>
            <ol>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                Deleted fund sources are not added to the list_origins_ when listing
                a user&#39;s source of funds.
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                If at the time of listing the sources of funds of a user he has a
                wallet associated with solutions &#40;promotions&#41; and the latter
                is not active, they are not added to the list.
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                The fund sources listing operation can be configured so that certain
                types of wallets are not added to the lista_origins _ according to
                the service for which the operation was invoked
                &#40;_paramtero_servicio&#41;.
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                You cannot create fondos locales_ _&#40;like Pademobile Balance&#41;
                or those that only allow one source of funds of its type in an
                account.
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                Non-risk sources of funds are automatically authorized during the
                creation process. Otherwise, the user must follow the rules of the
                type of source of funds created to authorize it. For example, for
                credit cards it will be necessary to enter the decimals of two
                different test charges. The total amount of these two charges is
                returned to the owner of the source of funds once said wallet is
                authorized.
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                All administrators have an action in the system that allows
                authorizing or rejecting a source of funds directly, without the
                need to enter any type of extra information.
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                If the confirmation attempt limit is exceeded for a source of funds,
                it is marked as deleted. The maximum number of confirmation attempts
                depends on the type of source of funds.
              </li>
            </ol>
          </li>
        </ul>
        <h1 id="3-calls-data">3. CALLS DATA</h1>
        <p>
          This section details, for each environment, all the information necessary
          to exploit the previously documented services.
        </p>
        <table>
          <thead>
            <tr>
              <th align="center">Parameter</th>
              <th align="center">Value</th>
              <th align="center">Services to which it applies</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">country_code</td>
              <td align="center">MX</td>
              <td align="center">All</td>
            </tr>
            <tr>
              <td align="center">perfil</td>
              <td align="center">1</td>
              <td align="center">User registration</td>
            </tr>
            <tr>
              <td align="center">channel_id</td>
              <td align="center">1</td>
              <td align="center">All</td>
            </tr>
          </tbody>
        </table>
        <h6 id="table-3a1-parameters-provided-by-avanquia-for-the-pre-production-environment">
          <strong>Table 3.a.1</strong>: Parameters provided by Avanquia for the
          pre-production environment.
        </h6>
        <table>
          <thead>
            <tr>
              <th align="center">Parameter</th>
              <th align="center">Value</th>
              <th align="center">Service to which it applies</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">country_code</td>
              <td align="center">MX</td>
              <td align="center">All</td>
            </tr>
            <tr>
              <td align="center">perfil</td>
              <td align="center">-</td>
              <td align="center">User registration</td>
            </tr>
            <tr>
              <td align="center">channel_id</td>
              <td align="center">-</td>
              <td align="center">All</td>
            </tr>
          </tbody>
        </table>
        <h6 id="table-3a2-parameters-provided-by-avanquia-for-the-production-environment">
          <strong>Table 3.a.2</strong>: Parameters provided by Avanquia for the
          production environment.
        </h6>
        <p>
          Additionally, there is a test user already created, which can be used to
          exploit the services described in this documentation.
        </p>
        <table>
          <thead>
            <tr>
              <th align="center">Parameter</th>
              <th align="center">Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">ID de usuario</td>
              <td align="center">--</td>
            </tr>
            <tr>
              <td align="center">Nick</td>
              <td align="center">--</td>
            </tr>
            <tr>
              <td align="center">Password</td>
              <td align="center">--</td>
            </tr>
            <tr>
              <td align="center">Private Key</td>
              <td align="center">--</td>
            </tr>
          </tbody>
        </table>
        <h6 id="table-3a3-test-user-available-in-the-pre-production-environment">
          <strong>Table 3.a.3</strong>: Test user available in the pre-production
          environment.
        </h6>
      </Fragment>
    );
  }
export default Origenes_de_fondos_EN;
