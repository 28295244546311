import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h4>1.1.4 Flexibility in Programming</h4>
      <p>
        AVAP™ offers a wide range of features that promote flexibility in programming. This includes support for higher-order functions, 
        dynamic exception handling, and the ability to manipulate objects at runtime, among others.
      </p>
      <pre>
        {`
# Example of a higher-order function 
def operation(func, a, b): 
    return func(a, b) 
def add(x, y): 
    return x + y 
result = operation(add, 3, 5) 
# The add function is passed as an argument
        `}
      </pre>
    </div>
  </Fragment>
);
export default Intro_1;
