import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_2 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>randomString()</h2>
      <p>
        The <code>randomString()</code> command generates a random string based on a specified pattern and stores it in a target variable. It is especially useful when random strings are needed to conform to a specific format, such as passwords or identifiers.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Pattern</strong> <br />
          Type: <code>var</code> <br />
          Description: A regular expression (regex) pattern that defines the characters and structure of the string to be generated. It can be a direct value or a variable containing the pattern. For example, <code>[a-zA-Z0-9]</code> will generate a string that includes uppercase letters, lowercase letters, and numbers.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Length</strong> <br />
          Type: <code>var</code> <br />
          Description: An integer value specifying the length of the random string to be generated. It can be a direct value or a variable containing the desired length. This value determines how many characters the resulting string will have.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>TargetVariable</strong> <br />
          Type: <code>var</code> <br />
          Description: The variable where the generated string will be stored. This variable should be used later in the program. Unlike the other parameters, this must be a variable and not a direct value.
        </li>
      </ul>
      <h3>Usage Example</h3>
      <pre>
        <code>
          {`// Direct call with values:
            randomString('[a-zA-Z0-9]', 8, generatedPassword)

            // Call using variables:
            pattern = '[a-zA-Z0-9]'
            length = 8
            randomString(pattern, length, generatedPassword)`}
        </code>
      </pre>
    </div>
  </Fragment>
);

export default Appendices_2;
