import React, { Fragment } from 'react';
import './chapter.css'
const Chapter11_3 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>7.2 Functioning of the Loop Statement</h2>
      <p>
        The loop statement in AVAP™ follows this execution process:
      </p>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          The control variable <code>control</code> is initialized with the starting value specified in <code>start</code>.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          The loop condition is evaluated: while the value of <code>control</code> is less than or equal to the end value <code>end</code>, the code block within <code>startLoop()</code> is executed. If the value of <code>control</code> exceeds the end value, the loop terminates, and execution continues after <code>endLoop()</code>.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          In each iteration of the loop, the code block within <code>startLoop()</code> is executed, and the control variable <code>control</code> is automatically incremented by one.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          Once the control variable reaches or exceeds the end value <code>end</code>, the loop terminates, and execution continues after <code>endLoop()</code>.
        </li>
      </ul>
    </div>
  </Fragment>
);

export default Chapter11_3;
