import { Container, Row, Col } from 'react-bootstrap';
import React, { FunctionComponent, useEffect, useState } from 'react';
import '../../../assets/css/baseLayout.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderMenu from '../baseLayout/DevHeaderMenu';
import DashboardSideBar from './DevDashboardSidebar';
import Footer from './FooterMenu';

type BaseProps = {
  sider: any,
  active?: string,
  sublinks?: Array<any>,
  projects?: Array<any>,
  children?: any
};

const DevBaseLayout:FunctionComponent<BaseProps> = (props:BaseProps) => {
  const { sider, children, active, sublinks } = props;

  const valuesSideBarClient = [
    "Dashboard",
    "Projects",
    "Requests",
    "Developer Teams",
    "Organizations",
    "Developers",
    "Downloads & GitHub",
    "Setting"
]

const [isDashboardBlue, setIsDashboardBlue] = useState(false);

const groupSider = ()=>{
  const groupTitles = sider.menus.map(item => item.title );
  const equal = arraysAreEqual(valuesSideBarClient, groupTitles);
  if(equal==true){
    setIsDashboardBlue(true);
  }
  // debugger
}

function arraysAreEqual(arr1, arr2) {
  return JSON.stringify(arr1) === JSON.stringify(arr2);
}

useEffect(() => {
  groupSider();
}, []);



  return (

    <>
      {!isDashboardBlue && (
        <Container fluid>
          <HeaderMenu />
          <Row id="content-page">
            <DashboardSideBar sider={sider} active={active} sublinks={sublinks} />
            <Col id="content" className="d-flex flex-column min-vh-100">
              <Row className="pt-4 pl-4 pr-4">
                <Col>
                  {children}
                </Col>
              </Row>
              <Row id="footer-dashboard" className="justify-content-md-center footer-dashboard mt-auto">
                <Col>
                  <Footer />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>


      )}

      {isDashboardBlue && (
        <Container fluid className='px-0'>
          <HeaderMenu isDashboardBlue={isDashboardBlue} />
          <Row id="content-page" className='marginTopDashboardBlue'>
            <DashboardSideBar sider={sider} active={active} sublinks={sublinks} />
            <Col id="content" className={`d-flex flex-column min-vh-100 ${isDashboardBlue ? 'marginContentAvapCloud' : ""}`}>
              <Row className="pt-4 pl-4 pr-4 mr-0 ml-0">
                <Col>
                  {children}
                </Col>
              </Row>
            </Col>
          </Row>
          <Footer />
        </Container>


      )}






    </>
  )
}

export default DevBaseLayout;