import React from 'react';
import './chapter.css'
const Chapter9_44 = () => (
    <div>
        <h2>Literal Patterns</h2>
        <p>
            In AVAP, literal patterns are used to match specific literal values, such as numbers, strings, or boolean values. The syntax for a literal pattern is:
        </p>
        <pre><code>literal_pattern ::=  signed_number
                     | strings
                     | "None"
                     | "True"
                     | "False"</code></pre>
        <p>
            A literal pattern only succeeds if the value of the subject is equal to the specified literal value.
        </p>
        <p>
            Here are examples of literal patterns and their usage:
        </p>
        <pre><code>match value:
    case 42:
        print("Matched the number 42")
    case "hello":
        print("Matched the string 'hello'")
    case None:
        print("Matched None")
    case True:
        print("Matched True")
    case False:
        print("Matched False")
    case _:
        print("No match")</code></pre>
        <p>
            In this example:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>case 42:</code> matches if <code>value</code> is exactly 42.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>case "hello":</code> matches if <code>value</code> is the string "hello".</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>case None:</code> matches if <code>value</code> is <code>None</code>.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>case True:</code> matches if <code>value</code> is <code>True</code>.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>case False:</code> matches if <code>value</code> is <code>False</code>.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>case _:</code> is a catch-all pattern that executes if none of the previous patterns match.</li>
        </ul>
        <p>
            Literal patterns are useful for matching specific, known values and are a fundamental part of pattern matching in AVAP.
        </p>
    </div>
);

export default Chapter9_44;
