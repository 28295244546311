import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h2>2.1 Importance of Variables</h2>
  <p>
    Variables play a crucial role in programming, as they allow us to store and manipulate data during the execution of a program. They enable the storage of temporary or permanent values, perform calculations, and facilitate communication between different parts of the program.
  </p>
</div>
  </Fragment>
);
export default Intro_1;
