import React, { Fragment } from 'react';

const AVAP_AVS = () => (
  <Fragment>
        <div className="body-md">
      <h1>Getting Started with AVS (AVAP Virtual Server)</h1>
      
      <h2>AVAP Virtual Server</h2>
      <p>
        This document will help you in the first steps of installing, configuring, and setting up AVS (AVAP Virtual Server) in either of the server modes: peer to peer or standalone.
      </p>
      <p>
        The peer to peer mode allows you to add the AVS (AVAP Virtual Server) to the AVAP Collaborative Network deployment, while the standalone mode allows you to run and exploit an AVS (AVAP Virtual Server) on your local computer.
      </p>
      <p>
        For AVS (AVAP Virtual Server) Enterprise installations, specific documentation for this mode of operation must be consulted.
      </p>

      <h2>What is AVS (AVAP Virtual Server)?</h2>
      <p>
        AVS (AVAP Virtual Server) is a complete virtual server for managing APIs. By complete, we mean that it is not just an API Manager but manages all the services required in a full API lifecycle, boosting its operations through other products in the AVAP Framework suite.
      </p>
      <p>
        This makes AVS (AVAP Virtual Server) the backbone of AVAP technology at the infrastructure level, aligning with the AVAP (Advanced Virtual API Programming) language as a key player and complementing it by applying the attribute that enables API virtualization.
      </p>

      <h2>Why is it a Virtual Server?</h2>
      <p>
        AVS (AVAP Virtual Server) is a virtual server because the configuration of the functionalities it offers is not defined in the code or the product. Instead, it is injected from the cloud through the operation mode configuration and setup service, enabling real-time functional flow configuration and server operation.
      </p>

      <h2>Basic Concepts</h2>
      <ul className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
        <li><b>Deployment</b>: A configuration of servers (AVS, AVAP, and gateway advanced) designed to provide a complete API management and publication service.</li>
        <li><b>ACL or Primary Server</b>: The ACL server is the AVS server in the deployment that maintains and serves the configurations for projects, APIs, permissions, users, etc., in the current deployment.</li>
        <li><b>Standalone</b>: A type of deployment where only one server is running in this mode, intended for simple local development deployments.</li>
        <li><b>Environment</b>: The environment refers to ...</li>
      </ul>

      <h2>First Steps</h2>
      <p>
        The AVS server is responsible for providing the endpoints where APIs will be exposed in the various environments configured in the deployment’s ACL.
      </p>
      <p>
        The first step is to identify which deployment you want to start the AVS server in, which results in three basic initialization types.
      </p>

      <h3>Standalone</h3>
      <pre>
        <code>avs –serve –standalone</code>
      </pre>
      <p>or</p>
      <pre>
        <code>avs -s -sa</code>
      </pre>

      <h3>Cloud</h3>
      <pre>
        <code>avs –serve –cloud</code>
      </pre>
      <p>or</p>
      <pre>
        <code>avs -s -cl</code>
      </pre>

      <h3>Collaborative Network</h3>
      <pre>
        <code>avs –serve –peer_to_peer</code>
      </pre>
      <p>or</p>
      <pre>
        <code>avs -s -p2p</code>
      </pre>

      <h2>Usage Credentials</h2>
      <p>
        As previously mentioned, AVAP AVS is a virtual server that needs to be configured with the desired functional model.
      </p>
      <p>
        When creating an AVAP ID, you obtain a credential file with which to identify the AVS server instance and access this configuration.
      </p>
      <p>
        An AVAP ID comes with access to Standalone and Collaborative Network deployments. The credential file must be located on the local disk of the developer who wants to start the server, and the path is indicated with the following parameter.
      </p>

      <pre>
        <code>–license or -l</code>
      </pre>

      <p>Example:</p>
      <pre>
        <code>avs –serve –standalone –license &lt;credential_file_path&gt;</code>
      </pre>

      <h2>Gateway</h2>
      <p>
        One of the main functions of an AVS is to act as a gateway.
      </p>
      <p>
        AVAP AVS exposes a set of endpoints per environment configured in the ACL on the specified ports. By default, the environments are:
      </p>
      <ul className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
        <li><b>Local</b>: Port 82</li>
        <li><b>Test</b>: Port 81</li>
        <li><b>Live</b>: Port 80</li>
      </ul>

      <p>
        Therefore, APIs are consumed against the port of the environment you want to use. For example, consuming the API <b>api_test</b> in the Test environment:
      </p>

      <pre>
        <code>http://&lt;avs_server_ip&gt;:81/api_test</code>
      </pre>
    </div>
  </Fragment>
);
export default AVAP_AVS;
