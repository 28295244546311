import React, { useState } from "react";


import icn_item from '../../../assets/img/ui_icons/cld_request.svg';
import chevron from '../../../assets/img/ui_icons/chevron.svg';


function Request() {

    // Estado para controlar la visibilidad del menú y el valor seleccionado
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Select API Product");

    // Opciones del menú
    const options = ["API 1", "API 2", "API 3"];

    // Función para manejar la selección de una opción
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false); // Cerrar el menú después de seleccionar
    };



    return (
        <div id='w_apiProject' className="item h-type-2">
            <div className="title">
                <span><img src={icn_item} alt="" /></span>
                <p>Request</p>
            </div>

            <div className="data">
                <div className="dataContent">
                    <span> <div className="dot green"></div>Available</span>
                    <p>10,000</p>
                </div>

                <div className="dataContent">
                    <span><div className="dot red"></div>In Use</span>
                    <p>8,457</p>
                </div>

                <div className="selectWrapper">
                </div>

            </div>

            <div className="bottom">
                <div className="label">To increase your request upgrade your plan</div>
            </div>
        </div>
    );
}

export default Request;