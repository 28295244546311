import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';
import "./../../../../assets/css/effectStickySections.css"

const Register_New_Order = () => 
{

  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{
        "status": true,
        "codtran": "f856c5db007fcaa5a00b9a4d56a9d40a",
        "order_code": 12034
        "elapsed": 0.06796097755432129
    }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: ` import requestsurl = "http://34.121.95.179:80/ws/ws/orders.py/new?country_code=ES&user_id=133&session_id=1688-eEqteXomKai8aDW2GkUFZQ==&purchase_transaction_code=24&client_address=Calle sin numero&delivery_time=12:34:43&payment_operation_code=pi_968ac76f7d898e7&order_products_codes=[34001,34002,34003]&order_category_id=1200&delivery_type=takeout&client_email_address=correo@servidor.ext&order_prices=[10.0,11.0,9.0]&delivery_price=3.5&total_amount=33.5&purchase_detail=[\"hamburguesa\",\"ensalada\",\"cocacola\"]&gps_direction={\"lat\": 40,5431311,\"lng\": -3,6302845 }&contact=Manuel&order_amount=30 €&shipping_amount=3,5 €"
    payload={}
    headers = { '101ObexApiKey': 'WcdoRNOUS8jQLs5XWo75yjwzvEZazcSI'}
    response = requests.request("GET", url, headers=headers, data=payload)
    print(response.text)`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requestsurl = "http://34.121.95.179:80/ws/ws/orders.py/new?country_code=ES&user_id=133&session_id=1688-eEqteXomKai8aDW2GkUFZQ==&purchase_transaction_code=24&client_address=Calle sin numero&delivery_time=12:34:43&payment_operation_code=pi_968ac76f7d898e7&order_products_codes=[34001,34002,34003]&order_category_id=1200&delivery_type=takeout&client_email_address=correo@servidor.ext&order_prices=[10.0,11.0,9.0]&delivery_price=3.5&total_amount=33.5&purchase_detail=[\"hamburguesa\",\"ensalada\",\"cocacola\"]&gps_direction={\"lat\": 40,5431311,\"lng\": -3,6302845 }&contact=Manuel&order_amount=30 €&shipping_amount=3,5 €"
    payload={}
    headers = { '101ObexApiKey': 'WcdoRNOUS8jQLs5XWo75yjwzvEZazcSI'}
    response = requests.request("GET", url, headers=headers, data=payload)
    print(response.text)`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: ` var request = require('request');
    var options = {
      'method': 'GET',
      'url': 'http://34.121.95.179:80/ws/ws/orders.py/new?country_code=ES&user_id=133&session_id=1688-eEqteXomKai8aDW2GkUFZQ==&purchase_transaction_code=24&client_address=Calle sin numero&delivery_time=12:34:43&payment_operation_code=pi_968ac76f7d898e7&order_products_codes=34001,34002,34003&order_category_id=1200&delivery_type=takeout&client_email_address=correo@servidor.ext&order_prices=10.0,11.0,9.0&delivery_price=3.5&total_amount=33.5&purchase_detail="hamburguesa","ensalada","cocacola"&gps_direction={"lat": 40,5431311,"lng": -3,6302845 }&contact=Manuel&order_amount=30 €&shipping_amount=3,5 €',
      'headers': {
        '101ObexApiKey': 'WcdoRNOUS8jQLs5XWo75yjwzvEZazcSI'
      }
    };
    request(options, function (error, response) {
      if (error) throw new Error(error);
      console.log(response.body);
    });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: ` var myHeaders = new Headers();
    myHeaders.append("101ObexApiKey", "WcdoRNOUS8jQLs5XWo75yjwzvEZazcSI");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
    fetch("http://34.121.95.179:80/ws/ws/orders.py/new?country_code=ES&user_id=133&session_id=1688-eEqteXomKai8aDW2GkUFZQ==&purchase_transaction_code=24&client_address=Calle sin numero&delivery_time=12:34:43&payment_operation_code=pi_968ac76f7d898e7&order_products_codes=[34001,34002,34003]&order_category_id=1200&delivery_type=takeout&client_email_address=correo@servidor.ext&order_prices=[10.0,11.0,9.0]&delivery_price=3.5&total_amount=33.5&purchase_detail=[\"hamburguesa\",\"ensalada\",\"cocacola\"]&gps_direction={\"lat\": 40,5431311,\"lng\": -3,6302845 }&contact=Manuel&order_amount=30 €&shipping_amount=3,5 €", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: ` curl --location -g --request GET 'http://34.121.95.179:80/ws/ws/orders.py/new?country_code=ES&user_id=133&session_id=1688-eEqteXomKai8aDW2GkUFZQ==&purchase_transaction_code=24&client_address=Calle sin numero&delivery_time=12:34:43&payment_operation_code=pi_968ac76f7d898e7&order_products_codes=[34001,34002,34003]&order_category_id=1200&delivery_type=takeout&client_email_address=correo@servidor.ext&order_prices=[10.0,11.0,9.0]&delivery_price=3.5&total_amount=33.5&purchase_detail=["hamburguesa","ensalada","cocacola"]&gps_direction={"lat": 40,5431311,"lng": -3,6302845 }&contact=Manuel&order_amount=30 €&shipping_amount=3,5 €' \--header '101ObexApiKey: WcdoRNOUS8jQLs5XWo75yjwzvEZazcSI'`
      }
    ]
  }

  return(
    <Fragment>
            <div className='container-fluid row'>
        <div className='col-md-7'>

      <h1 >Register New Order</h1>
      <h2 >ws/orders.py/last_order_summary</h2>
      <h3 >Receives</h3>
      <p>
        All the parameters that the service receives must be indicated in the body
        of the request.
      </p>
     <div className='overflow-auto'>
     <table>
        <thead>
          <tr>
            <th align="center">Parameter</th>
            <th align="center">Format</th>
            <th align="center">Obligatory</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
            <th align="center">Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="center">country_code</td>
            <td align="center">
              ISO 3166 alpha-2 &#40;
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                https://www.iso.org/glossary-for-iso-3166.html
              </a>
              &#41;
            </td>
            <td align="center">Yes</td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
            <td align="center">ES</td>
          </tr>
          <tr>
            <td align="center">user_id</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">
              Identifier of the user making the request &#40;Administrator&#41;
            </td>
            <td align="center">N/A</td>
            <td align="center">18</td>
          </tr>
          <tr>
            <td align="center">session_id</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send signature to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Obtained by identifying the user with ID user_id on the platform
            </td>
            <td align="center">N/A</td>
            <td align="center">34242-7M94x6WvLh7-b19HC6PM_g==</td>
          </tr>
          <tr>
            <td align="center">signature</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send session_id to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Enables you to sign the request without having to log in
            </td>
            <td align="center">N/A</td>
            <td align="center">328621ee3acda7a582338c5bba444c3094cd6f29</td>
          </tr>
          <tr>
            <td align="center">purchase_transaction_code</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">Transaction ID</td>
            <td align="center">N/A</td>
            <td align="center">24</td>
          </tr>
          <tr>
            <td align="center">client_address</td>
            <td align="center">String</td>
            <td align="center">Yes</td>
            <td align="center">Direction of the delivery</td>
            <td align="center">N/A</td>
            <td align="center">Street without number</td>
          </tr>
          <tr>
            <td align="center">delivery_time</td>
            <td align="center">HH:MM:SS</td>
            <td align="center">Yes</td>
            <td align="center">Delivery time</td>
            <td align="center">N/A</td>
            <td align="center">12:34:43</td>
          </tr>
          <tr>
            <td align="center">payment_operation_code</td>
            <td align="center">Array</td>
            <td align="center">Yes</td>
            <td align="center">Order payment identifier</td>
            <td align="center">N/A</td>
            <td align="center">pi_968ac76f7d898e7</td>
          </tr>
          <tr>
            <td align="center">order_products_codes</td>
            <td align="center">Array</td>
            <td align="center">Yes</td>
            <td align="center">Order of the product codes</td>
            <td align="center">N/A</td>
            <td align="center">[34001,34002,34003]</td>
          </tr>
          <tr>
            <td align="center">order_category_id</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">
              Code of the establishment to which the order is made
            </td>
            <td align="center">N/A</td>
            <td align="center">1200</td>
          </tr>
          <tr>
            <td align="center">delivery_type</td>
            <td align="center">String</td>
            <td align="center">Yes</td>
            <td align="center">Shipping type</td>
            <td align="center">N/A</td>
            <td align="center">delivery / takeout</td>
          </tr>
          <tr>
            <td align="center">client_email_address</td>
            <td align="center">Email</td>
            <td align="center">Yes</td>
            <td align="center">Email where updates will be sent</td>
            <td align="center">N/A</td>
            <td align="center">
              <a href="mailto:correo@servidor.ext">correo@servidor.ext</a>
            </td>
          </tr>
          <tr>
            <td align="center">order_prices</td>
            <td align="center">Array</td>
            <td align="center">Yes</td>
            <td align="center">List of prices of the items in the order</td>
            <td align="center">N/A</td>
            <td align="center">[10.0,11.0,9.0]</td>
          </tr>
          <tr>
            <td align="center">delivery_price</td>
            <td align="center">Array</td>
            <td align="center">Yes</td>
            <td align="center">Shippment price</td>
            <td align="center">N/A</td>
            <td align="center">3.5</td>
          </tr>
          <tr>
            <td align="center">total_amount</td>
            <td align="center">Float</td>
            <td align="center">Yes</td>
            <td align="center">Total amount</td>
            <td align="center">N/A</td>
            <td align="center">33.5</td>
          </tr>
          <tr>
            <td align="center">purchase_detail</td>
            <td align="center">Array</td>
            <td align="center">Yes</td>
            <td align="center">List of names of the products in the shippment</td>
            <td align="center">N/A</td>
            <td align="center">
              [&quot;hamburguesa&quot;,&quot;ensalada&quot;,&quot;cocacola&quot;]
            </td>
          </tr>
          <tr>
            <td align="center">gps_direction</td>
            <td align="center">Decimal degrees &#40;DD&#41;</td>
            <td align="center">Yes</td>
            <td align="center">GPS position of the client</td>
            <td align="center">N/A</td>
            <td align="center">
              &#123;&quot;lat&quot;: 40,5431311,&quot;lng&quot;: -3,6302845 &#125;
            </td>
          </tr>
          <tr>
            <td align="center">contact</td>
            <td align="center">String</td>
            <td align="center">Yes</td>
            <td align="center">Client name</td>
            <td align="center">N/A</td>
            <td align="center">
              &#123;&quot;firstName&quot;:&quot;Rafa&quot;,&quot;lastName&quot;:&quot;
              &quot;,&quot;mail&quot;:&quot;
              <a href="mailto:rafa.ruiz@waynnovate.com">
                rafa.ruiz@waynnovate.com
              </a>
              &quot;,&quot;phone&quot;:&quot;643340526&quot;&#125;
            </td>
          </tr>
          <tr>
            <td align="center">order_amount</td>
            <td align="center">Float</td>
            <td align="center">Yes</td>
            <td align="center">Total order foraged</td>
            <td align="center">N/A</td>
            <td align="center">30 €</td>
          </tr>
          <tr>
            <td align="center">shipping_amount</td>
            <td align="center">Float</td>
            <td align="center">Yes</td>
            <td align="center">Formated price of the shippment</td>
            <td align="center">N/A</td>
            <td align="center">3,5 €</td>
          </tr>
        </tbody>
      </table>
     </div>
     <h2 >Returns:</h2>
      <p>
        Depending on the result of the operation, this service can return two
        different JSON:
      </p>
  
      <div className='mb-3'>
        <DisplayCode1 codeToshow={codes.answe_ok1}/>
      </div>
      <h2 >Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li>
          <code>codtran:</code> Operation result.
        </li>
        <li>
          <code>order_code:</code> Identifier of the order in the system.
        </li>
        <li>
          <code>elapsed:</code> Operation execution time.
        </li>
      </ul>
      <h3 >Answer JSON KO:</h3>
  
      <div className='mb-3'>
        <DisplayCodeKo/>
      </div>

      <h2 >Business logic:</h2>
      <p>The objective of this service is to add an order.</p>
        </div>
        <div className='col-md-5'>
      <h2 >Example requests:</h2>
<div className='effectStickySections'>

      <div className='mb-3'>
      <DisplayCode2 codeToshow={codesMutiple}/>
      </div>
      
</div>
      
        </div>

      </div>
    </Fragment>
  );
}
export default Register_New_Order;
