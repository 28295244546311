import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Liquidaciones_de_Afiliados_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": <boolean>, 
          "codtran": <string>, 
          "result": <decimal>, 
          "elapsed": <float> 
        }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ko",
        type: "json",
        code: `{ "status": <boolean>, 
        "level": <string>, 
        "message": <string>, 
        "error": <string> }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: ` import requests url = "URL_BASE/ws/liquidacion.py/liquidacionafiliado" 
        payload = {'country_code': 'MX', 'user_id': '4532', 'session_id': '443-L6Cc10xJ7rMGOM2tM9-v0g==', 'from': '2019-01-07', 'unntil': '2020-07-14', 'type': 'consulta', 'affiliate_id': '1234'} 
        files = [] 
        headers= {} 
        response = requests.request("POST", url, headers=headers, data = payload, files = files)
         print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests url = "URL_BASE/ws/liquidacion.py/liquidacionafiliado" 
          payload = {'country_code': 'MX', 'user_id': '4532', 'session_id': '443-L6Cc10xJ7rMGOM2tM9-v0g==', 'from': '2019-01-07', 'unntil': '2020-07-14', 'type': 'consulta', 'affiliate_id': '1234'} 
          files = [] 
          headers= {} 
          response = requests.request("POST", url, headers=headers, data = payload, files = files)
           print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'POST',
              'url': 'URL_BASE/ws/liquidacion.py/liquidacionafiliado',
              'headers': {},
              formData: {
              'country_code': 'MX',
              'user_id': '4532',
              'session_id': '443-L6Cc10xJ7rMGOM2tM9-v0g==',
              'from': '2019-01-07',
              'unntil': '2020-07-14',
              'type': 'consulta',
              'affiliate_id': '1234'
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: ` var formdata = new FormData();
              formdata.append("country_code", "MX");
              formdata.append("user_id", "4532");
              formdata.append("session_id", "443-L6Cc10xJ7rMGOM2tM9-v0g==");
              formdata.append("from", "2019-01-07");
              formdata.append("unntil", "2020-07-14");
              formdata.append("type", "consulta");
              formdata.append("affiliate_id", "1234");
              var requestOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/liquidacion.py/liquidacionafiliado", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request POST 'URL_BASE/ws/liquidacion.py/liquidacionafiliado' \ --form 'country_code=MX' \ --form 'user_id=4532' \ --form 'session_id=443-L6Cc10xJ7rMGOM2tM9-v0g==' \ --form 'from=2019-01-07' \ --form 'unntil=2020-07-14' \ --form 'type=consulta' \ --form 'affiliate_id=1234'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="affiliate-liquidations">Affiliate Liquidations</h1>
        <p>
          The amount to be settled with the affiliate is obtained, or the settlement
          is made.
        </p>
        <p>
          <span className="label post">POST:</span>
          <span> </span>{' '}
          <code>URL_BASE + /ws/liquidacion.py/liquidacionafiliado</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  ISO 3166 alpha-2
                </a>
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Identifier of the user making the request &#40;Admin or
                Affiliate&#41;
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">type</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                It must have the value <code>compensate</code> or
                <code>consult</code>
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">from</td>
              <td align="center">String</td>
              <td align="center">No</td>
              <td align="center">
                The amount to be settled is obtained from the indicated date
                &#40;only if the type <code>consult</code> is indicated&#41;
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">unntil</td>
              <td align="center">String</td>
              <td align="center">No</td>
              <td align="center">
                The amount to be settled is obtained until the indicated date
                &#40;only if the type <code>consult</code> is indicated&#41;
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">affiliate_id</td>
              <td align="center">Integer</td>
              <td align="center">No</td>
              <td align="center">
                Identifier of the affiliated user from whom we want to obtain the
                amount to be settled or make the settlement
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">amount</td>
              <td align="center">Decimal</td>
              <td align="center">Si</td>
              <td align="center">
                Amount to be settled with the affiliate. &#40;only if type is
                indicated <code>compensate</code>&#41;
              </td>
              <td align="center">N/A</td>
            </tr>
          </tbody>
        </table>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok-if-cvs-is-sent">Answer JSON OK, if cvs is sent:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>result:</code> Service answer.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>level:</code> Error importance level.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          In order to run this service, it is necessary to do it from a user logged
          into the system who has an administrator profile. With this endpoint you
          get the amount to settle with an affiliate, or you can make the
          settlement.
        </p>
        </div>

<div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
        </div>
      </Fragment>
    );
  }
export default Liquidaciones_de_Afiliados_EN;
