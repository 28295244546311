import React from "react";
import Comprar_EN from "../../newViews/Docs/12_Comprar/0_Comprar/Comprar_EN";
import Comprobar_nick_EN from "../../newViews/Docs/13_Comprobar/1_comprobar_nick_EN/comprobar_nick_EN";
import Confirmar_Ingreso_EN from "../../newViews/Docs/14_Confirmar/0_Confirmar_Ingreso_EN/Confirmar_Ingreso_EN";
import Consultar_Saldo_Monedero_Prepago_EN from "../../newViews/Docs/15_Consultar/0_Consultar_Saldo_Monedero_Prepago_EN/Consultar_Saldo_Monedero_Prepago_EN";
import Consultar_Solucion_EN from "../../newViews/Docs/15_Consultar/1_Consultar_Solucion_EN/Consultar_Solucion_EN";
import Consultar_usuarios_de_la_solucion_EN from "../../newViews/Docs/15_Consultar/2_Consultar_usuarios_de_la_solucion_EN/Consultar_usuarios_de_la_solucion_EN";
import Crear_Programa_de_Puntos_EN from "../../newViews/Docs/16_Crear/0_Crear_Programa_de_Puntos_EN/Crear_Programa_de_Puntos_EN";
import Crear_Solucion_EN from "../../newViews/Docs/16_Crear/1_Crear_Solucion_EN/Crear_Solucion_EN";
import Create_ethereum_account from "../../newViews/Docs/16_Crear/2_Create_ethereum_account/create_ethereum_account";
import Cuadro_de_mando_EN from "../../newViews/Docs/17_Cuadro/0_Cuadro_de_mando_EN/Cuadro_de_mando_EN";
import Dashboard_Afiliado_EN from "../../newViews/Docs/19_Dashboard/0_Dashboard_Afiliado_EN/Dashboard_Afiliado_EN";
import Actividad_del_usuario_EN from "../../newViews/Docs/1_tools/1_actividad_del_usuario_EN/actividad_del_usuario_EN";
import Actualizar_Programa_de_Puntos_EN from "../../newViews/Docs/1_tools/2_Actualizar_Programa_de_Puntos_EN/Actualizar_Programa_de_Puntos_EN";
import Cancel_Transaction_EN from "../../newViews/Docs/1_tools/6_Cancel_Transaction_EN/Cancel_Transaction_EN";
import Carga_masiva_de_saldo_operador_EN from "../../newViews/Docs/1_tools/7_Carga_masiva_de_saldo_operador_EN/Carga_masiva_de_saldo_operador_EN";
import Datos_cuenta_EN from "../../newViews/Docs/20_Datos/0_Datos_cuentas_EN/Datos_cuenta_EN";
import Datos_Programa_de_Puntos_EN from "../../newViews/Docs/20_Datos/1_Datos_Programa_de_Puntos_EN/Datos_Programa_de_Puntos_EN";
import Del_employee_location from "../../newViews/APIs/5_Employee_Time_Control/13_Delete_An_Employee_Location/del_employee_location";
import Del_location from "../../newViews/APIs/5_Employee_Time_Control/6_Delete_An_Enterprise_Location/del_location";
import Desactivacion_de_una_cuenta_de_usuario_EN from "../../newViews/Docs/21_Desactivacion/0_Desactivacion_de_una_cuenta_de_usuario_EN/Desactivacion_de_una_cuenta_de_usuario_EN";
import Desplegar_condiciones_legales_EN from "../../newViews/Docs/22_Desplegar/0_Desplegar_condiciones_legales_EN/Desplegar_condiciones_legales_EN";
import Desplegar_preguntas_de_seguridad_EN from "../../newViews/Docs/22_Desplegar/1_Desplegar_preguntas_de_seguridad_EN/Desplegar_preguntas_de_seguridad_EN";
import Devolucion_desde_afiliado_o_administrador_EN from "../../newViews/Docs/23_Devolucion/0_Devolucion_desde_afiliado_o_administrador_EN/Devolucion_desde_afiliado_o_administrador_EN";
import Editar_datos_usuario_EN from "../../newViews/Docs/25_editar/0_Editar_datos_usuario_EN/Editar_datos_usuario_EN";
import Employee_data2 from "../../newViews/APIs/5_Employee_Time_Control/12_Get_Employee_Data/employee_data";
import Entreorigenes_EN from "../../newViews/Docs/27_Entreorigenes/0_Entreorigenes_EN/Entreorigenes_EN";
import Enviar_donacion_a_un_usuario_EN from "../../newViews/Docs/28_Enviar/0_Enviar_donacion_a_un_usuario_EN/Enviar_donacion_a_un_usuario_EN";
import Enviar_SMS_EN from "../../newViews/Docs/28_Enviar/1_Enviar_SMS_EN/Enviar_SMS_EN";
import Enviar_Ticket_por_email_EN from "../../newViews/Docs/28_Enviar/2_Enviar_Ticket_por_email_EN/Enviar_Ticket_por_email_EN";
import Envio_de_dinero_EN from "../../newViews/Docs/28_Enviar/3_Envio_de_dinero_EN/Envio_de_dinero_EN";
import Envio_de_dinero_Impersonado_EN from "../../newViews/Docs/28_Enviar/4_Envio_de_dinero_Impersonado_EN/Envio_de_dinero_Impersonado_EN";
import Envio_de_dinero_masiva_EN from "../../newViews/Docs/28_Enviar/5_Envio_de_dinero_masiva_EN/Envio_de_dinero_masiva_EN";
import Envio_de_regalo_EN from "../../newViews/Docs/28_Enviar/6_Envio_de_regalo_EN/Envio_de_regalo_EN";
import Add_employee_location from "../../newViews/APIs/5_Employee_Time_Control/9_Add_An_Employee_Location/add_employee_location";
import Add_location from "../../newViews/APIs/5_Employee_Time_Control/2_Add_An_Enterprise_Location/add_location";
import Get_token_info from "../../newViews/Docs/30_Get_Started/13_get_token_info/get_token_info";
import Get_total_active_hours from "../../newViews/APIs/5_Employee_Time_Control/11_Get_Total_Active_Hours/get_total_active_hours";
import Get_vaulted_transactions from "../../newViews/Docs/30_Get_Started/15_get_vaulted_transactions/get_vaulted_transactions";
import GetUSDGRAYcotization from "../../newViews/Docs/30_Get_Started/17_getUSDGRAYcotization/getUSDGRAYcotization";
import Get_blockchain_accounts from "../../newViews/Docs/30_Get_Started/2_get_blockchain_accounts/get_blockchain_accounts";
import Get_blockchain_networks from "../../newViews/Docs/30_Get_Started/3_get_blockchain_networks/get_blockchain_networks";
import Get_comminsioned_users from "../../newViews/Docs/30_Get_Started/4_get_comminsioned_users/get_comminsioned_users";
import Get_employees from "../../newViews/APIs/5_Employee_Time_Control/7_Get_Employee_Data/get_employees";
import Get_enterprise_code from "../../newViews/APIs/5_Employee_Time_Control/4_Get_Enterprice_Code/get_enterprise_code";
import Get_gasprices from "../../newViews/Docs/30_Get_Started/7_get_gasprices/get_gasprices";
import Get_locations from "../../newViews/APIs/5_Employee_Time_Control/10_Get_Locations/get_locations";
import Get_pools from "../../newViews/Docs/30_Get_Started/9_get_pools/get_pools";
import Historico_de_Confirmaciones_de_Ingresos_EN from "../../newViews/Docs/32_Historico/0_Historico_de_Confirmaciones_de_Ingresos_EN/Historico_de_Confirmaciones_de_Ingresos_EN";
import Historico_de_Ingresos_Tiempo_Aire_EN from "../../newViews/Docs/32_Historico/1_Historico_de_Ingresos_Tiempo_Aire_EN/Historico_de_Ingresos_Tiempo_Aire_EN";
import Informe_afiliados_con_tipo_EN from "../../newViews/Docs/33_informe/Informe_afiliados_con_tipo_EN";
import Informe_altas_EN from "../../newViews/Docs/33_informe/Informe_altas_EN";
import Informe_Consejo_EN from "../../newViews/Docs/33_informe/Informe_Consejo_EN";
import Informe_cuadro_de_mando_EN from "../../newViews/Docs/33_informe/Informe_cuadro_de_mando_EN";
import Informe_listado_de_usuarios_EN from "../../newViews/Docs/33_informe/Informe_listado_de_usuarios_EN";
import Informe_numero_altas_EN from "../../newViews/Docs/33_informe/Informe_numero_altas_EN";
import Informe_numero_transacciones_EN from "../../newViews/Docs/33_informe/Informe_numero_transacciones_EN";
import Informe_transacciones_EN from "../../newViews/Docs/33_informe/Informe_transacciones_EN";
import Ingresar_Prepago_EN from "../../newViews/Docs/34_Ingresar/Ingresar_Prepago_EN";
import Ingreso_de_fondos_para_tiempo_aire_EN from "../../newViews/Docs/34_Ingresar/Ingreso_de_fondos_para_tiempo_aire_EN";
import Interfaz_servicios_pagos_EN from "../../newViews/Docs/35_inter/interfaz_servicios_pagos_EN";
import Inter_networks_movement from "../../newViews/Docs/35_inter/inter_networks_movement";
import Inter_networks_pool_stake from "../../newViews/Docs/35_inter/inter_networks_pool_stake";
import Leer_notificaciones_del_usuario_EN from "../../newViews/Docs/37_Leer/Leer_notificaciones_del_usuario_EN";
import Leer_una_notificacion_del_usuario_EN from "../../newViews/Docs/37_Leer/Leer_una_notificacion_del_usuario_EN";
import Liquidaciones_de_Afiliados_EN from "../../newViews/Docs/38_liquidacion/Liquidaciones_de_Afiliados_EN";
import Listado_de_comisiones_de_afiliado_EN from "../../newViews/Docs/39_lista/Listado_de_comisiones_de_afiliado_EN";
import Listado_de_movimientos_EN from "../../newViews/Docs/39_lista/Listado_de_movimientos_EN";
import Listado_de_solicitudes_KYC_EN from "../../newViews/Docs/39_lista/Listado_de_solicitudes_KYC_EN";
import Listado_de_usuarios_bloqueados_EN from "../../newViews/Docs/39_lista/Listado_de_usuarios_bloqueados_EN";
import Listado_de_usuarios_en_lista_negra_EN from "../../newViews/Docs/39_lista/Listado_de_usuarios_en_lista_negra_EN";
import Listar_Programa_de_Puntos_EN from "../../newViews/Docs/39_lista/Listar_Programa_de_Puntos_EN";
import Listar_Soluciones_EN from "../../newViews/Docs/39_lista/Listar_Soluciones_EN";
import Listar_Usuarios_del_Programa_de_Puntos_EN from "../../newViews/Docs/39_lista/Listar_Usuarios_del_Programa_de_Puntos_EN";
import Administrar_solicitudes_KYC_POST_EN from "../../newViews/Docs/3_Administrar_Solicitudes/0_Administrar_solicitudes_KYC_(POST)_EN/Administrar_solicitudes_KYC_(POST)_EN";
import Administrar_solicitudes_KYC_GET_EN from "../../newViews/Docs/3_Administrar_Solicitudes/1_Administrar_solicitudes_KYC_GET_EN/Administrar_solicitudes_KYC_GET_EN";
import Logout_Services_EN from "../../newViews/Docs/40_logout/Logout_Services_EN";
import Logs_del_sistema_EN from "../../newViews/Docs/40_logout/Logs_del_sistema_EN";
import Notificaciones_no_leidas_por_el_usuario_EN from "../../newViews/Docs/44_notificaciones/Notificaciones_no_leidas_por_el_usuario_EN";
import Obtener_Campos_Alta_Cliente_EN from "../../newViews/Docs/45_Obtener/Obtener_Campos_Alta_Cliente_EN";
import Obtener_Campos_Nivel_KYC_EN from "../../newViews/Docs/45_Obtener/Obtener_Campos_Nivel_KYC_EN";
import Obtener_datos_usuario_EN from "../../newViews/Docs/45_Obtener/Obtener_datos_usuario_EN";
import Obtener_imagen_del_perfil_de_usuario_EN from "../../newViews/Docs/45_Obtener/Obtener_imagen_del_perfil_de_usuario_EN";
import Obtener_preguntas_de_seguridad_del_usuario_EN from "../../newViews/Docs/45_Obtener/Obtener_preguntas_de_seguridad_del_usuario_EN";
import Obtener_Shakes_EN from "../../newViews/Docs/45_Obtener/Obtener_Shakes_EN";
import Obtener_Shake_4_EN from "../../newViews/Docs/45_Obtener/Obtener_Shake_4_EN";
import Obtener_Shake_v2_3_EN from "../../newViews/Docs/45_Obtener/Obtener_Shake_v2_3_EN";
import Obtener_Shake_v2_EN from "../../newViews/Docs/45_Obtener/Obtener_Shake_v2_EN";
import Operaciones_EN from "../../newViews/Docs/46_Operaciones/Operaciones_EN";
import Orden_de_cargo_EN from "../../newViews/Docs/47_Odern/Orden_de_cargo_EN";
import Origenes_de_fondos_EN from "../../newViews/Docs/48_origenes/origenes_de_fondos_EN";
import Agregar_usuarios_a_la_comunidad_de_un_afiliado_EN from "../../newViews/Docs/4_Agregar_usuarios/0_Agregar_usuarios_a_la_comunidad_de_un_afiliado_EN/Agregar_usuarios_a_la_comunidad_de_un_afiliado_EN";
import Agregar_usuarios_a_la_lista_negra_EN from "../../newViews/Docs/4_Agregar_usuarios/1_Agregar_usuarios_a_la_lista_negra_EN/Agregar_usuarios_a_la_lista_negra_EN";
import Pago_diferido_EN from "../../newViews/Docs/50_pago/Pago_diferido_EN";
import Procesar_SMS_EN from "../../newViews/Docs/53_Procesar/Procesar_SMS_EN";
import Reactivacion_de_una_cuenta_de_usuario_EN from "../../newViews/Docs/54_Reactivacion/Reactivacion_de_una_cuenta_de_usuario_EN";
import Recarga_de_dinero_EN from "../../newViews/Docs/55_Recarga/Recarga_de_dinero_EN";
import Rechazar_solicitudes_KYC_EN from "../../newViews/Docs/56_rechazar/Rechazar_solicitudes_KYC_EN";
import Reenviar_el_OTP_para_el_alta_EN from "../../newViews/Docs/57_Reenviar/Reenviar_el_OTP_para_el_alta_EN";
import Regenerar_contrasena_del_usuario_EN from "../../newViews/Docs/58_regenrar/Regenerar_contrasena_del_usuario_EN";
import Registered_events_today from "../../newViews/APIs/5_Employee_Time_Control/8_Registered_Events_Today/registered_events_today";
import Registrar_Monedero_Prepago_EN from "../../newViews/Docs/59_Registrar/Registrar_Monedero_Prepago_EN";
import Alta_Admin_EN from "../../newViews/Docs/5_Alta/0_Alta_Admin_EN/Alta_Admin_EN";
import Alta_baja_modificacion_EN from "../../newViews/Docs/5_Alta/1_alta_baja_modificacion/alta_baja_modificacion_EN";
import Remesa_dirigida_EN from "../../newViews/Docs/60_Remesa/Remesa_dirigida_EN";
import Repetir_transaccion_EN from "../../newViews/Docs/62_repetir/repetir_transaccion_EN";
import Resumen_de_las_comisiones_del_afiliado_EN from "../../newViews/Docs/63_Resumen_comisiones/Resumen_de_las_comisiones_del_afiliado_EN";
import Retirar_a_Banco_EN from "../../newViews/Docs/64_Retirar/Retirar_a_Banco_EN";
import Retirar_a_Tarjeta_EN from "../../newViews/Docs/64_Retirar/Retirar_a_Tarjeta_EN";
import Retirar_EN from "../../newViews/Docs/64_Retirar/Retirar_EN";
import Retirar_Prepago_EN from "../../newViews/Docs/64_Retirar/Retirar_Prepago_EN";
import Retiro_en_cajero_EN from "../../newViews/Docs/64_Retirar/Retiro_en_cajero_EN";
import Saldo_de_la_cuenta_EN from "../../newViews/Docs/66_Saldo/Saldo_de_la_cuenta_EN";
import Saldo_Usuario_a_Fecha_EN from "../../newViews/Docs/66_Saldo/Saldo_Usuario_a_Fecha_EN";
import Servicio_de_Alta_de_Afiliados_EN from "../../newViews/Docs/68_servicio/Servicio_de_Alta_de_Afiliados_EN";
import Servicio_de_cambiar_el_perfil_de_un_usuario_EN from "../../newViews/Docs/68_servicio/Servicio_de_cambiar_el_perfil_de_un_usuario_EN";
import Servicio_de_configuracion_obtener_condicion_EN from "../../newViews/Docs/68_servicio/Servicio_de_configuracion_obtener_condicion_EN";
import Servicio_de_desbloqueo_de_un_usuario_EN from "../../newViews/Docs/68_servicio/Servicio_de_desbloqueo_de_un_usuario_EN";
import Servicio_de_edicion_de_perfilesM from "../../newViews/Docs/68_servicio/Servicio_de_edicion_de_perfiles_(Mover)_EN";
import Servicio_de_edicion_de_perfiles_Poner_EN from "../../newViews/Docs/68_servicio/Servicio_de_edicion_de_perfiles_(Poner)_EN";
import Servicio_de_edicion_de_perfilesQ from "../../newViews/Docs/68_servicio/Servicio_de_edicion_de_perfiles_(Quitar)_EN";
import Servicio_de_edicion_de_perfiles_Copiar_EN from "../../newViews/Docs/68_servicio/Servicio_de_edicion_de_perfiles_Copiar_EN";
import Servicio_de_edicion_de_perfiles_GET_EN from "../../newViews/Docs/68_servicio/Servicio_de_edicion_de_perfiles_GET_EN";
import Servicio_de_notificaciones_Borrar_Grupo_EN from "../../newViews/Docs/68_servicio/Servicio_de_notificaciones_Borrar_Grupo_EN";
import Servicio_de_notificaciones_Crear_EN from "../../newViews/Docs/68_servicio/Servicio_de_notificaciones_Crear_EN";
import Servicio_de_notificaciones_Crear_Grupo_EN from "../../newViews/Docs/68_servicio/Servicio_de_notificaciones_Crear_Grupo_EN";
import Servicio_de_notificaciones_Listar_Envia_EN from "../../newViews/Docs/68_servicio/Servicio_de_notificaciones_Listar_Envia_EN";
import Servicio_de_notificaciones_Listar_Grupo_EN from "../../newViews/Docs/68_servicio/Servicio_de_notificaciones_Listar_Grupo_EN";
import Servicio_de_notificaciones_Modificar_Grupo_EN from "../../newViews/Docs/68_servicio/Servicio_de_notificaciones_Modificar_Grupo_EN";
import Servicio_de_revision_de_origenes_de_fondos_aprobar_EN from "../../newViews/Docs/68_servicio/Servicio_de_revision_de_origenes_de_fondos_aprobar_EN";
import Servicio_de_revision_de_origenes_de_fondos_obtener_EN from "../../newViews/Docs/68_servicio/Servicio_de_revision_de_origenes_de_fondos_obtener_EN";
import Servicio_de_revision_de_origenes_de_fondos_rechazar_EN from "../../newViews/Docs/68_servicio/Servicio_de_revision_de_origenes_de_fondos_rechazar_EN";
import Servicio_Tiempo_Aire_EN from "../../newViews/Docs/68_servicio/Servicio_Tiempo_Aire_EN";
import Set_blokchain_network from "../../newViews/Docs/69_set/set_blokchain_network";
import Aprobar_solicitudes_KYC_EN from "../../newViews/Docs/6_Apobar_Autorizar/0_Aprobar_solicitudes_KYC_EN/Aprobar_solicitudes_KYC_EN";
import Autorizar_carga_masiva_de_saldo_EN from "../../newViews/Docs/6_Apobar_Autorizar/1_Autorizar_carga_masiva_de_saldo_EN/Autorizar_carga_masiva_de_saldo_EN";
import Autorizar_Solucion_EN from "../../newViews/Docs/6_Apobar_Autorizar/2_Autorizar_Solucion_EN/Autorizar_Solucion_EN";
import Solicitar_subida_de_nivel_KYC_EN from "../../newViews/Docs/70_solicitar/Solicitar_subida_de_nivel_KYC_EN";
import Solicitud_de_dinero_EN from "../../newViews/Docs/70_solicitar/Solicitud_de_dinero_EN";
import Spei_EN from "../../newViews/Docs/72_spei/spei_EN";
import Status_today from "../../newViews/APIs/5_Employee_Time_Control/3_Get_Status_Today/status_today";
import Subir_imagen_del_perfil_de_usuario_EN from "../../newViews/Docs/74_subir_imagen/Subir_imagen_del_perfil_de_usuario_EN";
import Transacciones_EN from "../../newViews/Docs/76_Transacciones/Transacciones_EN";
import Transaction_search_EN from "../../newViews/Docs/76_Transacciones/Transaction_search_EN";
import Transferencias_masivas_de_dinero_EN from "../../newViews/Docs/77_transferencia/Transferencias_masivas_de_dinero_EN";
import Unvault from "../../newViews/Docs/78_unvault/unvault";
import Chat_Operador_EN from "../../newViews/Docs/7_Chat/0_Chat_Operador_EN/Chat_Operador_EN";
import Chat_prebind_EN from "../../newViews/Docs/7_Chat/1_Chat_prebind_EN/Chat_prebind_EN";
import Chat_Usuario_EN from "../../newViews/Docs/7_Chat/2_Chat_Usuario_EN/Chat_Usuario_EN";
import Usuarios_con_saldo_EN from "../../newViews/Docs/80_Usuarios/Usuarios_con_saldo_EN";
import Vault from "../../newViews/Docs/82_vault/vault";
import Check_in from "../../newViews/APIs/5_Employee_Time_Control/5_Check_In/check_in";
import Check_Session_EN from "../../newViews/Docs/8_check/1_Check_Session_EN/Check_Session_EN";
import Check_out from "../../newViews/APIs/5_Employee_Time_Control/1_Check_Out/check_out";
// import Accesos_EN from "../../newViews/APIs/1_tools/0_Accesos_EN/Accesos_EN";
const Accesos_EN = React.lazy(() => import("../../newViews/APIs/1_tools/0_Accesos_EN/Accesos_EN"));
const Obtain_a_users_Profile = React.lazy(() => import("../../newViews/APIs/1_tools/10_Obtain_a_users_Profile/Obtain_a_users_Profile"));
const Obtain_Active_Countries = React.lazy(() => import("../../newViews/APIs/1_tools/11_Obtain_Active_Countries/Obtain_Active_Countries"));
const Currency_List = React.lazy(() => import("../../newViews/APIs/1_tools/1_Currency_List/Currency_List"));
const Obtain_Name_Of_Affiliate = React.lazy(() => import("../../newViews/APIs/1_tools/2_Obtain_Name_Of_Affiliate/Obtain_Name_Of_Affiliate"));
const Affiliate_Commisions_List = React.lazy(() => import("../../newViews/APIs/1_tools/3_Affiliate_Commisions_List/Affiliate_Commisions_List"));
const Obtain_Transaction_Data = React.lazy(() => import("../../newViews/APIs/1_tools/4_Obtain_Transaction_Data/Obtain_Transaction_Data"));
const Check_Transaction_Amount = React.lazy(() => import("../../newViews/APIs/1_tools/5_Check_Transaction_Amount/Check_Transaction_Amount"));
const Obtain_Country_Regions = React.lazy(() => import("../../newViews/APIs/1_tools/6_Obtain_Country_Regions/Obtain_Country_Regions"));
const Prices_of_Different_Services = React.lazy(() => import("../../newViews/APIs/1_tools/7_Prices_of_Different_Services/Prices_of_Different_Services"));
const Obtain_Country_Operator = React.lazy(() => import("../../newViews/APIs/1_tools/8_Obtain_Country_Operator/Obtain_Country_Operator"));
const Obtain_multiple_users_profiles = React.lazy(() => import("../../newViews/APIs/1_tools/9_Obtain_multiple_users_profiles/Obtain_multiple_users_profiles"));
const Register_New_Order = React.lazy(() => import("../../newViews/APIs/2_Order_Management/1_Register_New_Order/Register_New_Order"));
const Order_History_Summary = React.lazy(() => import("../../newViews/APIs/2_Order_Management/2_Order_History_Summary/Order_History_Summary"));
const Close_Order = React.lazy(() => import("../../newViews/APIs/2_Order_Management/3_Close_Order/Close_Order"));
const Last_Order_Summary = React.lazy(() => import("../../newViews/APIs/2_Order_Management/4_Last_Order_Summary/Last_Order_Summary"));
const Rider_Order_Management = React.lazy(() => import("../../newViews/APIs/2_Order_Management/5_Rider_Order_Management/Rider_Order_Management"));
const Remittance_Information = React.lazy(() => import("../../newViews/APIs/3_Remittance/1_Remittance_Information/Remittance_Information"));
const Remittance_Payment = React.lazy(() => import("../../newViews/APIs/3_Remittance/2_Remittance_Payment/Remittance_Payment"));
const OTP_se = React.lazy(() => import("../../newViews/APIs/4_Security/1_OTP_se/OTP_se"));
const OTP = React.lazy(() => import("../../newViews/APIs/4_Security/1_OTP_se/OTP_se"));
const OTP_v = React.lazy(() => import("../../newViews/APIs/4_Security/2_OTP_v/OTP_v"));
const Signature_Service = React.lazy(() => import("../../newViews/APIs/4_Security/3_Signature Service/Signature_Service"));



const  routerApis =
{
    "showFirst":{
        "id": "Accesos_EN",
        "links": [
            {
                "path": "Accesos_EN",
                "title": "Accesos_EN",
                "view":<Accesos_EN/>
            }
        ],
        "title": "Accesos_EN"
    },
    "menus": [
        {
            "id": "tools",
            "links": [
                {
                    "path": "Listado_de_divisas_EN",
                    "title": "Currency List1",
                    "view": <Currency_List/>
                },
                {
                    "path": "Obtener_Afiliados_del_sistema_EN",
                    "title": "Obtain name(s) of Affiliate(s)",
                    "view": <Obtain_Name_Of_Affiliate/>
                },
                {
                    "path": "Listado_de_comisiones_de_afiliado_EN",
                    "title": "Affiliate Commisions List",
                    "view": <Affiliate_Commisions_List/>
                },
                {
                    "path": "Obtener_todos_los_datos_de_la_transaccion_EN",
                    "title": "Obtain Transaction Data",
                    "view": <Obtain_Transaction_Data/>
                },
                {
                    "path": "Comprobar_el_importe_de_una_transaccion_EN",
                    "title": "Check Transaction Amount",
                    "view": <Check_Transaction_Amount/>
                },
                {
                    "path": "Obtener_Provincias_del_pais_EN",
                    "title": "Obtain Country Regions",
                    "view": <Obtain_Country_Regions/>
                },
                {
                    "path": "Precios_de_los_distintos_servicios_EN",
                    "title": "Prices of Different Services",
                    "view": <Prices_of_Different_Services/>
                },
                {
                    "path": "Obtener_Operadores_del_pais_EN",
                    "title": "Obtain Country Operator",
                    "view": <Obtain_Country_Operator/>
                },
                {
                    "path": "Obtener_Perfiles_de_Usuario_EN",
                    "title": "Obtain multiple users' profiles",
                    "view": <Obtain_multiple_users_profiles/>
                },
                {
                    "path": "Obtener_caracteristicas_de_un_servicio_EN",
                    "title": "Obtain a user's Profile",
                    "view": <Obtain_a_users_Profile/>
                },
                {
                    "path": "Obtener_Paises_Activos_EN",
                    "title": "Obtain Active Countries",
                    "view": <Obtain_Active_Countries/>
                }
            ],
            "title": "TOOLS"
        },
        {
            "id": "order management",
            "links": [
                {
                    "path": "new",
                    "title": "Register New Order",
                    "view": <Register_New_Order/>
                },
                {
                    "path": "order_history_summary",
                    "title": "Order History Summary",
                    "view": <Order_History_Summary/>
                },
                {
                    "path": "close",
                    "title": "Close Order",
                    "view": <Close_Order/>
                },
                {
                    "path": "last_order_summary",
                    "title": "Last Order Summary",
                    "view": <Last_Order_Summary/>
                },
                {
                    "path": "rider_order_management",
                    "title": "Rider Order Management",
                    "view": <Rider_Order_Management/>
                }
            ],
            "title": "ORDER MANAGEMENT"
        },
        {
            "id": "remittance",
            "links": [
                {
                    "path": "remittance_information",
                    "title": "Remittance Information",
                    "view": <Remittance_Information/>
                },
                {
                    "path": "remittance_payment",
                    "title": "**Remittance Payment**",
                    "view": <Remittance_Payment/>
                }
            ],
            "title": "REMITTANCE"
        },
        {
            "id": "security",
            "links": [
                {
                    "path": "Envio_de_una_OTP_a_un_telefono_externo_de_la_plataforma_EN",
                    "title": "OTP (sent externally)",
                    "view": <OTP_se/>
                },
                {
                    "path": "Validacion_de_una_OTP_enviada_a_un_telefono_externo_de_la_plataforma_EN",
                    "title": "OTP (Validation)",
                    "view": <OTP_v/> 
                },
                {
                    "path": "Servicio_de_Firma_EN",
                    "title": "Signature Service",
                    "view": <Signature_Service/>
                }
            ],
            "title": "SECURITY"
        },
        {
            "id": "employee time control",
            "links": [
                {
                    "path": "check_out",
                    "title": "Check Out",
                    "view": <Check_out/>
                },
                {
                    "path": "add_location",
                    "title": "Add an Enterprise Location",
                    "view":<Add_location/>
                },
                {
                    "path": "status_today",
                    "title": "Get Status Today",
                    "view": <Status_today/>
                },
                {
                    "path": "get_enterprise_code",
                    "title": "Get Enterprice Code",
                    "view": <Get_enterprise_code/>
                },
                {
                    "path": "check_in",
                    "title": "Check In",
                    "view": <Check_in/>
                },
                {
                    "path": "del_location",
                    "title": "Delete an Enterprise Location",
                    "view": <Del_location/>
                },
                {
                    "path": "get_employees",
                    "title": "Get Employee Data",
                    "view": <Get_employees/>
                },
                {
                    "path": "registered_events_today",
                    "title": "Registered Events Today",
                    "view":<Registered_events_today/>
                },
                {
                    "path": "add_employee_location",
                    "title": "Add an Employee Location",
                    "view": <Add_employee_location/>
                },
                {
                    "path": "get_locations",
                    "title": "Get Locations",
                    "view":<Get_locations/>
                },
                {
                    "path": "get_total_active_hours",
                    "title": "Get Total Active Hours",
                    "view": <Get_total_active_hours/>
                },
                {
                    "path": "employee_data",
                    "title": "Get Employee Data",
                    "view": <Employee_data2/>
                },
                {
                    "path": "del_employee_location",
                    "title": "Delete an Employee Location",
                    "view":<Del_employee_location/>
                }
            ],
            "title": "EMPLOYEE TIME CONTROL"
        },
        {
            "id": "money transfers",
            "links": [
                {
                    "path": "Envio_de_dinero_EN",
                    "title": "Send Money",
                    "view": <Envio_de_dinero_EN/>
                },
                {
                    "path": "repetir_transaccion_EN",
                    "title": "repet transaction",
                    "view":<Repetir_transaccion_EN/>
                },
                {
                    "path": "Envio_de_regalo_EN",
                    "title": "Send to Different User",
                    "view": <Envio_de_regalo_EN/>
                },
                {
                    "path": "Remesa_dirigida_EN",
                    "title": "Send via SMS",
                    "view": <Remesa_dirigida_EN/>
                },
                {
                    "path": "Entreorigenes_EN",
                    "title": "Between Origins",
                    "view":<Entreorigenes_EN/>
                },
                {
                    "path": "Solicitud_de_dinero_EN",
                    "title": "Money Request",
                    "view":<Solicitud_de_dinero_EN/>
                }
            ],
            "title": "MONEY TRANSFERS"
        },
        {
            "id": "others",
            "links": [
                {
                    "path": "Logs_del_sistema_EN",
                    "title": "System Logs",
                    "view":<Logs_del_sistema_EN/>
                },
                {
                    "path": "Obtener_Shake_v2_EN",
                    "title": "Obtain Shake v2",
                    "view":<Obtener_Shake_v2_EN/>
                },
                {
                    "path": "Obtener_Shakes_EN",
                    "title": "Obtain Shake",
                    "view":<Obtener_Shakes_EN/>
                },
                {
                    "path": "Crear_Solucion_EN",
                    "title": "Create a Solution",
                    "view":<Crear_Solucion_EN/>
                },
                {
                    "path": "Obtener_Shake_4_EN",
                    "title": "Obtener Shake 4",
                    "view":<Obtener_Shake_4_EN/>
                },
                {
                    "path": "Actualizar_Solucion_EN",
                    "title": "Solution Update"
                },
                {
                    "path": "Consultar_Solucion_EN",
                    "title": "Examine Solution",
                    "view": <Consultar_Solucion_EN/>
                },
                {
                    "path": "Obtener_Shake_v2_3_EN",
                    "title": "Obtain Shake v2 3",
                    "view": <Obtener_Shake_v2_3_EN/>
                },
                {
                    "path": "Consultar_usuarios_de_la_solucion_EN",
                    "title": "List of solution users",
                    "view":<Consultar_usuarios_de_la_solucion_EN/>
                },
                {
                    "path": "Autorizar_Solucion_EN",
                    "title": "Authorize User of Solution",
                    "view":<Autorizar_Solucion_EN/>
                },
                {
                    "path": "Chat_Usuario_EN",
                    "title": "Availability Status (User/Operator)",
                    "view":<Chat_Usuario_EN/>
                },
                {
                    "path": "Chat_prebind_EN",
                    "title": "Open New Chat Connection",
                    "view": <Chat_prebind_EN/>
                },
                {
                    "path": "Orden_de_cargo_EN",
                    "title": "Charge Order",
                    "view": <Orden_de_cargo_EN/>
                },
                {
                    "path": "Chat_Operador_EN",
                    "title": "Chat Connection Status (User)",
                    "view": <Chat_Operador_EN/>
                },
                {
                    "path": "Listar_Soluciones_EN",
                    "title": "List of solutions",
                    "view": <Listar_Soluciones_EN/>
                }
            ],
            "title": "OTHERS"
        },
        {
            "id": "loyalty",
            "links": [
                {
                    "path": "Enviar_donacion_a_un_usuario_EN",
                    "title": "Send Donations to a User",
                    "view":<Enviar_donacion_a_un_usuario_EN/>
                },
                {
                    "path": "Datos_Programa_de_Puntos_EN",
                    "title": "Points Program Data",
                    "view":<Datos_Programa_de_Puntos_EN/>
                },
                {
                    "path": "Listar_Programa_de_Puntos_EN",
                    "title": "List of Point Programs",
                    "view":<Listar_Programa_de_Puntos_EN/>
                },
                {
                    "path": "Crear_Programa_de_Puntos_EN",
                    "title": "Create Points Program",
                    "view":<Crear_Programa_de_Puntos_EN/>
                },
                {
                    "path": "Actualizar_Programa_de_Puntos_EN",
                    "title": "Update Points Program",
                    "view": <Actualizar_Programa_de_Puntos_EN/>
                },
                {
                    "path": "Listar_Usuarios_del_Programa_de_Puntos_EN",
                    "title": "List of users in the points Program",
                    "view":<Listar_Usuarios_del_Programa_de_Puntos_EN/>
                }
            ],
            "title": "LOYALTY"
        },
        {
            "id": "checkout",
            "links": [
                {
                    "path": "bills_2_EN",
                    "title": "**Bills 2.0 \u2013 Functional Definition**"
                }
            ],
            "title": "CHECKOUT"
        },
        {
            "id": "deposits",
            "links": [
                {
                    "path": "Recarga_de_dinero_EN",
                    "title": "Deposit (Internal Wallet)",
                    "view": <Recarga_de_dinero_EN/>
                },
                {
                    "path": "Ingresar_Prepago_EN",
                    "title": "Deposit (External Wallet)",
                    "view":<Ingresar_Prepago_EN/>
                }
            ],
            "title": "DEPOSITS"
        },
        {
            "id": "management console",
            "links": [
                {
                    "path": "Agregar_usuarios_a_la_comunidad_de_un_afiliado_EN",
                    "title": "Add Users to an Affiliate's Community", 
                    "view": <Agregar_usuarios_a_la_comunidad_de_un_afiliado_EN/>
                },
                {
                    "path": "Servicio_de_revision_de_origenes_de_fondos_obtener_EN",
                    "title": "Obtain Source of Funds Review", 
                    "view":<Servicio_de_revision_de_origenes_de_fondos_obtener_EN/>
                },
                {
                    "path": "Cuadro_de_mando_EN",
                    "title": "Control Panel", 
                    "view": <Cuadro_de_mando_EN/>
                },
                {
                    "path": "Servicio_de_edicion_de_perfiles_(Poner)_EN",
                    "title": "Modify Profile (Add Action)", 
                    "view":<Servicio_de_edicion_de_perfiles_Poner_EN/>
                },
                {
                    "path": "Servicio_de_edicion_de_perfiles_(Quitar)_EN",
                    "title": "Modify Profile (Remove)", 
                    "view": <Servicio_de_edicion_de_perfilesQ/>
                },
                {
                    "path": "Historico_de_Confirmaciones_de_Ingresos_EN",
                    "title": "Income Confirmation Summary",
                    "view":<Historico_de_Confirmaciones_de_Ingresos_EN/>
                },
                {
                    "path": "Envio_de_dinero_Impersonado_EN",
                    "title": "Send Money (SMS)",
                    "view":<Envio_de_dinero_Impersonado_EN/>
                },
                {
                    "path": "Resumen_de_las_comisiones_del_afiliado_EN",
                    "title": "Summary of Affiliate Fees", 
                    "view":<Resumen_de_las_comisiones_del_afiliado_EN/>
                },
                {
                    "path": "Ingreso_de_fondos_para_tiempo_aire_EN",
                    "title": "Deposit of Airtime Funds",
                    "view":<Ingreso_de_fondos_para_tiempo_aire_EN/>
                },
                {
                    "path": "Servicio_de_edicion_de_perfiles_(Mover)_EN",
                    "title": "Modify Profile (Order/Action)",
                    "view":<Servicio_de_edicion_de_perfilesM/>
                },
                {
                    "path": "Datos_cuenta_EN",
                    "title": "Account Data",
                    "view":<Datos_cuenta_EN/>
                },
                {
                    "path": "Saldo_Usuario_a_Fecha_EN",
                    "title": "User Updated Balance",
                    "view":<Saldo_Usuario_a_Fecha_EN/>
                },
                {
                    "path": "Servicio_de_edicion_de_perfiles_GET_EN",
                    "title": "Modify Profile (Editing Panel)",
                    "view":<Servicio_de_edicion_de_perfiles_GET_EN/>
                },
                {
                    "path": "Listado_de_comisiones_de_afiliado_EN",
                    "title": "Affiliate Commisions List",
                    "view": <Listado_de_comisiones_de_afiliado_EN/>
                },
                {
                    "path": "Servicio_de_revision_de_origenes_de_fondos_rechazar_EN",
                    "title": "Fund Source Review Service (Reject)",
                    "view":<Servicio_de_revision_de_origenes_de_fondos_rechazar_EN/>
                },
                {
                    "path": "Usuarios_con_saldo_EN",
                    "title": "Collection of Balances",
                    "view":<Usuarios_con_saldo_EN/>
                },
                {
                    "path": "Servicio_de_Alta_de_Afiliados_EN",
                    "title": "Affiliate Registration Service",
                    "view":<Servicio_de_Alta_de_Afiliados_EN/>
                },
                {
                    "path": "Transacciones_EN",
                    "title": "Transactions ",
                    "view":<Transacciones_EN/>
                },
                {
                    "path": "Listado_de_usuarios_en_lista_negra_EN",
                    "title": "List of Users in the Black List",
                    "view":<Listado_de_usuarios_en_lista_negra_EN/>
                },
                {
                    "path": "Servicio_de_revision_de_origenes_de_fondos_aprobar_EN",
                    "title": "Funds Source Review Service (Approve)",
                    "view": <Servicio_de_revision_de_origenes_de_fondos_aprobar_EN/>
                },
                {
                    "path": "Servicio_de_desbloqueo_de_un_usuario_EN",
                    "title": "Unblock User Service",
                    "view":<Servicio_de_desbloqueo_de_un_usuario_EN/>
                },
                {
                    "path": "Servicio_de_configuracion_obtener_condicion_EN",
                    "title": "Settings Service to Obtain a Condition",
                    "view":<Servicio_de_configuracion_obtener_condicion_EN/>
                },
                {
                    "path": "Dashboard_Afiliado_EN",
                    "title": "Affiliate Dashboard ",
                    "view":<Dashboard_Afiliado_EN/>
                },
                {
                    "path": "Servicio_de_cambiar_el_perfil_de_un_usuario_EN",
                    "title": "Changing User's Profile Service",
                    "view":<Servicio_de_cambiar_el_perfil_de_un_usuario_EN/>
                },
                {
                    "path": "Liquidaciones_de_Afiliados_EN",
                    "title": "Affiliate Liquidations",
                    "view": <Liquidaciones_de_Afiliados_EN/>
                },
                {
                    "path": "Agregar_usuarios_a_la_lista_negra_EN",
                    "title": "Add Users to the Black List",
                    "view":<Agregar_usuarios_a_la_lista_negra_EN/>
                },
                {
                    "path": "Servicio_de_edicion_de_perfiles_Copiar_EN",
                    "title": "Modify Profile (Copy)",
                    "view":<Servicio_de_edicion_de_perfiles_Copiar_EN/>
                },
                {
                    "path": "Listado_de_usuarios_bloqueados_EN",
                    "title": "List of Blocked Users",
                    "view":<Listado_de_usuarios_bloqueados_EN/>
                },
                {
                    "path": "Confirmar_Ingreso_EN",
                    "title": "Income Confirmation",
                    "view":<Confirmar_Ingreso_EN/>
                },
                {
                    "path": "Historico_de_Ingresos_Tiempo_Aire_EN",
                    "title": "AirTime Income Summary",
                    "view":<Historico_de_Ingresos_Tiempo_Aire_EN/>
                },
                {
                    "path": "Devolucion_desde_afiliado_o_administrador_EN",
                    "title": "Refund from Affiliate or Administrator",
                    "view":<Devolucion_desde_afiliado_o_administrador_EN/>
                },
                {
                    "path": "Operaciones_EN",
                    "title": "Operations",
                    "view":<Operaciones_EN/>
                },
                {
                    "path": "Alta_Admin_EN",
                    "title": "Admin Registration",
                    "view":<Alta_Admin_EN/>
                }
            ],
            "title": "MANAGEMENT CONSOLE"
        },
        {
            "id": "physical payments",
            "links": [
                {
                    "path": "Comprar_EN",
                    "title": "Purchase",
                    "view":<Comprar_EN/>
                }
            ],
            "title": "PHYSICAL PAYMENTS"
        },
        {
            "id": "withdrawals",
            "links": [
                {
                    "path": "Retiro_en_cajero_EN",
                    "title": "Money Withdrawal from ATM Machine",
                    "view":<Retiro_en_cajero_EN/>
                },
                {
                    "path": "Retirar_Prepago_EN",
                    "title": "Pre-paid withdrawal",
                    "view":<Retirar_Prepago_EN/>
                },
                {
                    "path": "spei_EN",
                    "title": "Spei Transfer",
                    "view":<Spei_EN/>
                },
                {
                    "path": "Retirar_a_Banco_EN",
                    "title": "Withdrawal to a Bank Account",
                    "view":<Retirar_a_Banco_EN/>
                },
                {
                    "path": "Retirar_a_Tarjeta_EN",
                    "title": "Withdrawal to Credit Card",
                    "view":<Retirar_a_Tarjeta_EN/>
                },
                {
                    "path": "Retirar_EN",
                    "title": "Withdrawal",
                    "view":<Retirar_EN/>
                }
            ],
            "title": "WITHDRAWALS"
        },
        {
            "id": "payment service interface",
            "links": [
                {
                    "path": "Transaction_search_EN",
                    "title": "#Transaction Search",
                    "view":<Transaction_search_EN/>
                },
                {
                    "path": "repetir_transaccion_EN",
                    "title": "Repeat Transaction",
                    "view":<Repetir_transaccion_EN/>
                },
                {
                    "path": "interfaz_servicios_pagos_EN",
                    "title": "**Card Payment**",
                    "view":<Interfaz_servicios_pagos_EN/>
                },
                {
                    "path": "Cancel_Transaction_EN",
                    "title": "#Cancel transaction",
                    "view":<Cancel_Transaction_EN/>
                }
            ],
            "title": "PAYMENT SERVICE INTERFACE"
        },
        {
            "id": "wallets",
            "links": [
                {
                    "path": "Consultar_Saldo_Monedero_Prepago_EN",
                    "title": "Check Prepaid Wallet Balance",
                    "view":<Consultar_Saldo_Monedero_Prepago_EN/>
                },
                {
                    "path": "Registrar_Monedero_Prepago_EN",
                    "title": "Prepaid Wallet Registration",
                    "view":<Registrar_Monedero_Prepago_EN/>
                },
                {
                    "path": "Saldo_de_la_cuenta_EN",
                    "title": "Account Balance",
                    "view":<Saldo_de_la_cuenta_EN/>
                },
                {
                    "path": "origenes_de_fondos_EN",
                    "title": "origins",//?
                    "view":<Origenes_de_fondos_EN/>
                },
                {
                    "path": "actividad_del_usuario_EN",
                    "title": "User Activity Log",
                    "view":<Actividad_del_usuario_EN/>
                },
                {
                    "path": "Listado_de_movimientos_EN",
                    "title": "List of Movements",
                    "view":<Listado_de_movimientos_EN/>
                }
            ],
            "title": "WALLETS"
        },
        {
            "id": "massive dispersions",
            "links": [
                {
                    "path": "Carga_masiva_de_saldo_operador_EN",
                    "title": "Balance Massive Charge",
                    "view":<Carga_masiva_de_saldo_operador_EN/>
                },
                {
                    "path": "Transferencias_masivas_de_dinero_EN",
                    "title": "Multiple Money Transfers",
                    "view":<Transferencias_masivas_de_dinero_EN/>
                },
                {
                    "path": "Autorizar_carga_masiva_de_saldo_EN",
                    "title": "Authorize bulk upload of balance",
                    "view":<Autorizar_carga_masiva_de_saldo_EN/>
                },
                {
                    "path": "Envio_de_dinero_masiva_EN",
                    "title": "Transfer via SMS",
                    "view":<Envio_de_dinero_masiva_EN/>
                }
            ],
            "title": "MASSIVE DISPERSIONS"
        },
        {
            "id": "notifications",
            "links": [
                {
                    "path": "Servicio_de_notificaciones_Crear_Grupo_EN",
                    "title": "Notifications Service (Create Group)",
                    "view":<Servicio_de_notificaciones_Crear_Grupo_EN/>
                },
                {
                    "path": "Procesar_SMS_EN",
                    "title": "Process SMS",
                    "view":<Procesar_SMS_EN/>
                },
                {
                    "path": "Servicio_de_notificaciones_Listar_Envia_EN",
                    "title": "Notifications Service (Sent Notifications List)",
                    "view":<Servicio_de_notificaciones_Listar_Envia_EN/>
                },
                {
                    "path": "Enviar_Ticket_por_email_EN",
                    "title": "Send Ticket via Email",
                    "view":<Enviar_Ticket_por_email_EN/>
                },
                {
                    "path": "Servicio_de_notificaciones_Listar_Grupo_EN",
                    "title": "Notification Service (List of Groups)",
                    "view":<Servicio_de_notificaciones_Listar_Grupo_EN/>
                },
                {
                    "path": "Leer_notificaciones_del_usuario_EN",
                    "title": "Read All Users' Notifications",
                    "view":<Leer_notificaciones_del_usuario_EN/>
                },
                {
                    "path": "Notificaciones_no_leidas_por_el_usuario_EN",
                    "title": "Unread User Notifications",
                    "view":<Notificaciones_no_leidas_por_el_usuario_EN/>
                },
                {
                    "path": "Servicio_de_notificaciones_Crear_EN",
                    "title": "Notifications Service (Create)",
                    "view":<Servicio_de_notificaciones_Crear_EN/>
                },
                {
                    "path": "Leer_una_notificacion_del_usuario_EN",
                    "title": "Read User Notification",
                    "view":<Leer_una_notificacion_del_usuario_EN/>
                },
                {
                    "path": "Servicio_de_notificaciones_Borrar_Grupo_EN",
                    "title": "Notification Service (Delete Group)",
                    "view":<Servicio_de_notificaciones_Borrar_Grupo_EN/>
                },
                {
                    "path": "Enviar_SMS_EN",
                    "title": "Send SMS",
                    "view":<Enviar_SMS_EN/>
                },
                {
                    "path": "Servicio_de_notificaciones_Modificar_Grupo_EN",
                    "title": "Notification service (Modify Group)",
                    "view":<Servicio_de_notificaciones_Modificar_Grupo_EN/>
                }
            ],
            "title": "NOTIFICATIONS"
        },
        {
            "id": "login",
            "links": [
                {
                    "path": "Check_Session_EN",
                    "title": "Session Status",
                    "view":<Check_Session_EN/>
                },
                {
                    "path": "Logout_Services_EN",
                    "title": "Logout Services",
                    "view":<Logout_Services_EN/>
                },
                {
                    "path": "Accesos_EN",
                    "title": "Access",
                    "view":<Accesos_EN/>
                }
            ],
            "title": "LOGIN"
        },
        {
            "id": "payment services",
            "links": [
                {
                    "path": "Pago_diferido_EN",
                    "title": "Buy via Credit",
                    "view":<Pago_diferido_EN/>
                },
                {
                    "path": "Servicio_Tiempo_Aire_EN",
                    "title": "Air Time Service",
                    "view":<Servicio_Tiempo_Aire_EN/>
                }
            ],
            "title": "PAYMENT SERVICES"
        },
        {
            "id": "onboarding",
            "links": [
                {
                    "path": "Obtener_datos_usuario_EN",
                    "title": "Obtain User Data",
                    "view":<Obtener_datos_usuario_EN/>
                },
                {
                    "path": "Listado_de_solicitudes_KYC_EN",
                    "title": "List of Pending KYC requests",
                    "view":<Listado_de_solicitudes_KYC_EN/>
                },
                {
                    "path": "Obtener_Campos_Nivel_KYC_EN",
                    "title": "Obtain KYC level Fields",
                    "view":<Obtener_Campos_Nivel_KYC_EN/>
                },
                {
                    "path": "Obtener_info_usuario_EN",
                    "title": "Obtain user Data",
                    "view":<Obtener_datos_usuario_EN/>
                },
                {
                    "path": "Obtener_preguntas_de_seguridad_del_usuario_EN",
                    "title": "Obtain Security Questions",
                    "view":<Obtener_preguntas_de_seguridad_del_usuario_EN/>
                },
                {
                    "path": "Administrar_solicitudes_KYC_(POST)_EN",
                    "title": "Manage KYC Requests (Modify)",
                    "view":<Administrar_solicitudes_KYC_POST_EN/>
                },
                {
                    "path": "Obtener_imagen_del_perfil_de_usuario_EN",
                    "title": "Obtain User Profile Image",
                    "view":<Obtener_imagen_del_perfil_de_usuario_EN/>
                },
                {
                    "path": "Aprobar_solicitudes_KYC_EN",
                    "title": "KYC Requests Approval",
                    "view":<Aprobar_solicitudes_KYC_EN/>
                },
                {
                    "path": "Subir_imagen_del_perfil_de_usuario_EN",
                    "title": "Upload user Profile Image",
                    "view":<Subir_imagen_del_perfil_de_usuario_EN/>
                },
                {
                    "path": "Editar_datos_usuario_EN",
                    "title": "Edit User Data",
                    "view":<Editar_datos_usuario_EN/>
                },
                {
                    "path": "Desplegar_condiciones_legales_EN",
                    "title": "Legal Conditions",
                    "view":<Desplegar_condiciones_legales_EN/>
                },
                {
                    "path": "Solicitar_subida_de_nivel_KYC_EN",
                    "title": "KYC Level Upgrade Request",
                    "view":<Solicitar_subida_de_nivel_KYC_EN/>
                },
                {
                    "path": "Obtener_Campos_Alta_Cliente_EN",
                    "title": "Obtain User Registration",
                    "view":<Obtener_Campos_Alta_Cliente_EN/>
                },
                {
                    "path": "Rechazar_solicitudes_KYC_EN",
                    "title": "KYC Requests Rejection",
                    "view":<Rechazar_solicitudes_KYC_EN/>
                },
                {
                    "path": "Administrar_solicitudes_KYC_GET_EN",
                    "title": "Manage KYC Requests (Get)",
                    "view":<Administrar_solicitudes_KYC_GET_EN/>
                },
                {
                    "path": "Reactivacion_de_una_cuenta_de_usuario_EN",
                    "title": "User Account Reactivation",
                    "view":<Reactivacion_de_una_cuenta_de_usuario_EN/>
                },
                {
                    "path": "alta_baja_modificacion_EN",
                    "title": "Registration and Modification Services",
                    "view":<Alta_baja_modificacion_EN/>
                },
                {
                    "path": "Reenviar_el_OTP_para_el_alta_EN",
                    "title": "Resend OTP for Registration",
                    "view":<Reenviar_el_OTP_para_el_alta_EN/>
                },
                {
                    "path": "Desplegar_preguntas_de_seguridad_EN",
                    "title": "Security Questions",
                    "view":<Desplegar_preguntas_de_seguridad_EN/>
                },
                {
                    "path": "Regenerar_contrasena_del_usuario_EN",
                    "title": "Generate New Password",
                    "view":<Regenerar_contrasena_del_usuario_EN/>
                },
                {
                    "path": "Desactivacion_de_una_cuenta_de_usuario_EN",
                    "title": "User Account Deactivation",
                    "view":<Desactivacion_de_una_cuenta_de_usuario_EN/>
                },
                {
                    "path": "comprobar_nick_EN",
                    "title": "Nickname Check",
                    "view":<Comprobar_nick_EN/>
                }
            ],
            "title": "ONBOARDING"
        },
        {
            "id": "ethereum",
            "links": [
                {
                    "path": "get_vaulted_transactions",
                    "title": "Get Vaulted Transactions",
                    "view":<Get_vaulted_transactions/>
                },
                {
                    "path": "inter_networks_pool_stake",
                    "title": "Inter Networls Pool Stake",
                    "view":<Inter_networks_pool_stake/>
                },
                {
                    "path": "get_gasprices",
                    "title": "Get Gas Prices",
                    "view":<Get_gasprices/>
                },
                {
                    "path": "vault",
                    "title": "Vault Tokens",
                    "view":<Vault/>
                },
                {
                    "path": "get_blockchain_accounts",
                    "title": "Inter Networks Movement",
                    "view":<Get_blockchain_accounts/>
                },
                {
                    "path": "getUSDGRAYcotization",
                    "title": "Get USD GRAY Cotization",
                    "view":<GetUSDGRAYcotization/>
                },
                {
                    "path": "set_blokchain_network",
                    "title": "Set Blockchain Network as selected",
                    "view":<Set_blokchain_network/>
                },
                {
                    "path": "inter_networks_movement",
                    "title": "Inter Networks Movement",
                    "view":<Inter_networks_movement/>
                },
                {
                    "path": "get_pools",
                    "title": "Obtain Inter Network Pools",
                    "view":<Get_pools/>
                },
                {
                    "path": "get_comminsioned_users",
                    "title": "Get Commission Users",
                    "view":<Get_comminsioned_users/>
                },
                {
                    "path": "unvault",
                    "title": "UnVault Tokens",
                    "view":<Unvault/>
                },
                {
                    "path": "get_token_info",
                    "title": "Set Blockchain Account as selected",
                    "view":<Get_token_info/>
                },
                {
                    "path": "create_ethereum_account",
                    "title": "Create Ethereum Account",
                    "view":<Create_ethereum_account/>
                },
                {
                    "path": "get_blockchain_networks",
                    "title": "Set Blockchain Account as selected",
                    "view":<Get_blockchain_networks/>
                }
            ],
            "title": "ETHEREUM"
        },
        {
            "id": "reports",
            "links": [
                {
                    "path": "Informe_altas_EN",
                    "title": "All Registrations",
                    "view":<Informe_altas_EN/>
                },
                {
                    "path": "Informe_cuadro_de_mando_EN",
                    "title": "Control Panel Report",
                    "view":<Informe_cuadro_de_mando_EN/>
                },
                {
                    "path": "Informe_numero_altas_EN",
                    "title": "Registrations (Filtered)",
                    "view":<Informe_numero_altas_EN/>
                },
                {
                    "path": "Informe_numero_transacciones_EN",
                    "title": "Transactions (Filtered)",
                    "view":<Informe_numero_transacciones_EN/>
                },
                {
                    "path": "Informe_Consejo_EN",
                    "title": "List of Self and Affiliate Registered Users",
                    "view":<Informe_Consejo_EN/>
                },
                {
                    "path": "Informe_listado_de_usuarios_EN",
                    "title": "List of all Users",
                    "view":<Informe_listado_de_usuarios_EN/>
                },
                {
                    "path": "Informe_afiliados_con_tipo_EN",
                    "title": "List of Affiliates",
                    "view":<Informe_afiliados_con_tipo_EN/>
                },
                {
                    "path": "Informe_transacciones_EN",
                    "title": "All Transactions",
                    "view":<Informe_transacciones_EN/>
                }
            ],
            "title": "REPORTS"
        }
    ],
    "basePath": "apis/",
    "titleNav": "TITULO QUE TU QUIERAS"
}

export default routerApis;