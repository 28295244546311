import React, { Fragment } from 'react';
import './chapter.css'
const Chapter8_4 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>Working with Lists</h2>
      <p>
        Lists are a very versatile data structure in AVAP™ that allows you to store collections of elements of different types. 
        Expressions in AVAP™ can involve operations and manipulations of lists, such as accessing individual elements, concatenation, searching, deletion, and more.
      </p>
      <pre>
        <code>
          {`// Definition of a list
my_list = [1, 2, 3, 4, 5]

// Accessing individual elements
first_element = my_list[0]  // Output: 1

// Concatenation of lists
another_list = [6, 7, 8]
combined_list = my_list + another_list  // Output: [1, 2, 3, 4, 5, 6, 7, 8]

// Length of a list
length = len(my_list)  // Output: 5

// Searching in a list
is_present = 5 in my_list  // Output: True

// Removing elements
my_list.remove(3)  // Removes the element 3 from the list`}
        </code>
      </pre>
    </div>
  </Fragment>
);

export default Chapter8_4;
