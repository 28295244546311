import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import Logo from './logo_blanco.svg';

// UI icons
import home from '../../../assets/img/ui_icons/cld_home.svg';
import Iam from '../../../assets/img/ui_icons/cld_IAM.svg';
import dashboard from '../../../assets/img/ui_icons/cld_dashboard.svg';
import organizations from '../../../assets/img/ui_icons/cld_organizations.svg';
import Repo from '../../../assets/img/ui_icons/cld_repo.svg';
import ApiCat from '../../../assets/img/ui_icons/cld_apiCat.svg';
import Devportal from '../../../assets/img/ui_icons/cld_devPortal.svg';
import Gateway from '../../../assets/img/ui_icons/cld_gate.svg';
import Billing from '../../../assets/img/ui_icons/cld_bill.svg';
import Analytics from '../../../assets/img/ui_icons/cld_analytics.svg';

function Sidebar({ isCollapsed, isMobileOpen }) {
    const location = useLocation();

    // Función para verificar si una ruta es activa según la ubicación actual
    const isActive = (path) => {
        const currentPath = location.pathname;

        if (path === '/cloudBlack') {
            return currentPath === path;
        }
    
        if (path === '/cloudBlack/organizations') {
            return currentPath.startsWith(path);
        }

        return currentPath === path;
    };

    return (
        <div
            className={`${isMobileOpen ? 'd-block' : 'd-none'} ${isCollapsed ? 'sidebar-collapsed' : ''} d-md-block`}
            id="sidebarCloud-wrapper"
        >
            <div className="sidebar-heading text-white p-3">
                <div className="logoWrapper">
                    <img src={Logo} className='logo' alt="Logo Avap Cloud" />
                </div>
            </div>
            <div className="list-group list-group-flush">
                <Link to="/cloudBlack" className={`sb-link ${isActive('/cloudBlack') ? 'active' : ''}`}>
                    <img src={dashboard} alt="" /> {!isCollapsed && <span>Dashboard</span>}
                </Link>
                <Link to="/iam" className={`sb-link ${isActive('/iam') ? 'active' : ''}`}>
                    <img src={Iam} alt="" /> {!isCollapsed && <span>IAM</span>}
                </Link>

                {/* Organizations Section with Subsections */}
                <div className={`sb-section-wrapper ${isActive('/cloudBlack/organizations') ? 'active' : ''}`}>
                    <div className="sb-link">
                        <img src={organizations} alt="" /> {!isCollapsed && <span>Organizations</span>}
                    </div>
                    {!isCollapsed && (
                        <div className="subsection">
                            <Link to="/cloudBlack/organizations/" className="sb-link">
                                <span>My Organizations</span>
                            </Link>
                            <Link to="/cloudBlack/organizations/devs" className="sb-link">
                                <span>Developers</span>
                            </Link>
                            <Link to="/cloudBlack/organizations/teams" className="sb-link">
                                <span>Dev Team</span>
                            </Link>
                        </div>
                    )}
                </div>

                {/* API Projects Section with Subsections */}
                <div className={`sb-section-wrapper ${isActive('/cloudBlack/api') ? 'active' : ''}`}>
                    <div className="sb-link">
                        <img src={ApiCat} alt="" /> {!isCollapsed && <span>API Projects</span>}
                    </div>
                    {!isCollapsed && (
                        <div className="subsection">
                            <Link to="/cloudBlack/api/products" className="sb-link">
                                <span>API Products</span>
                            </Link>
                            <Link to="/cloudBlack/api/publisher" className="sb-link">
                                <span>API Publisher</span>
                            </Link>
                        </div>
                    )}
                </div>

                {/* Account Section with Subsections */}
                <div className={`sb-section-wrapper ${isActive('/cloudBlack/account') ? 'active' : ''}`}>
                    <div className="sb-link">
                        <img src={Billing} alt="" /> {!isCollapsed && <span>Account</span>}
                    </div>
                    {!isCollapsed && (
                        <div className="subsection">
                            <Link to="/cloudBlack/account/subscription" className="sb-link">
                                <span>Subscription</span>
                            </Link>
                            <Link to="/cloudBlack/account/tutorial" className="sb-link">
                                <span>Tutorial</span>
                            </Link>
                        </div>
                    )}
                </div>

                {/* Other Links */}
                <Link to="#" className="sb-link">
                    <img src={home} alt="" /> {!isCollapsed && <span>Credentials Manager</span>}
                </Link>
                <Link to="#" className="sb-link">
                    <img src={Repo} alt="" /> {!isCollapsed && <span>Repo Manager</span>}
                </Link>
                <Link to="#" className="sb-link">
                    <img src={Devportal} alt="" /> {!isCollapsed && <span>Dev Portal</span>}
                </Link>
                <Link to="#" className="sb-link">
                    <img src={Gateway} alt="" /> {!isCollapsed && <span>Gateway Manager</span>}
                </Link>
                <Link to="#" className="sb-link">
                    <img src={Analytics} alt="" /> {!isCollapsed && <span>Analytics</span>}
                </Link>
            </div>
        </div>
    );
}

export default Sidebar;
