import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';


const Transaction_search_EN = () => 
 {
  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{
          "status": true, "codtran": "67f26198c2c49d09de176be34b1f9abe", 
          "result": {
              "transaction": [{
                 "transaction_id": "9073279", 
                 "codtran": "61c25b83adfae0ce7cc587736140a226", 
                 "state": "1", "state_name": "Approved", 
                 "date": "11/10/2019 11:36:12", 
                 "invoice": "", "concept": "Informed concept", 
                 "amount": 100.00, "payment_reference": "928036", 
                 "error_id": "", "exception": "" }] }, "elapsed": <float>
                }`,
      copied: false
    },
    answe_ko1: {
      title:"Answer ok",
      type: "json",
      code: `{
          "status": false, "level": <str>, 
          "message": <str>, 
          "error": { "code": "***", "message": "************" }
        }`,
      copied: false
    },
    example: {
      title:"Answer ok",
      type: "json",
      code: `/ws/movimientos.py/consultatransactiones? 
      \ user_id=12345 \ &country_code=MX 
      \ &transaction=61c25b83adfae0ce7cc587736140a226 \
       &signature=cc30e3efc7159bb10b910512ca441664c1578a4d`,
      copied: false
    }
  };

  return(
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
      <h2 id="transaction-search">Transaction Search</h2>
      <p>
        By means of this EndPoint you will obtain a list with all the affiliated
        user&#39;s transactions, between the two dates provided, being able to
        request up to a maximum of 7 days. If a transaction is indicated in the
        query&#39;s querystring, a list with a single transaction will be
        returned.
        <br></br>
        <span className="label post">POST:</span>
        <span> </span>{' '}
        <code>URL_BASE + /ws/movimientos.py/consultatransactiones</code>
        <br></br>
      </p>
      <h2 id="receives">Receives:</h2>
      <table>
        <thead>
          <tr>
            <th align="center">Parameter</th>
            <th align="center">Obligatory</th>
            <th align="center">Format</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
            <th align="center">Examples</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="center">country_code</td>
            <td align="center">Yes</td>
            <td align="center">
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                ISO 3166
              </a>{' '}
              alpha-2
            </td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
            <td align="center">ES &#40;Spain&#41;</td>
          </tr>
          <tr>
            <td align="center">user_id</td>
            <td align="center">Yes</td>
            <td align="center">Numeric</td>
            <td align="center">
              Identifier of the account of the user requesting the request.
            </td>
            <td align="center">N/A</td>
            <td align="center">2345, 8764, 95473</td>
          </tr>
          <tr>
            <td align="center">channel_id</td>
            <td align="center">No</td>
            <td align="center">Numeric</td>
            <td align="center">Channel from which the request is made.</td>
            <td align="center">1 &#40;Web&#41;</td>
            <td align="center">
              1 &#40;Web&#41;, 2 &#40;SMS&#41;, 3 &#40;Retail&#41;
            </td>
          </tr>
          <tr>
            <td align="center">session_id</td>
            <td align="center">
              Yes, if the signature parameter is not reported
            </td>
            <td align="center">Alphanumeric</td>
            <td align="center">
              Obtained by identifying the user with ID user_id on the platform
            </td>
            <td align="center">N/A</td>
            <td align="center">7e4d31c91cbb7fe04b7148323d9825a8</td>
          </tr>
          <tr>
            <td align="center">signature</td>
            <td align="center">
              Yes, if the id_session parameter is not reported
            </td>
            <td align="center">Alphanumeric</td>
            <td align="center">
              Allows you to sign the petition, making it unnecessary to log in
            </td>
            <td align="center">N/A</td>
            <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
          </tr>
          <tr>
            <td align="center">transaction</td>
            <td align="center">No</td>
            <td align="center">Alphanumeric</td>
            <td align="center">Transaction code to search</td>
            <td align="center">N/A</td>
            <td align="center">df850da1cee31731b6095fc533482b5c</td>
          </tr>
          <tr>
            <td align="center">start_date</td>
            <td align="center">No</td>
            <td align="center">Text</td>
            <td align="center">Start date for transaction search DD-MM-YYYY</td>
            <td align="center">Day when the request is made</td>
            <td align="center">11-10-2019</td>
          </tr>
          <tr>
            <td align="center">end_date</td>
            <td align="center">No</td>
            <td align="center">Text</td>
            <td align="center">End date for transaction search DD-MM-YYYY</td>
            <td align="center">Day on which the request is made</td>
            <td align="center">11-10-2019</td>
          </tr>
        </tbody>
      </table>
      <p>
        <br></br>
      </p>
      </div>

      <div className='col-md-5'>
      <h3 id="example-request">Example request:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.example}/>
          </div>
      <h3 id="example-ok-answer">Example OK answer:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
      <h3 id="example-ko-answer">Example KO answer:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
      <p>
        <br></br>
      </p>
      <h3 id="business-logic">Business Logic:</h3>
      <p>
        This section details some particularities related to this service that it
        is advisable to take into account:
      </p>
      <ol>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          If the transaction parameter is indicated, a search will be carried out
          for said transaction and it will be returned as long as it belongs to
          the user indicated in the user_id parameter. If in addition to the
          transaction the start and end dates are indicated, the transaction will
          be returned if it is between the indicated dates.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          If only the start and end dates are indicated, all the transactions
          carried out by that user that are between those dates will be returned,
          with a maximum difference of 7 days between the start and end date.{' '}
        </li>
      </ol>
      </div>
      </div>
    </Fragment>
  );
 }
export default Transaction_search_EN;
