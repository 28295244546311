import React, { Fragment } from 'react';
import './chapter.css'
const Chapter14_3 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>Technical Features</h2>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Parameters</strong>: Functions can accept zero or more parameters that are used as inputs to the function.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Return Values</strong>: Functions can return a value using the <code>return</code> keyword.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Scope</strong>: Functions in AVAP™ have their own scope, meaning that variables defined within a function are only visible within that function unless declared as global variables.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Code Reusability</strong>: Functions allow for encapsulating and reusing blocks of code that perform specific tasks.</li>
      </ul>
    </div>
  </Fragment>
);

export default Chapter14_3;
