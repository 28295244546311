import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const Chat_Operador_EN = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status": true,
        "bosh_data": {},
        "appraiser": <string>,
        "chat_status": <string>,
        "elapsed": <float>
    }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
    url = "URL_BASE/ws/chat.py/chat_operator"
    payload = {
    'country_code': 'MX',
    'user_id': '4532',
    'session_id': '447-E3OUWo8UoSPUb1x8kE0rYQ==',
    'command': 'open'
    }
    files = []
    headers= {}
    response = requests.request("POST", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
url = "URL_BASE/ws/chat.py/chat_operator"
payload = {
'country_code': 'MX',
'user_id': '4532',
'session_id': '447-E3OUWo8UoSPUb1x8kE0rYQ==',
'command': 'open'
}
files = []
headers= {}
response = requests.request("POST", url, headers=headers, data = payload, files = files)
print(response.text.encode('utf8'))`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'POST',
  'url': 'URL_BASE/ws/chat.py/chat_operator',
  'headers': {},
  formData: {
  'country_code': 'MX',
  'user_id': '4532',
  'session_id': '447-E3OUWo8UoSPUb1x8kE0rYQ==',
  'command': 'open'
  }
  };
  request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
  });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var formdata = new FormData();
formdata.append("country_code", "MX");
formdata.append("user_id", "4532");
formdata.append("session_id", "447-E3OUWo8UoSPUb1x8kE0rYQ==");
formdata.append("command", "open");
var requestOptions = {
method: 'POST',
body: formdata,
redirect: 'follow'
};
fetch("URL_BASE/ws/chat.py/chat_operator", requestOptions)
.then(response => response.text())
.then(result => console.log(result))
.catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request POST 'URL_BASE/ws/chat.py/chat_operator' 
  --form 'country_code=MX' 
  --form 'user_id=4532' 
  --form 'session_id=447-E3OUWo8UoSPUb1x8kE0rYQ==' 
  --form 'command=open'`
      }
    ]
  }

  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >
            Chat Connection Status &#40;User&#41;
          </h1>
          <p>
            This service is used to obtain the connection key for an open chat
            session.
          </p>
          <p>
            <span className="label post">POST:</span>
            <span> </span> <code>URL_BASE + /ws/chat.py/chat_operator</code>
          </p>
          <h2 >Receives:</h2>
          <p>
            All the parameters that the service receives must be indicated in the body
            of the request.
          </p>
          <div className='overflow-auto'>

            <table>
              <thead>
                <tr>
                  <th align="left">Parameter</th>
                  <th align="center">Format</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                  <th align="center">Example</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="left">country_code</td>
                  <td align="center">
                    ISO 3166 alpha-2 &#40;
                    <a href="https://www.iso.org/glossary-for-iso-3166.html">
                      https://www.iso.org/glossary-for-iso-3166.html
                    </a>
                    &#41;
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">Country code in which the user resides</td>
                  <td align="center">N/A</td>
                  <td align="center">ES &#40;Spain&#41;</td>
                </tr>
                <tr>
                  <td align="left">user_id</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Identifier of the user making the request &#40;Admin or
                    Affiliate&#41;
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">4532</td>
                </tr>
                <tr>
                  <td align="left">session_id</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send signature to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Obtained by identifying the user with ID user_id on the platform
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">385-XadgYoWixbzN_wqWc3Yyog==</td>
                </tr>
                <tr>
                  <td align="left">firma</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send session_id to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Enables you to sign the request without having to log in
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
                </tr>
                <tr>
                  <td align="left">command</td>
                  <td align="center">String</td>
                  <td align="center">Yes</td>
                  <td align="center">
                    It can contain the value <code>status</code>, <code>open</code> or{' '}
                    <code>close</code>
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">open</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>
          <h3 >Answer JSON OK:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2 >Where:</h2>
          <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>bosh_data:</code> Chat connection data.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>appraiser:</code> Operator identifier in the chat.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>chat_status:</code> Current chat status: open/close.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>elapsed:</code> Operation execution time.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Business logic:</h2>
          <p>
            This service is used to know the status of the user&#39;s connection in
            the chat. The service also has several commands:
          </p>
          <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>open</code>: Open a connection to the chat service.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>close</code>: Closes a connection with the chat service.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>status</code>: Indicates the status of the connection with the
              chat.
            </li>
          </ul>

        </div>
        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>

      </div>


    </Fragment>
  );
}
export default Chat_Operador_EN;
