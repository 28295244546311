import React, { FunctionComponent } from 'react'; 
import { Container } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';


const PageBlank:FunctionComponent<{}> = () => {
  return(
    <>
            <BrowserView>
    <Container>
      <div className="container-fluid">
	<div className="row">
    
		<div className="col-md-12">
      
		</div>
	</div>
</div>
    </Container>
    </BrowserView>
    <MobileView>
        <Disclaimer/>
    </MobileView>
</>
  );
}

export default PageBlank;