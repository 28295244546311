import React, { useState } from "react";


import icn_item from '../../../assets/img/ui_icons/cld_apiProjects.svg';
import chevron from '../../../assets/img/ui_icons/chevron.svg';


function ApiProject() {

    // Estado para controlar la visibilidad del menú y el valor seleccionado
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Select API Product");

    // Opciones del menú
    const options = ["API 1", "API 2", "API 3"];

    // Función para manejar la selección de una opción
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false); // Cerrar el menú después de seleccionar
    };



    return (
        //h-type controla la altura
        <div id='w_apiProject' className="item h-type-1">
            <div className="title">
                <span><img src={icn_item} alt="" /></span>
                <p> API Project</p>
            </div>

            <div className="data">
                <div className="dataContent">
                    <span>Request</span>
                    <p>1059</p>
                </div>

                <div className="selectWrapper">
                    <div className="custom-select" onClick={() => setIsOpen(!isOpen)}>
                        <span className="icon">
                        </span>
                        <span className="text">{selectedOption}</span>
                        <span className="arrow"><img src={chevron} alt="" /></span>

                        {isOpen && (
                            <div className="options">
                                {options.map((option, index) => (
                                    <div
                                        key={index}
                                        className="option"
                                        onClick={() => handleOptionClick(option)}
                                    >
                                        {option}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>

            </div>

            <div className="bottom">
                <div className="label">00.00% more than last month</div>
                <div className="label">3 in use of 10</div>
            </div>
        </div>
    );
}

export default ApiProject;