import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const Order_History_Summary = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status":true,
        "codtran":"850c29598f8ceae89e7083d1547faa29",
        "result":{
           "status":"Completed",
           "delivery_time":"13:12:55",
           "delivery_type":"sent",
           "order_category_id":"FL016",
           "delivery_distance":6987,
           "status_code":1,
           "order_products_codes":"[\n {\n \"article_id\": 39002,\n \"custom\": []\n },\n {\n \"article_id\": 39002,\n \"custom\": []\n },\n {\n \"article_id\": 39002,\n \"custom\": []\n },\n {\n \"article_id\": 39002,\n \"custom\": []\n }\n]",
           "establishment_coordinates":{
              "latitude":40.630568688062624,
              "longitude":-3.585006931371174
           },
           "location_gps":"{\n \"direction\": {\n \"street\": \"Calle Cádiz \",\n \"number\": \" 21\",\n \"city\": \"Fuente del Fresno\"\n },\n \"positioning\": {\n \"lat\": 40.5910124,\n \"lng\": -3.5886479\n }\n}",
           "delivery_price":"1,90 €",
           "contact":"{\n \"firstName\": \"Rafael\",\n \"Surname\": \"Surname1 Surname2\",\n \"mail\": \"rafa32gr@gmail.com\",\n \"phone\": \"643340526\"\n}",
           "purchase_detail":"Tortilla soup\nTortilla soup\nTortilla soup\nTortilla soup\n",
           "order_code":101033,
           "date":"18/08/2021 05:17:56",
           "transaction_code":"988569e4f38717f35e0fa5c256fe466b",
           "status_history":[
              {
                 "previous_status_code":12,
                 "status_change_date":"2021-08-18 12:17:57",
                 "new_status":"Sent to restaurant",
                 "new_status_code":0,
                 "previous_status":"Nuevo pedido"
              },
              {
                 "previous_status_code":0,
                 "status_change_date":"2021-08-18 12:18:51",
                 "new_status":"Being picked up",
                 "new_status_code":11,
                 "previous_status":"Sent to restaurant"
              },
              {
                 "previous_status_code":11,
                 "status_change_date":"2021-08-18 12:19:57",
                 "new_status":"In delivey",
                 "new_status_code":10,
                 "previous_status":"Being picked up"
              },
              {
                 "previous_status_code":10,
                 "status_change_date":"2021-08-18 12:20:37",
                 "new_status":"Completed",
                 "new_status_code":1,
                 "previous_status":"In delivey"
              }
           ],
           "client_address":"Fuente del Fresno\nCalle Cádiz Número 21\n643340526"
        },
        "elapsed":0.12410902976989746
     }
     `,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requestsurl = "http://34.121.95.179:80/ws/orders.py/last_order_summary?country_code=ES&user_id=14&session_id=1387-REIfL6Z3FzpGRvc9PYdbFg==&start_date=2018-01-01&end_date=2022-07-07"
      payload={}
      headers = { '101ObexApiKey': 'FsGwQeqT9TmGmQ2ytEImM6bDAMshRI55'}
      response = requests.request("GET", url, headers=headers, data=payload)
      print(response.text)
      `
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requestsurl = "http://34.121.95.179:80/ws/orders.py/last_order_summary?country_code=ES&user_id=14&session_id=1387-REIfL6Z3FzpGRvc9PYdbFg==&start_date=2018-01-01&end_date=2022-07-07"
        payload={}
        headers = { '101ObexApiKey': 'FsGwQeqT9TmGmQ2ytEImM6bDAMshRI55'}
        response = requests.request("GET", url, headers=headers, data=payload)
        print(response.text)`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
            var options = {
              'method': 'GET',
              'url': 'http://34.121.95.179:80/ws/orders.py/last_order_summary?country_code=ES&user_id=14&session_id=1387-REIfL6Z3FzpGRvc9PYdbFg==&start_date=2018-01-01&end_date=2022-07-07',
              'headers': {
                '101ObexApiKey': 'FsGwQeqT9TmGmQ2ytEImM6bDAMshRI55'
              }
            };
            request(options, function (error, response) {
              if (error) throw new Error(error);
              console.log(response.body);
            });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var myHeaders = new Headers();
            myHeaders.append("101ObexApiKey", "FsGwQeqT9TmGmQ2ytEImM6bDAMshRI55");
            
            var requestOptions = {
             method: 'GET',
             headers: myHeaders,
             redirect: 'follow'
            };
            
            fetch("http://34.121.95.179:80/ws/orders.py/last_order_summary?country_code=ES&user_id=14&session_id=1387-REIfL6Z3FzpGRvc9PYdbFg==&start_date=2018-01-01&end_date=2022-07-07", requestOptions)
             .then(response => response.text())
             .then(result => console.log(result))
             .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request GET 'http://34.121.95.179:80/ws/orders.py/last_order_summary?country_code=ES&user_id=14&session_id=1387-REIfL6Z3FzpGRvc9PYdbFg==&start_date=2018-01-01&end_date=2022-07-07' \--header '101ObexApiKey: FsGwQeqT9TmGmQ2ytEImM6bDAMshRI55'`
      }
    ]
  }


  return (
    <Fragment>

      <div className='container-fluid row'>
        <div className='col-md-7'>

          <h1 >Order History Summary</h1>
          <h2 >
            ws/orders.py/order_history_summary
          </h2>
          <h3 >Receives</h3>
          <p>
            All the parameters that the service receives must be indicated in the body
            of the request.
          </p>

          <div className='overflow-auto'>

            <table>
              <thead>
                <tr>
                  <th align="center">Parameter</th>
                  <th align="center">Format</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                  <th align="center">Example</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">country_code</td>
                  <td align="center">
                    ISO 3166 alpha-2 &#40;
                    <a href="https://www.iso.org/glossary-for-iso-3166.html">
                      https://www.iso.org/glossary-for-iso-3166.html
                    </a>
                    &#41;
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">Country code in which the user resides</td>
                  <td align="center">N/A</td>
                  <td align="center">ES</td>
                </tr>
                <tr>
                  <td align="center">user_id</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Identifier of the user making the request &#40;Administrator&#41;
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">18</td>
                </tr>
                <tr>
                  <td align="center">session_id</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send signature to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Obtained by identifying the user with ID user_id on the platform
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">34242-7M94x6WvLh7-b19HC6PM_g==</td>
                </tr>
                <tr>
                  <td align="center">signature</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send session_id to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Enables you to sign the request without having to log in
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">328621ee3acda7a582338c5bba444c3094cd6f29</td>
                </tr>
                <tr>
                  <td align="center">start_date</td>
                  <td align="center">
                    String dd/mm/yyyy, yyyy/mm/dd, dd-mm-yyyy, yyyy-mm-dd
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">Order query start date</td>
                  <td align="center">N/A</td>
                  <td align="center">2019-10-12</td>
                </tr>
                <tr>
                  <td align="center">end_date</td>
                  <td align="center">
                    String dd/mm/yyyy, yyyy/mm/dd, dd-mm-yyyy, yyyy-mm-dd
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">End date of the order query</td>
                  <td align="center">N/A</td>
                  <td align="center">2020-10-12</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 >
            Returns if user is RIDER profile:
          </h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>

          <h2 >Where:</h2>
          <ul>
            <li>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li>
              <code>codtran:</code> Operation result.
            </li>
            <li>
              <code>result:</code> Data of the last order in course.
              <ul>
                <li>
                  <code>status:</code> Current status of the order.
                </li>
                <li>
                  <code>status_code</code>: Code of the current state of the order.
                </li>
                <li>
                  <code>transaction_code</code>: Code of the transaction.
                </li>
                <li>
                  <code>order_code:</code> Code of the order.
                </li>
                <li>
                  <code>date:</code> Date of the order.
                </li>
                <li>
                  <code>client_address:</code> Address of the client.
                </li>
                <li>
                  <code>establishment_coordinates</code>: Coordinates of the
                  establishment that does the order.
                </li>
                <li>
                  <code>location_gps:</code>Coordinates of the company direction.
                </li>
                <li>
                  <code>delivery_time:</code> Hour established for the delivery.
                </li>
                <li>
                  <code>delivery_type:</code> Type of delivery &#40;pick up/ sent&#41;
                </li>
                <li>
                  <code>order_products_codes:</code> List of code products and its
                  options.
                </li>
                <li>
                  <code>purchase_detail:</code> List of product names with its
                  options.
                </li>
                <li>
                  <code>order_category_id:</code> Identifier of the establishment that
                  provides the order.
                </li>
                <li>
                  <code>contact:</code> Client contact.
                </li>
                <li>
                  <code>status_history:</code> History of status changes in the order.
                  <ul>
                    <li>
                      <code>new_status:</code> New status.
                    </li>
                    <li>
                      <code>new_status_code:</code> Code of the new status.
                    </li>
                    <li>
                      <code>previous_status:</code> Previous status.
                    </li>
                    <li>
                      <code>previous_status_code:</code> Previous status code.
                    </li>
                    <li>
                      <code>status_change_date:</code> Hour and time of the status
                      change.
                    </li>
                  </ul>
                </li>
                <li>
                  <code>delivery_price:</code> Price of the delivery.
                </li>
                <li>
                  <code>delivery_distance:</code> Distance between the client and the
                  establishment.
                </li>
              </ul>
            </li>
            <li>
              <code>elapsed:</code> Operation execution time.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Business logic:</h2>
          <p>
            The objective of this service is to retrieve the completed requests of the
            riders.
          </p>

        </div>
        <div className='col-md-5'>

          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>
          </div>


        </div>

      </div>

    </Fragment>
  );
}
export default Order_History_Summary;
