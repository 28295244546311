import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h4>1.1.2 Floating-Point Numbers (float)</h4>
  <p>
    Floating-point numbers represent real numbers with decimals. In AVAP™, they are defined using the <code>float</code> data type.
  </p>
  <pre>
    <code>
{`floating_number = 3.14`}
    </code>
  </pre>
</div>
  </Fragment>
);
export default Intro_1;
