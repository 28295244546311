import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, Spinner, Alert } from "react-bootstrap";
import axios from "axios";
import {url, port} from '../../configs/appConfig.json';
const UnitTestForm = ({ apiDetails, onClose }) => {
	const [parameters, setParameters] = useState([]);
	const [validations, setValidations] = useState([]);
	const [noConfigMessage, setNoConfigMessage] = useState(null);
	const [loading, setLoading] = useState(false);
	const [parameterError, setParameterError] = useState(false); // For API Parameters
	const cloud = `${url}:${port}`; // Base URL

	useEffect(() => {
		if (apiDetails) {
			// Fetch parameters and validations for the selected API
			const fetchApiDetails = async () => {
				setLoading(true);
				try {
					// Fetch parameters
					const paramsResponse = await axios.get(
						`${cloud}/publishing/get_api_paremeters?obex_project_id=${apiDetails.project_id}&endpoint=${apiDetails.endpoint}`
					);
                    console.log("Parameters Response:", paramsResponse.data);
					setParameters(paramsResponse.data.parameters || []);
					setParameterError(paramsResponse.data.parameters?.length === 0); // Check for empty parameters

					// Fetch response validations
					const validationsResponse = await axios.get(
						`${cloud}/unittest/config?endpoint=${apiDetails.endpoint}&obex_project_id=${apiDetails.project_id}`
					);
                    console.log("Validations Response:", validationsResponse.data);

					if (validationsResponse.data.data.config.length === 0) {
						setNoConfigMessage(validationsResponse.data.message);
					} else {
						console.log(validationsResponse.data.data.config[0]?.response_validation)
						setValidations(validationsResponse.data.data.config[0]?.response_validation.results.split(',') || []);
					}
				} catch (error) {
					console.error("Error fetching API details:", error);
					setParameterError(true); // Set parameter error if API fails
				}
				setLoading(false);
			};

			fetchApiDetails();
		}
	}, [apiDetails]);

	// Update parameter handler
	const updateParameter = (index, key, value) => {
		const updated = [...parameters];
		updated[index][key] = value;
		setParameters(updated);
	};

	// Update validation handler
	const updateValidation = (index, key, value) => {
		const updated = [...validations];
		updated[index][key] = value;
		setValidations(updated);
	};

	// Handle save functionality
	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			const payload = {
				endpoint: apiDetails.endpoint,
				obex_project_id: apiDetails.project_id,
				param_config: {
					parameters: parameters.reduce(
						(acc, param) => ({ ...acc, [param.name]: param.value }),
						{}
					),
					results: validations.reduce(
						(acc, validation) => ({ ...acc, [validation.field]: validation.regex }),
						{}
					),
				},
			};

			await axios.post(`${cloud}/unittest/config`, payload);

			alert("Unit test configuration saved successfully!");
			onClose();
		} catch (error) {
			console.error("Error saving unit test configuration:", error);
			alert("Failed to save unit test configuration. Please try again.");
		}
	};

	if (!apiDetails) {
		return <div>Please select an API to configure unit tests.</div>;
	}

	return (
		<Form onSubmit={handleSubmit}>
		{/*}	<h5>API: {apiDetails.api_name}</h5>
			<p>Endpoint: {apiDetails.endpoint}</p> */}

			{loading ? (
				<Spinner animation="border" />
			) : (
				<>
					{/* Parameters Section */}
					<Form.Group controlId="parameters" className="mb-3">
						<Form.Label>API Parameters</Form.Label>
						{parameterError && (
							<Alert variant="warning">No parameters found for this API.</Alert>
						)}
						{parameters.map((param, index) => (
							<Row key={index} className="mb-2">
								<Col md="6">
									<Form.Control
										type="text"
										placeholder="Parameter Name"
										value={param.name || ""}
										onChange={(e) => updateParameter(index, "name", e.target.value)}
									/>
								</Col>
								<Col md="6">
									<Form.Control
										type="text"
										placeholder="Value"
										value={param.value || ""}
										onChange={(e) => updateParameter(index, "value", e.target.value)}
									/>
								</Col>
							</Row>
						))}
					</Form.Group>

					{/* Validations Section */}
					<Form.Group controlId="validations" className="mb-3">
						<Form.Label>Response Validations</Form.Label>
						{noConfigMessage && (
							<Alert variant="warning">{noConfigMessage}</Alert>
						)}
						{validations.map((validation, index) => (
							<Row key={index} className="mb-2">
								<Col md="6">
									<Form.Control
										type="text"
										disabled
										placeholder="Field"
										value={validation.split(':')[0].replace(/"/g, '').replace(/{/g, '') || ""}
										onChange={(e) =>
											updateValidation(index, "field", e.target.value)
										}
									/>
								</Col>
								<Col md="6">
									<Form.Control
										type="text"
										placeholder="Regex"
										value={validation.split(':')[1].replace(/\"/g, '').replace(/\}/g, '') || ""}
										onChange={(e) =>
											updateValidation(index, "regex", e.target.value)
										}
									/>
								</Col>
							</Row>
						))}
					</Form.Group>
				</>
			)}

			{/* Save and Close Buttons */}
			<Row>
				<Col md="6">
					<Button className="w-100" variant="primary" type="submit">
						Save
					</Button>
				</Col>
				{/*}
				<Col md="6">
					<Button className="w-100" variant="secondary" onClick={onClose}>
						Close
					</Button>
				</Col>
				*/}
			</Row>
		</Form>
	);
};

export default UnitTestForm;
