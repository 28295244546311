import React, { Fragment } from 'react';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

const ApiProductPublishingGuide = () => (
  <Fragment>
    <Typography>
      <Title level={1}>Guide for Publishing an API Product on the AVAP Marketplace</Title>
      <Paragraph>
        This guide walks you through the entire process of preparing, certifying, and publishing an API Product on the AVAP Marketplace. Each section covers a crucial step, including repository setup, testing, and managing tester invitations.
      </Paragraph>

      <Title level={2}>1. Setting Up an API Product Repository</Title>
      <Paragraph>
        The first step in publishing an API Product involves setting up a code repository using the AVAP API Version Control extension for Visual Studio Code. In this setup:
      </Paragraph>
      <ul>
        <li>Create repositories specifically for managing your API versions.</li>
        <li>Configure environments (e.g., development, testing, and production) to organize API deployments.</li>
      </ul>

      <Title level={2}>2. Adding APIs to an API Product</Title>
      <Paragraph>
        After setting up the repository, create individual APIs within the AVAP API Publisher. APIs must be grouped under an API Product, which is the unit you’ll publish on the Marketplace.
      </Paragraph>
      <ul>
        <li>Configure unit tests for each API. These tests automatically run on each commit to ensure code stability and functionality.</li>
        <li>API Products are published in stages, starting from development to testing, and finally to the live environment.</li>
      </ul>
      <Paragraph>
        Once an API Product is successfully published in the Live environment, you can submit a request to have it appear on the AVAP Marketplace.
      </Paragraph>

      <Title level={2}>3. Managing API Products in the Marketplace</Title>
      <Paragraph>
        Go to the "My API Products" section in the Marketplace header. Here, you can manage existing API Products and monitor their publication status.
      </Paragraph>
      <Paragraph>
        Each API Product has three main sections:
      </Paragraph>
      <ul>
        <li><strong>Information:</strong> Contains detailed settings and descriptions for the API Product.</li>
        <li><strong>Certification:</strong> Manages the certification process required for Marketplace listing.</li>
        <li><strong>Invitation to Testers:</strong> Allows inviting testers to try out the API Product before going live.</li>
      </ul>

      <Title level={2}>4. Information Section</Title>
      <Paragraph>
        In this section, fill out a form with details about the API Product. This information appears on the API Product’s Marketplace page and includes the following:
      </Paragraph>
      <ul>
        <li><strong>Basic Information:</strong> The name of the API Product.</li>
        <li><strong>Description:</strong> Contains four fields:
          <ul>
            <li><strong>Overview:</strong> A general summary of the API Product.</li>
            <li><strong>Features:</strong> Key features and capabilities.</li>
            <li><strong>Terms of Use:</strong> Usage rules and restrictions.</li>
            <li><strong>Documentation:</strong> Links to API documentation.</li>
          </ul>
        </li>
        <li><strong>Logo:</strong> Upload an image representing the API Product.</li>
        <li><strong>Country:</strong> Specify the countries where the API Product will be available.</li>
        <li><strong>Keywords:</strong> Add tags for easy searchability and categorization.</li>
        <li><strong>Web and Social Media (Optional):</strong> Links to the developer’s website, support contact, and social media profiles.</li>
        <li><strong>Price and Period (Optional):</strong> 
          <ul>
            <li><strong>Price:</strong> Set to 0 by default, indicating a free API. If a price is added, additional fields appear.</li>
            <li><strong>Period:</strong> Select between monthly or annual billing cycles if a price is set.</li>
            <li><strong>Free Trial:</strong> Specify the number of days for a free trial, if offered.</li>
          </ul>
        </li>
      </ul>

      <Title level={2}>5. Certification Section</Title>
      <Paragraph>
        After completing all mandatory fields in the Information section, the "Start Certification" button activates. By clicking it, the API Product status changes from "Draft" to "Certification." This status change has the following effects:
      </Paragraph>
      <ul>
        <li>Invited testers lose access to the API Product.</li>
        <li>Tester tokens become invalid, preventing further testing.</li>
        <li>New tester invitations are disabled.</li>
      </ul>
      <Paragraph>
        In the Certification section, you can track the API Product’s progress through various certification stages. Once certified, the "Live on Marketplace" button becomes available, allowing you to make the API Product publicly accessible. If you wish to withdraw it, select "Request Removal from Marketplace."
      </Paragraph>
      <Paragraph>
        Note that the AVAP Framework reserves the right to remove any API Product that does not meet necessary standards, making it inaccessible to all users.
      </Paragraph>

      <Title level={2}>6. Invitation to Testers Section</Title>
      <Paragraph>
        The Invitation to Testers section allows you to invite potential testers to try out the API Product. To invite testers:
      </Paragraph>
      <ol>
        <li>Enter the email address of each tester you want to invite.</li>
        <li>The tester receives an email with three options:
          <ul>
            <li><strong>Accept:</strong> Grants access to the API Product with a temporary token, valid while the API Product is in draft status.</li>
            <li><strong>Reject:</strong> Denies access to the API Product.</li>
            <li><strong>Check API:</strong> Displays a summary of the API Product with essential information for testing purposes.</li>
          </ul>
        </li>
      </ol>

      <Title level={2}>7. Important Notes</Title>
      <Paragraph>
        Ensure that all required fields in the Information section are completed before starting certification. Invited testers only have access while the API Product is in draft status.
      </Paragraph>
    </Typography>
  </Fragment>
);

export default ApiProductPublishingGuide;