import React, { useState } from 'react';
import { Form, Button, Row, Col, Spinner, Alert } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';

const ApiProductForm = ({ availableProjects }) => {
  const initialFormState = {
    project: '',
    apiProduct: '',
    rateLimit: '',
    rateLimitPeriod: 'perMonth',
    monetization: '',
    security: [],
    apiType: '',
  };

  const [formData, setFormData] = useState(initialFormState);
  const [apiProducts, setApiProducts] = useState([]);
  const [loadingApiProducts, setLoadingApiProducts] = useState(false);

  const monetizationOptions = ['Free', 'Subscription'];
  const securityOptions = ['JWT', 'Basic Auth', 'CORS', 'HMAC', 'Signature', 'CSRF Protection'];
  const apiTypes = ['SOAP', 'REST', 'GraphQL', 'RPC'];

  const handleChange = async (e) => {
    const { name, value, type, checked } = e.target;

    if (name === 'project') {
      setFormData((prevData) => ({ ...prevData, project: value, apiProduct: '' }));
      setLoadingApiProducts(true);

      try {
        const response = await ObexRequestHandler.get(
          `/publishing/get_publish_products_by_session?obex_project_id=${value}`,
          {},
          true
        );
        const publishedApis = response.data || {};
        const products = publishedApis.Products || [];

        if (products.length === 0) {
          console.warn('No API Products found for the selected project.');
        }

        setApiProducts(products.map((product) => ({ id: product.id, name: product.name })));
      } catch (error) {
        console.error('Error fetching API Products:', error);
        setApiProducts([]);
      } finally {
        setLoadingApiProducts(false);
      }
    } else if (type === 'checkbox') {
      setFormData((prevData) => ({
        ...prevData,
        security: checked
          ? [...prevData.security, value]
          : prevData.security.filter((item) => item !== value),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form submitted:', formData);
    alert('Form successfully submitted');
  };

  return (
    <Form onSubmit={handleSubmit} className="cloudForm" style={{ maxWidth: '700px', margin: '0 auto' }}>
      <h3>API Product Configuration</h3>

      {/* Select de API Projects */}
      {availableProjects.length === 0 ? (
        <Alert variant="danger">No API Projects available. Please create one first.</Alert>
      ) : (
        <Form.Group controlId="project">
          <Form.Label>Available Projects</Form.Label>
          <Form.Control
            as="select"
            name="project"
            value={formData.project}
            onChange={handleChange}
          >
            <option value="">Select a project</option>
            {availableProjects.map((project) => (
              <option key={project.id} value={project.id}>
                {project.description}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )}

      {/* Select de API Products */}
      {formData.project && (
        <Form.Group controlId="apiProduct">
          <Form.Label>Available API Products</Form.Label>
          {loadingApiProducts ? (
            <Spinner animation="border" role="status" size="sm">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : apiProducts.length === 0 ? (
            <Alert variant="warning">No API Products found for this project.</Alert>
          ) : (
            <Form.Control
              as="select"
              name="apiProduct"
              value={formData.apiProduct}
              onChange={handleChange}
            >
              <option value="">Select an API Product</option>
              {apiProducts.map((product) => (
                <option key={product.id} value={product.id}>
                  {product.name}
                </option>
              ))}
            </Form.Control>
          )}
        </Form.Group>
      )}

      {/* Rate Limit Period */}
      <Form.Group controlId="rateLimitPeriod">
        <Form.Label>Rate Limit Period</Form.Label>
        <Form.Control
          as="select"
          name="rateLimitPeriod"
          value={formData.rateLimitPeriod}
          onChange={handleChange}
        >
          <option value="perMonth">Per Month</option>
          <option value="perDay">Per Day</option>
        </Form.Control>
      </Form.Group>

      {/* Rate Limit */}
      <Form.Group controlId="rateLimit">
        <Form.Label>Rate Limit (Requests)</Form.Label>
        <Form.Control
          type="number"
          name="rateLimit"
          value={formData.rateLimit}
          onChange={handleChange}
          placeholder="Example: 100"
        />
      </Form.Group>

      {/* Monetization */}
      <Form.Group controlId="monetization">
        <Form.Label>Monetization</Form.Label>
        <Form.Control
          as="select"
          name="monetization"
          value={formData.monetization}
          onChange={handleChange}
        >
          <option value="">Select an option</option>
          {monetizationOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {/* Security */}
      <Form.Group>
        <Form.Label>Security</Form.Label>
        <Row>
          {securityOptions.map((option) => (
            <Col xs={6} key={option} className="mb-2">
              <div className="d-flex align-items-center">
                <span>{option}</span>
                <label className="custom-toggle ml-auto">
                  <input
                    type="checkbox"
                    name="security"
                    value={option}
                    checked={formData.security.includes(option)}
                    onChange={handleChange}
                  />
                  <span className="slider"></span>
                </label>
              </div>
            </Col>
          ))}
        </Row>
      </Form.Group>

      {/* API Type */}
      <Form.Group controlId="apiType">
        <Form.Label>API Type</Form.Label>
        <Form.Control
          as="select"
          name="apiType"
          value={formData.apiType}
          onChange={handleChange}
        >
          <option value="">Select a type</option>
          {apiTypes.map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      <Row>
        <Col md='6'>
          <Button type="submit" variant="primary" className='w-100'>
            Submit
          </Button>
        </Col>
        <Col md='6'>

          <Button className='w-100' variant="outline-secondary" onClick={() => setFormData(initialFormState)}>
            Cancel
          </Button>
        </Col>
      </Row>




    </Form>
  );
};

export default ApiProductForm;
