import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, Navbar, Container, Nav, Image} from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import "./NewLanding.css"
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import Logo from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import icon_name from "../../assets/img/newDashboard/icon_name.png";
import icono_Infinito from "../../assets/img/newDashboard/icono-Infinito.png";
import logo_communities from "../../assets/img/newDashboard/logo_communities.png";
import logo_alexandria from "../../assets/img/newDashboard/logo-alexandria.png";
import logo_Connect from "../../assets/img/newDashboard/logo-Connect.png";
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from "../../assets/img/avap_logo.png"
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../components/mobileComponents/DisclaimerCloud';
import TopMenuBar from '../landing_framework/TopMenuBar';

type DashProps = {
    sider: any
};



const NewLanding = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();
    const [ menu1, setmenu1 ] = useState(false);
const [ menu2, setmenu2 ] = useState(false);
const [ menu3, setmenu3 ] = useState(false);
const [ menu4, setmenu4 ] = useState(false);
const [ menu5, setmenu5 ] = useState(false);

const cleanMenuAll = ()=>{
    setmenu1(false);
    setmenu2(false);
    setmenu3(false);
    setmenu4(false);
    setmenu5(false);
}

    useEffect(() => {
        //ReactGA.initialize('G-98LF8608ZM');

        if (config.url != 'https://avs-primary-pre.101obex.mooo.com'){
            ReactGA.initialize('G-98LF8608ZM');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap ID' });}

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario
    
        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
      };



    return (
        <>
            <BrowserView>

        <div className='d-flex flex-column'>

        <TopMenuBar/>


        <div onClick={()=>{cleanMenuAll()}}>      

            <div className="d-flex flex-column align-items-center">


                <div className='d-flex flex-column  min-vh-100 align-items-center w-principal justify-content-center'>

                    <div>
                        <div className='landingSizeLogo' style={{marginTop:"16rem", marginBottom:'2rem'}}>
                            <img style={{marginLeft:'10%',height:'80%', width:'80%'}} src={Logo} alt="" width={"100%"} height={"100%"}/>
                        </div>
                    </div>
                    <div  className='landingFont1 textBold landingFontColor mt-5 pt-3 text-center'>Your account for AVAP Framework</div>
                    <div  className='landingFont2  landingFontColor mt-5 pt-3 text-center'>Your AVAP ID will give you access to AVAP products and services.</div>
                    <div className='landingFont2  landingFontColor text-center'>Sign in to manage your account.</div>
                <div className='mt-5 pt-3' style={{marginBottom:"8rem"}}>
                    <button className="btn custom-button w-100 textBold " onClick={handleSignInClick}>Sign in</button>
                </div>

                <div className='textos w-text d-flex mt-5 pt-3'>
                    <div>
                    <div className='iconsSize mr-2'>
                            <img src={icon_name} alt="" width={"100%"} height={"100%"}/>
                        </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='landingFont3 textBold mb-5'>
                        What is AVAP ID?
                        </div>
                        <div className='landingFont4'>
                        AVAP ID is your personal account to access AVAP products and services like Interpreter & Language, AVAP Cloud, AVAP Dev Extensions, AVAP Gateway Advanced, AVAP Collaborative Network, and more. 
It includes your contact and sign in information, payment methods, and security details that you'll use for AVAP Framework.
                        </div>
                    </div>

                </div>

                <div className='textos w-text d-flex mt-5 pt-3'>
                    <div>
                        <div className='iconsSize mr-2' style={{height:'18px', width:'36px', marginLeft:'-10px'/*marginTop:"10px"*/}}>
                            <img src={icono_Infinito} alt="" width={"100%"} height={"150%"}/>
                        </div>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className='landingFont3 textBold mb-5'>
                        When do I use my AVAP ID?
                        </div>
                        <div className='landingFont4'>
                        Any time you set up a new device, make a purchase, or use any AVAP service, you will be asked to  sign in with your AVAP ID and password. 
                        Use the same AVAP ID everywhere you sign in to ensure that all your AVAP products, services and devices work together seamlessly, and access your personal content from all your devices.
                        </div>
                    </div>

                </div>

                <div className='textos w-text d-flex mt-5 pt-5 justify-content-between' style={{marginBottom:"112px"}}>
                    <div>
                        <div className='logosSize mr-2' >
                            <a href="https://speed-computing-1656.my.site.com/communities">
                            <img src={logo_communities} alt="" width={"80%"} height={"100%"}/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className='logosSize mr-2'>
                            <a href="https://speed-computing-1656.my.site.com/alexandria">
                            <img src={logo_alexandria} alt="" width={"80%"} height={"100%"}/>
                            </a>
                        </div>
                    </div>
                    <div>
                        <div className='logosSize mr-2'>
                            <a href="https://speed-computing-1656.my.site.com/connect">
                            <img src={logo_Connect} alt="" width={"80%"} height={"100%"}/>
                            </a>
                        </div>
                    </div>
{/*}                    <div>
                        <div className='logosSize mr-2'>
                            <img src={logo_framework} alt="" width={"100%"} height={"100%"}/>
                        </div>
                    </div>*/}
                    

                </div>

                </div>
            </div>
            </div>
            <div>
                <FooterMenu green={true}/>
            </div>

        </div>
        </BrowserView>
            <MobileView>
                <Disclaimer />
            </MobileView>
        </>
        
    )
}

export default NewLanding;