import React from 'react';
import './chapter.css'
const Chapter9_36 = () => (
    <div>
        <h2>Import Statement</h2>
        <p>
            In AVAP, the <strong>import</strong> statement is used to import an entire code file and define names in the local namespace. The syntax for the <strong>import</strong> statement is as follows:
        </p>
        <pre>
            <code>
                import file.avap
            </code>
        </pre>
        <p>
            The <strong>import</strong> statement in AVAP imports an entire code file and makes it available in the local namespace. No alias is assigned to the imported file; the file is simply referred to by its name.
        </p>
        <p>
            For example:
        </p>
        <pre>
            <code>
                # In the 'module.avap' file
                example_variable = 10

                # In the main file
                import module.avap
                print(module.avap.example_variable)
                # Will print 10
            </code>
        </pre>
        <p>
            In this example, the main file imports the <code>module.avap</code> file and can access the <code>example_variable</code> defined in that file using the <code>module.avap</code> syntax.
        </p>
    </div>
);

export default Chapter9_36;
