import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';

const Administrar_solicitudes_KYC_POST_EN = () => 
 {
  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{ 
        "status": <boolean>, 
        "user_data": { 
          "name": <string>, 
          "surname1": <string>, 
          "email": <string>, 
          "date_of_birth": <string>, 
          "surname2": <string> 
        }, 
          "fields": [ 
            { 
              "field_label": <string>, 
              "id": <string>, 
              "type": <string>, 
              "value": <string> 
            } ], 
            "stauts": <integer>, 
            "interview": <boolean>, 
            "elapsed": <float> 
          }`,
      copied: false
    },
    answe_ko1: {
      title:"Answer ok",
      type: "json",
      code: `{ 
        "status": false, 
        "level": <string>, 
        "message": <string>, 
        "error": <string> 
      }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: `import requests url = "URL_BASE/ws/admin.py/administrar_solicitud_kyc" 
      payload = {'country_code': 'MX', 'user-id': '2', 'session_id': '393--ZCAQsFyJF95Q2YpqRNLug==', 'phone': '7229063245', 'level_kyc': '3'} 
      files = [ ('firma_autografiada', open('test.jpeg','rb')), ('manifiesto_cuenta_propia', open('test.jpeg','rb')) ]
       headers= {} 
       response = requests.request("POST", url, headers=headers, data = payload, files = files) 
      print(response.text.encode('utf8'))`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/admin.py/administrar_solicitud_kyc" 
        payload = {'country_code': 'MX', 'user-id': '2', 'session_id': '393--ZCAQsFyJF95Q2YpqRNLug==', 'phone': '7229063245', 'level_kyc': '3'} 
        files = [ ('firma_autografiada', open('test.jpeg','rb')), ('manifiesto_cuenta_propia', open('test.jpeg','rb')) ]
         headers= {} 
         response = requests.request("POST", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: `var request = require('request');
            var fs = require('fs');
            var options = {
            'method': 'POST',
            'url': 'URL_BASE/ws/admin.py/administrar_solicitud_kyc',
            'headers': {
            },
            formData: {
            'country_code': 'MX',
            'user-id': '2',
            'session_id': '393--ZCAQsFyJF95Q2YpqRNLug==',
            'phone': '7229063245',
            'level_kyc': '3',
            'firma_autografiada': {
            'value': fs.createReadStream('test.jpeg'),
            'options': {
            'filename': 'test.jpeg',
            'contentType': null
            }
            },
            'manifiesto_cuenta_propia': {
            'value': fs.createReadStream('test.jpeg'),
            'options': {
            'filename': 'test.jpeg',
            'contentType': null
            }
            }
            }
            };
            request(options, function (error, response) { 
            if (error) throw new Error(error);
            console.log(response.body);
            });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: `var formdata = new FormData();
            formdata.append("country_code", "MX");
            formdata.append("user-id", "2");
            formdata.append("session_id", "393--ZCAQsFyJF95Q2YpqRNLug==");
            formdata.append("phone", "7229063245");
            formdata.append("level_kyc", "3");
            formdata.append("firma_autografiada", fileInput.files[0], "test.jpeg");
            formdata.append("manifiesto_cuenta_propia", fileInput.files[0], "test.jpeg");
            var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
            };
            fetch("URL_BASE/ws/admin.py/administrar_solicitud_kyc", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: `curl --location --request POST 'URL_BASE/ws/admin.py/administrar_solicitud_kyc' \ --form 'country_code=MX' \ --form 'user-id=2' \ --form 'session_id=393--ZCAQsFyJF95Q2YpqRNLug==' \ --form 'phone=7229063245' \ --form 'level_kyc=3' \ --form 'firma_autografiada=@test.jpeg' \ --form 'manifiesto_cuenta_propia=@test.jpeg'`
      }
    ]
  }
  return(
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
      <h1 id="manage-kyc-requests-modify">
        Manage KYC Requests &#40;Modify&#41;
      </h1>
      <p>This service is used to modify a KYC level up request.</p>
      <p>
        <span className="label post">POST:</span>
        <span> </span>{' '}
        <code>URL_BASE + /ws/admin.py/administrar_solicitud_kyc</code>
      </p>
      <h2 id="receives">Receives:</h2>
      <p>
        All the parameters that the service receives must be indicated in the body
        of the request.
      </p>
      <table>
        <thead>
          <tr>
            <th align="left">Parameter</th>
            <th align="center">Format</th>
            <th align="center">Obligatory</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="left">country_code</td>
            <td align="center">
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                ISO 3166 alpha-2
              </a>
            </td>
            <td align="center">Yes</td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">user-id</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">
              Identifier of the Administrator user, who will manage the KYC level
              up request
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">session_id</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send signature to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Obtained by identifying the user with ID user-id on the platform
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">signature</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send session_id to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Enables you to sign the request without having to log in
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">phone</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">
              Telephone number of the user who made the request
            </td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">level_kyc</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">Level KYC of the request we want to review</td>
            <td align="center">N/A</td>
          </tr>
          <tr>
            <td align="left">field_id</td>
            <td align="center">tipo_campo</td>
            <td align="center">No</td>
            <td align="center">
              Value to modify the fields that are returned when obtaining the
              request
            </td>
            <td align="center">N/A</td>
          </tr>
        </tbody>
      </table>
      <h2 id="returns">Returns:</h2>
      <p>
        Depending on the result of the operation, this service can return two
        different JSON:
      </p>
      <h3 id="answer-json-ok">Answer JSON OK:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
      <h2 id="where">Where:</h2>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>user_data:</code> Indicates the data of the user who made the
          request.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>name:</code> Name of the user making the request.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>surname1:</code> First surname of the user making the request.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>surname2:</code> Second surname of the user making the request.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>email:</code> Email of the user who makes the request.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>date_of_birth:</code> Date of birth of the user making the
          request.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>fields:</code> List with the fields to be reviewed by the
          administrator making the query.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>field_label:</code> Text to display in the label of each field of
          the form.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>id:</code> Html identifier of the field.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>type:</code> Contains the value{' '}
          <code>&quot;texto_numerico&quot;</code>,{' '}
          <code>&quot;texto_libre&quot;</code>,{' '}
          <code>&quot;seleccion_multiple&quot;</code>,{' '}
          <code>&quot;fecha&quot;</code>, <code>&quot;archivo&quot;</code>.
          Indicates the type of data that the field accepts.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>value:</code> Path where the document uploaded by the user is
          located, or field value.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>stauts:</code> Indicates the status of the request &#40;-1:
          Rejected, 0: In process&#41;.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>interview:</code> Indicates whether they are advisable to have a
          personal interview with the user requesting the upload level_kyc o no.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>elapsed:</code> Operation execution time.
        </li>
      </ul>
      <h3 id="answer-json-ko">Answer JSON KO:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
      <h2 id="where-1">Where:</h2>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>level:</code> Error importance level.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>message:</code> Error message.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>error:</code> Sole error code.
        </li>
      </ul>
      <h2 id="business-logic">Business logic:</h2>
      <p>
        This service allows you to modify the data of the request, and returns the
        updated data of the request.
      </p>
      </div>

<div className='col-md-5'>
      <h2 id="example-requests">Example requests:</h2>
      <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
      
      </div>
      </div>
    </Fragment>
  );
 }
export default Administrar_solicitudes_KYC_POST_EN;
