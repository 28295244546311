import React, { useState } from 'react';
import { Form, Button, Alert, InputGroup } from 'react-bootstrap';

const EditProfileForm = () => {
    const [formData, setFormData] = useState({
        orgId: 'aa40c490...',
        userId: '536e425e...',
        fullName: 'John Doe',
        preferredName: 'John',
        organization: 'TestObex',
        email: 'johnDoe@gmail.com',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert("Copied to clipboard!");
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };

    const handleDeleteAccount = () => {
        console.log("Account deletion initiated.");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Profile Data Submitted:", formData);
    };

    return (
        <Form className="cloudForm" onSubmit={handleSubmit} style={{ maxWidth: '500px', margin: '0 auto' }}>
            <h2>My Account</h2>

            {/* Org ID with Copy Button */}
            <Form.Group controlId="orgId">
                <Form.Label>Org ID:</Form.Label>
                <InputGroup>
                    <Form.Control type="text" value={formData.orgId} readOnly />
                    <Button variant="outline-secondary" onClick={() => handleCopy(formData.orgId)}>Copy</Button>
                </InputGroup>
            </Form.Group>

            {/* User ID with Copy Button */}
            <Form.Group controlId="userId">
                <Form.Label>User ID:</Form.Label>
                <InputGroup>
                    <Form.Control type="text" value={formData.userId} readOnly />
                    <Button variant="outline-secondary" onClick={() => handleCopy(formData.userId)}>Copy</Button>
                </InputGroup>
            </Form.Group>

            {/* Profile Section */}
            <h3>Profile</h3>
            <Form.Group controlId="fullName">
                <Form.Label>Full Name</Form.Label>
                <Form.Control
                    type="text"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleChange}
                    placeholder="Enter full name"
                />
                <Form.Text className="text-muted">
                    This name will be used in lists, such as the user list of your organization.
                </Form.Text>
            </Form.Group>

            <Form.Group controlId="preferredName">
                <Form.Label>Preferred Name</Form.Label>
                <Form.Control
                    type="text"
                    name="preferredName"
                    value={formData.preferredName}
                    onChange={handleChange}
                    placeholder="Enter preferred name"
                />
                <Form.Text className="text-muted">
                    This name can be anything you'd prefer us to use in communication with you.
                </Form.Text>
            </Form.Group>

            <Form.Group controlId="organization">
                <Form.Label>Organization</Form.Label>
                <Form.Control type="text" value={formData.organization} readOnly />
            </Form.Group>

            <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" value={formData.email} readOnly />
                <Form.Text className="text-muted">
                    Verified email cannot be changed.
                </Form.Text>
            </Form.Group>

            {/* Submit Button */}
            <div className="d-flex justify-content-end mt-4">
                <Button variant="primary" type="submit">Save Changes</Button>
            </div>

            {/* Delete Account Section */}
            <h3 className="mt-5">Delete Account</h3>
            <Alert variant="danger">
                <p>Deleting your account will remove your access from the organization <strong>{formData.organization}</strong>.</p>
                <p>If you wish to remove your personal information from our systems, please visit our Privacy Site.</p>
            </Alert>
            <Button variant="danger" onClick={handleDeleteAccount}>Delete Account</Button>
        </Form>
    );
};

export default EditProfileForm;
