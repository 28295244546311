import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h4>1.1.1 Dynamic Typing</h4>
      <p>
        In AVAP™, variable typing is dynamic, which means it is not necessary to explicitly declare the type of a variable before 
        assigning it a value. This allows greater flexibility in data handling and simplifies code writing.
      </p>
      <pre>
        <code>
          {`# Example of dynamic typing
x = 10  # x is an integer
x = "Hello"  # x is now a string`}
        </code>
      </pre>
    </div>
  </Fragment>
);
export default Intro_1;
