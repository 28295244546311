import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const Obtain_Transaction_Data = () => 
{

  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{
        "status":"<boolean>",
        "transaction_data":{
           "telefono_cuenta":"<string>",
           "transaction_code":"<string>",
           "user":"<string>",
           "user_email":"<string>",
           "origin_of_funds":"<string>",
           "destination":"<string>",
           "destination_phone":"<string>",
           "destination_language_id":"<integer>",
           "invoice":"<integer>",
           "reference":null,
           "concept":"<string>",
           "action":"<string>",
           "date":"<string>",
           "service_name":"<string>",
           "characteristics":{
              "<characteristics>"
           },
           "affiliate":"<string>",
           "affiliate_url":"<string>",
           "affiliate_url_image":"<string>",
           "amount":"<decimal>",
           "amount_fmt":"<string>",
           "total_commission":"<decimal>",
           "total_commission_fmt":"<string>",
           "commission":"<decimal>",
           "commission_fmt":"<string>",
           "vat_commission":"<decimal>",
           "vat_commission_fmt":"<string>",
           "vat_operation":"<decimal>",
           "vat_operation_fmt":"<string>",
           "currency":"<string>",
           "total":"<decimal>",
           "total_fmt":"<string>""subject_ticket":"<string>",
           "subject_ticket_destination":"<string>",
           "ticket_html":"<string>"
        },
        "elapsed":"<float>"
     }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: ` import requests url = "URL_BASE/ws/movimientos.py/transaction_data" 
    payload = {'country_code': 'MX', 'user_id': '4532', 'session_id': '414-YehjRL-mR64z81Nu9gg7og==', 'transaction_code': '60995737691715d3cc51aa4ab699fd84'} 
    files = [] 
    headers= {} 
    response = requests.request("POST", url, headers=headers, data = payload, files = files) 
    print(response.text.encode('utf8'))`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests url = "URL_BASE/ws/movimientos.py/transaction_data" 
    payload = {'country_code': 'MX', 'user_id': '4532', 'session_id': '414-YehjRL-mR64z81Nu9gg7og==', 'transaction_code': '60995737691715d3cc51aa4ab699fd84'} 
    files = [] 
    headers= {} 
    response = requests.request("POST", url, headers=headers, data = payload, files = files) 
    print(response.text.encode('utf8'))`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: ` var request = require('request');
  var options = {
  'method': 'POST',
  'url': 'URL_BASE/ws/movimientos.py/transaction_data',
  'headers': {},
  formData: {
  'country_code': 'MX',
  'user_id': '4532',
  'session_id': '414-YehjRL-mR64z81Nu9gg7og==',
  'transaction_code': '60995737691715d3cc51aa4ab699fd84'
  }
  };
  request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
  });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: ` var formdata = new FormData();
  formdata.append("country_code", "MX");
  formdata.append("user_id", "4532");
  formdata.append("session_id", "414-YehjRL-mR64z81Nu9gg7og==");
  formdata.append("transaction_code", "60995737691715d3cc51aa4ab699fd84");
  var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
  };
  fetch("URL_BASE/ws/movimientos.py/transaction_data", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: ` curl --location --request POST 'URL_BASE/ws/movimientos.py/transaction_data' \ --form 'country_code=MX' \ --form 'user_id=4532' \ --form 'session_id=414-YehjRL-mR64z81Nu9gg7og==' \ --form 'transaction_code=60995737691715d3cc51aa4ab699fd84'`
      }
    ]
  }

  return(
    <Fragment>

<div className='container-fluid row'>
        <div className='col-md-7'>

              <h1 >Obtain Transaction Data</h1>
              <p>
                This service gives you back all data associated to the chosen transaction.{' '}
              </p>
              <p>
                <span className="label post">POST:</span>
                <span> </span> <code>URL_BASE + /ws/movimientos.py/transaction_data</code>
              </p>
              <h2 >Receives:</h2>
              <p>
                All the parameters that the service receives must be indicated in the body
                of the request.
              </p>
              <div className='overflow-auto'>
              <table>
                <thead>
                  <tr>
                    <th align="left">Parameter</th>
                    <th align="center">Format</th>
                    <th align="center">Obligatory</th>
                    <th align="center">Description</th>
                    <th align="center">Default value</th>
                    <th align="center">Example</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td align="left">country_code</td>
                    <td align="center">
                      ISO 3166 alpha-2 &#40;
                      <a href="https://www.iso.org/glossary-for-iso-3166.html">
                        https://www.iso.org/glossary-for-iso-3166.html
                      </a>
                      &#41;
                    </td>
                    <td align="center">Yes</td>
                    <td align="center">Country code in which the user resides N/A</td>
                    <td align="center">ES &#40;Spain&#41;</td>
                    <td align="center"></td>
                  </tr>
                  <tr>
                    <td align="left">user_id</td>
                    <td align="center">Integer</td>
                    <td align="center">Yes</td>
                    <td align="center">Identifier of the user making the request</td>
                    <td align="center">N/A</td>
                    <td align="center">4532</td>
                  </tr>
                  <tr>
                    <td align="left">session_id</td>
                    <td align="center">String</td>
                    <td align="center">
                      Yes &#40;if you do not send signature to &#39;Access
                      Service&#39;&#41;
                    </td>
                    <td align="center">
                      Obtained by identifying the user with ID user_id on the platform
                    </td>
                    <td align="center">N/A</td>
                    <td align="center">385-XadgYoWixbzN_wqWc3Yyog==</td>
                  </tr>
                  <tr>
                    <td align="left">signature</td>
                    <td align="center">String</td>
                    <td align="center">
                      Yes &#40;if you do not send session_id to &#39;Access
                      Service&#39;&#41;
                    </td>
                    <td align="center">
                      Enables you to sign the request without having to log in
                    </td>
                    <td align="center">N/A</td>
                    <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
                  </tr>
                  <tr>
                    <td align="left">transaction_code</td>
                    <td align="center">String</td>
                    <td align="center">
                      Yes &#40;if you don&#39;t send the parameters of the request you
                      want to check&#41;
                    </td>
                    <td align="center">Transaction code</td>
                    <td align="center">N/A</td>
                    <td align="center">&quot;60995737691715d3cc51aa4ab699fd84&quot;</td>
                  </tr>
                </tbody>
              </table>
              </div>

      <h2 >Returns:</h2>
      <p>
        Depending on the result of the operation, this service can return two
        different JSON:
      </p>
      <h3 >Answer JSON OK:</h3>
  
      <div className='mb-3'>
        <DisplayCode1 codeToshow={codes.answe_ok1}/>
      </div>
      <h2 >Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li>
          <code>transaction_data:</code> Data of the requested transaction.
        </li>
        <li>
          <code>telefono_cuenta:</code> Telephone number of the account of origin
          of the transaction
        </li>
        <li>
          <code>transaction_code:</code> Transaction code.
        </li>
        <li>
          <code>user:</code> Name of the user of origin of the transaction..
        </li>
        <li>
          <code>user_email:</code> Email of the originating user of the
          transaction.
        </li>
        <li>
          <code>origin_of_funds:</code> Name of the source of funds used in the
          operation.
        </li>
        <li>
          <code>destination:</code> Name of the target user of the operation.
        </li>
        <li>
          <code>destination_phone:</code> Telephone number of the destination
          account of the operation.
        </li>
        <li>
          <code>destination_language_id:</code> Language identifier of the target
          user for the operation.
        </li>
        <li>
          <code>invoice:</code> Invoice number/invoice of the operation.
        </li>
        <li>
          <code>reference:</code> Invoice / page number of the operation.
        </li>
        <li>
          <code>concept:</code> Descriptive text of the operation.
        </li>
        <li>
          <code>action:</code> Name of the action that was executed with this
          operation.
        </li>
        <li>
          <code>date:</code> Date on which the transaction occurs.
        </li>
        <li>
          <code>service_name:</code> Text with the name of the service, if it does
          not have a name, the concept is returned.
        </li>
        <li>
          <code>characteristics:</code> Dictionary with the characteristics of the
          service, explained in greater depth on the endpoint{' '}
          <code>get_caracteristicas</code>.
        </li>
        <li>
          <code>affiliate:</code> Name of the affiliate of the user who executed
          the operation.
        </li>
        <li>
          <code>affiliate_url:</code> Affiliate website url.
        </li>
        <li>
          <code>affiliate_url_image:</code> Url of the affiliate image
          &#40;logo&#41;.
        </li>
        <li>
          <code>amount:</code> Amount of the operation without format or
          commissions.
        </li>
        <li>
          <code>amount_fmt:</code> Amount of the operation with the applied
          format, but without commissions.
        </li>
        <li>
          <code>total_commission:</code> Amount of commission charged without
          format and with VAT applied.
        </li>
        <li>
          <code>total_commission_fmt:</code> Amount of commission charged with the
          format and VAT applied.
        </li>
        <li>
          <code>commission:</code> Amount of the commission charged to the user
          without the format and without VAT.
        </li>
        <li>
          <code>commission_fmt:</code> Formatted commission amount and if VAT
          applied.
        </li>
        <li>
          <code>vat_commission:</code> VAT amount of raw commission.
        </li>
        <li>
          <code>vat_commission_fmt:</code> Commission VAT amount with the format.
        </li>
        <li>
          <code>vat_operation:</code> VAT amount applied to the operation carried
          out without format.
        </li>
        <li>
          <code>vat_operation_fmt:</code> Amount of VAT applied to the operation
          carried out with the applied currency format..
        </li>
        <li>
          <code>currency:</code> Name of the currency used in the operation.
        </li>
        <li>
          <code>total:</code> Total amount of the operation &#40;amount +
          commission + VAT&#41;, without format.
        </li>
        <li>
          <code>total_fmt:</code> Total amount of the operation &#40;amount +
          commission + VAT&#41;, with format.
        </li>
        <li>
          <code>subject_ticket:</code> Subject text of the ticket that is sent by
          mail to the user of origin of the operation.
        </li>
        <li>
          <code>subject_ticket_destination:</code> Subject text of the ticket that
          is sent by mail to the target user of the operation.
        </li>
        <li>
          <code>ticket_html:</code> Ticket that is mailed to users.
        </li>
        <li>
          <code>elapsed:</code> Operation execution time.
        </li>
      </ul>
      <h3 >Answer JSON KO:</h3>
      <div className='mb-3'>
        <DisplayCodeKo/>
      </div>
      <h2 >Business logic:</h2>
      <p>
        With this endpoint all the data associated with the requested transaction
        is returned.
      </p>

      </div>
      <div className='col-md-5'>

        <h2 >Example requests:</h2>
        <div className='mb-3 effectStickySections'>
        <DisplayCode2 codeToshow={codesMutiple}/>
      </div>
      
      </div>

      </div>
    </Fragment>
  );
}
export default Obtain_Transaction_Data;
