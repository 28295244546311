import React, { useState } from 'react';
import { FaBuilding } from 'react-icons/fa';
import chevron from '../../../assets/img/ui_icons/chevron.svg';

interface PaymentMethodProps {
  onAdd: () => void; // Prop para manejar el evento de añadir método de pago
}

const PaymentMethodForm: React.FC<PaymentMethodProps> = ({ onAdd }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select a card");
  
  // Opciones de tarjetas guardadas
  const options = [
    "**** **** **** 1234 - Visa",
    "**** **** **** 5678 - MasterCard",
    // Agrega más opciones aquí según sea necesario
  ];

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false); // Cierra el menú después de seleccionar
  };

  return (
    <form className="payment-method-form" >
      {/* Selección de tarjeta guardada usando custom-select */}
      <div className="form-group d-flex flex-wrap">
        <label htmlFor="savedCards w-100">Select Saved Card</label>
        <div className="custom-select w-100" onClick={() => setIsOpen(!isOpen)}>
          <span className="text">{selectedOption}</span>
          <span className="arrow">
            <img src={chevron} alt="chevron icon" />
          </span>
          {isOpen && (
            <div className="options">
              {options.map((option, index) => (
                <div
                  key={index}
                  className="option"
                  onClick={() => handleOptionClick(option)}
                >
                  {option}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Botones */}
      <div className="button-group d-flex justify-content-between mt-4">
  
        <button type="submit" className="btn btn-primary">
          Agree & Upgrade
        </button>

        <button type="button" className="btn btn-outline-secondary" onClick={onAdd}>
          Add Payment Method
        </button>
      </div>
    </form>
  );
};

export default PaymentMethodForm;
