import React, { FunctionComponent, useState } from 'react';
import { Modal, Nav, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import icono_ubicacion from "../../../assets/img/icono_ubicacion.png";
import icono_mail from "../../../assets/img/icono-mail.png";
import iconos_familia from "../../../assets/img/LogosFamilia_pie.png";
import avap_logo from "../../../assets/img/avap_logo.png"

import twitterblue from "../../../assets/img/twittergrey.png"
import linkedinblue from "../../../assets/img/linkedingrey.png"

import yticon from "../../../assets/img/icon-yt.png"
import redicon from "../../../assets/img/icon-redd.png"
import fbicon from "../../../assets/img/icono_fb.png"


import './style.css'
import { useHistory } from 'react-router-dom';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';

type color = {
  green?: boolean,
}

const FooterMenuAccess: FunctionComponent<color> = props => {
  const [neswleterEmail, setneswleterEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [registerin, setRegistering] = useState(false);
  const [showModal2, setshowModal2] = useState(false);
  
  const handleClose = () => {
    setshowModal2(false);
  }

  const validateNewsLetter = (email) => {
    const emailFormat = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
    if (email.match(emailFormat)) setValidEmail(true); else setValidEmail(false);

  }
  const porcessNewsLetterSuscription = async () => {
    console.log(neswleterEmail);
    const payload = {}
    setRegistering(true);
    setValidEmail(false);
    try {
    await ObexRequestHandler.post(`/salesforce/register_neswletter?email=${neswleterEmail}`, payload);
    setRegistering(false);
    setValidEmail(false);
    setneswleterEmail('');  
  } catch {
      setRegistering(false);
      setValidEmail(true);
    }

  };

  const history = useHistory();
  return (
    // <Nav id="footer-dashboard" className="justify-content-md-center ">
    //   <Nav.Item>
    //     <Nav.Link 
    //       target="_blank" 
    //       rel="noopener noreferrer" 
    //       href="https://101obex.com/termsconditions">Terms and Conditions1</Nav.Link>
    //   </Nav.Item>
    //   <Nav.Item>
    //     <Nav.Link 
    //       target="_blank" rel="noopener noreferrer" 
    //       href="https://101obex.com/privacypolicy">Privacy Policy</Nav.Link>
    //   </Nav.Item>
    // </Nav>
    <div id="footer-dashboard" className='pt-5' style={{ position: 'relative', zIndex: 9999999 }}>


      <div style={{ display: 'flex', height: '180px', zIndex: 9999999999 }}>


        <div onClick={() => { history.push('/') }} className='d-flex flex-column' style={{ cursor: 'pointer', marginLeft: '15%', marginBottom: '50px' }}>
            {/*}  <div className='pb-1'>
                <img src={avap_logo} alt="" width="271px" className='sizeLogo' />
              </div> */}
          <div>
            <img src={iconos_familia} width="271px" alt="" />
          </div>

        </div>

      
        <div style={{ marginLeft: '9.5%', marginTop: '5px' }}>
            <input value={neswleterEmail} 
              disabled={registerin}
              onChange={
              (event) => {
                  setneswleterEmail(event.target.value);
                  validateNewsLetter(event.target.value)
                  }
                  }
                  className='form-control' 
                  style={{
              width: '320px', height: '35px',
              borderColor: `${!props.green || window.location.pathname === '/framework'
                                    || window.location.pathname === '/about'
                                    || window.location.pathname === '/ai'
                                    || window.location.pathname === '/api-publisher'
                                    || window.location.pathname === '/avap'
                                    || window.location.pathname === '/avs'
                                    || window.location.pathname === '/cloud'
                                    || window.location.pathname === '/avap-connectors'
                                    || window.location.pathname === '/avapid'
                                    || window.location.pathname === '/vsc-extensions'
                                    || window.location.pathname === '/avap-marketplace'
                                    || window.location.pathname === '/version-control'
                                    || window.location.pathname === '/devstudio'
                                     

                                    ? '#147bce' : '#316952'}`, 
              borderRadius: '12px',
              borderWidth: '2px'
                    }}>

                    </input>
            </div>
        <div style={{ marginLeft: '20px', marginTop: '5px' }}>
              <button 
            style={{ maxHeight: '36px', overflow: 'hidden', marginRight: '7em' }}
                onClick={
              () => {
                    porcessNewsLetterSuscription()
                  }
                }
                disabled={!validEmail} 
                className={`${!props.green || window.location.pathname === '/framework'


              || window.location.pathname === '/devstudio'
              || window.location.pathname === '/about'
              || window.location.pathname === '/ai'
              || window.location.pathname === '/api-publisher'
              || window.location.pathname === '/avap'
              || window.location.pathname === '/avs'
              || window.location.pathname === '/cloud'
              || window.location.pathname === '/avap-connectors'
              || window.location.pathname === '/avapid'
              || window.location.pathname === '/vsc-extensions'
              || window.location.pathname === '/avap-marketplace'
              || window.location.pathname === '/version-control'


              ? 'custom-button-blue' : 'custom-button-green'} w-150 textBold`}>
            {registerin ? <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} /> : 'Suscribe to newsletter'}
              </button>
            </div>



      </div>





      <div className='newFooter d-flex justify-content-center ' style={{ marginTop: '7rem', width: '100%' }}>








        <div className='d-flex flex-column-reverse  width_row' style={{ width: '100%' }}>
          <div className='d-flex w-textLeft my-3 my-md-0' style={{ width: '85%', marginLeft: '15%', paddingRight: '30%' }}>
            <div className='w-textLeft-items' style={{ marginRight: '0px', width: '27.5%' }}>
              <div className='d-flex flex-column'>
                <div style={{ marginTop: '5em' }} className='text-left text-boldLogin mb-2 titlesText2'>Products & Services</div>
                <div style={{ cursor: 'pointer' }} onClick={() => { history.push('avap') }} className='text2 text-left width_text2 mb-1'>
                AVAP (Advance Virtual API Programming)
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => { history.push('/vsc-extensions') }} className='text2 text-left width_text2 mb-1'>
                AVAP API Extensions for Microsoft Visual Code
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => { history.push('devstudio') }} className='text2 text-left width_text2 mb-1'>
                AVAP Dev Studio
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => { history.push('version-control') }} className='text2 text-left width_text2 mb-1'>
                AVAP API Version Control
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => { history.push('api-publisher') }} className='text2 text-left width_text2 mb-1'>
                AVAP API Publisher
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => { history.push('avap-connectors') }} className='text2 text-left width_text2 mb-1'>
                AVAP Connectors
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => { history.push('avs') }} className='text2 text-left width_text2 mb-1'>
                AVAP Virtual Server
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => { history.push('gateway') }} className='text2 text-left width_text2 mb-1'>
                AVAP Gateway
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => { history.push('cloud') }} className='text2 text-left width_text2 mb-1'>
                AVAP Cloud
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => { history.push('collaborative') }} className='text2 text-left width_text2 mb-1'>
                AVAP Collaborative Network
                </div>
                {/*}
                <div className='d-flex align-items-baseline' >
                  <div className='text2 mt-1'>sales@101obex.com</div>
                </div>

                <div className='d-flex mr-1 marginTopimg' style={{marginTop:'10px'}}>
                    <img src={linkedinblue} alt="" width={"16px"} height={"16px"} />
                    <img style={{marginLeft:'5px'}} src={twitterblue} alt="" width={"16px"} height={"16px"} />
                  </div>
*/}
              </div>

            </div>
            <div className='w-textLeft-items' style={{ width: '22%' }}>
              <div className='d-flex flex-column'>
                <div style={{ marginTop: '5em' }} className='text-left text-boldLogin mb-2 titlesText2'>AVAP ID</div>
                <div style={{ cursor: 'pointer' }} onClick={() => { history.push('/avapid') }} className='text2 text-left width_text2 mb-1'>
                  AVAP ID
                </div>
                <div className='text-left text-boldLogin mb-2 titlesText2'>AI Brunix</div>
                <div style={{ cursor: 'pointer' }} onClick={() => { history.push('/ai') }} className='text2 text-left width_text2 mb-1'>
                  AI Brunix
                </div>
                {/*}
                <div className='text2 text-left width_text2 mb-1'>
                  Executive Leadership
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  News & Stories
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Invertor Relations
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Customer Stories
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Diversity, Equity, Inclusion
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Enviroment, Social & Governance
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  AI at VMware
                </div>
*/}


              </div>
            </div>


            <div className='w-textLeft-items' style={{ width: '22%' }}>
              <div className='d-flex flex-column'>
                <div style={{ marginTop: '5em' }} className='text-left text-boldLogin mb-2 titlesText2'>Resources</div>
                <div style={{ cursor: 'pointer' }} onClick={() => { history.push('/default') }} className='text2 text-left width_text2 mb-1'>
                AVAP Developer Portal
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => { history.push('/avap-marketplace') }} className='text2 text-left width_text2 mb-1'>
                AVAP Marketplace
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US', '_blank') }} className='text2 text-left width_text2 mb-1'>
                AVAP Alexandria
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => { window.open('https://speed-computing-1656.my.site.com/communities/s/', '_blank'), '_blank' }} className='text2 text-left width_text2 mb-1'>
                AVAP Communities
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => { window.open('http://blog.avapframework.com', '_blank'), '_blank' }} className='text2 text-left width_text2 mb-1'>
                AVAP Blog
                </div>
                {/*}
                <div className='text2 text-left width_text2 mb-1'>
                  Office Locations
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  VMware Cloud Trust Center
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  COVID-19 Resources
                </div>

*/}


              </div>
            </div>






            <div className='w-textLeft-items' style={{ width: '22%' }}>
              <div className='d-flex flex-column'>
                <div style={{ marginTop: '5em' }} className='text-left text-boldLogin mb-2 titlesText2'>Company</div>
                <div style={{ cursor: 'pointer' }} onClick={() => { history.push('/about') }} className='text2 text-left width_text2 mb-1'>
                  About us
                </div>
                {/*}
                <div className='text2 text-left width_text2 mb-1'>
                  Support Policies
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Product Documentation
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Compatibility Guide
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Terms & Conditions
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  California Trnasparency Act Statement
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Hands-on Labs
                </div>
*/}

              </div>
            </div>



            <div className='w-textLeft-items' style={{ width: '22%' }}>
              <div className='d-flex flex-column'>
                <div style={{ marginTop: '5em' }} className='text-left text-boldLogin mb-2 titlesText2'>Legal</div>
                <div className='text2 text-left width_text2 mb-1' style={{ cursor: 'pointer' }}
                  onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/avap-collaborative-network-terms-conditions-MCXYCPQ5L53JDSFN4HXYI66J2764?language=en_US') }}
              >

                  Terms & Conditions (AVAP Collaborative Networks)
                </div>
                <div className='text2 text-left width_text2 mb-1' style={{ cursor: 'pointer' }}
                  onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/avap-cloud-professional-business-terms-conditions-MCOYQYTA5VQNDZDAS5UGXSU3GEV4?language=en_US') }}
                >
                  Terms & Conditions (AVAP Cloud)
                </div>
                <div style={{ cursor: 'pointer' }}
                  onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/news/privacy-policy-MCSYZARSDENBDAVITQIIO6OWFYLI?language=en_US') }}
                className='text2 text-left width_text2 mb-1'>
                Privacy Policy
                </div>
                {/*}
                <div className='text2 text-left width_text2 mb-1'>
                  Support Policies
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Product Documentation
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Compatibility Guide
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Terms & Conditions
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  California Trnasparency Act Statement
                </div>
                <div className='text2 text-left width_text2 mb-1'>
                  Hands-on Labs
                </div>
*/}

              </div>
            </div>



          </div>

          <div className=' d-flex justify-content-start justify-content-md-end ' style={{ marginTop: '60px', width: '60%' }}>


          </div>

        </div>
      </div>
      <div style={{ marginLeft: 'auto', width: '50%', marginRight: 'auto', display: 'flex', marginBottom: '30px', marginTop: '30px' }}>

        <div style={{ marginLeft: 'auto', width: '45%', marginRight: '0px' }}>
          <div className='text-boldLogin'>Corporate 1 Head Office</div>

    <div className='d-flex'>
                  <div className='d-flexmarginTopimg'>
              <img style={{ marginBottom: 'auto', marginTop: 'auto', marginRight: '5px' }} src={icono_ubicacion} alt="" width={"10px"} height={"12px"} />
                  </div>
                  <div className='text-left text2 '>101OBEX, CORP 440 N Wolfe Rd 94085 Sunnyvale, CA</div>
                </div>



  </div>
        <div style={{ width: '2px', height: '48px', backgroundColor: '#ebebeb', marginRight: '10px', paddingTop: '10px' }}></div>
        <div style={{ marginLeft: '0px', width: '20%', marginRight: 'auto', justifyContent: 'start' }}>
      <div className='d-flex align-items-baseline' >
                  <div className=' ' >
              <img src={icono_mail} alt="" width={"12px"} height={"9px"} style={{ marginRight: '5px' }} />
                  </div>
                  <div className='text2 mt-1'>sales@101obex.com</div>
                </div>

          <div style={{ display: 'flex' }}>
            <img onClick={() => { window.open('https://www.linkedin.com/company/avap-framework') }} style={{ cursor: 'pointer' }} src={linkedinblue} alt="" width={"16px"} height={"16px"} />
            <img onClick={() => { window.open('https://x.com/AvapFramework') }} style={{ cursor: 'pointer', marginLeft: '20px' }} src={twitterblue} alt="" width={"16px"} height={"16px"} />
            <img onClick={() => { window.open('https://www.youtube.com/@AVAPFramework') }} src={yticon} alt="" style={{ cursor: 'pointer', marginLeft: '20px' }} width={"16px"} height={"16px"} />
            <img onClick={() => { window.open('https://www.reddit.com/user/AVAP_Framework/') }} style={{ cursor: 'pointer', marginLeft: '20px' }} src={redicon} alt="" width={"16px"} height={"16px"} />
            <img onClick={() => { window.open('https://www.facebook.com/AVAPFramework') }} style={{ cursor: 'pointer', marginLeft: '20px' }} src={fbicon} alt="" width={"16px"} height={"16px"} />
      </div>

  </div>
</div>
      <div style={{ marginLeft: 'auto', width: '80%', height: '2px', backgroundColor: '#ebebeb', marginRight: 'auto' }}></div>
      <div className='pt-4 p-3-5' style={{ textAlign: 'center' }}>
        © 2024 101OBEX, CORP, All rights reserved

      </div>
      <>
        <Modal style={{ marginTop: '7rem' }} show={showModal2} onHide={handleClose}>
          <Modal.Body className='colorBlack' style={{ textAlign: 'center' }}>
          <div><h1>Congratulations</h1></div>
          <div>You have been registered in the AVAP Newsletter</div>
          </Modal.Body>
          <Modal.Footer>

            <button className="btn custom-button textBold" onClick={handleClose}>
              Ok
            </button>

          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
}


export default FooterMenuAccess;