import React, { Fragment } from 'react';

const MSVC_Extensions = () => (
  <Fragment>
     <div>
    <h2>Getting Started with AVAP Extensions for MVSC</h2>
    
    <p>
      This document presents all the AVAP extensions for Microsoft Visual Studio Code, which will enable developers to manage every phase and process involved in the development and publication of APIs as part of their lifecycle within the AVAP Framework.
    </p>

    <h3>Installation of Extensions</h3>
    <p>
      It is recommended to install the extensions from the <a href="https://marketplace.visualstudio.com/">Microsoft Visual Studio Code marketplace</a>, although you can also download them manually from the AVAP ID portal and install them in your IDE.
    </p>

    <h3>Available Extensions</h3>
    <ol>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
        <strong>AVAP Linter & Completion</strong>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Purpose:</strong> Provides knowledge of AVAP (Advanced Virtual API Programming) format and syntax within the Microsoft IDE.</li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Features:</strong> Syntax highlighting, code suggestions, and error correction.</li>
        </ul>
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
        <strong>AVAP Main</strong>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Purpose:</strong> Base extension that includes Organization, Projects, and Teams components.</li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Features:</strong> Management of organizations, creation and administration of projects, and configuration of development teams.</li>
        </ul>
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
        <strong>AVAP API Publisher</strong>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Purpose:</strong> Assists developers in the entire API publishing process across the different environments in their deployment.</li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Features:</strong> Publication of APIs in Local, Test, and Live environments with configuration and control options.</li>
        </ul>
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
        <strong>AVAP API Version Control</strong>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Purpose:</strong> A complete version control manager for API development using AVAP coding.</li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Features:</strong> Version control, change tracking, and API version management.</li>
        </ul>
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
        <strong>AVAP Connectors</strong>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Purpose:</strong> Provides a fast and secure mechanism for connecting to databases and external systems.</li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Features:</strong> Configuration and management of connections to databases and external systems.</li>
        </ul>
      </li>
    </ol>

    <h3>Prerequisites</h3>
    <p>
      Before starting to work with the extensions, it is important to understand the credential and identification system known as AVAP ID. All users need to be registered at <a href="https://www.avapid.com/">AVAP ID</a> and have an active account, as well as a developer token to activate the AVAP extensions in Microsoft Visual Studio Code.
    </p>

    <h3>Activation and Use of Extensions</h3>
    <p>
      Once the developer has their developer token, they can install the extensions from the marketplace. The extensions will automatically activate and appear in the left-hand side or extension bar of Visual Studio Code.
    </p>
    <p>
      With AVAP extensions for Microsoft Visual Studio Code, developers can carry out the full lifecycle of an API, from development to testing, publication, and administration.
    </p>

    <p>
      For more information, check out AVAP Alexandria at <a href="https://alexandria.avapframework.com/">alexandria.avapframework.com</a> and join the user community at <a href="https://communities.avapframework.com/">AVAP Communities</a>.
    </p>
  </div>
  </Fragment>
);
export default MSVC_Extensions;
