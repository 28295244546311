import React, { FunctionComponent, useState, useEffect } from 'react';
import { Row, Col, Button, Form, Table, Spinner, Dropdown } from 'react-bootstrap';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import Drawer from './Drawer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faSearch } from '@fortawesome/free-solid-svg-icons';
import SessionService from '../../services/SessionsService';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import orgIcon from '../../assets/img/ui_icons/credentials.png'
import { useHistory } from 'react-router-dom';

type Certificate = {
  id: string;
  name: string;
  issuedby: string;
  issueddate: string;
  expiresdate: string;
  expired: boolean,
  status: string; // 'Valid', 'Expiring Soon', 'Expired'
  type: string;
};

const Certs: FunctionComponent = () => {
  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAddCertDrawerOpen, setIsAddCertDrawerOpen] = useState(false);
  const [securityTypes] = useState(['Self-Signed', 'SSL/TLS', 'Client Certificates']);
  const [securityType, setSecurityType] = useState('Self-Signed')
  const [expires, setExpires] = useState('');
  const [issuer, setIssuer] = useState('');
  const [certName, setCertName ] = useState('');
  const [allCertificates, setAllCertificates] = useState<Certificate[]>([]);
  const [certificate_data, setCertData ] = useState('');
  const [certificate_public, setCertPublic ] = useState('');
  const history = useHistory();
  const handleCertificate = (e) => {
    const { name, value, type, checked } = e.target;
    setCertData(value);
  };

  const handlePublic = (e) => {
    const { name, value, type, checked } = e.target;
    setCertPublic(value);
  };

const handleCertNameChange = (e) => {
  const { name, value, type, checked } = e.target;
  setCertName(value);
};

  const handleIssuer = (e) => {
    const { name, value, type, checked } = e.target;
    setIssuer(value);
  };


  const handleExpires = (e) => {
    const { name, value, type, checked } = e.target;
    setExpires(value);
  };
  
  const handleCertificateChange = (e) => {
    const { name, value, type, checked } = e.target;
    setSecurityType(value);
  };
  // Datos simulados
  const mockCertificates: Certificate[] = [
    {
      id: '1',
      name: 'SSL Certificate A',
      issuedby: 'Let\'s Encrypt',
      issueddate: '2023-01-01',
      expiresdate: '2024-01-01',
      status: 'Valid',
      type: 'Self-Signed',
      expired: false
    },
    {
      id: '2',
      name: 'TLS Certificate B',
      issuedby: 'DigiCert',
      issueddate: '2022-05-01',
      expiresdate: '2023-05-01',
      status: 'Expired',
      type: 'TLS',
      expired: false
    },
    {
      id: '3',
      name: 'API Cert X',
      issuedby: 'AWS',
      issueddate: '2023-06-01',
      expiresdate: '2023-12-31',
      expired: false,
      status: 'Expiring Soon',
       type: 'Client'
    },
  ];

  // Simulación de carga de datos
  // const fetchCertificates = async (filter: string) => {
  //   setLoading(true);

  //   const mockTokensRequst2 = await ObexRequestHandler.get(`/cert/get_certs?organization=${SessionService.ORGANIZATION}`) 

  //   console.log(mockTokensRequst2.data);

  //     const filteredCerts = mockTokensRequst2.data.filter(
  //       (cert) =>
  //         cert.name.toLowerCase().includes(filter.toLowerCase()) ||
  //         cert.id.includes(filter)
  //     );
  //     setCertificates(filter === '' ? mockTokensRequst2.data : filteredCerts);
  //     setLoading(false);

  // };

  const fetchCertificates = async () => { // Eliminamos el parámetro 'filter'
    setLoading(true);
  
    try {
      const response = await ObexRequestHandler.get(`/cert/get_certs?organization=${SessionService.ORGANIZATION}`);
      const data = response.data;
  
      if (!Array.isArray(data)) {
        console.error('Unexpected data format:', data);
        setAllCertificates([]);
        setCertificates([]);
        return;
      }
  
      const transformedCerts: Certificate[] = data.map((cert: any) => ({
        ...cert,
        id: cert.id ? cert.id.toString() : '', // Aseguramos que 'id' sea una cadena
      }));
  
      setAllCertificates(transformedCerts); // Almacena en allCertificates
      setCertificates(transformedCerts); // Muestra todos inicialmente
    } catch (error) {
      console.error('Error fetching certificates:', error);
      setAllCertificates([]);
      setCertificates([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filter.trim() === '') {
      setCertificates(allCertificates);
    } else {
      const filterLower = filter.toLowerCase();
      const filtered = allCertificates.filter((cert) => {
        const nameMatch = cert.name?.toLowerCase().includes(filterLower);
        const idMatch = cert.id?.includes(filterLower);
        const issuerMatch = cert.issuedby?.toLowerCase().includes(filterLower);
        const typeMatch = cert.type?.toLowerCase().includes(filterLower);
        return nameMatch || idMatch || issuerMatch || typeMatch;
      });
      setCertificates(filtered);
    }
  }, [filter, allCertificates]);
  
  
  

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilter(e.target.value);
  };

  const handleRevokeCertificate = async (certId: string) => {
    if (window.confirm('Are you sure you want to revoke this certificate?')) {
      const updatedCertificates = certificates.map((cert) =>
        cert.id === certId ? { ...cert, status: 'Revoked' } : cert
      );
      setCertificates(updatedCertificates);
      

      await ObexRequestHandler.get(`/cert/del_certs?id=${certId}`);

      fetchCertificates();
      history.push('/certs')
    }
  };

  const handleDownloadCertificate = (certificate: Certificate) => {
    const content = `
      Certificate ID: ${certificate.id}
      Name: ${certificate.name}
      Issued By: ${certificate.issuedby}
      Issued Date: ${certificate.issueddate}
      Expiry Date: ${certificate.expiresdate}
      Status: ${certificate.status}
    `;
    const blob = new Blob([content], { type: 'text/plain' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `certificate_${certificate.id}.txt`;
    link.click();
  };

  const handleAddCertificate = (newCert: Certificate) => {
    setCertificates((prevCerts) => [...prevCerts, newCert]);
  };

  useEffect(() => {
    fetchCertificates();
  }, []);

  return (
    <DashLayout sider={DashRouters} active="certificates" sublinks={[]}>
      <Row id="topHeader">
        <Col md="10">
          <div className="d-flex flex-column">
            <div className="cld_wrapperTitle">
            <img style={{ width:'24px' }} src={orgIcon} alt="icon dashboard" />
              <span className="cld_title w-100 margin-y-24px">
              <span style={{ color:'#949498', fontSize:'14px' }}>Credential Manager </span>/ Certs 
                </span>
            </div>
          </div>
        </Col>
        <Col md="2" className="align-content-center">
          <Button
            style={{ width: '225px' }}
            className="customButton-black btn px-4 float-right resBtn"
            onClick={() => {
            
              setSecurityType('Self-Signed')
              setExpires('');
              setIssuer('');
              setCertName('');
            
              setIsAddCertDrawerOpen(true)
            
            }}
          >
            Add Certificate
          </Button>
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>





      <Row id="table" className="mt-5">
        <Col>
          {loading ? (
            <Spinner animation="border" />
          ) : (
            <div className="cld_borderWrapper" style={{height:'auto', overflow:'visible'}}>

              <span className='cld_sectionTitle'>Certs</span>

              <div className="searchContainer">
                <Form.Control
                  id="buscador"
                  required
                  type="text"
                  value={filter}
                  placeholder="Search by Certificate Name or ID"
                  onChange={handleFilterChange}
                />
                <Button
                  className="customButton-transparent px-4"
                  onClick={() => fetchCertificates()}
                >
                  <FontAwesomeIcon icon={faSearch} size="1x" />
                </Button>
              </div>


              <Table style={{overflow:'visible'}} className="cld_tableWrapper" >
                <thead>
                  <tr className='tableRow'>
                    <th style={{ width: '30%', maxWidth:'24%' }} className="textBold">Name</th>
                    <th></th>
                    <th className="textBold">Issued By</th>
                    <th className="textBold">Type</th>
                    <th className="textBold">Issued Date</th>
                    <th className="textBold">Expires</th>
                    <th className="textBold">Status</th>

                    <th className="textBold" style={{ textAlign: 'end' }}>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {certificates.map((cert) => (
                    <tr className='tableRow' key={cert.id}>
                      <td className='td_centered'>{cert.name}</td>
                      <td></td>
                      <td className='td_centered'>{cert.issuedby}</td>
                      <td className='td_centered'>{cert.type}</td>
                      <td className='td_centered'>{cert.issueddate.split('T')[0]}</td>
                      <td className='td_centered'>{cert.expiresdate.split('T')[0]}</td>
                      <td className='td_centered'>{cert.status === undefined ? (cert.expired?'Expired':'Active'):(cert.status ? 'Active':'Inactive')}</td>

                      <td>
                        {/*}
                        <Button
                          className="customButton-blue"
                          onClick={() => handleDownloadCertificate(cert)}
                          style={{ marginRight: '8px' }}
                        >
                          Download
                        </Button>
                        {cert.status !== 'Revoked' && (
                          <Button
                            className="customButton-red"
                            onClick={() => handleRevokeCertificate(cert.id)}
                          >
                            Revoke
                          </Button>
                        )}
                          */}

                        <div className="actionsWrapper">

                          <Dropdown className='my-auto' style={{ marginRight: '0px' }}>
                            <Dropdown.Toggle className="organizations" >
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {/*<Dropdown.Item key={1} onClick={() => { handleDownloadCertificate(cert) }}>Download</Dropdown.Item>*/}
                              {(cert.status !== 'Revoked') && <Dropdown.Item key={1} onClick={() => handleRevokeCertificate(cert.id)}>Revoke</Dropdown.Item>}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>


                      </td>
                    </tr>
                  ))}
                  {/*}
                  {certificates.length < 3 &&
                    <div style={{ height:'65px' }}></div>
                  } */}
                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>

      {/* Drawer for Adding Certificate */}
      <Drawer
        isOpen={isAddCertDrawerOpen}
        toggleSidebar={() => setIsAddCertDrawerOpen(!isAddCertDrawerOpen)}
        name="Add Certificate"
      >
        <div>
          {/* <h3>Add a New Certificate</h3> */}
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              /*
              handleAddCertificate({
                id: `${certificates.length + 1}`,
                name: 'New Certificate',
                issuedBy: 'Custom CA',
                issuedDate: new Date().toISOString().split('T')[0],
                expiresDate: '2025-12-31',
                status: 'Valid',
                type:'Self-Signed'
              });*/

              const expiration = expires;
              const type = securityType;
              const org_id = SessionService.ORGANIZATION;
              const certificate_name = certName;
              const public_key = certificate_public;
              const payload = {
                issuer,
                expiration,
                type,
                org_id,
                certificate_name,
                certificate_data,
                public_key
              }
              console.log(payload)

              await ObexRequestHandler.post('/cert/add_certs', payload)
              setIsAddCertDrawerOpen(false);


              fetchCertificates();


            }}
          >


            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter certificate name" 
              value={certName}
              onChange={handleCertNameChange}/>
            </Form.Group>


            <Form.Group>
              <Form.Label>Token Type</Form.Label>
              <Form.Control
                as="select"
                name="securityType"
                value={securityType}
                onChange={handleCertificateChange}
              >
                <option value="">Select a Token type</option>
                {securityTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Issued By</Form.Label>
              <Form.Control type="text" placeholder="Enter issuer" 
                value={issuer}
                onChange={handleIssuer}
              />
            </Form.Group>


            <Form.Group controlId="expirationDate" className="mb-3">
              <Form.Label>Expiration Date</Form.Label>
              <Form.Control
                type="date"
                name="expirationDate"
                value={expires || ''} 
                onChange={handleExpires}
              />
            </Form.Group>

{(securityType == 'Client Certificates'  || securityType == 'SSL/TLS') &&
            <><Form.Group>
                <Form.Label>Certificate</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Paste the certificate here"
                  rows={10} // Ajusta el número de filas para que el editor sea más grande
                  value={certificate_data || ''} 
                  onChange={handleCertificate}
                />
              </Form.Group>
              <Form.Group>
                  <Form.Label>Public Key</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Paste the public key here"
                    rows={5} // Ajusta el número de filas para que el editor sea más grande
                    value={certificate_public || ''} 
                    onChange={handlePublic}
                  />
                </Form.Group></>

}
            <Row>
              <Col md='6'>
                <Button variant="primary" className="w-100" type="submit" >
                  Submit
                </Button>
              </Col>
            </Row>


          </Form>
        </div>
      </Drawer>
    </DashLayout>
  );
};

export default Certs;
