import React, { Fragment } from 'react';
import { Typography } from 'antd';
import { CodeOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const SelectSample = () => (
  <Fragment>
 
    <Typography>
      <Title level={1}>Select from a Database</Title>
      <Paragraph>
        This example demonstrates how to retrieve a currency identifier and its name using a country code as a reference.
      </Paragraph>

      <Title level={2}>Currency Retrieval by Country Code</Title>
      <Paragraph>
        This sample code queries the <code>countries</code> and <code>currencies</code> tables to retrieve the currency identifier associated with a specific country. It starts by using the <code>country_code</code> parameter from the call’s inputs to filter the query on the <code>countries</code> table. Then, it extracts the corresponding currency identifier and performs a second query to obtain the currency name, which is returned as the result.
      </Paragraph>

      <Title level={3}>Inputs:</Title>
      <ul>
        <li><strong>country_code</strong>: The country code, automatically collected from the call parameters.</li>
      </ul>

      <Title level={3}>Steps:</Title>
      <ol>
        <li>
          <strong>addParam(country_code, country_code)</strong>
          <Paragraph>
            The <code>country_code</code> parameter is retrieved from the call parameters and prepared for use in the query.
          </Paragraph>
        </li>
        <li>
          <strong>ormAccessSelect('currency_id', countries, `active=true and code='${'{country_code}'}'`, result)</strong>
          <Paragraph>
            A query is run on the <code>countries</code> table to select the <code>currency_id</code> field, which represents the currency associated with the country. This query is filtered to include only active countries (<code>active=true</code>) and is further refined by the <code>country_code</code> parameter collected earlier.
          </Paragraph>
        </li>
        <li>
          <strong>itemFromList(result, 0, 'currency_id')</strong>
          <Paragraph>
            The first item from the query result (<code>result</code>) is retrieved, with the <code>currency_id</code> field value being stored in the <code>currency_id</code> variable.
          </Paragraph>
        </li>
        <li>
          <strong>variableFromJSON(currency_id, 'currency_id', currency)</strong>
          <Paragraph>
            The JSON result in <code>currency_id</code> is parsed to extract the value associated with the <code>currency_id</code> field, which is then stored in the <code>currency</code> variable.
          </Paragraph>
        </li>
        <li>
          <strong>addResult(currency)</strong>
          <Paragraph>
            The value of <code>currency</code>—which represents the identifier of the currency associated with the country—is returned.
          </Paragraph>
        </li>
        <li>
          <strong>ormAccessSelect('name', currencies, `id=${'{currency}'}'`, currency_name)</strong>
          <Paragraph>
            A second query is executed on the <code>currencies</code> table to retrieve the <code>name</code> field of the currency that matches the <code>currency</code> ID.
          </Paragraph>
        </li>
        <li>
          <strong>addResult(currency_name)</strong>
          <Paragraph>
            The name of the currency obtained from the query is returned.
          </Paragraph>
        </li>
      </ol>

      <Title level={3}>Result:</Title>
      <Paragraph>
        The result is a JSON object in the following format:
      </Paragraph>

      <div style={{ backgroundColor: '#f5f5f5', padding: '16px', borderRadius: '8px' }}>
        <pre>
          <code>
            {`{
  "status": true,
  "elapsed": 0.3341941833496094,
  "result": {
    "currency": 70,
    "currency_name": [
      {
        "name": "Mexican Peso"
      }
    ]
  }
}`}
          </code>
        </pre>
      </div>

      <Title level={3}>Usage Example:</Title>
      <div style={{ backgroundColor: '#f5f5f5', padding: '16px', borderRadius: '8px' }}>
        <pre>
          <code>
            {`addParam(country_code, country_code)
  ormAccessSelect('currency_id', countries, \`active=true and code='${'{country_code}'}'\`, result)
  itemFromList(result, 0, currency_id)
  variableFromJSON(currency_id, 'currency_id', currency)
  addResult(currency)
  ormAccessSelect('name', currencies, \`id=${'{currency}'}'\`, currency_name)
addResult(currency_name)`}
          </code>
        </pre>
      </div>

      <Title level={3}>Notes:</Title>
      <Paragraph>
        This flow requires that the associated country has a registered currency in the <code>countries</code> table, and the <code>currencies</code> table must have an entry that corresponds to the currency's <code>id</code>.
      </Paragraph>
    </Typography>
  </Fragment>
);
export default SelectSample;
