import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './components/sidebar';
import Topbar from './components/topbar';

import chevron from '../../assets/img/ui_icons/chevron.svg';
import calendar from '../../assets/img/ui_icons/cld_calendar.svg';
import placeholder from '../../assets/img/ui_icons/cld_placeholder.svg';
import manageApi from '../../assets/img/ui_icons/cld_apiManage.svg';
import Storage from '../../assets/img/ui_icons/cld_storage.svg';

//widgets
import ApiProjects from './widgets/ApiProject';
import Request from './widgets/request';
import Team from './widgets/team';
import Developers from './widgets/Developers';
import Learning from './widgets/Learning';
import SessionService from '../../services/SessionsService';

function Dashboard() {
    // Leer el valor inicial desde localStorage o usar `true` para modo oscuro por defecto
    const initialTheme = localStorage.getItem('darkMode') === 'true' || localStorage.getItem('darkMode') === null;
    const [isCollapsed, setIsCollapsed] = useState(SessionService.ISCOLLAPSED);
    const [isMobileOpen, setIsMobileOpen] = useState(false);
    const [isDarkMode, setIsDarkMode] = useState(initialTheme);

    useEffect(() => {
        // Seleccionar el elemento #dashboardWrapper y actualizar la clase dark-mode
        const dashboardWrapper = document.getElementById('dashboardWrapper');
        if (dashboardWrapper) {
            if (isDarkMode) {
                dashboardWrapper.classList.add('dark-mode');
            } else {
                dashboardWrapper.classList.remove('dark-mode');
            }
        }
    }, [isDarkMode]);

    const toggleSidebar = () => {
        SessionService.ISCOLLAPSED = !isCollapsed;
        setIsCollapsed(!isCollapsed);
    };

    const toggleMobileSidebar = () => {
        setIsMobileOpen(!isMobileOpen);
    };

    const toggleTheme = () => {
        setIsDarkMode((prevMode) => {
            const newMode = !prevMode;
            localStorage.setItem('darkMode', String(newMode)); // Guardar el estado en localStorage
            return newMode;
        });
    };


    // Estado para controlar la visibilidad del menú y el valor seleccionado
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Select API Project");

    // Opciones del menú
    const options = ["API 1", "API 2", "API 3"];

    // Función para manejar la selección de una opción
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsOpen(false); // Cerrar el menú después de seleccionar
    };

    return (
        <div className="d-flex dark-mode" id="dashboardWrapper">
            {/* Sidebar */}
            <Sidebar isCollapsed={isCollapsed} isMobileOpen={isMobileOpen} />

            {/* Page Content */}
            <div id="page-content-wrapper" className="w-100">
                <Topbar
                    toggleSidebar={toggleSidebar}
                    toggleMobileSidebar={toggleMobileSidebar}
                    toggleTheme={toggleTheme}
                    isCollapsed={isCollapsed}
                    isDarkMode={isDarkMode}
                />
                <div id='content' className="container-fluid mt-0">
                    <div className="row">
                        <div className="col-12">
                            <h1 className='title margin-y-24px'><img src="" alt="" /><span>API Projects</span></h1>
                            <div className='customBorder' />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-8 col-md-6">
                            <h1 className='subtitle margin-y-24px'><img src="" alt="" /><span>Name API Project</span></h1>
                        </div>
                        <div className="col-lg-4 col-md-6 d-flex align-items-center justify-content-end">
                            <a className='cld-primary-button mr-3 mt-10px' href="">
                                Add New
                            </a>
                            <div className="customSelectCalendar" onClick={() => setIsOpen(!isOpen)}>
                                <span className="icon">
                                    <img src={calendar} alt="" />
                                </span>
                                <span className="text">{selectedOption}</span>
                                <span className="arrow"><img src={chevron} alt="" /></span>

                                {isOpen && (
                                    <div className="options">
                                        {options.map((option, index) => (
                                            <div
                                                key={index}
                                                className="option"
                                                onClick={() => handleOptionClick(option)}
                                            >
                                                {option}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>


                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-6 col-lg-4">
                            <div className="cld-card">
                                <span className="card-title">
                                    Name API Project
                                </span>

                                <div className="customBorder"></div>

                                <div className="wrapperContent">
                                    <div className="contentLeft">
                                        <p className='txt-bold'>Project ID</p>
                                        <p>LcbQMCLVRm</p>
                                    </div>
                                    <div className="contentRight">
                                        <p className='txt-bold'>Total Request</p>
                                        <p>1054 in use of <br /> 10,000</p>
                                    </div>
                                </div>

                                <div className="bottom">
                                    00.00% more than last month
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 col-lg-8">

                            <div className="cld-card d-flex align-items-center flex-wrap">
                                <div className='m-auto d-block'>
                                    <p className='text-bold text-center w-100 d-'>You don’t have data to show</p>
                                    <p className='text-center w-100'>When you have data to show, you’ll find here.</p>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Dashboard;
