import React from 'react';
import './chapter.css'
const Chapter9_21 = () => (
    <div>
        <h2>Calls</h2>
        <p>
            A call invokes a callable object (e.g., a function) with a possibly empty series of arguments:
        </p>
        <pre>
            <code>
{`call                 ::=  primary "(" [argument_list [","] | comprehension] ")"
argument_list        ::=  positional_arguments ["," starred_and_keywords]
                          ["," keywords_arguments]
                        | starred_and_keywords ["," keywords_arguments]
                        | keywords_arguments
positional_arguments ::=  positional_item ("," positional_item)*
positional_item      ::=  assignment_expression | "*" expression
starred_and_keywords ::=  ("*" expression | keyword_item)
                          ("," "*" expression | "," keyword_item)*
keywords_arguments   ::=  (keyword_item | "**" expression)
                          ("," keyword_item | "," "**" expression)*
keyword_item         ::=  identifier "=" expression
`}
            </code>
        </pre>
        <p>
            An optional trailing comma may be present after positional and keyword arguments but does not affect the semantics.
        </p>
        <p>
            The primary must evaluate to a callable object (user-defined functions, built-in functions, built-in object methods, class objects, class instance methods, and any object with a <code>__call__()</code> method are callable). All argument expressions are evaluated before attempting the call. Please refer to the Function Definitions section for the syntax of formal parameter lists.
        </p>
        <p>
            If keyword arguments are present, they are first converted into positional arguments as follows. First, a list of unfilled slots is created for the formal parameters. If there are N positional arguments, they are placed in the first N slots. Then, for each keyword argument, the identifier is used to determine the corresponding slot. If the slot is already filled, a <code>TypeError</code> exception is raised. Otherwise, the argument is placed in the slot, filling it (even if the expression is <code>None</code>, it fills the slot). When all arguments have been processed, any slots that are still empty are filled with the default value from the function definition. If there are unfilled slots for which no default value is specified, a <code>TypeError</code> exception is raised. Otherwise, the list of filled slots is used as the argument list for the call.
        </p>
    </div>
);

export default Chapter9_21;
