import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { Table } from 'react-bootstrap';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/Disclaimer';

type ApiStatusProps = {};

const ApiStatus: FunctionComponent<ApiStatusProps> = () => {
  const [apiProducts, setApiProducts] = useState([]);
  const [selectedApiProduct, setSelectedApiProduct] = useState<string | null>(null);
  const [apiServices, setApiServices] = useState([]);
  const [lastUnitTestResults, setLastUnitTestResults] = useState<{ [key: string]: string | boolean }>({});

  const getApiProducts = async () => {
    try {
      const result = (await ObexRequestHandler.get('/api_products')).data;
      setApiProducts(result);
    } catch (error) {
      console.error('Error al obtener la lista de API Products:', error);
    }
  };

  const getApiServices = async (apiProduct) => {
    try {
      const result = (await ObexRequestHandler.get(`/api_services/${apiProduct}`)).data;
      setApiServices(result);
    } catch (error) {
      console.error('Error al obtener la lista de API Services:', error);
    }
  };

  const getApiResult = async (apiProduct, apiService) => {
    try {
      const payload = {
        api_product: apiProduct,
        api_service: apiService,
        quantity:1
      };
      const result = (await ObexRequestHandler.post('/api_unittest_results', payload)).data;

      let lastUnitTestResult: string | boolean = 'No Test Result';

      if (result.length > 0) {
        const lastTest = result[result.length - 1];

        if (lastTest.output && lastTest.output.length > 0) {
          const lastOutput = lastTest.output[lastTest.output.length - 1];

          if (lastOutput.output && lastOutput.output.status !== undefined) {
            lastUnitTestResult = lastOutput.output.status ? 'Success' : 'Failure';
          } else if (lastOutput.error !== undefined) {
            lastUnitTestResult = 'Error';
          } else if (lastOutput.skip !== undefined) {
            lastUnitTestResult = 'Skip';
          }
        } else if (lastTest.error !== undefined) {
          lastUnitTestResult = 'Error';
        } else if (lastTest.skip !== undefined) {
          lastUnitTestResult = 'Skip';
        }
      }else{
        console.log('complete')

        const payload_aux={
          api_product: apiProduct,
          api_service: 'complete',
          quantity:1
        };
        const result_aux= (await ObexRequestHandler.post('/api_unittest_results', payload_aux)).data[0].output;
        if (result_aux.length > 0) {

          for (let index = 0; index < result_aux.length; index++) {
            const testOutput = result_aux[index];
            if (testOutput.unittest_name && testOutput.unittest_name==apiService) {
              if (testOutput.output) {    
                if (testOutput.output && testOutput.output.status !== undefined) {
                  lastUnitTestResult = testOutput.output.status ? 'Success' : 'Failure';
                } else if (testOutput.error !== undefined) {
                  lastUnitTestResult = 'Error';
                } else if (testOutput.skip !== undefined) {
                  lastUnitTestResult = 'Skip';
                }
              } else if (testOutput.error !== undefined) {
                lastUnitTestResult = 'Error';
              } else if (testOutput.skip !== undefined) {
                lastUnitTestResult = 'Skip';
              }
              break;
            } else {
              continue;
            }
            
          }
          
      }
    }
      setLastUnitTestResults((prevResults) => ({
        ...prevResults,
        [apiService]: lastUnitTestResult,
      }));

      // Imprimir el status del último output en la consola
      console.log(`Last UnitTest Result for ${apiService}:`, lastUnitTestResult);

      return lastUnitTestResult;
    } catch (error) {
      console.error('Error fetching API results:', error);
      return 'No Test Result';
    }
  };

  const handleApiProductClick = async (apiProduct) => {
    setSelectedApiProduct(apiProduct);

    try {
      const apiServicesResult = await ObexRequestHandler.get(`/api_services/${apiProduct}`);
      if (apiProduct=='DynamicApi'){
        const promises = apiServicesResult.data.map((service) => getApiResult(apiProduct, service.id_service));
        const apiResults = await Promise.all(promises);

        setApiServices(apiServicesResult.data);

        const resultsMap = {};
        apiServicesResult.data.forEach((service, index) => {
          resultsMap[service.id_service] = apiResults[index];
        });

      setLastUnitTestResults(resultsMap);
      }else{
        const promises = apiServicesResult.data.map((service) => getApiResult(apiProduct, service.api_service));
        const apiResults = await Promise.all(promises);

      setApiServices(apiServicesResult.data);

      const resultsMap = {};
      apiServicesResult.data.forEach((service, index) => {
        resultsMap[service.api_service] = apiResults[index];
      });

      setLastUnitTestResults(resultsMap);
      }
      
    } catch (error) {
      console.error('Error al obtener la lista de API Services o el último resultado de prueba:', error);
    }
  };

  useEffect(() => {
    getApiProducts();
  }, []);

  return (
    <>
      <BrowserView>
    <DashLayout sider={DashRouters} active="developers">
      <h3>API Products</h3>
      {apiProducts.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
               <th className='textBold'>API Product</th>
            </tr>
          </thead>
          <tbody>
            {apiProducts.map((product, index) => (
              <tr
                key={index}
                onClick={() => handleApiProductClick(product.api_product)}
                className={selectedApiProduct === product.api_product ? 'selected-row' : ''}
              >
                <td>{product.api_product}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      {selectedApiProduct && (
        <div>
          <div style={{ float: 'left', marginRight: '20px' }}>
            <h3>API Services for {selectedApiProduct}</h3>
            {apiServices.length > 0 && (
              <Table striped bordered hover>
                <thead>
                  <tr>
                     <th className='textBold'>API Service</th>
                     <th className='textBold'>Test Status</th>
                  </tr>
                </thead>
                <tbody>
                  {apiServices.map((service, index) => {
                    if (selectedApiProduct=='DynamicApi') {
                      return <tr key={index}>
                        <td>{service.id_service}</td>
                        <td>{lastUnitTestResults[service.id_service]}</td>
                      </tr>
                    }else{
                      return <tr key={index}>
                        <td>{service.api_service}</td>
                        <td>{lastUnitTestResults[service.api_service]}</td>
                      </tr>
                    }
                    
                  })}
                </tbody>
              </Table>
            )}
          </div>

          <div style={{ clear: 'both' }}></div>
        </div>
      )}
    </DashLayout>
    </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  );
};

export default ApiStatus;
