import React from 'react';
import './chapter.css'
const Chapter9_9 = () => (
    <div>
        <h2>5.3. Advanced Example</h2>
        <p>Consider the following example where multiple files are imported:</p>
        <pre>
            <code>
{`Content of the file main.avap
addVar(5, a) 
import utilities.avap 
import operations.avap

addVar(utilities.increment(a), b)
addVar(operations.multiply(b, 2), c)
print(c)

Content of the file utilities.avap
def increment(x): 
    return x + 1

Content of the file operations.avap
def multiply(x, y): 
    return x * y
`}
            </code>
        </pre>
        <p>
            In this example, <code>utilities.avap</code> and <code>operations.avap</code> are imported into <code>main.avap</code> at the specified points, allowing the <code>increment</code> and <code>multiply</code> functions to be used in <code>main.avap</code>.
        </p>
    </div>
);

export default Chapter9_9;
