import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_23 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>ormAI()</h2>
      <p>
        The <code>ormAI()</code> command uses an artificial intelligence model to convert a natural language query into an SQL statement, which is then executed against a database. This command processes a natural language query to generate an SQL statement that is executed on the table specified in the <code>source</code> parameter, and stores the result in a target variable.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>prompt</strong> <br />
          Type: <code>variable</code> <br />
          Description: A string in natural language that describes the query to be made. For example, "get the value of the row with id 5".
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>source</strong> <br />
          Type: <code>variable</code> <br />
          Description: The name of the table on which the generated query should be executed. It must be a variable containing the name of the table in the database.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>TargetVariable</strong> <br />
          Type: <code>variable</code> <br />
          Description: The variable in which the result of the query will be stored. It must be a variable that will receive the result of the generated and executed SQL query.
        </li>
      </ul>
      <h3>Command Flow</h3>
      <ol>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Generating SQL Query:</strong> Use the artificial intelligence model to convert the <code>prompt</code> into an SQL statement. For example, if the prompt is "get the value of the row with id 5", the AI will generate the SQL query <code>SELECT * FROM source WHERE id = 5;</code>.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Executing the Query:</strong> Execute the generated SQL statement on the table specified in <code>source</code>.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Storing the Result:</strong> Save the result of the query execution in the variable specified by <code>TargetVariable</code>. The result will be the dataset retrieved by the executed SQL statement.</li>
      </ol>
      <h3>Example Usage</h3>
      <p>Suppose you want to retrieve all the data from the row with id equal to 5 from a table called <code>users</code>.</p>
      <pre>
        <code>
          {`// Define variables
prompt = "get the value of the row with id 5"
source = "users"
queryResult = ''

// Call the command to process the query
ormAI(prompt, source, queryResult)

// Return the query result via addResult
addResult(queryResult)`}
        </code>
      </pre>
      <p>In this example, the <code>ormAI()</code> command will convert the <code>prompt</code> into an SQL query: <code>SELECT * FROM users WHERE id = 5;</code>. This query will be executed on the <code>users</code> table, and the results will be stored in the <code>queryResult</code> variable. The <code>queryResult</code> variable will be returned via <code>addResult(queryResult)</code>. The output will be the dataset retrieved by the executed SQL statement.</p>
    </div>
  </Fragment>
);

export default Appendices_23;
