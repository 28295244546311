import React from 'react';
import './chapter.css'
const Chapter9_8 = () => (
    <div>
        <h2>5.2. Limitations and Considerations</h2>
        <ol>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <strong>No Packages:</strong> Unlike other languages, AVAP does not have a hierarchical package system. Each file is imported independently and treated as an autonomous unit.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <strong>Sequential Execution:</strong> Execution in AVAP is sequential and does not allow lazy or deferred execution. Therefore, all functions and variables must be defined before use, and the content of imported files must be in the correct order.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <strong>No Conditional Import:</strong> The <code>import</code> statement in AVAP does not support conditions. The specified file will always be imported at the point of the statement, regardless of any conditions.
            </li>
        </ol>
    </div>
);

export default Chapter9_8;
