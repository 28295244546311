import React, { Fragment } from 'react';

const AVAP_ID = () => (
  <Fragment>
        <div className="body-md">
      <h1>Getting Started with AVAP ID</h1>
      <h2>A Single Credential to Manage Your API Universe</h2>
      <p>
        AVAP ID is the single credential that will grant you access to manage all elements within the AVAP Framework platform.
      </p>
      <p>
        AVAP ID represents the unique account identifier (username and password), regardless of the type of product or service being consumed, whether it is free or paid, based on a cloud or on-premises model.
      </p>
      <p>
        You can obtain your AVAP ID at <a href="http://www.avapid.com/" target="_blank" rel="noopener noreferrer">www.avapid.com</a>.
      </p>

      <br />
      <div style={{ textAlign: 'center' }}>
        <img
          src="https://developer.101obex.com/static_images/GettingStarted_AVAPID_Figure1.1.png"
          alt="Figure 1.1 avap id registration screen"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
        <small>*Figure 1.1 AVAP ID registration screen*</small>
      </div>
      <br />

      <p>
        Your AVAP ID is unique, and while it is typically identified through a username and password, in some cases, it may be necessary to use the credential certificate file, which users can download from the Sign-in & Security - Credentials section.
      </p>

      <br />
      <div style={{ textAlign: 'center' }}>
        <img
          src="https://developer.101obex.com/static_images/GettingStarted_AVAPID_Figure1.2.png"
          alt="Figure 1.2 AVAP ID Registration Screen"
          style={{ maxWidth: '100%', height: 'auto' }}
        />
        <small>Figure 1.2 AVAP ID Registration Screen</small>
      </div>
      <br />

      <p>
        Both the username, password, and the credential certificate file are personal and non-transferable.
      </p>
      <p>
        Your AVAP ID represents and is tied to a single license, and a license, in turn, contains the complete configuration for accessing products, services, and usage in the AVAP Framework. AVAP ID is the single credential that will enable you to fully manage your API universe.
      </p>
      <p>
        The AVAP ID username corresponds to an email address, and based on the argument above, an email cannot be duplicated in the system. Once an email is registered in the system, it cannot be deleted, although it can be deactivated and rendered inactive on the platform.
      </p>

      <p>
        Any operation within AVAP Framework requires the presence of an AVAP ID credential, whether for operating a paid product or a free product.
      </p>

      <p>
        To obtain your AVAP ID, please visit <a href="http://www.avapid.com/" target="_blank" rel="noopener noreferrer">www.avapid.com</a> and follow the registration instructions. As the final step of the registration process, the new user must verify their email account via the link received in the email with the subject: Verify your AVAP ID.
      </p>

      <h3>Your AVAP ID credential primarily manages two elements:</h3>
      <ul>
        <li className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
          Services, products, and available/contracted usage.
        </li>
        <li className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
          Computers linked to each product or service.
        </li>
      </ul>

      <p>
        When a product or service is first set up (primarily in on-premises installations), it is necessary to always have the credential file, which at that moment will check the availability of licenses for the product or service being set up. If licenses are available, called slots, the license is activated and linked to the computer on which it is being set up, consuming one license or slot of the product or service being operated.
      </p>

      <p>
        Through the AVAP ID portal, identified with your username and password, you can:
      </p>
      <ul>
        <li className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
          Manage account contact details.
        </li>
        <li className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
          Subscribe to specific products and services.
        </li>
        <li className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
          Request demos and quotes for certain products.
        </li>
        <li className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
          Manage license slots.
        </li>
        <li className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
          Manage purchase orders.
        </li>
        <li className="li-md" style={{ fontSize: '1.2em', paddingLeft: '10px', marginLeft: '25px' }}>
          Download software.
        </li>
      </ul>

      <p>
        You can find more information related to AVAP ID at AVAP Alexandria (
        <a href="http://alexandria.avapframework.com/" target="_blank" rel="noopener noreferrer">
          alexandria.avapframework.com
        </a>
        ) and connect with the user community at AVAP Communities (
        <a href="http://communities.avapframework.com/" target="_blank" rel="noopener noreferrer">
          communities.avapframework.com
        </a>
        ).
      </p>
    </div>
  </Fragment>
);
export default AVAP_ID;
