import React, { Fragment } from 'react';
import { Typography } from 'antd';
import { CodeOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const LoginSample = () => (
  <Fragment>
            <Typography>
    <Title level={1}>User Credential Validation</Title>
    <Paragraph>
      This example illustrates a process for validating user credentials. A query is performed on the database to check if the user exists and is active, the user’s hashed password is retrieved, and it is compared with an encoded password provided in the request. Depending on the match, a success or error message is returned.
    </Paragraph>

    <Title level={2}>Inputs:</Title>
    <ul>
      <li><strong>user</strong>: Username obtained from the request.</li>
      <li><strong>password</strong>: Password provided in the request.</li>
    </ul>

    <Title level={2}>Steps:</Title>
    <ol>
      <li>
        <strong>addParam(user, user)</strong>
        <Paragraph>The `user` query parameter is retrieved and assigned to the local variable `user`.</Paragraph>
      </li>
      <li>
        <strong>addParam(password, password)</strong>
        <Paragraph>The `password` query parameter is retrieved and assigned to the local variable `password`.</Paragraph>
      </li>
      <li>
        <strong>ormAccessSelect(*, users, `user = ${'{user}'} and active = true`, query_result)</strong>
        <Paragraph>A query is performed on the `users` table to retrieve any active row where the `user` column matches the value of the `user` query parameter. The result is stored in `query_result`.</Paragraph>
      </li>
      <li>
        <strong>getListLen(query_result, concordances)</strong>
        <Paragraph>The size of the result list obtained from the query is calculated.</Paragraph>
      </li>
      <li>
        <strong>if (concordances, 0, '&gt;')</strong>
        <Paragraph>The result is checked to contain at least one entry.</Paragraph>
      </li>
      <li>
        <strong>itemFromList(concordances, 0, user_data)</strong>
        <Paragraph>The first item from the result list is retrieved, and the user information is stored in `user_data`.</Paragraph>
      </li>
      <li>
        <strong>variableFromJSON(user_data, 'id', user_id)</strong>
        <Paragraph>The `id` field is extracted from the `user_data` object and stored in the `user_id` variable.</Paragraph>
      </li>
      <li>
        <strong>ormAccessSelect(*, passwords, `userid = ${'{user_id}'}'`, ciphered_password)</strong>
        <Paragraph>A query is performed on the `passwords` table to retrieve the hashed password of the user associated with the `user_id`.</Paragraph>
      </li>
      <li>
        <strong>encodeSHA256(password, proposed_password)</strong>
        <Paragraph>The provided `password` is encoded using the SHA256 algorithm and stored in `proposed_password`.</Paragraph>
      </li>
      <li>
        <strong>if (proposed_password, ciphered_password, '=')</strong>
        <Paragraph>If the stored hashed password and the provided encoded password match, a success message is returned.</Paragraph>
      </li>
      <li>
        <strong>addResult(`Login success for user ${'{user}'}`)</strong>
        <Paragraph>If the passwords match, the message `Login success for user *user*` is returned.</Paragraph>
      </li>
      <li>
        <strong>addResult(`Incorrect password for user ${'{user}'}`)</strong>
        <Paragraph>If the passwords do not match, the message `Incorrect password for user *user*` is returned.</Paragraph>
      </li>
      <li>
        <strong>addResult(`User ${'{user}'} not found`)</strong>
        <Paragraph>If no active user was found in the database, the message `User *user* not found` is returned.</Paragraph>
      </li>
    </ol>

    <Title level={2}>Result:</Title>
    <Paragraph>
      Based on the validation checks, the system returns one of the following messages:
      <ul>
        <li>`Login success for user user`: If the credentials are correct.</li>
        <li>`Incorrect password for user user`: If the password is incorrect.</li>
        <li>`User user not found`: If the user does not exist or is not active.</li>
      </ul>
    </Paragraph>

    <Title level={2}>Usage Example:</Title>
    <div style={{ backgroundColor: '#f5f5f5', padding: '16px', borderRadius: '8px' }}>
      <pre>
        <code>
{`// Retrieve from querystring and assign to local variables
addParam(user, user)
addParam(password, password)

// Query the 'users' table for any active user matching the provided username
ormAccessSelect(*, users, 'user = \${user} and active = true', query_result)

// Check if result is not empty
getListLen(query_result, concordances)

// If a result is found
if (concordances, 0, '>') {
   itemFromList(concordances, 0, user_data)
   variableFromJSON(user_data, 'id', user_id)

   // Query 'passwords' table for the user's ciphered password
   ormAccessSelect(*, passwords, 'userid = \${user_id}', ciphered_password)

   // Encode the provided password
   encodeSHA256(password, proposed_password)

   // Check if the encoded password matches the stored password
   if (proposed_password, ciphered_password, '=') {
       addResult('Login success for user \${user}')
   } else {
       addResult('Incorrect password for user \${user}')
   }
} else {
   addResult('User \${user} not found')
}`}
        </code>
      </pre>
    </div>

    <Title level={2}>Notes:</Title>
    <Paragraph>
      This code assumes that the passwords stored in the database are hashed using SHA256. The password provided by the user is also encoded using the same algorithm before comparison.
    </Paragraph>
  </Typography>
  </Fragment>
);
export default LoginSample;
