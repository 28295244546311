import React from 'react';
import './chapter.css'
const Chapter9_42 = () => (
    <div>
        <h2>OR Patterns</h2>
        <p>
            An OR pattern in AVAP allows you to specify multiple patterns separated by vertical bars (<code>|</code>). The OR pattern attempts to match each of its subpatterns with the subject value in order. If any of the subpatterns match, the OR pattern is considered successful. If none of the subpatterns match, the OR pattern fails.
        </p>
        <pre><code>or_pattern ::= "|".closed_pattern+</code></pre>
        <p>
            Here's how you can use OR patterns in practice:
        </p>
        <pre><code>match value:
    case 1 | 2 | 3:
        # Code to execute if value is 1, 2, or 3
    case "hello" | "world":
        # Code to execute if value is "hello" or "world"
    case _:
        # Code to execute if value does not match any of the above</code></pre>
        <p>
            In this example:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>The first case will match if <code>value</code> is either 1, 2, or 3.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>The second case will match if <code>value</code> is either "hello" or "world".</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>The last case is a catch-all pattern that will execute if none of the previous patterns match.</li>
        </ul>
        <p>
            OR patterns provide a concise way to handle multiple possible values or types, simplifying pattern matching and making your code more readable.
        </p>
    </div>
);

export default Chapter9_42;
