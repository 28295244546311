import React, { useState } from 'react';
import { Image, Nav, Navbar, Container, NavDropdown } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { } from 'react-router-dom';

import logo_framework from '../../../assets/img/avap_logo.png'


interface HeaderProps {
  isDashboardBlue?: any; // Aquí especificas que 'sider' puede ser de cualquier tipo
}

const mobileNavbar = () => {

  let { path, url } = useRouteMatch();
  const history = useHistory();

  //   const [ avapBut, setavapBut ] = useState(true);
  //   const [ vsBut, setvsBut ] = useState(false);
  //   const [ gBut, setgBut ] = useState(false);
  //   const [ dsBut, setdsBut ] = useState(false);
  //   const [ cloudBut, setcloudBut ] = useState(false);
  //   const [ CnBut, setcnBut ] = useState(false);

  //   const [ menu1, setmenu1 ] = useState(false);
  //   const [ menu2, setmenu2 ] = useState(false);
  //   const [ menu3, setmenu3 ] = useState(false);
  //   const [ menu4, setmenu4 ] = useState(false);
  //   const [ menu5, setmenu5 ] = useState(false);

  //   const cleanMenuAll = ()=>{
  //     setmenu1(false);
  //     setmenu2(false);
  //     setmenu3(false);
  //     setmenu4(false);
  //     setmenu5(false);
  // }


  // const cleanAll = ()=>{
  //   setavapBut(false);
  //   setvsBut(false);
  //   setgBut(false);
  //   setdsBut(false);
  //   setcloudBut(false);
  //   setcnBut(false);
  // }






  return (
    <Navbar id='frameworkNav' collapseOnSelect expand="lg" bg="light" variant="light" className='fixed-top' style={{ width: '100%' }}>
      <Container>
        <Navbar.Brand href="/framework">
          <Image src={logo_framework} style={{ marginTop: '-10px', paddingLeft: '15px' }} alt="Logo" className="mb-1" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" className='mr-2' >
          <div className='hamburguer' style={{ marginTop: '-10px' }}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav" style={{ backgroundColor: 'white', marginTop: '-20px' }} className="w-100 justify-content-between align-items-center">
          <div className="d-flex flex-wrap">
            {/* onClick={() => { cleanMenuAll(); setmenu1(!menu1); }} va en navlink */}
            <Nav.Link className='hideLink'>
              Products
              {/* <div hidden={!menu1} className="menu-highlight"></div> */}
            </Nav.Link>
            <NavDropdown title="Products" id="DropdownProducts" className='showLink'>

              <div className="section-header">Build Virtual APIs</div>
              <div className="section-content" onClick={() => { history.push('/avap'); }}>
                AVAP (Advanced Virtual API Programming)
              </div>
              <div className="section-content" onClick={() => { history.push('/vsc-extensions'); }}>
                AVAP API Extensions for Microsoft Visual Code
              </div>
              <div className="section-content" onClick={() => { history.push('/devstudio'); }}>
                AVAP Dev Studio
              </div>


              <NavDropdown.Divider />
              <div className="section-header">Test & Deliver APIs</div>
              <div className="section-content" onClick={() => { history.push('/version-control'); }}>
                AVAP API Version Control
              </div>
              <div className="section-content" onClick={() => { history.push('/api-publisher'); }}>
                AVAP API Publisher
              </div>


              <NavDropdown.Divider />


              <div className="section-header">Run APIs</div>
              <div className="section-content" onClick={() => { history.push('/avs'); }}>
                AVAP Virtual Server
              </div>
              <div className="section-content" onClick={() => { history.push('/avap'); }}>
                AVAP Interpreter & Language Server
              </div>
              <div className="section-content" onClick={() => { history.push('/gateway'); }}>
                Gateway Advanced
              </div>



              <NavDropdown.Divider />
              <div className="section-header">Power Up APIs</div>
              <div className="section-content" onClick={() => { history.push('/avap-marketplace'); }}>
                AVAP Marketplace
              </div>
              <div className="section-content" onClick={() => { history.push('/avap-connectors'); }}>
                AVAP Connectors
              </div>



            </NavDropdown>


            {/* onClick={() => { cleanMenuAll(); setmenu2(!menu2); }}  */}
            <Nav.Link className='hideLink'>
              Services
              {/* <div hidden={!menu2} className="menu-highlight"></div> */}
            </Nav.Link>
            <NavDropdown title="Services" id="DropdownProducts" className='showLink'>
              <div className="section-header">Professional</div>
              <div className="section-content" onClick={() => { history.push('/collaborative'); }}>
                AVAP Collaborative Network
              </div>
              <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                AVAP Cloud Professional
              </div>
              <NavDropdown.Divider />
              <div className="section-header">Business</div>
              <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                AVAP Cloud Business
              </div>


              <NavDropdown.Divider />
              <div className="section-header">Corporate</div>
              <div className="section-content" onClick={() => { history.push('/cloud'); }}>
                AVAP Cloud Enterprise
              </div>

            </NavDropdown>





            {/* onClick={() => { history.push('/avapid'); cleanMenuAll(); }} */}
            <Nav.Link onClick={() => { history.push('/ai'); }}>
              AI Brunix
              {/* <div hidden={!menu3} className="menu-highlight"></div> */}
            </Nav.Link>
            <Nav.Link onClick={() => { history.push('/avapid'); }}>
              AVAP ID
              {/* <div hidden={!menu3} className="menu-highlight"></div> */}
            </Nav.Link>
            {/* onClick={() => { cleanMenuAll(); setmenu4(!menu4); }} */}
            <Nav.Link className='hideLink'>
              Resources
              {/* <div hidden={!menu4} className="menu-highlight"></div> */}
            </Nav.Link>
            <NavDropdown title="Resources" id="DropdownProducts" className='showLink'>
              <div className="section-header">Developer Support</div>
              {/* <div className="section-content" onClick={() => { history.push('/default'); }}>
                    AVAP Developer Portal
                </div> */}
              <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/communities/s/', '_blank'); }}>
                AVAP Communities
              </div>
              <NavDropdown.Divider />
              <div className="section-header">Knowledge Center</div>
              <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US'); }}>
                AVAP Alexandria
              </div>
              <div className="section-content" onClick={() => { window.open('http://blog.avapframework.com'); }}>
                AVAP Blog
              </div>
              <NavDropdown.Divider />
              <div className="section-header">API Products</div>
              <div className="section-content" onClick={() => { history.push('/avap-marketplace'); }}>
                AVAP Marketplace
              </div>
            </NavDropdown>

            {/* onClick={() => { cleanMenuAll(); setmenu5(!menu5); }}  */}
            <Nav.Link className='hideLink'>
              Company
              {/* <div hidden={!menu5} className="menu-highlight"></div> */}
            </Nav.Link>
            <NavDropdown title="Company" id="DropdownProducts" className='showLink'>
              <div className="section-content" onClick={() => { history.push('/about'); }}>
                About Us
              </div>
              <div className="section-content" onClick={() => { window.open('mailto:info@101obex.com', '_self'); }}>
                Contact Us
              </div>
            </NavDropdown>
          </div>
          {/*<button
                className="greenbutton"
                onClick={() => { history.push('/login'); }}
            >
                Sign in with your AVAP ID
            </button>*/}
        </Navbar.Collapse>
      </Container>
    </Navbar>


    // {menu1 && (
    //     <>

    //         <div className="menu-dropdown ">
    //             <div className="d-flex">
    //                 <div className="section">
    //                     <div className="section-header">Build Virtual APIs</div>
    //                     <div className="section-content" onClick={() => { history.push('/avap'); }}>
    //                         AVAP (Advanced Virtual API Programming)
    //                     </div>
    //                     <div className="section-content" onClick={() => { history.push('/vsc-extensions'); }}>
    //                         AVAP API Extensions for Microsoft Visual Code
    //                     </div>
    //                     <div className="section-content" onClick={() => { history.push('/devstudio'); }}>
    //                         AVAP Dev Studio
    //                     </div>
    //                 </div>
    //                 <div className="section">
    //                     <div className="section-header">Test & Deliver APIs</div>
    //                     <div className="section-content" onClick={() => { history.push('/version-control'); }}>
    //                         AVAP API Version Control
    //                     </div>
    //                     <div className="section-content" onClick={() => { history.push('/api-publisher'); }}>
    //                         AVAP API Publisher
    //                     </div>
    //                 </div>
    //                 <div className="section">
    //                     <div className="section-header">Run APIs</div>
    //                     <div className="section-content" onClick={() => { history.push('/avs'); }}>
    //                         AVAP Virtual Server
    //                     </div>
    //                     <div className="section-content" onClick={() => { history.push('/avap'); }}>
    //                         AVAP Interpreter & Language Server
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>


    //     </>
    // )}

    // {menu2 && (
    //     <>
    //         <div className="menu-dropdown">
    //             <div className="d-flex">
    //                 <div className="section">
    //                     <div className="section-header">Professional</div>
    //                     <div className="section-content" onClick={() => { history.push('/collaborative'); }}>
    //                         AVAP Collaborative Network
    //                     </div>
    //                     <div className="section-content" onClick={() => { history.push('/cloud'); }}>
    //                         AVAP Cloud Professional
    //                     </div>
    //                 </div>
    //                 <div className="section">
    //                     <div className="section-header">Business</div>
    //                     <div className="section-content" onClick={() => { history.push('/cloud'); }}>
    //                         AVAP Cloud Business
    //                     </div>
    //                 </div>
    //                 <div className="section">
    //                     <div className="section-header">Corporate</div>
    //                     <div className="section-content" onClick={() => { history.push('/cloud'); }}>
    //                         AVAP Cloud Enterprise
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>


    //     </>
    // )}

    // {menu3 && (
    //     <>
    //         <div className="menu-dropdown">
    //             <div className="d-flex">
    //                 <div className="section">
    //                     <div className="section-header">Partner Ecosystem</div>
    //                     <div className="section-content" onClick={() => { /* Acción para esta sección */ }}>
    //                         Find a Partner
    //                     </div>
    //                     <div className="section-content" onClick={() => { /* Acción para esta sección */ }}>
    //                         Become a Partner
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </>
    // )}

    // {menu4 && (
    //     <>
    //         <div className="menu-dropdown">
    //             <div className="d-flex">
    //                 <div className="section">
    //                     <div className="section-header">Developer Support</div>
    //                     <div className="section-content" onClick={() => { history.push('/default'); }}>
    //                         AVAP Developer Portal
    //                     </div>
    //                     <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/communities/s/', '_blank'); }}>
    //                         AVAP Communities
    //                     </div>
    //                 </div>
    //                 <div className="section">
    //                     <div className="section-header">Knowledge Center</div>
    //                     <div className="section-content" onClick={() => { window.open('https://speed-computing-1656.my.site.com/alexandria/s/?language=en_US'); }}>
    //                         AVAP Alexandria
    //                     </div>
    //                     <div className="section-content" onClick={() => { window.open('http://blog.avapframework.com'); }}>
    //                         AVAP Blog
    //                     </div>
    //                 </div>
    //                 <div className="section">
    //                     <div className="section-header">API Products</div>
    //                     {/*<div className="section-content" onClick={() => { history.push('/avap-marketplace'); }}>
    //                         AVAP Marketplace
    //                     </div>*/}
    //                 </div>
    //             </div>
    //         </div>
    //     </>
    // )}

    // {menu5 && (
    //     <>
    //         <div className="menu-dropdown">
    //             <div className="d-flex">
    //                 <div className="section">
    //                     <div className="section-header">Company</div>
    //                     <div className="section-content" onClick={() => { history.push('/about'); }}>
    //                         About Us
    //                     </div>
    //                     <div className="section-content" onClick={() => { window.open('mailto:info@101obex.com', '_self'); }}>
    //                         Contact Us
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </>
    // )}



  )

}

export default mobileNavbar;