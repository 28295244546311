import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import "./CustomSeachBox.css"

function CustomSearchBox({ currentRefinement, refine }) {
  const handleChange = (e) => {
    refine(e.target.value);
  };

  return (
    <div className='row border-bottom pb-2'>

      <div className='col-1' style={{ width: '15px' }} > {/* Establece un ancho fijo para el icono */}
        <svg className="icono-svg" width={"100%"} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
        </svg>
      </div>
      <div className='col-11 p-0 '>
        <input
        style={{ width: '100%' }}
          className='rounded border-0 test99 p-1'
          type="text"
          placeholder="Go to..."
          value={currentRefinement}
          onChange={handleChange}
        />
      </div>
    </div>
  );
}

export default connectSearchBox(CustomSearchBox);
