import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const Get_total_active_hours = () => {


  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status":true,
        "codtran":"e2f2a17feede0a12d7dbbcc854669dec",
        "result":[
           {
              "date":"2022-07-18",
              "start_time":"21:30:06",
              "end_time":"21:30:16",
              "total_time":10
           }
        ],
        "elapsed":0.06685805320739746
     }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requestsurl = "http://34.121.95.179:80/ws/employee_time_control.py/registered_events_today?country_code=ES&id_usuarioo=74&session_id=34242-7M94x6WvLh7-b19HC6PM_g==&init_date=2019-10-12&end_date=2022-07-25"
    payload={}
    headers = {}
    response = requests.request("GET", url, headers=headers, data=payload)
    print(response.text)`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requestsurl = "http://34.121.95.179:80/ws/employee_time_control.py/registered_events_today?country_code=ES&id_usuarioo=74&session_id=34242-7M94x6WvLh7-b19HC6PM_g==&init_date=2019-10-12&end_date=2022-07-25"
    payload={}
    headers = {}
    response = requests.request("GET", url, headers=headers, data=payload)
    print(response.text)`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'GET',
  'url': 'http://34.121.95.179:80/ws/employee_time_control.py/registered_events_today?country_code=ES&id_usuarioo=74&session_id=34242-7M94x6WvLh7-b19HC6PM_g==&init_date=2019-10-12&end_date=2022-07-25',
  'headers': {
  }
  };
  request(options, function (error, response) {
  if (error) throw new Error(error);
  console.log(response.body);
  });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var requestOptions = {
  method: 'GET',
  redirect: 'follow'
  };

  fetch("http://34.121.95.179:80/ws/employee_time_control.py/registered_events_today?country_code=ES&id_usuarioo=74&session_id=34242-7M94x6WvLh7-b19HC6PM_g==&init_date=2019-10-12&end_date=2022-07-25", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request GET 'http://34.121.95.179:80/ws/employee_time_control.py/registered_events_today?country_code=ES&id_usuarioo=74&session_id=34242-7M94x6WvLh7-b19HC6PM_g==&init_date=2019-10-12&end_date=2022-07-25'`
      }
    ]
  }

  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >Get Total Active Hours</h1>
          <h2 >
            ws/employee_time_control.py/get_total_active_hours
          </h2>
          <h3 >Receives</h3>
          <p>
            All the parameters that the service receives must be indicated in the body
            of the request.
          </p>
          <div className='overflow-auto'>

            <table>
              <thead>
                <tr>
                  <th align="center">Parameter</th>
                  <th align="center">Format</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                  <th align="center">Example</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">country_code</td>
                  <td align="center">
                    ISO 3166 alpha-2 &#40;
                    <a href="https://www.iso.org/glossary-for-iso-3166.html">
                      https://www.iso.org/glossary-for-iso-3166.html
                    </a>
                    &#41;
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">Country code in which the user resides</td>
                  <td align="center">N/A</td>
                  <td align="center">ES</td>
                </tr>
                <tr>
                  <td align="center">user_id</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">Identifier of the user making the request</td>
                  <td align="center">N/A</td>
                  <td align="center">18</td>
                </tr>
                <tr>
                  <td align="center">session_id</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send signature to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Obtained by identifying the user with ID user_id on the platform
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">34242-7M94x6WvLh7-b19HC6PM_g==</td>
                </tr>
                <tr>
                  <td align="center">signature</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send session_id to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Enables you to sign the request without having to log in
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">328621ee3acda7a582338c5bba444c3094cd6f29</td>
                </tr>
                <tr>
                  <td align="center">init_date</td>
                  <td align="center">
                    String dd/mm/yyyy, yyyy/mm/dd, dd-mm-yyyy, yyyy-mm-dd
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Work registrations are obtained from the indicated date
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">2019-10-12</td>
                </tr>
                <tr>
                  <td align="center">end_date</td>
                  <td align="center">
                    String dd/mm/yyyy, yyyy/mm/dd, dd-mm-yyyy, yyyy-mm-dd
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Work registrations are obtained until the indicated date
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">2010-10-12</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2 >Where:</h2>
          <ul>
            <li>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li>
              <code>codtran:</code> Operation result
            </li>
            <li>
              <code>result:</code> List of worked periods
              <ul>
                <li>
                  <code>date</code>: Date of the event.
                </li>
                <li>
                  <code>start_time</code>: Start time for the worked period.
                </li>
                <li>
                  <code>end_time</code>: End time for the worked period.
                </li>
                <li>
                  <code>total_time:</code>Total seconds worked in this worked period.
                </li>
              </ul>
            </li>
            <li>
              <code>elapsed:</code> Operation execution time.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Business logic:</h2>
          <p>
            This service is used to retrieve the list of the worked period between
            init_date, and end_date informing the start and end time and total time
            for each period.
          </p>

        </div>
        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>
        </div>

      </div>

    </Fragment>
  );
}
export default Get_total_active_hours;
