import React, { Fragment } from 'react';
import './chapter.css'
const Chapter10_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h1>IF-THEN-ELSE Statement</h1>
      <p>
        The IF-THEN-ELSE statement in AVAP™ allows for decision-making based on specific conditions and executes different blocks of code depending on the outcome of those conditions. Below is a detailed explanation of its syntax and functionality.
      </p>
    </div>
  </Fragment>
);

export default Chapter10_1;