import React from 'react';
import './chapter.css'
const Chapter9_41 = () => (
    <div>
        <h2>Patterns in AVAP</h2>
        <p>
            In AVAP, patterns provide a powerful way to match and destructure values. Patterns can be used in <code>match</code> statements to perform complex value comparisons and deconstructions. Here is a description of the available patterns and how they are used:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <strong>Literal Patterns:</strong> Match specific literal values such as numbers, strings, or booleans. For example:
                <pre><code>match value:
    case 10:
        # Code to execute if value is 10
    case "hello":
        # Code to execute if value is "hello"</code></pre>
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <strong>Variable Patterns:</strong> Capture the value of a variable. This allows you to use the matched value in the corresponding case block:
                <pre><code>match value:
    case x:
        # Code to execute, x will be assigned the value</code></pre>
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <strong>Sequence Patterns:</strong> Match sequences like lists or tuples. You can also use the <code>*</code> operator to capture remaining elements:
                <pre><code>match value:
    case [1, 2, *rest]:
        # Code to execute, rest will capture any additional elements</code></pre>
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <strong>Mapping Patterns:</strong> Match dictionaries or similar mappings by specifying keys and their corresponding patterns:
                <pre><code>match value:
    case "key": 42:
        # Code to execute if the dictionary has "key" with value 42</code></pre>
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <strong>Class Patterns:</strong> Match instances of classes. You can also match specific attributes within the instance:
                <pre><code>match value:
    case MyClass(attr1=42):
        # Code to execute if value is an instance of MyClass with attr1 equal to 42</code></pre>
            </li>
        </ul>
        <p>
            Patterns in AVAP offer a flexible approach for handling different kinds of data structures and values, making it easier to write expressive and maintainable code.
        </p>
    </div>
);

export default Chapter9_41;

