import React, { useState, useEffect } from 'react';
import { Form, Row, Col, Button, Dropdown, DropdownButton, Table, Alert, Spinner } from 'react-bootstrap';
import GenericSelectableList from '../DashboardComponents/CustomSelectSearch'
import MultiColumnSelectableList from '../DashboardComponents/MultiColumnSelectableList'
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import Cookies from 'universal-cookie';
import { useDropzone } from 'react-dropzone';
import { SketchPicker } from 'react-color';
import { useHistory } from 'react-router-dom';
import { FaPalette } from 'react-icons/fa';
import { ChromePicker } from 'react-color';

import img_layout1 from '../../assets/img/ui_icons/layout1.svg';
import img_layout2 from '../../assets/img/ui_icons/layout2.svg';

type DevPortalFormProps = {
  isEditing: boolean;
  id?: string;
  products?: string[];
  basePath: string;
  projects: string[];
  name: string;
  description: string;
  config: any;
  opened: any;
  onSubmit: (proxy: {
    id?: string;
    products?: string[];
    basePath: string;
    projects: string[];
    name: string;
    description: string;
    config: any;
    layout?: number;
  }) => void;
};


const DevPortalForm: React.FC<DevPortalFormProps> = ({
  opened,
  isEditing,
  id,
  basePath = '',
  name = "",
  description = "",
  products = [],
  projects = [],
  config,
  onSubmit
}) => {
  const [localBasePath, setBasePath] = useState(basePath);
  const [localSelectedGA, setSelectedGA] = useState<any[]>(products);
  const [localRoutes, setRoutes] = useState<string[]>(projects);
  const [nameDp, setNameDp] = useState(name);
  const [nameDs, setNameDs] = useState(description);
  const [visibility, setVisibility] = useState('Custom');
  const [fileLogo, setFileLogo] = useState();
  const [permissions, setPermissions] = useState([]);
  const [localSelectedCA, setSelectedCA] = useState([]);
  const [localSelectedA, setSelectedA] = useState([]);
  const [GAS, setGas] = useState([]);
  const [loading, setLoading] = useState(false);

  const [apiProducts, setApiProducts] = useState([]);
  const [availableProjects, setAvailableProjects] = useState([]);


  const [selectedProject, setSelectedProject] = useState('');
  const [selectedProjectID, setSelectedProjectID] = useState(0);
  const [selectedapiProduct, setselectedapiProduct] = useState('');
  const [selectedLayout, setSelectedLayout] = useState<number>(1);


  const [localConfig, setLocalConfig] = useState({})

  const history = useHistory();

  const handleChangeProject = async (project) => {
    console.log(project.target.value)


    setSelectedProjectID(project.target.value);

    availableProjects.forEach(gg => {

      if (gg.id == project.target.value)
        setSelectedProject(gg.descriptio);
    })

    await fetchAPIProducts(project.target.value);

  }

  const handleLayoutChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedLayout(Number(event.target.value));
  };

  const handleChangeApiProduct = (product) => {
    console.log(product.target.value);
    setselectedapiProduct(product.target.value);

  }

  const [AMBIENTS, setAMBIENTS] = useState([]);


  const fetchApiData = async () => {
    try {
      setLoading(true);
      const projectsResponse = await ObexRequestHandler.get('/projects', {}, true);
      setAvailableProjects(projectsResponse.data || []);
      console.log('Projects Response:', projectsResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchAPIProducts = async (project_id) => {

    const productsResponse = await ObexRequestHandler.get(`/publishing/get_products_by_org?org_id=${SessionService.ORGANIZATION}`, {}, true);

    const project_products = [];

    productsResponse.data.forEach(element => {
      if (element.project_id == project_id) project_products.push(element)
    });
    setApiProducts(project_products || []);
  }

  const getGAs = async () => {

    const gas = await ObexRequestHandler.get(`/gateway/get_gas?organization=${SessionService.ORGANIZATION}`);

    setGas(gas.data);

  }

  const getAMBIENTS = async () => {
    const gas = await ObexRequestHandler.get(`/gateway/get_gateway_ambients?organization=${SessionService.ORGANIZATION}`);
    setAMBIENTS(gas.data);
  }

  const getCerts = async () => {
    const certs = await ObexRequestHandler.get(`/cert/get_certs?organization=${SessionService.ORGANIZATION}`)


    console.log(certs.data);

    console.log(certs.data.map((ga) => ({ id: ga.id, name: ga.name, region_id: ga.region_id })))
  };

  useEffect(()=>{

    if (!isEditing){
    setNameDp('');
    setNameDs('');
    setBasePath('');
    setSelectedGA([]);
    setColor('#000000');
    setColor1('#ffffff');
    setColor2('#ffffff');
    setColor3('#f0f0f0');
    setVisibility('Global')
    setSelectedLayout(1);
    }

    history.push('/dev_portals');

  }, [opened])

  useEffect(() => {

    console.log("XXXXXXXXXXXXXXXX");
    console.log(config);
    console.log("XXXXXXXXXXXXXXXX");

    fetchApiData();
    setNameDp(name);
    setNameDs(description);
    setBasePath(basePath);
    setSelectedGA(products);
    setColor(config.textColor);
    setColor1(config.primaryColor);
    setColor2(config.secondaryColor);
    setColor3(config.backgroundColor);
    setVisibility(config.visibility===true ?  'Global' : 'Custom')
    setSelectedLayout(config.layout)
    getCerts();
    getGAs();
    getAMBIENTS();
  }, [basePath]);


  const handleAddGA = (ga: string) => {
    if (!localSelectedGA.includes(ga)) {
      setSelectedGA([...localSelectedGA, ga]);
    }
  };

  const handleRemoveGA = (ga: string) => {
    setSelectedGA(localSelectedGA.filter((item) => item !== ga));
  };

  const handleAddRoute = () => {
    setRoutes([...localRoutes, '']);
  };

  const handleUpdateRoute = (index: number, value: string) => {
    const updatedRoutes = [...localRoutes];
    updatedRoutes[index] = value;
    setRoutes(updatedRoutes);
  };

  const handleRemoveRoute = (index: number) => {
    setRoutes(localRoutes.filter((_, i) => i !== index));
  };

  const handleCopyCert = (cert: string) => {
    navigator.clipboard.writeText(cert).then(() => {
      alert('Certificate copied to clipboard!');
    });
  };


  const handleAddCA = (permit) => {
    // Añade un permiso seleccionado
    if (!localSelectedCA.some((p) => p.id === permit.id)) {
      setSelectedCA((prev) => [...prev, permit]);
    }
  };

  const handleRemoveCA = (permit) => {
    // Elimina un permiso seleccionado
    setSelectedCA((prev) => prev.filter((p) => p.id !== permit.id));
  };


  const handleAddA = (permit) => {
    // Añade un permiso seleccionado
    if (!localSelectedA.some((p) => p.id === permit.id)) {
      setSelectedA((prev) => [...prev, permit]);
    }
  };

  const handleRemoveA = (permit) => {
    // Elimina un permiso seleccionado
    setSelectedA((prev) => prev.filter((p) => p.id !== permit.id));
  };

  const readFileAsBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject(new Error("Error reading file"));
      reader.readAsDataURL(file); // Convierte el archivo a base64
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      id,
      basePath: localBasePath,
      name: nameDp,
      description: nameDs,
      products: localSelectedGA,
      projects: localRoutes,
      config: localConfig,
      layout: selectedLayout
    });

    let base64Image = '';
    try {
      base64Image = await readFileAsBase64(fileLogo);
    } catch {
      base64Image = '';
    }
    const cookies = new Cookies();
    const userInfo = cookies.get('101Obex');

    const payload = {
      visibility: visibility == 'Global',
      layout: selectedLayout,
      base_path: localBasePath,
      name: nameDp,
      description: nameDs,
      obex_project: userInfo.user_id, //selectedProjectID,
      obex_user_id: userInfo.user_id,
      api_products: localSelectedGA,
      organization: SessionService.ORGANIZATION,
      primary_color: color1 || '#000000',
      secondary_color: color2 || '#ffffff',
      background_color: color3 || '#f0f0f0',
      text_color: color || '#333333',
      logo: base64Image == '' ? config.logo || '' :  base64Image
    }
    console.log(payload)

    if (!isEditing) {

      await ObexRequestHandler.post('/publishing/create_dev_portal', payload)


    }
    else {
      console.log(id)

      await ObexRequestHandler.post(`/publishing/update_dev_portal/${id}`, payload)

    }

  };


  ////////


  const [selectedColors, setSelectedColors] = useState({
    primary: '#000000', // Color primario por defecto
    secondary: '#ffffff', // Color secundario por defecto
    background: '#f0f0f0', // Color de fondo por defecto
    text: '#333333' // Color de texto por defecto
  });

  const [logoPreview, setLogoPreview] = useState(null);

  const handleColorChange = (color, field) => {
    setSelectedColors((prevColors) => ({
      ...prevColors,
      [field]: color.hex
    }));
  };

  const handleLogoChange = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setFileLogo(file);
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setLogoPreview(fileURL);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleLogoChange
  });


  ///////

  const [color, setColor] = useState(''); // Color inicial (puedes cambiar el valor)
  const [color1, setColor1] = useState(''); // Color inicial (puedes cambiar el valor)
  const [color2, setColor2] = useState(''); // Color inicial (puedes cambiar el valor)
  const [color3, setColor3] = useState(''); // Color inicial (puedes cambiar el valor)
  const [showPicker, setShowPicker] = useState(false); // Controla si el picker está visible
  const [showPicker1, setShowPicker1] = useState(false); // Controla si el picker está visible
  const [showPicker2, setShowPicker2] = useState(false); // Controla si el picker está visible
  const [showPicker3, setShowPicker3] = useState(false); // Controla si el picker está visible
  // Maneja el cambio de color del picker
  const handleChangeComplete = (color: { hex: string }) => {
    setColor(color.hex); // Actualiza el color al seleccionar uno del picker
  };

  const handleChangeComplete1 = (color1: { hex: string }) => {
    setColor1(color1.hex); // Actualiza el color al seleccionar uno del picker
  };

  const handleChangeComplete2 = (color2: { hex: string }) => {
    setColor2(color2.hex); // Actualiza el color al seleccionar uno del picker
  };

  const handleChangeComplete3 = (color3: { hex: string }) => {
    setColor3(color3.hex); // Actualiza el color al seleccionar uno del picker
  };

  // Manejador del botón para mostrar/ocultar el picker
  const togglePicker = () => {
    setShowPicker(!showPicker);

    setShowPicker1(false);
    setShowPicker2(false);
    setShowPicker3(false);

  };
  const togglePicker1 = () => {
    setShowPicker1(!showPicker1);

    setShowPicker(false);
    setShowPicker2(false);
    setShowPicker3(false);
  };

  const togglePicker2 = () => {
    setShowPicker2(!showPicker2);

    setShowPicker1(false);
    setShowPicker(false);
    setShowPicker3(false);
  };

  const togglePicker3 = () => {
    setShowPicker3(!showPicker3);

    setShowPicker1(false);
    setShowPicker2(false);
    setShowPicker(false);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="basePath" className="mb-3">
        <Form.Label>Basepath</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter basepath"
          value={localBasePath}
          onChange={(e) => setBasePath(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="basePath" className="mb-3">
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter name"
          value={nameDp}
          onChange={(e) => setNameDp(e.target.value)}
        />
      </Form.Group>


      <Form.Group controlId="basePath" className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter description"
          value={nameDs}
          onChange={(e) => setNameDs(e.target.value)}
        />
      </Form.Group>
      {availableProjects.length === 0 ? (
        <Alert variant="danger">No API Projects available. Please create one first.</Alert>
      ) : (
        <Form.Group controlId="project">
          <Form.Label>Available Projects</Form.Label>
          <Form.Control
            as="select"
            name="project"
            value={selectedProject}
            onChange={handleChangeProject}
          >
            <option value="">Select a project</option>
            {availableProjects.map((project) => (
              <option key={project.id} value={project.id}>
                {project.description}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      )}


      {(selectedProject != '' || true) && (
        <Form.Group controlId="apiProduct">
          <Form.Label>Available API Products</Form.Label>
          {loading ? (
            <Spinner animation="border" role="status" size="sm">
              <span className="sr-only">Loading...</span>
            </Spinner>
          ) : apiProducts.length === 0 && false ? (
            <Alert variant="warning">No API Products found for this project.</Alert>
          ) : (

            <Form.Group controlId="selectGA" className="mb-3">

              <GenericSelectableList
                items={apiProducts.map((ga) => ({ id: ga.api_product_id, name: ga.api_product_name }))} // Convierte las cadenas en objetos con `id` y `name`
                searchKeys={['api_product_name']} // Busca por el nombre
                onAdd={handleAddGA}
                onRemove={handleRemoveGA}
                renderLabel={(item) => item.name ? `${item.name}` : `> ${item}`} // Renderiza directamente el nombre
                placeholder="Select API Product."
                multiOption={true} // Permitir selección múltiple
                selectedItems={localSelectedGA}
                setSelectedItems={setSelectedGA}
              />
            </Form.Group>)}
        </Form.Group>
      )}



        <Form.Label style = {{ paddingLeft:'0px' }} as="legend" column sm={12}>
        Visibility
        </Form.Label>
                  <Form.Check
                    type="switch"
                    id="visibility-global"
                    label="Private"
                    checked={visibility === 'Global'}
                    onChange={(e) => {
                      const isGlobal = e.target.checked;
                      if (isGlobal) {
                        setVisibility('Global');
                      } else {
                        setVisibility('Custom');
                      }
                    }}
                    className="mb-3"
                  />



      <Form.Group as={Row} className="mb-3" controlId="themeSelection">
        <Form.Label as="legend" column sm={12}>
          Select Layout
        </Form.Label>

        <Col sm={6}>
          <img src={img_layout1} alt="layout 1" />
          <Form.Check
            type="radio"
            label="Layout 1"
            name="themeOptions"
            id="layout1"
            value={1}
            checked={selectedLayout === 1}
            onChange={handleLayoutChange}
          />
        </Col>
        <Col sm={6}>
          <img src={img_layout2} alt="layout 2" />
          <Form.Check
            type="radio"
            label="layout 2"
            name="themeOptions"
            id="layout2"
            value={2}
            checked={selectedLayout === 2}
            onChange={handleLayoutChange}
          />
        </Col>
      </Form.Group>


      <Form.Group controlId="colorPicker" className="mb-3">
        <Form.Label>Primary Color</Form.Label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Control
            type="text"
            value={color1}
            onChange={(e) => setColor1(e.target.value)} // Permite cambiar el valor a mano
            style={{ width: '200px' }}
          />
          <Button
            variant="secondary"
            onClick={togglePicker1}
            style={{ marginLeft: '10px' }}
            size="sm"
          >
            <FaPalette /> {/* Icono de paleta de colores */}
          </Button>
        </div>

        {/* El picker se muestra solo si showPicker es verdadero */}
        {showPicker1 && (
          <div style={{ position: 'absolute', zIndex: 2 }}>
            <ChromePicker
              color={color1}
              onChangeComplete={handleChangeComplete1}
            />
          </div>
        )}
      </Form.Group>

      {/* Repite este bloque para el color secundario, background, texto, etc. */}
      <Form.Group controlId="secondaryColor" className="mb-3">
        <Form.Label>Secondary Color</Form.Label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Control
            type="text"
            value={color2}
            onChange={(e) => setColor2(e.target.value)} // Permite cambiar el valor a mano
            style={{ width: '200px' }}
          />
          <Button
            variant="secondary"
            onClick={togglePicker2}
            style={{ marginLeft: '10px' }}
            size="sm"
          >
            <FaPalette /> {/* Icono de paleta de colores */}
          </Button>
        </div>

        {/* El picker se muestra solo si showPicker es verdadero */}
        {showPicker2 && (
          <div style={{ position: 'absolute', zIndex: 2 }}>
            <ChromePicker
              color={color2}
              onChangeComplete={handleChangeComplete2}
            />
          </div>
        )}
      </Form.Group>




      <Form.Group controlId="colorPicker" className="mb-3">
        <Form.Label>Background Color</Form.Label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Control
            type="text"
            value={color3}
            onChange={(e) => setColor3(e.target.value)} // Permite cambiar el valor a mano
            style={{ width: '200px' }}
          />
          <Button
            variant="secondary"
            onClick={togglePicker3}
            style={{ marginLeft: '10px' }}
            size="sm"
          >
            <FaPalette /> {/* Icono de paleta de colores */}
          </Button>
        </div>

        {/* El picker se muestra solo si showPicker es verdadero */}
        {showPicker3 && (
          <div style={{ position: 'absolute', zIndex: 2 }}>
            <ChromePicker
              color={color3}
              onChangeComplete={handleChangeComplete3}
            />
          </div>
        )}
      </Form.Group>


      <Form.Group controlId="colorPicker" className="mb-3">
        <Form.Label>Text Color</Form.Label>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Form.Control
            type="text"
            value={color}
            onChange={(e) => setColor(e.target.value)} // Permite cambiar el valor a mano
            style={{ width: '200px' }}
          />
          <Button
            variant="secondary"
            onClick={togglePicker}
            style={{ marginLeft: '10px' }}
            size="sm"
          >
            <FaPalette /> {/* Icono de paleta de colores */}
          </Button>
        </div>

        {/* El picker se muestra solo si showPicker es verdadero */}
        {showPicker && (
          <div style={{ position: 'absolute', zIndex: 2 }}>
            <ChromePicker
              color={color}
              onChangeComplete={handleChangeComplete}
            />
          </div>
        )}
      </Form.Group>


      {/* Logo */}
      <Form.Group className="mb-3">
        <Form.Label>Logo</Form.Label>
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Button variant="secondary">Upload Logo</Button>
        </div>
        {logoPreview && (
          <div className="mt-3">
            <img src={logoPreview} alt="Logo preview" style={{ width: '100px', height: '100px' }} />
          </div>
        )}
      </Form.Group>


      <Row>
        <Col md="6">
          <Button className="w-100" variant="primary" type="submit">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

/*
return (
  <Form onSubmit={handleSubmit}>
    <Form.Group controlId="basePath" className="mb-3">
      <Form.Label>Basepath</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter basepath"
        value={localBasePath}
        onChange={(e) => setBasePath(e.target.value)}
      />
    </Form.Group>

    <Form.Group controlId="basePath" className="mb-3">
      <Form.Label>Name</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter name"
        value={nameDp}
        onChange={(e) => setNameDp(e.target.value)}
      />
    </Form.Group>


    <Form.Group controlId="basePath" className="mb-3">
      <Form.Label>Description</Form.Label>
      <Form.Control
        type="text"
        placeholder="Enter description"
        value={nameDs}
        onChange={(e) => setNameDs(e.target.value)}
      />
    </Form.Group>
    {availableProjects.length === 0 ? (
          <Alert variant="danger">No API Projects available. Please create one first.</Alert>
        ) : (
          <Form.Group controlId="project">
            <Form.Label>Available Projects</Form.Label>
            <Form.Control
              as="select"
              name="project"
              value={selectedProject}
              onChange={handleChangeProject}
            >
              <option value="">Select a project</option>
              {availableProjects.map((project) => (
                <option key={project.id} value={project.id}>
                  {project.description}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        )}

        
        {(selectedProject!='' || true) && (
          <Form.Group controlId="apiProduct">
            <Form.Label>Available API Products</Form.Label>
            {loading ? (
              <Spinner animation="border" role="status" size="sm">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : apiProducts.length === 0 && false ? (
              <Alert variant="warning">No API Products found for this project.</Alert>
            ) : (
              
              <Form.Group controlId="selectGA" className="mb-3">
              
              <GenericSelectableList
                items={apiProducts.map((ga) => ({ id: ga.api_product_id, name: ga.api_product_name }))} // Convierte las cadenas en objetos con `id` y `name`
                searchKeys={['api_product_name']} // Busca por el nombre
                onAdd={handleAddGA}
                onRemove={handleRemoveGA}
                renderLabel={(item) => item.name ? `${item.name}` : `> ${item}`} // Renderiza directamente el nombre
                placeholder="Select API Product."
                multiOption={true} // Permitir selección múltiple
                selectedItems={localSelectedGA}
                setSelectedItems={setSelectedGA}
              />
            </Form.Group>)}
          </Form.Group>
        )}

    <Row>
      <Col md='6'>
        <Button className="w-100" variant="primary" type="submit">
          Save
        </Button>
      </Col>
    </Row>
  </Form>
);
};*/


export default DevPortalForm;
