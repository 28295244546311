import React, { Fragment } from 'react';

const AVAP_Cloud = () => (
  <Fragment>
     <div>
    <h2>Getting Started with AVAP Cloud</h2>

    <h3>AVAP Cloud</h3>

    <h4>Introduction to AVAP Cloud</h4>
    <p>
      AVAP Cloud is a comprehensive platform designed for the complete lifecycle management of APIs, from development to publication and maintenance. It provides a complete and advanced solution for API management, with value-added features derived from the AVAP technology. This technology is developed to enhance the entire API lifecycle, offering significant additional value.
    </p>

    <h4>Professional, Business, and Enterprise Memberships</h4>
    <p>
      AVAP Cloud is offered as a SaaS (Software as a Service) in three different membership versions:
    </p>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Professional</strong></li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Business</strong></li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Enterprise</strong></li>
    </ul>
    <p>
      Each membership is tailored to a different type of user, aiming to meet the needs and characteristics of various market segments.
    </p>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Professional:</strong> Designed for developers, freelancers, and independent professionals who require sufficient infrastructure to carry out API-based development tasks. AVAP Cloud allows them to avoid not only having but also maintaining and updating such infrastructure.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Business:</strong> Intended to meet the needs of small to medium-sized companies that require sufficient infrastructure to run their software products. AVAP Cloud Business enables these companies to be more competitive without incurring significant infrastructure costs related to API work.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Enterprise:</strong> The most comprehensive corporate solution available today, offering a platform that combines managed cloud infrastructure with on-premise networks, working together to provide the most powerful API development, management, publication, and maintenance network on the market.</li>
    </ul>

    <h4>Main Features of AVAP Cloud</h4>
    <h5>Infrastructure Management</h5>
    <p>
      AVAP Cloud allows users to focus on API project development and management by handling all underlying infrastructure management. This includes:
    </p>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>AVAP Virtual Server (AVS):</strong> Manages API traffic routing and API gateway administration.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>AVAP Gateway Advanced:</strong> Provides advanced routing and traffic management between AVS and AVAP Interpreter & Language Server.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>AVAP Interpreter & Language Server:</strong> Facilitates the execution of the AVAP language and API interpretation.</li>
    </ul>
    <p>
      Users delegate infrastructure management to AVAP Cloud, enabling them to concentrate on creating and optimizing API projects without worrying about server and related component administration.
    </p>

    <h5>Hybrid Network Capabilities in the Enterprise Membership</h5>
    <p>
      In the <strong>Enterprise</strong> membership of AVAP Cloud, users can manage their infrastructure logically. This includes:
    </p>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Hybrid Network Creation:</strong> Users can combine cloud resources with on-premise resources to create hybrid networks tailored to their specific needs.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Advanced Management:</strong> The platform allows for more granular management of resources, facilitating the integration of different environments and configurations.</li>
    </ul>

    <h5>Deployment Models</h5>
    <p>
      AVAP Cloud offers various deployment models to meet different business needs:
    </p>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Cloud Business:</strong> Based on AVAP Cloud Business services, utilizing AVAP Interpreter & Language Server, AVAP Extensions for Microsoft Visual Studio Code, or AVAP Dev Studio, and AVAP Cloud Business membership.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Cloud Enterprise:</strong> Based on AVAP Cloud Enterprise services, with AVAP Interpreter & Language Server, AVAP Dev Studio, and AVAP Cloud Enterprise membership.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Cloud Enterprise Hybrid:</strong> Includes AVAP Cloud Enterprise services and additional components such as AVAP Virtual Server and AVAP Gateway Advanced.</li>
    </ul>

    <h4>Additional Benefits</h4>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Operational Efficiency:</strong> The platform simplifies infrastructure management and enhances efficiency in API development and maintenance.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Flexibility and Scalability:</strong> With the ability to create hybrid networks and manage different deployment models, AVAP Cloud offers flexibility to adapt to various business needs.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Ongoing Support:</strong> AVAP Cloud provides continuous support and updates to ensure users have access to the latest features and improvements.</li>
    </ul>
    <p>
      AVAP Cloud stands out for its ability to efficiently manage both infrastructure and API projects, offering a robust and flexible solution that surpasses the capabilities of traditional platforms like Google Apigee. With its advanced features and adaptable deployment models, AVAP Cloud is designed to meet the needs of organizations of all sizes.
    </p>

    <p>
      You can register at <a href="http://www.avapcloud.com/">www.avapcloud.com</a> or log in to your portal at the same address to explore the world of virtual APIs.
    </p>

    <h4>Additional Resources</h4>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>For more information about AVAP Cloud, visit AVAP Alexandria at <a href="https://alexandria.avapframework.com/">alexandria.avapframework.com</a>.</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Join the AVAP Communities at <a href="https://communities.avapframework.com/">communities.avapframework.com</a> to connect with other users and stay updated.</li>
    </ul>
  </div>
  </Fragment>
);
export default AVAP_Cloud;
