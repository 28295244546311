import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_11 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>itemFromList()</h2>
      <p>
        The <code>itemFromList()</code> command extracts a specific element from a list based on a given index and stores the result in a target variable. This is useful for accessing individual elements within a list.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>SourceVariable</strong> <br />
          Type: <code>var</code> <br />
          Description: The variable containing the list from which an element is to be extracted. It can be a variable that stores the list or a direct value representing the list.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>index</strong> <br />
          Type: <code>value</code> <br />
          Description: The index of the element to be extracted from the list. It must be an integer value that indicates the position of the element within the list.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>TargetVariable</strong> <br />
          Type: <code>var</code> <br />
          Description: The variable where the extracted element will be stored. It must be a variable that will receive the value of the element at the specified index position.
        </li>
      </ul>
      <h3>Command Flow</h3>
      <ol>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Access the List:</strong> Access the list stored in the <code>SourceVariable</code>.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Extract the Element:</strong> Retrieve the element at the position specified by the <code>index</code>.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Store the Result:</strong> Save the extracted element in the variable specified by <code>TargetVariable</code>.</li>
      </ol>
      <h3>Usage Example</h3>
      <p>Suppose the list in <code>myList</code> is <code>['apple', 'banana', 'cherry']</code> and you want to extract the element at index 1.</p>
      <pre>
        <code>
          {`// Variable definitions
myList = ['apple', 'banana', 'cherry']
element = ''

// Call the command to extract the element at index 1
itemFromList(myList, 1, element)

// Return the extracted element through addResult
addResult(element)`}
        </code>
      </pre>
      <p>Since index 1 corresponds to the element 'banana' in the <code>myList</code>, the <code>itemFromList()</code> command will extract 'banana' and store it in the variable <code>element</code>. The <code>element</code> variable will be returned through <code>addResult(element)</code>, resulting in the following output:</p>
      <pre>
        <code>
          {`"banana"`}
        </code>
      </pre>
    </div>
  </Fragment>
);

export default Appendices_11;
