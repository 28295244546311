import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../components/DisplayCodeKo/DisplayCodeKo';

const Listar_Usuarios_del_Programa_de_Puntos_EN = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status": <boolean>,
        "data": [{
            "id": <integer>, 
            "name": <string>,
            "accumulated_points": <integer>,
            "transactions": [ 
                {"date": <string>, "amount": <integer>, "points": <integer>}
            ],
            "gifts": [
                {"date": <string>, "points": <integer>}
            ]
        }]
    }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
    url = "URL_BASE/ws/programadepuntos.py/listado_usuarios?country_code=MX&user_id=4532&session_id=400-nnpRvOwTzJejvCUfGxXQOA==&solution_id=4"
    payload = {}
    files = {}
    headers= {}
    response = requests.request("GET", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
    url = "URL_BASE/ws/programadepuntos.py/listado_usuarios?country_code=MX&user_id=4532&session_id=400-nnpRvOwTzJejvCUfGxXQOA==&solution_id=4"
    payload = {}
    files = {}
    headers= {}
    response = requests.request("GET", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'GET',
  'url': 'URL_BASE/ws/programadepuntos.py/listado_usuarios?country_code=MX&user_id=4532&session_id=400-nnpRvOwTzJejvCUfGxXQOA==&solution_id=4',
  'headers': {
  },
  formData: {}
  };
  request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
  });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var formdata = new FormData();
  var requestOptions = {
  method: 'GET',
  body: formdata,
  redirect: 'follow'
  };
  fetch("URL_BASE/ws/programadepuntos.py/listado_usuarios?country_code=MX&user_id=4532&session_id=400-nnpRvOwTzJejvCUfGxXQOA==&solution_id=4", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request GET 'URL_BASE/ws/programadepuntos.py/listado_usuarios?country_code=MX&user_id=4532&session_id=400-nnpRvOwTzJejvCUfGxXQOA%3D%3D&solution_id=4'`
      }
    ]
  }

  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >
            List of users in the points Program
          </h1>
          <p>
            This service is used to obtain users who belong to the selected points
            program.
          </p>
          <p>
            <span className="label get">GET:</span>
            <span> </span>{' '}
            <code>URL_BASE + /ws/programadepuntos.py/listado_usuarios</code>
          </p>
          <h2 >Receives:</h2>
          <p>
            All the parameters that the service receives must be indicated in the body
            of the request.
          </p>
          <div className='overflow-auto'>

            <table>
              <thead>
                <tr>
                  <th align="left">Parameter</th>
                  <th align="center">Format</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                  <th align="center">Example</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="left">country_code</td>
                  <td align="center">
                    ISO 3166 alpha-2 &#40;
                    <a href="https://www.iso.org/glossary-for-iso-3166.html">
                      https://www.iso.org/glossary-for-iso-3166.html
                    </a>
                    &#41;
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">Country code in which the user resides</td>
                  <td align="center">N/A</td>
                  <td align="center">ES &#40;Spain&#41;</td>
                </tr>
                <tr>
                  <td align="left">user_id</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">Identifier of the user making the request</td>
                  <td align="center">N/A</td>
                  <td align="center">4532</td>
                </tr>
                <tr>
                  <td align="left">session_id</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send signature to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Obtained by identifying the user with ID user_id on the platform
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">385-XadgYoWixbzN_wqWc3Yyog==</td>
                </tr>
                <tr>
                  <td align="left">signature</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send session_id to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Enables you to sign the request without having to log in
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
                </tr>
                <tr>
                  <td align="left">solution_id</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">Identifier of the points programme</td>
                  <td align="center">N/A</td>
                  <td align="center">2</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>
          <h3 >Answer JSON OK:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2 >Where:</h2>
          <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>data:</code> List of users who belong to the point programme
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>id:</code> User identifier
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>name:</code> User name
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>accumulated_points:</code> Number of points that the user has
              accumulated in his account.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>transactions:</code> List with the transactions that have made the
              user earn points, indicating the date, the amount and the number of
              points obtained with that amount.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>gifts:</code> List with the gifts obtained by the user, in which
              the date of obtaining the gift is indicated as well as the points that
              the gift has cost.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Business logic:</h2>
          <p>
            This service returns a list of the users that belong to the selected
            points program.
          </p>

        </div>
        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>

      </div>

    </Fragment>
  );
}
export default Listar_Usuarios_del_Programa_de_Puntos_EN;
