import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';


import fondo from '../../assets/img/landingPage/fondoApps.png';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'

import banner from '../../assets/img/landingPage/interpreter/AVAPInterpreter-01-.png'
import features_1 from '../../assets/img/landingPage/interpreter/AVAPInterpreter-02.png'
    import features_2 from '../../assets/img/landingPage/interpreter/AVAPInterpreter-03.png'
    import features_3 from '../../assets/img/landingPage/interpreter/AVAPInterpreter-04.png'

import frameTablet from '../../assets/img/landingPage/iPad_16.9.png'


import user from '../../assets/img/ui_icons/Icon fa-regular-user.svg'
import download from '../../assets/img/ui_icons/Icon akar-download.svg'
import key from '../../assets/img/ui_icons/Icon akar-key.svg'
import doc from '../../assets/img/ui_icons/fa-doc.svg'

import tablet from '../../assets/img/landingPage/tabletApi.png'



import logo from '../../assets/img/logos/AVAP_Lenguaje_white2.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'




import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'

import Favicon from '../../assets/img/Favicon_AVAP_Framework.png'

const VersionControlProduct = () => {

    useEffect(() => {
        // favicon 
        const link = document.createElement('link');
        link.rel = 'icon';
        link.href = Favicon; // Usa la ruta importada directamente
        document.head.appendChild(link);
    
        // clean favicon 
        return () => {
          document.head.removeChild(link);
        };
      }, []);

    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);

    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center pt-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className='fl-subtitle mb-4'>Interpreter & Language Server</h1>
                    <h2 className="fl-title m-0">Building Virtual APIs</h2>
                    <p className="fl-subtitle mt-5">
                        Fast, smart, and scalable coding with AVAP Interpreter <br /> and Language Server.
                    </p>
                    <a className="glowBtn-dark fakeHide" href="#"><span className="btnInner">  Start with AVAP Cloud</span></a>
                </section>

                {/* Powerful API Platform */}
                <section className="container text-center pb-5">
                    <div className="row">
                        <div className="col">
                            <p className="fl-subtitle">
                                Virtual API Execution
                            </p>
                            <img
                                src={banner}
                                alt="Platform Placeholder"
                                className="img-fluid mt-4 mb-5"
                            />
                            <p className="fl-title mb-3">
                                Streamline API development and <br /> execution
                            </p>
                            <p className="fl-text">
                                AVAP Interpreter and Language Server empowers developers with a high-performance environment to seamlessly execute and manage API code. Designed for speed and scalability, it translates AVAP code into real-time actions, ensuring efficient API execution and smooth communication between servers. With built-in intelligence, BRUNIX, it optimizes the development process, streamlines deployment, and provides a reliable foundation for building and maintaining complex API ecosystems.
                            </p>
                        </div>
                    </div>

                </section>


                {/* Features */}
                <section className="container mb-5">
                    <div className="row">
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_free} alt="" />
                                <p>Speed and Scalability</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_dyn} alt="" />
                                <p>Efficient API Execution</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_expo} alt="" />
                                <p>Smooth communication between servers</p>
                            </div>
                        </div>
                    </div>
                </section>




                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Real-time Code Interpretation</h3>
                            <p className="fl-text">
                                The AVAP Interpreter processes AVAP code instantly, enabling developers to test, debug, and execute code without the delays associated with traditional compilation. This feature accelerates the development process, allowing immediate feedback and faster iteration, which ultimately leads to higher productivity and quicker bug resolution.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Seamless Integration with AVAP Technology</h3>
                            <p className="fl-text">
                                Designed to integrate effortlessly with AVAP’s suite of technologies, ensuring a smooth workflow throughout the development lifecycle. Developers benefit from a unified platform that leverages AVAP’s virtual programming capabilities, simplifying complex API management and deployment tasks.
                            </p>
                        </div>

                        <div className="col-md-6 text-center">
                            <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-8 fl-z-index-2 position-relative ">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center">
                            <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>


                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">AI-Driven Code Enhancement</h3>
                            <p className="fl-text">
                                Designed to integrate effortlessly with AVAP’s suite of technologies, ensuring a smooth workflow throughout the development lifecycle. Developers benefit from a unified platform that leverages AVAP’s virtual programming capabilities, simplifying complex API management and deployment tasks.
                            </p>
                        </div>

                    </div>
                </section>



                <section className="container fl-z-index-2 position-relative mb-8">
                    <div className="row">
                        <div className="col">
                            <div className="fl-title text-center mb-5">
                                Key Benefits
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Faster Development Cycle
                                </p>
                                <p className="fl-text">
                                    With real-time code interpretation and BRUNIX AI-driven support, developers can quickly test, iterate, and deploy, reducing time to market.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Enhanced Code Quality
                                </p>
                                <p className="fl-text">
                                    The integration of AI assists in identifying bugs early, suggesting improvements, and enhancing overall code quality, ensuring reliability and maintainability.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Enhanced Code Quality
                                </p>
                                <p className="fl-text">
                                    Seamless compatibility with AVAP technologies and popular IDEs simplifies the development environment, ensuring smooth collaboration and boosting developer productivity.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* 
                <section className="container py-5 fl-z-index-2 position-relative">
                        <div className="row mb-5">
                       
                            <div className="col-md-6 ">
                                <img
                                    src={tablet}
                                    alt="Virtual API"
                                    className="img-fluid"
                                />
                                <p className="mt-3">
                                    <a href="#" className="text-primary">Watch video →</a>
                                </p>
                            </div>
                            <div className="col-md-3">
                                <h2 className="fw-bold">Key Benefits</h2>
                                <h3 className="fw-bold mb-5">Getting Started</h3>
                                <ul className="startedList">
                                    <li>
                                        <img src={user} className="mr-2" alt="" />Create your AVAP ID</li>
                                    <li>
                                        <img src={download} className="mr-2" alt="" />Download Avap MSVC</li>
                                </ul>
                                <h3 className="fw-bold mb-5">Resources</h3>
                                <ul className="startedList">
                                    <li><img src={user} className="mr-2" alt="" />Getting Started Guide</li>
                                </ul>
                                <a className="glowBtn-dark m-0" href="#"><span className="btnInner">  Developer Portal →</span></a>
                            </div>
                        </div>
                    </section> */}




                <section className="container py-5">
                    <div className="row mb-5">

                        <div className="col-md-6 ">
                            <div className="ytWrapper2">
                                <img src={frameTablet} alt="" className="frameTablet" />
                                <iframe className='fl-video-container fl-video-mobile' src="https://www.youtube.com/embed/LlVcKzcM3uc?si=RbgB1V_dJPcusm5y&amp;controls=0&rel=0" title="YouTube video player" frameBorder={0} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
                            </div>
                            {/*  <p className="mt-3">
                                <a href="https://www.youtube.com/watch?v=LlVcKzcM3uc" className="text-primary" target='_blank'>Watch video →</a>
                            </p> */}
                        </div>
                        <div className="col-md-3">
                            {/* <h2 className="fw-bold">Key Benefits</h2> */}
                            <h3 className="fw-bold mb-5">Getting Started</h3>
                            <ul className="startedList">
                                <li><a href="http://avapid.com" target='_blank'><img src={user} className="mr-2" alt="" />Create an AVAP ID</a></li>
                                <li>
                                    <a href="https://developer.avapframework.com/wiki/over_view12-GET STARTED_Downloads/0" target='_blank'>
                                        <img src={download} className="mr-2" alt="" />Download AVAP API Extensions at MSVC
                                    </a>

                                </li>
                            </ul>
                            {/* <a className="glowBtn-dark mt-0 mx-0" href="#">
                                <span className="btnInner">  Developer Portal →</span>
                            </a> */}
                        </div>
                        <div className="col-md-3">
                            {/* <h2 className="fw-bold">Key Benefits</h2> */}
                            <h3 className="fw-bold mb-5">Resources</h3>
                            <ul className="startedList">
                                <li>
                                    <a href="https://developer.avapframework.com" target='_blank'>
                                        <img src={user} className="mr-2" alt="" />Developer Portal
                                    </a>

                                </li>
                                <li>
                                    <a href="https://developer.avapframework.com/wiki/over_view12-GET STARTED_Downloads/0" target='_blank'>
                                        <img src={download} className="mr-2" alt="" />Getting Started Guide
                                    </a>

                                </li>
                            </ul>

                        </div>
                    </div>
                </section>

                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default VersionControlProduct;
