import React, { FunctionComponent, useEffect, useState } from 'react';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import { Row, Col, Modal, Image, Container } from 'react-bootstrap';
import ConsumptionChart from './GraphComponents/ConsumptionChart';
import Consumptions from './Consumption/Consumptions';
import CategoriesConsumption from './Consumption/CategoriesConsumption';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { useHistory } from 'react-router-dom';
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../mobileComponents/DisclaimerCloud';
import splasLogo from '../../../src/assets/img/ilustracion_splash.png';
import { transform } from '@babel/core';
import Cookies from 'universal-cookie';
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'

import WidgetLearn from '../../newViews/avapCloud/widgets/Learning'

type DashProps = {
  sider: any
};



const DashboardContent: FunctionComponent<DashProps> = ({ sider }) => {

  const history = useHistory();
  const [showSplash, setShowSplash] = useState(false);
  const [showSplash2, setShowSplash2] = useState(false);
  const [showSplash3, setShowSplash3] = useState(false);
  const [showSplash4, setShowSplash4] = useState(false);
  const [showSplash5, setShowSplash5] = useState(false);
  const [showSplash6, setShowSplash6] = useState(false);
  const [showSplash7, setShowSplash7] = useState(false);
  const [showSplash8, setShowSplash8] = useState(false);
  const [showSplash9, setShowSplash9] = useState(false);
  const [showSplash10, setShowSplash10] = useState(false);
  const [showSplash11, setShowSplash11] = useState(false);
  const [showSplash12, setShowSplash12] = useState(false);
  const [showSplash13, setShowSplash13] = useState(false);
  const [showSplash14, setShowSplash14] = useState(false);
  const [showSplash15, setShowSplash15] = useState(false);
  const [showSplash16, setShowSplash16] = useState(false);
  const [tutorialPref, setTutorialpref] = useState(false);
  const handleClose = () => {

    setShowSplash(false)

  }
  const handleClose2a = () => { setShowSplash2(false); SessionService.TUTORIAL_SHOWED = true; }

  const handleClose2 = () => {
    setShowSplash2(false);
    setShowSplash3(true); SessionService.TUTORIAL_SHOWED = true;
  }
  const handleShow = () => setShowSplash2(!showSplash2);

  const handleClose3 = () => { setShowSplash3(false); SessionService.TUTORIAL_SHOWED = true; }
  const handleShow2 = () => setShowSplash3(!showSplash3);

  const handleClose4 = () => { setShowSplash4(false); SessionService.TUTORIAL_SHOWED = true; }
  const handleShow3 = () => setShowSplash4(!showSplash4);

  const handleClose5 = () => { setShowSplash5(false); SessionService.TUTORIAL_SHOWED = true; }
  const handleShow4 = () => setShowSplash5(!showSplash5);

  const handleClose6 = () => { setShowSplash6(false); SessionService.TUTORIAL_SHOWED = true; }
  const handleShow5 = () => setShowSplash6(!showSplash6);

  const handleClose7 = () => { setShowSplash7(false); SessionService.TUTORIAL_SHOWED = true; }
  const handleShow6 = () => setShowSplash7(!showSplash7);

  const handleClose8 = () => { setShowSplash8(false); SessionService.TUTORIAL_SHOWED = true; }
  const handleShow7 = () => setShowSplash8(!showSplash8);

  const handleClose9 = () => { setShowSplash9(false); SessionService.TUTORIAL_SHOWED = true; }
  const handleShow8 = () => setShowSplash9(!showSplash9);

  const handleClose10 = () => { setShowSplash10(false); SessionService.TUTORIAL_SHOWED = true; }
  const handleShow9 = () => setShowSplash10(!showSplash10);

  const handleClose11 = () => { setShowSplash11(false); SessionService.TUTORIAL_SHOWED = true; }
  const handleShow10 = () => setShowSplash11(!showSplash11);

  const handleClose12 = () => { setShowSplash12(false); SessionService.TUTORIAL_SHOWED = true; }
  const handleShow11 = () => setShowSplash12(!showSplash12);

  const handleClose13 = () => { setShowSplash13(false); SessionService.TUTORIAL_SHOWED = true; }
  const handleShow12 = () => setShowSplash13(!showSplash13);

  const handleClose14 = () => { setShowSplash14(false); SessionService.TUTORIAL_SHOWED = true; }
  const handleShow13 = () => setShowSplash14(!showSplash14);

  const handleClose15 = () => { setShowSplash15(false); SessionService.TUTORIAL_SHOWED = true; }
  const handleShow14 = () => setShowSplash15(!showSplash15);


  const handleClose16 = () => { setShowSplash16(false); SessionService.TUTORIAL_SHOWED = true; }
  const handleShow15 = () => setShowSplash16(!showSplash16);


  const startTutorial = () => {


    handleClose();
    handleShow();
  }

  if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
    ReactGA.initialize('G-37ZYHDM38N');
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Developer Cloud' });
  }


  const updateCookies = (e) => {
    console.log(e.target.checked);

    setTutorialpref(!e.target.checked)
    const cookies = new Cookies();
    cookies.set('tutorial', !e.target.checked, { path: '/' });

    console.log(cookies.get('tutorial')); // Pacman


  }

  const [showAlert, setShowAlert] = useState(true); // Estado para controlar la visibilidad del alert

  const closeAlert = () => setShowAlert(false); // Función para cerrar el alert

  /*
const getVerified = async () => {
    SessionService.RENOVATEDSUSCRIPTION =true;
    const result = await ObexRequestHandler.get('/check_renovated_suscription');
    
    const { success, data } = result;
    if (success) {
      const { renovated } = data;
      
      const wast = document.getElementById('nopayment') as HTMLDivElement;
      if (!renovated) {
        wast.style.display = 'block';
        SessionService.RENOVATEDSUSCRIPTION = renovated;
      }
    }
}
*/
  useEffect(() => {
    const cookies = new Cookies();
    let TutorialPreference = cookies.get('tutorial');
    if (TutorialPreference == undefined) TutorialPreference = 'true';
    const tuto_pref = (String(TutorialPreference).toLowerCase() === 'true');


    setTutorialpref(tuto_pref);
    //getVerified();
    if (SessionService.TUTORIAL_SHOWED == false) {
      setShowSplash(true);
      SessionService.TUTORIAL_SHOWED = true;
    }
  }, []);

  return (
    <>
      <BrowserView>

        <DashLayout sider={sider}>

          {showAlert && (
            <div id="alertNoPay" style={{ backgroundColor: '#ffcccc', borderRadius: '5px', display: 'none' }}>
              You have pending charges, and your subscription will be suspended.
              <a href='/plan_subcription#nopayment2' style={{ color: '#d9534f', cursor: 'pointer', textDecoration: 'underline' }}>
                please click here to make the payment
              </a>
              <button onClick={closeAlert} style={{
                position: 'absolute',
                top: '5px',
                right: '10px',
                backgroundColor: 'transparent',
                border: 'none',
                fontSize: '16px',
                cursor: 'pointer',
                color: '#d9534f'
              }}>×</button>
            </div>
          )}
        <Row className="">
          <Col lg={12} md={12}  style={{minWidth:'calc(100% + 13px)'}} >
          <Consumptions />
          </Col>
        {/*}  <Row style={{width: 'calc(100% + 17px)'}}> */}
            <Col lg={9} md={12} className="" style={{paddingRight:'0px', marginTop:'-13px'}}>
              <div className="cld_CardWrapper mb-4" >
                <ConsumptionChart />
              </div>
            </Col>
            <Col lg={3} md={12} style={{ marginLeft:'-0.3vw', marginTop:'-0.7vh' }}>
              <WidgetLearn></WidgetLearn>
            </Col>
         {/*}   </Row> */}
        </Row>


          {/*}   <CategoriesConsumption/> {*/}

          <Modal className='tip1'
            show={showSplash2}
            animation={false}
            onHide={handleClose2a}

          >
            <Modal.Body className='tipsBorder'
              style={{
                backgroundColor: '#4472b6',
                color: 'white'
              }}
            >
              <div style={{ height: '180px' }}>
                <div style={{ fontSize: '18px' }} className='textBold mb-3'>Organizations</div>
                <div style={{ fontSize: '12px' }}>
                  Organizations allow you to group developers and projects within the same operational scope. Multiple organizations can be present in a deployment, but information cannot be exchanged between them
                </div>
                <button onClick={() => { handleClose2a() }} style={{ marginLeft: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, left: 0 }} className='custom-button-blue'>Skip</button>
                <button onClick={() => { handleClose2() }} style={{ marginRight: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, right: 0 }} className='custom-button-blue'>Got it</button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal className='tip2'
            show={showSplash3}
            animation={false}
            onHide={handleClose3}

          >
            <Modal.Body className='tipsBorder'
              style={{
                backgroundColor: '#4472b6',
                color: 'white'
              }}
            >
              <div style={{ height: '180px' }}>
                <div style={{ fontSize: '18px' }} className='textBold mb-3'>Developers</div>
                <div style={{ fontSize: '12px' }}>
                  Displays information about the maximum number of available slots in your membership, the slots contracted, and the slots in use. The account owner automatically consumes one developer slot.
                </div>
                <button onClick={() => { handleClose3() }} style={{ marginLeft: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, left: 0 }} className='custom-button-blue'>Skip</button>
                <button onClick={() => { handleClose3(); setShowSplash4(true) }} style={{ marginRight: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, right: 0 }} className='custom-button-blue'>Got it</button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal className='tip3'
            show={showSplash4}
            animation={false}
            onHide={handleClose4}

          >
            <Modal.Body className='tipsBorder'
              style={{
                backgroundColor: '#4472b6',
                color: 'white'
              }}
            >
              <div style={{ height: '180px' }}>
                <div style={{ fontSize: '18px' }} className='textBold mb-3'>API Projects</div>
                <div style={{ fontSize: '12px' }}>
                  Shows information about the maximum capacity of API Projects in your membership, the available slots, and those used.
                </div>
                <button onClick={() => { handleClose4() }} style={{ marginLeft: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, left: 0 }} className='custom-button-blue'>Skip</button>
                <button onClick={() => { handleClose4(); setShowSplash5(true) }} style={{ marginRight: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, right: 0 }} className='custom-button-blue'>Got it</button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal className='tip4'
            show={showSplash5}
            animation={false}
            onHide={handleClose5}

          >
            <Modal.Body className='tipsBorder'
              style={{
                backgroundColor: '#4472b6',
                color: 'white'
              }}
            >
              <div style={{ height: '180px' }}>
                <div style={{ fontSize: '18px' }} className='textBold mb-3'>Managed APIs</div>
                <div style={{ fontSize: '12px' }}>
                  Helps you keep track of the maximum regarding managed APIs, as well as the available slots for API creation and the ones currently in use. An API consumes one managed API slot.
                </div>
                <button onClick={() => { handleClose5() }} style={{ marginLeft: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, left: 0 }} className='custom-button-blue'>Skip</button>
                <button onClick={() => { handleClose5(); setShowSplash6(true) }} style={{ marginRight: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, right: 0 }} className='custom-button-blue'>Got it</button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal className='tip5'
            show={showSplash6}
            animation={false}
            onHide={handleClose6}

          >
            <Modal.Body className='tipsBorder'
              style={{
                backgroundColor: '#4472b6',
                color: 'white'
              }}
            >
              <div style={{ height: '180px' }}>
                <div style={{ fontSize: '18px' }} className='textBold mb-3'>Requests</div>
                <div style={{ fontSize: '12px' }}>
                  Each API call consumes a request, and this section of the Dashboard allows you to monitor the maximum allowed requests per month according to your membership, the requests contracted, and the consumption to date.
                </div>
                <button onClick={() => { handleClose6() }} style={{ marginLeft: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, left: 0 }} className='custom-button-blue'>Skip</button>
                <button onClick={() => { handleClose6(); setShowSplash7(true) }} style={{ marginRight: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, right: 0 }} className='custom-button-blue'>Got it</button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal className='tip6'
            show={showSplash7}
            animation={false}
            onHide={handleClose7}

          >
            <Modal.Body className='tipsBorder'
              style={{
                backgroundColor: '#4472b6',
                color: 'white'
              }}
            >
              <div style={{ height: '180px' }}>
                <div style={{ fontSize: '18px' }} className='textBold mb-3'>Storage</div>
                <div style={{ fontSize: '12px' }}>
                  Manages the storage of your account. Here you can find the maximum size allowed for your database, the contracted storage, and real-time usage.
                </div>
                <button onClick={() => { handleClose7() }} style={{ marginLeft: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, left: 0 }} className='custom-button-blue'>Skip</button>
                <button onClick={() => { handleClose7(); setShowSplash8(true) }} style={{ marginRight: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, right: 0 }} className='custom-button-blue'>Got it</button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal className='tip7'
            show={showSplash8}
            animation={false}
            onHide={handleClose8}

          >
            <Modal.Body className='tipsBorder'
              style={{
                backgroundColor: '#4472b6',
                color: 'white'
              }}
            >
              <div style={{ height: '180px' }}>
                <div style={{ fontSize: '18px' }} className='textBold mb-3'>Organizations</div>
                <div style={{ fontSize: '12px' }}>
                  In addition to creating new organizations, you can search for and modify information related to them by clicking the edit button that appears to the right of each entry.

                </div>
                <button onClick={() => { handleClose8() }} style={{ marginLeft: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, left: 0 }} className='custom-button-blue'>Skip</button>
                <button onClick={() => { handleClose8(); setShowSplash9(true) }} style={{ marginRight: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, right: 0 }} className='custom-button-blue'>Got it</button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal className='tip8'
            show={showSplash9}
            animation={false}
            onHide={handleClose9}

          >
            <Modal.Body className='tipsBorder'
              style={{
                backgroundColor: '#4472b6',
                color: 'white'
              }}
            >
              <div style={{ height: '180px' }}>
                <div style={{ fontSize: '18px' }} className='textBold mb-3'>Developers</div>
                <div style={{ fontSize: '12px' }}>
                  Invite new developers to your organization and work with your team on developing your API Products. Only active developers consume a developer slot.
                </div>
                <button onClick={() => { handleClose9() }} style={{ marginLeft: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, left: 0 }} className='custom-button-blue'>Skip</button>
                <button onClick={() => { handleClose9(); setShowSplash10(true) }} style={{ marginRight: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, right: 0 }} className='custom-button-blue'>Got it</button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal className='tip9'
            show={showSplash10}
            animation={false}
            onHide={handleClose10}

          >
            <Modal.Body className='tipsBorder'
              style={{
                backgroundColor: '#4472b6',
                color: 'white'
              }}
            >
              <div style={{ height: '180px' }}>
                <div style={{ fontSize: '18px' }} className='textBold mb-3'>Developer Teams</div>
                <div style={{ fontSize: '12px' }}>
                  Group your developers into development teams to optimize the management of your work teams.
                </div>
                <button onClick={() => { handleClose10() }} style={{ marginLeft: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, left: 0 }} className='custom-button-blue'>Skip</button>
                <button onClick={() => { handleClose10(); setShowSplash11(true) }} style={{ marginRight: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, right: 0 }} className='custom-button-blue'>Got it</button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal className='tip10'
            show={showSplash11}
            animation={false}
            onHide={handleClose11}

          >
            <Modal.Body className='tipsBorder'
              style={{
                backgroundColor: '#4472b6',
                color: 'white'
              }}
            >
              <div style={{ height: '180px' }}>
                <div style={{ fontSize: '18px' }} className='textBold mb-3'>API Projects</div>
                <div style={{ fontSize: '12px' }}>
                  Organize your API development projects in API Projects, create your API Products, and assign the APIs that make up those products.
                </div>
                <button onClick={() => { handleClose11() }} style={{ marginLeft: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, left: 0 }} className='custom-button-blue'>Skip</button>
                <button onClick={() => { handleClose11(); setShowSplash12(true) }} style={{ marginRight: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, right: 0 }} className='custom-button-blue'>Got it</button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal className='tip11'
            show={showSplash12}
            animation={false}
            onHide={handleClose12}

          >
            <Modal.Body className='tipsBorder'
              style={{
                backgroundColor: '#4472b6',
                color: 'white'
              }}
            >
              <div style={{ height: '180px' }}>
                <div style={{ fontSize: '18px' }} className='textBold mb-3'>Subscription</div>
                <div style={{ fontSize: '12px' }}>
                  Keep track of your subscription and the resources contracted. Configure the payment model that best suits your needs and cancel any unused resources at any time.
                </div>
                <button onClick={() => { handleClose12() }} style={{ marginLeft: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, left: 0 }} className='custom-button-blue'>Skip</button>
                <button onClick={() => { handleClose12(); setShowSplash13(true) }} style={{ marginRight: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, right: 0 }} className='custom-button-blue'>Got it</button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal className='tip12'
            show={showSplash13}
            animation={false}
            onHide={handleClose13}

          >
            <Modal.Body className='tipsBorder'
              style={{
                backgroundColor: '#4472b6',
                color: 'white'
              }}
            >
              <div style={{ height: '180px' }}>
                <div style={{ fontSize: '18px' }} className='textBold mb-3'>Upgrade Plan</div>
                <div style={{ fontSize: '12px' }}>
                  Upgrade your membership and switch to Business or Enterprise based on your needs. Obtain new resources without service downtime.
                </div>
                <button onClick={() => { handleClose13() }} style={{ marginLeft: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, left: 0 }} className='custom-button-blue'>Skip</button>
                <button onClick={() => { handleClose13(); setShowSplash14(true) }} style={{ marginRight: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, right: 0 }} className='custom-button-blue'>Got it</button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal className='tip13'
            show={showSplash14}
            animation={false}
            onHide={handleClose14}

          >
            <Modal.Body className='tipsBorder'
              style={{
                backgroundColor: '#4472b6',
                color: 'white'
              }}
            >
              <div style={{ height: '180px' }}>
                <div style={{ fontSize: '18px' }} className='textBold mb-3'>Billing</div>
                <div style={{ fontSize: '12px' }}>
                  At any time, you can view the invoices generated for your membership to keep track of expenses. This includes not only your membership but also all contracted resources.
                </div>
                <button onClick={() => { handleClose14() }} style={{ marginLeft: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, left: 0 }} className='custom-button-blue'>Skip</button>
                <button onClick={() => { handleClose14(); setShowSplash15(true) }} style={{ marginRight: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, right: 0 }} className='custom-button-blue'>Got it</button>
              </div>
            </Modal.Body>
          </Modal>

          <Modal className='tip14'
            show={showSplash15}
            animation={false}
            onHide={handleClose15}

          >
            <Modal.Body className='tipsBorder'
              style={{
                backgroundColor: '#4472b6',
                color: 'white'
              }}
            >
              <div style={{ height: '180px' }}>
                <div style={{ fontSize: '18px' }} className='textBold mb-3'>Tutorial</div>
                <div style={{ fontSize: '12px' }}>
                  Launch this tutorial at any time. It allows you to access the tutorial and stay updated with any changes. Remember that this tutorial is dynamic and constantly updated!.
                </div>
                <button onClick={() => { handleClose15() }} style={{ marginLeft: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, left: 0 }} className='custom-button-blue'>Skip</button>
                <button onClick={() => { handleClose15(); setShowSplash16(true) }} style={{ marginRight: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, right: 0 }} className='custom-button-blue'>Got it</button>
              </div>
            </Modal.Body>
          </Modal>


          <Modal className='tip15 '
            show={showSplash16}
            animation={false}
            onHide={handleClose16}

          >
            <Modal.Body className='tipsBorder'
              style={{
                backgroundColor: '#4472b6',
                color: 'white'
              }}
            >
              <div style={{ height: '180px' }}>
                <div style={{ fontSize: '18px' }} className='textBold mb-3'>Sign out </div>
                <div style={{ fontSize: '12px' }}>
                  Log out of your session to avoid security issues. It is a good practice to sign out if you move to a different location or remain inactive for an extended period.
                </div>

                <button onClick={() => { handleClose16() }} style={{ marginRight: '10px', marginBottom: '10px', position: 'absolute', bottom: 0, right: 0 }} className='custom-button-blue'>Got it</button>
              </div>
            </Modal.Body>
          </Modal>


          <Modal dialogClassName="themeDialog addons-dialog w-40 obex-dialog" style={{ with: '40%' }} container={document.getElementById('dashboardWrapper')}
            show={showSplash}
            onHide={handleClose}

            animation={false}>
            <Modal.Header closeButton className="border-0 pb-0">
              <div style={{ width: '100%', textAlign: 'center' }}>
                <Image
                  style={{
                    paddingTop: '3em',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '30%'
                  }}
                  src={splasLogo}>

                </Image>

                <div className='' style={{ marginTop: '3rem' }}>
                  <h3 className='textBold'>Welcome to AVAP Cloud</h3>
                </div>
                <div style={{ marginTop: '-10px', marginBottom: '2rem' }}>
                  Let's find out how we can help you, in less than a minute
                </div>
                <div>
                  <button onClick={startTutorial} className='customButton-blue'>Get Started!</button>
                </div>
                <div style={{ display: 'flex', marginTop: '2rem' }}>


                  <div style={{ fontSize: '10px', marginLeft: 'auto', marginRight: 'auto' }}>

                    <input checked={!tutorialPref} style={{ marginRight: '10px' }} type="checkbox" onChange={updateCookies} />
                    Don't show again this window</div>

                </div>
              </div>
            </Modal.Header>
            <Modal.Body>

            </Modal.Body>
          </Modal>

        </DashLayout>
      </BrowserView>
      <MobileView>
        <Disclaimer />
      </MobileView>
    </>
  )
}

export default DashboardContent;