import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';

const Carga_masiva_de_saldo_operador_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": true, 
          "result": <string>,
           "elapsed": <float> 
          }`,
        copied: false
      },
      
        answe_ko1: {
          title:"Answer ok",
          type: "json",
          code: `{
            "status": false,
           "level": <string>,
            "message": <string>,
             "error": <string> 
            }`,
          copied: false
        }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/util.py/carga_masiva_ctm" 
        payload = { 'method': 'operator', 'id': 'trial', 'environment': 'trial' }
         files = [] 
         headers= {} 
         response = requests.request("POST", url, headers=headers, data = payload, files = files) 
         print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests url = "URL_BASE/ws/util.py/carga_masiva_ctm" 
          payload = { 'method': 'operator', 'id': 'trial', 'environment': 'trial' }
           files = [] 
           headers= {} 
           response = requests.request("POST", url, headers=headers, data = payload, files = files) 
           print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'POST',
              'url': 'URL_BASE/ws/util.py/carga_masiva_ctm',
              'headers': {},
              formData: {
              'method': 'operator',
              'id': 'trial',
              'environment': 'trial'
              }
              };
              request(options, function (error, response) {
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var formdata = new FormData();
              formdata.append("method", "operator");
              formdata.append("id", "trial");
              formdata.append("environment", "trial");
              var requestOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/util.py/carga_masiva_ctm", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request POST 'URL_BASE/ws/util.py/carga_masiva_ctm' \ --form 'method=operator' \ --form 'id=trial' \ --form 'environment=trial'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="balance-massive-charge">Balance Massive Charge</h1>
        <p>
          This service is used to carry out a balance upload through a file uploaded
          to an SFTP.
        </p>
        <p>
          <span className="label post">POST:</span>
          <span> </span> <code>URL_BASE + /ws/util.py/carga_masiva_ctm</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request
        </p>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">method</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                It must contain the value <code>operator</code>
              </td>
              <td align="center">N/A</td>
              <td align="center">operator</td>
            </tr>
            <tr>
              <td align="left">id</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">File name uploaded to SFTP</td>
              <td align="center">N/A</td>
              <td align="center">Test</td>
            </tr>
            <tr>
              <td align="left">environment</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                Name of the environment we are in, and that has bulk loading enabled
              </td>
              <td align="center">N/A</td>
              <td align="center">Test</td>
            </tr>
          </tbody>
        </table>
        <h2 id="retuns">Retuns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>result:</code> Executed service answer
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>level:</code> Error importance level.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code> Error message.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          This endpoint generates an email with which the bulk upload is approved or
          denied.
        </p>
        </div>

<div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
      </div>
      </Fragment>
    );
  }
export default Carga_masiva_de_saldo_operador_EN;
