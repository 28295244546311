import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';

const CreateServiceForm = () => {
    const [formData, setFormData] = useState({
        displayName: '',
        name: '',
        description: '',
        labels: [{ key: '', value: '' }],
        costCenter: '',
        dashboardName: '',
        dashboardLink: '',
        gitRepoName: '',
        gitRepoLink: '',
        jiraProjectName: '',
        jiraProjectLink: '',
        owner: '',
        productManager: '',
        slackChannelName: '',
        slackChannelLink: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleAddField = (field) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: [...prevData[field], { key: '', value: '' }],
        }));
    };

    const handleFieldChange = (e, index, field) => {
        const { name, value } = e.target;
        const updatedField = [...formData[field]];
        updatedField[index][name] = value;
        setFormData((prevData) => ({ ...prevData, [field]: updatedField }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Service Data Submitted:", formData);
    };

    const handleCancel = () => {
        setFormData({
            displayName: '',
            name: '',
            description: '',
            labels: [{ key: '', value: '' }],
            costCenter: '',
            dashboardName: '',
            dashboardLink: '',
            gitRepoName: '',
            gitRepoLink: '',
            jiraProjectName: '',
            jiraProjectLink: '',
            owner: '',
            productManager: '',
            slackChannelName: '',
            slackChannelLink: '',
        });
    };

    return (
        <Form className="cloudForm" onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
            <h2>Create a Service</h2>
            <p>Provide a display name, name and description to identify this service.</p>

            {/* General Information */}
            <Form.Group controlId="displayName">
                <Form.Label>Display Name</Form.Label>
                <Form.Control
                    type="text"
                    name="displayName"
                    value={formData.displayName}
                    onChange={handleChange}
                    placeholder="Display name of the service"
                    required
                />
            </Form.Group>

            <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Machine-readable name of the service"
                    required
                />
                <Form.Text className="text-muted">
                    Use only lowercase letters, numbers, hyphens, and periods (e.g., my-service-1.0)
                </Form.Text>
            </Form.Group>

            <Form.Group controlId="description">
                <Form.Label>Description</Form.Label>
                <Form.Control
                    as="textarea"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Describe the service"
                />
            </Form.Group>

            {/* Labels Section */}
            <h3>Labels</h3>
            <p>Labels are key:value pairs which are attributes associated to core entities.</p>
            {formData.labels.map((label, index) => (
                <Row key={index}>
                    <Col>
                        <Form.Group controlId={`label-key-${index}`}>
                            <Form.Label>Label Key</Form.Label>
                            <Form.Control
                                type="text"
                                name="key"
                                value={label.key}
                                onChange={(e) => handleFieldChange(e, index, 'labels')}
                                placeholder="Enter a key"
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId={`label-value-${index}`}>
                            <Form.Label>Label Value</Form.Label>
                            <Form.Control
                                type="text"
                                name="value"
                                value={label.value}
                                onChange={(e) => handleFieldChange(e, index, 'labels')}
                                placeholder="Enter a value"
                            />
                        </Form.Group>
                    </Col>
                </Row>
            ))}
            <Button variant="outline-primary" onClick={() => handleAddField('labels')}>
                Add Label
            </Button>

            {/* Metadata Section */}
            <h3>Metadata</h3>
            <p>Information to help your organization identify owners and communication channels for this service.</p>

            <Form.Group controlId="costCenter">
                <Form.Label>Cost Center</Form.Label>
                <Form.Control
                    type="text"
                    name="costCenter"
                    value={formData.costCenter}
                    onChange={handleChange}
                    placeholder="The service's cost center name"
                />
            </Form.Group>

            <Form.Group controlId="dashboard">
                <Form.Label>Dashboard</Form.Label>
                <Form.Control
                    type="text"
                    name="dashboardName"
                    value={formData.dashboardName}
                    onChange={handleChange}
                    placeholder="Dashboard URL name"
                />
                <Form.Control
                    type="url"
                    name="dashboardLink"
                    value={formData.dashboardLink}
                    onChange={handleChange}
                    placeholder="Dashboard URL link"
                />
            </Form.Group>

            <Form.Group controlId="gitRepo">
                <Form.Label>Git Repo</Form.Label>
                <Form.Control
                    type="text"
                    name="gitRepoName"
                    value={formData.gitRepoName}
                    onChange={handleChange}
                    placeholder="Git repository URL name"
                />
                <Form.Control
                    type="url"
                    name="gitRepoLink"
                    value={formData.gitRepoLink}
                    onChange={handleChange}
                    placeholder="Git repository URL link"
                />
            </Form.Group>

            <Form.Group controlId="jiraProject">
                <Form.Label>Jira Project</Form.Label>
                <Form.Control
                    type="text"
                    name="jiraProjectName"
                    value={formData.jiraProjectName}
                    onChange={handleChange}
                    placeholder="Jira project URL name"
                />
                <Form.Control
                    type="url"
                    name="jiraProjectLink"
                    value={formData.jiraProjectLink}
                    onChange={handleChange}
                    placeholder="Jira project URL link"
                />
            </Form.Group>

            <Form.Group controlId="owner">
                <Form.Label>Owner</Form.Label>
                <Form.Control
                    type="text"
                    name="owner"
                    value={formData.owner}
                    onChange={handleChange}
                    placeholder="Service owner (team or user)"
                />
            </Form.Group>

            <Form.Group controlId="productManager">
                <Form.Label>Product Manager</Form.Label>
                <Form.Control
                    type="text"
                    name="productManager"
                    value={formData.productManager}
                    onChange={handleChange}
                    placeholder="Product manager of the service"
                />
            </Form.Group>

            <Form.Group controlId="slackChannel">
                <Form.Label>Slack Channel</Form.Label>
                <Form.Control
                    type="text"
                    name="slackChannelName"
                    value={formData.slackChannelName}
                    onChange={handleChange}
                    placeholder="Slack channel URL name"
                />
                <Form.Control
                    type="url"
                    name="slackChannelLink"
                    value={formData.slackChannelLink}
                    onChange={handleChange}
                    placeholder="Slack channel URL link"
                />
            </Form.Group>

            {/* Fixed Buttons */}
            <div className="d-flex justify-content-between mt-4">
                <Button variant="primary" type="submit">Save Service</Button>
                <Button variant="outline-secondary" onClick={handleCancel}>Cancel</Button>
            </div>
        </Form>
    );
};

export default CreateServiceForm;
