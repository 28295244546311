import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';

const Get_pools = () => 
  {
    const codes ={
      Retun: {
        title:"Answer ok",
        type: "json",
        code: `{
           "status": true, 
           "result": [
             { 
              "network_id": 1, 
              "pools": [], 
              "network": 
              "Ethereum Mainnet" 
            }, 
            {
               "network_id": 2, 
               "pools": [], 
               "network": "Ethereum Ropsten Testnet" 
              }, 
              { 
                "network_id": 3, 
                "pools": [], 
                "network": 
                "Ethereum Kovan Testnet" 
              }, 
              {
                 "network_id": 4, 
                 "pools": [
                   { 
                    "maximum-comission": 70.0, 
                    "max-liq": 244.0, 
                    "average-percent-per-stalker": 100.0, 
                    "percent-of-max": 100.0, 
                    "total": 244.0, 
                    "percent-maximum-comission": 5.0, 
                    "CurrencyID": 415, "stakers": 1, 
                    "percent": 100.0, 
                    "Currency": "DAI", 
                    "liquidity": 244.0 
                  }, 
                  { 
                    "maximum-comission": 37.5, 
                    "max-liq": 0.051000000000000024, 
                    "average-percent-per-stalker": 50.0,
                    "percent-of-max": 100.0, 
                    "total": 0.060000000000000026, 
                    "percent-maximum-comission": 5.0, 
                    "CurrencyID": 414, "stakers": 2, 
                    "percent": 85.0, "Currency": 
                    "ETH", 
                    "liquidity": 0.051000000000000024 
                  } ], 
                  "network": "Ethereum Rinkeby Testnet" 
                }, 
                { 
                  "network_id": 5, 
                  "pools": [], 
                  "network": "Ethereum Goerly Testnet" 
                }, 
                { 
                  "network_id": 6, 
                  "pools": [], 
                  "network": "Binance Mainnet" 
                }, 
                { 
                  "network_id": 7, 
                  "pools": [
                     { 
                      "maximum-comission": 37.5, 
                      "max-liq": 0.051000000000000024, 
                      "average-percent-per-stalker": 50.0, 
                      "percent-of-max": 17.647058823529406, 
                      "total": 0.060000000000000026, 
                      "percent-maximum-comission": 31.764705882352942, 
                      "CurrencyID": 414, "stakers": 2, 
                      "percent": 14.999999999999996, 
                      "Currency": "ETH", "liquidity": 0.009000000000000001 
                    } ], "network": "Binance Testnet" } ], 
                    "elapsed": 1.6908998489379883}
        `,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": false, 
          "level": <string>, 
          "message": <string>, 
          "error": <string> 
        }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requestsurl = "http://34.121.95.179:80/ws/util.py/get_pools?country_code=ES"
        payload={}
        headers = { '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'}
        response = requests.request("GET", url, headers=headers, data=payload)
        print(response.text)`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requestsurl = "http://34.121.95.179:80/ws/util.py/get_pools?country_code=ES"
          payload={}
          headers = { '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'}
          response = requests.request("GET", url, headers=headers, data=payload)
          print(response.text)`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
               'method': 'GET',
               'url': 'http://34.121.95.179:80/ws/util.py/get_pools?country_code=ES',
               'headers': {
               '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'
               }
              };
              request(options, function (error, response) {
               if (error) throw new Error(error);
               console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var myHeaders = new Headers();
              myHeaders.append("101ObexApiKey", "KcM6Vr7EfNnKScEi00jdritBzNjWAOkz");
              
              var requestOptions = {
               method: 'GET',
               headers: myHeaders,
               redirect: 'follow'
              };
              
              fetch("http://34.121.95.179:80/ws/util.py/get_pools?country_code=ES", requestOptions)
               .then(response => response.text())
               .then(result => console.log(result))
               .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request GET 'http://34.121.95.179:80/ws/util.py/get_pools?country_code=ES' \--header '101ObexApiKey: KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="obtain-inter-network-pools">Obtain Inter Network Pools</h1>
        <h2 id="wsuserspyget_pools">/ws/users.py/get_pools</h2>
        <h3 id="receives">Receives</h3>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <table>
          <thead>
            <tr>
              <th align="center">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">country_code</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center">ES</td>
            </tr>
            <tr>
              <td align="center">network</td>
              <td align="center">Int</td>
              <td align="center">Yes</td>
              <td align="center">Network identifier to filter</td>
              <td align="center">N/A</td>
              <td align="center">4</td>
            </tr>
            <tr>
              <td align="center">currency</td>
              <td align="center">Int</td>
              <td align="center">Yes</td>
              <td align="center">Token identifier to filter</td>
              <td align="center">N/A</td>
              <td align="center">414</td>
            </tr>
          </tbody>
        </table>
        <h2 id="returns-if-not-filter-set">Returns if not filter set:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.Retun}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>result:</code> transaction code for the stake operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>level:</code> Error importance level.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code> Error message.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>error:</code> Sole error code.
          </li>
          <h2 id="business-logic">Business logic:</h2>
        <p>
          The purpose of this service is to recover stake pools in order to make
          transfers between networks.The purpose of this service is to recover stake
          pools in order to make transfers between networks.
        </p>
        </ul>
        </div>
        <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
        </div>
      </Fragment>
    );
  }
export default Get_pools;
