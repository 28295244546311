import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const OTP_se = () => {
  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status":"<boolean>",
        "amount":"<float>"
     }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
    url = "URL_BASE/ws/util.py/send_otp"
    payload = {'country_code': 'MX',
    'user_id': '4532',
    'phone_number': '7229063245',
    'signature': '277b62946d7ed08cf23a5613e3becc4711322abd'}
    files = []
    headers= {}
    response = requests.request("POST", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
    url = "URL_BASE/ws/util.py/send_otp"
    payload = {'country_code': 'MX',
    'user_id': '4532',
    'phone_number': '7229063245',
    'signature': '277b62946d7ed08cf23a5613e3becc4711322abd'}
    files = []
    headers= {}
    response = requests.request("POST", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'POST',
  'url': 'URL_BASE/ws/util.py/send_otp',
  'headers': {},
  formData: {
  'country_code': 'MX',
  'user_id': '4532',
  'phone_number': '7229063245',
  'signature': '277b62946d7ed08cf23a5613e3becc4711322abd'
  }
  };
  request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
  });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var formdata = new FormData();
  formdata.append("country_code", "MX");
  formdata.append("user_id", "4532");
  formdata.append("phone_number", "7229063245");
  formdata.append("signature", "277b62946d7ed08cf23a5613e3becc4711322abd");
  var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
  };
  fetch("URL_BASE/ws/util.py/send_otp", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request POST 'URL_BASE/ws/util.py/send_otp'
  --form 'country_code=MX' 
  --form 'user_id=4532' 
  --form 'phone_number=7229063245' 
  --form 'signature=277b62946d7ed08cf23a5613e3becc4711322abd'`
      }
    ]
  }

  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>

          <h1 >OTP &#40;sent externally&#41;</h1>
          <p>
            This service is used to send an OTP to an external phone on the platform.
          </p>
          <p>
            <span className="label post">POST:</span>
            <span> </span> <code>URL_BASE + /ws/util.py/send_otp</code>
          </p>
          <h2 >Receives:</h2>
          <p>
            All the parameters that the service receives must be indicated in the body
            of the request.
          </p>
          <table>
            <thead>
              <tr>
                <th align="left">Parameter</th>
                <th align="center">Format</th>
                <th align="center">Obligatory</th>
                <th align="center">Description</th>
                <th align="center">Default value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="left">country_code</td>
                <td align="center">
                  <a href="https://www.iso.org/glossary-for-iso-3166.html">
                    ISO 3166 alpha-2
                  </a>
                </td>
                <td align="center">Yes</td>
                <td align="center">Country code in which the user resides</td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">user_id</td>
                <td align="center">Integer</td>
                <td align="center">Yes</td>
                <td align="center">
                  Identifier of the user making the request &#40;Admin or
                  Affiliate&#41;
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">session_id</td>
                <td align="center">String</td>
                <td align="center">
                  Yes &#40;if you do not send signature to &#39;Access
                  Service&#39;&#41;
                </td>
                <td align="center">
                  Obtained by identifying the user with ID user_id on the platform
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">signature</td>
                <td align="center">String</td>
                <td align="center">
                  Yes &#40;if you do not send session_id to &#39;Access
                  Service&#39;&#41;
                </td>
                <td align="center">
                  Enables you to sign the request without having to log in
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">phone_numer</td>
                <td align="center">String</td>
                <td align="center">Yes</td>
                <td align="center">
                  Telephone number to which we want to send the OTP
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">message</td>
                <td align="center">String</td>
                <td align="center">No</td>
                <td align="center">
                  SMS text that will be sent with the OTP, it must have the OTP mask
                  &#40;&#123;otp&#125; or% &#40;otp&#41; s&#41;, if it is not
                  notified, the default text will be used
                </td>
                <td align="center">Your OTP is: &#123;otp&#125;</td>
              </tr>
            </tbody>
          </table>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>
          <h3 >Answer JSON OK:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2 >Where:</h2>
          <ul>
            <li>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li>
              <code>amount:</code> Amount of the operation with the applied format.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Business logic:</h2>
          <p>
            This service must be executed by a user who has an administrator profile.
            An OTP is sent to the phone indicated with the default message, or the one
            entered in the request.
          </p>

        </div>
        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>

      </div>

    </Fragment>
  );
}
export default OTP_se;
