import React, { Fragment } from 'react';
import './chapter.css'
const Chapter10_3 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>6.2 Functioning of the IF-THEN-ELSE Statement</h2>
      <p>
        The IF-THEN-ELSE statement evaluates the given condition and, if it is true, executes the block of code within the IF(). If the condition is false, it executes the block of code within the ELSE().
      </p>
      <p>Below is the description of each part of the IF-THEN-ELSE statement using the provided example:</p>
      <pre>
        <code>
          {`// IF, ELSE and END Sample Use
            addVar(selector,'yes')
            IF(selector,'yes','=')
            addVar(result,1) ELSE()
            addVar(result,0) END()
            addResult(result)`}
        </code>
      </pre>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>The variable <code>selector</code> is initialized with the value 'yes'.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          The statement <code>IF(selector,'yes','=')</code> evaluates whether the value of <code>selector</code> is equal to 'yes'. In this case, the condition is true.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Inside the IF() block, <code>addVar(result,1)</code> is executed, which assigns the value 1 to the <code>result</code> variable.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Since the condition of the IF() is true, the code block inside the ELSE() is not executed.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>The statement <code>addResult(result)</code> adds the value of the <code>result</code> variable to the API result.</li>
      </ul>
    </div>
  </Fragment>
);

export default Chapter10_3;
