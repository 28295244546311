import { Container, Row, Col, Alert, Button } from 'react-bootstrap';
import React, { FunctionComponent, useEffect } from 'react';
import '../../../assets/css/baseLayout.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HeaderMenu from './HeaderMenu';
import ApiSideBar from './ApiSideBar';
import Footer from '../FooterLayout/FooterMenu';
import Drawer from '../../../components/DashboardComponents/Drawer';
import CookieMessage from '../../Modals/CookieMessage';
import { useState } from 'react';
import ReactGA from 'react-ga4';
import Terminal from 'terminal-in-react';
import SessionService from '../../../services/SessionsService';
import FooterMenu from '../DashboardLayout/FooterMenuAccess';

//form
import CreatAppForm from './forms/CreateApp'

// import HeaderMenu from '../baseLayout/HeaderMenu';
import DashboardSideBar from '../../functionalComponents/DashboardLayout/DashboardSidebar';

//import pseudoFileSystem from 'terminal-in-react-pseudo-file-system-plugin';
//const FileSystemPlugin = pseudoFileSystem(); 
//import NodeEvalPlugin from 'terminal-in-react-node-eval-plugin';

type BaseProps = {
  sider: any,
  active?: string,
  children: any
};




const BaseLayout: FunctionComponent<BaseProps> = ({ sider, active, children }: BaseProps) => {


  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [activeSidebar, setActiveSidebar] = useState('api-products');
  const [thankyou, setThankyou] = useState(false);
  const [goodFeedback, setGood] = useState(true);
  const [badFeedback, setBad] = useState(true);
  const [StyleGood, setStyleGood] = useState({ backgroundColor: '#818881' });
  const [StyleBad, setStyleBad] = useState({ backgroundColor: '#818881' });
  const [hooverGood, sethooverGood] = useState(false);
  const [hooverBad, sethooverBad] = useState(false);
  const [panel, setPanel] = useState(false);


  const [isDarkMode, setIsDarkMode] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme === "dark";
  });

  useEffect(() => {
    if (isDarkMode) {
      document.body.classList.add("dark-mode");
    } else {
      document.body.classList.remove("dark-mode");
    }
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    const newTheme = !isDarkMode;
    setIsDarkMode(newTheme);
    localStorage.setItem("theme", newTheme ? "dark" : "light");
  };


  ////test Data
  const apiProduct2s = [
    {
      id: 'api1',
      titleNav: 'User Management API',
      version: '1.0',
      description: 'API for managing user accounts and authentication.',
      swagger_link: 'https://api.example.com/swagger/user-management',
      documentation_links: [
        { title: 'Getting Started Guide', url: 'https://docs.example.com/user-management/getting-started' },
        { title: 'API Reference', url: 'https://docs.example.com/user-management/api-reference' }
      ],
      basePath: '/user-management', // Añadido
      icon: 'users' // Añadido
    },
    {
      id: 'api2',
      titleNav: 'Order Management API',
      version: '2.1',
      description: 'API for handling orders, payments, and tracking.',
      swagger_link: 'https://api.example.com/swagger/order-management',
      documentation_links: [
        { title: 'Order Processing Guide', url: 'https://docs.example.com/order-management/processing' },
        { title: 'API Reference', url: 'https://docs.example.com/order-management/api-reference' }
      ],
      basePath: '/order-management', // Añadido
      icon: 'orders' // Añadido
    }
  ];

  const apiData = {
    menus: [
      {
        id: 'api1',
        title: "User Management API",
        icon: "dashboard",
        version: '1.0',
        description: 'API for managing user accounts and authentication.',
        swagger_link: 'https://api.example.com/swagger/user-management',
        basePath: '/user-management',
        links: [
          {
            title: 'Getting Started Guide',
            url: 'https://docs.example.com/user-management/getting-started'
          },
          {
            title: 'API Reference',
            url: 'https://docs.example.com/user-management/api-reference'
          }
        ]
      },
      {
        id: 'api2',
        title: "Order Management API",
        icon: "orders",
        version: '2.1',
        description: 'API for handling orders, payments, and tracking.',
        swagger_link: 'https://api.example.com/swagger/order-management',
        basePath: '/order-management',
        links: [
          {
            title: 'Order Processing Guide',
            url: 'https://docs.example.com/order-management/processing'
          },
          {
            title: 'API Reference',
            url: 'https://docs.example.com/order-management/api-reference'
          }
        ]
      }
    ]
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };




  const BadFeedback = () => {
    setGood(false);
    setThankyou(true);
    setBad(false);
    ReactGA.event({
      category: 'Feedback',
      action: 'Bad',
      label: (window.location.pathname + window.location.search)
    });

  }
  const GoodFeedback = () => {
    setBad(false);
    setThankyou(true);
    setGood(false);
    ReactGA.event({
      category: 'Feedback',
      action: 'Good',
      label: (window.location.pathname + window.location.search)
    });
  }

  const toggleHooverGood = () => {
    if (goodFeedback && badFeedback) {
      if (hooverGood) { setStyleGood({ backgroundColor: '#818881' }); sethooverGood(false); }
      else { setStyleGood({ backgroundColor: '#3e413e' }); sethooverGood(true); }
    }
  }
  const toggleHooverBad = () => {
    if (goodFeedback && badFeedback) {
      if (hooverBad) { setStyleBad({ backgroundColor: '#818881' }); sethooverBad(false); }
      else { setStyleBad({ backgroundColor: '#3e413e' }); sethooverBad(true); }
    }
  }


  const togglePanel = () => {
    setPanel(!panel);
  }
  const cambio = () => {

    setBad(true);
    setGood(true);
    setThankyou(false);
    setStyleGood({ backgroundColor: '#818881' });
    setStyleBad({ backgroundColor: '#818881' });
    sethooverGood(false);
    sethooverBad(false);

  }

  if (SessionService.eventEmitter) {
    SessionService.eventEmitter.once('panel', function () {
      console.log('Paneling');
      if (!SessionService.SCREAMING) {
        SessionService.SCREAMING = true;

        if (panel) {

          const elem1 = document.getElementById('pannel') as HTMLDivElement;
          elem1.hidden = true;
          const elem2 = document.getElementById('principal') as HTMLDivElement;
          elem2.style.height = '100%'
        } else {

          const elem1 = document.getElementById('pannel') as HTMLDivElement;
          elem1.hidden = false;
          const elem2 = document.getElementById('principal') as HTMLDivElement;
          elem2.style.height = '80%'
        }

        togglePanel();
        SessionService.SCREAMING = false;
      }
    });
  }

  return (


    <>


      <div id='dashboardWrapper' >
        <button onClick={toggleDarkMode} className="toggle-dark-mode">
          <span>Theme</span>
          {isDarkMode ? "Dark" : "Light"}
        </button>
        <HeaderMenu />
        <div id="page-content-wrapper" className='w-100'>
          <ApiSideBar sider={sider} active={active} />
          <div id="content" className="container-fluid mt-0 p-0">
            <div className="scrollContainer">
              <div className="d-flex justify-content-end">
                <button className='d-table cld-greenButton' onClick={toggleDrawer}>New Product</button>
              </div>
              <Drawer isOpen={isDrawerOpen} toggleSidebar={toggleDrawer} name="New Product">
                <CreatAppForm onSubmit={(data) => console.log(data)} />
              </Drawer>
              {children}
            </div>
          </div>
        </div>
        <Footer />
      </div>




      <div className="position-fixed" id="pannel" style={{ backgroundColor: 'black', width: '100%', height: `${panel ? '20%' : '0%'}`, overflow: 'hidden', zIndex: 9999999 }}>
        <Terminal></Terminal>
      </div></>


  )
}

export default BaseLayout;