import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const ApiOwnershipForm: React.FC = () => {
    const [formData, setFormData] = useState({
        users: '',
        userGroups: '',
        categories: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log("Form Data Submitted: ", formData);
        // Aquí puedes añadir la lógica de envío al backend
    };

    const handleCancel = () => {
        // Reinicia el formulario
        setFormData({
            users: '',
            userGroups: '',
            categories: '',
        });
        console.log("Form reset");
    };

    return (
        <Form className="cloudForm" onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
            <h2>API Ownership</h2>

            <p className="text-muted">
                You can limit access to this API to the list of users or user groups. Limiting access to the API will also limit access to Analytics Keys, Policies, oAuth Client, and Developer objects using it.
            </p>

            {/* Users Selector */}
            <Form.Group controlId="users">
                <Form.Label>Users</Form.Label>
                <Form.Control
                    as="select"
                    name="users"
                    value={formData.users}
                    onChange={handleChange}
                >
                    <option value="">Select a user</option>
                    <option value="user1">User 1</option>
                    <option value="user2">User 2</option>
                    <option value="user3">User 3</option>
                    {/* Agrega más usuarios aquí según sea necesario */}
                </Form.Control>
            </Form.Group>

            {/* User Groups Selector */}
            <Form.Group controlId="userGroups">
                <Form.Label>User Groups</Form.Label>
                <Form.Control
                    as="select"
                    name="userGroups"
                    value={formData.userGroups}
                    onChange={handleChange}
                >
                    <option value="">Select a user group</option>
                    <option value="group1">Group 1</option>
                    <option value="group2">Group 2</option>
                    <option value="group3">Group 3</option>
                    {/* Agrega más grupos de usuarios aquí según sea necesario */}
                </Form.Control>
            </Form.Group>

            <h3>Categories</h3>
            <p className="text-muted">
                API categories enable you to group APIs into categories that make sense to you, making it easy to manage and search for APIs. You can create a new category here or assign your API to an existing category.
            </p>

            {/* Categories Selector */}
            <Form.Group controlId="categories">
                <Form.Label>Categories</Form.Label>
                <Form.Control
                    as="select"
                    name="categories"
                    value={formData.categories}
                    onChange={handleChange}
                >
                    <option value="">Select a category</option>
                    <option value="category1">Category 1</option>
                    <option value="category2">Category 2</option>
                    <option value="category3">Category 3</option>
                    {/* Agrega más categorías aquí según sea necesario */}
                </Form.Control>
            </Form.Group>

            {/* Fixed Buttons */}
            <div className="d-flex justify-content-between">
                <Button variant="primary" type="submit">
                    Submit
                </Button>
                <Button variant="outline-secondary" onClick={handleCancel}>
                    Cancel
                </Button>
            </div>
        </Form>
    );
};

export default ApiOwnershipForm;
