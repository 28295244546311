
import React from 'react';

// Coordenadas aproximadas para algunos servidores en el SVG
const serverLocations = {
    "aws-ap-southeast-1": { x: 450, y: 250, label: "Singapore" },
    "aws-us-east-1": { x: 130, y: 150, label: "Virginia" },
    "aws-eu-west-1": { x: 300, y: 110, label: "Ireland" },
    // Agrega más servidores aquí según sea necesario
};

const WorldMap = ({ selectedRegion }) => {
    const location = serverLocations[selectedRegion];

    return (
        <div style={{ position: 'relative', width: '100%', height: '400px' }}>
            {/* Mapa del mundo en SVG */}
            <svg
                version="1.0"
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 1280 688"
                preserveAspectRatio="xMidYMid meet"
            >
                <g
                    transform="translate(0.000000,688.000000) scale(0.100000,-0.100000)"
                    fill="#147bce"
                    stroke="none"
                >

                    <path d="M3225 6869 c-71 -8 -105 -29 -105 -65 0 -10 -15 -14 -55 -14 -39 0
-56 -4 -63 -16 -5 -10 -32 -21 -63 -26 -30 -6 -72 -13 -94 -17 l-40 -7 33 -8
c18 -4 37 -16 42 -27 17 -31 48 -42 79 -29 21 8 35 7 64 -5 55 -23 127 -19
193 10 64 28 94 32 94 10 0 -44 -188 -103 -257 -81 -12 4 -32 2 -43 -4 -18 -9
-19 -14 -9 -26 20 -24 85 -54 118 -54 35 0 48 -15 31 -35 -26 -31 -166 -8
-180 29 -4 12 -15 28 -24 35 -9 7 -16 24 -16 37 0 14 -6 24 -14 24 -8 0 -42
13 -75 29 -73 34 -126 30 -89 -7 9 -9 6 -12 -15 -12 -15 0 -36 -9 -48 -21 -19
-19 -19 -23 -6 -36 8 -8 37 -19 63 -24 27 -5 52 -13 58 -19 9 -9 6 -11 -38
-24 -12 -4 -12 -10 -3 -30 10 -22 18 -26 58 -26 31 0 55 7 72 20 14 11 30 20
37 20 17 0 94 -47 115 -70 17 -18 17 -20 1 -26 -19 -7 -66 10 -66 25 0 5 12 4
27 -4 33 -17 44 -11 17 10 -24 19 -55 19 -80 1 -19 -15 -19 -15 0 -29 10 -8
16 -19 12 -25 -11 -18 -26 -14 -19 4 5 14 3 15 -15 5 -12 -7 -22 -16 -22 -21
0 -11 31 -16 183 -29 101 -9 124 -8 158 5 43 18 53 34 16 27 -27 -5 -97 21
-97 36 0 6 21 11 48 11 64 1 172 51 172 82 0 4 -16 8 -35 8 -23 0 -35 5 -35
13 0 14 40 37 65 37 8 0 15 7 15 15 0 18 36 20 76 5 24 -9 33 -7 68 18 23 16
79 49 126 72 75 38 82 44 60 50 -16 4 -26 2 -28 -6 -4 -17 -63 -37 -75 -25
-16 16 1 28 76 56 39 15 92 36 117 47 l45 19 -29 26 c-27 23 -36 25 -90 21
-69 -6 -86 -2 -86 18 0 7 -10 16 -22 18 -43 9 -333 12 -403 5z"/>
                    <path d="M4633 6860 c-6 -24 -39 -26 -86 -7 -18 8 -65 13 -107 13 -70 -1 -75
-3 -78 -23 -4 -26 15 -31 24 -8 9 22 27 18 54 -9 14 -16 20 -31 16 -42 -6 -15
-19 -16 -114 -12 -120 7 -132 5 -132 -23 0 -23 -32 -26 -50 -4 -7 8 -16 15
-21 15 -5 0 -9 -7 -9 -15 0 -12 -16 -15 -77 -16 -101 -1 -157 -17 -161 -47 -4
-25 -38 -53 -57 -46 -7 3 -16 1 -20 -5 -3 -6 -18 -11 -33 -11 -39 -1 -120 -39
-104 -49 8 -4 32 -5 55 0 23 4 45 5 50 2 12 -7 -33 -80 -57 -91 -21 -11 -42
-16 -128 -32 -58 -11 -105 -34 -89 -44 6 -4 45 -10 86 -13 113 -9 122 -42 16
-59 -41 -7 -67 -24 -36 -24 8 0 18 -7 22 -16 10 -26 47 -34 115 -24 35 5 106
11 159 13 l96 5 16 -28 c10 -15 32 -46 50 -69 57 -71 81 -115 87 -161 l6 -45
14 28 c17 34 41 32 54 -6 12 -33 1 -56 -35 -74 l-24 -13 25 0 c27 0 90 -34 90
-48 0 -5 -9 -17 -20 -27 -24 -22 -25 -38 -4 -56 14 -11 11 -15 -20 -31 -41
-21 -50 -44 -11 -26 14 6 28 8 31 5 8 -8 -14 -66 -32 -87 -7 -9 -11 -26 -8
-38 5 -18 10 -20 25 -11 17 9 19 6 19 -28 0 -69 88 -177 159 -195 28 -7 45
-18 49 -30 6 -24 19 -23 59 8 28 21 33 31 33 68 0 23 7 51 15 62 8 10 15 27
15 38 0 28 115 136 146 136 50 0 94 27 113 70 10 23 30 48 45 56 14 8 38 21
54 29 17 9 55 15 102 15 41 1 93 8 115 16 22 8 64 22 94 31 65 20 49 31 -46
32 -47 1 -63 5 -63 15 0 8 6 16 14 19 8 3 16 16 18 29 2 18 10 24 39 26 30 3
37 -1 47 -23 13 -27 37 -33 45 -11 13 34 -52 76 -116 76 -48 0 -49 1 -43 26 5
19 1 30 -16 44 -29 23 -18 35 32 32 34 -2 40 0 40 17 0 33 27 32 95 -4 77 -41
86 -43 56 -11 -29 30 -20 46 25 46 19 0 34 3 34 8 -1 17 -37 49 -63 56 -37 9
-34 31 6 44 l32 10 -37 1 c-20 1 -40 6 -44 13 -13 20 28 39 79 36 28 -2 46 1
43 7 -1 6 -10 9 -18 7 -17 -3 -58 34 -58 53 0 7 -3 20 -6 28 -3 9 8 22 30 35
20 12 36 27 36 33 0 7 7 21 17 31 15 17 15 18 -14 12 -20 -4 -33 -1 -38 7 -13
19 21 37 85 44 32 4 61 12 63 19 3 7 34 23 68 36 59 21 62 23 39 32 -14 5 -50
7 -80 4 -160 -17 -218 -25 -242 -31 -24 -6 -28 -4 -28 15 0 22 -1 23 -97 17
-79 -4 -117 -12 -195 -41 -88 -32 -128 -37 -128 -16 0 10 144 75 197 89 23 6
78 16 123 22 46 5 96 16 114 24 30 13 28 13 -41 12 -77 -2 -93 4 -73 28 11 13
6 15 -40 15 -29 0 -111 -7 -182 -16 -71 -9 -131 -14 -135 -11 -12 12 19 37 56
44 l36 7 -50 7 c-27 4 -85 8 -128 8 -71 1 -79 -1 -84 -19z m-647 -161 c6 -10
-11 -29 -26 -29 -3 0 -11 4 -19 9 -17 11 -5 31 19 31 10 0 22 -5 26 -11z
m1132 -700 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4 11 13 11 9 0 15
-5 13 -11z"/>
                    <path d="M4265 6850 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
7z"/>
                    <path d="M4205 6831 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
                    <path d="M5429 6749 c-11 -6 -11 -11 -2 -20 12 -12 43 -6 43 10 0 14 -25 20
-41 10z"/>
                    <path d="M8157 6721 c-3 -14 -11 -18 -26 -14 -14 4 -21 1 -21 -10 0 -14 27
-44 34 -36 15 17 34 69 27 73 -5 3 -11 -3 -14 -13z"/>
                    <path d="M8285 6721 c-8 -15 3 -31 21 -31 9 0 14 7 12 17 -4 20 -24 28 -33 14z" />
                    <path d="M7877 6704 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M8032 6689 c-13 -5 -35 -6 -49 -3 -19 5 -24 3 -21 -7 3 -8 11 -14 19
-15 8 0 22 -4 32 -8 20 -9 67 7 67 23 0 14 -23 19 -48 10z"/>
                    <path d="M8196 6693 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M9277 6694 c-14 -14 -7 -35 11 -32 9 2 17 10 17 17 0 16 -18 25 -28
15z"/>
                    <path d="M3640 6680 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z" />
                    <path d="M3227 6654 c-27 -14 -46 -28 -43 -31 8 -8 92 25 106 43 19 22 -7 17
-63 -12z"/>
                    <path d="M9401 6657 c-59 -30 -52 -51 19 -55 88 -5 116 29 51 62 -25 13 -31
13 -70 -7z"/>
                    <path d="M8328 6658 c-41 -14 -37 -39 5 -31 38 7 52 17 43 32 -8 13 -12 13
-48 -1z"/>
                    <path d="M7705 6646 c-10 -7 -16 -20 -13 -28 7 -17 89 -59 101 -51 13 8 7 21
-13 26 -23 6 -27 35 -5 43 26 11 16 24 -18 24 -18 0 -42 -6 -52 -14z"/>
                    <path d="M7821 6646 c-10 -12 -9 -21 5 -43 31 -49 74 -72 74 -38 0 8 -9 15
-20 15 -24 0 -27 15 -4 24 27 10 52 7 57 -8 5 -11 20 -14 61 -12 62 3 71 8 62
31 -5 13 -10 14 -27 5 -17 -9 -25 -8 -40 6 -11 10 -36 18 -59 18 -21 1 -42 4
-46 9 -12 12 -51 8 -63 -7z"/>
                    <path d="M8211 6636 c-13 -14 -18 -27 -13 -30 16 -9 39 3 57 30 14 22 14 24
-3 24 -10 0 -29 -11 -41 -24z"/>
                    <path d="M8084 6624 c11 -45 25 -52 47 -25 24 29 24 41 0 41 -11 0 -27 3 -37
7 -15 5 -16 2 -10 -23z"/>
                    <path d="M6857 6579 c-17 -31 -17 -31 -35 -11 -9 11 -34 23 -54 26 -39 7 -47
-1 -26 -22 9 -9 6 -17 -12 -37 l-23 -24 -29 24 c-36 30 -39 30 -71 5 -24 -19
-67 -28 -67 -13 0 3 7 15 17 25 15 17 15 18 -3 18 -33 0 -94 -20 -94 -31 0 -6
9 -8 19 -4 11 3 22 1 26 -5 9 -14 -23 -50 -43 -50 -12 0 -12 -3 -2 -15 16 -19
44 -20 37 -1 -5 11 0 11 23 -2 27 -16 30 -15 70 7 62 35 101 10 40 -26 -35
-20 -39 -33 -10 -33 28 0 25 -15 -7 -39 -19 -14 -23 -20 -12 -21 9 0 24 -9 34
-20 10 -11 22 -20 26 -20 4 0 22 27 40 59 17 33 40 68 50 77 18 16 22 16 54 1
24 -12 34 -22 32 -36 -1 -11 1 -20 6 -20 41 -4 68 1 65 11 -2 6 -26 23 -54 37
-27 14 -60 36 -72 49 -12 12 -26 22 -32 22 -5 0 -10 4 -10 9 0 18 35 21 111
10 77 -12 77 -12 123 17 25 16 46 32 46 35 0 4 -24 10 -52 13 -29 4 -62 9 -73
12 -15 3 -25 -4 -38 -27z"/>
                    <path d="M7201 6591 c-17 -11 -5 -31 19 -31 24 0 36 20 19 31 -8 5 -16 9 -19
9 -3 0 -11 -4 -19 -9z"/>
                    <path d="M7370 6574 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z" />
                    <path d="M8167 6584 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M9315 6580 c-7 -11 39 -25 54 -15 6 3 11 10 11 16 0 12 -57 12 -65
-1z"/>
                    <path d="M9429 6576 c-19 -7 -47 -19 -63 -28 l-29 -15 74 -18 c41 -10 94 -27
118 -37 52 -22 61 -15 61 48 0 54 -8 60 -41 28 l-28 -27 -10 25 c-12 31 -38
39 -82 24z"/>
                    <path d="M2510 6565 c0 -16 27 -32 37 -21 7 7 -16 36 -28 36 -5 0 -9 -7 -9
-15z"/>
                    <path d="M5457 6544 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M2333 6512 c10 -10 17 -23 17 -29 0 -6 11 -8 26 -5 18 3 40 -6 80
-33 57 -39 73 -41 55 -6 -24 43 -112 91 -169 91 -24 0 -24 -1 -9 -18z"/>
                    <path d="M9648 6480 c-41 -44 -49 -67 -20 -55 9 3 46 13 82 21 36 9 69 19 74
24 11 10 -51 50 -78 50 -11 0 -36 -18 -58 -40z"/>
                    <path d="M7065 6500 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43
7z"/>
                    <path d="M6992 6479 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
                    <path d="M2080 6450 c-22 -7 -20 -8 15 -10 l40 -1 -42 -21 c-41 -19 -58 -48
-30 -48 22 0 97 59 97 75 0 16 -37 19 -80 5z"/>
                    <path d="M9372 6449 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
                    <path d="M9832 6449 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
                    <path d="M2609 6430 c19 -16 26 -18 38 -8 13 10 12 13 -4 19 -10 4 -27 8 -38
8 -19 0 -18 -1 4 -19z"/>
                    <path d="M5422 6419 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
                    <path d="M9327 6424 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M1980 6410 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
                    <path d="M2316 6401 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z" />
                    <path d="M9255 6400 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"/>
                    <path d="M9696 6369 c-20 -24 -24 -35 -16 -49 15 -28 13 -30 -34 -30 -24 0
-63 -7 -87 -16 -28 -9 -58 -13 -83 -9 -21 3 -77 -2 -125 -10 -47 -9 -110 -20
-140 -26 -30 -5 -65 -16 -79 -25 l-24 -16 21 -22 c32 -34 28 -57 -9 -49 -17 3
-65 6 -108 7 -77 1 -77 1 -80 -26 -3 -23 2 -30 32 -42 37 -16 66 -59 66 -101
0 -20 -5 -25 -25 -25 -21 0 -25 5 -25 28 0 16 -8 36 -17 44 -27 24 -91 41
-125 34 -18 -3 -37 -1 -44 5 -31 26 -11 -10 22 -41 26 -23 33 -35 23 -40 -28
-18 -93 24 -113 74 l-12 30 -23 -37 c-20 -32 -21 -42 -12 -69 6 -17 11 -41 11
-54 0 -18 7 -23 42 -29 45 -8 108 -59 108 -88 0 -9 10 -23 22 -31 17 -12 19
-19 10 -28 -19 -19 -61 7 -79 50 -29 69 -52 69 -93 -2 -40 -71 -94 -119 -123
-110 -43 13 -78 35 -74 47 6 19 47 20 67 2 17 -16 19 -15 35 9 28 43 41 147
27 216 -7 33 -10 75 -7 93 8 41 -11 60 -56 55 -25 -2 -37 -11 -52 -38 -10 -19
-32 -49 -48 -65 -16 -17 -29 -34 -29 -37 0 -4 18 -28 41 -54 22 -25 39 -54 37
-63 -5 -24 -44 -25 -75 -3 -34 24 -96 42 -145 42 -27 0 -47 -8 -71 -30 -42
-37 -82 -41 -71 -8 l6 21 -33 -19 c-19 -11 -54 -28 -78 -38 -45 -17 -45 -17
-62 5 -16 22 -17 22 -58 6 -23 -10 -50 -17 -60 -17 -10 0 -42 -17 -72 -38 -73
-51 -88 -56 -110 -36 -24 22 -24 31 2 39 18 6 19 9 8 19 -22 19 -68 36 -74 27
-2 -5 0 -22 6 -39 6 -17 9 -43 7 -59 -3 -25 -8 -29 -48 -33 -52 -7 -90 -28
-90 -50 0 -39 -41 -50 -90 -25 -35 18 -38 10 -10 -20 18 -19 19 -23 6 -35 -25
-20 -87 17 -111 67 -11 23 -31 53 -44 67 -22 23 -22 26 -6 32 9 4 40 -1 68
-11 85 -31 177 -23 198 17 15 27 -5 43 -121 99 -47 23 -97 48 -112 55 -15 8
-56 14 -95 14 -38 0 -73 3 -77 7 -4 4 3 15 16 25 22 17 22 18 3 18 -11 0 -31
7 -44 16 -13 9 -26 13 -30 8 -3 -5 -20 -10 -38 -10 -25 -1 -30 2 -26 14 5 12
1 14 -18 9 -13 -4 -31 -7 -40 -7 -10 0 -16 -7 -15 -17 1 -15 -7 -18 -46 -18
-39 0 -47 -3 -43 -15 4 -12 -9 -20 -50 -33 -31 -9 -67 -17 -80 -17 -22 0 -24
-3 -18 -30 5 -28 2 -32 -26 -43 -18 -6 -34 -21 -37 -32 -3 -11 -27 -33 -54
-50 -27 -16 -50 -39 -52 -49 -2 -13 -15 -23 -35 -28 -38 -10 -37 -8 -18 -44
20 -39 19 -57 -5 -79 -17 -15 -24 -16 -46 -7 -32 15 -44 15 -44 0 0 -7 -20
-31 -46 -53 l-46 -40 9 -85 c7 -79 10 -85 32 -88 35 -5 66 5 101 34 18 16 35
23 40 18 10 -11 60 -133 60 -148 0 -7 7 -21 15 -34 l16 -22 37 20 c20 11 47
20 59 20 21 0 23 5 23 48 1 50 13 74 44 85 10 4 16 10 13 14 -4 3 -2 12 4 19
8 10 6 20 -10 40 -14 18 -21 41 -21 72 0 39 5 49 38 81 21 20 50 43 64 53 13
9 30 34 37 58 10 33 20 45 48 56 31 13 37 13 59 -1 13 -8 24 -23 24 -31 0 -9
-25 -44 -56 -77 -31 -34 -60 -72 -65 -85 -11 -28 -2 -95 16 -119 12 -16 16
-16 74 0 99 28 191 19 191 -17 0 -15 -23 -20 -137 -32 -71 -7 -87 -26 -58 -70
30 -46 -2 -99 -35 -59 -16 20 -46 19 -54 -1 -3 -9 -6 -28 -6 -44 0 -42 -9 -57
-46 -80 -27 -17 -37 -19 -55 -10 -18 10 -33 7 -92 -20 l-70 -33 -17 23 c-16
21 -20 22 -50 11 -27 -9 -38 -8 -57 5 -32 20 -36 35 -24 79 8 30 7 37 -6 42
-25 10 -33 -18 -26 -88 l6 -66 -38 -6 c-58 -9 -125 -40 -125 -57 0 -27 -29
-58 -59 -65 -16 -3 -32 -13 -35 -20 -8 -23 -87 -75 -112 -75 -14 0 -25 -8 -29
-20 -5 -16 -15 -20 -46 -20 -48 0 -50 -12 -5 -38 62 -37 96 -149 60 -200 -16
-23 -19 -23 -135 -16 l-119 7 0 -68 c0 -37 -5 -85 -11 -106 -16 -51 2 -94 44
-108 17 -6 39 -19 49 -28 17 -15 23 -15 94 7 85 27 124 62 124 111 0 23 7 34
31 47 55 30 89 63 89 86 0 35 31 56 81 56 24 1 55 7 69 15 49 28 71 19 140
-54 36 -37 84 -78 107 -89 50 -26 73 -56 73 -95 0 -16 4 -26 10 -22 6 3 10 29
10 56 0 47 1 49 21 39 11 -7 23 -9 26 -6 3 2 -26 25 -64 49 -55 36 -79 59
-111 111 -23 36 -42 69 -42 74 0 5 9 17 19 26 19 17 22 15 83 -42 34 -32 83
-76 107 -98 25 -22 53 -61 66 -91 27 -60 74 -123 93 -123 18 0 14 18 -5 23
-14 4 -13 5 3 6 12 0 25 -7 28 -16 7 -17 -9 -33 -31 -33 -7 0 -13 -9 -13 -20
0 -25 5 -25 46 1 41 25 42 41 9 81 -28 33 -28 55 -1 50 9 -2 19 4 22 13 5 11
21 15 59 15 65 0 89 19 82 64 -9 57 60 180 116 211 43 23 81 8 71 -29 -4 -17
1 -25 21 -34 27 -13 55 -16 55 -7 0 3 -7 16 -16 30 -15 22 -14 24 10 40 32 21
101 31 110 16 4 -6 -1 -25 -10 -43 l-17 -32 72 -66 c39 -36 71 -72 71 -81 -1
-51 -97 -65 -195 -28 -36 14 -70 19 -93 16 -50 -7 -138 -40 -160 -60 -10 -10
-28 -17 -41 -17 -29 0 -35 -21 -25 -83 13 -75 36 -84 194 -82 l122 2 -6 -41
c-12 -79 -49 -187 -73 -212 -19 -21 -29 -25 -51 -19 -15 5 -56 7 -92 7 -51 -2
-88 6 -172 34 -62 20 -116 33 -127 29 -11 -3 -30 -25 -43 -48 -35 -64 -32 -64
-233 41 l-110 58 0 66 c0 37 -5 72 -10 77 -28 28 -221 7 -380 -41 -121 -36
-175 -42 -175 -18 0 21 -20 8 -36 -22 -9 -18 -39 -54 -65 -79 -43 -42 -49 -52
-49 -89 0 -56 -23 -101 -70 -139 -22 -18 -58 -60 -81 -94 -23 -34 -53 -75 -68
-90 -25 -27 -26 -34 -32 -190 -3 -89 -12 -177 -19 -195 -10 -28 -10 -36 3 -50
40 -45 137 -199 137 -218 0 -30 53 -93 112 -133 48 -32 52 -33 116 -27 36 4
93 9 126 11 34 2 90 13 126 24 97 31 123 28 168 -17 34 -35 40 -38 72 -31 24
5 42 3 58 -7 21 -14 22 -21 22 -147 l0 -133 40 -55 c23 -29 44 -65 47 -79 3
-14 16 -43 29 -65 15 -27 25 -64 29 -115 6 -67 4 -84 -24 -166 -17 -51 -31
-106 -31 -123 0 -17 13 -64 30 -105 16 -41 36 -116 45 -168 20 -114 44 -187
96 -290 59 -117 61 -125 42 -144 -20 -19 -9 -41 29 -63 22 -13 40 -13 124 -2
137 19 195 42 242 97 63 74 101 146 117 220 13 61 19 73 57 106 42 35 43 38
41 91 -2 30 -7 67 -13 83 -14 37 4 62 96 131 95 71 107 95 101 208 -2 50 -9
100 -15 112 -6 11 -19 55 -27 98 -15 73 -15 81 0 123 29 73 109 183 184 250
40 35 88 89 108 120 39 62 113 215 113 235 0 7 9 29 19 50 10 20 18 37 16 37
-2 0 -47 -9 -100 -20 -53 -11 -108 -20 -120 -20 -24 0 -88 47 -81 59 2 3 -29
51 -70 106 -41 55 -74 103 -74 107 0 4 -14 23 -31 42 -24 28 -31 47 -36 93 -6
67 -49 177 -111 280 -26 43 -42 82 -40 95 4 28 19 28 34 -1 l11 -22 14 22 c14
21 15 21 44 -21 16 -23 39 -71 51 -107 15 -45 29 -69 45 -77 23 -11 32 -34 44
-108 4 -24 26 -64 60 -109 30 -40 55 -77 55 -83 1 -41 21 -140 31 -152 6 -8
20 -14 31 -14 32 0 350 166 405 212 28 24 52 48 52 54 1 6 12 21 26 34 14 13
25 30 25 38 0 9 8 24 18 35 16 19 16 20 -17 46 -56 42 -91 82 -91 102 0 26 -8
24 -33 -7 -21 -27 -89 -63 -119 -64 -9 0 -21 12 -27 28 -6 15 -36 74 -66 131
-59 109 -60 131 -9 131 25 0 41 -13 87 -74 36 -47 80 -69 136 -67 40 1 49 -3
72 -30 25 -31 27 -31 167 -43 139 -12 143 -13 174 -44 18 -17 50 -38 70 -46
43 -18 55 -34 34 -42 -21 -8 -20 -31 2 -43 21 -11 35 -4 52 28 18 35 27 12 35
-88 7 -90 37 -212 61 -246 8 -11 14 -26 14 -35 0 -20 91 -210 101 -210 4 0 19
24 34 53 19 40 28 78 34 151 4 53 12 101 17 105 5 5 41 35 79 68 39 33 95 90
124 126 48 60 56 65 101 71 26 4 53 13 60 21 7 8 16 15 20 15 5 0 33 -36 64
-80 53 -75 56 -83 56 -140 0 -54 2 -60 20 -60 12 0 34 10 51 22 36 27 44 19
54 -49 3 -25 13 -63 21 -85 8 -24 14 -79 14 -134 0 -80 4 -101 24 -141 14 -27
30 -66 36 -88 23 -78 110 -172 110 -118 0 44 -35 135 -69 176 -94 119 -102
147 -67 231 13 32 19 36 51 36 43 0 75 -15 75 -35 0 -8 14 -30 30 -48 17 -19
30 -44 30 -56 0 -11 4 -21 9 -21 6 0 39 25 76 56 l65 56 -5 51 c-12 103 -17
118 -61 184 -71 106 -70 97 -23 148 44 47 57 52 88 33 15 -9 22 -8 34 3 8 8
48 25 88 38 150 47 196 85 282 235 42 74 44 84 20 101 -17 12 -17 14 1 34 19
21 19 21 -2 21 -25 0 -30 28 -7 36 22 9 19 23 -15 74 -16 25 -30 54 -30 65 0
25 40 69 80 90 17 9 30 17 30 19 0 2 -19 1 -42 -1 -50 -5 -116 14 -132 39 -14
22 30 70 95 103 60 31 76 19 48 -36 -22 -44 -16 -51 13 -14 36 46 128 26 115
-25 -4 -17 2 -32 23 -57 24 -27 30 -43 30 -78 0 -49 6 -53 50 -30 38 20 38 42
0 135 l-30 73 79 86 c78 85 80 86 121 83 48 -4 66 9 135 99 119 154 181 362
127 429 -17 20 -21 21 -59 10 -39 -12 -44 -11 -72 13 -17 14 -36 27 -43 29
-26 9 118 149 231 225 55 37 67 39 153 27 27 -3 62 -1 82 6 29 9 49 8 105 -6
67 -16 108 -14 97 4 -17 29 124 132 180 132 39 0 49 -12 32 -39 -7 -11 -10
-23 -6 -26 11 -11 96 50 106 78 10 28 52 40 52 15 0 -23 -38 -76 -67 -94 -15
-10 -62 -51 -104 -91 -42 -40 -81 -73 -87 -73 -19 0 -50 -65 -57 -120 -6 -47
14 -192 28 -207 6 -6 107 99 102 106 -8 15 35 71 55 71 15 0 20 7 20 26 0 33
17 64 35 64 17 0 19 11 6 25 -5 6 -12 28 -15 50 -7 44 19 101 52 111 9 3 31
12 47 20 41 19 140 30 149 15 12 -18 43 -13 83 15 80 54 137 77 198 80 49 2
60 6 63 21 3 26 -34 82 -62 92 -25 10 -41 37 -26 46 5 3 28 1 50 -5 48 -13 80
0 80 33 0 12 4 28 9 36 10 16 31 6 31 -14 0 -21 16 -26 44 -14 21 10 29 8 53
-10 71 -55 135 -88 118 -61 -11 18 23 59 60 74 45 19 44 31 -2 55 -69 34 -82
35 -95 7 -15 -34 -35 -33 -43 3 -17 78 -157 146 -360 175 -55 8 -114 18 -130
21 -31 7 -31 7 -27 -34 5 -49 -4 -51 -88 -21 -38 13 -83 19 -145 20 -116 0
-133 5 -149 45 -17 40 -43 46 -173 38 -84 -5 -100 -3 -112 11 -7 9 -22 16 -33
16 -13 0 -24 11 -31 29 -15 40 -36 43 -101 13 -61 -27 -75 -26 -55 7 7 11 11
22 9 24 -12 11 -182 13 -185 3 -2 -6 -6 -23 -9 -38 -8 -37 -68 -55 -117 -34
-24 10 -40 11 -58 4 -14 -5 -40 -7 -58 -3 -29 5 -35 2 -45 -20 -15 -33 -36
-32 -84 4 -86 63 -113 111 -63 111 25 0 24 15 -3 34 -28 20 -105 21 -155 2
-21 -8 -52 -17 -69 -19 -37 -7 -104 7 -115 24 -9 16 -118 15 -148 0 -19 -11
-23 -8 -43 34 -23 47 -45 56 -93 35 -18 -8 -18 -9 -2 -9 23 -1 81 -31 73 -38
-3 -3 -11 -1 -18 5 -12 9 -49 16 -72 13 -4 -1 2 -9 14 -19 l23 -18 -46 -18
c-47 -18 -122 -24 -135 -11 -4 4 2 13 13 21 73 52 133 86 167 93 49 11 82 39
67 57 -7 8 -7 14 1 19 18 11 11 25 -14 25 -14 0 -28 5 -32 11 -4 8 -31 9 -86
4 -62 -5 -88 -3 -123 11 -47 17 -55 28 -30 38 29 11 15 26 -24 26 -31 0 -44
-6 -65 -31z m-2483 -1001 c22 -26 22 -38 -2 -38 -11 0 -26 -3 -35 -6 -19 -7
-42 36 -32 61 8 21 44 12 69 -17z m725 -780 c3 -24 0 -28 -22 -28 -23 0 -38
-12 -42 -35 -1 -5 -5 -17 -9 -26 -4 -10 4 -28 19 -47 15 -16 26 -38 26 -48 0
-11 4 -25 9 -33 8 -12 12 -12 25 -2 13 11 19 11 31 1 22 -18 19 -24 -17 -38
-31 -11 -31 -12 -13 -32 27 -30 33 -119 9 -137 -38 -28 -126 -4 -139 37 -3 11
1 43 11 73 l16 53 -26 38 c-27 40 -56 123 -56 161 0 42 90 98 150 92 19 -1 26
-8 28 -29z m272 -41 c0 -20 -8 -41 -19 -51 -16 -15 -19 -15 -31 -1 -11 13 -10
22 3 50 22 46 47 46 47 2z"/>
                    <path d="M2847 6384 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M9470 6374 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z" />
                    <path d="M1857 6356 c-32 -11 -74 -34 -95 -51 l-36 -29 34 -8 c32 -7 38 -4 63
27 23 27 34 34 52 29 19 -5 27 0 40 25 18 36 19 35 -58 7z"/>
                    <path d="M9216 6373 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M9866 6373 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M2002 6348 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>
                    <path d="M3495 6351 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
                    <path d="M6966 6343 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M11346 6343 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M2635 6330 c-7 -11 24 -25 79 -36 40 -7 66 -27 66 -49 0 -17 31 -35
59 -35 11 0 40 -7 65 -15 36 -12 68 -14 175 -7 72 5 132 10 134 13 2 2 7 17
11 32 6 26 5 27 -13 17 -11 -5 -91 -11 -178 -13 -138 -2 -162 0 -186 16 -15 9
-27 24 -27 32 0 14 -32 25 -110 40 -19 4 -42 9 -52 11 -9 3 -19 0 -23 -6z"/>
                    <path d="M8410 6309 c-30 -11 -82 -22 -114 -24 -33 -3 -87 -13 -120 -24 -34
-11 -74 -21 -91 -23 -24 -2 -29 -7 -27 -23 1 -13 -10 -32 -28 -50 -34 -33 -38
-51 -12 -58 11 -3 7 -5 -10 -6 -18 -1 -37 -13 -57 -38 -37 -45 -38 -51 -8 -55
12 -2 26 -13 32 -25 10 -23 32 -33 78 -33 l29 0 -26 31 c-41 48 -34 71 48 157
63 67 89 82 190 107 113 29 206 63 206 76 0 14 -29 10 -90 -12z"/>
                    <path d="M9500 6314 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z" />
                    <path d="M2188 6289 c-5 -48 -28 -58 -85 -35 -126 48 -179 45 -154 -10 9 -20
15 -24 23 -16 16 16 60 15 83 -2 18 -13 17 -14 -11 -29 l-29 -16 30 0 c44 -1
238 54 243 67 2 7 -7 12 -22 12 -18 0 -30 9 -41 30 -20 39 -33 38 -37 -1z"/>
                    <path d="M2367 6314 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M2452 6311 c-19 -12 2 -46 46 -73 26 -16 35 -18 47 -8 47 39 -40 115
-93 81z m48 -26 c7 -9 10 -18 7 -21 -10 -11 -37 5 -37 21 0 19 14 19 30 0z"/>
                    <path d="M11460 6305 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6
2 -11 -4 -11 -13z"/>
                    <path d="M10875 6280 c-3 -5 -2 -16 2 -24 11 -20 38 -3 30 18 -7 18 -23 21
-32 6z"/>
                    <path d="M10964 6276 c-3 -8 -4 -25 -2 -38 3 -24 0 -23 93 -16 11 1 34 -2 50
-7 25 -6 28 -5 18 6 -7 7 -13 23 -13 36 0 26 -3 27 -28 12 -14 -9 -25 -8 -50
5 -38 20 -61 20 -68 2z"/>
                    <path d="M11140 6272 c0 -5 7 -15 15 -22 17 -14 58 -4 52 13 -4 14 -67 22 -67
9z"/>
                    <path d="M1842 6259 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
                    <path d="M2400 6250 c11 -21 22 -25 33 -13 9 9 -12 33 -30 33 -11 0 -11 -4 -3
-20z"/>
                    <path d="M2602 6261 c4 -13 18 -16 19 -4 0 4 -5 9 -11 12 -7 2 -11 -2 -8 -8z" />
                    <path d="M5410 6245 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
                    <path d="M2337 6243 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
                    <path d="M2663 6243 c-20 -7 -15 -31 7 -38 23 -7 40 1 40 20 0 10 -25 28 -33
24 -1 0 -8 -3 -14 -6z"/>
                    <path d="M8965 6240 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"/>
                    <path d="M11285 6242 c6 -5 28 -13 50 -17 26 -4 36 -3 30 3 -5 5 -28 13 -50
17 -26 4 -36 3 -30 -3z"/>
                    <path d="M5457 6224 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M6746 6213 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M10866 6183 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M1735 6173 c-34 -9 -45 -24 -45 -63 0 -30 -6 -43 -27 -59 -19 -14
-23 -20 -12 -21 9 0 24 -9 34 -20 10 -11 28 -20 40 -20 12 0 34 9 48 20 14 11
29 20 35 20 5 0 17 9 28 21 10 12 37 30 59 41 l40 19 -24 -21 c-25 -22 -21
-26 10 -10 21 12 26 55 7 67 -7 4 -40 11 -73 15 -33 3 -69 9 -80 11 -11 3 -29
3 -40 0z"/>
                    <path d="M11066 6155 c-4 -14 -4 -30 -1 -35 8 -13 85 -13 85 0 0 6 -7 10 -15
10 -9 0 -26 11 -39 25 l-24 25 -6 -25z"/>
                    <path d="M2738 6153 c-40 -6 -51 -25 -41 -74 10 -52 27 -60 41 -19 6 16 15 30
21 30 16 0 71 51 65 61 -5 9 -35 10 -86 2z"/>
                    <path d="M8555 6151 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
                    <path d="M2533 6131 c-33 -13 -40 -20 -33 -31 17 -27 11 -32 -27 -25 l-36 7
29 -25 c63 -53 93 -66 130 -54 38 11 43 30 18 65 -10 15 -13 32 -9 52 8 36 -6
38 -72 11z"/>
                    <path d="M2304 6125 c-4 -9 -19 -22 -35 -31 -19 -9 -29 -22 -29 -37 0 -46 -16
-53 -49 -19 -39 39 -72 50 -85 29 -8 -12 -15 -13 -33 -4 -18 8 -27 7 -41 -6
-22 -20 -32 -13 -32 24 l0 28 -37 -20 c-76 -41 -85 -51 -72 -87 6 -17 16 -33
22 -33 7 -1 44 -2 84 -3 44 0 77 -6 85 -14 21 -21 -9 -32 -89 -32 -74 0 -96
-12 -45 -25 15 -3 44 -18 64 -32 33 -22 44 -25 87 -19 27 4 75 15 107 26 73
25 96 25 167 -4 31 -13 57 -20 57 -15 0 4 -4 11 -10 14 -18 11 -10 34 16 48
l27 14 -42 7 c-50 8 -73 29 -96 91 -15 38 -16 53 -8 76 7 17 8 31 2 35 -5 3
-12 -2 -15 -11z"/>
                    <path d="M8747 6134 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M3200 6112 c0 -29 10 -32 69 -25 46 5 51 8 38 21 -8 8 -36 17 -61 19
-42 5 -46 4 -46 -15z"/>
                    <path d="M2901 6082 c-37 -35 -40 -41 -32 -69 11 -39 26 -67 44 -81 8 -7 81
-16 173 -21 134 -8 173 -14 247 -39 102 -34 113 -42 98 -69 -9 -18 -7 -20 14
-16 20 4 25 0 36 -36 11 -37 15 -41 45 -41 39 0 50 -13 28 -34 -19 -19 -24
-19 -48 -1 -26 20 -39 19 -67 -4 -19 -16 -22 -22 -11 -32 22 -23 13 -29 -44
-29 -62 0 -94 -13 -94 -37 0 -13 9 -15 55 -9 60 7 112 -9 150 -44 11 -10 33
-23 48 -29 16 -6 26 -15 23 -20 -3 -5 11 -11 32 -15 20 -3 61 -17 90 -30 50
-24 68 -22 37 4 -20 17 -19 27 5 35 38 12 25 56 -35 122 -30 34 -55 66 -55 72
0 6 11 18 25 27 28 18 25 19 99 -40 30 -23 39 -26 51 -16 8 7 15 18 15 25 0 7
10 20 23 28 l22 16 -25 20 c-14 11 -31 21 -38 21 -8 0 -27 9 -42 20 -16 11
-43 23 -62 26 -35 7 -68 30 -68 47 0 6 7 18 16 28 14 15 13 19 -14 38 -16 11
-53 26 -81 32 -28 6 -51 13 -51 17 0 3 7 14 17 24 18 20 4 25 -28 7 -23 -12
-89 8 -89 27 0 21 -42 44 -81 44 -27 0 -41 -6 -51 -22 -14 -21 -18 -22 -50
-10 -19 6 -41 12 -50 12 -19 0 -28 20 -28 61 0 25 -4 29 -28 29 -59 0 -96 -63
-72 -125 8 -20 8 -31 0 -39 -20 -19 -50 30 -48 78 1 36 2 37 5 9 5 -39 23 -44
23 -7 0 28 -16 65 -34 76 -24 17 -57 6 -95 -30z m764 -576 c19 -8 35 -20 35
-25 0 -15 -5 -14 -50 9 -61 31 -49 42 15 16z"/>
                    <path d="M5190 6086 c28 -17 60 -22 45 -7 -6 5 -23 12 -40 16 l-30 6 25 -15z" />
                    <path d="M12590 5985 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6
2 -11 -4 -11 -13z"/>
                    <path d="M2666 5968 c-29 -41 -14 -78 31 -78 29 0 31 -32 3 -77 -21 -35 -38
-40 -72 -22 -16 9 -34 7 -78 -6 -84 -25 -176 -18 -223 18 -37 28 -97 36 -127
17 -13 -9 -13 -11 0 -20 8 -5 21 -10 29 -10 11 0 11 3 0 17 -12 15 -12 16 4
10 10 -4 21 -7 23 -7 14 0 0 -30 -16 -35 -11 -3 -20 -9 -21 -13 -4 -38 -9 -65
-13 -70 -3 -3 -23 13 -44 36 l-39 42 -79 0 c-96 0 -117 9 -89 40 16 18 17 23
5 31 -8 5 -32 7 -54 4 -28 -4 -56 2 -104 21 -52 21 -77 25 -121 22 -36 -3 -71
0 -97 10 l-40 15 -95 -52 c-93 -50 -129 -61 -129 -41 0 5 24 25 52 45 58 39
50 47 -14 14 -24 -12 -50 -18 -63 -15 -22 6 -23 5 -10 -22 22 -46 28 -102 12
-102 -20 0 -36 21 -43 58 -6 28 -12 32 -90 56 -104 33 -158 45 -254 57 -41 5
-96 14 -121 20 -25 6 -58 7 -73 3 -16 -4 -32 -1 -41 6 -36 30 -176 45 -217 24
-16 -8 -28 -11 -28 -8 0 4 -8 0 -17 -8 -10 -9 -34 -21 -54 -27 -20 -6 -49 -25
-64 -41 -16 -17 -35 -30 -42 -30 -8 0 -30 -7 -51 -16 l-37 -15 40 -36 c22 -19
64 -46 93 -60 55 -26 65 -45 31 -63 -15 -9 -31 -8 -65 4 -40 13 -49 13 -89 -2
-49 -18 -56 -27 -25 -37 11 -3 20 -12 20 -19 0 -39 103 -54 151 -23 29 19 39
14 39 -21 0 -30 -54 -82 -85 -82 -22 0 -75 -50 -75 -71 0 -40 57 -99 96 -99
11 0 29 -8 38 -19 22 -24 97 -45 131 -37 23 6 25 4 25 -23 0 -44 -46 -90 -129
-130 -69 -34 -99 -61 -43 -40 120 45 277 159 308 224 38 79 60 105 90 105 29
0 29 0 3 -44 -22 -38 -19 -57 9 -49 29 7 52 30 57 54 8 41 46 51 79 20 23 -21
104 -51 138 -51 12 0 68 -16 126 -36 57 -19 110 -33 118 -30 16 6 34 -11 67
-64 13 -19 40 -45 62 -57 45 -25 66 -60 68 -115 2 -29 8 -44 27 -58 22 -17 23
-22 13 -39 -11 -17 -10 -19 1 -15 8 3 24 1 35 -6 18 -9 19 -14 10 -32 -12 -23
1 -38 35 -38 25 0 156 -133 156 -158 0 -27 -23 -45 -38 -30 -6 6 -15 8 -19 4
-13 -13 -15 -330 -3 -376 6 -23 24 -54 39 -69 16 -14 31 -37 34 -52 9 -36 59
-107 91 -128 15 -10 27 -22 26 -27 -1 -5 9 -11 22 -14 17 -4 31 -24 53 -75 17
-39 40 -86 53 -106 12 -20 19 -44 16 -53 -4 -10 6 -29 25 -52 17 -19 41 -55
52 -80 21 -43 64 -84 90 -84 7 0 1 13 -14 28 -15 15 -27 32 -27 36 0 5 -17 36
-38 70 -21 34 -53 94 -71 134 -26 58 -30 76 -21 87 16 19 47 3 60 -31 11 -28
112 -182 196 -297 44 -61 57 -86 58 -119 3 -52 33 -79 171 -150 85 -44 109
-52 160 -54 52 -1 69 -7 134 -47 41 -26 95 -53 121 -62 65 -22 120 -83 120
-135 0 -16 4 -19 23 -15 18 5 26 1 34 -18 9 -19 27 -30 68 -42 53 -15 58 -15
75 0 28 26 53 14 78 -35 40 -80 28 -163 -35 -247 -31 -40 -63 -124 -63 -163 0
-17 7 -33 18 -39 16 -10 15 -13 -11 -36 l-27 -24 39 -88 c21 -48 50 -119 64
-158 69 -191 62 -179 150 -248 45 -36 92 -76 105 -89 l23 -25 -16 -219 c-30
-420 -30 -425 -64 -502 -32 -72 -46 -163 -42 -272 7 -190 5 -228 -9 -245 -13
-16 -15 -16 -27 2 -12 15 -15 16 -23 3 -14 -22 -12 -28 10 -35 22 -7 70 -65
70 -83 0 -7 -9 -12 -19 -12 -17 0 -20 -9 -23 -67 -2 -38 -8 -76 -12 -85 -7
-15 -3 -18 17 -18 31 0 90 -47 83 -66 -11 -28 13 -13 34 21 12 19 25 35 30 35
4 0 10 14 14 31 3 17 17 44 31 61 14 16 25 34 25 40 0 5 17 30 38 55 26 33 33
48 23 51 -56 19 -69 106 -19 130 20 9 28 22 33 52 4 25 14 44 27 51 18 10 18
12 4 34 -24 37 -21 65 8 65 40 0 56 11 77 51 13 27 28 40 47 44 131 28 152 40
152 85 0 15 -14 46 -31 69 -17 24 -28 45 -25 48 3 4 23 -2 45 -11 83 -38 145
15 241 203 38 74 42 89 37 132 -6 45 -4 50 29 78 19 17 52 40 72 50 21 11 44
26 51 34 7 9 31 18 54 22 66 11 83 27 117 117 28 70 33 100 38 196 l5 112 47
59 c65 81 92 140 88 193 -3 39 -8 46 -70 94 -61 47 -73 52 -135 59 -77 8 -92
14 -147 62 -22 18 -47 33 -57 33 -9 0 -36 -16 -59 -35 -46 -39 -57 -41 -85
-22 -17 13 -15 15 21 33 39 19 59 54 31 54 -8 0 -34 -16 -58 -35 -24 -19 -50
-35 -57 -35 -18 0 9 49 53 95 l35 37 -20 26 c-11 13 -20 35 -20 47 0 48 -89
115 -154 115 -15 0 -37 5 -49 11 -33 17 -157 141 -168 167 -8 22 -14 24 -67
21 -59 -3 -147 19 -190 47 -23 15 -25 14 -40 -8 -10 -12 -19 -36 -22 -52 -7
-39 -27 -44 -42 -12 -9 22 -8 33 5 61 20 41 16 43 -26 11 -18 -14 -44 -29 -58
-35 -19 -7 -32 -24 -44 -55 -19 -48 -34 -56 -55 -30 -25 29 -47 34 -100 23
-48 -10 -53 -9 -77 13 -41 38 -51 73 -46 160 7 90 7 90 -104 103 -61 7 -63 8
-63 36 0 16 7 46 15 65 20 49 30 130 16 138 -18 11 -89 -13 -106 -37 -8 -12
-15 -31 -15 -43 0 -31 -22 -45 -82 -50 -67 -7 -104 15 -136 79 -30 61 -46 160
-32 200 5 16 10 49 10 73 0 49 16 68 85 105 43 23 44 23 117 6 94 -22 115 -23
98 -1 -11 13 -10 18 7 36 18 18 28 19 96 13 93 -9 109 -22 137 -113 19 -66 43
-102 68 -102 17 0 15 25 -8 106 -10 39 -23 90 -29 113 l-9 43 74 84 c41 45 74
87 74 91 0 5 9 14 21 21 12 7 18 16 15 19 -3 4 -1 12 5 19 7 9 7 24 -2 52 -20
68 8 126 32 65 l11 -28 12 36 c26 75 71 119 120 119 31 0 39 12 27 43 -17 45
20 83 119 123 86 34 130 42 130 24 0 -5 -5 -10 -10 -10 -17 0 -60 -47 -60 -65
0 -12 12 -7 49 17 48 32 98 63 133 83 10 5 18 13 18 17 0 15 -23 8 -52 -17
-34 -28 -48 -28 -63 1 -6 11 -18 18 -28 16 -17 -3 -77 50 -77 68 0 5 14 11 30
15 31 7 41 32 15 41 -21 8 -78 -13 -124 -47 -45 -32 -61 -36 -61 -15 0 17 82
91 124 113 17 9 73 19 126 23 94 7 227 43 242 64 11 15 10 68 -1 85 -7 10 -21
12 -53 7 -79 -12 -83 -11 -83 4 0 8 19 24 43 35 l42 21 -65 33 c-35 18 -71 44
-80 57 -8 13 -13 24 -10 24 3 0 -7 19 -24 43 -16 24 -34 62 -40 85 -7 23 -15
42 -18 42 -4 0 -21 -14 -39 -31 -18 -18 -50 -35 -75 -40 -42 -10 -45 -9 -71
21 -29 31 -35 54 -18 65 25 15 6 44 -56 85 -55 36 -77 45 -124 48 -54 4 -59 3
-76 -23 -12 -18 -19 -48 -19 -84 -1 -31 -5 -72 -9 -92 -6 -28 -3 -43 13 -70
26 -43 26 -79 1 -129 -12 -24 -24 -75 -29 -127 -10 -101 -25 -122 -71 -98 -15
8 -42 31 -60 51 -30 34 -32 41 -28 94 l4 57 -60 11 c-38 7 -88 28 -138 57 -42
25 -86 45 -97 45 -22 0 -41 27 -41 59 0 12 -6 22 -12 22 -7 1 -17 2 -23 4 -5
1 -15 3 -22 3 -19 3 -35 65 -24 94 5 14 31 52 58 84 27 32 56 73 64 89 11 24
22 31 55 35 43 6 94 33 94 50 0 5 -18 15 -40 21 -22 7 -40 15 -40 19 0 17 85
26 107 12 7 -4 15 -26 19 -48 5 -32 2 -45 -12 -59 -17 -17 -17 -18 1 -31 29
-21 57 -17 81 13 l22 28 37 -18 c20 -9 41 -15 47 -11 16 10 -43 64 -98 89 -28
12 -59 33 -68 46 l-16 23 59 12 c56 12 111 38 111 53 0 4 -7 17 -16 30 -10 14
-14 33 -10 51 7 37 -22 60 -76 60 -36 0 -38 -2 -32 -25 4 -18 -1 -37 -20 -65
-40 -61 -65 -51 -80 31 -4 21 -14 43 -22 50 -14 11 -20 8 -44 -20 -28 -35 -50
-35 -50 -1 0 11 -11 27 -25 36 -19 12 -23 22 -19 40 4 16 -1 30 -20 48 -31 32
-70 33 -90 4z m251 -1204 c-9 -9 -28 6 -21 18 4 6 10 6 17 -1 6 -6 8 -13 4
-17z m71 -77 c8 -6 27 -30 43 -53 25 -37 38 -46 89 -60 65 -18 82 -32 57 -45
-8 -5 -18 -23 -22 -39 -4 -17 -9 -30 -11 -30 -13 0 -53 58 -54 76 0 21 -17 34
-46 34 -17 0 -50 -63 -59 -115 -4 -22 -11 -47 -15 -55 -13 -22 -22 25 -18 90
3 52 5 56 42 76 l39 22 -29 18 c-19 13 -52 20 -106 23 l-77 3 57 39 c57 38 79
41 110 16z m357 -38 c0 -7 -8 -15 -17 -17 -18 -3 -25 18 -11 32 10 10 28 1 28
-15z m-115 -54 c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6 23 -9z
m60 -139 c-36 -18 -84 -45 -108 -60 -48 -30 -81 -31 -36 -1 59 39 170 94 189
94 11 0 -8 -14 -45 -33z"/>
                    <path d="M3030 5974 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z" />
                    <path d="M12414 5965 c-18 -14 -17 -14 12 -15 44 0 94 11 94 21 0 15 -85 10
-106 -6z"/>
                    <path d="M1497 5964 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M5790 5955 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
                    <path d="M5747 5934 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M8153 5924 c-6 -17 23 -43 50 -44 20 0 23 23 5 37 -26 20 -49 23 -55
7z"/>
                    <path d="M6717 5923 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
                    <path d="M2552 5878 c-17 -17 -15 -25 8 -48 21 -21 21 -21 65 -1 42 19 64 13
29 -7 -16 -9 -12 -32 6 -32 9 0 50 62 50 76 0 5 -21 8 -46 5 -25 -2 -51 2 -57
8 -14 14 -40 14 -55 -1z"/>
                    <path d="M7796 5864 c-19 -18 -13 -34 13 -34 20 0 41 19 41 37 0 18 -35 16
-54 -3z"/>
                    <path d="M3220 5856 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12 -12z" />
                    <path d="M4150 5860 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
                    <path d="M1354 5835 c-10 -8 -14 -15 -8 -15 6 0 17 7 24 15 16 19 9 19 -16 0z" />
                    <path d="M6587 5834 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M3237 5804 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M6525 5801 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
                    <path d="M3334 5775 c-16 -12 -17 -16 -5 -24 18 -11 41 -3 41 14 0 22 -15 26
-36 10z"/>
                    <path d="M3513 5688 c2 -7 10 -13 17 -13 7 0 15 6 18 13 2 7 -5 12 -18 12 -13
0 -20 -5 -17 -12z"/>
                    <path d="M5404 5673 c-39 -3 -53 -8 -57 -22 -5 -20 -63 -51 -99 -52 l-23 -1
25 -11 c14 -7 36 -22 48 -34 16 -17 33 -23 65 -23 42 0 134 33 182 64 l24 16
-22 35 c-24 37 -21 36 -143 28z"/>
                    <path d="M5230 5660 c-8 -5 -11 -12 -8 -15 9 -9 68 5 68 16 0 12 -41 11 -60
-1z"/>
                    <path d="M6397 5554 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M0 5514 c1 -30 55 -53 85 -37 14 8 14 11 2 22 -19 15 -87 27 -87 15z" />
                    <path d="M3320 5505 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
                    <path d="M3266 5503 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M3080 5445 c-10 -12 -9 -16 4 -21 16 -6 46 11 46 27 0 15 -37 10 -50
-6z"/>
                    <path d="M3204 5445 c-7 -18 2 -45 15 -45 12 0 24 37 16 50 -9 15 -25 12 -31
-5z"/>
                    <path d="M5807 5443 c-2 -4 -8 -25 -11 -47 -9 -49 14 -46 27 4 8 30 -4 64 -16
43z"/>
                    <path d="M6003 5344 c-9 -23 11 -38 26 -20 13 16 7 36 -10 36 -5 0 -12 -7 -16
-16z"/>
                    <path d="M3630 5335 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
                    <path d="M6766 5343 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M147 5324 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M5913 5238 c-45 -49 -54 -70 -46 -123 5 -40 11 -51 40 -67 46 -27 54
-65 21 -93 -14 -12 -29 -31 -32 -43 -5 -16 -2 -21 16 -24 37 -5 33 -32 -8 -51
-47 -23 -39 -31 17 -18 24 6 63 11 86 11 56 0 71 7 88 41 19 37 19 38 -9 49
-17 6 -29 22 -37 51 -7 22 -33 63 -57 91 -43 47 -44 50 -31 76 7 15 15 30 17
34 2 4 -10 5 -27 2 -38 -8 -41 12 -10 62 26 43 12 44 -28 2z"/>
                    <path d="M5805 5240 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"/>
                    <path d="M305 5231 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z" />
                    <path d="M6845 5231 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z" />
                    <path d="M645 5214 c-43 -22 -59 -40 -51 -59 5 -15 9 -14 36 5 29 20 56 58 48
65 -2 3 -17 -3 -33 -11z"/>
                    <path d="M1220 5184 c0 -17 22 -14 28 4 2 7 -3 12 -12 12 -9 0 -16 -7 -16 -16z" />
                    <path d="M5806 5183 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M6707 5183 c-11 -10 -8 -43 3 -43 15 0 24 30 13 41 -5 5 -12 6 -16 2z" />
                    <path d="M32 5129 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
                    <path d="M565 5121 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
                    <path d="M3207 5104 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M545 5080 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"/>
                    <path d="M1320 5064 c0 -31 23 -28 28 4 2 15 -2 22 -12 22 -11 0 -16 -9 -16
-26z"/>
                    <path d="M11925 5080 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6
6 -12 6 -17 -2z"/>
                    <path d="M6453 5054 c-9 -23 11 -38 26 -20 13 16 7 36 -10 36 -5 0 -12 -7 -16
-16z"/>
                    <path d="M6576 5053 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M5767 5043 c-4 -3 -7 -14 -7 -24 0 -10 -9 -21 -21 -24 -16 -5 -19
-12 -15 -30 3 -13 2 -27 -4 -30 -13 -8 -13 -55 0 -55 21 0 89 40 104 62 19 27
21 79 4 96 -13 13 -50 16 -61 5z"/>
                    <path d="M232 5028 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12 -12z" />
                    <path d="M11120 5013 c0 -10 -7 -36 -16 -60 -14 -35 -14 -48 -4 -75 6 -18 14
-86 17 -151 3 -65 9 -119 12 -122 4 -2 13 0 21 5 12 7 11 14 -4 42 -10 18 -16
47 -14 63 3 26 7 30 30 27 l27 -3 -21 48 c-15 34 -23 76 -26 146 -3 53 -9 97
-13 97 -5 0 -9 -8 -9 -17z"/>
                    <path d="M11997 5023 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
                    <path d="M132 4998 c7 -19 38 -22 38 -4 0 10 -9 16 -21 16 -12 0 -19 -5 -17
-12z"/>
                    <path d="M1320 4990 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20 -8
0 -15 -9 -15 -20z"/>
                    <path d="M77 4963 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
                    <path d="M3130 4938 c0 -16 19 -38 33 -38 4 0 7 10 5 22 -3 25 -38 39 -38 16z" />
                    <path d="M12172 4939 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>
                    <path d="M1350 4910 c1 -8 7 -28 16 -45 l14 -30 -5 35 c-5 35 -25 67 -25 40z" />
                    <path d="M11557 4827 c-3 -8 -1 -20 4 -28 11 -19 34 3 26 25 -8 20 -23 21 -30
3z"/>
                    <path d="M1530 4800 c0 -5 9 -10 21 -10 11 0 33 -13 47 -30 15 -16 31 -30 36
-30 15 0 -36 50 -66 66 -32 17 -38 17 -38 4z"/>
                    <path d="M4008 4767 c-56 -46 -68 -68 -47 -94 13 -15 50 -14 107 3 19 6 22 3
22 -25 0 -34 11 -39 30 -16 9 10 17 12 30 5 17 -9 30 -4 30 12 0 4 -9 8 -20 8
-17 0 -19 5 -14 24 8 32 -26 66 -66 66 -34 0 -37 6 -14 31 9 10 11 19 5 23 -5
4 -34 -13 -63 -37z"/>
                    <path d="M3785 4761 c-8 -13 35 -41 62 -41 31 0 29 24 -2 38 -31 14 -52 15
-60 3z"/>
                    <path d="M11536 4763 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M11487 4714 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M11445 4660 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6
6 -12 6 -17 -2z"/>
                    <path d="M4030 4635 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
                    <path d="M11357 4604 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M11306 4563 c-13 -14 -5 -33 14 -33 11 0 20 6 20 14 0 15 -24 28 -34
19z"/>
                    <path d="M11076 4544 c-3 -9 -6 -26 -6 -39 0 -13 -10 -30 -26 -40 -21 -13 -25
-22 -19 -43 4 -15 11 -33 17 -40 13 -16 33 -10 33 9 0 9 9 13 23 11 12 -2 34
6 48 17 15 12 33 21 40 21 17 0 37 37 30 56 -4 10 -13 13 -24 10 -12 -4 -32 4
-57 24 -43 34 -51 36 -59 14z"/>
                    <path d="M11252 4519 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>
                    <path d="M11246 4473 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M6364 4419 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
-16 -11z"/>
                    <path d="M11025 4328 c-3 -7 -9 -33 -13 -58 -7 -41 -10 -45 -35 -43 -24 2 -27
-1 -24 -30 2 -29 -1 -32 -25 -32 -16 0 -34 -10 -47 -27 -15 -18 -37 -30 -74
-38 -30 -6 -68 -24 -90 -41 -28 -23 -44 -29 -63 -25 -29 8 -29 6 -4 -44 11
-22 20 -52 20 -68 0 -16 6 -35 14 -41 16 -13 24 -6 55 57 16 31 27 42 45 42
13 0 30 7 37 16 11 14 9 19 -11 35 -22 16 -22 19 -7 19 11 0 31 -13 46 -29
l28 -30 21 21 c15 16 37 23 80 26 l59 4 13 57 c7 31 21 79 31 106 21 56 24 88
9 116 -12 21 -58 26 -65 7z"/>
                    <path d="M6364 4315 c-4 -8 -3 -23 0 -32 6 -15 8 -15 23 -1 16 17 12 48 -7 48
-6 0 -13 -7 -16 -15z"/>
                    <path d="M6198 4308 c-16 -5 -28 -17 -28 -25 0 -10 5 -13 18 -8 9 4 25 10 35
12 18 5 23 19 10 26 -5 2 -20 0 -35 -5z"/>
                    <path d="M6107 4254 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M6533 4203 c-22 -8 -14 -20 23 -38 30 -14 38 -15 45 -4 13 21 11 27
-10 39 -21 11 -38 12 -58 3z"/>
                    <path d="M6777 4194 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M6476 4153 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M6996 4153 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M6920 4125 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
                    <path d="M6546 4093 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M7210 4085 c0 -21 27 -30 45 -15 19 16 10 30 -21 30 -15 0 -24 -6
-24 -15z"/>
                    <path d="M6482 4069 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
                    <path d="M6938 4063 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z" />
                    <path d="M10547 3993 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
                    <path d="M3730 3955 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
                    <path d="M5442 3939 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
                    <path d="M10602 3939 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>
                    <path d="M5410 3766 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z" />
                    <path d="M5545 3760 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"/>
                    <path d="M10645 3760 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6
6 -12 6 -17 -2z"/>
                    <path d="M5470 3735 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
                    <path d="M3250 3704 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z" />
                    <path d="M5410 3704 c0 -17 22 -14 28 4 2 7 -3 12 -12 12 -9 0 -16 -7 -16 -16z" />
                    <path d="M3310 3695 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
                    <path d="M10577 3693 c-12 -11 -7 -43 6 -43 17 0 37 20 37 37 0 13 -32 18 -43
6z"/>
                    <path d="M7980 3645 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
                    <path d="M3250 3636 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12 -12z" />
                    <path d="M3327 3643 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
                    <path d="M10500 3605 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8
0 -15 -7 -15 -15z"/>
                    <path d="M3285 3600 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"/>
                    <path d="M3355 3600 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"/>
                    <path d="M10445 3600 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6
6 -12 6 -17 -2z"/>
                    <path d="M10340 3565 c-12 -35 -6 -75 11 -75 5 0 9 8 9 18 0 9 3 34 6 55 8 48
-10 50 -26 2z"/>
                    <path d="M3320 3551 c0 -11 40 -31 61 -31 5 0 9 7 9 15 0 8 -6 15 -12 15 -7 0
-23 3 -35 6 -14 4 -23 2 -23 -5z"/>
                    <path d="M3093 3525 c-39 -17 -41 -28 -8 -43 14 -6 25 -18 25 -27 0 -8 5 -15
11 -15 7 0 8 11 4 30 -6 29 -6 30 18 21 72 -28 150 -74 155 -93 4 -17 11 -19
45 -15 88 12 74 37 -56 101 -107 52 -149 61 -194 41z"/>
                    <path d="M3427 3513 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
                    <path d="M392 3489 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
                    <path d="M3482 3489 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
                    <path d="M440 3470 c-11 -7 -12 -12 -3 -21 14 -14 46 -4 41 13 -6 16 -20 19
-38 8z"/>
                    <path d="M3452 3448 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>
                    <path d="M517 3444 c-8 -8 4 -77 14 -89 6 -6 15 -3 26 8 16 15 16 18 0 35 -9
10 -17 24 -17 30 0 13 -15 24 -23 16z"/>
                    <path d="M9923 3375 c-3 -9 -3 -18 0 -21 7 -8 47 15 47 26 0 16 -40 11 -47 -5z" />
                    <path d="M3458 3351 c-9 -11 -14 -22 -10 -25 7 -8 88 -7 137 3 39 7 39 7 17
20 -13 7 -47 14 -75 17 -42 3 -55 0 -69 -15z"/>
                    <path d="M3675 3341 c-8 -15 3 -31 21 -31 9 0 14 7 12 17 -4 20 -24 28 -33 14z" />
                    <path d="M3266 3321 c-9 -13 19 -32 38 -25 25 10 19 34 -8 34 -14 0 -27 -4
-30 -9z"/>
                    <path d="M10343 3318 c-16 -21 -25 -116 -13 -138 6 -11 26 -26 44 -34 37 -16
76 -60 76 -87 0 -11 5 -19 10 -19 6 0 10 8 10 17 0 13 3 14 12 5 7 -7 17 -12
22 -12 6 0 -15 26 -45 58 -31 31 -66 70 -78 86 -21 29 -21 30 -2 50 25 28 30
47 19 69 -10 20 -41 23 -55 5z"/>
                    <path d="M3746 3313 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M3020 3285 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
                    <path d="M3800 3284 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z" />
                    <path d="M3860 3276 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z" />
                    <path d="M5150 3246 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z" />
                    <path d="M5235 3231 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
                    <path d="M3860 3215 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
                    <path d="M11227 3204 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M11997 3194 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M5185 3180 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"/>
                    <path d="M5235 3171 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z" />
                    <path d="M3870 3144 c-14 -35 -13 -44 5 -44 8 0 15 6 15 13 0 6 3 22 6 35 9
31 -14 28 -26 -4z"/>
                    <path d="M10470 3155 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8
0 -15 -7 -15 -15z"/>
                    <path d="M11186 3153 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M3932 3099 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
                    <path d="M10340 3090 c0 -11 7 -20 15 -20 8 0 15 9 15 20 0 11 -7 20 -15 20
-8 0 -15 -9 -15 -20z"/>
                    <path d="M3616 3093 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M9327 3094 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M3672 3069 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
                    <path d="M7950 3065 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
                    <path d="M3847 3064 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M10295 3061 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
                    <path d="M9327 3034 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M10387 3034 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M3895 3019 c-10 -15 3 -25 16 -12 7 7 7 13 1 17 -6 3 -14 1 -17 -5z" />
                    <path d="M3490 3003 c0 -5 5 -15 10 -23 8 -12 10 -11 10 8 0 12 -4 22 -10 22
-5 0 -10 -3 -10 -7z"/>
                    <path d="M10251 2969 c-23 -37 -26 -49 -13 -49 10 0 45 71 38 77 -3 3 -14 -10
-25 -28z"/>
                    <path d="M10516 2993 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M9715 2971 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
                    <path d="M11506 2933 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M12110 2926 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z" />
                    <path d="M10481 2914 c-18 -8 -39 -13 -47 -10 -12 5 -50 -41 -41 -51 3 -2 14
0 24 5 23 13 38 5 47 -25 8 -25 18 -28 46 -13 21 11 33 51 24 85 -7 28 -7 29
-53 9z"/>
                    <path d="M8885 2890 c-9 -39 2 -70 24 -70 34 0 44 24 27 64 -18 44 -42 47 -51
6z"/>
                    <path d="M8630 2895 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
                    <path d="M11400 2895 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6
2 -11 -4 -11 -13z"/>
                    <path d="M11462 2881 c2 -7 7 -12 11 -12 12 1 9 15 -3 20 -7 2 -11 -2 -8 -8z" />
                    <path d="M8630 2835 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
                    <path d="M10818 2838 c-7 -20 3 -49 16 -45 15 5 14 49 0 54 -7 2 -14 -2 -16
-9z"/>
                    <path d="M9376 2833 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M11056 2833 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M11300 2825 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6
2 -11 -4 -11 -13z"/>
                    <path d="M10117 2745 c-43 -41 -84 -75 -92 -75 -7 0 -20 -11 -29 -24 -8 -14
-28 -31 -43 -38 -24 -12 -28 -19 -25 -48 4 -47 59 -137 91 -149 36 -13 113
-21 139 -14 18 4 22 13 22 44 0 36 16 69 67 139 15 20 17 31 9 58 -6 17 -16
32 -22 32 -28 0 -25 23 7 56 l33 34 -29 30 c-16 16 -34 30 -39 30 -6 -1 -46
-34 -89 -75z"/>
                    <path d="M10327 2804 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M11240 2796 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12
-12z"/>
                    <path d="M8642 2779 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
                    <path d="M11837 2774 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M9457 2758 c55 -69 89 -120 103 -153 9 -22 28 -54 42 -70 14 -17 33
-47 43 -68 22 -46 109 -141 125 -135 15 5 52 98 45 116 -2 6 -12 12 -21 12
-25 0 -81 46 -90 72 -4 13 -43 64 -87 113 -59 67 -91 95 -125 108 -30 12 -42
14 -35 5z"/>
                    <path d="M8636 2733 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M9875 2711 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
                    <path d="M6326 2703 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M9446 2673 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M10606 2673 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M6297 2643 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
                    <path d="M2817 2614 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M9505 2600 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"/>
                    <path d="M10592 2598 c-14 -14 -15 -47 -3 -67 6 -10 12 -3 21 24 13 40 3 64
-18 43z"/>
                    <path d="M12145 2600 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6
6 -12 6 -17 -2z"/>
                    <path d="M6250 2575 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z"/>
                    <path d="M10334 2580 c-38 -15 -57 -138 -37 -233 18 -82 33 -80 30 4 -2 60 1
74 13 74 9 0 21 -19 31 -49 19 -62 39 -72 39 -21 0 27 -8 47 -31 72 -37 43
-34 57 14 61 52 4 36 22 -19 22 -32 0 -46 5 -51 16 -10 26 24 44 81 44 28 0
58 5 66 10 19 12 -105 13 -136 0z"/>
                    <path d="M10660 2544 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z" />
                    <path d="M2753 2534 c-7 -19 11 -39 26 -30 16 10 13 33 -4 40 -9 3 -18 -1 -22
-10z"/>
                    <path d="M2817 2543 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
                    <path d="M11956 2543 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M2872 2519 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
                    <path d="M10720 2510 c0 -5 15 -17 33 -26 43 -22 43 -21 23 -43 -21 -23 -12
-44 13 -36 43 13 191 -84 191 -125 0 -10 -7 -23 -15 -30 -22 -18 -18 -30 9
-30 13 0 43 -9 67 -20 58 -26 83 -25 113 5 34 34 68 32 99 -5 14 -16 27 -34
29 -40 4 -12 88 -43 96 -36 3 3 -14 24 -37 47 -29 29 -43 53 -47 81 -7 42 -20
54 -126 114 -70 40 -200 94 -225 94 -9 0 -29 -12 -45 -27 l-28 -27 -25 24
c-14 13 -25 33 -25 46 0 17 -9 24 -44 33 -54 13 -56 13 -56 1z"/>
                    <path d="M12170 2505 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6
2 -11 -4 -11 -13z"/>
                    <path d="M9560 2485 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
                    <path d="M10656 2492 c-8 -13 11 -35 23 -28 16 10 14 36 -3 36 -8 0 -17 -4
-20 -8z"/>
                    <path d="M10862 2489 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>
                    <path d="M11092 2488 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>
                    <path d="M11372 2489 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>
                    <path d="M6227 2483 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
                    <path d="M9890 2476 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12 -12z" />
                    <path d="M11257 2483 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
                    <path d="M10472 2459 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>
                    <path d="M10532 2459 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>
                    <path d="M12212 2459 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>
                    <path d="M12270 2445 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6
2 -11 -4 -11 -13z"/>
                    <path d="M9865 2440 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"/>
                    <path d="M10623 2429 c-39 -14 -27 -30 20 -27 23 2 41 7 40 13 -3 15 -36 23
-60 14z"/>
                    <path d="M11460 2427 c0 -7 10 -23 21 -36 29 -31 47 -13 24 23 -18 27 -45 35
-45 13z"/>
                    <path d="M9600 2421 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
                    <path d="M10536 2413 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M8026 2383 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M11397 2359 c-50 -31 -55 -40 -33 -58 11 -9 21 -6 45 15 35 30 55 60
43 68 -4 2 -29 -9 -55 -25z"/>
                    <path d="M7487 2353 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
                    <path d="M10762 2329 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>
                    <path d="M11300 2324 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z" />
                    <path d="M10455 2321 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
                    <path d="M10820 2316 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z" />
                    <path d="M11547 2324 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M7952 2299 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
                    <path d="M7470 2285 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
                    <path d="M11600 2285 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8
0 -15 -7 -15 -15z"/>
                    <path d="M9812 2279 c7 -22 51 -39 141 -55 45 -8 95 -20 110 -26 33 -13 45 -5
27 17 -23 28 -73 49 -135 56 -33 5 -80 11 -104 15 -35 6 -43 4 -39 -7z"/>
                    <path d="M10084 2279 c-10 -17 13 -36 27 -22 12 12 4 33 -11 33 -5 0 -12 -5
-16 -11z"/>
                    <path d="M10155 2280 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6
6 -12 6 -17 -2z"/>
                    <path d="M12285 2280 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6
6 -12 6 -17 -2z"/>
                    <path d="M10370 2254 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z" />
                    <path d="M11660 2256 c0 -15 24 -28 34 -19 13 14 5 33 -14 33 -11 0 -20 -6
-20 -14z"/>
                    <path d="M10576 2253 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M10690 2225 c0 -8 9 -15 20 -15 11 0 20 7 20 15 0 8 -9 15 -20 15
-11 0 -20 -7 -20 -15z"/>
                    <path d="M11615 2231 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
                    <path d="M12355 2231 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
                    <path d="M7487 2223 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
                    <path d="M10636 2223 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M11736 2221 c-3 -4 1 -17 9 -27 10 -15 16 -16 26 -7 6 6 9 19 6 27
-7 17 -32 22 -41 7z"/>
                    <path d="M10525 2208 c-26 -14 -85 -70 -85 -80 0 -16 37 -7 78 19 42 27 57 47
46 64 -7 11 -14 11 -39 -3z"/>
                    <path d="M10140 2195 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6
2 -11 -4 -11 -13z"/>
                    <path d="M10430 2195 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6
2 -11 -4 -11 -13z"/>
                    <path d="M10197 2194 c-12 -12 4 -24 34 -24 31 0 39 14 13 24 -20 8 -39 8 -47
0z"/>
                    <path d="M10314 2189 c-6 -10 11 -29 26 -29 3 0 11 4 19 9 17 11 5 31 -19 31
-10 0 -22 -5 -26 -11z"/>
                    <path d="M11702 2169 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>
                    <path d="M10282 2139 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>
                    <path d="M10370 2125 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6
2 -11 -4 -11 -13z"/>
                    <path d="M11775 2131 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
                    <path d="M11455 2101 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
                    <path d="M11720 2086 c0 -9 7 -16 16 -16 9 0 14 5 12 12 -6 18 -28 21 -28 4z" />
                    <path d="M10740 2066 c0 -8 -8 -20 -19 -25 -26 -14 -61 -85 -54 -110 5 -19 1
-21 -39 -21 -30 0 -54 7 -71 20 -14 11 -27 20 -30 20 -13 0 -87 -85 -83 -95 2
-7 0 -18 -4 -26 -7 -11 -13 -10 -29 5 -21 19 -21 19 -21 -15 0 -50 -43 -87
-180 -156 -141 -71 -157 -91 -122 -155 12 -24 22 -50 22 -59 0 -28 -14 -31
-32 -7 -27 35 -22 2 7 -47 31 -54 67 -191 67 -261 1 -46 5 -58 26 -74 33 -26
55 -25 106 5 23 14 66 29 95 35 29 5 71 23 94 39 61 42 187 66 250 47 60 -18
99 -47 122 -90 11 -20 22 -36 25 -36 3 0 16 20 29 45 29 54 56 62 48 13 -3
-18 -9 -41 -13 -52 -6 -16 -4 -17 13 -8 23 13 33 7 33 -20 0 -10 14 -37 30
-60 17 -22 30 -47 30 -54 0 -31 95 -49 125 -24 13 11 20 9 45 -15 l30 -29 33
31 c17 17 43 33 56 35 29 4 28 3 80 148 45 124 65 218 76 346 l7 82 -36 17
c-36 17 -126 123 -126 148 0 6 -13 19 -29 28 -36 21 -76 106 -84 178 -4 35
-15 65 -31 86 -14 18 -31 54 -38 79 -7 25 -15 46 -19 46 -11 0 -27 -49 -43
-127 -18 -86 -50 -163 -68 -163 -7 0 -44 24 -83 53 -81 60 -88 80 -56 153 12
26 20 48 19 49 -15 9 -72 25 -110 31 -40 5 -48 4 -48 -10z"/>
                    <path d="M7576 2063 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M7617 2034 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M7783 1981 c-16 -39 -32 -56 -76 -84 -68 -44 -77 -59 -77 -135 0 -33
-7 -76 -15 -95 -19 -47 -19 -82 1 -141 19 -56 34 -64 80 -43 38 18 38 18 99
279 28 117 36 168 31 200 -11 69 -20 73 -43 19z"/>
                    <path d="M7666 1993 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M10910 1980 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
                    <path d="M12705 1971 c-7 -12 1 -17 44 -26 26 -5 32 -4 29 6 -3 8 -12 14 -21
14 -8 0 -19 4 -23 8 -10 10 -22 9 -29 -2z"/>
                    <path d="M11937 1914 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M7975 1871 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
                    <path d="M11955 1860 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6
6 -12 6 -17 -2z"/>
                    <path d="M12382 1849 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>
                    <path d="M11996 1833 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M740 1804 c0 -17 22 -14 28 4 2 7 -3 12 -12 12 -9 0 -16 -7 -16 -16z" />
                    <path d="M12342 1808 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>
                    <path d="M782 1778 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12 -12z" />
                    <path d="M12607 1713 c-12 -11 -8 -23 8 -23 8 0 15 7 15 15 0 16 -12 20 -23 8z" />
                    <path d="M1190 1674 c0 -17 22 -14 28 4 2 7 -3 12 -12 12 -9 0 -16 -7 -16 -16z" />
                    <path d="M8080 1675 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
                    <path d="M11880 1676 c0 -16 42 -46 65 -46 22 0 18 27 -7 44 -28 20 -58 21
-58 2z"/>
                    <path d="M8026 1673 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M12566 1673 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M1047 1654 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M1280 1585 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
                    <path d="M1485 1540 c-4 -6 -3 -16 3 -22 6 -6 12 -6 17 2 4 6 3 16 -3 22 -6 6
-12 6 -17 -2z"/>
                    <path d="M1616 1513 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M2326 1453 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M2150 1415 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
                    <path d="M3220 1094 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z" />
                    <path d="M12180 1046 c0 -3 14 -30 30 -59 34 -60 37 -85 15 -126 -13 -27 -13
-29 10 -39 17 -8 25 -20 25 -38 0 -34 21 -27 41 16 9 18 23 38 32 45 9 6 19
23 22 38 6 24 4 27 -18 27 -17 0 -26 7 -30 21 -5 17 -9 19 -25 10 -18 -10 -23
-6 -41 33 -19 39 -61 89 -61 72z"/>
                    <path d="M10892 1009 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>
                    <path d="M11282 819 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
                    <path d="M11152 808 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>
                    <path d="M11204 735 c17 -66 25 -76 58 -73 26 2 33 9 43 38 17 47 4 60 -57 60
-48 0 -50 -1 -44 -25z"/>
                    <path d="M12160 738 c-11 -13 -44 -50 -74 -83 -29 -33 -67 -80 -85 -104 l-32
-45 48 -12 c66 -18 81 -11 94 42 6 24 24 61 40 81 16 19 29 40 29 45 0 6 9 21
20 35 15 19 17 30 9 44 -13 25 -25 24 -49 -3z"/>
                    <path d="M3377 707 c-3 -8 -1 -20 4 -28 11 -19 34 3 26 25 -8 20 -23 21 -30 3z" />
                    <path d="M12010 445 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
                    <path d="M3352 429 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
                    <path d="M8485 390 c-9 -15 14 -50 33 -50 19 0 42 18 42 32 0 4 -9 8 -19 8
-11 0 -23 5 -26 10 -8 13 -22 13 -30 0z"/>
                    <path d="M3350 354 c0 -9 5 -14 12 -12 18 6 21 28 4 28 -9 0 -16 -7 -16 -16z" />
                    <path d="M11926 323 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M3356 293 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z" />
                    <path d="M3949 268 c0 -2 -2 -10 -4 -18 -2 -8 -6 -22 -10 -31 -6 -17 10 -36
18 -22 12 21 18 73 8 73 -6 0 -11 -1 -12 -2z"/>
                    <path d="M3877 254 c-15 -15 -7 -45 11 -42 9 2 17 12 17 22 0 20 -16 31 -28
20z"/>
                    <path d="M3396 191 c-3 -4 3 -16 14 -26 11 -10 20 -24 20 -32 0 -7 5 -13 11
-13 10 0 4 52 -8 73 -6 10 -31 9 -37 -2z"/>
                    <path d="M8647 174 c-8 -8 1 -24 14 -24 5 0 9 7 9 15 0 15 -12 20 -23 9z" />
                    <path d="M3568 138 c-2 -28 -8 -34 -30 -36 -40 -5 -34 -20 10 -27 20 -4 49
-11 64 -17 32 -12 58 -5 58 15 0 14 -78 97 -91 97 -4 0 -9 -15 -11 -32z"/>
                    <path d="M4703 95 c31 -28 36 -29 57 -15 13 9 12 12 -4 25 -11 8 -34 15 -50
15 l-31 -1 28 -24z"/>
                    <path d="M3730 80 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
                    <path d="M3555 30 c-4 -7 -3 -16 3 -22 14 -14 45 -2 40 15 -6 16 -34 21 -43 7z" />
                </g>
            </svg>

            {/* Círculo para la ubicación del servidor seleccionado */}
            {location && (
                <svg
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        pointerEvents: 'none',
                    }}
                >
                    <circle
                        cx={location.x}
                        cy={location.y}
                        r="10"
                        fill="WHITE"
                    >
                        <title>{location.label}</title>
                    </circle>
                </svg>
            )}
        </div>
    );
};

export default WorldMap;
