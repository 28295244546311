import React, { Fragment } from 'react';
import { Typography } from 'antd';
import { CodeOutlined } from '@ant-design/icons';

const { Title, Paragraph } = Typography;

const LoopSample = () => (
  <Fragment>
 
 <Typography>
    <Title level={1}>Loop Sample Example</Title>
    <Paragraph>
      This example demonstrates how to iterate over a list of words, concatenate them into a single string, and return the final phrase. A loop is used to process each word in the list, appending it to the final phrase in every iteration.
    </Paragraph>

    <Title level={2}>Inputs:</Title>
    <ul>
      <li><strong>words</strong>: A list of words to be concatenated.</li>
      <li><strong>phrase</strong>: A variable used to build the final string.</li>
    </ul>

    <Title level={2}>Steps:</Title>
    <ol>
      <li>
        <strong>words = ["hello", "how", "are", "you"]</strong>
        <Paragraph>
          A list of words is defined and assigned to the <code>words</code> variable.
        </Paragraph>
      </li>
      <li>
        <strong>phrase = ""</strong>
        <Paragraph>
          The <code>phrase</code> variable is initialized as an empty string, which will be used to store the final concatenated phrase.
        </Paragraph>
      </li>
      <li>
        <strong>startLoop(control, 0, 4)</strong>
        <Paragraph>
          A loop is initiated starting from index <code>0</code> up to <code>4</code>, iterating through the <code>words</code> list.
        </Paragraph>
      </li>
      <li>
        <strong>iitemFromList(words, control, word)</strong>
        <Paragraph>
          During each iteration, a word is retrieved from the <code>words</code> list based on the <code>control</code> index and stored in the <code>word</code> variable.
        </Paragraph>
      </li>
      <li>
        <strong>phrase = "%s %s" % (phrase, word)</strong>
        <Paragraph>
          The current word is concatenated with the previous words in the <code>phrase</code>, with a space between each word.
        </Paragraph>
      </li>
      <li>
        <strong>endLoop()</strong>
        <Paragraph>
          The loop ends after all the words in the list have been processed.
        </Paragraph>
      </li>
      <li>
        <strong>addResult(phrase)</strong>
        <Paragraph>
          The final concatenated phrase is returned.
        </Paragraph>
      </li>
    </ol>

    <Title level={2}>Result:</Title>
    <Paragraph>
      The result is a string that contains all the words from the list, concatenated with spaces in between.
    </Paragraph>

    <div style={{ backgroundColor: '#f5f5f5', padding: '16px', borderRadius: '8px' }}>
      <pre>
        <code>{`" hello how are you"`}</code>
      </pre>
    </div>

    <Title level={2}>Usage Example:</Title>
    <div style={{ backgroundColor: '#f5f5f5', padding: '16px', borderRadius: '8px' }}>
      <pre>
        <code>
{`// defining a list of words
words = ["hello","how","are","you"]

// initializing a phrase
phrase = ""

// iterating over the list of words to add each word to final phrase
startLoop(control,0,4)
    iitemFromList(words,control,word)
    phrase = "%s %s" % (phrase,word)
endLoop()

addResult(phrase)`}
        </code>
      </pre>
    </div>

    <Title level={2}>Notes:</Title>
    <Paragraph>
      The loop iterates over each word in the <code>words</code> list, appending it to the final <code>phrase</code> with a space. The loop is defined from index 0 to 4, meaning it will process 4 words, assuming the list has at least 4 elements.
    </Paragraph>
  </Typography>
  </Fragment>
);
export default LoopSample;
