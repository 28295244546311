import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';

const Administrar_solicitudes_KYC_GET_EN = () => 
  {
    const codes ={
      answe_example: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "country_code": "MX", 
          "user_id": "4562", 
          "session_id": "385-XadgYoWixbzN_wqWc3Yyog==", 
          "signature": "00d4c87a1dd9c87ccda8518bb007835a71233069", 
          "phone": "7229063245", 
          "level_kyc": "2"
        }`,
        copied: false
      },
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": <boolean>, 
          "datos_usuario": { 
            "name": <string>, 
            "surname1": <string>, 
            "email": <string>, 
            "date_of_birth": <string>, 
            "surname2": <string> }, 
            "campos": [ { 
              "field_label": <string>, 
              "id": <string>, "type": <string>, 
              "value": <string> } ], 
              "status": <integer>, 
              "interview": <boolean>, 
              "elapsed": <float>
            }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": false, 
          "level": <string>, 
          "message": <string>, 
          "error": <string>
        }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requestsurl = "URL_BASE/ws/admin.py/administrar_solicitud_kyc?country_code=MX&user_id=2&session_id=393--ZCAQsFyJF95Q2YpqRNLug==&phone=7229063245&level_kyc=2"
        payload = {}
        headers= {}
        response = requests.request("GET", url, headers=headers, data = payload)
        print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requestsurl = "URL_BASE/ws/admin.py/administrar_solicitud_kyc?country_code=MX&user_id=2&session_id=393--ZCAQsFyJF95Q2YpqRNLug==&phone=7229063245&level_kyc=2"
          payload = {}
          headers= {}
          response = requests.request("GET", url, headers=headers, data = payload)
          print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
               'method': 'GET',
               'url': 'URL_BASE/ws/admin.py/administrar_solicitud_kyc?country_code=MX&user_id=2&session_id=393--ZCAQsFyJF95Q2YpqRNLug==&phone=7229063245&level_kyc=2',
               'headers': {
               }
              };
              request(options, function (error, response) { 
               if (error) throw new Error(error);
               console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var requestOptions = {
                method: 'GET',
                redirect: 'follow'
               };
               fetch("URL_BASE/ws/admin.py/administrar_solicitud_kyc?country_code=MX&user_id=2&session_id=393--ZCAQsFyJF95Q2YpqRNLug==&phone=7229063245&level_kyc=2", requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request GET 'URL_BASE/ws/admin.py/administrar_solicitud_kyc?country_code=MX&user_id=2&session_id=393--ZCAQsFyJF95Q2YpqRNLug%3D%3D&phone=7229063245&level_kyc=2'`
        }
      ]
    }
  
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="manage-kyc-requests-get">Manage KYC Requests &#40;Get&#41;</h1>
        <p>This service is used to get a KYC level up request.</p>
        <p>
          <span className="label get">GET:</span>{' '}
          <code>URL_BASE + /ws/admin.py/administrar_solicitud_kyc</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All parameters are sent in the querystring of the call, so a percentage
          encoding for URI must be applied &#40;aka URL encoding&#41;.
        </p>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  ISO 3166 alpha-2
                </a>
              </td>
              <td align="center">Yes</td>
              <td align="center">
                Country code of the user in which the user resides
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Identifier of the Administrator user, who will manage the KYC level
                up request.
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">phone</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Telephone number of the user who made the request.
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">level_kyc</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">Level KYC of the request we want to review.</td>
              <td align="center">N/A</td>
            </tr>
          </tbody>
        </table>
        <h3 id="json-example">JSON Example:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_example}/>
          </div>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>datos_usuario:</code> Indicates the data of the user who made the
            request.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>name:</code> Name of the user making the request.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>surname1:</code> First surname of the user making the request.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>surname2:</code> Second surname of the user making the request.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>email:</code> Email of the user who makes the request.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>date_of_birth:</code> Date of birth of the user making the
            request.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>campos:</code> List with the fields to be reviewed by the
            administrator making the query.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>field_label:</code> Text to display in the label of each field of
            the form.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>id:</code> Html identifier of the field.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>type:</code> It has the value{' '}
            <code>&quot;numeric_text&quot;</code>,{' '}
            <code>&quot;free_text&quot;</code>,{' '}
            <code>&quot;multiple_select&quot;</code>, <code>&quot;date&quot;</code>,{' '}
            <code>&quot;file&quot;</code>. Indicates the type of data that the field
            accepts.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>value:</code> Path where the document uploaded by the user is
            located, or field value.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Indicates the status of the request &#40;-1:
            Rejected, 0: In process&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>interview:</code> Indicates whether they are advisable to have a
            personal interview with the user requesting the upload level_kyc or not.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
       
        <h2 id="where-1">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>level:</code> Error importance level.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code> Error message.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          This service returns a request with the details for your review, and thus
          is able to approve, reject or modify it.
        </p>
        </div>

<div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>

        </div>
        </div>
      </Fragment>
    );
  }
export default Administrar_solicitudes_KYC_GET_EN;
