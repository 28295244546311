import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';

const AddCertificateForm = () => {
    const [certificateFile, setCertificateFile] = useState(null);
    const [fileName, setFileName] = useState("No se eligió ningún archivo");

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setCertificateFile(file);
            setFileName(file.name);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!certificateFile) {
            alert("Por favor, selecciona un archivo de certificado antes de subirlo.");
            return;
        }
        console.log("Certificado subido:", certificateFile);
        // Aquí puedes agregar la lógica para enviar el archivo al servidor
    };

    const handleCancel = () => {
        setCertificateFile(null);
        setFileName("No se eligió ningún archivo");
        console.log("Formulario reiniciado");
    };

    return (
        <Form className="cloudForm" onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
            <h2>Add Certificate</h2>

            {/* Upload Certificate Section */}
            <Form.Group controlId="certificateUpload">
                <Form.Label>Upload Certificate</Form.Label>
                <div className="custom-file">
                    <Form.Control
                        type="file"
                        className="custom-file-input"
                        onChange={handleFileChange}
                        accept=".pem"
                    />
                    <Form.Label className="custom-file-label">{fileName}</Form.Label>
                </div>
                <Form.Text className="text-muted mt-2">
                    Upload new SSL certificate in PEM format. If the certificate should have a private key,
                    convert both the public certificate and the associated private key to PEM format and concatenate
                    them to a single file.
                </Form.Text>
            </Form.Group>

            {/* Fixed Buttons */}
            <div className="d-flex justify-content-between mt-4">
                <Button variant="primary" type="submit">
                    Upload Certificate
                </Button>
                <Button variant="outline-secondary" onClick={handleCancel}>
                    Cancel
                </Button>
            </div>
        </Form>
    );
};

export default AddCertificateForm;
