import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
     <div>
      <h3>Explanation:</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;program&gt;:</strong> A program is a list of statements.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;statement_list&gt;:</strong> A list of statements can be a single statement or a statement followed by another list of statements.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;statement&gt;:</strong> A statement can be a global assignment, a local assignment, or a command.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;global_assignment&gt;:</strong> A global assignment follows the format <code>addVar('value', variable_name)</code>.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;local_assignment&gt;:</strong> A local assignment follows the Python syntax <code>variable_name = value</code>.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;string_value&gt;:</strong> A string value is enclosed in double quotes and contains string content.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;string_content&gt;:</strong> The content of a string can be a string part or a string part followed by more string content.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;string_part&gt;:</strong> A string part can be literal text or a variable reference.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;text&gt;:</strong> Text is a series of characters.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;variable_reference&gt;:</strong> A variable reference follows the format <code>$ variable </code>.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;variable_name&gt;:</strong> A variable name can be a letter or a combination of letters.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;value&gt;:</strong> A value can be a string value, a number, or an expression.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;number&gt;:</strong> A number can be a digit or a series of digits.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;expression&gt;:</strong> An expression can be a value or a combination of two values with an operator.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;operator&gt;:</strong> An operator can be <code>+</code>, <code>-</code>, <code>*</code>, or <code>/</code>.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;command&gt;:</strong> A command can be any valid command syntax.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;character&gt;:</strong> A character can be any character except double quotes and the backslash.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;letter&gt;:</strong> A letter can be an alphabetical character, a digit, or an underscore.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>&lt;digit&gt;:</strong> A digit is a number from 0 to 9.
        </li>
      </ul>
      <p>
        This BNF notation covers the assignment of global and local variables, as well as variable substitution in strings.
      </p>
    </div>
  </Fragment>
);
export default Intro_1;
