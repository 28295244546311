import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h3>1.1 Features of AVAP™ as a Dynamic Language</h3>
      <p>
        AVAP™ is characterized by its dynamic nature, which means it offers various features that allow flexibility and adaptability 
        in program development. Below, we will detail some of these features:
      </p>
    </div>
  </Fragment>
);
export default Intro_1;
