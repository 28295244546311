import React, { Fragment } from 'react';
import './chapter.css'
const Chapter10_2 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>6.1 Syntax of the IF-THEN-ELSE Statement</h2>
      <p>The basic syntax of the IF-THEN-ELSE statement in AVAP™ is as follows:</p>
      <pre>
        <code>
          {`IF(condition, true_value, operator) 
            // Block of code if the condition is true ELSE 
            // Block of code if the condition is false END()`}
        </code>
      </pre>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>condition</strong>: This is an expression that evaluates to either true or false.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>true_value</strong>: This is the value assigned if the condition is true.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>operator</strong>: This is the operator used to compare the condition with the true value.</li>
      </ul>
    </div>
  </Fragment>
);

export default Chapter10_2;