import React, { FunctionComponent, useEffect, useState } from 'react';

import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import "./SidebarAVAPCLOUD.css";
// import "./../NewDashboard.css";
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import BasicImgUser from '../../../assets/img/icono.png';
import SessionService from '../../../services/SessionsService';

const SidebarAVAPCLOUD = () => {
  let { path, url } = useRouteMatch();

  const history = useHistory();
  const [get_personal_info, setGet_personal_info] = useState<any>({});
  const [showModalImg, setShowModalImg] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [disabledButtonSendImg, setDisabledButtonSendImg] = useState(false);

  const handleClose = () => setShowModalImg(false);




  const changeImg = () => {
    setShowModalImg(true)
    console.log("changeImg");

  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };


  const handleSubmit = async() => {
    // Aquí puedes enviar la imagen al servidor o realizar otras acciones necesarias
      // Restablece el estado después de enviar la imagen
    if (selectedImage) {

      setDisabledButtonSendImg(true);

      const safedstring = selectedImage.toString(); 
      const base64SafedString = btoa(safedstring);

      const payload = { image: base64SafedString };
      
      const result = await ObexRequestHandler.post('/update_avatar', payload);

      // debugger
      if(result.success && result.success==true){
        setSelectedImage(null);
        setShowModalImg(false);
        setDisabledButtonSendImg(false);
        // getPersonalInformation(); //re-render
      }

      
      
    } else {
      console.log('No se ha seleccionado ningún archivo');
      setDisabledButtonSendImg(false)
    }
  };

  const decode_atob =(text)=>{
    const decodedText = atob(text);
    return decodedText;

  } 

  useEffect(() => {

  }, [])

  return (

    <div className='d-flex flex-column'>
      {/* <div className='marginTopProfile mb-3'>
        {get_personal_info && get_personal_info.image ?
          <div >
            <img className='sizeProfile' src={decode_atob(get_personal_info.image)} alt="" width={"100%"} height={"100%"} onClick={() => changeImg()} />
          </div> :
          <div className='sizeProfile border d-flex justify-content-center align-items-center'>
            <div>
              <img src={BasicImgUser} alt="" onClick={() => changeImg()} />
            </div>

          </div>
        }
      </div> */}

      {/* <div className='textBold colorBlack fontSizeProfile'>
        {get_personal_info && get_personal_info.first_name} {get_personal_info && get_personal_info.last_name}
      </div>
      <div className='colorGreen2 mb-4 fontSizeEmail'>
        {get_personal_info && get_personal_info.email}
      </div> */}



      <div className='d-flex flex-column colorBlack2'>

        <div className={`mb-3 ${location.pathname === `${url}/personal-information` ? 'active' : ''}`}>
          <Link to={`${url}/personal-information`} className="colorBlack2 fontSizeLinks">Personal Information</Link>
        </div>

        <div className={`mb-3 ${location.pathname === `${url}/projects` ? 'active' : ''}`}>
          <Link to={`${url}/projects`} className="colorBlack2 fontSizeLinks">Sign-In and Security</Link>
        </div>

        <div className={`mb-3 ${location.pathname === `${url}/licenses-and-downloads` ? 'active' : ''}`}>
          <Link to={`${url}/licenses-and-downloads`} className="colorBlack2 fontSizeLinks">Licenses and Downloads</Link>
        </div>

        <div className={`mb-3 ${location.pathname === `${url}/project-granted` ? 'active' : ''}`}>
          <Link to={`${url}/project-granted`} className="colorBlack2 fontSizeLinks">Projects Granted</Link>
        </div>

        <div className={`mb-3 ${location.pathname === `${url}/payments-and-billing` ? 'active' : ''}`}>
          <Link to={`${url}/payments-and-billing`} className="colorBlack2 fontSizeLinks">Payments & Billing</Link>
        </div>

        <div className={`${location.pathname === `${url}/privacy` ? 'active' : ''}`}>
          <Link to={`${url}/privacy`} className="colorBlack2 fontSizeLinks">Privacy</Link>
        </div>
        {/* <ul>
          <li>
            <Link to={`${url}/personal-information`}>Personal Information</Link>
          </li>
          <li>
            <Link to={`${url}/sigin-and-security`}>Sign-In and Security</Link>
          </li>
          <li>
            <Link to={`${url}/licenses-and-downloads`}>Licenses and Downloads</Link>
          </li>
          <li>
            <Link to={`${url}/project-granted`}>Project Granted</Link>
          </li>
          <li>
            <Link to={`${url}/payments-and-billing`}>Payments & Billing</Link>
          </li>
          <li>
            <Link to={`${url}/privacy`}>Privacy</Link>
          </li>
        </ul> */}

      </div>


      {/* MODAL BEGIN */}
      {/* <Modal show={showModalImg} onHide={handleClose} id="modalImg">
        <Modal.Header closeButton>
          <Modal.Title>Select an image for you avatar</Modal.Title>
        </Modal.Header>
        <Modal.Body className=''>
          <Form >
            <Form.Group controlId="formFile" className='m-0'>
              <Form.Control type="file" accept=".jpg,.jpeg,.png" onChange={handleFileChange} />
            </Form.Group>
            {selectedImage && (
              <div className='d-flex justify-content-center align-items-center'>
                <img src={selectedImage} alt="Preview" style={{ maxWidth: '100%', marginTop: '10px' }} />
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-center w-100'>
            <button className="btn custom-button " onClick={handleSubmit} disabled={disabledButtonSendImg}>Send</button>

          </div>
        </Modal.Footer>
      </Modal> */}
      {/* MODAL END */}

    </div>
  )
}

export default SidebarAVAPCLOUD;