import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const Consultar_Solucion_EN = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status": <boolean>,
        "result": <solucion>,
        "elapsed": <float>,
        "codtran": <string>
    }`,
      copied: false
    },
    answe_ok2: {
      title: "Points Program Solution",
      type: "json",
      code: `{
        "point_value": <decimal>,
        "until": <string>,
        "from": <string>,
        "solution_id": <integer>,
        "name": <string>,
        "active": <boolean>
    }`,
      copied: false
    },
    answe_ok3: {
      title: "Charge order type solution",
      type: "json",
      code: `{
        "acceptance_conditions": <string>,
        "until": <string>,
        "from": <string>,
        "solution_id": <integer>,
        "name": <string>,
        "active": <boolean>
    }`,
      copied: false
    },
    answe_ok4: {
      title: "Refund promotion type solution",
      type: "json",
      code: `{
        "minimum_amount": <integer>,
        "refund_percentage": <integer>,
        "until": <string>,
        "from": <string>,
        "affiliate_id": <integer>,
        "solution_id": <integer>,
        "name": <string>,
        "active": <boolean>
    }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
    url = "URL_BASE/ws/ws_soluciones.py/gestorsoluciones?country_code=MX&user_id=4532&session_id=439-InjyJc39dwPumPisHS5xgw==&command=consultation&solution_id=8"
    payload = {}
    files = {}
    headers= {}
    response = requests.request("GET", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
    url = "URL_BASE/ws/ws_soluciones.py/gestorsoluciones?country_code=MX&user_id=4532&session_id=439-InjyJc39dwPumPisHS5xgw==&command=consultation&solution_id=8"
    payload = {}
    files = {}
    headers= {}
    response = requests.request("GET", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'GET',
  'url': 'URL_BASE/ws/ws_soluciones.py/gestorsoluciones?country_code=MX&user_id=4532&session_id=439-InjyJc39dwPumPisHS5xgw==&command=consultation&solution_id=8',
  'headers': {},
  formData: {}
  };
  request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
  });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var formdata = new FormData();
  var requestOptions = {
  method: 'GET',
  body: formdata,
  redirect: 'follow'
  };
  fetch("URL_BASE/ws/ws_soluciones.py/gestorsoluciones?country_code=MX&user_id=4532&session_id=439-InjyJc39dwPumPisHS5xgw==&command=consultation&solution_id=8", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request GET 'URL_BASE/ws/ws_soluciones.py/gestorsoluciones?country_code=MX&user_id=4532&session_id=439-InjyJc39dwPumPisHS5xgw%3D%3D&command=consultation&solution_id=8'`
      }
    ]
  }

  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 id="examine-solution">Examine Solution</h1>
          <p>This service is used to query a system solution.</p>
          <p>
            <span className="label get">GET:</span>
            <span> </span>{' '}
            <code>URL_BASE + /ws/ws_soluciones.py/gestorsoluciones</code>
          </p>
          <h2 id="receives">Receives:</h2>
          <p>
            All parameters are sent in the querystring of the call, so a percentage
            encoding for URI must be applied &#40;aka URL encoding&#41;.
          </p>
          <table>
            <thead>
              <tr>
                <th align="left">Parameter</th>
                <th align="center">Format</th>
                <th align="center">Obligatory</th>
                <th align="center">Description</th>
                <th align="center">Default value</th>
                <th align="center">Example</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="left">country_code</td>
                <td align="center">
                  ISO 3166 alpha-2 &#40;
                  <a href="https://www.iso.org/glossary-for-iso-3166.html">
                    https://www.iso.org/glossary-for-iso-3166.html
                  </a>
                  &#41;
                </td>
                <td align="center">Yes</td>
                <td align="center">Country code where the user resides</td>
                <td align="center">N/A</td>
                <td align="center">ES &#40;Spain&#41;</td>
              </tr>
              <tr>
                <td align="left">user_id</td>
                <td align="center">Integer</td>
                <td align="center">Yes</td>
                <td align="center">
                  Yes &#40;if you do not send signature to &#39;Access
                  Service&#39;&#41;
                </td>
                <td align="center">
                  Obtained by identifying the user with ID user_id on the platform
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="left">session_id</td>
                <td align="center">String</td>
                <td align="center">
                  Yes &#40;if you do not send session_id to &#39;Access
                  Service&#39;&#41;
                </td>
                <td align="center">
                  Enables you to sign the request without having to log in
                </td>
                <td align="center">N/A</td>
                <td align="center"></td>
              </tr>
              <tr>
                <td align="left">firma</td>
                <td align="center">String</td>
                <td align="center">
                  Yes &#40;if you do not send session_id to &#39;Access
                  Service&#39;&#41;
                </td>
                <td align="center">
                  Enables you to sign the request without having to log in
                </td>
                <td align="center">N/A</td>
                <td align="center"></td>
              </tr>
              <tr>
                <td align="left">command</td>
                <td align="center">String</td>
                <td align="center">Yes</td>
                <td align="center">
                  It must contain the value <code>consultation</code>
                </td>
                <td align="center">N/A</td>
                <td align="center">consultation</td>
              </tr>
              <tr>
                <td align="left">solution_id</td>
                <td align="center">Integer</td>
                <td align="center">Yes</td>
                <td align="center">Identifier of the solution to consult</td>
                <td align="center">N/A</td>
                <td align="center">1</td>
              </tr>
            </tbody>
          </table>
          <h2 id="returns">Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>
          <h3 id="answer-json-ok">Answer JSON OK:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2 id="where">Where:</h2>
          <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>solution:</code> More detail in the business logic.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>elapsed:</code> Operation execution time.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>codtran:</code> Transaction code that identifies the executed
              operation.
            </li>
          </ul>
          <h3 id="answer-json-ko">Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 id="business-logic">Business Logic:</h2>
          <p>This service returns the data of a solution.</p>
          <h3 id="points-program-solution">Points Program Solution:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok2} />
          </div>
          <h2 id="where-2">Where:</h2>
          <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>point_value:</code> Value in pesos of 1 point
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>until:</code> Solution end date.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>from:</code> Solution start date.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>solution_id:</code> Identifier of the solution.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>name:</code> Name assigned to the solution.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>active:</code> Indicates if the solution is active or not.
            </li>
          </ul>
          <h3 id="charge-order-type-solution">Charge order type solution:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok3} />
          </div>
          <h2 id="where-3">Where:</h2>
          <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>acceptance_conditions:</code> Conditions that the user will
              accept.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>until:</code> Solution end date.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>from:</code> Solution start date.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>solution_id:</code> Identifier of the solution.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>name:</code> Name assigned to the solution.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>active:</code> Indicates if the solution is active or not.
            </li>
          </ul>
          <h3 id="refund-promotion-type-solution">Refund promotion type solution:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok4} />
          </div>
          <h2 id="where-4">Where:</h2>
          <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>minimum_amount:</code> Amount from which the refund occurs.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>refund_percentage:</code> Percentage to be reimbursed for each
              purchase.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>until:</code> Solution end date.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>from:</code> Solution start date
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>affiliate_id:</code> Identifier of the affiliate making the
              refund.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>solution_id:</code> Identifier of the solution.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>name:</code> Name assigned to the solution.
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
              <code>active:</code> Indicates if the solution is active or not.
            </li>
          </ul>

        </div>
        <div className='col-md-5'>
          <h2 id="example-requests">Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>

      </div>



    </Fragment>
  );
}
export default Consultar_Solucion_EN;
