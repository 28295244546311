import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h1>Working with Variables</h1>
  <p>
    In this chapter, we will explore in detail working with variables in AVAP™. Variables are fundamental elements in programming as they allow us to store and manipulate data within a program. Throughout this chapter, we will examine the importance of variables, the types of local and global variables, as well as the different ways to declare them in AVAP™.
  </p>
</div>
  </Fragment>
);
export default Intro_1;
