import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Listado_de_movimientos_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{ "status": true, 
        "data": [ [ 
          <string> ] ], 
          "number_of_results": <integer>, 
          "totals": <integer>, 
          "columns": [<string>], 
          "limit_of_results": <integer>, 
          "elapsed": <float> 
        }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{ "status": false,
         "level": <string>, 
         "message": <string>, 
         "error": <string> 
        }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/movimientos.py/listado?country_code=MX&user_id=2&session_id=387-M301Sm9r7MK6QXs27Z_XHw==&phone=7229063245&number_of_movements=2&sartsdate=1-04-2020&enddate=29-05-2020" 
        payload = {}
         headers= {} 
         response = requests.request("GET", url, headers=headers, data = payload) 
        print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests url = "URL_BASE/ws/movimientos.py/listado?country_code=MX&user_id=2&session_id=387-M301Sm9r7MK6QXs27Z_XHw==&phone=7229063245&number_of_movements=2&sartsdate=1-04-2020&enddate=29-05-2020" 
          payload = {}
           headers= {} 
           response = requests.request("GET", url, headers=headers, data = payload) 
          print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'GET',
              'url': 'URL_BASE/ws/movimientos.py/listado?country_code=MX&user_id=2&session_id=387-M301Sm9r7MK6QXs27Z_XHw==&phone=7229063245&number_of_movements=2&sartsdate=1-04-2020&enddate=29-05-2020',
              'headers': {
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var requestOptions = {
                method: 'GET',
                redirect: 'follow'
                };
                fetch("URL_BASE/ws/movimientos.py/listado?country_code=MX&user_id=2&session_id=387-M301Sm9r7MK6QXs27Z_XHw==&phone=7229063245&number_of_movements=2&sartsdate=1-04-2020&enddate=29-05-2020", requestOptions)
                .then(response => response.text())
                .then(result => console.log(result))
                .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request GET 'URL_BASE/ws/movimientos.py/listado?country_code=MX&user_id=2&session_id=387-M301Sm9r7MK6QXs27Z_XHw==&phone=7229063245&number_of_movements=2&sartsdate=1-04-2020&enddate=29-05-2020'`
        }
      ]
    }
    return(

      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="list-of-movements">List of Movements</h1>
        <p>
          This service is used to obtain the movements that a user has made within
          the system.
        </p>
        <p>
          <span className="label get">GET:</span>
          <span> </span> <code>URL_BASE + /ws/movimientos.py/listado</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All parameters are sent in the querystring of the call, so a percentage
          encoding for URI must be applied &#40;aka URL encoding&#41;.
        </p>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  ISO 3166 alpha-2
                </a>
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Identifier of the user making the request &#40;Admin or
                Affiliate&#41;
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">phone</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Phone number of the user whose movements we want to see.
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">number_of_movements</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Number of movements that we want to visualize per page.
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">pag</td>
              <td align="center">Integer</td>
              <td align="center">No</td>
              <td align="center">Page we want to obtain.</td>
              <td align="center">1</td>
            </tr>
            <tr>
              <td align="left">sartsdate</td>
              <td align="center">
                String &#40;dd/mm/yyyy, dd-mm-yyyy, yyyy/mm/dd, yyyy-mm-dd&#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">
                Date from which the movements begin to be posted.
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">enddate</td>
              <td align="center">
                String &#40;dd/mm/yyyy, dd-mm-yyyy, yyyy/mm/dd, yyyy-mm-dd&#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Date until which the movements are posted.</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">csv</td>
              <td align="center">Boolean</td>
              <td align="center">No</td>
              <td align="center">
                It returns a csv with the user&#39;s movements.
              </td>
              <td align="center">False</td>
            </tr>
            <tr>
              <td align="left">without_commission</td>
              <td align="center">Boolean</td>
              <td align="center">No</td>
              <td align="center">It does not return commission-type movements.</td>
              <td align="center">False</td>
            </tr>
          </tbody>
        </table>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>data:</code> A list of lists in which each one contains the values
            ​​for each of the columns.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>number_of_results:</code> Number of results that come in
            &#39;data&#39;
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>totals:</code> Total number of results
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>columns:</code> Name of each of the values found in each of the
            lists in the data list.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>limit_of_results:</code>Maximum number of results that come in the
            query.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>level:</code> Error importance level.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code> Error message.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          This service, through the &#39;telephone&#39;, searches for the user whose
          movements are to be consulted, and returns a list with each of the
          user&#39;s movements between two given dates.
        </p>
        </div>

      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
        </div>
      </Fragment>
    );
  }
export default Listado_de_movimientos_EN;
