import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_13 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>AddVariableToJSON()</h2>
      <p>
        The <code>AddVariableToJSON()</code> command adds a new key and its corresponding value to a JSON object and stores the result in a target variable. This command is useful for updating a JSON object with new key-value pairs.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Key</strong> <br />
          Type: <code>variable</code> <br />
          Description: The key to be added to the JSON object. It must be a variable that stores the key to be added.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Value</strong> <br />
          Type: <code>variable</code> <br />
          Description: The value associated with the key to be added to the JSON object. It must be a variable that stores the corresponding value.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>TargetVariable</strong> <br />
          Type: <code>variable</code> <br />
          Description: The variable where the updated JSON object will be stored. It must be a variable that will receive the JSON object with the new key and its added value.
        </li>
      </ul>
      <h3>Command Flow</h3>
      <ol>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Access the JSON Object:</strong> Access the JSON object stored in the <code>TargetVariable</code>.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Add the Key and Value:</strong> Add the new key and its associated value to the JSON object.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Store the Result:</strong> Save the updated JSON object in the variable specified by <code>TargetVariable</code>.</li>
      </ol>
      <h3>Usage Example</h3>
      <p>Suppose the initial JSON object in <code>jsonData</code> is <code>"name": "Alice", "age": 30</code>, and you want to add a new key <code>"email"</code> with the value <code>"alice@example.com"</code>.</p>
      <pre>
        <code>
          {`// Variable definitions
jsonData = {"name": "Alice", "age": 30}
newKey = "email"
newValue = "alice@example.com"

// Call the command to add the new key and value to the JSON object
AddVariableToJSON(newKey, newValue, jsonData)

// Return the updated JSON object through addResult
addResult(jsonData)`}
        </code>
      </pre>
      <p>This updated JSON object will be stored in the variable <code>jsonData</code> and will be returned through <code>addResult(jsonData)</code>, resulting in the following output:</p>
      <pre>
        <code>
          {`{
  "name": "Alice",
  "age": 30,
  "email": "alice@example.com"
}`}
        </code>
      </pre>
    </div>
  </Fragment>
);

export default Appendices_13;
