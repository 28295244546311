import React from 'react';
import './chapter.css'
const Chapter9_33 = () => (
    <div>
        <h2>Raise Statement</h2>
        <p>
            In AVAP, the <strong>raise</strong> statement is used to throw an exception. The syntax for the <strong>raise</strong> statement is as follows:
        </p>
        <pre>
            <code>
                raise [expression ["from" expression]]
            </code>
        </pre>
        <p>
            If no expressions are present, <strong>raise</strong> re-raises the currently handled exception, also known as the active exception. If there is no active exception, a <code>RuntimeError</code> is raised indicating that it is an error.
        </p>
        <p>
            Otherwise, <strong>raise</strong> evaluates the first expression as the exception object. It must be a subclass or an instance of <code>BaseException</code>. If it is a class, the exception instance is obtained when needed by creating an instance of the class without arguments.
        </p>
        <p>
            The type of the exception is the instance of the exception class, and the value is the instance itself.
        </p>
        <p>
            The <code>from</code> clause is used for exception chaining: if provided, the second expression must be another class or instance of exception. If the second expression is an exception instance, it will be attached to the raised exception as the <code>__cause__</code> attribute (which is modifiable). If the expression is an exception class, the class will be instantiated and the resulting exception instance will be attached to the raised exception as the <code>__cause__</code> attribute. If the raised exception is not handled, both exceptions will be printed.
        </p>
        <pre>
            <code>
                startLoop()
                try:
                    print(1 / 0)
                except Exception as exc:
                    raise RuntimeError("Something went wrong") from exc
                endLoop()
            </code>
        </pre>
        <p>
            A mechanism works implicitly if a new exception is raised while an exception is already being handled. An exception may be handled by an <code>except</code> or <code>finally</code> clause, or a <code>with</code> statement. The previous exception is then attached as the new exception’s <code>__context__</code> attribute:
        </p>
        <pre>
            <code>
                startLoop()
                try:
                    print(1 / 0)
                except:
                    raise RuntimeError("Something went wrong") from None
                endLoop()
            </code>
        </pre>
        <p>
            Exception chaining can be explicitly suppressed by specifying <code>None</code> in the <code>from</code> clause:
        </p>
        <pre>
            <code>
                startLoop()
                try:
                    print(1 / 0)
                except:
                    raise RuntimeError("Something went wrong") from None
                endLoop()
            </code>
        </pre>
    </div>
);

export default Chapter9_33;
