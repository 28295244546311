import React from 'react';
import './chapter.css'
const Chapter9_3 = () => (
    <div>
        <h2>4.2.2. Name Resolution</h2>
        <p>
            A scope defines the visibility of a name in a code block. In AVAP, if a variable is defined in a code block, its scope includes that block. The scope of a variable within a function extends to the entire function block.
        </p>
        <p>
            When a name is used in a code block, it is resolved using the nearest enclosing scope. If the name is not found in the current scope, a <code>NameError</code> exception is raised.
        </p>
        <p>
            If a name binding operation occurs anywhere within a code block, all uses of the name within that block are treated as references to the current block. This means that variables must be defined before their use within the same block.
        </p>
        <p>
            In AVAP, there are no global or nonlocal declarations. All names are resolved within the scope in which they are defined. There is no dynamic code execution with <code>eval</code> or <code>exec</code>, so all bindings must be static and known at code writing time.
        </p>
    </div>
);

export default Chapter9_3;
