import React from 'react';
import './chapter.css'
const Chapter9_40 = () => (
    <div>
        <h2>The try Statement</h2>
        <p>
            The <code>try</code> statement in AVAP specifies exception handlers and/or cleanup code for a block of statements. The syntax is as follows:
        </p>
        <pre>
            <code>
                try(): 
                code to execute 
                except(): 
                code to execute
            </code>
        </pre>
        <p>
            The <code>try</code> block contains code that might raise an exception. The <code>except</code> block contains code to handle exceptions raised by the <code>try</code> block. If an exception occurs, control is transferred to the <code>except</code> block. If no exception occurs, the <code>except</code> block is skipped.
        </p>
        <p>
            Additional information about exceptions can be found in the section <a href="#exceptions">Exceptions</a>, and information about using the <code>raise</code> statement to throw exceptions can be found in the section <a href="#raise">The raise Statement</a>.
        </p>
    </div>
);

export default Chapter9_40;

