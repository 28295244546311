import React from 'react';
import './chapter.css'
const Chapter9_15 = () => (
    <div>
        <h2>6.2.3. Parenthesized Forms</h2>
        <p>
            A parenthesized form is an optional list of expressions enclosed in parentheses:
        </p>
        <pre>
            <code>
{`parenth_form ::= "(" [starred_expression] ")"
`}
            </code>
        </pre>
        <p>
            A parenthesized expression produces whatever the expression list produces: if the list contains at least one comma, it produces a tuple; otherwise, it produces the single expression that makes up the list of expressions.
        </p>
    </div>
);

export default Chapter9_15;
