import React from 'react';
import './chapter.css'
const Chapter9_14 = () => (
    <div>
        <h2>6.2.2. Literals</h2>
        <p>
            AVAP supports string and bytes literals, as well as various numeric literals:
        </p>
        <pre>
            <code>
{`literal ::=  stringliteral | bytesliteral | integer | floatnumber | imagnumber
`}
            </code>
        </pre>
        <p>
            Evaluating a literal produces an object of the given type (string, bytes, integer, floating-point number, complex number) with the given value. All literals correspond to immutable data types.
        </p>
    </div>
);

export default Chapter9_14;
