import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const AddEndpointForm = () => {
    const [formData, setFormData] = useState({
        method: '',
        path: '/',
        regexSyntax: false,
        summary: '',
        description: '',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Endpoint added:", formData);
        // Aquí puedes agregar lógica para enviar los datos a un backend
        // o manejar el estado en el componente padre si lo necesitas
    };

    const handleCancel = () => {
        // Resetea los datos del formulario
        setFormData({
            method: '',
            path: '/',
            regexSyntax: false,
            summary: '',
            description: '',
        });
        console.log("Action cancelled");
    };

    return (
        <Form className="cloudForm" onSubmit={handleSubmit} style={{ maxWidth: '500px', margin: '0 auto' }}>
            <h2>Add New Endpoint</h2>

            {/* Method Selector */}
            <Form.Group controlId="method">
                <Form.Label>Method</Form.Label>
                <Form.Control
                    as="select"
                    name="method"
                    value={formData.method}
                    onChange={handleChange}
                    required
                >
                    <option value="">Select Method</option>
                    <option value="GET">GET</option>
                    <option value="POST">POST</option>
                    <option value="PUT">PUT</option>
                    <option value="DELETE">DELETE</option>
                    <option value="PATCH">PATCH</option>
                </Form.Control>
            </Form.Group>

            {/* Path Input */}
            <Form.Group controlId="path">
                <Form.Label>Path</Form.Label>
                <Form.Control
                    type="text"
                    name="path"
                    value={formData.path}
                    onChange={handleChange}
                    placeholder="/"
                    required
                />
                <Form.Check
                    type="checkbox"
                    name="regexSyntax"
                    checked={formData.regexSyntax}
                    onChange={handleChange}
                    label="Path definition uses regex syntax"
                />
            </Form.Group>

            {/* Summary (optional) */}
            <Form.Group controlId="summary">
                <Form.Label>Summary (optional)</Form.Label>
                <Form.Control
                    type="text"
                    name="summary"
                    value={formData.summary}
                    onChange={handleChange}
                    placeholder="Type a brief summary"
                />
            </Form.Group>

            {/* Description (optional) */}
            <Form.Group controlId="description">
                <Form.Label>Description (optional)</Form.Label>
                <Form.Control
                    as="textarea"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Type a description for your endpoint"
                    rows={3}
                />
            </Form.Group>

            {/* Fixed Buttons */}
            <div className="d-flex justify-content-between">
                <Button variant="primary" type="submit">
                    Add Endpoint
                </Button>
                <Button variant="outline-secondary" onClick={handleCancel}>
                    Cancel
                </Button>
            </div>
        </Form>
    );
};

export default AddEndpointForm;
