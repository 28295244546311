import React from 'react';
import './chapter.css'
const Chapter9_49 = () => (
    <div>
        <h2>Sequence Patterns</h2>
        <p>
            In AVAP, sequence patterns are used to match elements within sequences like lists or tuples. The syntax for sequence patterns is:
        </p>
        <pre><code>sequence_pattern ::=  "[" [maybe_sequence_pattern] "]"
                            | "(" [open_sequence_pattern] ")"</code></pre>
        <p>
            Sequence patterns can match elements of sequences based on specific rules. Here’s how they work:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <strong>List Patterns:</strong> Use square brackets <code>[ ]</code> to match lists. You can include patterns for the elements within the list.
                <pre><code>case [a, b, c]:
    print("Matched a list with three elements")</code></pre>
            </li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <strong>Tuple Patterns:</strong> Use parentheses <code>( )</code> to match tuples. Similarly, you can specify patterns for the tuple elements.
                <pre><code>case (x, y):
    print("Matched a tuple with two elements")</code></pre>
            </li>
        </ul>
        <p>
            Sequence patterns allow for flexible and powerful matching of sequence types. They can match sequences of various lengths and structures by defining the pattern for each element.
        </p>
        <p>
            Here’s an example of using sequence patterns in a match statement:
        </p>
        <pre><code>match value:
    case [1, 2, 3]:
        print("Matched a list with elements 1, 2, 3")
    case (a, b, c) if a + b == c:
        print("Matched a tuple where a + b equals c")
    case _:
        print("Matched something else")</code></pre>
        <p>
            In this example:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>case [1, 2, 3]:</code> matches a list with exactly the elements 1, 2, and 3.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>case (a, b, c) if a + b == c:</code> matches a tuple and includes a condition to check if <code>a + b</code> equals <code>c</code>.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>case _:</code> matches any other value not covered by the previous cases.</li>
        </ul>
    </div>
);

export default Chapter9_49;
