import React, { FunctionComponent, useEffect, useState } from 'react';
import { Row, Col, Spinner, Modal, } from 'react-bootstrap';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import "./LicensesAnddownloads.css"
import "./../NewDashboard.css"

import icono_download from "../../../assets/img/newDashboard/icono_download.png";

import logo_AVAP from "../../../assets/img/newDashboard/logo_AVAP.png";
import logo_AVAP_DS from "../../../assets/img/newDashboard/logo_AVAP_DS.png";
import logo_AVAP_VS from "../../../assets/img/newDashboard/logo_AVAP_VS.png";
import logo_Gateway from "../../../assets/img/newDashboard/logo_Gateway.png";
import logo_Microsoft from "../../../assets/img/microsoft.png";
import logo_AVAP_Cli from "../../../assets/img/newDashboard/avapcli.png";

import link_icon from "../../../assets/img/link-icon.png";

import logotipo_collaborativeN from "../../../assets/img/newDashboard/logotipo-collaborativeN.png";
import logo_AVAP_Cloud from "../../../assets/img/newDashboard/logo_AVAP_Cloud.png";
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import PaymentConfirmation from '../../../components/Modals/paymentConfirmation';
import AddCreditCard from '../../../components/Modals/addCreditCard';


const PRODUCTS = ['AVAP.zip','vscode_extensions.zip','AVAP_DEV_Studio.zip','AVS.zip','deployment.yaml','AVAP_DEV_Studio.zip','vscode_extensions.zip','','','','avapcli.zip']
const LicensesAnddownloads = () => {
  let { path, url } = useRouteMatch();
  const [licenseslist, setLicenseslist] = useState([]);
  const [divsLicenses, setDivsLicenses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [paymentEnable, setPaymentMode] = useState(true);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [show, setShow] = useState(false);

  const [buyTitle, setBuyTitle] = useState ('');
  const [buySubTitle, setBuySubTitle] = useState ('');
  const [buyDescription, setBuyDescription] = useState ('');
  const [buyAmmount, setBuyAmmount] = useState(0);
  const [card_id_stripe, setCardID] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [cards_, setCards] = useState([]);
  const [newadded_card, setAddedCard] = useState('');

  const [itemSelected, setItemSelected] = useState({})

  function sortByKey(array, key) {
    return array.sort(function(a, b) {
        var x = a[key]; var y = b[key];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
}


  const getCards = async () => {
    try {
      const result = (await ObexRequestHandler.get('/cards', {}, true)).data || {};
      const { defaultCard, cards } = result;
      const pmCards = [];
      if (cards) {
        for (const c of cards) {
          const isDefault = c.id === defaultCard;
          c.defaultCard = isDefault;
          isDefault ? pmCards.unshift(c) : pmCards.push(c);
          if(cards_.some(e => e.id == c.id)){
            //console.log('exists');
          }else{
            setAddedCard(c.id);
          }
        }
      }
      setCards(pmCards);
  
    } catch (error) {
      console.error('ERROR FETCHING CARDS ', error);
      throw error;
    }
  }

  const handleModal = () => {
    setShowModal(!showModal);
    //onCloseAction();
    getCards();

  };
  

  const handleCard = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { value } = e.target;
    if (value=='addCard') {
      handleModal();
    } else {
      setCardID(value);

    }
    console.log(value);
    e.preventDefault();
    e.stopPropagation();
  
  }

  const startPayment = () => {
    setLoading(true);
    setSuccess('');
    setError('');
  }

  const onCloseAction = () =>{

    setShow(false)
  }

  const OnSuccesAction = () =>{
    
    setShow(false)
    if (!error) {
      history.push('/dashboard');
      history.push('/dashboard/licenses-and-suscriptions');
    }
    setShow(false);
  }

  const makePayment = async () => {
    try {
      startPayment();
      sendBuy(itemSelected)
      /*
      const payload = {};
      const result = await ObexRequestHandler.post('/signup_avapcloud', payload);
      const { success, message, data } = result;
      if (!success) throw { message };
      setSuccess(data);
      setPaymentSuccess(true);
      setPaymentMode(false);
      OnSuccesAction();
      /*setTimeout(() => {
        onSuccess();
      }, 5000);*/ // TODO MIRAR SI HABILITAR ESTO
      
    } catch (error) {
      setError(error.message || error);
      console.error('ERROR BUYING EXTRA PROJECTS SLOTS ', error);
    }
    setLoading(false);
    
  }

  const history = useHistory();

  const OpenBuy = (item) => {
    console.log(item)
    setItemSelected(item);
    setBuyAmmount(item.price)
    setBuyTitle(`Buy ${item.name}`)
    setBuySubTitle('Please confirm your buy and proceed to pay.')
    setBuyDescription(item.name);
    setShow(true);

  }

  const logos =
    [
      {
        "id": 1,
        "name": "AVAP Interpreter & Language Server",
        "img": logo_AVAP
      },
      {
        "id": 2,
        "name": "AVAP Dev Extensions for Microsoft Visual Code",
        "img": logo_AVAP_DS,
      },
      {
        "id": 3,
        "name": "AVAP Dev Studio",
        "img": logo_AVAP_DS,
      },
      {
        "id": 4,
        "name": "AVAP Virtual Server Enterprise",
        "img": logo_AVAP_VS,
      },
      {
        "id": 5,
        "name": "AVAP Gateway Advanced",
        "img": logo_Gateway,
      },
      {
        "id": 6,
        "name": "AVAP Collaborative Network",
        "img": logotipo_collaborativeN,
      },
      {
        "id": 7,
        "name": "AVAP Cloud Business",
        "img": logo_AVAP_Cloud,
      },
      {
        "id": 8,
        "name": "AVAP Cloud Enterprise",
        "img": logo_AVAP_Cloud,
      },
      {
        "id": 9,
        "name": "AVS",
        "img": logo_AVAP_VS,
      },
      {
        "id": 10,
        "name": "AVAP Gateway Advanced",
        "img": logo_Gateway,
      },
      {
        "id": 11,
        "name": "AVAP Cli",
        "img": logo_AVAP_Cli,
      }
    ];
    const [isBuying, setIsBuying] = useState(false);
  

  useEffect(() => {
    getLicenses();
    getCards();
    window.scrollTo(0, 0);

  }, []);

  const getLicenses = async () => {
    try {
      const result = (await ObexRequestHandler.get('/get_all_licenses_availables')).data;
      generateItemLicenses(result);
    } catch (error) {
      console.error('ERROR GET PRIVACY INFO', error);
    }
  };

  const get_user_licenses = async () => {
    try {
      const result = (await ObexRequestHandler.get('/get_user_licenses')).data;
      return result;
    } catch (error) {
      console.error('get_user_licenses', error);
    }
  };


const sendDownload = async (item) => {
  if (item === 3) return;
console.log(item)

  let urlefe = undefined;

console.log(Number(item))

  if (Number(item) !== 2 && Number(item) !== 2 && Number(item) !== 5)  {

    var t = document.getElementById(item) as HTMLSelectElement;
    console.log(t)
    let plat = (t.options[Number(t.value)-1].text).toLowerCase().replace(" ","_")

    console.log(plat);
    console.log(PRODUCTS[Number(item)-1]);

    urlefe = `https://developer.101obex.com/static_images/downloads/staging/${plat}/${plat}-${PRODUCTS[Number(item)-1]}`;

  } else {

    urlefe = `https://developer.101obex.com/static_images/downloads/staging/${PRODUCTS[Number(item)-1]}`
    
  }

  if (urlefe !== undefined){
    const link = document.createElement('a');
    link.target = '';
    link.href = urlefe;
    link.click();
  }

}

  const sendBuy = async (item) => {
    setIsBuying(true);
    try {
      // debugger
      
      console.log(item);
      const result = (await ObexRequestHandler.post('/buy_license', {license_id:item.id}));

      if(result.success){
        
        window.location.reload();
      }
      
      return result;
    } catch (error) {
      console.error('buy_license', error);
    } finally{
      setIsBuying(false);
    }

  }


  const generateItemLicenses = async (licenses) => {
    let arrayDivsProyects = [];

    let itemsBought = await get_user_licenses();
    
    let licOrdered = sortByKey(licenses, 'orden');

    for (const key in licOrdered) {
      let item = licenses[key];
      let imgFounded = logos.find(proj => proj.id === item.id);
      let itHasBeenBought =  itemsBought.find(itemBought => itemBought.id === item.id);
      let bought = itHasBeenBought ? true : false;

      if (licenses[key].obex_license_type_id!=2)
      arrayDivsProyects.push(

        <div className="boxShadowCard borderRadius " key={key}>
          <div className='py-3 d-flex flex-column'>
            {/* Header card */} 
            <div ></div>
            <div className='d-flex boxShadowCardBottomTitle pl-3 pb-3'>
              <div className='pr-4'>
                <div className='sizeImgTitleCard'>
                  <img src={imgFounded.img}  alt={licenses[key].name} width={"100%"} height={"100%"} />
                </div>
              </div>
              <div className='fontSizeTitleCard textBold'>
                {licenses[key].name}
              </div>
            </div> 

            {/* Header card END*/}

            {/* Content */}
            <div className='d-flex flex-column align-self-center widthContentCard pt-3'>
            <div className='d-flex flex-column cardSize'>
            <div className='colorBlack mb-3 txt-wrp'>
                  {licenses[key].description} 
                </div>

{ licenses[key].obex_license_type_id != 2 ?

              ( licenses[key].obex_license_type_id != 3 ?
                <div className="select-container d-flex mb-5">
                  <div className='backgroundSelect borderRadiusLeft d-flex align-items-center px-2'>Platform</div>
                  
                   {licenses[key].obex_license_type_id < 3 ?
                  <select id ={licenses[key].id} className="form-select p-2 borderRadiusRight backgroundSelect colorBlack" aria-label="Default select example">
                    <option value="1">Windows</option>
                    <option value="2">macOS Intel</option>
                    <option value="3">macOS ARM64</option>
                    <option value="4">Linux</option> 
                    
                  </select> :
                  <select id = {licenses[key].id} className="form-select p-2 borderRadiusRight backgroundSelect colorBlack" aria-label="Default select example">
                    <option value="1">Kubernete deployment</option>                    
                  </select> 
    }
                </div>
:
<div id={licenses[key].id} className="textBold link-container d-flex mb-5"><a style={{width:'100%', height:'40px', borderRadius: '12px', color:'black'}} className=" backgroundSelect borderRadiusLeft borderRadiusRight d-flex align-items-center px-2" href="https://marketplace.visualstudio.com/items?itemName=101OBeXCorp.avap-linter" target='_BLANK'><img style={{marginRight:'10px', color:'black'}} src={logo_Microsoft} alt="" width={"20px"} height={"20px"} /> <span className='res-text'>Microsoft Visual Studio Code Marketplace</span> </a></div>

  ):

  <></>





              }


                <div className='d-flex flex-row W-100 justify-content-between colorGreen textBold mb-3 fontText3'>
                  <div> {licenses[key].price>0 ? '':''}</div>
                  <div >{
                    licenses[key].price >= 0 ?
                    (
                  licenses[key].price === 0 ? '' : (bought ? '':'')
                    )
                  : 'Contact Us'
                  }
                  
                  </div>
                </div>

                <div className='d-flex flex-row W-100 justify-content-between'>
                  <div>
                    <div className='sizeImgTitleCard'>
                   {/*}   { licenses[key].price > 0 && licenses[key].obex_license_type_id!=2 ? <img src={icono_download} alt="" width={"100%"} height={"100%"} />: <></>} */}
                    </div>
                  </div>

                  { licenses[key].id === 6 ?
                  <div className='w-65'>
                    <button style={{ marginTop:'auto', padding:'3px 0px', marginLeft:'auto', marginRight:'auto' }} className="btn customButton-green w-100 textBold" disabled={bought || isBuying} onClick={() => OpenBuy(item)}>{
                    licenses[key].price >= 0 && !bought ?
                    (licenses[key].price === 0 ? 
                      (
                        licenses[key].id === 6 ?
                        ' Go to AVAP Collaborative Network':
                        'Download'
    
    
                      )
                      
                      
                       : 'Buy Now'):
                    'Contact Us'
                    
                    }</button>
                  </div>
                  :
                  <div className='w-40'>
                    <button style={{ marginTop:'auto',padding:'3px 0px', marginLeft:'auto', marginRight:'auto' }} className="btn customButton-green w-100 textBold" disabled={isBuying} onClick={() => {
                      if (bought || licenses[key].price === 0) sendDownload(licenses[key].id); else {
                        if (licenses[key].price === 0 || bought) sendDownload(licenses[key].id); else sendDownload(licenses[key].id) 
                      }
                    }}>{
                    licenses[key].price >= 0 ?
                    (licenses[key].price === 0 ? 
                      (
                        licenses[key].id === 6 ?
                        ' Go to AVAP Collaborative Network':
                        'Download'
                      )
                       : (bought ? 'Coming Soon':'Coming Soon')):
                    'Contact Us'
                    
                    }</button>
                  </div>
                  }
                </div>

              </div>
            </div>
            {/* Content END*/}

          </div>
        </div>

      )

    }

    setDivsLicenses(arrayDivsProyects);
    setIsLoading(false);
    // return arrayDivsProyects;

  }


  return (
    <><div>
      <h2 className='textBold marginBottom2rem'>Downloads</h2>
      <p className='fontText3 colorBlack marginBottom2rem'>Purchase and manage your AVAP products and services.</p>
      {isLoading &&
        <div className='d-flex justify-content-center align-items-center '>
          <Spinner animation="border" />
        </div>}
      <div className="grid-container" style={{width:'65vw'}}>

        {divsLicenses}
      </div>
    </div><>{show &&

      <Modal dialogClassName="addons-dialog w-75 obex-dialog"
        show={show}
        onHide={onCloseAction}
        animation={false}
        container={document.getElementById('avapIdWrapper')}>
        <Modal.Header closeButton className="border-0 pb-0">
          <Modal.Title className="text-center w-100">
            <Row>
              <Col className="addons-title">
                <span>{buyTitle}</span>
              </Col>
            </Row>
            <Row>
              <Col md="10" className="mx-auto text-center modal-subtitle">
                <span className="text-center d-block">{buySubTitle}</span>
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PaymentConfirmation
            actionSubmit={makePayment}
            handleGoBack={() => {
              setPaymentMode(false),
                setShow(false);
            } }
            handleAnnualPayment={null}
            nameServiceSelected={buyDescription}
            amountMonth={buyAmmount}
            amountYear={(buyAmmount * 10) / 12}
            processingPayment={loading}
            success={success}
            error={error}
            handleCard={handleCard}
            isBlue={false}
            cards={cards_}
            prorated={true}
            addedCard={newadded_card}
            spanish={false}

            />
        </Modal.Body>
      </Modal>}
      {showModal && <AddCreditCard show={showModal} handleClose={handleModal} isGreen={true} spanish={false}/>}
      </></>



  );
}


export default LicensesAnddownloads;









