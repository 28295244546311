import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Procesar_SMS_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{
           "status": true,
            "currency_data": { 
              "abbreviation": <string>,
               "suffix": <string>, "format": <string>,
                "symbol": <string>, "prefix": <string>,
                 "decimals": <decimal>, "id": <integer> 
                }, "elapsed": <float>,
                 "raw_amount": <decimal>, 
                 "currency": <string>, 
                 "commission_user_card": <decimal>, 
                 "codtran": <string>, 
                 "user_commission": <decimal>, 
                 "amount": <string> 
                }`,
        copied: false
      },
      answe_ok2: {
        title:"Answer ok",
        type: "json",
        code: `{ "status": true, 
        "currency_data": {
           "abbreviation": <string>, 
           "suffix": <string>, "format": <string>, 
           "symbol": <string>, "prefix": <string>, 
           "decimals": <decimal>, "id": <integer> }, 
           "elapsed": <float>, "raw_amount": <decimal>,
            "currency": <string>, "commission_user_card": <decimal>,
             "codtran": <string>, "user_commission": <decimal>, 
             "amount": <string> 
            }`,
        copied: false
      },
      answe_ok3: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": true, 
          "signed": <boolean>, 
          "invoice": <integer>, 
          "currency_data": {
             "abbreviation": <string>, 
             "suffix": <string>, 
             "format": <string>, 
             "symbol": <string>, 
             "prefix": <string>, 
             "decimals": <decimal>, 
             "id": <integer> }, 
             "elapsed": <float>, 
             "raw_amount": <decimal>, 
             "currency": <string>, 
             "commission_user_card": <decimal>,
             "codtran": <string>, 
             "user_commission": <decimal>, 
             "amount": <string> }`,
        copied: false
      },
      answe_ok4: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": true, 
        "message": <string>, "
        elapsed": <float> }`,
        copied: false
      },
      answe_ok5: {
        title:"Answer ok",
        type: "json",
        code: `{ "status": true, 
        "message": { 
          "sms_response": <string>, 
        }, 
        "elapsed": <float> 
      }`,
        copied: false
      },
      answe_ok6: {
        title:"Answer ok",
        type: "json",
        code: `{
           "status": true, 
           "signed": <boolean>, 
           "invoice": <integer>, 
           "currency_data": { 
            "abbreviation": <string>, 
            "suffix": <string>, 
            "format": <string>, 
            "symbol": <string>, 
            "prefix": <string>, 
            "decimals": <decimal>, 
            "id": <integer> 
          }, 
          "elapsed": <float>, 
          "raw_amount": <decimal>, 
          "currency": <string>, 
          "commission_user_card": <decimal>, 
          "codtran": <string>, 
          "user_commission": <decimal>, 
          "amount": <string> }`,
        copied: false
      },
      answe_ok7: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": <boolean>,
         "message": {
           "status": <boolean>, 
           "codtran": <string>, 
           "resultado": { 
            "user_registration_id": <integer>,
             "longitud_otp": <integer> 
            }, 
            "sms_response": <string> 
          }, 
          "elapsed": <float> }`,
        copied: false
      },
      answe_ok8: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": true, 
          "message": { 
            "signed": <boolean>, 
            "invoice": <integer>, 
            "sms_response": <string>,
             "currency_data": { 
              "abbreviation": <string>,
               "suffix": <string>,
                "format": <string>, 
                "symbol": <string>,
                 "prefix": <string>,
                  "decimals": <decimal>, 
                  "id": <integer> 
                }, 
                "raw_amount": <decimal>, 
                "currency": <string>, 
                "commission_user_card": <decimal>, 
                "codtran": <string>, "user_commission": <decimal>,
                 "amount": <string> 
                }, 
                "elapsed": <float> }`,
        copied: false
      },
      answe_ok9: {
        title:"Answer ok",
        type: "json",
        code: `{ "status": true, "message": 
        { "signed": <boolean>, "invoice": <integer>, 
        "currency_data": { "abbreviation": <string>, 
        "suffix": <string>, "format": <string>, 
        "symbol": <string>, "prefix": <string>, 
        "decimals": <decimal>, "id": <integer> }, 
        "raw_amount": <decimal>, "currency": <string>, 
        "commission_user_card": <decimal>, "codtran": <string>, 
        "user_commission": <decimal>, "amount": <string> }, 
        "elapsed": <float> }`,
        copied: false
      },
      answe_ok10: {
        title:"Answer ok",
        type: "json",
        code: `{ "status": true, "currency_data": 
        { "abbreviation": <string>, 
        "suffix": <string>, "format": <string>, 
        "symbol": <string>, "prefix": <string>, 
        "decimals": <decimal>, "id": <integer> 
      }, 
      "elapsed": <float>, 
      "raw_amount": <decimal>, 
      "currency": <string>, 
      "commission_user_card": <decimal>, 
      "codtran": <string>, 
      "user_commission": <decimal>, 
      "amount": <string> 
    }`,
        copied: false
      },
      answe_ok11: {
        title:"Answer ok",
        type: "json",
        code: `{ "status": true, "signed": <boolean>, 
        "invoice": <integer>, "currency_data": { 
          "abbreviation": <string>, "suffix": <string>, 
          "format": <string>, "symbol": <string>, 
          "prefix": <string>, "decimals": <decimal>, 
          "id": <integer> }, "elapsed": <float>, 
          "raw_amount": <decimal>, "currency": <string>, 
          "commission_user_card": <decimal>, "codtran": <string>,
           "user_commission": <decimal>, "amount": <string> 
          }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{ 
          "status": false, 
          "level": <string>, 
          "message": <string>, 
          "error": <string> 
        }`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests url = "URL_BASE/ws/sms.py/procesarpeticion" 
        payload = {'country_code': 'MX', 'destination': '98780', 'phone': '527229063245', 'text': 'AYUDA'} 
        files = []
        headers= {} 
        response = requests.request("POST", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests url = "URL_BASE/ws/sms.py/procesarpeticion" 
          payload = {'country_code': 'MX', 'destination': '98780', 'phone': '527229063245', 'text': 'AYUDA'} 
          files = []
          headers= {} 
          response = requests.request("POST", url, headers=headers, data = payload, files = files) 
          print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'POST',
              'url': 'URL_BASE/ws/sms.py/procesarpeticion',
              'headers': {},
              formData: {
              'country_code': 'MX',
              'destination': '98780',
              'phone': '527229063245',
              'text': 'AYUDA'
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var formdata = new FormData();
              formdata.append("country_code", "MX");
              formdata.append("destination", "98780");
              formdata.append("phone", "527229063245");
              formdata.append("text", "AYUDA");
              var requestOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/sms.py/procesarpeticion", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request POST 'URL_BASE/ws/sms.py/procesarpeticion' \ --form 'country_code=MX' \ --form 'destination=98780' \ --form 'phone=527229063245' \ --form 'text=AYUDA'`
        }
      ]
    }
    return(
      <Fragment>
         <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="process-sms">Process SMS</h1>
        <p>This service is used to process the different SMS messages.</p>
        <p>
          <span className="label post">POST:</span>
          <span> </span> <code>URL_BASE + /ws/sms.py/procesarpeticion</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center">ES &#40;Spain&#41;</td>
            </tr>
            <tr>
              <td align="left">destination</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Destination number of the user&#39;s SMS message: 98780 &#40;system
                phone number&#41;
              </td>
              <td align="center">N/A</td>
              <td align="center">98780</td>
            </tr>
            <tr>
              <td align="left">phone</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                Telephone number that sends the sms to the system
              </td>
              <td align="center">N/A</td>
              <td align="center">7229063245</td>
            </tr>
            <tr>
              <td align="left">text</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">SMS Text. COMMAND + command parameters</td>
              <td align="center">N/A</td>
              <td align="center">HELP</td>
            </tr>
          </tbody>
        </table>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the command indicated in the text and the parameters, this
          service can return two different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency_data:</code>Contains the different details of the
            currency used in the operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>abbreviation:</code> The abbreviated name of the currency
            &#40;EUR, MXN&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>suffix:</code> Indicates the suffix that is applied in the
            currency format &#40;pesos, euros&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>format:</code> The full format that applies to the currency, it
            includes the suffix and the prefix.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>symbol:</code> The symbol associated to the currency &#40;€, ¢,
            $&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>prefix:</code> The prefix that is applied in the currency format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>decimals:</code> The maximum number of decimal places to be
            included in the currency format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>id:</code> Identifier of the currency in BBDD.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>raw_amount:</code> Negative transaction amount without applying
            the format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency:</code> Short name of the currency used in the operation,
            it matches the abbreviation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>commission_user_card:</code> Commission that would be applied if
            the payment is made by card.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>codtran:</code> Transaction code that identifies the executed
            operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>user_commission:</code> Commission that will be applied to the
            user.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>amount:</code> Negative transaction amount with the applied
            format.
          </li>
        </ul>
        <p>
          Command: DEMAND Parameters: command + amount + phone + object Example:
          DEMAND 5 7229063245 Lorem ipsum
        </p>
        <h3 id="answer-json-ok-1">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok2}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency_data:</code> Contains the different details of the
            currency used in the operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>abbreviation:</code> The abbreviated name of the currency
            &#40;EUR, MXN&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>suffix:</code> Indicates the suffix that is applied in the
            currency format &#40;pesos, euros&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>format:</code> The full format that applies to the currency, it
            includes the suffix and the prefix.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>symbol:</code> The symbol associated to the currency &#40;€, ¢,
            $&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>prefix:</code> The prefix that is applied in the currency format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>decimals:</code> The maximum number of decimal places to be
            included in the currency format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>id:</code> Identifier of the currency in BBDD.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>raw_amount:</code> Amount of the operation without applying the
            format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency:</code> Short name of the currency used in the operation,
            it matches the abbreviation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>commission_user_card:</code> Commission that would be applied if
            the payment is made by card.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>codtran:</code> Transaction code that identifies the executed
            operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>user_commission:</code> Commission that will be applied to the
            user.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>amount:</code> Amount of the operation with the applied format.
          </li>
        </ul>
        <p>Command: SIGN Parameters: command + otp Example: SIGN 1234</p>
        <h3 id="answer-json-ok-2">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok3}/>
          </div>
        <h2 id="where-2">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>signed:</code> Shows if the signature has been successfully
            processed&#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>invoice:</code> Invoice number associated to the operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency_data:</code> Contains the different details of the
            currency used in the operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>abbreviation:</code>The abbreviated name of the currency &#40;EUR,
            MXN&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>suffix:</code> Indicates the suffix that is applied in the
            currency format &#40;pesos, euros&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>format:</code> The full format that applies to the currency, it
            includes the suffix and the prefix.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>symbol:</code> The symbol associated to the currency &#40;€, ¢,
            $&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>prefix:</code> The prefix that is applied in the currency format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>decimals:</code> The maximum number of decimal places that will be
            included in the currency format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>id:</code> Identifier of the currency in BBDD.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>raw_amount:</code> Negative transaction amount without applying
            the format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency:</code> Short name of the currency used in the operation,
            it matches the abbreviation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>commission_user_card:</code> Commission that would be applied if
            the payment is made by card.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>codtran:</code> Transaction code that identifies the executed
            operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>user_commission:</code> Commission that will be applied to the
            user.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>amount:</code> Negative transaction amount applying the format.
          </li>
        </ul>
        <p>Command: HELP Parameters: command Example: HELP</p>
        <h3 id="answer-json-ok-3">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok4}/>
          </div>
        <h2 id="where-3">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code> Text with the sms &#40;indicates all available
            commands&#41; that is returned to the phone requesting the help.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Execution operation time.
          </li>
        </ul>
        <p>Command: BALANCE PARAMETERS: comMand Example: BALANCE</p>
        <h3 id="answer-json-ok-4">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok5}/>
          </div>
        <h2 id="where-4">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code>
            <blockquote>
              <ul>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>sms_response</code>: Text with the sms that is returned to
                  the phone.
                </li>
              </ul>
            </blockquote>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code>Operation execution time.
          </li>
        </ul>
        <p>
          <strong>* The following commands are active. *</strong>
        </p>
        <table>
          <thead>
            <tr>
              <th align="center">Command</th>
              <th align="center">Parameters</th>
              <th align="center">Example</th>
              <th align="center">Type</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">AIRETEL</td>
              <td align="center">command</td>
              <td align="center">AIRETELL</td>
              <td align="center">Bills 2 purchase</td>
            </tr>
            <tr>
              <td align="center">AIREMOV</td>
              <td align="center">command</td>
              <td align="center">AIREMOVI</td>
              <td align="center">Bills 2 purchase</td>
            </tr>
            <tr>
              <td align="center">AIREIUSACELL</td>
              <td align="center">command</td>
              <td align="center">AIREIUSACELL</td>
              <td align="center">Bills 2 purchase</td>
            </tr>
            <tr>
              <td align="center">AIRENEXTEL</td>
              <td align="center">command</td>
              <td align="center">AIRENEXTEL</td>
              <td align="center">Bills 2 purchase</td>
            </tr>
            <tr>
              <td align="center">PAY</td>
              <td align="center">command phone amount concept</td>
              <td align="center">PAY 7229063245 1 object</td>
              <td align="center">Money Sending</td>
            </tr>
            <tr>
              <td align="center">ATM</td>
              <td align="center">
                operator [&#39;TELCEL&#39;, &#39;MOVISTAR&#39;, &#39;ATT&#39;]
                amount
              </td>
              <td align="center">ATM ATT 100</td>
              <td align="center">ATM withdrawal</td>
            </tr>
            <tr>
              <td align="center">DONATE</td>
              <td align="center">telephone amount command</td>
              <td align="center">DONATE 5 7229063245</td>
              <td align="center">Money Sending</td>
            </tr>
            <tr>
              <td align="center">JV</td>
              <td align="center">amount object command</td>
              <td align="center">JV 1 object</td>
              <td align="center">Money Sending</td>
            </tr>
          </tbody>
        </table>
        <h3 id="answer-json-ok-5">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok6}/>
          </div>
        <h2 id="where-5">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>signed:</code> Shows if the signature has been successfully
            processed &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>invoice:</code> Invoice number associated to the operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency_data:</code> Contains the different details of the
            currency used in the operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>abbreviation:</code> The abbreviated name of the currency
            &#40;EUR, MXN&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>suffix:</code> Indicates the suffix that is applied in the
            currency format&#40;pesos, euros&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>format:</code> The full format that applies to the currency, it
            includes the suffix and the prefix.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>symbol:</code> The symbol associated to the currency &#40;€, ¢,
            $&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>prefix:</code> The prefix that is applied in the currency format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>decimals:</code> The maximum number of decimal places that will be
            included in the currency format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>id:</code> Identifier of the currency in BBDD.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Execution operation time.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>raw_amount:</code> Negative transaction amount without applying
            the format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency:</code> Short name of the currency used in the operation,
            it matches the abbreviation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>commission_user_card:</code> Commission that would be applied if
            the payment is made by card.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>codtran:</code> Transaction code that identifies the executed
            operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>user_commission:</code> Commission that will be applied to the
            user.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>amount:</code> Negative transaction amount applying the format.
          </li>
        </ul>
        <p>
          <strong>* The following commands are Inactive *</strong>
        </p>
        <table>
          <thead>
            <tr>
              <th align="center">Command</th>
              <th align="center">Parameters</th>
              <th align="center">Example</th>
              <th align="center">Type</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">REGISTRATION</td>
              <td align="center">command + nick_afiliado&#40;optional&#41;</td>
              <td align="center">ALTA</td>
              <td align="center">Onboarding Services</td>
            </tr>
          </tbody>
        </table>
        <blockquote>
          <p>Activation: It is activated with the feature: permitir_alta_sms</p>
        </blockquote>
        <h3 id="answer-json-ok-6">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok7}/>
          </div>
        <h2 id="where-6">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code>{' '}
            <blockquote>
              <ul>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>status:</code> Shows if the signature has been successfully
                  processed &#40;true&#41; or not &#40;false&#41;.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>codtran:</code> Transaction code that identifies the
                  executed operation.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>resultado:</code> Registration service answer.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>user_registration_id:</code> Identifier of the user who has
                  just been registered.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>longitud_otp:</code> Length of the otp to validate, which
                  has been sent to the user.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>sms_response:</code> Text with the sms that is returned to
                  the phone.
                </li>
              </ul>
            </blockquote>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ok-7">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok8}/>
          </div>
        <h2 id="where-7">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code>{' '}
            <blockquote>
              <ul>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>signed:</code> Shows if the signature has been successfully
                  processed &#40;true&#41; or not &#40;false&#41;.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>invoice:</code> Invoice number associated to the operation.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>sms_response:</code> Text with the sms that is returned to
                  the phone.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>currency_data:</code> Contains the different details of the
                  currency used in the operation.
                  <blockquote>
                    <ul>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <code>abbreviation:</code> The abbreviated name of the
                        currency&#40;EUR, MXN&#41;.
                      </li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <code>suffix:</code> Indicates the suffix that is applied in
                        the currency format &#40;pesos, euros&#41;.
                      </li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <code>format:</code> The full format that applies to the
                        currency, it includes the suffix and the prefix.
                      </li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <code>symbol:</code> The symbol associated to the currency
                        &#40;€, ¢, $&#41;.
                      </li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <code>prefix:</code> The prefix that is applied in the
                        currency format.
                      </li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <code>decimals:</code> The maximum number of decimal places
                        that will be included in the currency format.
                      </li>
                      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                        <code>id:</code> Identifier of the currency in BBDD.
                      </li>
                    </ul>
                  </blockquote>
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>raw_amount:</code> Negative transaction amount without
                  applying the format.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>currency:</code> Short name of the currency used in the
                  operation, it matches the abbreviation.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>commission_user_card:</code> Commission that would be
                  applied if the payment is made by card.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>codtran:</code> Transaction code that identifies the
                  executed operation.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>user_commission:</code> Commission that will be applied to
                  the user.
                </li>
              </ul>
            </blockquote>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ok-8">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok9}/>
          </div>
        <h2 id="where-8">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code>
            <blockquote>
              <ul>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>signed:</code> Shows if the signature has been successfully
                  processed &#40;true&#41; or not &#40;false&#41;.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>invoice:</code> Invoice number associated to the operation.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>currency_data:</code> Contains the different details of the
                  currency used in the operation.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>abbreviation:</code> The abbreviated name of the currency
                  &#40;EUR, MXN&#41;.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>suffix:</code> Indicates the suffix that is applied in the
                  currency format &#40;pesos, euros&#41;.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>format:</code> The full format that applies to the currency,
                  it includes the suffix and the prefix.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>symbol:</code> The symbol associated to the currency &#40;€,
                  ¢, $&#41;.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>prefix:</code> The prefix that is applied in the currency
                  format.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>decimals:</code> The maximum number of decimal places that
                  will be included in the currency format.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>id:</code> Identifier of the currency in BBDD.
                </li>
                <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                  <code>elapsed:</code> Operation execution time.
                </li>
              </ul>
            </blockquote>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>raw_amount:</code> Negative transaction amount without applying
            the format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency:</code> Short name of the currency used in the operation,
            it matches the abbreviation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>commission_user_card:</code> Commission that would be applied if
            the payment is made by card.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>codtran:</code> Transaction code that identifies the executed
            operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>user_commission:</code> Commission that will be applied to the
            user.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ok-9">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok10}/>
          </div>
        <h2 id="where-9">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency_data:</code> Contains the different details of the
            currency used in the operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>abbreviation:</code> The abbreviated name of the currency
            &#40;EUR, MXN&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>suffix:</code> Indicates the suffix that is applied in the
            currency format &#40;pesos, euros&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>format:</code> The full format that applies to the currency, it
            includes the suffix and the prefix.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>symbol:</code> The symbol associated to the currency &#40;€, ¢,
            $&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>prefix:</code> The prefix that is applied in the currency format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>decimals:</code> The maximum number of decimal places that will be
            included in the currency.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>id:</code> Identifier of the currency in BBDD.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>raw_amount:</code> Negative transaction amount without applying
            the format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency:</code> Short name of the currency used in the operation,
            it matches the abbreviation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>commission_user_card:</code> Commission that would be applied if
            the payment is made by card.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>codtran:</code> Transaction code that identifies the executed
            operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>user_commission:</code> Commission that will be applied to the
            user.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>amount:</code> Negative transaction amount applying the format.
          </li>
        </ul>
        <h3 id="answer-json-ok-10">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok11}/>
          </div>
        <h2 id="where-10">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>signed:</code> Shows if the signature has been successfully
            processed &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>invoice:</code> Invoice number associated to the operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency_data:</code> Contains the different details of the
            currency used in the operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>abbreviation:</code> The abbreviated name of the currency&#40;EUR,
            MXN&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>suffix:</code> Indicates the suffix that is applied in the
            currency format &#40;pesos, euros&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>format:</code> The full format that applies to the currency, it
            includes the suffix and the prefix.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>symbol:</code> The symbol associated to the currency &#40;€, ¢,
            $&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>prefix:</code> The prefix that is applied in the currency format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>decimals:</code> The maximum number of decimal places that will be
            included in the currency format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>id:</code> Identifier of the currency in BBDD.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>raw_amount:</code> Negative transaction amount without applying
            the format.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>currency:</code> Short name of the currency used in the operation,
            it matches the abbreviation..
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>commission_user_card:</code> Commission that would be applied if
            the payment is made by card.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>codtran:</code> Transaction code that identifies the executed
            operation.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>user_commission:</code> Commission that will be applied to the
            user.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>amount:</code> Negative transaction amount applying the format.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-11">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>level:</code> Error importance level.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code> Error message.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          This service is in charge of processing the sms that are sent from a phone
          to the backend, the destination being the backend phone number.
        </p>
        </div>

      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
      
        </div>
      </div>
      </Fragment>
    );
  }
export default Procesar_SMS_EN;
