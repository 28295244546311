import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';

const Get_vaulted_transactions = () => 
  {
    const codes ={
      Ret: {
        title:"Answer ok",
        type: "json",
        code: `{
          "status": true,
          "result": [
          {
          "currency": "GRAY",
          "date": "2021-09-10",
          "amount": 195603000,
          "unblock": "2022-06-10",
          "vault_transaction": "0x0a0c2d12643d03a626b6684cda9c5cfd632b5b4bc685fa522de7034e31bc99d4",
          "network": 4,
          "time": 23328000,
          "vault_address": "0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735"
          },
          {
          "currency": "GRAY",
          "date": "2021-09-10",
          "amount": 7451560,
          "unblock": "2022-06-10",
          "vault_transaction": "0x208cb58fa99e21930aabbd34a183b70d6503ed4bf0444081bad7280056d435bd",
          "network": 4,
          "time": 23328000,
          "vault_address": "0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735"
          },
          {
          "currency": "GRAY",
          "date": "2021-09-10",
          "amount": 7824140,
          "unblock": "2022-06-10",
          "vault_transaction": "0x4e5276904862e0e7dc59aea5e2218f8ff54540d1ec863c6f2c3566b6ed5a309f",
          "network": 4,
          "time": 23328000,
          "vault_address": "0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735"
          },
          {
          "currency": "GRAY",
          "date": "2021-09-10",
          "amount": 7451560,
          "unblock": "2022-06-10",
          "vault_transaction": "0xdd13c192ca907ba27dc526dc7036d5aa161b0b6787a98f2d6fb24f221e3593a5",
          "network": 4,
          "time": 23328000,
          "vault_address": "0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735"
          },
          {
          "currency": "GRAY",
          "date": "2021-09-13",
          "amount": 252031000,
          "unblock": "2022-06-13",
          "vault_transaction": "0xe46cda6860038c6d16c5aa0f26e9ede00c9938806de0dd789d9d1f6f2fc53d72",
          "network": 4,
          "time": 23328000,
          "vault_address": "0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735"
          },
          {
          "currency": "GRAY",
          "date": "2021-09-10",
          "amount": 7824140,
          "unblock": "2022-06-10",
          "vault_transaction": "0x93f796d0bfa5aa49da307eda716f00249dbd7ae9a3504a6ed1ce43da2ac9e6f8",
          "network": 4,
          "time": 23328000,
          "vault_address": "0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735"
          },
          {
          "currency": "GRAY",
          "date": "2021-09-10",
          "amount": 7451560,
          "unblock": "2022-06-10",
          "vault_transaction": "0x316d1940fdd9a21734a74a3f5ae5cfd024c60224d6789dd6245808a8985192b0",
          "network": 4,
          "time": 23328000,
          "vault_address": "0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735"
          },
          {
          "currency": "GRAY",
          "date": "2021-09-10",
          "amount": 7451560,
          "unblock": "2022-06-10",
          "vault_transaction": "0xc80fdc1fb448c2a185da3a78df8b3fa021e969e453fb26a00e2a057750638b2c",
          "network": 4,
          "time": 23328000,
          "vault_address": "0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735"
          },
          {
          "currency": "GRAY",
          "date": "2021-09-10",
          "amount": 7451560,
          "unblock": "2022-06-10",
          "vault_transaction": "0x34a9e8027f1473aced3c8cb5625d61500a41b7e761a257ae46f1d36b0c017a51",
          "network": 4,
          "time": 23328000,
          "vault_address": "0xc7AD46e0b8a400Bb3C915120d284AafbA8fc4735"
          }
          ],
          "elapsed": 0.056375980377197266
         }`,
        copied: false
      },
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{
          "status": false,
          "level": <string>,
          "message": <string>,
          "error": <string>
          }
         Where:`,
        copied: false
      }
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests
        url = "http://34.121.95.179:80/ws/util.py/get_vaulted_transactions?account=0xFcA789F1D5398E47b63d561D855ad98DAa2F522c"
        payload={}
        headers = {
         '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'
        }
        response = requests.request("GET", url, headers=headers, data=payload)  
        print(response.text)`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests
          url = "http://34.121.95.179:80/ws/util.py/get_vaulted_transactions?account=0xFcA789F1D5398E47b63d561D855ad98DAa2F522c"
          payload={}
          headers = {
           '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'
          }
          response = requests.request("GET", url, headers=headers, data=payload)  
          print(response.text)`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
               'method': 'GET',
               'url': 'http://34.121.95.179:80/ws/util.py/get_vaulted_transactions?account=0xFcA789F1D5398E47b63d561D855ad98DAa2F522c',
               'headers': {
               '101ObexApiKey': 'KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'
               }
              };
              request(options, function (error, response) {
               if (error) throw new Error(error);
               console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var myHeaders = new Headers();
              myHeaders.append("101ObexApiKey", "KcM6Vr7EfNnKScEi00jdritBzNjWAOkz");
              
              var requestOptions = {
               method: 'GET',
               headers: myHeaders,
               redirect: 'follow'
              };
              
              fetch("http://34.121.95.179:80/ws/util.py/get_vaulted_transactions?account=0xFcA789F1D5398E47b63d561D855ad98DAa2F522c", requestOptions)
               .then(response => response.text())
               .then(result => console.log(result))
               .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request GET 'http://34.121.95.179:80/ws/util.py/get_vaulted_transactions?account=0xFcA789F1D5398E47b63d561D855ad98DAa2F522c' \
            --header '101ObexApiKey: KcM6Vr7EfNnKScEi00jdritBzNjWAOkz'`
        }
      ]
    }
    return(

      <Fragment>
        <div className='container-fluid row'>
            <div className='col-md-7'>
        <h1 id="get-vaulted-transactions">Get Vaulted Transactions</h1>
        <h2 id="wsutilpyget_vaulted_transactions">
          ws/util.py/get_vaulted_transactions
        </h2>
        <h3 id="receives">Receives</h3>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <div className='overflow-auto'>
        <table>
          <thead>
            <tr>
              <th align="center">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="center">Country</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center">ES</td>
            </tr>
            <tr>
              <td align="center">account</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">Ethereum account</td>
              <td align="center">N/A</td>
              <td align="center">0xFcA789F1D5398E47b63d561D855ad98DAa2F522c</td>
            </tr>
          </tbody>
        </table>
        </div>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.Ret}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </p>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <code>result:</code> Result of the consultation, list of blocked.
            </p>
            <ul>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <code>currency</code>: Symbol of the blocked token.
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <code>date</code>: Date in which the token was blocked.
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <code>amount</code>: Amount that is blocked
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <code>unblock</code>: Date in which the blocked tokens are
                unblocked.
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <code>vault_transaction</code>: Identifier of the transaction that
                blocks the tokens.
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <code>network</code>: Network in which the tokens are blocked.
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <code>time</code>: Time token is bloqued.
              </li>
              <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
                <code>vault_address</code>: Address in which the tokens are blocked.
              </li>
            </ul>
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <p>
              <code>elapsed:</code> Operation execution time.
            </p>
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>level:</code> Error importance level.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code> Error message.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>error:</code> Sole error code.
          </li>
          <h2 id="business-logic">Business logic:</h2>
        <p>This service aims to recover locked tokens from a ethereum account.</p>
        </ul>
        </div>
        <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
        
        </div>
         </div>
      </Fragment>
    );
  }
export default Get_vaulted_transactions;
