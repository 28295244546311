import React, { useState } from 'react';
import { Form, Button, Col } from 'react-bootstrap';

const NewDocumentationPageForm = () => {
    const [formData, setFormData] = useState({
        file: null,
        pageName: '',
        urlSlug: '',
        status: true, // true = Published, false = Unpublished
        parent: 'none',
        uploadOption: 'uploadFile', // 'uploadFile' or 'emptyDocument'
    });

    const handleOptionChange = (option) => {
        setFormData((prevData) => ({
            ...prevData,
            uploadOption: option,
            file: option === 'uploadFile' ? prevData.file : null,
        }));
    };

    const handleFileChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            file: e.target.files[0],
        }));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("New Documentation Page Data Submitted:", formData);
    };

    const handleCancel = () => {
        setFormData({
            file: null,
            pageName: '',
            urlSlug: '',
            status: true,
            parent: 'none',
            uploadOption: 'uploadFile',
        });
    };

    return (
        <Form className="cloudForm" onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
            <h2>New Documentation Page</h2>
            <p>Provide guides, examples, and other useful information about this service to developers.</p>

            {/* Radio Buttons for Upload File and Empty Document */}
            <Form.Group controlId="uploadOptions">
                <Form.Check
                    type="radio"
                    label="Upload File"
                    name="uploadOption"
                    value="uploadFile"
                    checked={formData.uploadOption === 'uploadFile'}
                    onChange={() => handleOptionChange('uploadFile')}
                />
                <Form.Check
                    type="radio"
                    label="Empty Document"
                    name="uploadOption"
                    value="emptyDocument"
                    checked={formData.uploadOption === 'emptyDocument'}
                    onChange={() => handleOptionChange('emptyDocument')}
                />
            </Form.Group>

            {/* File Upload */}
            {formData.uploadOption === 'uploadFile' && (
                <Form.Group controlId="file">
                    <Form.Label>Upload File</Form.Label>
                    <Form.File
                        name="file"
                        label={formData.file ? formData.file.name : "No file selected"}
                        onChange={handleFileChange}
                        custom
                    />
                    <Form.Text className="text-muted">
                        YAML or JSON files supported
                    </Form.Text>
                </Form.Group>
            )}

            {/* Page Name */}
            <Form.Group controlId="pageName">
                <Form.Label>Page Name</Form.Label>
                <Form.Control
                    type="text"
                    name="pageName"
                    value={formData.pageName}
                    onChange={handleChange}
                    placeholder="Enter a page name"
                    required
                />
            </Form.Group>

            {/* URL Slug */}
            <Form.Group controlId="urlSlug">
                <Form.Label>URL Slug</Form.Label>
                <Form.Control
                    type="text"
                    name="urlSlug"
                    value={formData.urlSlug}
                    onChange={handleChange}
                    placeholder="Enter a URL slug"
                    required
                />
            </Form.Group>

            {/* Status Toggle */}
            <Form.Group controlId="status" className="d-flex align-items-center">
                <Form.Label className="mr-2">Status</Form.Label>
                <label className="custom-toggle">
                    <input
                        type="checkbox"
                        name="status"
                        checked={formData.status}
                        onChange={() => setFormData((prevData) => ({ ...prevData, status: !prevData.status }))}
                    />
                    <span className="slider"></span>
                </label>
                <span className="ml-2">{formData.status ? 'Published' : 'Unpublished'}</span>
            </Form.Group>

            {/* Parent */}
            <Form.Group controlId="parent">
                <Form.Label>Parent</Form.Label>
                <Form.Control
                    as="select"
                    name="parent"
                    value={formData.parent}
                    onChange={handleChange}
                >
                    <option value="none">None</option>
                    <option value="section1">Section 1</option>
                    <option value="section2">Section 2</option>
                </Form.Control>
            </Form.Group>

            {/* Fixed Buttons */}
            <div className="d-flex justify-content-between mt-4">
                <Button variant="primary" type="submit">Save Page</Button>
                <Button variant="outline-secondary" onClick={handleCancel}>Cancel</Button>
            </div>
        </Form>
    );
};

export default NewDocumentationPageForm;
