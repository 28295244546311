import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const Signature_Service = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status": true,
        "signed": <boolean>,
        "invoice": <integer>,
        "currency_data": {
            "abbreviation": <string>,
            "suffix": <string>,
            "format": <string>,
            "symbol": <string>,
            "prefix": <string>,
            "decimals": <decimal>,
            "id": <integer>
        },
        "elapsed": <float>,
        "raw_amount": <decimal>,
        "currency": <string>,
        "commission_user_card": <decimal>,
        "codtran": <string>,
        "user_commission": <decimal>,
        "amount": <string>
    }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
    url = "URL_BASE/ws/firma.py/firmar"
    payload = {
    'otp': '1234',
    'phone': '7229063245',
    'country_code': 'MX'}
    files = []
    headers= {}
    response = requests.request("POST", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
    url = "URL_BASE/ws/firma.py/firmar"
    payload = {
    'otp': '1234',
    'phone': '7229063245',
    'country_code': 'MX'}
    files = []
    headers= {}
    response = requests.request("POST", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'POST',
  'url': 'URL_BASE/ws/firma.py/firmar',
  'headers': {},
  formData: {
  'otp': '1234',
  'phone_dst': '7229063245',
  'country_code': 'MX'
  }
  };
  request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
  });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var formdata = new FormData();
  formdata.append("otp", "1234");
  formdata.append("phone_dst", "7229063245");
  formdata.append("country_code", "MX");
  var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
  };
  fetch("URL_BASE/ws/firma.py/firmar", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request POST 'URL_BASE/ws/firma.py/firmar' 
          --form 'otp=1234' 
          --form 'phone_dst=7229063245' 
          --form 'country_code=MX'`
      }
    ]
  }

  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >Signature Service</h1>
          <p>This service is used to approve several operations within the system.</p>
          <p>
            <span className="label post">POST:</span>
            <span> </span> <code>URL_BASE + /ws/firma.py/firmar</code>
          </p>
          <h2 >Receives:</h2>
          <p>
            All the parameters that the service receives must be indicated in the body
            of the request.
          </p>

          <div className='overflow-auto'>

            <table>
              <thead>
                <tr>
                  <th align="left">Parameter</th>
                  <th align="center">Format</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Description</th>
                  <th align="center">VDefault value</th>
                  <th align="center">Example</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="left">country_code</td>
                  <td align="center">
                    ISO 3166 alpha-2 &#40;
                    <a href="https://www.iso.org/glossary-for-iso-3166.html">
                      https://www.iso.org/glossary-for-iso-3166.html
                    </a>
                    &#41;
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">Country code in which the user resides</td>
                  <td align="center">N/A</td>
                  <td align="center">ES &#40;Spain&#41;</td>
                </tr>
                <tr>
                  <td align="left">phone</td>
                  <td align="center">Integer</td>
                  <td align="center">
                    Yes &#40;if you do not send the transaction code associated to the
                    operation&#41;
                  </td>
                  <td align="center">
                    Amount to be sent to the user indicated with &#39;phone_dst&#39;
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">7229063245</td>
                </tr>
                <tr>
                  <td align="left">codtran</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send the phone number of the user who sends
                    the operation&#41;
                  </td>
                  <td align="center">Code of the operation to sign</td>
                  <td align="center">N/A</td>
                  <td align="center">&quot;60995737691715d3cc51aa4ab699fd84&quot;</td>
                </tr>
                <tr>
                  <td align="left">otp</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Phone number of the user that is going to receive the money sent
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">1234</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>
          <h3 >Answer JSON OK:</h3>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2 >Where:</h2>
          <ul>
            <li>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li>
              <code>signed:</code> Shows if the signature has been successfully
              processed &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li>
              <code>invoice:</code> Invoice number associated to the operation.
            </li>
            <li>
              <code>currency_data:</code> Contains the different details of the
              currency used in the operation.
            </li>
            <li>
              <code>abbreviation:</code>The abbreviated name of the currency &#40;EUR,
              MXN&#41;.
            </li>
            <li>
              <code>suffix:</code> Indicates the suffix that is applied in the
              currency format &#40;pesos, euros&#41;.
            </li>
            <li>
              <code>format:</code> The full format that is applied to the currency, it
              includes the suffix and the prefix.
            </li>
            <li>
              <code>symbol:</code> The symbol associated to the currency &#40;€, ¢,
              $&#41;.
            </li>
            <li>
              <code>prefix:</code> The prefix that is applied in the format of the
              currency.
            </li>
            <li>
              <code>decimals:</code> The maximum number of decimal places to be
              included in the currency format.
            </li>
            <li>
              <code>id:</code> Currency identifier in BBDD.
            </li>
            <li>
              <code>elapsed:</code> Execution operation time.
            </li>
            <li>
              <code>raw_amount:</code> Amount of the operation in negative without
              applying the format.
            </li>
            <li>
              <code>currency:</code> Short name of the currency used in the operation,
              it matches the abbreviation.
            </li>
            <li>
              <code>commission_user_card:</code> Commission that would be applied if
              the payment is made by card.
            </li>
            <li>
              <code>codtran:</code> Transaction code that identifies the executed
              operation.
            </li>
            <li>
              <code>user_commission:</code> Commission to be charged to the user.
            </li>
            <li>
              <code>amount:</code> Negative transaction amount with the applied
              format.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Business logic:</h2>
          <p>
            This service searches for the operations pending signing that the entered
            phone has, and, among the results, accepts the operation through the OTP.
          </p>

        </div>
        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>

      </div>


    </Fragment>
  );
}
export default Signature_Service;
