import React, { Fragment } from 'react';
import './chapter.css'
const Infrastructure_AVS = () => (
  <Fragment>
       <div>
    <h2>Discovering AVAP</h2>
    <p>
      AVAP™ is much more than just a programming language; it is a versatile tool designed to enhance creativity and efficiency in software development. With its clear and expressive syntax, AVAP™ allows developers to write code more quickly and concisely, without sacrificing the power and flexibility needed to create robust and scalable applications.
    </p>
  </div>
  </Fragment>
);
export default Infrastructure_AVS;
