import React from 'react';
import './chapter.css'
const Chapter9_23 = () => (
    <div>
        <h2>Usage Example</h2>
        <p>
            <strong>Creation and management of lists:</strong>
        </p>
        <pre>
            <code>
{`// Creating a list 
variableToList("item1", "myList") 
variableToList("item2", "myList") 
variableToList("item3", "myList")

// Retrieving an item from the list 
itemFromList("myList", 1, "myVariable")

// Getting the length of the list 
getListLen("myList", "listLength")
`}
            </code>
        </pre>
        <p>
            <strong>Creation and management of objects (dictionaries):</strong>
        </p>
        <pre>
            <code>
{`// Creating an object 
AddvariableToJSON("key1", "value1", "myObject")
AddvariableToJSON("key2", "value2", "myObject")

// Retrieving a value by key from the object
variableFromJSON("myObject", "key1", "myVariable")
`}
            </code>
        </pre>
        <p>
            In this way, lists and objects in AVAP can be manipulated using the specific functions provided for working with variables stored as strings.
        </p>
    </div>
);

export default Chapter9_23;
