import React, { Fragment } from 'react';
import './chapter.css'
const Chapter8_3 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>Operators</h2>
      <p>
        In AVAP™, as in Python, expressions can include a variety of operators that perform specific operations on data. 
        Some of the most common operators include:
      </p>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Arithmetic</strong>: +, -, *, /, %, etc.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Logical</strong>: and, or, not.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Comparative</strong>: ==, !=, &gt;, &lt;, &gt;=, &lt;=, etc.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Assignment</strong>: =, +=, -=, *=, /=, etc.
        </li>
      </ul>
    </div>
  </Fragment>
);

export default Chapter8_3;
