import React, { useRef, useState } from "react";
import { Button } from "react-bootstrap";





// Tipo de Archivo	MIME Type
// JSON	 --------- application/json
// JPEG Image----- image/jpeg
// PNG Image------ /png
// PDF ------------/pdf
// Msft Word ----- application/msword
// Word (docx)---- application/vnd.openxmlformats-officedocument.wordprocessingml.document
// Excel --------- application/vnd.ms-excel
// Excel (xlsx)	-- application/vnd.openxmlformats-officedocument.spreadsheetml.sheet




const FileUpload = ({ onFileSelect, allowedTypes, label }) => {
    const fileInputRef = useRef(null); // Referencia al input oculto
    const [fileName, setFileName] = useState(""); // Estado para mostrar el nombre del archivo

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (allowedTypes && !allowedTypes.includes(file.type)) {
                alert(`Invalid file type. Allowed types: ${allowedTypes.join(", ")}`);
                fileInputRef.current.value = ""; // Resetea el input
                setFileName(""); // Borra el nombre del archivo
                return;
            }

            setFileName(file.name); // Actualiza el estado con el nombre del archivo seleccionado
            onFileSelect(file); // Llama a la función proporcionada por el padre para manejar el archivo
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Activa el input oculto
        }
    };

    return (
        <div className="file-upload-wrapper">

            <span className="file-upload-label">
                {fileName ? fileName : label || "No file selected"}
            </span>

            {/* Input oculto */}
            <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: "none" }}
            />

            {/* Botón estilizado */}
            <Button variant="primary" onClick={handleButtonClick}>
                Choose File
            </Button>


        </div>
    );
};

export default FileUpload;
