import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';
import "./../../../../assets/css/effectStickySections.css"

const Affiliate_Commisions_List = () => 

{

  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{
        "status":true,
        "result":{
           "number_of_results":"<integer>",
           "totals":[
              "<string>"
           ],
           "columns":[
              "<string>"
           ],
           "data":[
              
           ],
           "limit_results":"<integer>"
        },
        "elapsed":"<float>"
     }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: ` import requests url = "URL_BASE/ws/afiliados.py/comisionesafiliado?country_code=MX&user_id=2&session_id=442-4tXk7omYJZt7PGRAaToHVQ==&from=2020-01-07&=2020-07-13&limit=5&page=1" 
  payload = {} 
  files = {} 
  headers= {} 
  response = requests.request("GET", url, headers=headers, data = payload, files = files) 
  print(response.text.encode('utf8'))`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests url = "URL_BASE/ws/afiliados.py/comisionesafiliado?country_code=MX&user_id=2&session_id=442-4tXk7omYJZt7PGRAaToHVQ==&from=2020-01-07&=2020-07-13&limit=5&page=1" 
    payload = {} 
    files = {} 
    headers= {} 
    response = requests.request("GET", url, headers=headers, data = payload, files = files) 
    print(response.text.encode('utf8'))`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: ` var request = require('request');
    var options = {
    'method': 'GET',
    'url': 'URL_BASE/ws/afiliados.py/comisionesafiliado?country_code=MX&user_id=2&session_id=442-4tXk7omYJZt7PGRAaToHVQ==&from=2020-01-07&=2020-07-13&limit=5&page=1',
    'headers': {},
    formData: {}
    };
    request(options, function (error, response) { 
    if (error) throw new Error(error);
    console.log(response.body);
    });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: ` var formdata = new FormData();
    var requestOptions = {
    method: 'GET',
    body: formdata,
    redirect: 'follow'
    };
    fetch("URL_BASE/ws/afiliados.py/comisionesafiliado?country_code=MX&user_id=2&session_id=442-4tXk7omYJZt7PGRAaToHVQ==&from=2020-01-07&=2020-07-13&limit=5&page=1", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: `curl --location --request GET 'URL_BASE/ws/afiliados.py/comisionesafiliado?country_code=MX&user_id=2&session_id=442-4tXk7omYJZt7PGRAaToHVQ%3D%3D&from=2020-01-07&=2020-07-13&limit=5&page=1'`
      }
    ]
  }


  return(
    <Fragment>

<div className='container-fluid row'>
    <div className='col-md-7'>

      <h1 >Affiliate Commisions List</h1>
      <p>
        This service returns an entire list of the affiliate&#39;s commisions.
      </p>
      <p>
        <span className="label get">GET:</span>
        <span> </span> <code>URL_BASE + /ws/afiliados.py/comisionesafiliado</code>
        until
      </p>
      <h2 >Receives:</h2>
      <p>
        All parameters are sent in the querystring of the call, so a percentage
        encoding for URI must be applied &#40;aka URL encoding&#41;.
      </p>
      <div className='overflow-auto'>
      <table>
        <thead>
          <tr>
            <th align="left">Parameter</th>
            <th align="center">Format</th>
            <th align="center">Obligatory</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
            <th align="center">Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="left">country_code</td>
            <td align="center">
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                ISO 3166 alpha-2
              </a>
            </td>
            <td align="center">Yes</td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
            <td align="center"></td>
          </tr>
          <tr>
            <td align="left">user_id</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">
              Identifier of the user making the request &#40;Admin or
              Affiliate&#41;
            </td>
            <td align="center">N/A</td>
            <td align="center"></td>
          </tr>
          <tr>
            <td align="left">session_id</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send signature to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Obtained by identifying the user with ID user_id on the platform
            </td>
            <td align="center">N/A</td>
            <td align="center"></td>
          </tr>
          <tr>
            <td align="left">signature</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send session_id to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Enables you to sign the request without having to log in
            </td>
            <td align="center">N/A</td>
            <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
          </tr>
          <tr>
            <td align="left">limit</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">
              Number of results that we want to visualize per page
            </td>
            <td align="center">50</td>
            <td align="center">5</td>
          </tr>
          <tr>
            <td align="left">page</td>
            <td align="center">Integer</td>
            <td align="center">No</td>
            <td align="center">Page we want to obtain</td>
            <td align="center">1</td>
            <td align="center">1</td>
          </tr>
          <tr>
            <td align="left">from</td>
            <td align="center">
              String &#40;dd/mm/yyyy, dd-mm-yyyy, yyyy/mm/dd, yyyy-mm-dd&#41;
            </td>
            <td align="center">Yes</td>
            <td align="center">
              Date from which commissions start to be counted
            </td>
            <td align="center">N/A</td>
            <td align="center">2020-01-07</td>
          </tr>
          <tr>
            <td align="left"></td>
            <td align="center">
              String &#40;dd/mm/yyyy, dd-mm-yyyy, yyyy/mm/dd, yyyy-mm-dd&#41;
            </td>
            <td align="center">Yes</td>
            <td align="center">Date until which commissions are posted</td>
            <td align="center">N/A</td>
            <td align="center">2020-07-13</td>
          </tr>
        </tbody>
      </table>
      </div>

      <h2 >Returns:</h2>
      <p>
        Depending on the result of the operation, this service can return two
        different JSON:
      </p>
      <h3>Answer JSON OK:</h3>
  
      <div className='mb-3'>
        <DisplayCode1 codeToshow={codes.answe_ok1}/>
      </div>

      <h2 >Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li>
          <code>result:</code> Service answer
        </li>
        <li>
          <code>number_of_results:</code> Number of results that come in
          &#39;data&#39;
        </li>
        <li>
          <code>totals:</code> List with the sum of the values of each of the
          columns. Has the value <code>null</code> for fields that cannot be
          summed..
        </li>
        <li>
          <code>columns:</code> Name of each of the values found in each of the
          lists in the data list.
        </li>
        <li>
          <code>data:</code> A list of lists in which each one contains the values
          ​​for each of the columns.
        </li>
        <li>
          <code>limit_results:</code> Maximum number of results that come in the
          query.
        </li>
        <li>
          <code>elapsed:</code> Operation execution time.
        </li>
      </ul>
      <h3 >Answer JSON KO:</h3>
  
       <div className='mb-3'>
        <DisplayCodeKo/>
      </div>
      <h2 >Business logic:</h2>
          <p>
            Looks for affiliate commissions between two given dates and pay´s them
            back to you.
        </p>

      </div>
      <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
          
        </div>

      </div>
    </Fragment>
  );
}
export default Affiliate_Commisions_List;
