import React from 'react';
import './chapter.css'
const Chapter9_37 = () => (
    <div>
        <h2>Compound Statements</h2>
        <p>
            In AVAP, compound statements contain (groups of) other statements; these affect or control the execution of those other statements in some way. In general, compound statements span multiple lines, though in simpler representations a complete compound statement might be contained within a single line.
        </p>
        <p>
            <strong>if</strong> statements implement traditional flow control constructs. <strong>match</strong> specifies matching patterns for variable values. Function and class definitions are also syntactically compound statements.
        </p>
        <p>
            A compound statement consists of one or more "clauses." A clause consists of a header and a "suite." The clause headers of a particular compound statement are all at the same level of indentation. Each clause header begins with a uniquely identifying keyword and ends with a colon. A suite is a group of statements controlled by a clause. A suite can be one or more simple statements separated by semicolons on the same line as the header, following the colon of the header, or it can be one or more statements indented on subsequent lines. Only the latter form of a suite can contain nested compound statements.
        </p>
    </div>
);

export default Chapter9_37;
