import React from 'react';
import './chapter.css'
const Chapter9_4 = () => (
    <div>
        <h2>4.3. Importing Files</h2>
        <p>
            In AVAP, it is possible to import the contents of other code files. The <code>import file.avap</code> statement inserts the contents of the specified file at the exact point where the import statement appears. This process is linear and sequential, meaning that the imported content is executed as if it were part of the original file.
        </p>
        <p>
            It is crucial that the necessary functions are defined before they are called. If a function is not defined before its call, a <code>NameError</code> exception will be raised.
        </p>
        <p>Example of import usage:</p>
        <pre>
            <code>
{`avap

// Content of the file main.avap 
addVar(10, x) 
import functions.avap 
myFunction(x)

// Content of the file functions.avap
def myFunction(y):
    addVar(y + 5, result)
    print(result)
`}
            </code>
        </pre>
    </div>
);

export default Chapter9_4;
