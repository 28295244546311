import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h4>1.2.1 Conversion to Integer</h4>
  <p>
    To convert a value to an integer, the <code>int()</code> function is used.
  </p>
  <pre><code>
text = "10" <br />
number = int(text)
  </code></pre>
</div>
  </Fragment>
);
export default Intro_1;
