import React, { Fragment } from 'react';
import './chapter.css'
const Chapter8_2 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>Types of Expressions</h2>
      <p>
        In AVAP™, as in Python, there are several types of expressions that can be used to perform different operations and calculations. 
        Some of the most common types of expressions include:
      </p>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Arithmetic</strong>: Perform mathematical operations such as addition, subtraction, multiplication, and division.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Logical</strong>: Evaluate logical conditions and return boolean values, such as True or False.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Comparative</strong>: Compare two values and return a result based on their relationship, such as equality, inequality, greater than, less than, etc.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Assignment</strong>: Assign a value to a variable.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>Function Calls</strong>: Invoke functions and methods to perform specific tasks.
        </li>
      </ul>
    </div>
  </Fragment>
);

export default Chapter8_2;
