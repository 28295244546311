import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const Employee_data2 = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status":true,
        "result":[
           {
              "date":"2022-07-18",
              "event_type":"in",
              "time":"09:26:18"
           },
           {
              "date":"2022-07-18",
              "event_type":"out",
              "time":"11:02:02"
           }
        ],
        "actividad":{
           "total_month":140041.0,
           "total_today":5744.0,
           "total_week":5744.0
        },
        "codtran":"a76a620d445332824370b1eb3f995c69",
        "elapsed":0.07489490509033203
     }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requestsurl = "http://34.121.95.179:80/ws/employee_time_control.py/employee_data?country_code=ES&user_id=18&session_id=34726-WGmPWDqzvePDsvZQN_wfyQ==&user=74&date=2022-07-18"
    payload={}
    headers = { '101ObexApiKey': 'WcdoRNOUS8jQLs5XWo75yjwzvEZazcSI'}
    response = requests.request("GET", url, headers=headers, data=payload)
    print(response.text)`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requestsurl = "http://34.121.95.179:80/ws/employee_time_control.py/employee_data?country_code=ES&user_id=18&session_id=34726-WGmPWDqzvePDsvZQN_wfyQ==&user=74&date=2022-07-18"
    payload={}
    headers = { '101ObexApiKey': 'WcdoRNOUS8jQLs5XWo75yjwzvEZazcSI'}
    response = requests.request("GET", url, headers=headers, data=payload)
    print(response.text)`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'GET',
  'url': 'http://34.121.95.179:80/ws/employee_time_control.py/employee_data?country_code=ES&user_id=18&session_id=34726-WGmPWDqzvePDsvZQN_wfyQ==&user=74&date=2022-07-18',
  'headers': {
  '101ObexApiKey': 'WcdoRNOUS8jQLs5XWo75yjwzvEZazcSI'
  }
  };
  request(options, function (error, response) {
  if (error) throw new Error(error);
  console.log(response.body);
  });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var myHeaders = new Headers();
  myHeaders.append("101ObexApiKey", "WcdoRNOUS8jQLs5XWo75yjwzvEZazcSI");

  var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
  };

  fetch("http://34.121.95.179:80/ws/employee_time_control.py/employee_data?country_code=ES&user_id=18&session_id=34726-WGmPWDqzvePDsvZQN_wfyQ==&user=74&date=2022-07-18", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request GET 'http://34.121.95.179:80/ws/employee_time_control.py/employee_data?country_code=ES&user_id=18&session_id=34726-WGmPWDqzvePDsvZQN_wfyQ==&user=74&date=2022-07-18'; \
--header '101ObexApiKey: WcdoRNOUS8jQLs5XWo75yjwzvEZazcSI'`
      }
    ]
  }

  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >Get Employee Data</h1>
          <h2>
            ws/employee_time_control.py/employee_data
          </h2>
          <h3 >Receives</h3>
          <p>
            All the parameters that the service receives must be indicated in the body
            of the request.
          </p>
          <div className='overflow-auto'>

            <table>
              <thead>
                <tr>
                  <th align="center">Parameter</th>
                  <th align="center">Format</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                  <th align="center">Example</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">country_code</td>
                  <td align="center">
                    ISO 3166 alpha-2 &#40;
                    <a href="https://www.iso.org/glossary-for-iso-3166.html">
                      https://www.iso.org/glossary-for-iso-3166.html
                    </a>
                    &#41;
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">Country code in which the user resides</td>
                  <td align="center">N/A</td>
                  <td align="center">ES</td>
                </tr>
                <tr>
                  <td align="center">user_id</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Identifier of the user making the request &#40;Administrator&#41;
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">18</td>
                </tr>
                <tr>
                  <td align="center">session_id</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send signature to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Obtained by identifying the user with ID user_id on the platform
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">34242-7M94x6WvLh7-b19HC6PM_g==</td>
                </tr>
                <tr>
                  <td align="center">signature</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send session_id to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Enables you to sign the request without having to log in
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">328621ee3acda7a582338c5bba444c3094cd6f29</td>
                </tr>
                <tr>
                  <td align="center">user</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">User whose data will be recovered</td>
                  <td align="center"></td>
                  <td align="center">74</td>
                </tr>
                <tr>
                  <td align="center">date</td>
                  <td align="center">
                    String dd/mm/yyyy, yyyy/mm/dd, dd-mm-yyyy, yyyy-mm-dd
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Work registrations are obtained from the indicated date
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">2022-07-18</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2 >Where:</h2>
          <ul>
            <li>
              <p>
                <code>status:</code> Shows if the call has been successful
                &#40;true&#41; or not &#40;false&#41;.
              </p>
            </li>
            <li>
              <p>
                <code>codtran:</code> Operation result
              </p>
            </li>
            <li>
              <p>
                <code>result:</code> Data recovered for the user identifies with user
                parameter in date identified with date parameter.
              </p>
              <ul>
                <li>
                  <code>date</code>: Is employee actual status not working?
                </li>
                <li>
                  <code>event_type</code>: Is employee actual status working?.
                </li>
                <li>
                  <code>time</code>: Need the employee send gps positon to check in or
                  out?
                </li>
              </ul>
            </li>
            <li>
              <p>
                <code>actividad:</code> Summary for that date of time worked
              </p>
              <ul>
                <li>
                  <code>total_month</code>: seconds worked this month.
                </li>
                <li>
                  <code>total_today</code>: Total seconds worked this date.
                </li>
                <li>
                  <code>total_week:</code>Total seconds worked this week.
                </li>
              </ul>
            </li>
            <li>
              <p>
                <code>elapsed:</code> Operation execution time.
              </p>
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Business logic:</h2>
          <p>
            This service is used to retrieve a summary for a date identified with
            parameter date, to know worked periods and total seconds worked this day,
            this week and this month.
          </p>

        </div>
        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>

      </div>

    </Fragment>
  );
}
export default Employee_data2;
