import React, { Fragment } from 'react';
import './chapter.css'
const Chapter1_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>Introduction</h2>
      <p>
        Lexical analysis is the first step in the process of compiling or interpreting a program in AVAP™. It involves breaking down the source code into lexical components or "tokens," which are the smallest units of meaning in the language. These tokens include keywords, identifiers, operators, punctuation symbols, and literals.
      </p>
    </div>
  </Fragment>
);
export default Chapter1_1;
