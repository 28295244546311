import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const get_employees = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status":true,
        "codtran":"dbd5c8904ddd615e790727fa0f9ddd48",
        "resultado":[
           {
              "surname2":"Surname1",
              "surname1":"Surname2",
              "status":"status",
              "id":19,
              "actual":"out",
              "qr_to_check":"True",
              "active":true,
              "location_to_check":"True",
              "nick":"655555555",
              "location":[
                 15
              ],
              "name":"Name",
              "blocked":false,
              "email":"usuario@email.com"
           },
           "..."
        ],
        "elapsed":0.15848994255065918
     }`,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requestsurl = "http://34.121.95.179:80/ws/employee_time_control.py/get_employees?country_code=ES&user_id=18&session_id=34776-goDScaVG2Af8ttJxEhTF2Q=="
    payload={}
    headers = { '101ObexApiKey': 'WcdoRNOUS8jQLs5XWo75yjwzvEZazcSI'}
    response = requests.request("GET", url, headers=headers, data=payload)
    print(response.text)`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requestsurl = "http://34.121.95.179:80/ws/employee_time_control.py/get_employees?country_code=ES&user_id=18&session_id=34776-goDScaVG2Af8ttJxEhTF2Q=="
    payload={}
    headers = { '101ObexApiKey': 'WcdoRNOUS8jQLs5XWo75yjwzvEZazcSI'}
    response = requests.request("GET", url, headers=headers, data=payload)
    print(response.text)`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'GET',
  'url': 'http://34.121.95.179:80/ws/employee_time_control.py/get_employees?country_code=ES&user_id=18&session_id=34776-goDScaVG2Af8ttJxEhTF2Q==',
  'headers': {
  '101ObexApiKey': 'WcdoRNOUS8jQLs5XWo75yjwzvEZazcSI'
  }
  };
  request(options, function (error, response) {
  if (error) throw new Error(error);
  console.log(response.body);
  });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var myHeaders = new Headers();
  myHeaders.append("101ObexApiKey", "WcdoRNOUS8jQLs5XWo75yjwzvEZazcSI");

  var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
  };

  fetch("http://34.121.95.179:80/ws/employee_time_control.py/get_employees?country_code=ES&user_id=18&session_id=34776-goDScaVG2Af8ttJxEhTF2Q==", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request GET 'http://34.121.95.179:80/ws/employee_time_control.py/get_employees?country_code=ES&user_id=18&session_id=34776-goDScaVG2Af8ttJxEhTF2Q=='; \
--header '101ObexApiKey: WcdoRNOUS8jQLs5XWo75yjwzvEZazcSI'`
      }
    ]
  }

  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >Get Employee Data</h1>
          <h2 >
            ws/employee_time_control.py/employee_data
          </h2>
          <h3 >Receives</h3>
          <p>
            All the parameters that the service receives must be indicated in the body
            of the request.
          </p>
          <div className='overflow-auto'>

            <table>
              <thead>
                <tr>
                  <th align="center">Parameter</th>
                  <th align="center">Format</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                  <th align="center">Example</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">country_code</td>
                  <td align="center">
                    ISO 3166 alpha-2 &#40;
                    <a href="https://www.iso.org/glossary-for-iso-3166.html">
                      https://www.iso.org/glossary-for-iso-3166.html
                    </a>
                    &#41;
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">Country code in which the user resides</td>
                  <td align="center">N/A</td>
                  <td align="center">ES</td>
                </tr>
                <tr>
                  <td align="center">user_id</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Identifier of the user making the request &#40;Administrator&#41;
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">18</td>
                </tr>
                <tr>
                  <td align="center">session_id</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send signature to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Obtained by identifying the user with ID user_id on the platform
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">34242-7M94x6WvLh7-b19HC6PM_g==</td>
                </tr>
                <tr>
                  <td align="center">signature</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send session_id to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Enables you to sign the request without having to log in
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">328621ee3acda7a582338c5bba444c3094cd6f29</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2 >Where:</h2>
          <ul>
            <li>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li>
              <code>codtran:</code> Operation result.
            </li>
            <li>
              <code>result:</code> List of employees.
              <ul>
                <li>
                  <code>surname2</code>: The second surname of the employee.
                </li>
                <li>
                  <code>surname1</code>: The first surname of the employee.
                </li>
                <li>
                  <code>status</code>: Location of the employee.
                </li>
                <li>
                  <code>id</code>: Identification of the employee.
                </li>
                <li>
                  <code>actual</code>: Actual status &#40;Working, outside of
                  work&#41;
                </li>
                <li>
                  <code>location_to_check</code>: Needs to send the code of the
                  company to register state changes.
                </li>
                <li>
                  <code>nick</code>: Employees phone number.
                </li>
                <li>
                  <code>active</code>: Is the employee active?
                </li>
                <li>
                  <code>location</code>: List of locations where the employee can
                  register.
                </li>
                <li>`name:Name of the employee</li>
                <li>
                  <code>blocked</code>: Is the user blocked?
                </li>
                <li>
                  <code>email</code>: Employees email.
                </li>
              </ul>
            </li>
            <li>
              <code>elapsed:</code> Operation execution time.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Business logic:</h2>
          <p>
            This service has as an objective to retrieve all the employees of a
            company and also their actual status.
          </p>

        </div>
        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>

      </div>


    </Fragment>
  );
}
export default get_employees;
