import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const Close_Order = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `
      {
        "status":true,
        "codtran":"850c29598f8ceae89e7083d1547faa29",
        "result":"120d29398f8ceae89e707ad1547fa12c",
        "elapsed":0.12410902976989746
     }
     `,
      copied: false
    }
  };

  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
  url = "http://34.121.95.179:80/ws/orders.py/close?country_code=ES&user_id=133&session_id=1689-oocyMaFovWi1jljrF-eaSw==";
  payload={} 
  headers = { '101ObexApiKey': 'MS1phGJRa3WyLilN9dlZ7vurJDIpe0nM' }
  response = requests.request("GET", url, headers=headers, data=payload)
  print(response.text)`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
url = "http://34.121.95.179:80/ws/orders.py/close?country_code=ES&user_id=133&session_id=1689-oocyMaFovWi1jljrF-eaSw==";
payload={} 
headers = { '101ObexApiKey': 'MS1phGJRa3WyLilN9dlZ7vurJDIpe0nM' }
response = requests.request("GET", url, headers=headers, data=payload)
print(response.text)`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
var options = {
'method': 'GET',
'url': 'http://34.121.95.179:80/ws/orders.py/last_order_summary?country_code=ES&user_id=133&session_id=1689-oocyMaFovWi1jljrF-eaSw==',
'headers': {
'101ObexApiKey': 'MS1phGJRa3WyLilN9dlZ7vurJDIpe0nM'
}
};
request(options, function (error, response) {
if (error) throw new Error(error);
console.log(response.body);
});`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var myHeaders = new Headers();
myHeaders.append("101ObexApiKey", "MS1phGJRa3WyLilN9dlZ7vurJDIpe0nM");

var requestOptions = {
method: 'GET',
headers: myHeaders,
redirect: 'follow'
};

fetch("http://34.121.95.179:80/ws/orders.py/last_order_summary?country_code=ES&user_id=133&session_id=1689-oocyMaFovWi1jljrF-eaSw==", requestOptions)
.then(response => response.text())
.then(result => console.log(result))
.catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request GET 'http://34.121.95.179:80/ws/orders.py/last_order_summary?country_code=ES&user_id=133&session_id=1689-oocyMaFovWi1jljrF-eaSw==' \--header '101ObexApiKey: MS1phGJRa3WyLilN9dlZ7vurJDIpe0nM'`
      }
    ]
  }


  return (
    <Fragment>

      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >Close Order</h1>
          <h2 >ws/orders.py/close</h2>
          <h3 >Receives</h3>
          <p>
            All the parameters that the service receives must be indicated in the body
            of the request.
          </p>

          <div className='overflow-auto'>

            <table>
              <thead>
                <tr>
                  <th align="center">Parameter</th>
                  <th align="center">Format</th>
                  <th align="center">Obligatory</th>
                  <th align="center">Description</th>
                  <th align="center">Default value</th>
                  <th align="center">Example</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td align="center">country_code</td>
                  <td align="center">
                    ISO 3166 alpha-2 &#40;
                    <a href="https://www.iso.org/glossary-for-iso-3166.html">
                      https://www.iso.org/glossary-for-iso-3166.html
                    </a>
                    &#41;
                  </td>
                  <td align="center">Yes</td>
                  <td align="center">Country code in which the user resides</td>
                  <td align="center">N/A</td>
                  <td align="center">ES</td>
                </tr>
                <tr>
                  <td align="center">user_id</td>
                  <td align="center">Integer</td>
                  <td align="center">Yes</td>
                  <td align="center">
                    Identifier of the user making the request &#40;Administrator&#41;
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">18</td>
                </tr>
                <tr>
                  <td align="center">session_id</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send signature to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Obtained by identifying the user with ID user_id on the platform
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">34242-7M94x6WvLh7-b19HC6PM_g==</td>
                </tr>
                <tr>
                  <td align="center">signature</td>
                  <td align="center">String</td>
                  <td align="center">
                    Yes &#40;if you do not send session_id to &#39;Access
                    Service&#39;&#41;
                  </td>
                  <td align="center">
                    Enables you to sign the request without having to log in
                  </td>
                  <td align="center">N/A</td>
                  <td align="center">328621ee3acda7a582338c5bba444c3094cd6f29</td>
                </tr>
                <tr>
                  <td align="center">order_code</td>
                  <td align="center">String</td>
                  <td align="center">Yes</td>
                  <td align="center">Order code</td>
                  <td align="center">N/A</td>
                  <td align="center">101033</td>
                </tr>
              </tbody>
            </table>
          </div>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>

          <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>
          <h2 >Where:</h2>
          <ul>
            <li>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li>
              <code>codtran:</code> Operation result.
            </li>
            <li>
              <code>result:</code> Code of the transaction that cancels the order.
            </li>
            <li>
              <code>elapsed:</code> Operation execution time.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Where:</h2>
          <ul>
            <li>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li>
              <code>level:</code> Error importance level.
            </li>
            <li>
              <code>message:</code> Error message.
            </li>
            <li>
              <code>error:</code> Sole error code.
            </li>
          </ul>

          <h2 >Business logic:</h2>
          <p>
            The objective of this service is to permit an administrator close an
            order.
          </p>

        </div>
        <div className='col-md-5'>

          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>

      </div>



    </Fragment>
  );
}
export default Close_Order;
