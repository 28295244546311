import React, { Fragment } from 'react';
import DisplayCode1 from '../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../components/DisplayCode2/DisplayCode2';

const Retiro_en_cajero_EN = () => 
 {
  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `
      { "status": <boolean>, 
      "codtran": <string>, 
      "invoice": <integer>, 
      "signed": <boolean>, 
      "currency_data": { 
        "abbreviation": <string>, "suffix": <string>, 
        "format": <string>, "symbol": <string>, 
        "prefix": <string>, "decimals": <decimal>, 
        "id": <integer> 
      }, 
      "raw_amount": <decimal>, "currency": <string>, 
      "commission_user_card": <decimal>, 
      "user_commission": <decimal>, 
      "amount": <string> "elapsed": <float> 
    }`,
      copied: false
    },
    answe_ko1: {
      title:"Answer ok",
      type: "json",
      code: `
      { 
        "status": false, 
        "level": <string>, 
        "message": <string>, 
        "error": <string> 
      }`,
      copied: false
    },
    businesslogic: {
      title:"Answer ok",
      type: "json",
      code: `
      { "amount": <string>, "bank": <string>, 
      "total_registrations": <string>, 
      "amount_sum": <string>, "number_of_registrations": <string>, 
      "number_of_unsubscriptions": <string>, 
      "total_registrations": <string>,
       "total_unsubscriptions": <string>, 
       "transacciones": [ { 
        "phone_number": <string>, "telephone_company": <string>, 
        "beneficiary_name": <string>, "amount": <string>, 
        "rfc_beneficiary": <string>, "payment_date": <string>, 
        "validity_date": <string>, 
        "birthdate_beneficiary": <string> } ] }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: `import requests url = "URL_BASE/ws/pagomovil.py/pagomovil" 
      payload = { 'country_code': 'MX', 'user_id': '4532', 'session_id': '431-VJGiA5oBnkQlHPX8IAVsYg==', 'operation_type': 'mobile_money', 'operation_parameters': '{}' } 
      files = [] 
      headers= {} 
      response = requests.request("POST", url, headers=headers, data = payload, files = files) 
      print(response.text.encode('utf8'))`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests url = "URL_BASE/ws/pagomovil.py/pagomovil" 
        payload = { 'country_code': 'MX', 'user_id': '4532', 'session_id': '431-VJGiA5oBnkQlHPX8IAVsYg==', 'operation_type': 'mobile_money', 'operation_parameters': '{}' } 
        files = [] 
        headers= {} 
        response = requests.request("POST", url, headers=headers, data = payload, files = files) 
        print(response.text.encode('utf8'))`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: `var request = require('request');
            var options = {
            'method': 'POST',
            'url': 'URL_BASE/ws/pagomovil.py/pagomovil',
            'headers': {
            },
            formData: {
            'country_code': 'MX',
            'user_id': '4532',
            'session_id': '431-VJGiA5oBnkQlHPX8IAVsYg==',
            'operation_type': 'mobile_money',
            'operation_parameters': '{}'
            }
            };
            request(options, function (error, response) { 
            if (error) throw new Error(error);
            console.log(response.body);
            });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: `var formdata = new FormData();
            formdata.append("country_code", "MX");
            formdata.append("user_id", "4532");
            formdata.append("session_id", "431-VJGiA5oBnkQlHPX8IAVsYg==");
            formdata.append("operation_type", "mobile_money");
            formdata.append("operation_parameters", "{}");
            var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
            };
            fetch("URL_BASE/ws/pagomovil.py/pagomovil", requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: `curl --location --request POST 'URL_BASE/ws/pagomovil.py/pagomovil' \ --form 'country_code=MX' \ --form 'user_id=4532' \ --form 'session_id=431-VJGiA5oBnkQlHPX8IAVsYg==' \ --form 'operation_type=mobile_money' \ --form 'operation_parameters={}'`
      }
    ]
  }
  return(
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
      <h1 id="money-withdrawal-from-atm-machine">
        Money Withdrawal from ATM Machine
      </h1>
      <p>This service is used to withdraw money from an ATM machine.</p>
      <p>
        <span className="label post">POST:</span>
        <span> </span> <code>URL_BASE + /ws/pagomovil.py/pagomovil</code>
      </p>
      <h2 id="receives">Receives:</h2>
      <p>
        All the parameters that the service receives must be indicated in the body
        of the request.
      </p>
      <div className='overflow-auto'>
      <table>
        <thead>
          <tr>
            <th align="left">Parameter</th>
            <th align="center">Format</th>
            <th align="center">Obligatory</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
            <th align="center">Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="left">country_code</td>
            <td align="center">
              ISO 3166 alpha-2 &#40;
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                https://www.iso.org/glossary-for-iso-3166.html
              </a>
              &#41;
            </td>
            <td align="center">Yes</td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
            <td align="center">ES &#40;Spain&#41;</td>
          </tr>
          <tr>
            <td align="left">user_id</td>
            <td align="center">Integer</td>
            <td align="center">Yes</td>
            <td align="center">Identifier of the user making the request</td>
            <td align="center">N/A</td>
            <td align="center">4532</td>
          </tr>
          <tr>
            <td align="left">session_id</td>
            <td align="center">String</td>
            <td align="center">
              Yes &#40;if you do not send signature to &#39;Access
              Service&#39;&#41;
            </td>
            <td align="center">
              Obtained by identifying the user with ID user_id on the platform
            </td>
            <td align="center">N/A</td>
            <td align="center">385-XadgYoWixbzN_wqWc3Yyog==</td>
          </tr>
          <tr>
            <td align="left">signature</td>
            <td align="center">String</td>
            <td align="center">Yes</td>
            <td align="center">
              Enables you to sign the request without having to log in
            </td>
            <td align="center">N/A</td>
            <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
          </tr>
          <tr>
            <td align="left">operation_type</td>
            <td align="center">String</td>
            <td align="center">Yes</td>
            <td align="center">It must contain the value mobile_money</td>
            <td align="center">N/A</td>
            <td align="center">mobile_money</td>
          </tr>
          <tr>
            <td align="left">operation_parameters</td>
            <td align="center">JSON</td>
            <td align="center">Yes</td>
            <td align="center">This json is applied in the business logic</td>
            <td align="center">N/A</td>
            <td align="center">&#123;&#125;</td>
          </tr>
        </tbody>
      </table>
      </div>
      <h2 id="returns">Returns:</h2>
      <p>
        Depending on the result of the operation, this service can return two
        different JSON:
      </p>
      <h3 id="answer-json-ok">Answer JSON OK:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
      <h2 id="where">Where:</h2>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>signed:</code> Shows if the signature has been successfully
          processed &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>invoice:</code> Invoice number associated to the operation.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>currency_data:</code> Contains the details of the currency used in
          the operation.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>abbreviation:</code> The abbreviated name of the currency
          &#40;EUR, MXN&#41;.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>suffix:</code> Indicates the suffix that is applied in the
          currency format &#40;pesos, euros&#41;.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>format:</code> The full format that is applied to the currency, it
          includes the suffix and the prefix.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>symbol:</code> Symbol associated to the currency &#40;€, ¢,
          $&#41;.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>prefix:</code> The prefix that is applied in the currency format.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>decimals:</code> The maximum number of decimal places to be
          included in the currency format.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>id:</code> Identifier of the currency in BBDD.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>raw_amount:</code> Amount of the operation in negative without
          applying the format.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>currency:</code> Short name of the currency used in the operation,
          it matches the abbreviation.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>commission_user_card:</code> Commission that would be applied if
          the payment is made by card.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>codtran:</code> Transaction code that identifies the executed
          operation.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>user_commission:</code> Commission that will be applied to the
          user.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>amount:</code> Negative transaction amount with the applied
          format.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>elapsed:</code> Operation execution time.
        </li>
      </ul>
      <h3 id="answer-json-ko">Answer JSON KO:</h3>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
      <h2 id="donde">Donde:</h2>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>level:</code> Error importance level.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>message:</code> Error message.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>error:</code> Sole error code.
        </li>
      </ul>
      <h2 id="business-logic">Business logic:</h2>
      <p>
        By means of this service we can withdraw the desired amount from an ATM
        machine.
      </p>
      <p>
        The parameter <code>operation_parameters</code>, is a JSON with the
        following structure
      </p>
  
      <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.businesslogic}/>
          </div>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>amount:</code> Amount to withdraw from the ATM machine, without
          decimals. Example: &quot;100&quot;
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>bank:</code> it must cointain the value &quot;01&quot;.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>total_registrations:</code> Number of transactions to accomplish
          must be &quot;1&quot;.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>amount_sum:</code> Sum of the transaction amounts, it must take
          the value of the amount adding the decimals but without separator.
          Example: &quot;10000&quot;
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>number_of_registrations:</code> Number of transactions to
          accomplish must be &quot;1&quot;.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>number_of_unsubscriptions:</code> Number of transactions to
          accomplish must be &quot;1&quot;.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>total_registrations:</code> Sum of the transaction amounts, it
          must take the value of the amount adding the decimals but without
          separator. Example: &quot;10000&quot;
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>total_unsubscriptions:</code> Sum of the transaction amounts, it
          must take the value of the amount adding the decimals but without
          separator. Example: &quot;10000&quot;
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>transacciones:</code> List of the transactions to accomplish.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>phone_number:</code> Phone number that will receive the OTP to
          introduce in the ATM machine. It must containe the value 052 delante
          before the phone number. Example: &quot;0527229063245&quot;
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>telephone_company:</code> Identifier of the telephone company of
          the destination telephone of the OTP. Values: AT&amp;T:&quot;01&quot;,
          Movistar:&quot;02&quot;, Telcel:&quot;03&quot;. Example: &quot;01&quot;
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>beneficiary_name:</code> Name of the beneficiary of the money.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>amount:</code> Transaction amount must contain the amount value
          adding the decimals but without separator.Example:&quot;10000&quot;
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>rfc_beneficiary:</code> RFC of the beneficiary of the money.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>payment_date:</code> Date when the payment will be processed.
          Format: yyyy-mm-dd Example: &quot;2020-02-28&quot;
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>validity_date:</code> Payment validity date. yyyy-mm-dd Example:
          &quot;2020-02-28&quot;
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <code>birth_date_beneficiary:</code> Birth date of the money. yyyy-mm-dd
          Example: &quot;2020-02-28&quot;
        </li>
      </ul>
      </div>

      <div className='col-md-5'>
      <h2 id="example-requests">Example requests:</h2>
      <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
      
      </div>
      </div>
    </Fragment>
  );
 }
export default Retiro_en_cajero_EN;
