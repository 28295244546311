import React, { Fragment } from 'react';

const AVAP_Ambients = () => (
  <Fragment>
     <div>
    <h2>Getting Started with AVAP Ambients</h2>

    <h3>AVAP Ambients</h3>
    <p>
      AVS (AVAP Virtual Server) servers, following the guidelines established in the ACL, manage the various environments present in a deployment.
    </p>
    <p>
      An environment contains a specific number of AVAP Interpreter & Language Servers. An AVAP Interpreter & Language Server can operate in only one environment at a time.
    </p>
    <p>
      The environments established for AVAP Collaborative Network, AVAP Cloud, and AVAP Standalone deployments are:
    </p>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Local</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Test</li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>Live</li>
    </ul>
    <p>
      In AVAP Cloud Enterprise or AVAP Enterprise deployments, particularly for on-premise installations, administrators have complete freedom to define the number of environments needed, name them, and associate the required AVAP Interpreter & Language Servers for optimal service performance.
    </p>

    <h3>Environments in an AVAP Deployment</h3>

    <h4>1. Definition of Environments</h4>
    <p>
      <strong>Description:</strong> In an AVAP deployment, environments are workspaces used to manage the development, testing, and production of APIs. Environment configuration ensures a controlled flow throughout the API lifecycle.
    </p>

    <h4>2. Types of Environments</h4>
    <p><strong>By default, AVAP Framework operates with three environments:</strong></p>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Local:</strong>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Purpose:</strong> Development and initial testing environment.</li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Characteristics:</strong> Local setup on the developer’s machine. Allows for quick testing and modifications without affecting other environments.</li>
        </ul>
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Test:</strong>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Purpose:</strong> More controlled testing environment.</li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Characteristics:</strong> Simulates production conditions for more thorough testing before release. Ensures that APIs function correctly in a production-like environment.</li>
        </ul>
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Live:</strong>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Purpose:</strong> Live production environment.</li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Characteristics:</strong> The environment where APIs are exposed to end users. Must be fully stabilized and optimized to provide the best performance.</li>
        </ul>
      </li>
    </ul>

    <p>
      Working with API Products and configuring appropriate environments in AVAP Framework is essential for efficient API lifecycle management. API Products allow for effective organization and exposure of APIs, while environment configuration ensures a controlled flow between development, testing, and production.
    </p>

    <p>
      For more information on working with environments, visit AVAP Alexandria at <a href="http://alexandria.avapframework.com/">alexandria.avapframework.com</a> and engage with the user community at <a href="http://communities.avapframework.com/">AVAP Communities</a>.
    </p>
  </div>
  </Fragment>
);
export default AVAP_Ambients;
