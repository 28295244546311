import React, { useEffect, useState } from 'react';
import { Table, Button, Row, Col, Spinner, Dropdown, Form } from 'react-bootstrap';
import { faEllipsisV, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashLayout from '../functionalComponents/DashboardLayout/DashboardLayout';
import DashRouters from '../../configs/routers/dashboard-routers.json';
import Drawer from './Drawer';
import GatewayForm from './GatewayProxyForm';
import ProxIcon from '../../assets/img/ui_icons/cld_proxy.svg'
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import Organizations from '../../newViews/avapCloud/organizations';
import SessionService from '../../services/SessionsService';
import { useHistory } from 'react-router-dom';

// Tipo para un Proxy
type Proxy = {
  id?: string; // Opcional
  basePath: string;
  selectedGA: string[];
  useCerts: boolean;
  balance: boolean;
  algorithm: string;
  certs?: string[]; // Nuevo campo para los certificados
  routes?: string[];
};

const ProxyManager = () => {
  const [loading, setLoading] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [editingProxy, setEditingProxy] = useState<Proxy | null>(null);
  const [filteredProxies, setFilteredProxies] = useState<Proxy[]>([]);
  const [filtro, setFiltro] = useState('');
  const [certsT, setCertsT] = useState([]);

  const history = useHistory();
  const [proxies, setProxies] = useState<Proxy[]>([]);

  const handleSaveProxy = (newProxy: Proxy) => {
    if (editingProxy && editingProxy.id) {
      setProxies(
        proxies.map((proxy) =>
          proxy.id === editingProxy.id
            ? { ...newProxy, id: editingProxy.id }
            : proxy
        )
      );
    } else {
      setProxies([
        ...proxies,
        { ...newProxy, id: `${Math.random().toString(36).substr(2, 9)}` },
      ]);
    }
    setDrawerOpen(false);
    history.push('/proxies')
  };
  

  const handleOpenDrawer = (proxy?: Proxy) => {
    console.log(proxy);
    if (proxy && certsT) proxy.useCerts = true;
    setEditingProxy(
      proxy || {
        id: undefined,
        basePath: '',
        selectedGA: [],
        useCerts: false,
        balance: false,
        algorithm: '',
        routes: [],
        certs: certsT, // Inicializa certs vacío si no se edita
      }
    );
    setDrawerOpen(true);
    history.push('/proxies')
  };
  

  const handleDeleteProxy = async (proxy) => {
    if (window.confirm('Are you sure you want to delete this proxy?')) {

      console.log(proxy);
      //setProxies(proxies.filter((proxy) => proxy.id !== id));
      await ObexRequestHandler.get(`/gateway/delete_proxies?id=${proxy.id}`)
    }
    getProxies();
  };


  const getCerts = async () =>{
    const certs = await ObexRequestHandler.get(`/cert/get_certs?organization=${SessionService.ORGANIZATION}`)

    setCertsT(certs.data);

  };


	const getProxies = async () => {
		setLoading(true);
		try {
			const proxies_configs = await ObexRequestHandler.get(
				`/gateway/get_proxies?organization=${SessionService.ORGANIZATION}`
			);
			const proxies = proxies_configs.data.map((prox) => ({
				basePath: prox.basepath,
				id: prox.id,
				balance: prox.balance,
				algorithm: prox.ba_algorithm_name,
				selectedGA: prox.regions,
				routes: [],
				certs: prox.certificate_name && prox.certificate_name,
			}));
			setProxies(proxies);
			setFilteredProxies(proxies); 
		} catch (error) {
			console.error('Error fetching proxies:', error);
		} finally {
			setLoading(false);
		}
	};
  

	const changeFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFiltro(e.target.value);
	};

  useEffect(() => {
		const lowerCaseFilter = filtro.toLowerCase();
		const filtered = proxies.filter((proxy) =>
			proxy.basePath.toLowerCase().includes(lowerCaseFilter)
		);
		setFilteredProxies(filtered);
	}, [filtro, proxies]);

  useEffect(() => {
    getProxies();
    getCerts();
  }, []);

  return (
    <DashLayout sider={DashRouters} active="proxies" sublinks={[]}>
      <Row id="topHeader">
        <Col md="10">
          <div className="cld_wrapperTitle">
            <img src={ProxIcon} alt="icon dashboard" />
            <span className="cld_title w-100 margin-y-24px">
            <span style={{color:'#949498', fontSize:'14px'}}>Gateway Manager </span>/ Proxies 
            </span>
          </div>
        </Col>
        <Col md="2" className="align-content-center">
          <Button
            style={{ width: '225px' }}
            className="customButton-black btn px-4 float-right  resBtn"
            onClick={() => handleOpenDrawer()}>
            Add Proxy
          </Button>
        </Col>
        <Col>
          <div className="customBorder"></div>
        </Col>
      </Row>

      <Row id='table' className='mt-5'>
        <Col>
          {loading ? (
            <Row style={{ textAlign: 'center' }}>
              <Col>
                <Spinner animation="border" style={{ marginLeft: '8px' }} />
              </Col>
            </Row>
          ) : (
            <div className='cld_borderWrapper'>
              <span className='cld_sectionTitle'>Proxies</span>

              <div className="searchContainer">
                <Form.Control id='buscador' required type="text" value={filtro} placeholder="Search by Organization name"
                  onChange={(e) => setFiltro(e.target.value)}/>
                <Button
                  className="customButton-transparent px-4"
                  onClick={getProxies}>
                  <FontAwesomeIcon icon={faSearch} size="1x" />
                </Button>

              </div>

              <Table className="cld_tableWrapper">
                <tbody>
                  <tr className='tableRow'>
                    <th style={{ width: '20%' }} className='textBold'>Base Path</th>
                    <th style={{ width: '15%' }} className='textBold'>G.A.s</th>
                    <th style={{ width: '20%' }} className='textBold'>Certificates</th>
                    <th style={{ width: '20%' }} className='textBold'>Balance</th>
                    <th style={{ width: '20%' }} className='textBold'>Algorithm</th>
                    <th style={{ width: '5%', textAlign: 'end' }} className='textBold'>Actions</th>
                  </tr>


                  {filteredProxies.map((proxy) => (
                    <tr className="tableRow" key={proxy.id}>
                      <td className='td_centered'>{proxy.basePath}</td>
                      <td className='td_centered'>{proxy.selectedGA.join(', ')}</td>
                      <td className='td_centered'>
                        {proxy.certs && proxy.certs.length > 0
                          ? proxy.certs
                          : 'No certificates'}
                      </td>
                      <td className='td_centered'>{proxy.balance ? 'Enabled' : 'Disabled'}</td>
                      <td className='td_centered'>{proxy.algorithm || 'N/A'}</td>
                      <td className="text-right">
                        <div className="actionsWrapper">
                          <Dropdown className="my-auto" style={{ marginRight: '20px' }}>
                            <Dropdown.Toggle className="organizations">
                              <FontAwesomeIcon icon={faEllipsisV} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item key={1} onClick={() => handleOpenDrawer(proxy)}>
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item key={1} onClick={() => handleDeleteProxy(proxy)}>
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}

                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>
      <Drawer
        isOpen={drawerOpen}
        toggleSidebar={() => {setDrawerOpen(false); history.push('/proxies')}}
        name={editingProxy ? 'Edit Proxy' : 'Add Proxy'}
      >
        <GatewayForm
          id={editingProxy?.id}
          basePath={editingProxy?.basePath || ''}
          selectedGA={editingProxy?.selectedGA || []}
          useCerts={editingProxy?.useCerts || false}
          balance={editingProxy?.balance || false}
          algorithm={editingProxy?.algorithm || ''}
          routes={editingProxy?.routes || []}
          certs={editingProxy?.certs || certsT } //{editingProxy?.certs || certs}
          onSubmit={handleSaveProxy}
        />
      </Drawer>
    </DashLayout>
  );
};

export default ProxyManager;
