import React from 'react';
import './chapter.css'
const Chapter9_12 = () => (
    <div>
        <h2>6.2. Atoms</h2>
        <p>
            Atoms are the most basic elements of expressions in AVAP. The simplest atoms are identifiers or literals. Forms enclosed in parentheses, brackets, or braces are also syntactically categorized as atoms. The syntax for atoms is:
        </p>
        <pre>
            <code>
{`atom ::= identifier | literal | enclosure 
enclosure ::= parenth_form | list_display | dict_display | set_display | generator_expression
`}
            </code>
        </pre>
    </div>
);

export default Chapter9_12;
