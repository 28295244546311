import React from 'react';
import './chapter.css'
const Chapter9_24 = () => (
    <div>
        <h2>Binary Arithmetic Operations</h2>
        <p>
            Binary arithmetic operations have the conventional levels of precedence. Some of these operations also apply to certain non-numeric types. Aside from the exponentiation operator, there are two levels: one for multiplicative operators and another for additive ones:
        </p>
        <pre>
            <code>
{`m_expr ::=  u_expr | m_expr "*" u_expr | m_expr "@" m_expr |
            m_expr "//" u_expr | m_expr "/" u_expr |
            m_expr "%" u_expr
a_expr ::=  m_expr | a_expr "+" m_expr | a_expr "-" m_expr
`}
            </code>
        </pre>
        <p>
            The <code>*</code> (multiplication) operator produces the product of its arguments. The arguments can both be numbers, or one argument must be an integer and the other a sequence. In the first case, the numbers are converted to a common type and then multiplied. In the second case, sequence repetition occurs; a negative repetition factor produces an empty sequence.
        </p>
        <p>
            The <code>@</code> (matrix multiplication) operator is intended for matrix multiplication. No built-in type in Python implements this operator.
        </p>
        <p>
            The <code>/</code> (division) and <code>//</code> (floor division) operators produce the quotient of their arguments. Numeric arguments are converted to a common type. Division between integers produces a floating-point number, while floor division between integers results in an integer; the result is that of a mathematical division with the “floor” function applied to the result. Division by zero raises a <code>ZeroDivisionError</code>.
        </p>
        <p>
            The <code>%</code> (modulus) operator produces the remainder of the division of the first argument by the second. Numeric arguments are converted to a common type. A zero argument on the right raises a <code>ZeroDivisionError</code>. Arguments can be floating-point numbers, e.g., <code>3.14 % 0.7</code> is equal to <code>0.34</code> (since <code>3.14</code> is equal to <code>4 * 0.7 + 0.34</code>). The modulus operator always produces a result with the same sign as its second operand (or zero); the absolute value of the result is strictly smaller than the absolute value of the second operand.
        </p>
        <p>
            The floor division and modulus operators are connected by the following identity: <code>x == (x // y) * y + (x % y)</code>. Floor division and modulus are also connected by the built-in function <code>divmod()</code>: <code>divmod(x, y) == (x // y, x % y)</code>.
        </p>
        <p>
            In addition to performing the modulus operation on numbers, the <code>%</code> operator is also overloaded by string objects for old-style string formatting (also known as interpolation). The syntax for string formatting is described in the Python Library Reference, section Old-Style String Formatting.
        </p>
        <p>
            The floor division operator, the modulus operator, and the <code>divmod()</code> function are not defined for complex numbers. Instead, convert to a floating-point number using the <code>abs()</code> function if appropriate.
        </p>
        <p>
            The <code>+</code> (addition) operator produces the sum of its arguments. The arguments must both be numbers or both be sequences of the same type. In the first case, the numbers are converted to a common type and then added. In the second case, the sequences are concatenated.
        </p>
        <p>
            The <code>-</code> (subtraction) operator produces the difference between its arguments. Numeric arguments are converted to a common type.
        </p>
    </div>
);

export default Chapter9_24;
