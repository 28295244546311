import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h2>1.1 Basic Data Types</h2>
  <p>
    In AVAP™, like in Python, there are several basic data types:
  </p>
</div>
  </Fragment>
);
export default Intro_1;
