import React, { useEffect, useState } from 'react';
import icn_edit from '../../../assets/img/ui_icons/cld_edit.svg';

interface RowData {
  [key: string]: any; // Puedes definir campos específicos si conoces la estructura
}

interface DynamicTableProps {
  dataUrl: string;
  onEditRow?: (row: RowData) => void; // Prop `onEditRow` es opcional
}

const DynamicTable: React.FC<DynamicTableProps> = ({ dataUrl, onEditRow }) => {
  const [data, setData] = useState<RowData[]>([]);
  const [columns, setColumns] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 10; // Límite de filas por página

  useEffect(() => {
    fetch(dataUrl)
      .then(response => response.json())
      .then((data: RowData[]) => {
        setData(data);

        // Mapear y aplanar las claves de los objetos en el arreglo de datos
        const uniqueKeys = Array.from(
          new Set(data.reduce<string[]>((acc, item) => acc.concat(Object.keys(item)), []))
        );

        setColumns(uniqueKeys); // Establecer las columnas como un arreglo de strings
      })
      .catch(error => console.error('Error loading data:', error));
  }, [dataUrl]);

  const handleEdit = (row: RowData) => {
    if (onEditRow) {
      onEditRow(row); // Llama a `onEditRow` solo si está definido
    }
  };

  // Calcular el número de páginas
  const totalPages = Math.ceil(data.length / rowsPerPage);

  // Obtener datos de la página actual
  const currentData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const goToPage = (page: number) => {
    setCurrentPage(page);
  };

  // Definir gridTemplateColumns como una cadena única
  const gridTemplateColumns: string =
    columns
      .map(column => (column === 'description' ? 'minmax(250px, 2fr)' : 'minmax(150px, 1fr)'))
      .join(' ') + ' minmax(100px, 1fr)'; // Añadir columna de "Actions" al final

  return (
    <div>
      <div className='overflowX'>
        <div
          className="table-grid"
          style={{
            gridTemplateColumns // Asignar gridTemplateColumns como cadena
          }}
        >
          {/* Renderizar encabezados de columna */}
          {columns.map((column, index) => (
            <div key={index} className="header">
              {column === 'developers' ? '# of Developers' : column}
            </div>
          ))}
          <div className="header">Actions</div>

          {/* Renderizar filas de datos */}
          {currentData.map((item, rowIndex) => (
            <React.Fragment key={rowIndex}>
              {columns.map((column, colIndex) => (
                <div key={`${rowIndex}-${colIndex}`} className="cell">
                  {column === 'developers' ? (
                    Array.isArray(item[column]) ? (
                      <div>
                        {item[column].map((developer: any, devIndex: number) => (
                          <div key={devIndex} style={{ marginBottom: '8px' }}>
                            <div style={{ fontWeight: 'bold', opacity: '0.7' }}>{developer.name}</div>
                            <div style={{ fontSize: '0.9em', opacity: '0.5' }}>{developer.email}</div>
                          </div>
                        ))}
                      </div>
                    ) : typeof item[column] === 'number' ? (
                      <div className="devsItem">
                        {item[column]} Devs
                      </div>
                    ) : (
                      <span>{item[column]}</span>
                    )
                  ) : (
                    item[column] || '-'
                  )}
                </div>
              ))}
              <div className="cell">
                <button
                  className="btn btn-sm btn-table"
                  onClick={() => handleEdit(item)} // Pasamos la fila completa
                >
                  <img src={icn_edit} alt="icono para editar" />
                  Edit
                </button>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>

      {/* Controles de paginación */}
      {totalPages > 1 && (
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i + 1}
              className={`btn btn-sm ${currentPage === i + 1 ? 'btn-primary' : 'btn-secondary'}`}
              onClick={() => goToPage(i + 1)}
            >
              {i + 1}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default DynamicTable;
