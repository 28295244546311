import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const GenerateAccessTokenForm = () => {
    const [formData, setFormData] = useState({
        tokenName: '',
        expiration: '30 days',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Token Data Submitted:", formData);
        // Here you can add logic to submit the token data to your backend
    };

    return (
        <Form className="cloudForm" onSubmit={handleSubmit} style={{ maxWidth: '400px', margin: '0 auto' }}>
            <h2>Generate Personal Access Token</h2>
            <p>Personal access tokens are used to authenticate API requests.</p>

            {/* Token Name Field */}
            <Form.Group controlId="tokenName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                    type="text"
                    name="tokenName"
                    value={formData.tokenName}
                    onChange={handleChange}
                    placeholder="Enter token name"
                />
            </Form.Group>

            {/* Expiration Field */}
            <Form.Group controlId="expiration">
                <Form.Label>Expiration</Form.Label>
                <Form.Control
                    as="select"
                    name="expiration"
                    value={formData.expiration}
                    onChange={handleChange}
                >
                    <option value="30 days">30 days</option>
                    <option value="60 days">60 days</option>
                    <option value="90 days">90 days</option>
                    <option value="Never">Never</option>
                </Form.Control>
            </Form.Group>

            {/* Submit Button */}
            <div className="d-flex justify-content-end mt-4">
                <Button variant="primary" type="submit">Generate Token</Button>
            </div>
        </Form>
    );
};

export default GenerateAccessTokenForm;
