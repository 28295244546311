import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';

const Remittance_Payment = () => {

  const codes = {
    answe_ok1: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status": true,
        "signed": true,
        "result": {
            "description": "Remittance - 32001"
        },
        "currency_data": {
            "abbreviation": "MXN",
            "suffix": "pesos",
            "format": "$%.2f pesos",
            "symbol": "$",
            "prefix": "$",
            "decimals": 2.0,
            "id": 70
        },
        "response": {
            "description": "Remittance - 32001"
        },
        "invoice": 100092,
        "elapsed": 36.604214906692505,
        "raw_amount": -1.0,
        "currency": "MXN",
        "FIELDS": [],
        "commission_user_card": 0.0,
        "user_commission": 0.0,
        "codtran": "6532ef30ef4818e4f37c51b44bd324bc",
        "amount": "$-1.00 pesos"
    }`,
      copied: false
    },
    answe_ok2: {
      title: "Answer ok",
      type: "json",
      code: `{
        "status": false,
        "level": <str>,
        "message": <str>,
        "error": {
            "code": "***",
            "message": "************"
        }
    }`,
      copied: false
    },
    answe_ok3: {
      title: "Request",
      type: "json",
      code: `curl "https://example.com/ws/pagodeservicios.py/pagarservicio?country_code=MX&channel_id=1&user_id=90&service_id_next_step=1&version_bills=2&id_servicio=32001&codtran_info=07e0303f9ac3de9b5105fb72066f6149&REFERENCE_REMITTANCE=O123456789012&REPEAT_REFERENCE_REMITTANCE=O123456789012&AMOUNT=0&identification=RFC&no_identification=1234567890123&info=0&signature=d55324b0bd374833cbd3cafc05f76d9513a7895f`,
      copied: false
    }
  };

  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >
            <strong>Remittance Payment</strong>
          </h1>
          <p>Payment of remittance and notes in local wallet.</p>
          <p>
            <br></br>
            <span className="label post">GET:</span>
            <span> </span>{' '}
            <code>URL_BASE + /ws/pagodeservicios.py/pagarservicio</code>
          </p>
          <h3 >Receives:</h3>
          <table>
            <thead>
              <tr>
                <th align="center">Parameter</th>
                <th align="center">Obligatory</th>
                <th align="center">Format</th>
                <th align="center">Description</th>
                <th align="center">Default value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="center">country_code</td>
                <td align="center">Yes</td>
                <td align="center">
                  <a href="https://www.iso.org/glossary-for-iso-3166.html">
                    ISO 3166 alpha-2
                  </a>
                </td>
                <td align="center">Country code in which the user resides</td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="center">user_id</td>
                <td align="center">Yes</td>
                <td align="center">Numeric</td>
                <td align="center">
                  Identifier of the user account making the request.
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="center">channel_id</td>
                <td align="center">No</td>
                <td align="center">Numeric</td>
                <td align="center">Channel from which the request is made.</td>
                <td align="center">1 &#40;Web&#41;</td>
              </tr>
              <tr>
                <td align="center">session_id</td>
                <td align="center">
                  Yes, if the signature parameter is not reported
                </td>
                <td align="center">Alphanumeric</td>
                <td align="center">
                  Obtained by identifying the user with ID user_id on the plataform
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="center">signature</td>
                <td align="center">
                  Yes, if the session_id parameter is not reported
                </td>
                <td align="center">Alphanumeric</td>
                <td align="center">
                  Allows you to sign the petition, without having to log in
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="center">service_id_next_step</td>
                <td align="center">Yes</td>
                <td align="center">Numeric</td>
                <td align="center">
                  Request the fields from the next step if there is a
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="center">version_bills</td>
                <td align="center">Yes</td>
                <td align="center">Numeric</td>
                <td align="center">Version used to consume the service</td>
                <td align="center">1</td>
              </tr>
              <tr>
                <td align="center">codtran_info</td>
                <td align="center">Yes</td>
                <td align="center">Alphanumeric</td>
                <td align="center">Transaction code to associate with</td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="center">REFERENCE_REMITTANCE</td>
                <td align="center">Yes</td>
                <td align="center">Alphanumeric</td>
                <td align="center">Remittance code to be used to receive payment</td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="center">REPEAT_REFERENCE_REMITTANCE</td>
                <td align="center">Yes</td>
                <td align="center">Alphanumeric</td>
                <td align="center">
                  Repeat the REFERENCE_REMITTANCE code to validate
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="center">AMOUNT</td>
                <td align="center">Yes</td>
                <td align="center">Numeric</td>
                <td align="center">Amount of money</td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="center">identification</td>
                <td align="center">Yes</td>
                <td align="center">Text</td>
                <td align="center">
                  Type of documentation with which the user is identified
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="center">no_identification</td>
                <td align="center">Yes</td>
                <td align="center">Numeric</td>
                <td align="center">Number of documentation identifying the user</td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="center">info</td>
                <td align="center">Yes</td>
                <td align="center">Numeric</td>
                <td align="center">
                  If it is set to 1, it only returns the requested remittance
                  information and does not make the payment. To make the payment must
                  contain the value 0
                </td>
                <td align="center">N/A</td>
              </tr>
              <tr>
                <td align="center">paso</td>
                <td align="center">Yes</td>
                <td align="center">Numeric</td>
                <td align="center">
                  Number of the step of the process in which the process is located, 0
                  to obtain remittance information, 1 to make the payment
                </td>
                <td align="center">N/A</td>
              </tr>
            </tbody>
          </table>
          <blockquote>
            <p>
              The AMOUNT parameter in this case is not used in this service but needs
              to be sent with a value of 0.
            </p>
          </blockquote>
          <p>
            <br></br>
          </p>

          <p>
            <br></br>
          </p>
          <h3 >Example OK answer:</h3>

          <div className='mb-3'>
        <DisplayCode1 codeToshow={codes.answe_ok1}/>
      </div>
          <h3 >Example KO answer:</h3>

          <div className='mb-3'>
        <DisplayCode1 codeToshow={codes.answe_ok2}/>
      </div>

        </div>
        <div className='col-md-5'>
          <h3 >Example requests:</h3>

          <div className='effectStickySections'>

<div className='mb-3'>
<DisplayCode1 codeToshow={codes.answe_ok3}/>
</div>

</div>

        </div>

      </div>

    </Fragment>
  );
}
export default Remittance_Payment;
