import React from 'react';
import './chapter.css'
const Chapter13_4 = () => (
  <div>
    <h2>Syntax of Includes</h2>
    <p>
      In AVAP™, the syntax for including a file is similar to that of other languages like C. The keyword <code>include</code> is used followed by the name of the file to be included. There are two main ways to include files in AVAP™:
    </p>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
        <strong>Local Include:</strong> Used to include project-specific files located in the same directory or in subdirectories relative to the current file. The file name is specified within quotes. Example:
        <pre><code>include "file_name.avap"</code></pre>
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
        <strong>System Include:</strong> Used to include standard or system library files located in predefined or configured paths on the system. The file or library name is specified between angle brackets (&lt; and &gt;). Example:
        <pre><code>include &lt;library_name.avap&gt;</code></pre>
      </li>
    </ul>
  </div>
);

export default Chapter13_4;

