import React, { useState } from 'react';
import { Form, Button, Row, Col } from 'react-bootstrap';

interface PaymentFormProps {
  onCancel: () => void;
  onSubmit: () => void;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ onCancel, onSubmit }) => {
  const [cardNumber, setCardNumber] = useState<string>('');
  const [expiryMonth, setExpiryMonth] = useState<string>('');
  const [expiryYear, setExpiryYear] = useState<string>('');
  const [cvv, setCvv] = useState<string>('');

  // Manejar el cambio del número de tarjeta con formato
  const handleCardNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/\D/g, ''); // Remueve caracteres no numéricos
    value = value.replace(/(.{4})/g, '$1 ').trim(); // Formatea en grupos de 4 dígitos
    setCardNumber(value);
  };

  // Manejar el cambio del mes de expiración
  const handleExpiryMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, ''); // Remueve caracteres no numéricos
    setExpiryMonth(value.slice(0, 2)); // Limita a 2 dígitos
  };

  // Manejar el cambio del año de expiración
  const handleExpiryYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, ''); // Remueve caracteres no numéricos
    setExpiryYear(value.slice(0, 2)); // Limita a 2 dígitos
  };

  // Manejar el cambio del CVV
  const handleCvvChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, ''); // Remueve caracteres no numéricos
    setCvv(value.slice(0, 4)); // Limita a 4 dígitos
  };

  return (
    <Form className='cloudForm' onSubmit={(e) => { e.preventDefault(); onSubmit(); }}>
      {/* Cardholder's Name */}
      <Form.Group controlId="cardholderName">
        <Form.Label>Cardholder’s Name</Form.Label>
        <Form.Control type="text" placeholder="Name" />
      </Form.Group>

      {/* Credit Card Number, Expiration Date, and CVV */}
      <Form.Group>
        <Row>
          {/* Credit Card Number */}
          <Col md={6}>
            <Form.Label>Credit Card Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="1234 5678 9012 3456"
              maxLength={19} // maxLength como número
              value={cardNumber}
              onChange={handleCardNumberChange}
            />
          </Col>

          {/* Expiration Date */}
          <Col md={3}>
            <Form.Label>Expiration Date</Form.Label>
            <Row>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="MM"
                  maxLength={2} // maxLength como número
                  value={expiryMonth}
                  onChange={handleExpiryMonthChange}
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="YY"
                  maxLength={2} // maxLength como número
                  value={expiryYear}
                  onChange={handleExpiryYearChange}
                />
              </Col>
            </Row>
          </Col>

          {/* CVV */}
          <Col md={3}>
            <Form.Label>CVV/CVV2</Form.Label>
            <Form.Control
              type="text"
              placeholder="CVV"
              maxLength={4} // maxLength como número
              value={cvv}
              onChange={handleCvvChange}
            />
          </Col>
        </Row>
      </Form.Group>

      {/* Buttons */}
      <div className="d-flex justify-content-between mt-3">
        <Button variant="primary" type="submit">
          Agree & Upgrade
        </Button>
        <Button variant="outline-secondary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Form>
  );
};

export default PaymentForm;
