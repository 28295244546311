import React, { Fragment } from 'react';

const Servicio_de_notificaciones_Crear_Grupo_EN = () => 
  {
    return(
      <Fragment>
        
        <h1 id="notifications-service-create-group">
          Notifications Service &#40;Create Group&#41;
        </h1>
        <p>
          This service is used to generate a notification to all the users of a
          group.
        </p>
        <p>
          <span className="label post">POST:</span>
          <span> </span>{' '}
          <code>URL_BASE + /ws/notificaciones.py/gestor_notificaciones</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
              <th align="center">Example</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                ISO 3166 alpha-2 &#40;
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  https://www.iso.org/glossary-for-iso-3166.html
                </a>
                &#41;
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
              <td align="center">ES &#40;Spain&#41;</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Identifier of the user making the request of the user who makes the
                solicitude
              </td>
              <td align="center">N/A</td>
              <td align="center">4532</td>
            </tr>
            <tr>
              <td align="left">session_id</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
              <td align="center">385-XadgYoWixbzN_wqWc3Yyog==</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session_id to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
              <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
            </tr>
            <tr>
              <td align="left">command</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">
                It must contain the value <code>create_group</code>
              </td>
              <td align="center">N/A</td>
              <td align="center">create_group</td>
            </tr>
            <tr>
              <td align="left">issue</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">Text to show in the notification object</td>
              <td align="center">N/A</td>
              <td align="center">Lorem ipsum dolor sit amet</td>
            </tr>
            <tr>
              <td align="left">text</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">Text to show in the notification</td>
              <td align="center">N/A</td>
              <td align="center">Lorem ipsum dolor sit amet</td>
            </tr>
            <tr>
              <td align="left">profile_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Identifier of the profile to modify &#40;obtained from{' '}
                <code>/ws/util.py/perfiles</code>&#41;
              </td>
              <td align="center">N/A</td>
              <td align="center">6</td>
            </tr>
          </tbody>
        </table>
      </Fragment>
    );
  }
export default Servicio_de_notificaciones_Crear_Grupo_EN;
