import axios from 'axios';
import ErrorHandler from './ErrorHandler';

class RequestHandler {
  private static errHandler = ErrorHandler;

  constructor(){
    axios.defaults.timeout = 50;
  }


  public static async get(url: string, params:object = {}, headers: object = {}): Promise<any> {
    try {
      const parameters:object = Object.assign({ params }, { headers });
      return (await axios.get(url, parameters)).data || {};
    } catch (error) {
      return this.errHandler.handleFailRequest(error);
    }    
  }

  public static async delete(url: string, params:object = {}, headers: object = {}): Promise<any> {
    try {
      const parameters:object = Object.assign({ params }, { headers });
      return (await axios.delete(url, parameters)).data || {}; 
    } catch (error) {
      return this.errHandler.handleFailRequest(error);
    }    
  }

  public static async post(url: string, payload: object = {}, headers: object = {}): Promise<any> {
    try {
      return (await axios.post(url, payload, { headers })).data || {};
    } catch (error) {
      return this.errHandler.handleFailRequest(error);
    }    
  }

  public static async put(url: string, payload: object = {}, headers: object = {}): Promise<any> {
    try {
      return (await axios.put(url, payload, { headers })).data || {};
    } catch (error) {
      return this.errHandler.handleFailRequest(error);
    }    
  }

  public static async patch(url: string, payload: object = {}, headers: object = {}): Promise<any> {
    try {
      return (await axios.patch(url, payload, { headers })).data || {};
    } catch (error) {
      return this.errHandler.handleFailRequest(error);
    }    
  }

}


export default RequestHandler;