import React, { useState, useEffect } from "react";
import { Form, Row, Col, Button, ListGroup, InputGroup, FormControl } from "react-bootstrap";
import ObexRequestHandler from "../../handlers/ObexRequestsHandler";
import { FaCopy, FaTrash } from "react-icons/fa";
import Cookies from 'universal-cookie';
import { useParams, useHistory } from "react-router-dom";


const cookies = new Cookies();
interface App {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  apiProducts: string[];
}
interface Portal {
  portal_id: number;
  id: string;
  name: string;
  logo: string;
  theme: {
    primaryColor: string;
    secondaryColor: string;
    backgroundColor: string;
    textColor: string;
  };
  user: {
    name: string;
    photo: string;
  };
  apps: App[];
  apiProducts: ApiProduct[];
}

type ApiProduct = {
  title: string;
  description: string;
  details: string;
  latestVersion: string;
  id: string;
};

type CreateAppFormProps = {
  devportal_id: string;
  app_data: any;
  onSubmit: () => void;
};

const CreateAppForm: React.FC<CreateAppFormProps> = ({ devportal_id, onSubmit, app_data }) => {
  const [portal, setPortal] = useState<Portal | null>(null);
  const [appName, setAppName] = useState("");
  const [appDescription, setAppDescription] = useState("");
  const [filteredProducts, setFilteredProducts] = useState<ApiProduct[]>([]);
  const [selectedAPIs, setSelectedAPIs] = useState<string[]>([]);
  const [userID, setUserId] = useState(0)
  const [userName, setUserName] = useState('unknown')

  // Nueva funcionalidad: Tokens
  const [tokens, setTokens] = useState<string[]>([]);


  const DevPortalExists = async ()=>{

  const { id } = useParams<{ id: string }>();
  const history = useHistory();

    const portal = cookies.get('portal');
    const user = cookies.get('user');

    if (!portal) history.replace(`/devportal/${id}/login`)

      
    console.log('>>' + portal);
    if (portal.id!=undefined) {
      console.log('>>');
      console.log(portal)
    } else {
      history.replace(`/devportal/${id}/login`)
    }
    if (user.username!=undefined) {
      setUserName(user.username)
      setUserId(user.user_id)
    }

  }

  // Obtener los productos desde el API
  useEffect(() => {
    DevPortalExists();
    const fetchProducts = async () => {
      try {
        const res = await ObexRequestHandler.get(`/publishing/get_dev_portal_products/${devportal_id}`, {}, true);
        const { data } = res;
        if (data && data.length > 0) {
          const api_products = data.map((prod: any) => ({
            title: prod.avap_api_product_name,
            description: prod.dev_portal_published_description,
            latestVersion: "v0.1",
            details: prod.dev_portal_published_description + prod.avap_api_product_documentation,
            id: String(prod.avap_api_product_id)
          }));
          setFilteredProducts(api_products);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchProducts();
  }, [devportal_id]);

  const handleSelectAPI = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = e.target.value;
    if (selectedId && !selectedAPIs.includes(selectedId)) {
      setSelectedAPIs([...selectedAPIs, selectedId]);
    }
    e.target.value = '';
  };

  const handleRemoveAPI = (apiId: string) => {
    setSelectedAPIs(selectedAPIs.filter((id) => id !== apiId));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();


    const user = cookies.get('user');
    console.log("User", user);


    const body = {
      app_description: appDescription,
      devportal_id: devportal_id,
      app_name: appName,
      api_products: selectedAPIs,
      user_id: user.user_id,
      tokens: tokens
    };

    console.log("devportal_id:", devportal_id);
    console.log("URL final:", "/publishing/create_dev_portal_app");
    console.log("Payload:", body);


    const res = await ObexRequestHandler.post("/publishing/create_dev_portal_app", body);
    console.log(res);
    onSubmit();

  };

  const availableAPIs = filteredProducts.filter((api) => !selectedAPIs.includes(api.id));

  function generateToken() {
    const array = new Uint8Array(16); // 16 bytes
    window.crypto.getRandomValues(array); // Generar valores aleatorios
  
    // Convertir a formato hexadecimal
    return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join('');
  }

  // Handlers para Tokens (nueva funcionalidad)
  const handleCreateToken = () => {
    const token = generateToken();
    // Genera un token hardcodeado (puede ser un valor mock)
    const newToken = token;
    setTokens([...tokens, newToken]);
  };

  const handleCopyToken = async (token: string) => {
    try {
      await navigator.clipboard.writeText(token);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleRemoveToken = (token: string) => {
    setTokens(tokens.filter((t) => t !== token));
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="appName" className="mb-3">
        <Form.Label>Application Name</Form.Label>
        <Form.Control
          type="text"
          placeholder="Enter application name"
          value={appName || app_data.name}
          onChange={(e) => setAppName(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="appDescription" className="mb-3">
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          placeholder="Enter a brief description of the application"
          value={appDescription || app_data.description }
          onChange={(e) => setAppDescription(e.target.value)}
        />
      </Form.Group>

      <Form.Group controlId="selectAPIs" className="mb-3">
        <Form.Label>Select API Product</Form.Label>
        <Form.Control as="select" onChange={handleSelectAPI} defaultValue="">
          <option value="" disabled>
            -- Select an API Product --
          </option>
          {(availableAPIs).map((api) => (
            <option key={api.id} value={api.id}>
              {api.title}
            </option>
          ))}
        </Form.Control>
      </Form.Group>

      {(selectedAPIs.length > 0  || true) && (
        <Form.Group controlId="selectedAPIsList" className="mb-3">
          <Form.Label>Selected APIs</Form.Label>
          <ListGroup>
            {( selectedAPIs.length ===0 ? (app_data.apiProductsID ? app_data.apiProductsID: []): selectedAPIs).map((apiId) => {
              const api = filteredProducts.find((p) => p.id === apiId);
              return (
                <ListGroup.Item
                  key={apiId}
                  className="d-flex justify-content-between align-items-center"
                >
                  {api?.title || apiId}
                  <Button
                                          //variant="danger"
                                          style={{backgroundColor:'transparent', borderColor:'transparent'}}
                    size="sm"
                    onClick={() => handleRemoveAPI(apiId)}
                  >
                    x
                  </Button>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Form.Group>
      )}

      {/* Nueva funcionalidad: Tokens */}
      <Form.Group controlId="createTokens" className="mb-3 mt-3">
        <Button variant="secondary" onClick={handleCreateToken} className="mb-2">
          Create Token
        </Button>
        {(tokens.length > 0 || true) && (
          <ListGroup>
            {(tokens.length == 0 ? (app_data.tokens ? app_data.tokens: []) : tokens).map((token) => (
              <ListGroup.Item key={token} className="d-flex align-items-center">
                <InputGroup>
                  <FormControl type="text" readOnly value={token} />
                  <Button variant="outline-secondary" onClick={() => handleCopyToken(token)}>
                    <FaCopy />
                  </Button>
                  <Button variant="danger" onClick={() => handleRemoveToken(token)}>
                    <FaTrash />
                  </Button>
                </InputGroup>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Form.Group>

      <Row className="mt-4">
        <Col md="6">
          <Button className="w-100" variant="primary" type="submit">
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CreateAppForm;
