import React, { Fragment } from 'react';
import './chapter.css'
const Intro_1 = () => (
  <Fragment>
      <div className='body-md'>
    <h1>Introduction</h1>
    <h2>Discovering a New Programming Language</h2>
    <p>
      Welcome to the AVAP book, where you will delve into the fascinating world of an innovative and powerful programming language: <strong>AVAP™</strong>. In these pages, we will explore together the fundamental concepts, syntax, and unique features of AVAP™, and prepare you to master this new language and harness its full potential in your software development projects.
    </p>
  </div>
  </Fragment>
);
export default Intro_1;
