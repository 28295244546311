import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_21 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>ormAccessSelect()</h2>
      <p>
        The <code>ormAccessSelect()</code> command retrieves records from a table in a database based on the provided selection criteria. This command selects the desired fields and stores the results in a target variable.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>fields</strong> <br />
          Type: <code>variable</code> <br />
          Description: A string containing the names of the fields to be retrieved. The field names should be separated by commas.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>dbase</strong> <br />
          Type: <code>variable</code> <br />
          Description: The name of the database from which records should be retrieved. It must be a variable containing the name of the database.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>selector</strong> <br />
          Type: <code>variable</code> <br />
          Description: A condition to select the records to be retrieved. It must be a string specifying the selection criteria in SQL format, such as <code>id = 1</code>.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>varTarget</strong> <br />
          Type: <code>variable</code> <br />
          Description: The variable in which the query results will be stored. It must be a variable that will receive a list of dictionaries, each representing a retrieved record.
        </li>
      </ul>
      <h3>Command Flow</h3>
      <ol>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Defining the Fields:</strong> Use the field names (<code>fields</code>) to specify which data should be retrieved.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Selecting Records:</strong> Use the condition provided in <code>selector</code> to identify which records should be selected from the database.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Retrieving Data:</strong> Access the database specified by <code>dbase</code> and retrieve the records that meet the <code>selector</code> condition, including only the specified fields.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Storing the Result:</strong> Save the query results in the variable specified by <code>varTarget</code>. The stored value will be a list of dictionaries, where each dictionary represents a retrieved record with the requested fields.</li>
      </ol>
      <h3>Example Usage</h3>
      <p>Suppose you want to retrieve the <code>username</code> field for all users where <code>age</code> is greater than 25 from a database called <code>myDatabase</code>.</p>
      <pre>
        <code>
          {`// Define variables
fields = "username"
dbase = "myDatabase"
selector = "age > 25"
usersList = ''

// Call the command to retrieve the records
ormAccessSelect(fields, dbase, selector, usersList)

// Return the query results via addResult
addResult(usersList)`}
        </code>
      </pre>
      <p>In this example, the <code>ormAccessSelect()</code> command will retrieve the <code>username</code> field for all users in the <code>myDatabase</code> database where <code>age</code> is greater than 25. The results will be stored in the <code>usersList</code> variable, and this variable will be returned via <code>addResult(usersList)</code>. The output will be a list of dictionaries, each representing a user whose username has been retrieved.</p>
    </div>
  </Fragment>
);

export default Appendices_21;
