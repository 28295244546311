import React, { Fragment } from 'react';
import './chapter.css'
const Infrastructure_AVS = () => (
  <Fragment>
         <div>
      <h2>The Virtuality Attribute in AVAP™</h2>
      <p>
        AVAP™ (Advance Virtual API Programming) is a dynamic programming language distinguished by its virtuality attribute, which enables the development of virtual APIs in a dynamic and flexible manner. This attribute is based on the fact that the language specifications do not reside in the language interpreter, allowing the final code to be constructed in real-time by the language server.
      </p>
    </div>
  </Fragment>
);
export default Infrastructure_AVS;
