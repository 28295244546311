import React from 'react';
import './chapter.css'
const Chapter9_48 = () => (
    <div>
        <h2>Group Patterns</h2>
        <p>
            In AVAP, group patterns are used to group multiple patterns together. The syntax for a group pattern is:
        </p>
        <pre><code>group_pattern ::=  "(" pattern ")"</code></pre>
        <p>
            Group patterns are useful when you want to combine patterns or when patterns need to be evaluated together. They have the same effect as the pattern they contain but allow for more complex pattern structures.
        </p>
        <p>
            Here’s an example of how to use group patterns in AVAP:
        </p>
        <pre><code>match value:
    case (42 | 43):
        print("Matched either 42 or 43")
    case (name, age) if age {'>'} 18:
        print(f"{name} is an adult")
    case _:
        print("Matched something else")</code></pre>
        <p>
            In this example:
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>case (42 | 43):</code> uses a group pattern to match either the value 42 or 43.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>case (name, age) if age {'>'} 18:</code> uses a group pattern to match a tuple and includes an additional condition on the age.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><code>case _:</code> matches any other value not covered by the previous cases.</li>
        </ul>
        <p>
            Group patterns are ideal for creating more complex matching scenarios where patterns need to be combined or grouped together.
        </p>
    </div>
);

export default Chapter9_48;
