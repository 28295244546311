import React, { Fragment, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const Currency_List = () => {

  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{
        "status":true,
        "currencies":[
           {
              "change_sale":"<float>",
              "currency_data":{
                 "abbreviation":"<string>",
                 "suffix":"<string>",
                 "format":"<string>",
                 "symbol":"<string>",
                 "prefix":"<string>",
                 "decimals":"<decimal>",
                 "id":"<integer>"
              },
              "abbreviation":"<string>",
              "format":"<string>",
              "currency_symbol":"<string>",
              "precision":"<integer>",
              "change_purchase":"<float>",
              "change_reference":"<float>",
              "name":"<string>",
              "placeholder_currency":"<string>"
           }
        ],
        "elapsed":0.008363962173461914
     }`,
      copied: false
    }
  };


const codesMutiple={
  selected: {
    id: "python",
    type: "python",
    code: ` import requests 
    url = "URL_BASE/ws/currencies.py/listado" 
    payload = {} 
    headers= {} 
    response = requests.request("GET", url, headers=headers, data = payload) 
    print(response.text.encode('utf8'))`
},
  options: [
      {
          id: "python",
          type: "python",
          code: ` import requests 
          url = "URL_BASE/ws/currencies.py/listado" 
          payload = {} 
          headers= {} 
          response = requests.request("GET", url, headers=headers, data = payload) 
          print(response.text.encode('utf8'))`
      },
      {
          id: "nodejs",
          type: "javascript",
          code: `var request = require('request');
var options = {
'method': 'GET',
'url': 'URL_BASE/ws/currencies.py/listado',
'headers': {}
};
request(options, function (error, response) { 
if (error) throw new Error(error);
console.log(response.body);
});`
      },
      {
          id: "javascript",
          type: "javascript",
          code: `var requestOptions = {
method: 'GET',
redirect: 'follow'
};
fetch("URL_BASE/ws/currencies.py/listado", requestOptions)
.then(response => response.text())
.then(result => console.log(result))
.catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: `curl --location --request GET 'URL_BASE/ws/currencies.py/listado'`
    }
  ]
}

  
  return(
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          
          <h1 >Currency List</h1>
          <p>
            This service is used to obtain the currencies of the countries active in
            the system.
          </p>
          <p>
            <span className="label get">GET:</span>
            <span> </span> <code>URL_BASE + /ws/currencies.py/listado</code>
          </p>
          <h2 >Receives:</h2>
          <p>
            All parameters are sent in the querystring of the call, so a percentage
            encoding for URI must be applied &#40;aka URL encoding&#41;.
          </p>
          <table className="table">
            <thead className='text-bold table-dark text-center'>
              <tr>
                <th align="left" className='col-1'>Parameter</th>
                <th align="center" className='col-1'>Format</th>
                <th align="center" className='col-1'>Obligatory</th>
                <th align="center" className='col-3'>Description</th>
                <th align="center" className='col-2'>Default value</th>
                <th align="center" className='col-1'>Example</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="left">language</td>
                <td align="center">String</td>
                <td align="center">No</td>
                <td align="center">
                  Translates the currencies that the service returns to the indicated
                  language
                </td>
                <td align="center">N/A</td>
                <td align="center">ES &#40;Spain&#41;</td>
              </tr>
              <tr>
                <td align="left">selection</td>
                <td align="center">Boolean</td>
                <td align="center">No</td>
                <td align="center">
                  If this parameter is notified, it will return the currencies of the
                  active countries, and Bitcoin
                </td>
                <td align="center">False</td>
                <td align="center">True</td>
              </tr>
            </tbody>
          </table>

          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>
          <h3 >Answer JSON OK:</h3>

          <div className='mb-3'>
          <DisplayCode1 codeToshow={codes.answe_ok1} />
          </div>


          <h2 >Where:</h2>
          <ul>
            <li>
              <code>status:</code> Shows if the call has been successful
              &#40;true&#41; or not &#40;false&#41;.
            </li>
            <li>
              <code>currencies:</code> List of currencies returned by the system.
            </li>
            <li>
              <code>currency_data:</code> Contains the different details of the
              currency used for the operation..
            </li>
            <li>
              <code>abbreviation:</code> The abbreviated name of the currency&#40;EUR,
              MXN&#41;.
            </li>
            <li>
              <code>suffix:</code> Indicates the suffix that is applied in the
              currency format&#40;pesos, euros&#41;.
            </li>
            <li>
              <code>format:</code> The full format that applies to the currency, it
              includes the suffix and the prefix.
            </li>
            <li>
              <code>symbol:</code> The symbol associated to the currency&#40;€, ¢,
              $&#41;.
            </li>
            <li>
              <code>prefix:</code> The prefix that is applied in the currency format.
            </li>
            <li>
              <code>decimals:</code> The maximum number of decimal places that will be
              included in the currency format.
            </li>
            <li>
              <code>id:</code> Identifier of the currency in BBDD.
            </li>
            <li>
              <code>abbreviation:</code> The abbreviated name of the currency&#40;EUR,
              MXN&#41;.
            </li>
            <li>
              <code>format:</code> The full format that applies to the currency, it
              includes the suffix and the prefix.
            </li>
            <li>
              <code>name:</code> Full name of the currency.
            </li>
            <li>
              <code>precision:</code> Number of decimal places allowed by the
              currency.
            </li>
            <li>
              <code>currency_symbol:</code> The symbol associated to the currency
              &#40;€, ¢, $&#41;.
            </li>
            <li>
              <code>placeholder_currency:</code> The text of the currency always
              showing a value of 0.
            </li>
            <li>
              <code>change_purchase:</code> Currency price for purchase.
            </li>
            <li>
              <code>change_reference:</code> The value of the currency.
            </li>
            <li>
              <code>change_sale:</code> Currency price for sale.
            </li>
            <li>
              <code>elapsed:</code> Operation execution time.
            </li>
          </ul>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
          <DisplayCodeKo/>
          </div>

          <h2 >Business logic</h2>
          <p>
            This service, through the &#39;telefone&#39;, searches for the user whose
            movements are to be consulted, and returns a list with each of the
            user&#39;s movements between two given dates.
          </p>
        </div>

        <div className='col-md-5'>
         
          
          <h2 >Example requests:</h2>


          <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>


         
        </div>
      </div>




    </Fragment>
)};
export default Currency_List;