// OrganizationForm.js
import React, { useState } from 'react';
import WorldMap from '../worldMap';

const OrganizationForm = () => {
  const [organizationName, setOrganizationName] = useState('');
  const [homeRegion, setHomeRegion] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleNameChange = (e) => setOrganizationName(e.target.value);
  const handleRegionChange = (e) => setHomeRegion(e.target.value);
  const handleCheckboxChange = (e) => setIsConfirmed(e.target.checked);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isConfirmed) {
      alert("Please confirm that you understand the home region policy.");
      return;
    }
    // Aquí se manejará el envío del formulario
    console.log("Form Submitted", { organizationName, homeRegion });
  };

  return (
    <form className='cloudForm' onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="organizationName">Organization Name</label>
        <input
          type="text"
          id="organizationName"
          value={organizationName}
          onChange={handleNameChange}
          className="form-control"
          required
        />
      </div>

      <div className="form-group mt-3">
        <label htmlFor="homeRegion">Home Region</label>
        <select
          id="homeRegion"
          value={homeRegion}
          onChange={handleRegionChange}
          className="form-control"
          required
        >
          <option value="">Select a region</option>
          <option value="aws-ap-southeast-1">AWS AP Southeast 1 (Singapore)</option>
          <option value="aws-us-east-1">AWS US East 1 (Virginia)</option>
          <option value="aws-eu-west-1">AWS EU West 1 (Ireland)</option>
          {/* Agrega más opciones aquí según sea necesario */}
        </select>
      </div>

      {/* Mapa del mundo con el punto resaltado */}
      <div className="mt-3">
        <WorldMap selectedRegion={homeRegion} />
      </div>

      <div className="form-group mt-3">
        <input
          type="checkbox"
          id="confirmation"
          checked={isConfirmed}
          onChange={handleCheckboxChange}
          required
        />
        <label htmlFor="confirmation" className="ml-2">
          I understand that my Home Region will store my data and that it cannot be changed.
        </label>
      </div>

      <div className="form-group mt-3">
        <p className="text-muted">
          You will automatically be assigned as Organization Admin of this new organization.
        </p>
      </div>

      <button type="submit" className="btn btn-primary mt-3">
        Create Organization
      </button>
    </form>
  );
};

export default OrganizationForm;
