import React from 'react';
import './chapter.css'
const Chapter9_10 = () => (
    <div>
        <h2>6. Expressions in AVAP</h2>
        <p>
            This chapter explains the meaning of expression elements in AVAP.
        </p>
    </div>
);

export default Chapter9_10;
