
import React from 'react';
import './chapter.css'
const Chapter9_50 = () => (
    <div>
        <h2>Mapping Patterns</h2>
        <p>
            In AVAP, <strong>mapping patterns</strong> are used to match mapping elements, such as dictionaries. Here is the syntax and behavior of mapping patterns:
        </p>
        <pre><code>mapping_pattern ::=  &#123; [items_pattern] &#125;</code></pre>
        <p>
            <strong>Mapping Patterns</strong> are designed to match elements within mappings, such as dictionaries. They use specific rules to determine if a pattern matches the given mapping.
        </p>
        <ul>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Syntax:</strong> Mapping patterns are enclosed in curly braces <code>&#123; ... &#125;</code>. The <code>items_pattern</code> specifies the pattern for the mapping items.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Matching Rules:</strong> The rules for matching mapping patterns include checking for key-value pairs in the mapping and ensuring they align with the specified pattern.</li>
            <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Usage:</strong> Mapping patterns are useful for destructuring dictionaries and other mapping types in a concise manner.</li>
        </ul>
        <p>
            Mapping patterns enhance pattern matching capabilities by allowing for specific and flexible matching of dictionary elements.
        </p>
    </div>
);

export default Chapter9_50;


