import React, { FunctionComponent, useState, useEffect } from 'react';
import { Row, Col, Form, Alert, Spinner, Button, DropdownButton, Dropdown, Table } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import GenericSelectableList from '../DashboardComponents/CustomSelectSearch'




type AddOrganizationProps = {
  handleClose: () => void;    // Función para cerrar el Drawer
  updateTable?: () => void;    // Función para actualizar la tabla
  show: boolean;
  selectedGA: string[];
  userData: any;
  roles: any[];
  setlistaRecuperados: any;
  listaRecuperados: any[];
  opened: any
};

const EditDevelopeModal: FunctionComponent<AddOrganizationProps> = ({ handleClose, updateTable, selectedGA, userData, roles, setlistaRecuperados, listaRecuperados, opened }) => {
  const [cardNumber, setCardNumber] = useState('');
  const [cardholder, setCardholder] = useState('');
  const [location, setLocation] = useState('');
  const [responsible, setResponsible] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [errorNombre, setErrorNombre] = useState(false);
  const [errorTarget, setErrorTarget] = useState(false);
  const [errorLocation, setErrorLocation] = useState(false);
  const [errorResponsible, setErrorResponsible] = useState(false);
  const [localSelectedGA, setSelectedGA] = useState<string[]>(selectedGA);

  // Funciones para manejar la entrada y validación
  const changeCardholder = (e) => {
    const newCardholder = e.target.value;
    setCardholder(newCardholder);
    validateName(newCardholder);
  };

  const changeCardNumber = (e) => {
    const newCardNumber = e.target.value;
    setCardNumber(newCardNumber);
    validateTarget(newCardNumber);
  };

  const changeLocation = (e) => {
    const newLocation = e.target.value;
    setLocation(newLocation);
    validateLocation(newLocation);
  };

  const changeResponsible = (e) => {
    const newResponsible = e.target.value;
    setResponsible(newResponsible);
    validateResponsible(newResponsible);
  };

  const validateName = (name) => {
    const nameFormat = /^[A-Za-záéíóúÁÉÍÓÚüÜñÑ\s'-]+$/;
    setErrorNombre(!name.match(nameFormat));
  };

  const validateTarget = (description) => {
    const targetFormat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setErrorTarget(!description.match(targetFormat));
  };

  const validateLocation = (loc) => {
    const locationFormat = /^[a-zA-Z\s]{1,50}$/;
    setErrorLocation(!loc.match(locationFormat));
  };

  const validateResponsible = (res) => {
    const responsibleFormat = /^[a-zA-Z\s]{1,50}$/;
    setErrorResponsible(!res.match(responsibleFormat));
  };

  useEffect(()=>{
    console.log('...................');
    console.log(opened)
    console.log('...................');
    clearForm();

  }, [opened]);

  useEffect(() => {
    console.log("DESABILITANDO")
    setSubmitDisabled(
      false
    );
  }, [localSelectedGA, cardholder, cardNumber, location, responsible, errorNombre, errorTarget, errorLocation, errorResponsible]);

  // Limpiar los campos al cerrar el Drawer
  const clearForm = () => {
    console.log('borrando');
    setCardNumber('');
    (document.getElementById('correo') as HTMLElement).innerText = '';
    setCardholder('');
    setLocation('');
    setResponsible('');
    setError('');
    setSuccess('');
    setSubmitDisabled(true);
    setErrorNombre(false);
    setErrorTarget(false);
    setErrorLocation(false);
    setErrorResponsible(false);
  };

  const mockRoles = [
    { id: '1', name: 'Owner' },
    { id: '2', name: 'Admin' },
    { id: '3', name: 'Developer' },
    { id: '4', name: 'Marketing' },
];

  // Llamar a `clearForm` al cerrar el Drawer
  const handleDrawerClose = () => {
    clearForm(); console.log("CERRADOOO")
    handleClose();
  };

  const handleAddGA = (ga: string) => {
    if (!localSelectedGA.includes(ga)) {
      setSelectedGA([...localSelectedGA, ga]);
      console.log("++3+")
      console.log(ga);
      console.log(localSelectedGA);
      if (cardholder && cardNumber && localSelectedGA.length > 0) setSubmitDisabled(false);
    }
  };

  const handleRemoveGA = (ga: string) => {
    setSelectedGA(localSelectedGA.filter((item) => item !== ga));
  };

  // Función de envío del formulario
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError('');

    console.log(localSelectedGA);
    console.log(cardNumber);
    console.log(cardholder);
    
    const rolesSelected = []

    localSelectedGA.forEach((sel)=>{
      roles.forEach(rr=>{
        if (rr.name === sel) rolesSelected.push(rr);
      });
    })
    


    const payload = {
      roles: rolesSelected,
      dev_id: userData.dev_id,
      name: cardholder || userData.name
    }

    console.log(payload);
    
    
    const result = await ObexRequestHandler.post(
      '/iam/update_user_roles',
      payload
    );
    

   //const result = { success: true, data:'', message:'' }
    const { success, data, message } = result;

    if (success) {
      setLoading(false);
      setSuccess(data);
      setTimeout(() => {
        setSuccess('');
        if (updateTable) updateTable(); // Llama a `updateTable` solo si está definido
        handleDrawerClose(); // Cierra el Drawer y limpia el formulario
      }, 1000);
    } else {
      setError(message);
      setLoading(false);
    }
  };

  return (
    <div className="add-organization">
      <Row>
        <Col md="12" className="mx-auto">
          <Form onSubmit={handleSubmit}>
            <Form.Row className="mb-3">
              <Form.Label column sm="12">User Name</Form.Label>
              <Form.Control
                isValid={!errorNombre && cardholder.length > 0}
                isInvalid={errorNombre}
                
                maxLength={18}
                required
                type="text"
                placeholder="Name"
                value={cardholder || userData.name}
                onChange={changeCardholder}
              />
            </Form.Row>
            <Form.Row className="mb-3">
              <Form.Label column sm="12">User Email</Form.Label>
              <Form.Control
                isValid={!errorTarget && cardNumber.length > 0}
                isInvalid={errorTarget}
                disabled={true}
                required
                type="text"
                maxLength={100}
                id="correo"
                placeholder="Email"
                value={cardNumber || userData.email}
                onChange={changeCardNumber}
              />
            </Form.Row>



            <Form.Group controlId="selectGA" className="mb-3">
              <Form.Label>Select Roles</Form.Label>
              <GenericSelectableList
                items={roles}
                searchKeys={['name']}
                onAdd={(role) => setSelectedGA((prev) => [...prev, role.name])}
                onRemove={(role) =>
                  setSelectedGA((prev) => prev.filter((r) => r !== role.name))
                }
                renderLabel={(role) => role.name}
                placeholder="Search roles..."
                multiOption={true} // Selección múltiple
                setSelectedItems={setlistaRecuperados}
                selectedItems={listaRecuperados}

              />
            </Form.Group>


            {/*}
            <Form.Row className="mb-3">
              <Form.Label column sm="12">Location</Form.Label>
              <Form.Control
                isValid={!errorLocation && location.length > 0}
                isInvalid={errorLocation}
                required
                type="text"
                maxLength={50}
                placeholder="Location"
                value={location}
                onChange={changeLocation}
              />
            </Form.Row>
            <Form.Row className="mb-3">
              <Form.Label column sm="12">Responsible</Form.Label>
              <Form.Control
                isValid={!errorResponsible && responsible.length > 0}
                isInvalid={errorResponsible}
                required
                type="text"
                maxLength={50}
                placeholder="Responsible"
                value={responsible}
                onChange={changeResponsible}
              />
            </Form.Row>
            */}
            <Form.Row className="my-3">
              <Col md="6" >
                <Button
                  disabled={submitDisabled}
                  type="submit"
                  variant="primary" className="w-100"
                >
                  Update {success && <FontAwesomeIcon icon={faCheck} />}
                  {loading && <Spinner animation="border" size="sm" style={{ marginLeft: '8px' }} />}
                </Button>
              </Col>
            </Form.Row>
          </Form>
          {error && <Alert variant="danger">{error}</Alert>}
        </Col>
      </Row>
    </div>
  );
};

export default EditDevelopeModal;
