import React, { Fragment, useState } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula, solarizedDarkAtom } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import "./colorCode.css"


const DisplayCode1 = (props) => {
    const [codeToshow, setCodeToshow] = useState(props.codeToshow)
    // let codeToshow = props.codeToshow;
    const customStyle = {
        // backgroundColor: '#F5F6F8', // Fondo similar al de Stripe
        borderRadius: '8px',
        padding: '1rem',
        boxShadow: '0 0 20px rgba(0, 0, 0, 0.05)', // Sombra suave similar a la de Stripe
        overflowX: 'auto', // Para permitir el desplazamiento horizontal si es necesario
    };


    return (
        <Fragment>
                <div className="backgroundHeader2 rounded-top p-2 px-3 d-flex justify-content-between">
                    <div>{codeToshow.title}</div>
                    <div>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-top"> {codeToshow.copied ? "copied" : "copy"}</Tooltip>}
                        >
                            <CopyToClipboard text={codeToshow.code} onCopy={() => setCodeToshow({
                                ...codeToshow,
                                copied: true
                            })}>
                                <div className='sizeClipBoard'>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" fill="#353A44"><path d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM96 424c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm96-192c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm128 368c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z" /></svg>
                                </div>
                            </CopyToClipboard>
                        </OverlayTrigger>

                    </div>
                </div>
                <SyntaxHighlighter language="json" style={solarizedDarkAtom} className="rounded-bottom m-0 backgroundGray " id="codeLight">
                    {codeToshow.code}
                </SyntaxHighlighter>

        </Fragment>
    )
};
export default DisplayCode1;
