import React, { useState } from 'react';

const ApiForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        apiName: '',
        type: 'http', // Valor predeterminado
        apiStyle: 'OpenAPI', // Valor predeterminado
        upstreamUrl: 'http://httpbin.org/',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Form Data Submitted: ", formData);
        // Aquí puedes realizar acciones con los datos, como enviarlos a un servidor
    };

    return (
        <form className='cloudForm' onSubmit={handleSubmit} style={{ maxWidth: '600px', margin: '0 auto' }}>
            <h2>API Configuration</h2>

            {/* Overview Section */}
            <div className="form-section">
                <h3>Overview</h3>
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                        className="form-control"
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="apiName">API Name</label>
                    <input
                        type="text"
                        id="apiName"
                        name="apiName"
                        value={formData.apiName}
                        onChange={handleInputChange}
                        className="form-control"
                        required
                    />
                </div>
            </div>

            {/* Type Section */}
            <div className="form-section">
                <h3>Type</h3>
                <div className="form-group d-grid">
                    {['http', 'tcp', 'graphql', 'udg', 'federation'].map((type) => (
                        <label key={type} className="radio-inline">
                            <input
                                type="radio"
                                name="type"
                                value={type}
                                checked={formData.type === type}
                                onChange={handleInputChange}
                            />
                            {type.charAt(0).toUpperCase() + type.slice(1)}
                        </label>
                    ))}
                </div>
            </div>

            {/* API Style Section */}
            <div className="form-section">
                <h3>API Style</h3>
                <div className="form-group  d-grid">
                    {['OpenAPI', 'Classic'].map((style) => (
                        <label key={style} className="radio-inline">
                            <input
                                type="radio"
                                name="apiStyle"
                                value={style}
                                checked={formData.apiStyle === style}
                                onChange={handleInputChange}
                            />
                            {style}
                        </label>
                    ))}
                </div>
            </div>

            {/* Details Section */}
            <div className="form-section">
                <h3>Details</h3>
                <div className="form-group">
                    <label htmlFor="upstreamUrl">Upstream URL</label>
                    <input
                        type="url"
                        id="upstreamUrl"
                        name="upstreamUrl"
                        value={formData.upstreamUrl}
                        onChange={handleInputChange}
                        className="form-control"
                        required
                    />
                </div>
            </div>

            <button type="submit" className="btn btn-primary">Submit</button>
        </form>
    );
};

export default ApiForm;
