import React, { useEffect } from 'react';
//componets
import FlNav from './landingComponents/Navbar';
import FlFooter from './landingComponents/Footer';


import fondo from '../../assets/img/landingPage/fondoApps.png';
import icon_free from '../../assets/img/landingPage/freeIcon.svg'
import icon_expo from '../../assets/img/landingPage/expoIcon.svg'
import icon_dyn from '../../assets/img/landingPage/dynamicIcon.svg'

import features_1 from '../../assets/img/landingPage/vision/Vision01.png'
import features_2 from '../../assets/img/landingPage/vision/Vision02.png'
import features_3 from '../../assets/img/landingPage/vision/Vision03.png'




import logo from '../../assets/img/logos/AVAP_Vision-negativo.svg'
import dividerbg from '../../assets/img/landingPage/hexagono-horizonte.png'




import gradientbottom from '../../assets/img/landingPage/gradientbottom.png'

import Favicon from '../../assets/img/Favicon_AVAP_Framework.png'

const VersionControlProduct = () => {
    
  useEffect(() => {
    // favicon 
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = Favicon; // Usa la ruta importada directamente
    document.head.appendChild(link);

    // clean favicon 
    return () => {
      document.head.removeChild(link);
    };
  }, []);

    useEffect(() => {

        document.body.classList.add('dark-bg');
        return () => {
            document.body.classList.remove('dark-bg');
        };

    }, []);

    return (
        <>
            <FlNav></FlNav>
            <div className="fl-cloud-product bg-black text-white">
                {/* Hero Section */}
                <section id="heroProduct" className="text-center pt-5" style={{ backgroundImage: `url(${fondo})` }}>
                    <img
                        src={logo}
                        alt="Hero Placeholder"
                        className="img-fluid logo"
                    />
                    <h1 className="fl-title m-0">Next-Gen API Observability & Analytics</h1>
                    <p className="fl-subtitle">
                        Optimize, Analyze, Get Real-Time Insights, and Scale Your API Performance
                        with AI Analytics

                    </p>
                    <a className="glowBtn-dark fakeHide" href="#"><span className="btnInner">  Start with AVAP Cloud</span></a>
                </section>

                {/* Powerful API Platform */}
                <section className="container text-center pb-5">
                    <div className="row">
                        <div className="col">
                            {/* <p className="fl-subtitle mb-3">
                                Titulo
                            </p> */}
                            {/* <img
                                src="https://placehold.co/1026x423"
                                alt="Platform Placeholder"
                                className="img-fluid mt-4 mb-5"
                            /> */}
                            <p className="fl-title mb-3">
                            Seamless observability with deep insights for scalability, security, and performance
                            </p>
                            <p className="fl-text">
                            AVAP Vision is a powerful analytics and visualization tool built for AVAP Framework. Designed to monitor, analyze, and optimize API performance, AVAP Vision provides actionable insights from real-time data, helping developers and businesses understand API behavior, detect patterns, and make informed decisions. 
                            </p>
                        </div>
                    </div>

                </section>

                {/* Features */}
                <section className="container pb-5">
                    <div className="row">
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_free} alt="" />
                                <p>Real-Time API Observability</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_dyn} alt="" />
                                <p>AI-Driven Analytics</p>
                            </div>
                        </div>
                        <div className="col-md-4 text-center">
                            <div className="fl-pill">
                                <img src={icon_expo} alt="" />
                                <p>Real-Time Insights</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Beyond a Proxy */}
                <section className="container py-5 gradient-d fl-br-20 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 text-center">
                            <img
                                src={features_1}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>
                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Interactive API Dashboards</h3>
                            <p className="fl-text">
                                Deep insights into your API ecosystem with real-time, fully customizable dashboards. AVAP Vision allows you to track API performance, request/response times, error rates, and traffic trends—all in one intuitive interface.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Comprehensive API Management */}
                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">
                        <div className="col-md-6 pl-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Intelligent Anomaly Detection</h3>
                            <p className="fl-text">
                                Real-time, fully customizable dashboards that offer deep visibility into API activity, performance, and security. Developers and operations teams can track key metrics such as request/response times, error rates, and traffic patterns.
                            </p>
                        </div>

                        <div className="col-md-6 text-center">
                            <img
                                src={features_2}
                                alt="Management Placeholder"
                                className="img-fluid"
                            />
                        </div>
                    </div>
                </section>

                <section className="container py-5 gradient-d fl-br-20 mt-5 mb-5">
                    <div className="row align-items-center">

                        <div className="col-md-6 text-center">
                            <img
                                src={features_3}
                                alt="Proxy Placeholder"
                                className="img-fluid"
                            />
                        </div>


                        <div className="col-md-6 pr-5">
                            <span className="fl-label">Key Features</span>
                            <h3 className="fl-title">Seamless AVAP Framework Integration</h3>
                            <p className="fl-text">
                                Built for AVAP, AVAP Vision offers native compatibility with AVAP Virtual APIs, eliminating the need for complex configurations and external monitoring tools. It streamlines data ingestion, optimizes query execution, and provides end-to-end observability, ensuring developers and DevOps teams have a frictionless experience managing their APIs.
                            </p>
                        </div>

                    </div>
                </section>

                <section className="container fl-z-index-2 position-relative mb-8">
                    <div className="row">
                        <div className="col mb-5">
                            <div className="fl-title text-center mb-3">
                                Key Benefits
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    API Observability with Real-Time Insights
                                </p>
                                <p className="fl-text">
                                    Gain a complete view of your API ecosystem with real-time dashboards, interactive visualizations, and deep analytics.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Strengthens Security with Proactive Threat Detection
                                </p>
                                <p className="fl-text">
                                    Leverage AI-powered anomaly detection to identify and mitigate potential security threats, unusual traffic patterns, and API vulnerabilities.
                                </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="fl-card grayGradient">
                                {/* <img src="https://placehold.co/50x50" alt="" /> */}
                                <p className="fl-subtitle-xs">
                                    Optimizes API Performance for Seamless Scalability
                                </p>
                                <p className="fl-text">
                                    Maintain peak API efficiency with intelligent load balancing, predictive failure analysis, and adaptive scaling.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>


                {/* Getting Started */}
                <div className="bottomWrapper position-relative d-flex justify-content-center">
                    <img src={gradientbottom} alt="" className="gradient gradientBottom" />
                </div>
            </div>
            <FlFooter></FlFooter>
        </>
    );
};

export default VersionControlProduct;
