import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { Navbar, Container, Nav, Image, Dropdown, NavDropdown } from 'react-bootstrap';
import ObexRequestHandler from '../../handlers/ObexRequestsHandler';
import SessionService from '../../services/SessionsService';
import { Link, Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import './NewLandingFramework.css'
import FooterMenu from '../../components/functionalComponents/DashboardLayout/FooterMenuAccess';
import Logo from '../../assets/img/../../assets/img/AVAP_ID_horizontal.png';
import icon_name from '../../assets/img/newDashboard/icon_name.png';
import icono_Infinito from '../../assets/img/newDashboard/icono-Infinito.png';
import logo_communities from '../../assets/img/newDashboard/logo_communities.png';
import logo_alexandria from '../../assets/img/newDashboard/logo-alexandria.png';
import logo_Connect from '../../assets/img/newDashboard/logo-Connect.png';
import Logo101Obex from '../../assets/img/newDashboard/icono_Framework.png';
import logo_framework from '../../assets/img/avap_logo.png'

import logo_framework_producto from '../../assets/img/AVAP_Framework_productoServicio.png'

import lenguaje from '../../assets/img/lenguaje.png'
import virtualidad from '../../assets/img/virtualidad.png'
import inteligencia from '../../assets/img/inteligencia.png'


import ai_1 from '../../assets/img/icons/AIakaBrunix_01.svg'
import ai_2 from '../../assets/img/icons/AIakaBrunix_02.svg'
import ai_3 from '../../assets/img/icons/AIakaBrunix_03.svg'
import ai_4 from '../../assets/img/icons/AIakaBrunix_04.svg'
import ai_5 from '../../assets/img/icons/AIakaBrunix_05.svg'
import ai_6 from '../../assets/img/icons/AIakaBrunix_06.svg'

import vineta_CN from '../../assets/img/vineta_AI.png'
import gafico_cn_i from '../../assets/img/grafico_AI_i.png'
import gafico_cn_mobile from '../../assets/img/graph_ai_mobile.svg'


import logo_framework_titulo from '../../assets/img/logos/AVAP_AI.svg'
import cabecera from '../../assets/img/foto_AI.png'
import cabecera2 from '../../assets/img/2cabecera.png'
import cabeceraMobile from '../../assets/img/foto_AI_movil.png'
import FooterMenuAccess from '../../components/functionalComponents/DashboardLayout/FooterMenu';
import FooterMenuAccessMobile from '../../components/functionalComponents/DashboardLayout/FooterMenuMobile';
import MobileNavbar from '../../components/functionalComponents/baseLayout/mobileNavbar';
import BrowserNavbar from '../../components/functionalComponents/baseLayout/browserNavbar';



import botonAvapActivo from '../../assets/img/boton_avap_activo.png'
import botonCloudActivo from '../../assets/img/boton_cloud_activo.png'
import botonDsActivo from '../../assets/img/boton_ds_activo.png'
import botonCnpActivo from '../../assets/img/boton_cn_activo.png'
import botonGActivo from '../../assets/img/boton_g_activo.png'
import botonVspActivo from '../../assets/img/boton_vs_activo.png'

import botonAvap from '../../assets/img/boton_avap.png'
import botonCloud from '../../assets/img/boton_cloud.png'
import botonDs from '../../assets/img/boton_ds.png'
import botonCnp from '../../assets/img/boton_cn.png'
import botonG from '../../assets/img/boton_g.png'
import botonVs from '../../assets/img/boton_vs.png'

import AVAPLanguage from '../../assets/img/AVAPLenguaje.png';
import AVAPVirtualServer from '../../assets/img/AVAPVS.png';
import AVAPGateway from '../../assets/img/AVAPGateway.png';
import AVAPDS from '../../assets/img/AVAPDS.png';
import AVAPCloudLogo from '../../assets/img/AVAPCloud.png';
import AVAPCollaborative from '../../assets/img/AVAPCollaborativeN.png'

import Virtualizacion from '../../assets/img/foto_virtualizacion.png'

import productividad from '../../assets/img/productividad.png'
import rentabilidad from '../../assets/img/rentabilidad.png'
import focoproyectos from '../../assets/img/focoproyectos.png'
import tiemposlanzamiento from '../../assets/img/tiemposlanzamiento.png'

import collaborative2 from '../../assets/img/collaborative2.png'

import icon_collaborative from '../../assets/img/iconCollaborative.png'
import connect from '../../assets/img/connect.png'
import avapconect from '../../assets/img/AVAPConnect.png'
import ReactGA from 'react-ga4';
import config from '../../configs/appConfig.json'
import { BrowserView, MobileView } from 'react-device-detect';
import Disclaimer from '../../components/mobileComponents/DisclaimerCloud';
import TopMenuBar from './TopMenuBar';

type DashProps = {
    sider: any
};



const NewLandingAI = () => {
    let { path, url } = useRouteMatch();

    const history = useHistory();

    const [avapBut, setavapBut] = useState(true);
    const [vsBut, setvsBut] = useState(false);
    const [gBut, setgBut] = useState(false);
    const [dsBut, setdsBut] = useState(false);
    const [cloudBut, setcloudBut] = useState(false);
    const [CnBut, setcnBut] = useState(false);

    const [menu1, setmenu1] = useState(false);
    const [menu2, setmenu2] = useState(false);
    const [menu3, setmenu3] = useState(false);
    const [menu4, setmenu4] = useState(false);
    const [menu5, setmenu5] = useState(false);


    const cleanAll = () => {
        setavapBut(false);
        setvsBut(false);
        setgBut(false);
        setdsBut(false);
        setcloudBut(false);
        setcnBut(false);
    }

    const cleanMenuAll = () => {
        setmenu1(false);
        setmenu2(false);
        setmenu3(false);
        setmenu4(false);
        setmenu5(false);
    }


    useEffect(() => {
        window.scrollTo(0, 0);
        //ReactGA.initialize('G-MQZQJN7TQL');
        if (config.url != 'https://avs-primary-pre.101obex.mooo.com') {
            ReactGA.initialize('G-MQZQJN7TQL');
            ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Avap Framework' });
        }

    }, []);


    const handleSignInClick = () => {
        // Aquí puedes realizar cualquier acción antes de redireccionar, si es necesario

        // Redirecciona a otra vista (por ejemplo, '/dashboard')
        history.push('/login');
    };

    const navbarRef = useRef(null);

    const handleClick = () => {
      // Llama a la función cleanMenuAll del componente BrowserNavbar
      if (navbarRef.current) {
        navbarRef.current.cleanMenuAll(); // O también navbarRef.current.cleanAll();
      }
    };


    return (
        <>


            <BrowserView>
              <BrowserNavbar  ref={navbarRef}/>

                <div id='mobileAi' className="wrapper pt-5"  onClick={() => { handleClick() }}>

                    <header className='w-100 headbanner' style={{ backgroundImage: `url(${cabecera})`, }} >
                    </header>
                    <section id='intro' className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <Image src={logo_framework_titulo} className='reslogo' alt="Logo" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                    AVAP AI aka Brunix, the artificial intelligence where
                                    <span className='txt-bold'>
                                        &nbsp;you are the copilot
                                    </span>
                                </h1>
                </div>
                </div>
                        <div className="row mt-46">
                            <div className="col-md-12">
                                <p className='bodyText text-center mb-5'>
                                    Artificial intelligence integrated into the core of all our
                                    AVAP products transcends mere Copilot functionality; it maximizes
                                    efficiency and enhances productivity in programming and API development.
                                </p>
                                {/*}  <button onClick={() => { history.push('/Register') }} className='bluebutton  d-table m-auto mb-6 '> Try it</button> */}
                            </div>
                        </div>

                    </section>

              

                    <section className='w-100 bg-division'>
                        <div className="container">

                            <div className="row mt-6 pt-6">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={ai_1} alt="icono" />
                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 '>AI at the core</p>
                                    <p className='txt-20'>
                                        AI is ingrained in our DNA, seamlessly integrating into all functions and capabilities.
                                        Within the AVAP code are commands empowering AI to execute proposed solutions via natural language directives.
                                    </p>
                                    </div>
                                    </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={ai_2} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 '>AI throughout the process</p>
                                    <p className='txt-20'>
                                        BRUNIX plays a crucial role in server assistance, development, and routing.
                                        It analyzes data collected from calls and uses artificial intelligence to make routing decisions.
                                    </p>
                                    </div>
                                    </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={ai_3} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 '>Real guidance</p>
                                    <p className='txt-20'>
                                        Artificial intelligence generates AVAP code proposals or Low Code implementations
                                         in response to natural language, leveraging its comprehensive understanding of the language.
                                    </p>
                                        </div>
                                    </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={ai_4} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 '>Automatic Optimization</p>
                                    <p className='txt-20'>
                                        AVAP AI aka BRUNIX, analyzes deployments and proposes modifications based on operational data.
                                    </p>
                                </div>
                                    </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={ai_5} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28'>
                                        Artificial intelligence continuously analyzes code,
                                        anticipating and suggesting solutions proactively without explicit requests.
                                    </p>
                                    </div>
                                    </div>

                            <div className="row mt-3 pb-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={ai_6} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 '>Automatic code debugging</p>
                                    <p className='txt-20'>
                                        Analyzes, identifies, and proposes improvements to code after it has been programmed.
                                    </p>
                        </div>
                        </div>
                        </div>
                    </section>



                    <section id="beneficts" className='container mt-5 pb-5'>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='hero-title text-center'>
                                    Top AVAP AI aka <br className='dynamicHide-inverse' /> Brunix <span className='dynBold'>Benefits</span>
                                </h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mt-3">
                                <div className='landingFont3b m-auto' style={{ marginTop: '20px', width: '90%', }}>
                                                <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    AI is seamlessly integrated into the product’s DNA, enhancing operational efficiency,
                                    anticipating needs and issues, and delivering rapid responses.


                                        </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                                <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                                Artificial intelligence optimizes the internal functioning of products,
                                                adapting to current requirements and conditions in real time with intelligent decision-making.


                                        </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                                <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                                Enhances operation and optimization, boosting efficiency in the development and management of APIs.

                                        </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Increased productivity.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                                <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>

                                                Reduces the learning curve, enabling developers to quickly start creating APIs.


                                        </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                                <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                                Boosts productivity by automating tasks and optimizing processes.
                                        </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                                <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                                Continuous improvement of functionalities and development performance.
                                        </div>
                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                                <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>

                                                Adaptability due to its ability to dynamically adjust to changes.

                                        </div>
                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                                <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                                Reduces operational costs by automating the development and maintenance processes of APIs.
                                </div>

                            </div>

                            <div className="col-md-6">
                                <p className='txt-20 txt-bold my-5 text-center'>AI Integration</p>
                                <Image src={gafico_cn_i} style={{ width: '100%', padding: "10px" }} width="" className='dynamicHide-inverse' />
                                <Image src={gafico_cn_mobile} style={{ width: '100%', padding: "10px" }} width="" className='dynamicHide' />
                            </div>
                        </div>
                    </section>




                </div>


                <div>
                    <FooterMenuAccess green={true} />
                </div>




            </BrowserView>



            <MobileView>

                <MobileNavbar />

                {/* <Disclaimer /> */}

                <div id='mobileAi' className="wrapper" >

                    <header className='w-100 headbanner mt-5' style={{ backgroundImage: `url(${cabeceraMobile})`, }} >
                    </header>
                    <section id='intro' className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <Image src={logo_framework_titulo} className='reslogo' alt="Logo" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='text-center hero-title'>
                                    AVAP AI aka Brunix, the artificial intelligence where
                                    <span className='txt-bold'>
                                        &nbsp;you are the copilot
                                    </span>
                                </h1>
                            </div>
                        </div>
                        <div className="row mt-46">
                            <div className="col-md-12">
                                <p className='bodyText text-center mb-5'>
                                    Artificial intelligence integrated into the core of all our
                                    AVAP products transcends mere Copilot functionality; it maximizes
                                    efficiency and enhances productivity in programming and API development.
                                </p>
                                {/*}  <button onClick={() => { history.push('/Register') }} className='bluebutton  d-table m-auto mb-6 '> Try it</button> */}
                            </div>
                        </div>

                    </section>

                    {/* <section className='w-100 bg-division'>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='text-center hero-title mt-6'>What is AVAP ID?</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='bodyText text-center mb-5 mt-2'>
                                        Your AVAP ID is your personal account that grants access to the full suite of AVAP Framework products and services, such as AVAP Cloud, AVAP Interpreter & Language Server, AVAP Gateway Advanced, and AVAP Collaborative Network.
                                        It securely stores your contact information, sign-in credentials, payment methods, and security details.</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='text-center hero-title mt-4'>When do I use my AVAP ID?</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p className='bodyText text-center mb-5 mt-2'>
                                        You’ll use your AVAP ID whenever you set up a new device, make a purchase, or access any AVAP service.
                                        Signing in with the same AVAP ID ensures that all your AVAP products and services work together seamlessly,
                                        providing you with access to your content and projects across all your devices.</p>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    <section className='w-100 bg-division'>
                        <div className="container">
                            {/* <div className="row">
                                <div className="col-md-12">
                                    <p className='text-center mt-7 mb-4 txt-60 txt-light'>Top <span className=' textBold'>Benefits</span></p>
                                </div>
                                <div className="col-md-12">
                                    <p className='text-center txt-20 text-center'>Here are the top three benefits of your AVAP ID</p>
                                </div>
                            </div> */}
                            <div className="row mt-6">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={ai_1} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>AI at the core</p>
                                    <p className='txt-20 text-center'>
                                        AI is ingrained in our DNA, seamlessly integrating into all functions and capabilities.
                                        Within the AVAP code are commands empowering AI to execute proposed solutions via natural language directives.
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={ai_2} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>AI throughout the process</p>
                                    <p className='txt-20 text-center'>
                                        BRUNIX plays a crucial role in server assistance, development, and routing.
                                        It analyzes data collected from calls and uses artificial intelligence to make routing decisions.
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={ai_3} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Real guidance</p>
                                    <p className='txt-20 text-center'>
                                        Artificial intelligence generates AVAP code proposals or Low Code implementations
                                        in response to natural language, leveraging its comprehensive understanding of the language.
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={ai_4} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Automatic Optimization</p>
                                    <p className='txt-20 text-center'>
                                        AVAP AI aka BRUNIX, analyzes deployments and proposes modifications based on operational data.
                                    </p>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={ai_5} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Copilot BRUNIX</p>
                                    <p className='txt-20 text-center'>
                                        Artificial intelligence continuously analyzes code,
                                        anticipating and suggesting solutions proactively without explicit requests.
                                    </p>
                                </div>
                            </div>

                            <div className="row mt-3 pb-3">
                                <div className="col-md-3">
                                    <Image className='dataIcon' src={ai_6} alt="icono" />
                                </div>
                                <div className="col-md-9">
                                    <p className='txt-28 textBold mb-0 text-center'>Automatic code debugging</p>
                                    <p className='txt-20 text-center'>
                                        Analyzes, identifies, and proposes improvements to code after it has been programmed.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <section id='lastSection' className='container'>
                        <div className="row">
                            <div className="col-md-12">
                                <Image src={iconId} className='icon-65  d-block icon-fix' alt="Logo ID" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <p className='txt-20 text-center mb-7'>
                                    With AVAP ID, you gain a secure, efficient, and unified way to
                                    <br /> manage your AVAP Framework products and services, making your
                                    <br /> API development journey faster and easier.
                                </p>
                            </div>
                        </div>

                        <div className="row">
                                <div className="col-md-12">
                                    <button onClick={() => { history.push('/Register') }}
                                        className='greenbutton d-block mb-fix-136'
                                        style={{ marginTop: '20px' }}
                                    >
                                        Create my AVAP ID
                                    </button>

                                </div>
                            </div>
                    </section> */}

                    <section id="beneficts" className='container mt-5 pb-5'>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className='hero-title text-center'>
                                    Top AVAP AI aka Brunix Benefits
                                </h1>
                            </div>
                            {/* <div className="col-md-12">
                                <p className='title'>Virtual API execution process</p>
                            </div> */}
                        </div>
                        <div className="row">
                            <div className="col-md-6 mt-3">
                                <div className='landingFont3b m-auto' style={{ marginTop: '20px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    AI is seamlessly integrated into the product’s DNA, enhancing operational efficiency,
                                    anticipating needs and issues, and delivering rapid responses.


                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Artificial intelligence optimizes the internal functioning of products,
                                    adapting to current requirements and conditions in real time with intelligent decision-making.


                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Enhances operation and optimization, boosting efficiency in the development and management of APIs.

                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Increased productivity.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>

                                    Reduces the learning curve, enabling developers to quickly start creating APIs.


                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Boosts productivity by automating tasks and optimizing processes.
                                </div>

                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Continuous improvement of functionalities and development performance.
                                </div>
                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>

                                    Adaptability due to its ability to dynamically adjust to changes.

                                </div>
                                <div className='landingFont3b m-auto' style={{ marginTop: '10px', width: '90%', }}>
                                    <Image style={{ marginRight: '10px', width: '15px' }} src={vineta_CN}></Image>
                                    Reduces operational costs by automating the development and maintenance processes of APIs.
                                </div>

                            </div>

                            <div className="col-md-6">
                                <p className='txt-20 txt-bold my-5 text-center'>AI Integration</p>
                                <Image src={gafico_cn_mobile} style={{ width: '100%', padding: "10px" }} width="" />
                            </div>
                        </div>
                    </section>




                </div>


                <div>
                    <FooterMenuAccessMobile green={true} />
                </div>




            </MobileView>

        </>

    )
}

export default NewLandingAI;