import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h2>Data Structures</h2>
  <p>
    In addition to individual data types, AVAP™ provides various data structures that allow for more complex organization and manipulation of data:
  </p>
  <ul>
    <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Lists:</strong> Created using square brackets [ ] and can contain any data type, including other lists.</li>
    <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Tuples:</strong> Created using parentheses ( ) and are immutable, meaning they cannot be modified once created.</li>
    <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Dictionaries:</strong> Created using curly braces { } and store key-value pairs, where each key is unique within the dictionary.</li>
    <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Sets:</strong> Created using curly braces { } and contain unique elements, meaning there are no duplicates in a set.</li>
  </ul>
</div>
  </Fragment>
);
export default Intro_1;
