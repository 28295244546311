import React from 'react';
import './chapter.css'
const Chapter13_7 = () => (
  <div>
    <h2>Practical Example</h2>
    <p>
      Suppose we have a file named <code>utils.avap</code> that contains utility functions we want to use in our main project. We can include this file in our main project as follows:
    </p>
    <pre>
      <code>
        include "utils.avap" // We can now use the functions defined in utils.avap
      </code>
    </pre>
    <p>
      With this understanding of the value and advantages of using includes in AVAP™, we will explore in detail their operation and practical application in project development.
    </p>
  </div>
);

export default Chapter13_7;
