import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_8 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>encodeSHA256()</h2>
      <p>
        The <code>encodeSHA256()</code> command generates a SHA-256 hash of the provided string and stores the result in a target variable. SHA-256 is a cryptographic hash function that produces a 256-bit value (64 hexadecimal characters), offering greater security compared to MD5.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>SourceVariable</strong> <br />
          Type: <code>var</code> <br />
          Description: The variable containing the text string to be encoded in SHA-256. It can be a direct value or a variable storing the input string.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>TargetVariable</strong> <br />
          Type: <code>var</code> <br />
          Description: The variable in which the resulting SHA-256 hash will be stored. Unlike the <code>SourceVariable</code> parameter, this must be a variable and not a direct value.
        </li>
      </ul>
      <h3>Usage Example</h3>
      <pre>
        <code>
          {`// Direct call with values:
encodeSHA256('example_string', sha256Hash)

// Call using variables:
text = 'example_string'
hashVariable = 'sha256Hash'
encodeSHA256(text, hashVariable)`}
        </code>
      </pre>
      <p>
        In the first example, a SHA-256 hash is generated from the string <code>'example_string'</code> and stored in the <code>sha256Hash</code> variable. In the second example, a variable <code>text</code> is used to define the input string, and another variable <code>hashVariable</code> is used to store the resulting SHA-256 hash.
      </p>
    </div>
  </Fragment>
);

export default Appendices_8;