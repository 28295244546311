import React from 'react';
import './chapter.css'
const Chapter13_3 = () => (
  <div>
    <h2>Advantages of Using Includes</h2>
    <ul>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
        <strong>Code Reuse:</strong> Includes allow for the reuse of functions, variables, and other code definitions in multiple parts of a project, reducing code duplication and promoting consistency and coherence in development.
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
        <strong>Facilitates Maintainability:</strong> By dividing code into smaller, more specific modules, it is easier to identify, understand, and modify parts of the code without affecting other parts of the project. This eases software maintenance over time.
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
        <strong>Promotes Modularity:</strong> The ability to include files selectively as needed encourages code modularity, which simplifies understanding and managing complex projects by breaking them down into smaller, manageable components.
      </li>
      <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
        <strong>Improves Readability and Organization:</strong> The use of includes helps organize code in a logical and structured manner, improving readability and facilitating navigation through different parts of the project.
      </li>
    </ul>
  </div>
);

export default Chapter13_3;
