import React, { Fragment } from 'react';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const Chat_prebind_EN = () => {


  const codesMutiple = {
    selected: {
      id: "python",
      type: "python",
      code: ` import requests
    url = "URL_BASE/ws/chat.py/prebind"
    payload = {
    'codtran': '00d4c87a1dd9c87ccda8518bb007835a71233069'
    }
    files = []
    headers= {}
    response = requests.request("POST", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
    },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests
    url = "URL_BASE/ws/chat.py/prebind"
    payload = {
    'codtran': '00d4c87a1dd9c87ccda8518bb007835a71233069'
    }
    files = []
    headers= {}
    response = requests.request("POST", url, headers=headers, data = payload, files = files)
    print(response.text.encode('utf8'))`
      },
      {
        id: "nodejs",
        type: "javascript",
        code: ` var request = require('request');
  var options = {
  'method': 'POST',
  'url': 'URL_BASE/ws/chat.py/prebind',
  'headers': {},
  formData: {
  'codtran': '00d4c87a1dd9c87ccda8518bb007835a71233069'
  }
  };
  request(options, function (error, response) { 
  if (error) throw new Error(error);
  console.log(response.body);
  });`
      },
      {
        id: "javascript",
        type: "javascript",
        code: ` var formdata = new FormData();
  formdata.append("codtran", "00d4c87a1dd9c87ccda8518bb007835a71233069");
  var requestOptions = {
  method: 'POST',
  body: formdata,
  redirect: 'follow'
  };
  fetch("URL_BASE/ws/chat.py/prebind", requestOptions)
  .then(response => response.text())
  .then(result => console.log(result))
  .catch(error => console.log('error', error));`
      },
      {
        id: "curl",
        type: "bash",
        code: ` curl --location --request POST 'URL_BASE/ws/chat.py/prebind' 
  --form 'codtran=00d4c87a1dd9c87ccda8518bb007835a71233069'`
      }
    ]
  }

  return (
    <Fragment>
      <div className='container-fluid row'>
        <div className='col-md-7'>
          <h1 >Open New Chat Connection</h1>
          <p>
            This service is used to obtain the connection key for an open chat
            session.
          </p>
          <p>
            <span className="label post">POST:</span>
            <span> </span> <code>URL_BASE + /ws/chat.py/prebind</code>
          </p>
          <h2 >Receives:</h2>
          <p>
            All the parameters that the service receives must be indicated in the body
            of the request.
          </p>
          <table>
            <thead>
              <tr>
                <th align="left">Parameter</th>
                <th align="center">Format</th>
                <th align="center">Obligatory</th>
                <th align="center">Description</th>
                <th align="center">Default value</th>
                <th align="center">Example</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td align="left">codtran</td>
                <td align="center">String</td>
                <td align="center">Yes</td>
                <td align="center">Transaction code of the chat connection</td>
                <td align="center">N/A</td>
                <td align="center">00d4c87a1dd9c87ccda8518bb007835a71233069</td>
              </tr>
            </tbody>
          </table>
          <h2 >Returns:</h2>
          <p>
            Depending on the result of the operation, this service can return two
            different JSON:
          </p>
          <h3 >Answer JSON OK:</h3>

          <pre className="language-json">
            <code className="language-json">&lt;string&gt;</code>
          </pre>
          <h2 >Where:</h2>
          <p>The response of this service is the key to the open chat session.</p>
          <h3 >Answer JSON KO:</h3>

          <div className='mb-3'>
            <DisplayCodeKo />
          </div>

          <h2 >Business logic:</h2>
          <p>
            This service is used to obtain the connection key for an open chat
            session.
          </p>

        </div>
        <div className='col-md-5'>
          <h2 >Example requests:</h2>
          <div className='effectStickySections'>

            <div className='mb-3'>
              <DisplayCode2 codeToshow={codesMutiple} />
            </div>

          </div>

        </div>

      </div>



    </Fragment>
  );
}
export default Chat_prebind_EN;
