import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>Introduction</h2>
      <p>
        The data model in AVAP™ defines how data is organized and manipulated within the language. Similar to Python, AVAP™ uses a flexible and dynamic data model that allows for working with a wide variety of data types and data structures.
      </p>
    </div>
  </Fragment>
);
export default Intro_1;
