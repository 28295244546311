import React, { FunctionComponent, Component, useEffect, useState, Fragment } from 'react';
import { Row, Col,  } from 'react-bootstrap';
import { Pie } from 'react-chartjs-2';
import '../../../assets/css/dashboard.css';
import ObexRequestHandler from '../../../handlers/ObexRequestsHandler';
import { statSync } from 'fs';


const options = {
  title:{
    display:false,
  },
  legend:{
    display:false,
    position:'right'
  },
  responsive: true
};



type ChartProps = {
  handleUpdate: string
};

const ConsumptionCategoryChart:FunctionComponent<ChartProps> = (props) => {

  const estado = props.handleUpdate;
  const [labelsCategory, setLabelsCategory] = useState([""]);
  const [dataPercent, setDataPercent] = useState([0]);
  const [isEmpty, setIsEmpty] = useState(true);

 const cambiaRango = () =>{



 }

  const getData = async () => {
    try{

      const result = await ObexRequestHandler.get(`/movements_stats/`, {"percent":true});
      const { success, data, message } = result;
      const stastPercent = data.stats.filter(value => value.count >= 1)
      const labels = stastPercent.map(value => value.name);
      setLabelsCategory(labels);
      const dataSets = stastPercent.map(value => {
        if (value.count > 0){
          setIsEmpty(false);
        }
        return value.count
      })
      setDataPercent(dataSets);
      if (!success) throw { message };
    }catch (error) {
      console.error('ERROR MOVEMENTS STATS', error);
    }
  }

  useEffect(() => {
    getData();
  }, [])

  const data = {
    labels: labelsCategory,
    datasets: [{
      data: dataPercent,
      backgroundColor: [
      '#FF6384',
      '#36A2EB',
      '#FFCE56'
      ],
      hoverBackgroundColor: [
      '#FF6384',
      '#36A2EB',
      '#FFCE56'
      ]
    }]
  };

  return (
    <><input hidden={true} value={estado} onChange={cambiaRango}></input>
      <Fragment>
        {isEmpty ?
          <span className="my-auto">There is no data yet.</span>
          :
          <Pie data={data} options={options} />}
      </Fragment></>
  );
}

export default ConsumptionCategoryChart;