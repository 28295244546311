import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h3>1.2 Conversion Between Data Types</h3>
  <p>
    In AVAP™, just like in Python, it is possible to convert between different data types using specific functions. Some common examples include:
  </p>
</div>
  </Fragment>
);
export default Intro_1;
