import React, { Fragment } from 'react';
import './chapter.css'
const Infrastructure_AVS = () => (
  <Fragment>
         <div>
      <h3>1.1.1 Language Specifications Decoupled from the Interpreter</h3>
      <p>
        In AVAP™, language specifications are not compiled into the core of the language nor do they reside in the interpreter. 
        This means that the interpreter is not tied to a specific implementation of the language, providing great flexibility 
        and adaptability in code interpretation.
      </p>
    </div>
  </Fragment>
);
export default Infrastructure_AVS;
