import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';
import DisplayCodeKo from '../../../../components/DisplayCodeKo/DisplayCodeKo';

const Obtain_Country_Operator = () => 
{

  const codes ={
    answe_ok1: {
      title:"Answer ok",
      type: "json",
      code: `{
        "status":"<boolean>",
        "operators":[
           {
              "name":"<string>",
              "operator_code":"<integer>"
           }
        ],
        "elapsed":"<float>"
     }`,
      copied: false
    }
  };

  const codesMutiple={
    selected: {
      id: "python",
      type: "python",
      code: ` import requests url = "URL_BASE/ws/util.py/operators?country_code=MX" 
    payload = {} 
    headers= {} 
    response = requests.request("GET", url, headers=headers, data = payload) 
    print(response.text.encode('utf8'))`
  },
    options: [
      {
        id: "python",
        type: "python",
        code: ` import requests url = "URL_BASE/ws/util.py/operators?country_code=MX" 
    payload = {} 
    headers= {} 
    response = requests.request("GET", url, headers=headers, data = payload) 
    print(response.text.encode('utf8'))`
    },
        {
            id: "nodejs",
            type: "javascript",
            code: ` var request = require('request');
  var options = {
      'method': 'GET',
      'url': 'URL_BASE/ws/util.py/operators?country_code=MX',
      'headers': {}
  };
  request(options, function (error, response) { 
      if (error) throw new Error(error);
      console.log(response.body);
  });`
        },
        {
            id: "javascript",
            type: "javascript",
            code: ` var requestOptions = {
    method: 'GET',
    redirect: 'follow'
};
fetch("URL_BASE/ws/util.py/operators?country_code=MX", requestOptions)
    .then(response => response.text())
    .then(result => console.log(result))
    .catch(error => console.log('error', error));`
        },
        {
          id: "curl",
          type: "bash",
          code: ` curl --location --request GET 'URL_BASE/ws/util.py/operators?country_code=MX'`
      }
    ]
  }

  return(
    <Fragment>
            <div className='container-fluid row'>
        <div className='col-md-7'>

      <h1 >Obtain Country Operator</h1>
      <p>This service is used to obtain the operators of the chosen country.</p>
      <p>
        <span className="label get">GET:</span>
        <span> </span> <code>URL_BASE + /ws/util.py/operators</code>
      </p>
      <h2 >Receives:</h2>
      <p>
        All parameters are sent in the querystring of the call, so a percentage
        encoding for URI must be applied &#40;aka URL encoding&#41;.
      </p>
      <div className='overflow-auto'>
      <table>
        <thead>
          <tr>
            <th align="left">Parameter</th>
            <th align="center">Format</th>
            <th align="center">Obligatory</th>
            <th align="center">Description</th>
            <th align="center">Default value</th>
            <th align="center">Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td align="left">country_code</td>
            <td align="center">
              ISO 3166 alpha-2 &#40;
              <a href="https://www.iso.org/glossary-for-iso-3166.html">
                https://www.iso.org/glossary-for-iso-3166.html
              </a>
              &#41;
            </td>
            <td align="center">Yes</td>
            <td align="center">Country code in which the user resides</td>
            <td align="center">N/A</td>
            <td align="center">ES &#40;Spain&#41;</td>
          </tr>
        </tbody>
      </table>
      </div>

      
      <h2 >Returns:</h2>
      <p>
        Depending on the result of the operation, this service can return two
        different JSON:
      </p>
      <h3 >Answer JSON OK:</h3>
  
      <div className='mb-3'>
        <DisplayCode1 codeToshow={codes.answe_ok1}/>
      </div>
      <h2 >Where:</h2>
      <ul>
        <li>
          <code>status:</code> Shows if the call has been successful
          &#40;true&#41; or not &#40;false&#41;.
        </li>
        <li>
          <code>operators:</code> List with the telephone operators of the
          country.
        </li>
        <li>
          <code>name:</code> Name of the operator
        </li>
        <li>
          <code>operator_code:</code> Identifier of the operator.
        </li>
        <li>
          <code>elapsed:</code> Operation execution time.
        </li>
      </ul>
      <h3 >Answer JSON KO:</h3>
  
      <div className='mb-3'>
        <DisplayCodeKo/>
      </div>
      <h2 >Business logic:</h2>
      <p>
        By means of this service you get all the operators of the indicated
        country.
      </p>
      </div>
        <div className='col-md-5'>
      <h2 >Example requests:</h2>
      <div className='mb-3 effectStickySections'>
      <DisplayCode2 codeToshow={codesMutiple}/>
      </div>
      
        </div>

      </div>

    </Fragment>
  );
}
export default Obtain_Country_Operator;
