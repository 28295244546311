import React, { Fragment } from 'react';
import './chapter.css'
const Chapter8_5 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>Practical Example</h2>
      <p>
        Below is a practical example that illustrates the use of expressions in AVAP™ with lists:
      </p>
      <pre>
        <code>
          {`// Definition of a list of numbers
numbers = [1, 2, 3, 4, 5]

// Calculation of the sum of the elements
total = sum(numbers)  // Output: 15

// Checking if a number is present in the list
is_present = 6 in numbers  // Output: False`}
        </code>
      </pre>
    </div>
  </Fragment>
);

export default Chapter8_5;
