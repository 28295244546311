import React, { Fragment } from 'react';
import './chapter.css'
const Appendices_22 = () => (
  <Fragment>
    <div className='body-md'>
      <h2>ormAccessInsert()</h2>
      <p>
        The <code>ormAccessInsert()</code> command inserts a new record into a database table using the provided values for the fields. This command defines the fields and their corresponding values, and stores the result of the operation in a target variable.
      </p>
      <h3>Parameters</h3>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>fields</strong> <br />
          Type: <code>variable</code> <br />
          Description: A string containing the names of the fields into which the values will be inserted. The field names should be separated by commas.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>fieldsValuesVariables</strong> <br />
          Type: <code>variable</code> <br />
          Description: A string containing the names of the variables that hold the values to be inserted into the specified fields. The variable names should be separated by commas, in the same order as the fields in <code>fields</code>.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>dbase</strong> <br />
          Type: <code>variable</code> <br />
          Description: The name of the database where the table into which the new record should be inserted is located. It must be a variable containing the name of the database.
        </li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
          <strong>varTarget</strong> <br />
          Type: <code>variable</code> <br />
          Description: The variable in which the result of the insertion operation will be stored. It must be a variable that will receive a value indicating whether the insertion was successful or not.
        </li>
      </ul>
      <h3>Command Flow</h3>
      <ol>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Defining the Fields and Values:</strong> Use the field names (<code>fields</code>) and the variables with the values to be inserted (<code>fieldsValuesVariables</code>) to define what data should be inserted.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Inserting into the Database:</strong> Perform the insertion of the new record into the database specified by <code>dbase</code>, using the provided values.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>Storing the Result:</strong> Save the result of the insertion operation in the variable specified by <code>varTarget</code>. The stored value will indicate whether the insertion was successful (<code>True</code>) or failed (<code>False</code>).</li>
      </ol>
      <h3>Example Usage</h3>
      <p>Suppose you want to insert a new record into a table called <code>users</code> in a database called <code>myDatabase</code>, with values for <code>username</code> and <code>age</code> coming from the variables <code>newUsername</code> and <code>newAge</code>.</p>
      <pre>
        <code>
          {`// Define variables
fields = "username,age"
fieldsValuesVariables = "newUsername,newAge"
dbase = "myDatabase"
insertSuccess = ''

// Define the variables with the new values
newUsername = "Alice"
newAge = 31

// Call the command to insert the new record
ormAccessInsert(fields, fieldsValuesVariables, dbase, insertSuccess)

// Return the result of the insertion via addResult
addResult(insertSuccess)`}
        </code>
      </pre>
      <p>In this example, the <code>ormAccessInsert()</code> command will insert a new record into the <code>myDatabase</code> database in the <code>users</code> table. The values for <code>username</code> and <code>age</code> are provided by the <code>newUsername</code> and <code>newAge</code> variables. The <code>insertSuccess</code> variable will store the result of the operation (whether it was successful or not), and this variable will be returned via <code>addResult(insertSuccess)</code>. The output will reflect whether the insertion was successful (<code>True</code>) or failed (<code>False</code>).</p>
    </div>
  </Fragment>
);

export default Appendices_22;
