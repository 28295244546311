import React from 'react';
import './chapter.css'
const Chapter12_2 = () => (
  <div>
    <h2>Usage of <code>addParam</code></h2>
    <p>
      The <code>addParam()</code> function is used to add parameters to an API call in the query string. 
      The basic syntax of this function is as follows:
    </p>
    <pre>
      <code>
        addParam(variable, value)
      </code>
    </pre>
    <p>
      Where <code>variable</code> is the name of the variable to be used as a parameter in the API call, 
      and <code>value</code> is the value assigned to this variable.
    </p>
  </div>
);

export default Chapter12_2;
