import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
      <h2>Practical Example</h2>
      <p>Below is a practical example that illustrates lexical analysis in AVAP™:</p>
      <pre>
        {`
// Function definition
function_example(parameter):
    result = parameter * 2
    return result

// Function call
value = function_example(10)
        `}
      </pre>
      <p>In this example, the lexical analysis would identify the following tokens:</p>
      <ul>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>function_example</strong>: Function identifier.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>(, )</strong>: Punctuation symbols.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>parameter, result, value</strong>: Variable identifiers.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>=, *, 2</strong>: Operators.</li>
        <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}><strong>10</strong>: Integer literal.</li>
      </ul>
    </div>
  </Fragment>
);
export default Intro_1;
