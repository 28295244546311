import React, { Fragment } from 'react';
import DisplayCode1 from '../../../../components/DisplayCode1/displayCode1';
import DisplayCode2 from '../../../../components/DisplayCode2/DisplayCode2';

const Confirmar_Ingreso_EN = () => 
  {
    const codes ={
      answe_ok1: {
        title:"Answer ok",
        type: "json",
        code: `{
          "status": true,
          "elapsed": <float>
          }`,
        copied: false
      },
      answe_ko1: {
        title:"Answer ok",
        type: "json",
        code: `{
          "status": false,
          "level": <string>,
          "message": <string>,
          "error": <string>
          }`,
        copied: false
      }
      
    };
  
    const codesMutiple={
      selected: {
        id: "python",
        type: "python",
        code: `import requests
        url = "URL_BASE/ws/admin.py/confirmaringreso"
        payload = {'country_code': 'MX',
        'user_id': '4532',
        'session': '398-E4cA1txCfRb9ERIpZQJ7tg==',
        'phone': '7229063245',
        'amount': '12.50',
        'reference': 'Lorem ipsum dolor sit amet.',
        'destination': '3451',
        'wallet': '555444',
        'affiliate_id': '127'}
        files = []
        headers= {}
        response = requests.request("POST", url, headers=headers, data = payload, files = files)
        print(response.text.encode('utf8'))`
    },
      options: [
        {
          id: "python",
          type: "python",
          code: `import requests
          url = "URL_BASE/ws/admin.py/confirmaringreso"
          payload = {'country_code': 'MX',
          'user_id': '4532',
          'session': '398-E4cA1txCfRb9ERIpZQJ7tg==',
          'phone': '7229063245',
          'amount': '12.50',
          'reference': 'Lorem ipsum dolor sit amet.',
          'destination': '3451',
          'wallet': '555444',
          'affiliate_id': '127'}
          files = []
          headers= {}
          response = requests.request("POST", url, headers=headers, data = payload, files = files)
          print(response.text.encode('utf8'))`
      },
          {
              id: "nodejs",
              type: "javascript",
              code: `var request = require('request');
              var options = {
              'method': 'POST',
              'url': 'URL_BASE/ws/admin.py/confirmaringreso',
              'headers': {
              },
              formData: {
              'country_code': 'MX',
              'user_id': '4532',
              'session': '398-E4cA1txCfRb9ERIpZQJ7tg==',
              'phone': '7229063245',
              'amount': '12.50',
              'reference': 'Lorem ipsum dolor sit amet.',
              'destination': '3451',
              'wallet': '555444',
              'affiliate_id': '127'
              }
              };
              request(options, function (error, response) { 
              if (error) throw new Error(error);
              console.log(response.body);
              });`
          },
          {
              id: "javascript",
              type: "javascript",
              code: `var formdata = new FormData();
              formdata.append("country_code", "MX");
              formdata.append("user_id", "4532");
              formdata.append("session", "398-E4cA1txCfRb9ERIpZQJ7tg==");
              formdata.append("phone", "7229063245");
              formdata.append("amount", "12.50");
              formdata.append("reference", "Lorem ipsum dolor sit amet.");
              formdata.append("destination", "3451");
              formdata.append("wallet", "555444");
              formdata.append("affiliate_id", "127");
              var requestOptions = {
              method: 'POST',
              body: formdata,
              redirect: 'follow'
              };
              fetch("URL_BASE/ws/admin.py/confirmaringreso", requestOptions)
              .then(response => response.text())
              .then(result => console.log(result))
              .catch(error => console.log('error', error));`
          },
          {
            id: "curl",
            type: "bash",
            code: `curl --location --request POST 'URL_BASE/ws/admin.py/confirmaringreso' \
            --form 'country_code=MX' \
            --form 'user_id=4532' \
            --form 'session=398-E4cA1txCfRb9ERIpZQJ7tg==' \
            --form 'phone=7229063245' \
            --form 'amount=12.50' \
            --form 'reference=Lorem ipsum dolor sit amet.' \
            --form 'destination=3451' \
            --form 'wallet=555444' \
            --form 'affiliate_id=127'`
        }
      ]
    }
    return(
      <Fragment>
        <div className='container-fluid row'>
        <div className='col-md-7'>
        <h1 id="income-confirmation">Income Confirmation</h1>
        <p>
          This service is used to confirm a deposit to a user with sufficient
          available balance on the platform.
        </p>
        <p>
          <span className="label post">POST:</span>{' '}
          <code>URL_BASE + /ws/admin.py/confirmaringreso</code>
        </p>
        <h2 id="receives">Receives:</h2>
        <p>
          All the parameters that the service receives must be indicated in the body
          of the request.
        </p>
        <table>
          <thead>
            <tr>
              <th align="left">Parameter</th>
              <th align="center">Format</th>
              <th align="center">Obligatory</th>
              <th align="center">Description</th>
              <th align="center">Default value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td align="left">country_code</td>
              <td align="center">
                <a href="https://www.iso.org/glossary-for-iso-3166.html">
                  ISO 3166 alpha-2
                </a>
              </td>
              <td align="center">Yes</td>
              <td align="center">Country code in which the user resides</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">user_id</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">Identifier of the user making the request</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">session</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send signature to &#39;Access
                Service&#39;&#41;
              </td>
              <td align="center">
                Obtained by identifying the user with ID user_id on the platform
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">signature</td>
              <td align="center">String</td>
              <td align="center">
                Yes &#40;if you do not send session to &#39;Access Service&#39;&#41;
              </td>
              <td align="center">
                Enables you to sign the request without having to log in
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">amount</td>
              <td align="center">Decimal</td>
              <td align="center">Yes</td>
              <td align="center">
                Quantity to send to the user indicated with &#39;phone&#39;
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">reference</td>
              <td align="center">String</td>
              <td align="center">Yes</td>
              <td align="center">Concept associated with the request</td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">phone</td>
              <td align="center">Integer</td>
              <td align="center">Yes</td>
              <td align="center">
                Telephone number of the user to whom the money is to be sent.
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">wallet</td>
              <td align="center">Integer</td>
              <td align="center">No</td>
              <td align="center">
                Fund origin identifier of the user to whom the deposit is to be
                made.
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">destination</td>
              <td align="center">Integer</td>
              <td align="center">No</td>
              <td align="center">
                Identifier of the user to whom the charge will be made.
              </td>
              <td align="center">N/A</td>
            </tr>
            <tr>
              <td align="left">affiliate_id</td>
              <td align="center">Integer</td>
              <td align="center">No</td>
              <td align="center">
                Identifier of the user who acts as the service provider, towards
                which the commissions are directed, if not indicated, these are sent
                to the affiliate by default of the system.
              </td>
              <td align="center">N/A</td>
            </tr>
          </tbody>
        </table>
        <h2 id="returns">Returns:</h2>
        <p>
          Depending on the result of the operation, this service can return two
          different JSON:
        </p>
        <h3 id="answer-json-ok">Answer JSON OK:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ok1}/>
          </div>
        <h2 id="where">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>elapsed:</code> Operation execution time.
          </li>
        </ul>
        <h3 id="answer-json-ko">Answer JSON KO:</h3>
    
        <div className='mb-3'>
            <DisplayCode1 codeToshow={codes.answe_ko1}/>
          </div>
        <h2 id="where-1">Where:</h2>
        <ul>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>status:</code> Shows if the call has been successful
            &#40;true&#41; or not &#40;false&#41;.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>level:</code> Error importance level.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>message:</code> Error message.
          </li>
          <li className="li-md" style={{fontSize:'1.2em', paddingLeft:'10px', marginLeft:'25px'}}>
            <code>error:</code> Sole error code.
          </li>
        </ul>
        <h2 id="business-logic">Business logic:</h2>
        <p>
          This service, through the &#39;telephone&#39;, searches for the user to
          whom the entry of the indicated &#39;amount&#39; will be confirmed. To be
          able to use this endpoint it is necessary that it be consumed with an
          administrator user session.
        </p>
        <p>
          <img
            src="../img/servicios_ilustrados_confirmar_ingreso.png"
            alt="Confirmar_ingreso"
          />
        </p>
        </div>

      <div className='col-md-5'>
        <h2 id="example-requests">Example requests:</h2>
        <div className='mb-3 effectStickySections'>
          <DisplayCode2 codeToshow={codesMutiple}/>
          </div>
       
        </div>
      </div>
      </Fragment>
    );
  }
export default Confirmar_Ingreso_EN;
