import React, { Fragment } from 'react';

const Intro_1 = () => (
  <Fragment>
<div>
  <h3>2.2.2 Global Variables</h3>
  <p>
    Global variables are those that are declared outside of any function or block of code and are available throughout the entire program. They have a global scope, and their lifespan lasts for the full duration of the program's execution. Global variables are used to store data that needs to be accessible from multiple parts of the program or that needs to retain its value over time.
  </p>
</div>
  </Fragment>
);
export default Intro_1;
