import React from 'react';
import './chapter.css'
const Chapter9_13 = () => (
    <div>
        <h2>6.2.1. Identifiers (Names)</h2>
        <p>
            An identifier that appears as an atom is a name. When the name is bound to an object, evaluating the atom yields that object. When a name is not bound, an attempt to evaluate it raises a <code>NameError</code> exception.
        </p>
        <h3>Private Name Mangling</h3>
        <p>
            When an identifier that occurs literally in a class definition begins with two or more underscores and does not end with two or more underscores, it is considered a private name of that class. Private names are transformed into a longer form before code is generated for them. The transformation inserts the class name, with the initial underscores removed and a single underscore inserted, in front of the name.
        </p>
    </div>
);

export default Chapter9_13;
